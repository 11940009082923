import ACTION_TYPES_AISLES from "app/actionTypes/aisles";
import ACTION_TYPES_SHELVES from "app/actionTypes/shelves";
import Aisle from "app/types/state/aisles/Aisle";
import ShelvesState from "app/types/state/shelves";
import Shelf from "app/types/state/shelves/Shelf";

const initialState: ShelvesState = {
  initialized: false,
  initializeError: null,
  allShelves: {},
  recentlyAddedShelf: null,
  recentlyAddedShelfVariantIds: [],
};

const getShelvesFromAisles = (aisles: Aisle[]) => {
  return aisles.reduce((acc, aisle) => {
    return {
      ...acc,
      ...aisle.shelves.reduce((acc, shelf) => {
        return {
          ...acc,
          [shelf.shelfId]: shelf,
        };
      }, {}),
    };
  }, {});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES_AISLES.FETCH_AISLES_SUCCEEDED: {
      const allShelves = getShelvesFromAisles(action.aisles);
      if (state.recentlyAddedShelf) {
        allShelves[state.recentlyAddedShelf.shelfId] = state.recentlyAddedShelf;
      }
      return {
        ...state,
        initialized: true,
        allShelves,
      };
    }

    case ACTION_TYPES_SHELVES.UPDATE_RECENTLY_ADDED_SHELF: {
      return {
        ...state,
        recentlyAddedShelf: action.recentlyAddedShelf,
        recentlyAddedShelfVariantIds: action.recentlyAddedShelfVariantIds,
      };
    }

    default:
      return state;
  }
}

export function allShelves(state: ShelvesState) {
  return state.allShelves;
}

export function getShelfInfoFromShelfId(state: ShelvesState, shelfId: string) {
  return state.allShelves[shelfId];
}

export function getRecentlyAddedShelf(state) {
  return {
    recentlyAddedShelf: state.shelves.recentlyAddedShelf,
    recentlyAddedShelfVariantIds: state.shelves.recentlyAddedShelfVariantIds,
  };
}

export function updateRecentlyAddedShelf(params: {
  recentlyAddedShelf: Shelf;
  recentlyAddedShelfVariantIds: string[];
}) {
  return { ...params, type: ACTION_TYPES_SHELVES.UPDATE_RECENTLY_ADDED_SHELF };
}
