// @ts-nocheck
import _intersection from "lodash/intersection";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";

import {
  getUserNeverList,
  getUserRecurringItems,
  isUserPlusMember,
} from "app/selectors/account";
import { getFulfillmentCenter } from "app/selectors/crossDomain/account";
import { canViewSignupShopping } from "app/selectors/crossDomain/shopping";
import * as fromOfferings from "app/selectors/offerings";
import * as fromActiveOrder from "app/selectors/orderActive";
import * as fromSearch from "app/selectors/search";
import * as fromSignup from "app/selectors/signup";
import ViewableOffering from "app/types/selectors/crossDomain/ViewableOffering";
import State from "app/types/state";
import PropsByVariant from "app/types/state/offerings/PropsByVariant";
import getViewableOffering from "app/utils/transformations/getViewableOffering";

// How do you type this correctly?
type OfferingByVariantIdType = ViewableOffering | null;

export const getOfferingByVariantId = createCachedSelector(
  [
    fromOfferings.getVariantByVariantId,
    fromActiveOrder.getActiveOrder,
    fromSignup.getOrderPosit,
    canViewSignupShopping,
    getUserNeverList,
    getUserRecurringItems,
    isUserPlusMember,
    getFulfillmentCenter,
    (state, props: { variantId: string }) => props.variantId,
    (state, props: { inScalePriceTreatment: boolean | null }) =>
      props.inScalePriceTreatment,
  ],
  (
    offering,
    order,
    positOrder,
    isInSignupShopping,
    neverList,
    recurringItems,
    isPlusMember,
    userFulfillmentCenterId,
    variantId,
    inScalePriceTreatment
  ): OfferingByVariantIdType => {
    // TODO: NC-1244 productionalize signup shopping
    const viewableOrder = isInSignupShopping ? positOrder : order;
    const viewableOffering = getViewableOffering(
      offering,
      viewableOrder,
      neverList,
      recurringItems,
      isPlusMember,
      userFulfillmentCenterId,
      variantId,
      inScalePriceTreatment
    );

    if (!viewableOffering) {
      return {};
    }
    return viewableOffering;
  }
)((_: State, props: PropsByVariant) => props.variantId);
// re-reselect keySelector
// Use "variantId" as cacheKey

export const getOfferingsByVariantIds = (
  state: State,
  props: { variantIds: string[] }
) => {
  return props.variantIds.reduce((acc: Offering[], variantId: string) => {
    const offering = getOfferingByVariantId(state, { variantId });
    if (offering) {
      acc.push(offering);
    }
    return acc;
  }, []);
};

export const getOfferingRecurringMetadata = createSelector(
  [getOfferingByVariantId],
  (offering) => {
    const {
      allowRecurring,
      inRecurringItems,
      availableForRecurring,
    } = offering;
    return { allowRecurring, inRecurringItems, availableForRecurring };
  }
);

export const getActiveTags = createSelector(
  [fromOfferings.getTags, fromOfferings.getAllOfferings],
  (tags, allOfferings) => {
    return tags
      .map((tag) => ({
        ...tag,
        active: allOfferings.some((o) =>
          o.tags.some((t) => t.tagId === tag.id)
        ),
      }))
      .sort((tagA, tagB) => tagB.active - tagA.active); // show active tags first
  }
);

// Use selected tags from redux to refine list of all variantIds
export const getOrderedOfferingsByFilter = createSelector(
  [fromOfferings.getAllOfferings, fromOfferings.getSelectedTagIds],
  (allOfferings, selectedTagIds) => {
    return allOfferings.reduce((acc, o) => {
      // otherwise inspect offering tagss, and keep offering visible if match
      let hasTag = !selectedTagIds.length;

      if (selectedTagIds.length && o.tags && o.tags.length) {
        hasTag = o.tags.some((t) => selectedTagIds.includes(t.tagId));
      }

      if (hasTag) acc.push(o.variantId);
      return acc;
    }, []);
  }
);

export const getFilteredSearchVariantIds = createSelector(
  [getOrderedOfferingsByFilter, fromSearch.getSearchResultVariantIds],
  (orderedOfferings, searchResultVariantIds) => {
    if (!orderedOfferings || !searchResultVariantIds) return null;

    return _intersection(searchResultVariantIds, orderedOfferings);
  }
);

export const getOrderedOfferingsWithSearch = createSelector(
  [
    getOrderedOfferingsByFilter,
    getFilteredSearchVariantIds,
    fromSearch.isSearching,
    fromOfferings.getMapOfVariants,
  ],
  (orderedOfferings, filteredSearchVariantIds, isSearching, mapOfVariants) => {
    if (isSearching && filteredSearchVariantIds) {
      return filteredSearchVariantIds.sort(
        (a, b) => mapOfVariants[b]?.hasStock - mapOfVariants[a]?.hasStock
      );
    }

    return orderedOfferings;
  }
);

export const getSearchResultInAllProducts = createSelector(
  [fromSearch.getSearchResultVariantIds, fromOfferings.getOrderedVariantIds],
  (searchResultVariantIds, orderedVariantIds) => {
    // null if we don't yet know if there are matching results
    if (!searchResultVariantIds || !orderedVariantIds) return 0;
    return _intersection(searchResultVariantIds, orderedVariantIds).length;
  }
);
