import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import colors, { Color } from "app/styles/colors";
import breakpoints from "app/styles/breakpoints";

export interface CurveProps {
  bgcolor?: Color;
  color?: Color;
  bottom?: boolean;
}

const Curve: React.FC<CurveProps> = ({
  bgcolor,
  color = "baba",
  bottom = false,
}) => {
  return (
    <SCCurveSVG
      width="100%"
      viewBox="0 0 375 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $bgcolor={bgcolor}
      $bottom={bottom}
    >
      <path
        d="M375 18C222.445 17.9914 154 0 0 0V30H375V18Z"
        fill={colors[color]}
      />
    </SCCurveSVG>
  );
};

interface CurveSVGProps {
  $bgcolor?: Color;
  $bottom: boolean;
}

const SCCurveSVG = styled.svg<CurveSVGProps>`
  ${({ $bgcolor }) => $bgcolor && `background-color: ${colors[$bgcolor]};`}
  display: block;
  position: relative;
  transform: ${({ $bottom }) => $bottom && "rotate(180deg)"};
  ${breakpoints.xl`
    top: ${({ $bottom }: CurveSVGProps) => !$bottom && `-${rem(16)}`};
    bottom: ${({ $bottom }: CurveSVGProps) => $bottom && `-${rem(32)}`};
  `}
`;

export default Curve;
