const deprecatedColors = {
  // Imperfect Colors
  greenLightest: "#C5DB56",
  greenLight: "#75B543",
  newGreen: "#699F3D",
  greenDark: "#547535",
  green: "#547420", // Primary
  alertGreen: "#1F8223", // For alert banner
  pink: "#B32274", // Primary
  secondaryPink: "#C03182",
  pinkLight: "#F49CC2",
  pinkBright: "#E95CA2",
  pinkDeep: "#DD3D96",
  pinkDark: "#A0256A",
  purple: "#951E5D",
  purpleLighter: "#C657A0",
  redLightest: "#F8B4B4",
  grapefruit: "#F1585A",
  orange: "#F7921E",
  orangeLightest: "#FCE1CD",
  yellow: "#FFDB00",
  gold: "#F2C94B",
  blueDark: "#2E7F7E",
  blue: "#41B3B1",
  blueLightest: "#9FDAE0",
  blueTeal: "#338F8D",
  babyBlue: "#CDF0FD",
  coral: "#ED615C", // For delayed delivery notice

  // Tans
  tanLightest: "#FDFCFA",
  tanLighter: "#F1EEEA",
  tanLight: "#DBD1C6",

  // Grays
  white: "#FFFFFF",
  backgroundWhite: "#FDFDFD",
  grayLightest: "#F8F8F8",
  grayLighter: "#EFEFEF",
  grayLight: "#CFCFCF",
  gray: "#707070",
  grayDark: "#333333", // '#333333',
  grayStone: "#9B9B9B",
  graySmoke: "#F3F3F3",

  boxShadowGray: "#484848",
  black: "#000000",
  textBlack: "#453D3D",

  // Experiment Related
  experimentGreen: "#55BC85",
  fauxSapGreen: "#5C821D",

  // Purchase button color - sensitive, requires more thought
  purchaseGreen: "#00BF81",
};

export const colors = {
  // primary
  beet: "#B32274",
  avocado: "#547420",

  // type
  peppercorn: "#453D3D",
  plum: "#371F2F",
  eggplant: "#780D49",
  kale: "#0B4127",

  // neutral
  marshmallow: "#FFFFFF",
  baba: "#F8F2ED",
  oat: "#ECE7E4",
  barley: "#BEB3B4",
  taro: "#676060",
  baseGray: "#373d3f",

  // backgrounds only
  frosting: "#C6E8EB",
  lavender: "#D8C0E0",
  lavenderLight: "#E5CFED",
  rose: "#FFCBDC",
  papaya: "#F6A685",
  papayaLight: "#FABDA4",
  lemon: "#F7D46D", // used as background AND "warning" MUI alert
  lemonLight: "#FFDE7C",
  lime: "#C5DB66",

  // temporary revamp badge colors
  // to be deprecated when imperfection colors are finalized
  rescuedRed: "#EF4349",
  upcycledBlue: "#338F8D",
  uglyGreen: "#607A23",
  cosmeticPurple: "#951E5D",
  sizeOrWeightBlue: "#00736E",
  leftoverPink: "#DD078C",

  // alerts
  cherry: "#D9291F", // "urgent"/"error" MUI alert
  robinEgg: "#63ACDE", // "info" MUI alert
  lettuce: "#75B543", // "success"/"update" MUI alert

  // ui
  guava: "#E95CA2",
  watermelon: "#DD3D96",
} as const;

// https://stackoverflow.com/a/69902027
// To access ColorValues, cast the object "as const" and then typeof object[key] will output all object values as a type
export type ColorNames = keyof typeof colors;
type ColorValues = typeof colors[ColorNames];

// Including ColorValues allows us to use the theme.colors values directly
export type Color = ColorNames | ColorValues;

// taken from https://www.figma.com/file/moXhijjwdtSkLmJcNrE1J2/Design-System%3A-%E2%9A%9B%EF%B8%8F-Atoms-(formerly-%F0%9F%8F%94-Foundations)?node-id=516%3A7822
export const getTypographyColorForBackground = (background?: Color) => {
  const blackFont = colors.peppercorn;
  const primaryFont = colors.plum;
  const lightFont = colors.marshmallow;
  const darkBackgrounds = [
    "eggplant",
    "kale",
    "taro",
    "peppercorn",
    "plum",
    "peppercorn",
    "beet",
    "avocado",
  ];

  if (background === "marshmallow") {
    return blackFont;
  }

  if (background && darkBackgrounds.includes(background)) {
    return lightFont;
  }

  return primaryFont;
};

export default {
  ...colors,
  ...deprecatedColors,
};
