// @ts-nocheck
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import breakpoints, { sizes } from "app/styles/breakpoints";
import grid from "app/styles/grid";
import { toRem } from "app/styles/utils";
import Modal from "app/ui/global/modals/Modal";
import { getImageURL } from "app/ui/global/utils";
import { StyledButton } from "app/ui/shared/Button";

export const NEVER_LIST_INFO_MODAL = "never-list-feature-intro";

const IMAGE_WIDTH = 320;
const IMAGE_HEIGHT = 201;

const NeverListFeatureIntro = ({ hideNeverListInfo }) => {
  return (
    <Modal
      modalId={NEVER_LIST_INFO_MODAL}
      closeOnBackgroundClick
      showCloseButton={false}
      onCloseModal={hideNeverListInfo}
      modalStyles={modalStyles}
      dark
    >
      {(closeModal) => (
        <>
          <Description>
            Tell us which items you never want in your default box by saying
            &#34;Never!&#34;
          </Description>
          <ImageWrapper>
            <img
              src={`${getImageURL(
                "remove-items.jpg",
                "v1561494476/website-assets",
                {
                  w: IMAGE_WIDTH,
                  h: IMAGE_HEIGHT,
                  c: "fit",
                  d: "products:no-image-found.png",
                  dpr: "auto",
                }
              )}`}
              alt=""
              width={IMAGE_WIDTH}
              height={IMAGE_HEIGHT}
            />
          </ImageWrapper>
          <SecondaryDescription first last>
            Never worry about getting that item in your box again unless you add
            it yourself.
          </SecondaryDescription>
          <Buttons width={IMAGE_WIDTH}>
            <StyledButton size="xLarge" intent="secondary" onClick={closeModal}>
              Got it!
            </StyledButton>
          </Buttons>
        </>
      )}
    </Modal>
  );
};

NeverListFeatureIntro.propTypes = {
  hideNeverListInfo: PropTypes.func.isRequired,
};

const outer = css`
  margin-top: ${({ theme }) => toRem(theme.layout.headerHeightMobile + 16)};
  padding: ${toRem([28, 0, 0])};
  text-align: center;

  ${breakpoints.md`
    max-width: ${toRem(sizes.md)};
  `};

  ${breakpoints.xl`
    margin-top: ${({ theme }) => toRem(theme.layout.headerHeightMobile)};
  `};
`;

const inner = css`
  padding: ${toRem([8, 24, 16])};
  max-height: 84vh;

  ${breakpoints.md`
    padding: ${toRem([16, 32])};
  `};

  ${breakpoints.lg`
    padding: ${toRem([16, 48, 32])};
  `};
`;
const modalStyles = { outer, inner };

const Description = styled.p`
  font-size: ${toRem(17)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  max-width: ${toRem(280)};
  margin: ${toRem([0, "auto", 8])};

  ${({ last }) =>
    last &&
    `
    margin-bottom: ${toRem(16)};
  `}

  ${({ first }) =>
    first &&
    `
    margin-top: ${toRem(16)};
  `}

  ${breakpoints.lg`
    max-width: ${toRem(350)};
  `};
`;

const SecondaryDescription = styled(Description)`
  font-size: ${toRem(14)};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  max-width: ${toRem(324)};
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: ${toRem([0, "auto"])};
  min-height: ${toRem(IMAGE_HEIGHT)};

  & > img {
    height: unset;
  }
`;

const Buttons = styled.div`
  ${grid.row};
  flex-direction: column;

  & > button {
    margin-bottom: 0;
  }

  ${breakpoints.lg`
    max-width: ${({ width }) => toRem(width + 32)};
    margin: ${toRem([0, "auto"])};
  `};
`;

export default NeverListFeatureIntro;
