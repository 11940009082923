import { useEffect, useRef } from "react";

// Do we want to bring this library in? Seems useful?
// https://github.com/streamich/react-use/blob/HEAD/docs/Usage.md
// This function is pretty common in the community so implementing locally for now.
// https://github.com/streamich/react-use/blob/master/src/usePrevious.ts
export default function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}
