import { LinkResolverFunction } from "@prismicio/helpers";

import routes from "app/router/routes.json";
import {
  CMS_LANDING_PAGE_CUSTOM_TYPE,
  CMS_CONTENT_PAGE_CUSTOM_TYPE,
  CMS_LEGACY_CONTENT_PAGE_CUSTOM_TYPE,
} from "app/ui/cms/shared/utils/constants";

const linkResolver: LinkResolverFunction = (document) => {
  if (document.type === CMS_LANDING_PAGE_CUSTOM_TYPE) {
    return `${routes.start.url}/${document.uid}`;
  }
  if (document.type === CMS_CONTENT_PAGE_CUSTOM_TYPE) {
    return `/${document.uid}`;
  }
  if (document.type === CMS_LEGACY_CONTENT_PAGE_CUSTOM_TYPE) {
    return `/${document.uid}`;
  }
  return "/";
};

export default linkResolver;
