import { rem } from "polished";
import React, { useMemo, useRef } from "react";
import styled from "styled-components";

import useActiveMobileShelf from "../hooks/useActiveMobileShelf";

import MobileNavDivider from "app/images/MobileNavDivider.svg";
import Aisle from "app/types/state/aisles/Aisle";
import Shelf from "app/types/state/shelves/Shelf";
import { MaskWithFadeTransition } from "app/ui/shared/MaskWithFadeTransition";
import { Icon as FruitletsIcon } from "app/ui/shared/components";
import MobileDropdown from "app/ui/shopping/MobileNavigation/MobileDropdown";
import { useSelector } from "react-redux";
import { getAisleListData, getSubaisleListData } from "app/selectors";
import State from "app/types/state";
// @ts-ignore

export interface MobileNavigationProps
  extends React.HTMLAttributes<HTMLDivElement> {
  currentAisle?: Aisle;
  currentAnchorId?: string;
  toggleAislesDropdown: () => void;
  aislesDropdownOpen: boolean;
  currentShelf?: Shelf;
  toggleShelvesDropdown: () => void;
  shelvesDropdownOpen: boolean;
  shopAllActive: boolean;
  stickyHeaderBottom?: number;
}

const MobileNavigation: React.FC<MobileNavigationProps> = (props) => {
  const {
    currentAisle,
    toggleAislesDropdown,
    aislesDropdownOpen,
    toggleShelvesDropdown,
    shelvesDropdownOpen,
    currentAnchorId,
    shopAllActive,
    stickyHeaderBottom,
  } = props;
  const aisleToggleRef = useRef<HTMLButtonElement>(null);
  const shelfToggleRef = useRef<HTMLButtonElement>(null);
  const activeShelf = useActiveMobileShelf({
    currentAisle,
    currentAnchorId,
  });

  const aisleListItems = useSelector(getAisleListData);
  const subaisleListItems = useSelector((state: State) =>
    getSubaisleListData(state, { aisle: currentAisle })
  );
  const activeAisleListItem = useMemo(
    () => aisleListItems.find((aisle) => aisle.active),
    [aisleListItems]
  );
  const activeSubaisleListItem = useMemo(
    () =>
      subaisleListItems?.find(
        (subaisle) => subaisle.id === activeShelf?.shelfId
      ),
    [subaisleListItems]
  );

  const aisleName = shopAllActive ? "Shop All" : currentAisle?.name;

  const displayAisleVariantCount =
    activeAisleListItem && activeAisleListItem.value !== null;

  const displaySubaisleVariantCount =
    activeSubaisleListItem && activeSubaisleListItem.value !== null;

  return (
    <>
      <StyledMobileNavigation
        data-testid="mobile-navigation-wrapper"
        {...props}
      >
        <MobileAisle
          data-testid="aisle-wrapper"
          $active={aislesDropdownOpen}
          onClick={toggleAislesDropdown}
          onMouseDown={(e) => e.preventDefault()}
          ref={aisleToggleRef}
        >
          {aisleName}{" "}
          {displayAisleVariantCount && `(${activeAisleListItem?.value})`}
          <ChevronIcon
            data-testid="chevron-icon"
            type={aislesDropdownOpen ? "ExpandLess" : "ExpandMore"}
            titleAccess={aislesDropdownOpen ? "ExpandLess" : "ExpandMore"}
          />
        </MobileAisle>
        <StyledDivider />
        {!shopAllActive && (
          <MobileShelf
            data-testid="shelf-wrapper"
            $active={shelvesDropdownOpen}
            onClick={toggleShelvesDropdown}
            onMouseDown={(e) => e.preventDefault()}
            ref={shelfToggleRef}
          >
            <span>
              {activeShelf?.name}{" "}
              {displaySubaisleVariantCount &&
                `(${activeSubaisleListItem?.value})`}
            </span>
            <ChevronIcon
              data-testid="chevron-icon"
              type={shelvesDropdownOpen ? "ExpandLess" : "ExpandMore"}
            />
          </MobileShelf>
        )}
      </StyledMobileNavigation>
      <MaskWithFadeTransition
        visible={aislesDropdownOpen}
        onClick={toggleAislesDropdown}
        componentTheme="opaqueWhite"
        scrollLock
        top={stickyHeaderBottom}
        toggleRef={aisleToggleRef}
      >
        <MobileDropdown
          data-testid="mobile-aisle-dropdown"
          type="aisle"
          aisleListItems={aisleListItems}
          subaisleListItems={subaisleListItems}
          {...props}
        />
      </MaskWithFadeTransition>
      <MaskWithFadeTransition
        visible={!shopAllActive && shelvesDropdownOpen}
        onClick={toggleShelvesDropdown}
        componentTheme="opaqueWhite"
        scrollLock
        top={stickyHeaderBottom}
        toggleRef={shelfToggleRef}
      >
        <MobileDropdown
          data-testid="mobile-shelf-dropdown"
          type="shelf"
          aisleListItems={aisleListItems}
          subaisleListItems={subaisleListItems}
          {...props}
        />
      </MaskWithFadeTransition>
    </>
  );
};

export default MobileNavigation;

const StyledMobileNavigation = styled.div`
  border-top: ${rem(0.5)} solid ${({ theme }) => theme.colors.grayLight};
  border-bottom: ${rem(0.5)} solid ${({ theme }) => theme.colors.grayLight};
  display: flex;
`;

interface ButtonProps {
  $active: boolean;
}

const NavSection = styled.button<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  font-size: ${rem(14)};
  border: none;
  padding: 0;
  text-align: left;
  ${({ $active }) => $active && "text-decoration: underline;"}
`;

const MobileAisle = styled(NavSection)`
  margin-left: ${rem(20)};
  margin-right: ${rem(16)};
  color: ${({ theme }) => theme.colors.textBlack};
  min-width: fit-content;
  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
  white-space: pre;
`;

const MobileShelf = styled(NavSection)`
  margin-left: ${rem(16)};
  margin-right: ${rem(20)};
  color: ${({ theme }) => theme.colors.textBlack};
  flex: 1 1 100%;
  /* https://css-tricks.com/flexbox-truncated-text/#the-solution-is-min-width-0-on-the-flex-child */
  min-width: 0;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    height: ${rem(16)};
  }
  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
`;

const StyledDivider = styled(MobileNavDivider)`
  width: ${rem(48)};
`;

const ChevronIcon = styled(FruitletsIcon)`
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.grayDark};
  margin-left: ${rem(3)};
`;
