import { take, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/analytics";
import { isAnalyticsInitialized } from "app/selectors";

// eslint-disable-next-line import/prefer-default-export
export function* waitForAnalyticsInitialization() {
  const ready = yield select(isAnalyticsInitialized);
  if (!ready) {
    yield take([ACTION_TYPES.SDK_READY]);
  }
}
