export enum AisleShelfClickedSource {
  NavigationTopBar = "Navigation Top Bar",
  SubaisleSideNav = "Subaisle Side Nav",
  YouMightAlsoLike = "You Might Also Like",
}

export default interface AisleShelfClicked {
  location: string;
  shelfName: string;
  shelfId: string;
  aisleName: string;
  aisleId: string;
  source: AisleShelfClickedSource;
}
