import { createSelector } from "reselect";

import * as fromUi from "../reducers/ui";

import {
  FULL_SCREEN_MODAL_IDS,
  URL_ENABLED_MODAL_IDS,
} from "app/constants/modals";
import State from "app/types/state";
import { SnackbarIds } from "app/types/ui/Snackbar";

export function isMaintenanceMode(state: State) {
  return fromUi.getMaintenanceMode(state.ui);
}

export function isBootstrapped(state: State) {
  return fromUi.getBootstrapped(state.ui);
}

export function isNotBootstrapped(state: State) {
  return !isBootstrapped(state);
}

export function isInitialized(state: State) {
  return fromUi.getInitialized(state.ui);
}

export function getCriticalRequestFailed(state: State) {
  return fromUi.getCriticalRequestFailed(state.ui);
}

export function getAppFailed(state: State) {
  return fromUi.getAppFailed(state.ui);
}

export function isMobileCartMessageVisible(state: State) {
  return fromUi.isMobileCartMessageVisible(state.ui);
}

export function isHolidayAlertVisible(state: State) {
  return fromUi.isHolidayAlertVisible(state.ui);
}

export function getHolidayAlertMessage(state: State) {
  return fromUi.getHolidayAlertMessage(state.ui);
}

export const shouldShowHolidayAlert = createSelector(
  [isHolidayAlertVisible, getHolidayAlertMessage],
  (visible, message) => visible && !!message
);

export function getSnackbars(state: State) {
  return fromUi.getSnackbars(state.ui);
}

export const getSnackbarById = (state: State, snackbarId: SnackbarIds) =>
  fromUi.getSnackbars(state.ui)[snackbarId];

export const isSnackbarVisible = (state: State, snackbarId: SnackbarIds) => {
  const snackbar = getSnackbarById(state, snackbarId);
  return !!snackbar && snackbar.isVisible;
};

export function getDialog(state: State) {
  return fromUi.getDialog(state.ui);
}

export function getModals(state: State) {
  return fromUi.getModals(state.ui);
}

export function getTopNav(state: State) {
  return fromUi.getTopNav(state.ui);
}

export function getCart(state: State) {
  return fromUi.getCart(state.ui);
}

export function getShopToolbarSelectedItem(state: State) {
  return fromUi.getShopToolbarSelectedItem(state.ui);
}

export function isShoppingInitialized(state: State) {
  return fromUi.isShoppingInitialized(state.ui);
}

export function isSignupShoppingInitialized(state: State) {
  return fromUi.isSignupShoppingInitialized(state.ui);
}

export function getRecurringItemsTimeSinceLastNudge(state: State) {
  return fromUi.getRecurringItemsTimeSinceLastNudge(state.ui);
}

export function isPlusInitialized(state: State) {
  return fromUi.isPlusInitialized(state.ui);
}

export function getRecipeToastState(state: State) {
  return fromUi.getRecipeToastState(state.ui);
}

export function getDeviceType(state: State) {
  return fromUi.getDeviceType(state.ui);
}

export function getCatalogPreviewTool(state: State) {
  return fromUi.getCatalogPreviewTool(state.ui);
}

export const isTopNavVisible = createSelector(
  [getTopNav],
  (topNav) => topNav.visible
);

export const isCartVisible = (state: State) => getCart(state).visible;

export function getAccountSubNav(state: State) {
  return fromUi.getAccountSubNav(state.ui);
}

export const isAccountSubNavVisible = createSelector(
  [getAccountSubNav],
  (accountSubNav) => accountSubNav.visible
);

export const getModalById = createSelector(
  [getModals, (_: State, props: { modalId: string }) => props],
  (modals, { modalId }) => modals[modalId] || {}
);

export const isAnyUrlEnabledModalVisible = createSelector(
  [getModals],
  (modals) => {
    const modalIds = Object.keys(modals).filter((id) =>
      URL_ENABLED_MODAL_IDS.includes(id)
    );
    return modalIds.reduce((anyModalVisible, id) => {
      return anyModalVisible || modals[id].visible;
    }, false);
  }
);

export const isModalVisible = createSelector(
  [getModalById],
  (modal) => modal.visible || false
);

export const isAnyModalVisible = createSelector(
  [getModals],
  (modals: State["ui"]["modals"]) =>
    Object.values(modals).some((params) => params.visible)
);

export const isAnyFullScreenModalVisible = createSelector(
  [getModals],
  (modals) =>
    FULL_SCREEN_MODAL_IDS.some((modalId: string) => {
      const params = modals[modalId];
      return params && params.visible;
    })
);

export const getModalParams = createSelector(
  [getModalById],
  (modal) => modal.params || {}
);

const queryDeviceType = (query: (arg0: string) => boolean) => {
  return createSelector([getDeviceType], (deviceType) => {
    return query(deviceType);
  });
};

export const isMobileDevice = queryDeviceType(
  (deviceType) => deviceType === "mobile"
);

export const isTabletDevice = queryDeviceType(
  (deviceType) => deviceType === "tablet"
);

export const isDesktopDevice = queryDeviceType(
  (deviceType) => deviceType === "desktop"
);

// Users previously buckted by split now see the "shopping mobile ui treatment" if on mobile or tablet
export const isInShoppingMobileTreatment = queryDeviceType(
  (deviceType) => deviceType === "mobile" || deviceType === "tablet"
);

export function getCatalogScrollToHistory(state: State) {
  return fromUi.getCatalogScrollToHistory(state.ui);
}

export function getCatalogNavigateUrl(state: State) {
  return fromUi.getCatalogNavigateUrl(state.ui);
}

export function isAppUpdateRequiredWarningModalVisible(state: State) {
  return state.ui.appUpdateRequired.showWarningModal;
}
