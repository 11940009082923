import {
  getShoppableRecipeAisleConfig,
  isInShoppableRecipeAisleTreatment,
  isInShoppingCarouselTreatment,
} from "./crossDomain/splitTreatments";
import { getCatalogShelfHeaderToken } from "app/ui/shopping/SinglePageCatalog/CatalogShelfHeader";
import { getCatalogMobileOfferingToken } from "app/ui/shopping/SinglePageCatalog/CatalogMobileOffering";
import _reduce from "lodash/reduce";
import queryString from "query-string";
import { createSelector } from "reselect";

import * as fromCatalog from "app/reducers/catalog";
import { toAisle } from "app/router/routes";
import { getAllAisles, getAisleConfig } from "app/selectors/aisles";
import { getActiveFilter } from "app/selectors/offerings";
import { getRoutingLocation } from "app/selectors/routing";
import { getAllShelves } from "app/selectors/shelves";
import { isMobileDevice } from "app/selectors/ui";
import { getUserLifetimeOrderCount } from "app/selectors/account";
import PropsByShelf from "app/types/catalog/PropsByShelf";
import State from "app/types/state";
import Aisle from "app/types/state/aisles/Aisle";
import { GetShelvesAvailabilityParams } from "app/types/state/catalog";
import { Breadcrumb } from "app/ui/designSystem/molecules/Breadcrumbs";
import { getCatalogAisleHeaderToken } from "app/ui/shopping/SinglePageCatalog/CatalogAisleHeader";
import { getNoVariantsWithFilterToken } from "app/ui/shopping/SinglePageCatalog/NoVariantsWithFilter";
import { CatalogToken } from "app/ui/shopping/SinglePageCatalog/CatalogList";
import { getCatalogShelfToken } from "app/ui/shopping/SinglePageCatalog/CatalogShelf";
import Tag from "app/types/state/offerings/Tag";
import { getCatalogShelfPromotionCardToken } from "app/ui/shopping/SinglePageCatalog/CatalogShelfPromotionCard";
import { getDesktopCarouselToken } from "app/ui/shopping/FirstTimeCarousel/DesktopCarousel";
import { getMobileCarouselToken } from "app/ui/shopping/FirstTimeCarousel/MobileCarousel";

export function isCatalogInitialized(state: State) {
  return fromCatalog.isCatalogInitialized(state.catalog);
}

export function getVariantIdsByShelf(state: State) {
  return fromCatalog.variantIdsByShelf(state.catalog);
}

export function isRefetchingCatalog(state: State) {
  return fromCatalog.isRefetchingCatalog(state.catalog);
}

export function getAlgoliaDataByShelf(state: State) {
  return fromCatalog.getAlgoliaDataByShelf(state.catalog);
}

export function getShelfVariantIds(state: State, props: PropsByShelf) {
  if (!isCatalogInitialized(state) || !getVariantIdsByShelf(state)) return [];
  return getVariantIdsByShelf(state)[props.shelfId];
}

export function getShelfAvailability(state: State, props: PropsByShelf) {
  if (!isCatalogInitialized(state) || !getVariantIdsByShelf(state)) return [];
  return fromCatalog.availabilityByShelf(state.catalog, props);
}

export function getShelvesAvailability(
  state: State,
  props: GetShelvesAvailabilityParams
) {
  if (
    !props.shelfIds ||
    !isCatalogInitialized(state) ||
    !getVariantIdsByShelf(state)
  )
    return [];
  return props.shelfIds.reduce((availability, shelfId) => {
    return {
      ...availability,
      [shelfId]: fromCatalog.availabilityByShelf(state.catalog, {
        shelfId,
      }),
    };
  }, {});
}

/** Returns the last aisle id that matches with any of the passed in shelf ids */
export function getLastAisleShelfPair(aisles: Aisle[], shelfIds: string[]) {
  let lastShelfId: string | undefined;

  const lastAisle = aisles
    .slice()
    .reverse()
    .find((aisle) => {
      const aisleShelfIds = aisle.shelves.map((shelf) => shelf.shelfId);
      return shelfIds.some((shelfId) => {
        if (aisleShelfIds.includes(shelfId)) {
          lastShelfId = shelfId;
          return true;
        }
        return false;
      });
    });

  return {
    aisleId: lastAisle?.aisleId,
    shelfId: lastShelfId,
  };
}

const getAisleShelfPairForVariant = createSelector(
  [
    getRoutingLocation,
    getVariantIdsByShelf,
    getAisleConfig,
    (_: State, props: { variantId: string }) => props.variantId,
  ],
  (
    location,
    variantIdsByShelf,
    aisleConfig,
    variantId
  ): { aisleId?: string; shelfId?: string } => {
    const shelvesForVariant = _reduce(
      variantIdsByShelf,
      (acc: string[], variantIds, shelfId) => {
        if (variantIds.includes(variantId)) {
          return [...acc, shelfId];
        }
        return acc;
      },
      []
    );

    const parsedHash = queryString.parse(location.hash);
    const parsedHashShelfId = parsedHash.shelf as string;
    const validatedShelfHash = shelvesForVariant.includes(parsedHashShelfId);

    return getLastAisleShelfPair(
      aisleConfig,
      validatedShelfHash ? [parsedHashShelfId] : shelvesForVariant
    );
  }
);

export const getBreadcrumbForVariant = createSelector(
  [
    getAisleShelfPairForVariant,
    getAllAisles,
    getAllShelves,
    (_: State, props: { isInSignupShopping?: boolean }) =>
      props.isInSignupShopping,
  ],
  (
    aisleShelfPair,
    allAisles,
    allShelves,
    isInSignupShopping
  ): Breadcrumb[] | null => {
    const { aisleId, shelfId } = aisleShelfPair;

    if (!aisleId || !shelfId) {
      return null;
    }

    return [
      {
        order: 1,
        name: allAisles[aisleId].name,
        url: toAisle({ aisle: { aisleId }, isInSignupShopping }),
      },
      {
        order: 2,
        url: toAisle({ shelf: { shelfId }, isInSignupShopping }),
        name: allShelves[shelfId].name,
      },
    ];
  }
);

export const getAisleAndShelfForVariant = createSelector(
  [getAisleShelfPairForVariant, getAllAisles, getAllShelves],
  (aisleShelfPair, allAisles, allShelves) => {
    const { aisleId, shelfId } = aisleShelfPair;

    if (!aisleId || !shelfId) {
      return null;
    }

    return {
      aisle: {
        aisleId,
        name: allAisles[aisleId].name,
      },
      shelf: {
        shelfId,
        shelfName: allShelves[shelfId].name,
      },
    };
  }
);

/**
 * Returns the id of the last shelf rendered within a given aisle
 */
const getLastRenderedShelfId = ({
  activeFilter,
  aisle,
  variantIdsByShelf,
}: {
  activeFilter: Tag | null;
  aisle: Aisle;
  variantIdsByShelf: Record<string, string[]>;
}) => {
  let lastRenderedShelfId: string | null = null;
  if (activeFilter) {
    lastRenderedShelfId =
      aisle.shelves[aisle.shelves.length - 1]?.shelfId || null;
  } else {
    for (let i = aisle.shelves.length - 1; i >= 0; i--) {
      if (
        variantIdsByShelf[aisle.shelves[i].shelfId] &&
        variantIdsByShelf[aisle.shelves[i].shelfId].length > 0
      ) {
        lastRenderedShelfId = aisle.shelves[i].shelfId;
        break;
      }
    }
  }
  return lastRenderedShelfId;
};

const getCatalogOfferingRowColumns = (_: State, props: { columns: number }) =>
  props.columns;

export const getCatalogTokens = createSelector(
  [
    getAisleConfig,
    getVariantIdsByShelf,
    getActiveFilter,
    getCatalogOfferingRowColumns,
    isInShoppableRecipeAisleTreatment,
    getShoppableRecipeAisleConfig,
    isMobileDevice,
    isInShoppingCarouselTreatment,
    getUserLifetimeOrderCount,
  ],
  (
    aisleConfig,
    variantIdsByShelf,
    activeFilter,
    offeringRowColumns,
    inShoppableRecipeAisleTreatment,
    shoppableRecipeAisleConfig,
    mobileDevice,
    inShoppingCarouselTreatment,
    orderNumber
  ) => {
    const catalogTokens: CatalogToken[] = [];

    /* TODO: NC-1768 Productize FTUE experiment */
    if (inShoppingCarouselTreatment) {
      if (mobileDevice) {
        catalogTokens.push(getMobileCarouselToken({ orderNumber }));
      } else {
        catalogTokens.push(getDesktopCarouselToken({ orderNumber }));
      }
    }

    // Push a header token for each aisle
    aisleConfig.forEach((aisle) => {
      const isRecipeAisle = shoppableRecipeAisleConfig?.aisleIds?.includes(
        aisle.aisleId
      );
      if (!inShoppableRecipeAisleTreatment && isRecipeAisle) {
        // hide recipes aisles for users not in the recipe split
        return;
      }

      catalogTokens.push(
        getCatalogAisleHeaderToken({
          columns: offeringRowColumns,
          aisleId: aisle.aisleId,
          aisleName: aisle.name,
          aisleDescription: aisle.description || "",
          heroImageFilename: aisle.heroImageFilename || "",
        })
      );

      const lastRenderedShelfId = getLastRenderedShelfId({
        activeFilter,
        aisle,
        variantIdsByShelf,
      });

      // Push a header token for each shelf
      aisle.shelves.forEach((shelf) => {
        const variantIds = variantIdsByShelf[shelf.shelfId] || [];

        if (activeFilter || variantIds?.length > 0) {
          if (offeringRowColumns > 1) {
            catalogTokens.push(
              getCatalogShelfToken({
                activeFilter,
                columns: offeringRowColumns,
                lastInAisle: shelf.shelfId === lastRenderedShelfId,
                description: shelf.description,
                shelfId: shelf.shelfId,
                shelfName: shelf.name,
                variantIds,
                promotionImageFilename: shelf.promotionImageFilename,
                promotionLink: shelf.promotionLink,
                promotionLinkText: shelf.promotionLinkText,
                promotionVideoFilename: shelf.promotionVideoFilename,
                promotionVideoSubtitleFilename:
                  shelf.promotionVideoSubtitleFilename,
                isRecipe: isRecipeAisle,
              })
            );
          }
          if (offeringRowColumns === 1) {
            catalogTokens.push(
              getCatalogShelfHeaderToken({
                columns: offeringRowColumns,
                description: shelf.description,
                shelfId: shelf.shelfId,
                shelfName: shelf.name,
                canAddToCart: isRecipeAisle && variantIds.length > 0,
              })
            );
            if (shelf.promotionImageFilename || shelf.promotionVideoFilename) {
              catalogTokens.push(
                getCatalogShelfPromotionCardToken({
                  shelfId: shelf.shelfId,
                  shelfName: shelf.shelfName,
                  columns: offeringRowColumns,
                  promotionImageFilename: shelf.promotionImageFilename,
                  promotionLink: shelf.promotionLink,
                  promotionLinkText: shelf.promotionLinkText,
                  promotionVideoFilename: shelf.promotionVideoFilename,
                  promotionVideoSubtitleFilename:
                    shelf.promotionVideoSubtitleFilename,
                })
              );
            }
            variantIds.forEach((variantId, index) => {
              const lastInAisle =
                shelf.shelfId === lastRenderedShelfId &&
                index === variantIds.length - 1;

              catalogTokens.push(
                getCatalogMobileOfferingToken({
                  variantId,
                  shelfId: shelf.shelfId,
                  index,
                  lastInAisle,
                })
              );
            });
            if (activeFilter && variantIds.length === 0) {
              catalogTokens.push(
                getNoVariantsWithFilterToken({
                  activeFilter,
                  columns: offeringRowColumns,
                  lastInAisle:
                    shelf.shelfId ===
                    aisle.shelves[aisle.shelves.length - 1].shelfId,
                })
              );
            }
          }
        }
      });
    });

    // If the browser font size is non default (1rem != 16px) we need to multiply all token heights to fix the layout
    const remFactor =
      parseFloat(getComputedStyle(document.documentElement).fontSize) / 16;
    if (remFactor && remFactor !== 1) {
      catalogTokens.forEach((t) => {
        // eslint-disable-next-line no-param-reassign
        t.listItemHeight *= remFactor;
      });
    }

    return catalogTokens;
  }
);
