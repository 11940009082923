import { createSelector } from "reselect";

import * as fromAuth from "../reducers/auth";
import State from "app/types/state";

export function isLoggedIn(state: State) {
  return fromAuth.isLoggedIn(state.auth);
}

export function getUserId(state: State) {
  return fromAuth.getUserId(state.auth);
}

export function getTokens(state: State) {
  return fromAuth.getTokens(state.auth);
}

export function getCognitoUser(state: State) {
  return fromAuth.getCognitoUser(state.auth);
}

export function isImpersonated(state: State) {
  return fromAuth.isImpersonated(state.auth);
}

export function hasRequestedPasswordReset(state: State) {
  return fromAuth.hasRequestedPasswordReset(state.auth);
}

export function getPasswordResetRequestEmail(state: State) {
  return fromAuth.getPasswordResetRequestEmail(state.auth);
}

export function hasSuccessfullyResetPassword(state: State) {
  return fromAuth.hasSuccessfullyResetPassword(state.auth);
}

export function newPasswordRequired(state: State) {
  return fromAuth.newPasswordRequired(state.auth);
}

export const isLoggedOut = createSelector(
  [isLoggedIn],
  (loggedIn) => !loggedIn
);

export const getIdToken = createSelector(
  [getTokens],
  (tokens) => tokens.id_token
);
