import { put } from "redux-saga/effects";

import { resetShopping } from "app/reducers/ui";

type OrderId = string | null;

function* triageResetShoppingFlow(
  previousOrderId: OrderId,
  activeOrderId: OrderId
) {
  if (previousOrderId && activeOrderId !== previousOrderId) {
    yield put(resetShopping());
  }
}

export default triageResetShoppingFlow;
