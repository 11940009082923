// @ts-nocheck
import PropTypes from "prop-types";
import React, { Component } from "react";

import * as Monitoring from "app/monitoring";
import { getCriticalRequestFailed, getAppFailed } from "app/selectors";
import Error from "app/ui/Error";
import chunkLoadErrorTest from "app/utils/chunkLoadErrorTest";
import connected from "connected";

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, chunkLoadError: chunkLoadErrorTest(error) };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false, chunkLoadError: false };
  }

  componentDidCatch = (error) => {
    Monitoring.addError(error);
  };

  render() {
    const {
      FallbackComponent,
      hasCriticalRequestFailed,
      hasAppFailed,
      ...restProps
    } = this.props;

    if (hasCriticalRequestFailed || this.state.chunkLoadError) {
      const id = hasCriticalRequestFailed
        ? "critical-request-failed-error-boundary"
        : "chunk-load-error-boundary";
      return (
        <div id={id}>
          <FallbackComponent
            {...restProps}
            titleText="Yikes, we hit a network snag!"
            ctaText="Reload Page"
          />
        </div>
      );
    }

    if (hasAppFailed || this.state.hasError) {
      return (
        <div id="default-error-boundary">
          <FallbackComponent {...restProps} />
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  FallbackComponent: PropTypes.func,
  hasCriticalRequestFailed: PropTypes.bool,
  hasAppFailed: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  FallbackComponent: Error,
};

function mapStateToProps(state) {
  return {
    hasCriticalRequestFailed: getCriticalRequestFailed(state),
    hasAppFailed: getAppFailed(state),
  };
}

const enhanced = connected(mapStateToProps);

export default enhanced(ErrorBoundary);
