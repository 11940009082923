import _get from "lodash/get";

import { VisitorStatusPayload } from "app/types/api/friendbuy";
import {
  APIType,
  FRIEND_TAGS,
  LogEventCode,
  ReferralAttributes,
} from "app/types/state/friendbuy";
import * as Monitoring from "app/monitoring";
import { LogLevel } from "app/types/monitoring";

export const isValidReferral = (
  referral: ReferralAttributes | null
): boolean => {
  return (
    !!referral?.campaignId &&
    !!referral?.referralCode &&
    !referral?.isSelfReferral
  );
};

const getReferralFromVisitorStatus = (
  visitorStatus: VisitorStatusPayload,
  apiType = APIType.NEXTGEN
): ReferralAttributes => {
  const isSelfReferral: boolean = _get(
    visitorStatus,
    "payload.isSelfReferral",
    false
  );
  const referralCode: string | null = _get(
    visitorStatus,
    "payload.referralCode",
    null
  );
  const attributionId: string | null = _get(
    visitorStatus,
    "payload.attributionId",
    null
  );
  const campaignId: string | null = _get(
    visitorStatus,
    "payload.campaignId",
    null
  );
  const referringUserId: string | null = _get(
    visitorStatus,
    "payload.advocate.customerId",
    null
  );

  const logWarning = (message: string) => {
    Monitoring.sendLog({
      tags: FRIEND_TAGS,
      level: LogLevel.WARN,
      message,
      apiType,
      visitorStatus,
      campaignId,
      attributionId,
      eventCode: LogEventCode.VISITOR_STATUS_VALIDATION,
    });
  };

  const logInfo = (message: string) => {
    Monitoring.sendLog({
      tags: FRIEND_TAGS,
      level: LogLevel.INFO,
      message,
      apiType,
      visitorStatus,
      campaignId,
      attributionId,
      eventCode: LogEventCode.VISITOR_STATUS_INFO,
    });
  };

  if (isSelfReferral) {
    logInfo("Visitor status self referral");

    // If attributionId is present, we should have additional data points
    // We've had instances where we *should* have a valid referral
    // but are missing referralCode and/or campaignId
    // So far observed when in incognito window only, but surface for visibility
  } else if (attributionId) {
    if (!campaignId) {
      logWarning("No campaignId found in visitor status response");
    }
    if (!referralCode) {
      logWarning("No referralCode found in visitor status response");
    }
    if (!referringUserId) {
      logWarning("No referringUserId found in visitor status response");
    }
  }

  return {
    referralCode,
    campaignId,
    referringUserId,
    isSelfReferral,
  };
};

export default getReferralFromVisitorStatus;
