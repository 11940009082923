import Grow from "@material-ui/core/Grow";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import MenuList, { MenuListProps } from "@material-ui/core/MenuList";
import Popper, { PopperProps } from "@material-ui/core/Popper";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { toRem } from "app/styles/utils";

// Individual item in a popout menu
// Built on https://material-ui.com/api/menu-item/#menuitem-api

export const Item: React.FC<MenuItemProps> = ({ button, ...props }) => {
  // only pass button if true (MUI bug)
  return <MenuItem {...(button && { button })} {...props} />;
};

// Some default styles, but mostly left up to implementation
export const PopoutMenuItem = styled(Item)`
  font: ${({ theme }) =>
    `${theme.fonts.weight.normal} ${toRem(14)} ${theme.fonts.body}`};
  padding: ${toRem(14)} ${toRem(32)} ${toRem(14)} ${toRem(25)};
  justify-content: flex-start;
`;

const PopoutHeader = styled(PopoutMenuItem)`
  border-bottom: ${({ theme }) => theme.borders.dark};
`;

const PopoutFooter = styled(PopoutMenuItem)`
  border-top: ${({ theme }) => theme.borders.dark};
`;

// The menu component itself
// Built on https://material-ui.com/api/menu-list/#menulist-api
// Automatically adjusts to largest items

interface PopoutMenuProps extends MenuListProps {
  handleClose: () => void;
  // use the caret
  hasCaret?: boolean;
  // number of pixels to move the caret off center
  // by default, the caret will appear in the center of the element
  // to move the caret left, this number will be negative
  // to move the caret right, positive
  caretOffset?: number;
  accentColor?: "gray" | "pink";
}

interface PopoutComposition {
  Header?: React.ReactNode;
  Footer?: React.ReactNode;
}

const PopoutMenuList: React.FC<PopoutMenuProps> & PopoutComposition = ({
  handleClose,
  autoFocusItem = true,
  id,
  ...props
}) => {
  const menuRef = useRef<any>(null);
  // For refocusing on anchor after escape
  const [anchor, setAnchor] = useState<HTMLElement>();

  // Handle click outside of menu if the click is outside of the menu element
  const handleClickOutside = (clickEvent: MouseEvent) =>
    !menuRef?.current?.contains(clickEvent.target) && handleClose();

  const handleEscape = () => {
    // when exiting via keyboard, refocus on anchor (if it's there)
    // eslint-disable-next-line no-unused-expressions
    anchor?.focus();
    return handleClose();
  };

  // Listen for click outside of menu
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener(
      "keydown",
      (keyEvent: KeyboardEvent) => keyEvent.key === "Escape" && handleEscape()
    );
    // Finding our anchor element in dom
    const anchorEl = document.querySelector(
      `[aria-controls="${id}"]`
    ) as HTMLElement;
    setAnchor(anchorEl);
    return () => {
      // cleanup
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener(
        "keydown",
        (keyEvent: KeyboardEvent) => keyEvent.key === "Escape" && handleEscape()
      );
    };
    // Want to update when the anchor element is available
  }, [anchor]);
  return (
    <MenuList {...props} ref={menuRef} autoFocusItem={autoFocusItem} id={id} />
  );
};

// Custom style props we don't want to pass to the base MUI component
const styleOnlyProps = ["hasCaret", "caretOffset", "accentColor"];

export const PopoutMenu = styled(PopoutMenuList).withConfig({
  shouldForwardProp: (prop) => !styleOnlyProps.includes(prop),
})`
  border-radius: ${toRem(4)};
  border: none;
  box-shadow: ${({ theme }) =>
    `${theme.boxShadows.right}, ${theme.boxShadows.bottom}, ${theme.boxShadows.left}`};
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 0;
  padding-bottom: 0;
  ${({ hasCaret, theme, caretOffset = 0, accentColor = "gray" }) => {
    const accent =
      accentColor === "gray" ? theme.colors.gray : theme.colors.pink;
    return (
      hasCaret &&
      `
      top: ${toRem(15)};
      border-top: ${toRem(5)} solid ${accent};
      &::before {
        content:'';
        position: absolute;
        height: ${toRem(20)};
        width: ${toRem(20)};
        left: calc((50% - ${toRem(8)}) + ${toRem(caretOffset)});
        top: -${toRem(20)};

        // rounded caret values
        background: linear-gradient(to bottom right,${accent} 50%,transparent 50%);
        border-radius: ${toRem(2)};
        transform: rotate(45deg);
        transform-origin: 0;
    }
  `
    );
  }};
`;

PopoutMenu.Header = PopoutHeader;
PopoutMenu.Footer = PopoutFooter;

// Menu container that controls positioning and visibility
// Built on https://material-ui.com/api/popper/#popper-api

export const Popout: React.FC<PopperProps> = ({
  open,
  anchorEl,
  children,
  // popout location in relation to anchor
  placement = "bottom",
  transition = true,
  // defaults to tooltip, which is not always what we want
  role = undefined,
  ...props
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal
      placement={placement}
      transition={transition}
      role={role}
      {...props}
    >
      {({ TransitionProps }) => (
        <Grow in={open} {...TransitionProps}>
          {children as React.ReactElement}
        </Grow>
      )}
    </Popper>
  );
};
