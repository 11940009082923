/*
Note this should return promises (or async/await) so it can be consumed directly from components
*/
import algoliasearch from "algoliasearch/lite";
import chunk from "lodash/chunk";

import { config } from "app/config";
import { getRankedIndex, getVariantsIndex } from "app/constants";

const searchClient = algoliasearch(
  config.get("algolia.app_id"),
  config.get("algolia.key")
);

const getTagFilters = (tagIds: string[]) => {
  return ` AND ( ${tagIds
    .map((tag) => `tags.tagId: "${tag}"`)
    .join(" AND ")} )`;
};

const getCategoryFilters = (catIds: string[]) => {
  return ` AND ( ${catIds
    .map((cat) => `categories.categoryId: "${cat}"`)
    .join(" AND ")} )`;
};

const getShelfFilters = (shelfIds: string[]) => {
  return ` AND ( ${shelfIds
    .map((shelf) => `shelves.shelfId: "${shelf}"`)
    .join(" AND ")} )`;
};

export function buildAlgoliaFilterString({
  packDate,
  fc,
  tagIds,
  categoryIds,
  shelfIds,
  priceZoneId,
}: {
  packDate: string;
  fc: string;
  tagIds?: string[];
  categoryIds?: string[];
  shelfIds?: string[];
  priceZoneId: string;
}) {
  let filters = `date: ${packDate} AND fc: ${fc} AND priceZoneId: ${priceZoneId}`;

  if (categoryIds && categoryIds.length) {
    filters += getCategoryFilters(categoryIds);
  }
  if (tagIds && tagIds.length) {
    filters += getTagFilters(tagIds);
  }
  if (shelfIds && shelfIds.length) {
    filters += getShelfFilters(shelfIds);
  }

  return filters;
}

const singleQueryForMultiQuery = (
  indexName: string,
  filters: string,
  attributesToRetrieve: string[],
  analyticsTags: string[],
  responseFields: string[],
  clickAnalytics: boolean
) => ({
  indexName,
  query: "",
  params: {
    filters,
    attributesToRetrieve,
    analyticsTags,
    attributesToHighlight: [],
    responseFields,
    clickAnalytics,
    hitsPerPage: 1000,
  },
});

export type OfferingIdType = "product" | "variant";

export interface AlgoliaMultiQueryOfferingIds {
  type: OfferingIdType;
  ids: string[];
}

export const createOfferingListFilterString = (
  filters: string,
  offeringIds: AlgoliaMultiQueryOfferingIds
) => {
  const offeringIdString = offeringIds.ids.reduce(
    (acc, offeringId, index) =>
      `${acc}${offeringIds.type}Id: ${offeringId}${
        index < offeringIds.ids.length - 1 ? " OR " : ""
      }`,
    ""
  );
  return filters ? `${filters} AND ${offeringIdString}` : offeringIdString;
};

export class OfferingsForDatesClient {
  private env: string;

  constructor({ env }: { env: string }) {
    if (!env) throw new Error("Must provide env to the constructor");
    this.env = env;
  }

  clearCache = () => searchClient.clearCache();

  search = ({
    query = "",
    filters,
    facets = [
      "categories.categoryId",
      "categories.name",
      "tags.name",
      "tags.tagId",
    ],
    attributesToRetrieve = ["*"],
    analyticsTags = [],
    clickAnalytics = true,
    enableReRanking = false,
  }: {
    query?: string;
    filters: string;
    facets?: string[];
    attributesToRetrieve?: string[];
    analyticsTags?: string[];
    clickAnalytics?: boolean;
    enableReRanking?: boolean;
  }) => {
    const indexName =
      query.length > 0 ? getVariantsIndex(this.env) : getRankedIndex(this.env);
    const index = searchClient.initIndex(indexName);
    return index.search(query, {
      attributesToRetrieve,
      filters,
      facets,
      analyticsTags,
      clickAnalytics,
      hitsPerPage: 1000,
      enableReRanking,
      // page,
      // analytics,
      // "getRankingInfo": true, // ??
      // responseFields, // can exclude however visibility is represented
    });
  };

  allOfferingsMultiQuery = ({ filters }: { filters: string }) => {
    const indexName = getRankedIndex(this.env);
    // Algolia has a max hit count of 1000 per page. To ensure that we can safely
    // scale beyond that, we are fetching two pages per request.
    // The second page will return an empty list until we have over 1000 SKUs,
    // but will not affect performance.
    const queries = [...Array(2).keys()].map((_, index) => ({
      analyticsTags: [],
      attributesToRetrieve: [
        "*",
        "-pickingLocation",
        "-containerSize",
        "-baseUnitMultiplier",
        "-shelves.shelfName",
        "-categories.commonId",
        "-categories.name",
        "-categories.parentId",
        "-categories.thumbnailUrl",
        "-priceZoneId",
        "-priceZoneName",
        "-tags.commonId",
      ],
      clickAnalytics: true,
      enableReRanking: false,
      facets: [
        "categories.categoryId",
        "categories.name",
        "tags.name",
        "tags.tagId",
      ],
      filters,
      hitsPerPage: 1000,
      indexName,
      page: index,
    }));
    return searchClient.search(queries);
  };

  shelfMultiQuery = ({
    shelfIds = [],
    filters,
    attributesToRetrieve = ["variantId"],
    analyticsTags = [],
    responseFields = ["*"],
    clickAnalytics = true,
  }: {
    shelfIds?: string[];
    filters?: string;
    attributesToRetrieve?: string[];
    analyticsTags?: string[];
    responseFields?: string[];
    clickAnalytics?: boolean;
  }) => {
    const indexName = `${this.env}_variants`;
    const chunkedMultiQueryPayload = chunk(
      shelfIds.map((shelfId) =>
        singleQueryForMultiQuery(
          indexName,
          filters
            ? `${filters} AND shelves.shelfId: ${shelfId}`
            : `shelves.shelfId: ${shelfId}`,
          attributesToRetrieve,
          analyticsTags,
          responseFields,
          clickAnalytics
        )
      ),
      50
    );
    return Promise.all(
      chunkedMultiQueryPayload.map((multiQueryPayload) => {
        return searchClient.search(multiQueryPayload);
      })
    );
  };

  // Sends multiple queries to the variants index -- one per category
  // Note: This is not chunked to work around the Algolia limit of 50 queries per multiquery request
  categoryMultiQuery = ({
    categoryIds = [],
    filters,
    attributesToRetrieve = [],
    analyticsTags = [],
    responseFields = ["*"],
    clickAnalytics = true,
  }: {
    categoryIds?: string[];
    filters: string;
    attributesToRetrieve?: string[];
    analyticsTags?: string[];
    responseFields?: string[];
    clickAnalytics?: boolean;
  }) => {
    const indexName = `${this.env}_variants`;
    const multiQueryPayload = categoryIds.map((categoryId) =>
      singleQueryForMultiQuery(
        indexName,
        `${filters} AND categories.categoryId: ${categoryId}`,
        attributesToRetrieve,
        analyticsTags,
        responseFields,
        clickAnalytics
      )
    );
    return searchClient.search(multiQueryPayload);
  };

  /**
   * Multi query to fetch offerings from Algolia for lists of
   * offering ids in the shape of AlgoliaMultiQueryOfferingIds
   */
  offeringListMultiQuery = ({
    offeringIdLists,
    filters,
    attributesToRetrieve = ["variantId"],
    analyticsTags = [],
    responseFields = ["*"],
    clickAnalytics = true,
  }: {
    offeringIdLists: AlgoliaMultiQueryOfferingIds[];
    filters: string;
    attributesToRetrieve?: string[];
    analyticsTags?: string[];
    responseFields?: string[];
    clickAnalytics?: boolean;
  }) => {
    const indexName = `${this.env}_variants`;
    const chunkedMultiQueryPayload = chunk(
      offeringIdLists.map((offeringIds) =>
        singleQueryForMultiQuery(
          indexName,
          createOfferingListFilterString(filters, offeringIds),
          attributesToRetrieve,
          analyticsTags,
          responseFields,
          clickAnalytics
        )
      ),
      50
    );
    return Promise.all(
      chunkedMultiQueryPayload.map((multiQueryPayload) => {
        return searchClient.search(multiQueryPayload);
      })
    );
  };
}

export const algoliaOfferingsClient = new OfferingsForDatesClient({
  env: config.get("algolia.env"),
});
