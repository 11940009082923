import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import breakpoints, {
  breakpointsNonScaling,
  sizes,
} from "app/styles/breakpoints";
import { SIDE_NAV_WIDTH } from "app/ui/shopping/Navigation/SideNav";

import EndCap, { ProfileType, SLIM_TEXT_HEIGHT } from "./EndCap";
import useAnchorIdHash from "./useAnchorIdHash";

export interface CatalogAisleHeaderProps {
  columns: number;
  aisleId: string;
  aisleName: string;
  aisleDescription: string;
  heroImageFilename: string;
  style?: React.CSSProperties;
  updateHash?: _.DebouncedFunc<(anchorId: string) => void>;
  viewportRef?: React.RefObject<HTMLDivElement>;
}

const CatalogAisleHeader: React.FC<CatalogAisleHeaderProps> = React.memo(
  ({
    columns,
    aisleId,
    aisleName,
    aisleDescription,
    heroImageFilename,
    style,
    updateHash,
    viewportRef,
  }) => {
    const ref = useAnchorIdHash(aisleId, updateHash, viewportRef);
    const profileType = columns > 2 ? ProfileType.REGULAR : ProfileType.SLIM;

    return (
      <ListItemContainer ref={ref} style={style} $columns={columns}>
        <ListItemInnerContainer $profileType={profileType}>
          <EndCap
            header={aisleName}
            description={aisleDescription}
            imageUrl={heroImageFilename}
            imageHeight={getImageHeight(columns)}
            profileType={profileType}
          />
        </ListItemInnerContainer>
      </ListItemContainer>
    );
  }
);

export default CatalogAisleHeader;

const DESKTOP_PADDING_TOP = 48;
const PADDING_BOTTOM = 24;

const ListItemContainer = styled.div<{ $columns: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${rem(PADDING_BOTTOM)};

  ${({ $columns }) =>
    $columns > 2 && `padding-top: ${rem(DESKTOP_PADDING_TOP)}`};
`;

const ListItemInnerContainer = styled.div<{ $profileType: ProfileType }>`
  width: 100%;
  max-width: ${rem(sizes.xxxl)};

  ${({ $profileType }) =>
    breakpoints.md`padding: 0 ${rem(
      $profileType === ProfileType.REGULAR ? 16 : 0
    )}`};

  ${breakpointsNonScaling.xl`
    padding: 0 ${rem(48)} 0 ${SIDE_NAV_WIDTH + 64}px;
  `};
`;

export interface CatalogAisleHeaderToken {
  component: typeof CatalogAisleHeader;
  componentProps: CatalogAisleHeaderProps;
  data: {
    anchorId: string;
    updateHash: boolean;
  };
  listItemHeight: number;
}

const getImageHeight = (columns: number) => {
  return columns > 2 ? 240 : 200;
};

export const calculateAisleHeaderHeight = (columns: number) => {
  if (columns > 2) {
    return getImageHeight(columns) + PADDING_BOTTOM + DESKTOP_PADDING_TOP;
  }
  return getImageHeight(columns) + SLIM_TEXT_HEIGHT;
};

export const getCatalogAisleHeaderToken = ({
  columns,
  aisleId,
  aisleName,
  aisleDescription,
  heroImageFilename,
}: CatalogAisleHeaderProps): CatalogAisleHeaderToken => ({
  component: CatalogAisleHeader,
  componentProps: {
    columns,
    aisleId,
    aisleName,
    aisleDescription,
    heroImageFilename,
  },
  data: {
    anchorId: aisleId,
    updateHash: true,
  },
  listItemHeight: calculateAisleHeaderHeight(columns),
});
