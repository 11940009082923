// @ts-nocheck
import queryString from "query-string";
import { generatePath } from "react-router";

import { config } from "app/config";
import {
  JOIN_PLUS_MODAL_ID,
  OUT_OF_STOCK_MODAL_ID,
  WAITLIST_MODAL_ID,
  PACKAGING_RETURN_MODAL_ID,
  EXPLORE_ADDONS_MODAL,
} from "app/constants/modals";
import history from "app/router/history";
import routes from "app/router/routes.json";
import { removeFilter, applyFilter } from "app/ui/global/utils";
import { CatalogPreviewToolData } from "app/types/state/ui";

export const goBack = () => history.goBack();

// extracts stringified "search" query params into query object
export const addLocationQuery = (location) => {
  return {
    ...location,
    query: location.search ? queryString.parse(location.search) : {},
  };
};

// turns query object back into stringified "search" location param
export const parseLocationQuery = (location) => {
  if (location.query) {
    return {
      ...location,
      search: queryString.stringify(location.query),
    };
  }
  return location;
};

// supports the following connected-react-router historyActions: push, replace, go, goBack, goForward
export const goTo = (route, historyAction = "push") => {
  const location = {
    ...route,
    ...(route.query && {
      search: queryString.stringify(route.query),
    }),
  };
  return history[historyAction](location);
};

export const toCloseModal = () => {
  const currentLocation = addLocationQuery(history.location);
  let hash = "";
  const parsedHash = queryString.parse(currentLocation.hash);
  if (parsedHash.shelf) {
    hash = queryString.stringify({ shelf: parsedHash.shelf });
  }
  return {
    ...currentLocation,
    hash,
  };
};

export const goToCloseModal = () => history.push(toCloseModal());

function getHashWithModalShelfVariant(parsedHash, MODAL_ID, variantId) {
  return queryString.stringify({
    shelf: parsedHash.shelf,
    modal: MODAL_ID,
    variantId,
  });
}

const toModal = (modalId: string) => {
  const currentLocation = addLocationQuery(history.location);
  const hash = `#modal=${modalId}`;

  return {
    ...currentLocation,
    hash,
  };
};

export const toPackagingReturnModal = () => {
  return toModal(PACKAGING_RETURN_MODAL_ID);
};

export const toWaitlistModal = () => {
  return toModal(WAITLIST_MODAL_ID);
};

export const toOutOfStockModal = () => {
  const currentLocation = addLocationQuery(history.location);
  let hash = `#modal=${OUT_OF_STOCK_MODAL_ID}`;
  const parsedHash = queryString.parse(currentLocation.hash);

  if (parsedHash.shelf) {
    hash = getHashWithModalShelfVariant(parsedHash, OUT_OF_STOCK_MODAL_ID);
  }

  return {
    ...currentLocation,
    hash,
  };
};

export const toJoinPlusModal = () => {
  return toModal(JOIN_PLUS_MODAL_ID);
};

export const toRemovedFilter = ({ taxonomyId, taxonomy }) => {
  const location = addLocationQuery(history.location);
  return removeFilter(taxonomyId, taxonomy, location);
};

export const goToRemovedFilter = (props) => goTo(toRemovedFilter(props));

export const toToggledShoppingFilter = ({ taxonomyId, taxonomy, selected }) => {
  const location = addLocationQuery(history.location);
  const to = selected
    ? removeFilter(taxonomyId, taxonomy, location)
    : applyFilter(taxonomyId, taxonomy, location);
  return to;
};

export const toHelpPath = (path = "/") => {
  return `https://help.${config.get(
    "endpoints.website_domain"
  )}/hc/en-us${path}`;
};

export const toBlogPath = (path = "/") => {
  return `https://blog.${config.get("endpoints.website_domain")}${path}`;
};

export const shoppingLocation = {
  pathname: routes.shopping.url,
};

export const signupShoppingLocation = {
  pathname: routes.signupShopping.url,
};

export const toShoppingPage = (isInSignupShopping = false) => {
  return {
    ...(isInSignupShopping ? signupShoppingLocation : shoppingLocation),
    hash: history.location.hash,
    query: history.location.query,
  };
};

export const goToShoppingPage = () => goTo(toShoppingPage());

export const toAccountAddonModal = (boxId) => {
  return `${routes.account.url}#modal=${EXPLORE_ADDONS_MODAL}&boxId=${boxId}`;
};

export const toAisle = (props) => {
  const {
    shopAllClicked,
    tagPath = "",
    aisle,
    shelf,
    tags,
    isInSignupShopping = false,
  } = props;
  const pathname = isInSignupShopping
    ? routes.signupShopping.url
    : routes.shopping.url;
  let newLocation;

  const search = tags?.length
    ? queryString.stringify({ tag: tags[0].tagId })
    : tagPath;

  if (shopAllClicked) {
    newLocation = {
      pathname: routes.shopAll.url,
      search,
    };
  } else if (shelf) {
    newLocation = {
      pathname,
      search,
      hash: `#${queryString.stringify({
        shelf: shelf.shelfId,
      })}`,
    };
  } else {
    newLocation = {
      pathname,
      search,
      hash: queryString.stringify({ shelf: aisle.aisleId }),
    };
  }

  return newLocation;
};

export const goToAisle = (props) => goTo(toAisle(props));

interface ToAisleShelfAnchorProps {
  shelfId?: string;
  tagId?: string;
  isInSignupShopping?: boolean;
}

export const toAisleShelfAnchor = ({
  shelfId,
  tagId,
  isInSignupShopping = false,
}: ToAisleShelfAnchorProps) => {
  const baseLocation = isInSignupShopping
    ? {
        ...signupShoppingLocation,
        hash: "",
      }
    : {
        ...shoppingLocation,
        hash: "",
      };

  if (!shelfId) return baseLocation;

  return {
    ...baseLocation,
    hash: queryString.stringify({
      ...queryString.parse(baseLocation.hash),
      shelf: shelfId,
    }),
    state: {
      scrolling: true,
    },
    ...(tagId && {
      search: queryString.stringify({ tag: tagId }),
    }),
  };
};

export const goToAisleShelfAnchor = (props) =>
  goTo(toAisleShelfAnchor(props), "replace");

export const getShoppingButtonDestination = (location) => {
  if (location.pathname === routes.shopping.url) return null;
  return routes.shopping.url;
};

export const toShopAll = ({ tagId }) => ({
  pathname: routes.shopAll.url,
  hash: queryString.stringify({
    ...queryString.parse(history.location.hash),
    shelf: "",
  }),
  ...(tagId && {
    search: queryString.stringify({ tag: tagId }),
  }),
});

export const toShopProductDetailPage = ({
  variantId,
  fromShelfId,
  isInSignupShopping = false,
}: {
  variantId: string;
  fromShelfId?: string;
  isInSignupShopping?: boolean;
}) => {
  const hash = fromShelfId ? queryString.stringify({ shelf: fromShelfId }) : "";
  const basePath = isInSignupShopping
    ? routes.signupShoppingProductDetailPage.url
    : routes.shopProductDetailPage.url;
  return {
    pathname: variantId ? generatePath(basePath, { variantId }) : "",
    hash,
  };
};

export const toCatalogAnchor = ({
  anchorId,
  tagId,
  catalogPreviewData,
  isInSignupShopping,
}: {
  anchorId: string;
  tagId?: string;
  catalogPreviewData?: CatalogPreviewToolData;
  isInSignupShopping: boolean;
}) => {
  const newShoppingLocation = isInSignupShopping
    ? signupShoppingLocation
    : shoppingLocation;
  return {
    ...newShoppingLocation,
    hash: queryString.stringify({
      shelf: anchorId,
    }),
    ...((tagId || catalogPreviewData) && {
      search: queryString.stringify({
        ...{ tag: tagId },
        ...catalogPreviewData,
      }),
    }),
  };
};

export const goToCatalogAnchor = ({
  anchorId,
  tagId,
  catalogPreviewData,
  isInSignupShopping,
}) =>
  goTo(
    toCatalogAnchor({
      anchorId,
      tagId,
      catalogPreviewData,
      isInSignupShopping,
    }),
    "replace"
  );
