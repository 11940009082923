import { Backdrop, Dialog } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { updateVariantQuantity } from "app/reducers/orders";
import { RemoveOutOfStockDialog as RemoveOutOfStockDialogType } from "app/types/ui/Dialog";
import ConfirmationDialog from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";

export interface RemoveOutOfStockDialogProps {
  dialog: RemoveOutOfStockDialogType;
}

const RemoveOutOfStockDialog: React.FC<RemoveOutOfStockDialogProps> = ({
  dialog,
}) => {
  const dispatch = useDispatch();

  const { open, handleClose, handleExited } = useHandleTransitions(dialog);

  const handlePrimaryButtonClick = () => {
    dispatch(
      updateVariantQuantity({
        quantity: dialog.quantity,
        shelfId: dialog.shelfId,
        source: dialog.source,
        variantId: dialog.variantId,
        rank: dialog.rank,
      })
    );

    handleClose();
  };

  return (
    <Dialog
      BackdropComponent={StyledBackdrop}
      maxWidth={false}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <ConfirmationDialog
        headerText="Are you sure you want to remove it?"
        onClearIconButtonClick={handleClose}
        onPrimaryButtonClick={handlePrimaryButtonClick}
        onSecondaryButtonClick={handleClose}
        overlineText="Low Inventory"
        primaryButtonText="Remove it"
        secondaryButtonText="Keep it"
        subcopyText={
          <>
            <OfferingName>{dialog.offeringName}</OfferingName> is currently sold
            out. If you remove this item from your cart, you won&apos;t be able
            to add it back.
          </>
        }
      />
    </Dialog>
  );
};

export default RemoveOutOfStockDialog;

const StyledBackdrop = styled(Backdrop)`
  backdrop-filter: unset;
`;

const OfferingName = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
`;
