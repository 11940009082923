import { rem } from "polished";
import colorPrimitives from "app/styles/colors";
import fonts from "app/styles/fonts";
import { colorUtils } from "app/styles/utils";

const root = {
  color: colorPrimitives.white,
  borderRadius: rem(50),
  minWidth: rem(30),
  boxShadow: "none",
  padding: `${rem(12)} ${rem(32)}`,
};

export default root;

export const contained = {
  boxShadow: "none",
  backgroundColor: colorPrimitives.beet,
  color: colorPrimitives.white,
  "&:hover": {
    backgroundColor: colorPrimitives.eggplant,
    boxShadow: "none",
  },
  "&:disabled": {
    backgroundColor: colorPrimitives.barley,
    color: colorPrimitives.white,
  },
  "&:focus": {
    backgroundColor: colorPrimitives.eggplant,
    boxShadow: `0 0 0 3px ${colorUtils.alpha(colorPrimitives.beet, 0.2)}`,
  },
  "&:active": {
    backgroundColor: colorPrimitives.beet,
    color: colorPrimitives.white,
    boxShadow: "none",
  },
};

export const outlined = {
  backgroundColor: colorPrimitives.white,
  color: colorPrimitives.plum,
  padding: `${rem(10)} ${rem(32)}`,
  border: `2px solid ${colorPrimitives.barley}`,
  "&:hover": {
    backgroundColor: colorPrimitives.oat,
    border: `2px solid ${colorPrimitives.eggplant}`,
  },
  "&:disabled": {
    color: colorPrimitives.barley,
    backgroundColor: colorPrimitives.white,
    border: `2px solid ${colorPrimitives.barley}`,
  },
  "&:focus": {
    border: `2px solid ${colorPrimitives.beet}`,
    backgroundColor: colorPrimitives.oat,
    boxShadow: `0 0 0 3px ${colorUtils.alpha(colorPrimitives.beet, 0.2)}`,
  },
  "&:active": {
    border: `2px solid ${colorPrimitives.beet}`,
    backgroundColor: colorPrimitives.white,
  },
};

export const sizeLarge = {
  padding: `${rem(12)} ${rem(48)}`,
  "& $label": {
    lineHeight: rem(32),
    fontSize: rem(18),
    fontWeight: fonts.weight.semiBold,
  },
};

export const sizeSmall = {
  padding: `${rem(8)} ${rem(24)}`,
  "& $label": {
    lineHeight: rem(24),
    fontSize: rem(14),
    fontWeight: fonts.weight.semiBold,
  },
};
