import { debounce } from "lodash";
import loadable from "@loadable/component";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import styled from "styled-components";

import {
  showCart,
  toggleCart,
  backToTopClicked,
  setDialog,
} from "app/reducers/ui";
import routes from "app/router/routes.json";
import {
  canShowPlusWidget,
  isCartVisible,
  isMobileDevice,
  getRouteMetadata,
  getPreviousRoutingLocation,
  getRoutingLocation,
  isSearching,
  getOrderedOfferingsWithSearch,
  getSearchResultInAllProducts,
  isLoadingNewSearchResults,
  getSearchQuery,
  getSelectedTags,
  getShopToolbarSelectedItem,
  isInNewFeatureModalTreatment,
} from "app/selectors";
import { BreakpointDirection, useXLargeScreen } from "app/styles/breakpoints";
import Header from "app/ui/header";
import CustomizeToast from "app/ui/shopping/CustomizeToast";
import CustomizeToastContainer from "app/ui/shopping/CustomizeToastContainer";
import { SearchProps } from "app/ui/shopping/Search/SearchProps";
import ShopCart from "app/ui/shopping/ShopCart";
import ShoppingNavigation from "app/ui/shopping/ShoppingNavigation";
import { PotentialState } from "app/types/state/extendedRouter";
import { isPathInUrlList, SHOPPING_ROUTES } from "app/constants/routes";
import { FallBack } from "app/ui/global/loadable";
import AddIngredientToast from "app/ui/shopping/ShoppableRecipes/AddIngredientToast";
import AppUpdateRequiredModal from "app/ui/global/modals/AppUpdateRequiredModal";
import { ShopToolbarIcons } from "./Navigation/ShopToolbar";
import { DialogType } from "app/types/ui/Dialog";
import { get as getCookie, set as setCookie } from "app/api/cookie";

const ShoppingRoutes = loadable(() => import("app/ui/shared/shoppingRoutes"));

const ShoppingRoot: React.FC = () => {
  const showPlusBanner = useSelector(canShowPlusWidget);
  const cartVisible = useSelector(isCartVisible);
  const isMobile = useSelector(isMobileDevice);
  const activeRoute = useSelector(getRouteMetadata);

  const [alertBannerHidden, setAlertBannerHidden] = useState(false);

  const xLargeScreen = useXLargeScreen(BreakpointDirection.UP);

  const selectedToolbarItem = useSelector(getShopToolbarSelectedItem);
  const filterActive = selectedToolbarItem === ShopToolbarIcons.FILTER;

  const hideAlertBanner = useCallback(
    debounce(
      (scrollOffset, scrollUpdateWasRequested) => {
        if (xLargeScreen || scrollUpdateWasRequested) return;

        if (scrollOffset > 0 && alertBannerHidden === false) {
          setAlertBannerHidden(true);
        }

        if (scrollOffset === 0 && alertBannerHidden === true && !filterActive) {
          setAlertBannerHidden(false);
        }
      },
      300,
      {
        leading: true,
        trailing: true,
      }
    ),
    [alertBannerHidden, xLargeScreen, filterActive]
  );

  const dispatch = useDispatch();

  const location = useSelector(getRoutingLocation);
  const previousLocation = useSelector(getPreviousRoutingLocation);

  const previouslyOnShoppingRoute = useRef(
    previousLocation?.pathname === undefined ||
      (previousLocation?.pathname &&
        isPathInUrlList(previousLocation?.pathname, SHOPPING_ROUTES))
  );

  const loadableFallback = previouslyOnShoppingRoute.current ? (
    <></>
  ) : (
    <FallBack />
  );

  const { state } = useLocation<PotentialState>();

  useEffect(() => {
    if (state?.shouldInitializeWithCartOpen) {
      dispatch(showCart());
    }
  }, []);

  useEffect(() => {
    if (xLargeScreen) {
      setAlertBannerHidden(false);
    }
  }, [xLargeScreen]);

  useEffect(() => {
    if (location.pathname !== previousLocation?.pathname) {
      setAlertBannerHidden(false);
    }
  }, [location]);

  const selectedTags = useSelector(getSelectedTags);
  const selectedTag =
    (selectedTags.length === 1 && selectedTags[0]) || undefined;
  const appliedFilterId = selectedTag?.id || "";

  const shopAllActive = activeRoute.url === routes.shopAll.url;
  const shopCatalogActive = activeRoute.url === routes.shopping.url;
  const shoppingNavigationEnabled = shopAllActive || shopCatalogActive;

  const offeringIds = useSelector(getOrderedOfferingsWithSearch);
  const allSearchResults = useSelector(getSearchResultInAllProducts);
  const isSearchingResult = useSelector(isSearching);
  const showSearchResults = isSearchingResult && allSearchResults !== null;
  const showNoSearchResults = showSearchResults && !offeringIds.length;
  const loading = useSelector(isLoadingNewSearchResults);
  const searchQuery = useSelector(getSearchQuery);
  const inNewFeatureModalTreatment = useSelector(isInNewFeatureModalTreatment);

  const newFeatureModalCookie = getCookie("newFeatureModal");

  const shouldDisplayNewFeatureModal =
    inNewFeatureModalTreatment && newFeatureModalCookie !== "opened";

  if (shouldDisplayNewFeatureModal) {
    dispatch(setDialog({ type: DialogType.NEW_FEATURE }));
    setCookie("newFeatureModal", "opened");
  }

  const searchProps: SearchProps = {
    isSearching: isSearchingResult,
    showSearchResults,
    showNoSearchResults,
    allSearchResults,
    offeringIds,
    loading,
    searchQuery,
  };

  return (
    <>
      {/* @ts-ignore */}
      <Header alertBannerHidden={alertBannerHidden} />
      <ShoppingNavigation
        enabled={shoppingNavigationEnabled}
        shopAllActive={shopAllActive}
        renderMobileNavigation={!xLargeScreen}
        isMobile={isMobile}
        searchProps={searchProps}
        showPlusBanner={showPlusBanner}
      />
      <Container data-testid="shop-container">
        <ToastProvider
          components={{
            ToastContainer: CustomizeToastContainer,
            Toast: CustomizeToast,
          }}
          autoDismiss
          autoDismissTimeout={6000} // in ms
        >
          <ShoppingRoutes
            appliedFilterId={appliedFilterId}
            backToTopClicked={(props) => dispatch(backToTopClicked(props))}
            fallback={loadableFallback}
            hideAlertBanner={hideAlertBanner}
            searchProps={searchProps}
          />
          <ShopCart
            cartVisible={cartVisible}
            toggleCart={() => dispatch(toggleCart())}
          />
          <AppUpdateRequiredModal />
        </ToastProvider>
        <AddIngredientToast />
      </Container>
    </>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default ShoppingRoot;
