import MuiButton from "@material-ui/core/Button";
import MuiClickAwayListener from "@material-ui/core/ClickAwayListener";
import MuiSnackbar from "@material-ui/core/Snackbar";
import MuiTooltip from "@material-ui/core/Tooltip";
import MuiAddRoundedIcon from "@material-ui/icons/AddRounded";
import MuiRemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import { rem } from "polished";
import React, { useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import styled, { css } from "styled-components";

import { SnackbarContainerId } from "app/ui/designSystem/molecules/Snackbar/SnackbarContainer";

export enum AlertType {
  TOOLTIP = "tooltip",
  SNACKBAR = "snackbar",
}

export interface QuantityButtonProps {
  entity: string; // the title of the value passed - this string is used for aria labels
  quantity: number;
  maxQuantity?: number;
  minQuantity?: number;
  handleDecrement: () => void;
  handleIncrement: () => void;
  hasStock?: boolean;
  activeParent?: boolean;
  alertType?: AlertType;
  buttonSize?: number;
  disabled?: boolean;
  expandedTimeout?: number;
  extendedExpandedTimeout?: number;
  hoverEffect?: boolean;
  remainExpanded?: boolean; // forces the quantity button to always be expanded
  maxQuantityMessage?: string;
}

enum QuantitySlide {
  INCREMENT = "increment",
  DECREMENT = "decrement",
}

const QuantityButton: React.FC<QuantityButtonProps> = ({
  activeParent,
  alertType = AlertType.TOOLTIP,
  buttonSize,
  disabled = false,
  expandedTimeout = 3000,
  extendedExpandedTimeout = 10000,
  handleDecrement,
  handleIncrement,
  hasStock = true,
  hoverEffect = true,
  maxQuantity = null,
  entity,
  quantity = 0,
  remainExpanded = false,
  maxQuantityMessage = `Limit of ${maxQuantity}`,
  minQuantity = 0,
}) => {
  const isExpandedOnMount = () => {
    if (remainExpanded) return true;
    return activeParent === undefined ? false : activeParent;
  };
  const [expanded, setExpanded] = useState(isExpandedOnMount());
  const [quantitySliding, setQuantitySliding] = useState<QuantitySlide | null>(
    null
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const timeoutId = useRef<number>();

  const incrementButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearCollapseTimeout();
      }
    };
  }, []);

  useEffect(() => {
    if (activeParent && quantity > 0) {
      setExpanded(true);
      if (timeoutId.current) {
        clearCollapseTimeout();
      }
    }
  }, [activeParent]);

  useEffect(() => {
    if (activeParent === false && expanded) {
      setCollapseTimeout();
    }
  }, [activeParent]);

  useEffect(() => {
    if (!expanded) {
      timeoutId.current = undefined;
    }
    if (incrementButtonRef?.current && !activeParent) {
      incrementButtonRef.current.focus();
    }
  }, [expanded]);

  const clearCollapseTimeout = () => {
    clearTimeout(timeoutId.current);
    timeoutId.current = undefined;
  };

  const setCollapseTimeout = (wasOpenedViaKeyboard?: boolean) => {
    const timeToCollapse = wasOpenedViaKeyboard
      ? extendedExpandedTimeout
      : expandedTimeout;
    timeoutId.current = window.setTimeout(() => {
      if (!remainExpanded) setExpanded(false);
      setSnackbarOpen(false);
    }, timeToCollapse);
  };

  const resetCollapseTimeout = () => {
    if (timeoutId.current) {
      clearCollapseTimeout();
      setCollapseTimeout();
    }
  };

  const handleClosedButtonClick = () => {
    if (!remainExpanded) setExpanded(true);
    setQuantitySliding(null);

    if (!activeParent) {
      // passing true as you cannot click with a mouse without being the active parent
      setCollapseTimeout(true);
    }
  };

  const displayAlert = () => {
    if (alertType === AlertType.SNACKBAR) {
      if (!snackbarOpen) {
        setSnackbarOpen(true);
      }
    }
    if (alertType === AlertType.TOOLTIP) {
      clearCollapseTimeout();
      setTooltipOpen(true);
    }
  };

  const hideMaxQuantityAlert = () => {
    setSnackbarOpen(false);
    setTooltipOpen(false);
  };

  const handleDecrementClick = () => {
    hideMaxQuantityAlert();
    handleDecrement();

    if (!hasStock) return;

    setQuantitySliding(QuantitySlide.DECREMENT);

    if (quantity > 1) {
      resetCollapseTimeout();
    } else if (!remainExpanded) {
      setExpanded(false);
      clearCollapseTimeout();
    }
  };

  const handleIncrementClick = () => {
    if (quantity === maxQuantity || !hasStock) {
      displayAlert();
      return;
    }

    setQuantitySliding(QuantitySlide.INCREMENT);
    handleIncrement();
    resetCollapseTimeout();
  };

  const handleTooltipClickAway = () => {
    if (tooltipOpen) {
      setCollapseTimeout();
      setTooltipOpen(false);
    }
  };

  const snackbarPortal = document.getElementById(SnackbarContainerId.ROOT);

  let alertText = "";
  if (quantity === maxQuantity) {
    alertText = maxQuantityMessage;
  }
  if (!hasStock) {
    alertText = "This item is out of stock";
  }

  const incrementItemAriaLabel = `Increment quantity of ${entity}`;
  const buttonClosedWithoutStock = quantity < 1 && !expanded && !hasStock;

  return (
    <QuantityButtonWrapper
      onClick={(e) => e.stopPropagation()}
      $buttonSize={buttonSize}
      $hoverEffect={hoverEffect && !buttonClosedWithoutStock}
      className="quantity-button-wrapper"
    >
      {quantity < 1 && !expanded && hasStock && (
        <ButtonClosed
          disabled={disabled || !hasStock}
          onClick={() => {
            handleIncrementClick();
            handleClosedButtonClick();
          }}
          onKeyPress={() => {
            handleIncrementClick();
            handleClosedButtonClick();
          }}
          $buttonSize={buttonSize}
          $hoverEffect={hoverEffect}
          aria-label={`Add ${entity}`}
          data-testid="button-closed"
          data-clickid="quantity-button"
        >
          <MuiAddRoundedIcon />
        </ButtonClosed>
      )}
      {buttonClosedWithoutStock && alertType === AlertType.TOOLTIP && (
        <IncrementControlTooltip
          arrow
          enterTouchDelay={0}
          placement="top-end"
          title={alertText}
        >
          <TooltipDisabledWrapper>
            <ButtonClosed
              disabled
              $buttonSize={buttonSize}
              $hoverEffect={hoverEffect}
              aria-label={`Add ${entity}`}
              data-testid="button-closed"
              data-clickid="quantity-button"
            >
              <MuiAddRoundedIcon />
            </ButtonClosed>
          </TooltipDisabledWrapper>
        </IncrementControlTooltip>
      )}
      {buttonClosedWithoutStock && alertType === AlertType.SNACKBAR && (
        <ButtonClosed
          $disabledWithSnackbar
          onClick={() => setSnackbarOpen(true)}
          $buttonSize={buttonSize}
          $hoverEffect={hoverEffect}
          aria-label={`Add ${entity}`}
          data-testid="button-closed"
          data-clickid="quantity-button"
        >
          <MuiAddRoundedIcon />
        </ButtonClosed>
      )}
      {quantity > 0 && !expanded && (
        <ButtonClosedWithQuantity
          disabled={disabled}
          onClick={handleClosedButtonClick}
          onKeyPress={handleClosedButtonClick}
          $buttonSize={buttonSize}
          $hoverEffect={hoverEffect}
          aria-label={`Edit quantity of ${entity}`}
          data-testid="button-closed-with-quantity"
        >
          {quantity}
        </ButtonClosedWithQuantity>
      )}
      {expanded && (
        <ButtonExpandedWrapper
          $buttonSize={buttonSize}
          $hoverEffect={hoverEffect}
          data-testid="button-expanded"
          data-clickid="quantity-button"
        >
          <ButtonExpandedControl
            disabled={quantity <= minQuantity}
            onClick={handleDecrementClick}
            aria-label={`Decrement quantity of ${entity}`}
          >
            <MuiRemoveRoundedIcon />
          </ButtonExpandedControl>
          <QuantityWrapper $quantity={quantity}>
            <QuantityOverflowMask>
              <IncrementQuantitySlider
                $sliding={quantitySliding === QuantitySlide.INCREMENT}
                aria-hidden="true"
              >
                {quantity}
              </IncrementQuantitySlider>
              <ButtonExpandedQuantity
                data-testid="quantity"
                $sliding={quantitySliding}
                aria-live="polite"
                onTransitionEnd={() => setQuantitySliding(null)}
              >
                {!quantitySliding && quantity}
                {quantitySliding === QuantitySlide.INCREMENT && quantity - 1}
                {quantitySliding === QuantitySlide.DECREMENT && quantity + 1}
              </ButtonExpandedQuantity>
              <DecrementQuantitySlider
                $sliding={quantitySliding === QuantitySlide.DECREMENT}
                aria-hidden="true"
              >
                {quantity}
              </DecrementQuantitySlider>
            </QuantityOverflowMask>
          </QuantityWrapper>
          {alertType === AlertType.TOOLTIP && (
            <MuiClickAwayListener onClickAway={handleTooltipClickAway}>
              <div style={{ zIndex: 1 }}>
                <IncrementControlTooltip
                  title={alertText}
                  open={tooltipOpen}
                  aria-label={alertText}
                  placement="top-end"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <TooltipDisabledWrapper
                    onClick={handleIncrementClick}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    data-testid="tooltip-disabled-wrapper"
                  >
                    <ButtonExpandedControl
                      disabled={quantity === maxQuantity || !hasStock}
                      onClick={handleIncrementClick}
                      aria-label={incrementItemAriaLabel}
                      ref={incrementButtonRef}
                    >
                      <MuiAddRoundedIcon />
                    </ButtonExpandedControl>
                  </TooltipDisabledWrapper>
                </IncrementControlTooltip>
              </div>
            </MuiClickAwayListener>
          )}
          {alertType === AlertType.SNACKBAR && (
            <ButtonExpandedControl
              $disabledWithSnackbar={quantity === maxQuantity || !hasStock}
              onClick={handleIncrementClick}
              aria-label={incrementItemAriaLabel}
            >
              <MuiAddRoundedIcon />
            </ButtonExpandedControl>
          )}
        </ButtonExpandedWrapper>
      )}
      <Portal node={snackbarPortal}>
        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={alertText}
          ContentProps={{ variant: "outlined" }}
          action={
            <CloseSnackbarButton onClick={() => setSnackbarOpen(false)}>
              Dismiss
            </CloseSnackbarButton>
          }
        />
      </Portal>
    </QuantityButtonWrapper>
  );
};

const ButtonSize = css<{ $buttonSize?: number }>`
  /* when over 32px, you can see the numbers above/below the current quantity.
  this additional padding hides them. will need to be adjusted if > 42px tall,
  but feels unlikely that that'd be necessary. */
  padding: ${({ $buttonSize }) =>
    $buttonSize && $buttonSize > 32 ? `${rem(4)} 0` : 0};
  height: ${rem(32)};
  min-width: ${rem(32)};
  ${({ $buttonSize }) =>
    $buttonSize &&
    `
    height: ${rem($buttonSize)};
    min-width: ${rem($buttonSize)};
  `};
`;

const QuantityButtonWrapper = styled.div<{
  $buttonSize?: number;
  $hoverEffect: boolean;
}>`
  ${ButtonSize};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: "";
    position: absolute;
    width: calc(100% + ${rem(2)});
    height: calc(100% + ${rem(2)});
    border: ${({ theme }) => `${rem(2)} solid ${theme.colors.purple}`};
    border-radius: ${rem(32)};
    transform: translate(${rem(0)}, ${rem(0)});
    opacity: 0;
    transition: 250ms opacity;
  }
  ${({ $hoverEffect }) =>
    $hoverEffect &&
    `
      &:hover::after {
          opacity: 1;
      }
  `}
`;

const ButtonBase = css<{
  $buttonSize?: number;
  $hoverEffect: boolean;
}>`
  ${ButtonSize};
  border: ${({ theme }) => `${rem(2)} solid ${theme.colors.pink}`};
  border-radius: ${rem(32)};
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${rem(16)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  ${({ $hoverEffect, theme }) =>
    $hoverEffect &&
    `
      &:hover,
      &:focus {
        border: ${rem(2)} solid ${theme.colors.eggplant};
        color: ${theme.colors.white};
        background-color: ${theme.colors.eggplant};
      }
      &:focus {
        box-shadow: ${theme.boxShadows.focus};
      }
  `}
`;

const ButtonClosed = styled(MuiButton)<{
  $buttonSize?: number;
  $disabledWithSnackbar?: boolean;
  $hoverEffect: boolean;
}>`
  ${ButtonBase};
  &:focus {
    border: ${rem(2)} solid ${({ theme }) => theme.colors.white};
    outline: ${rem(2)} solid ${({ theme }) => theme.colors.eggplant};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.barley};
    background-color: ${({ theme }) => theme.colors.barley};
  }

  ${({ $disabledWithSnackbar, theme }) =>
    $disabledWithSnackbar &&
    `
      color: ${theme.colors.white};
      border-color: ${theme.colors.barley};
      background-color: ${theme.colors.barley};

      &:hover, &:focus {
        border-color: ${theme.colors.barley};
        background-color: ${theme.colors.barley};
        box-shadow: none;
        outline: none;
      }
  `}
`;

const ButtonClosedWithQuantity = styled(MuiButton)`
  ${ButtonBase};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.pink};
  ${({ $hoverEffect, theme }) =>
    !$hoverEffect &&
    `
      &:hover {
        border: ${rem(2)} solid ${theme.colors.eggplant};
        color: ${theme.colors.white};
        background-color: ${theme.colors.eggplant};
      }
  `}
  &:focus {
    border: ${rem(2)} solid ${({ theme }) => theme.colors.white};
    outline: ${rem(2)} solid ${({ theme }) => theme.colors.eggplant};
  }
`;

const ButtonExpandedWrapper = styled.div`
  ${ButtonBase};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, $hoverEffect }) =>
    !$hoverEffect ? theme.colors.eggplant : theme.colors.pink};
  span {
    padding: ${rem(0)} ${rem(6)};
  }
`;

const IncrementControlTooltip = styled((props) => (
  <MuiTooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltipPlacementTop {
    margin: ${rem(32)} ${rem(0)} ${rem(12)} ${rem(0)};
  }
  & .MuiTooltip-tooltip {
    padding: ${rem(16)};
    font-size: ${rem(12)};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    line-height: ${rem(16)};
    background-color: ${({ theme }) => theme.colors.textBlack};
  }
  & .MuiTooltip-arrow {
    color: ${({ theme }) => theme.colors.textBlack};
  }
`;

const TooltipDisabledWrapper = styled.div`
  z-index: 1;
`;

const ButtonExpandedControl = styled(MuiButton)<{
  $disabledWithSnackbar?: boolean;
}>`
  width: ${rem(28)};
  height: ${rem(28)};
  min-width: unset;
  border-radius: ${rem(28)};
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    outline: ${rem(2)} solid transparent;
  }
  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.4;
  }
  ${({ $disabledWithSnackbar, theme }) =>
    $disabledWithSnackbar &&
    `
    color: ${theme.colors.white};
    opacity: 0.4;
  `}
`;

const QuantityWrapper = styled.div<{ $quantity: number }>`
  position: relative;
  ${({ $quantity }) => `width: ${rem($quantity.toString().length * 11 + 12)}`};
  height: 100%;
`;

const QuantityOverflowMask = styled.div`
  position: absolute;
  top: ${rem(-2)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% + ${rem(4)});
  overflow: hidden;
`;

const SLIDE_TRANSITION_DURATION = 0.2;

const ButtonExpandedQuantity = styled.div<{ $sliding: QuantitySlide | null }>`
  padding: ${rem(0)} ${rem(4)};
  transform: translateY(0);
  ${({ $sliding }) =>
    $sliding &&
    $sliding === QuantitySlide.INCREMENT &&
    `
      transform: translateY(${rem(24)});
      transition: transform ${SLIDE_TRANSITION_DURATION}s;
    `};
  ${({ $sliding }) =>
    $sliding &&
    $sliding === QuantitySlide.DECREMENT &&
    `
      transform: translateY(${rem(-24)});
      transition: transform ${SLIDE_TRANSITION_DURATION}s;
    `};
`;

const IncrementQuantitySlider = styled.span<{ $sliding: boolean }>`
  position: absolute;
  transform: translateY(${rem(-24)});
  user-select: none;
  ${({ $sliding }) =>
    $sliding &&
    `
      transform: translateY(${rem(0)});
      transition: transform ${SLIDE_TRANSITION_DURATION}s;
    `};
`;

const DecrementQuantitySlider = styled.span<{ $sliding: boolean }>`
  position: absolute;
  transform: translateY(${rem(24)});
  user-select: none;
  ${({ $sliding }) =>
    $sliding &&
    `
      transform: translateY(${rem(0)});
      transition: transform ${SLIDE_TRANSITION_DURATION}s;
    `};
`;

const Snackbar = styled(MuiSnackbar)`
  && {
    bottom: ${rem(68)};
    width: 100%;
    padding: 0 ${rem(16)};
  }

  & .MuiSnackbarContent-root {
    width: 100%;
    padding: ${rem(12)} ${rem(16)};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${rem(15)};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
    line-height: ${rem(24)};
    background-color: ${({ theme }) => theme.colors.peppercorn};
  }
`;

const CloseSnackbarButton = styled(MuiButton)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${rem(14)};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: ${rem(24)};
  text-transform: uppercase;
`;

export default QuantityButton;
