import { IconButton } from "@material-ui/core";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { getImageURL } from "app/ui/global/utils";

const getCartItemImageSrc = (filename: string) =>
  getImageURL(filename, null, {
    ar: "1:1",
    c: "fill",
    dpr: "auto",
    w: "auto",
  });

interface CartItemImage {
  alt: string;
  className?: string;
  filename: string | null;
  onRecurringClick: () => void;
  showRecurringIcon: boolean;
}

const CartItemImage: React.FC<CartItemImage> = ({
  alt,
  className,
  filename,
  onRecurringClick,
  showRecurringIcon,
}) => (
  <Container className={className}>
    {showRecurringIcon && (
      <StyledIconButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onRecurringClick();
        }}
      >
        <StyledSyncIcon />
      </StyledIconButton>
    )}
    <ImageContainer>
      {filename && <img alt={alt} src={getCartItemImageSrc(filename)} />}
    </ImageContainer>
  </Container>
);

export default CartItemImage;

const Container = styled.div`
  position: relative;
  display: flex;
`;

const ImageContainer = styled.div`
  display: inline-flex;
  width: ${rem(52)};
  height: ${rem(52)};
  border-radius: ${rem(4)};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.oat};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  transform: translate(${rem(-4)}, ${rem(-4)});
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(24)};
  height: ${rem(24)};
  padding: 0;
  background-color: ${({ theme }) => theme.colors.eggplant};
  z-index: 1;

  &:hover {
    background-color: ${({ theme }) => theme.colors.eggplant};
  }

  &:focus {
    box-shadow: ${({ theme }) => theme.boxShadows.focusWithOutline};
  }
`;

const StyledSyncIcon = styled(SyncRoundedIcon)`
  width: ${rem(20)};
  height: ${rem(20)};
  color: ${({ theme }) => theme.colors.marshmallow};
  transform: rotate(64deg) scaleY(-1);
`;
