/**
 * @function MaterialList
 */

import MaterialCollapse, {
  CollapseProps as MuiCollapseProps,
} from "@material-ui/core/Collapse";
import MaterialDivider, {
  DividerProps as MuiDividerProps,
} from "@material-ui/core/Divider";
import MaterialList, {
  ListProps as MuiListProps,
} from "@material-ui/core/List";
import MaterialListItem, {
  ListItemProps as MuiListItemProps,
} from "@material-ui/core/ListItem";
import MaterialListItemAvatar, {
  ListItemAvatarProps as MuiListItemAvatarProps,
} from "@material-ui/core/ListItemAvatar";
import MaterialListItemIcon, {
  ListItemIconProps as MuiListItemIconProps,
} from "@material-ui/core/ListItemIcon";
import MaterialListItemSecondaryAction, {
  ListItemSecondaryActionProps as MuiListItemSecondaryActionProps,
} from "@material-ui/core/ListItemSecondaryAction";
import MaterialListItemText, {
  ListItemTextProps as MuiListItemTextProps,
} from "@material-ui/core/ListItemText";
import MaterialListSubheader, {
  ListSubheaderProps as MuiListSubheaderProps,
} from "@material-ui/core/ListSubheader";
import React from "react";

export const List: React.FC<MuiListProps> = (muiProps: MuiListProps) => {
  return <MaterialList {...muiProps} />;
};

/*
Added the button breakout due to this issue:
https://github.com/mui-org/material-ui/issues/14971
https://github.com/mui-org/material-ui/pull/15049
*/
export const ListItem: React.FC<MuiListItemProps> = ({
  button,
  ...muiProps
}: MuiListItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <MaterialListItem button={button as any} {...muiProps} />;
};

export const ListItemText: React.FC<MuiListItemTextProps> = (
  muiProps: MuiListItemTextProps
) => {
  return <MaterialListItemText {...muiProps} />;
};

export const ListItemAvatar: React.FC<MuiListItemAvatarProps> = (
  muiProps: MuiListItemAvatarProps
) => {
  return <MaterialListItemAvatar {...muiProps} />;
};

export const ListItemIcon: React.FC<MuiListItemIconProps> = (
  muiProps: MuiListItemIconProps
) => {
  return <MaterialListItemIcon {...muiProps} />;
};

export const ListItemSecondaryAction: React.FC<MuiListItemSecondaryActionProps> = (
  muiProps: MuiListItemSecondaryActionProps
) => {
  return <MaterialListItemSecondaryAction {...muiProps} />;
};

export const ListSubheader: React.FC<MuiListSubheaderProps> = (
  muiProps: MuiListSubheaderProps
) => {
  return <MaterialListSubheader {...muiProps} />;
};

export const Divider: React.FC<MuiDividerProps> = (
  muiProps: MuiDividerProps
) => {
  return <MaterialDivider aria-hidden="true" {...muiProps} />;
};

export const Collapse: React.FC<MuiCollapseProps> = (
  muiProps: MuiCollapseProps
) => {
  return <MaterialCollapse {...muiProps} />;
};
