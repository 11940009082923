import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "orders";
const prefixes = {
  FETCH_ACTIVE_ORDER: `${domain}/FETCH_ACTIVE_ORDER`,
  FETCH_ORDER_SUMMARIES: `${domain}/FETCH_ORDER_SUMMARIES`,
  FETCH_ORDER: `${domain}/FETCH_ORDER`,
  AUTH_ACTIVE_ORDER: `${domain}/AUTH_ACTIVE_ORDER`,
  UPDATE_VARIANT_QUANTITY: `${domain}/UPDATE_VARIANT_QUANTITY`,
  UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF: `${domain}/UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF`,
  SET_ORDER_STATUS: `${domain}/SET_ORDER_STATUS`,
  VALIDATE_COUPON: `${domain}/VALIDATE_COUPON`,
  JALAPENO_VALIDATE_COUPON: `${domain}/JALAPENO_VALIDATE_COUPON`,
  REMOVE_COUPON: `${domain}/REMOVE_COUPON`,
  CREATE_AD_HOC_ORDER: `${domain}/CREATE_AD_HOC_ORDER`,
  ADD_BOXES_TO_ORDER: `${domain}/ADD_BOXES_TO_ORDER`,
  AUTO_SKIP_NEXT_ORDER: `${domain}/AUTO_SKIP_NEXT_ORDER`,
  FETCH_ORDER_POSIT: `${domain}/FETCH_ORDER_POSIT`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
  UPDATE_VARIANT_QUANTITY_CANCELED: `${domain}/UPDATE_VARIANT_QUANTITY_CANCELED`,
  REMOVE_PRODUCT_FROM_NEVER_LIST_SUGGESTIONS: `${domain}/REMOVE_PRODUCT_FROM_NEVER_LIST_SUGGESTIONS`,
  TOGGLE_NEVER_LIST_SUGGESTIONS: `${domain}/TOGGLE_NEVER_LIST_SUGGESTIONS`,
  INCREMENT_VARIANTS_QUANTITY: `${domain}/INCREMENT_VARIANTS_QUANTITY`,
};
