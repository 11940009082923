// @ts-nocheck
import { sanitizer } from "@imperfectproduce/node-logger";
import { routerMiddleware } from "connected-react-router";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

import initializeCookie from "app/api/cookie";
import { config } from "app/config";
import * as Monitoring from "app/monitoring";
import basicAsyncCallback from "app/middleware/basicAsyncCallback";
import extendedRouterMiddleware from "app/middleware/extendedRouter";
import formikSubmit from "app/middleware/formikSubmit";
import reconcileCatalogMiddleware from "app/middleware/reconcileCatalogMiddleware";
import reducers from "app/reducers";
import { appFailed } from "app/reducers/ui";
import history from "app/router/history";
import { addLocationQuery } from "app/router/routes";
import rootSaga from "app/sagas";

/*
 * https://github.com/ReactTraining/react-router/issues/4410
 */

const modifyHistory = (h) => {
  // eslint-disable-next-line no-param-reassign
  h.location = addLocationQuery(h.location);
  return h;
};
modifyHistory(history);
history.listen(() => modifyHistory(history));

export default function configureStore(header) {
  // TODO: server rendering will pass req.headers.cookie
  // https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#server-example
  initializeCookie(header);

  const isProduction = config.get("env") === "production";
  const isReduxLoggingEnabled = config.get("flags.logger_enabled");

  // 1) Build the middleware for intercepting and dispatching navigation actions
  const routeMiddleware = routerMiddleware(history);

  // 2) Create an alternative Side Effects middleware (aka Asynchronous Actions) for Redux.
  // Instead of dispatching Thunks which get handled by the redux-thunk middleware,
  // we create Sagas to gather all our Side Effects logic in a central place.
  const sagaMiddleware = createSagaMiddleware({
    onError: (err) => {
      Monitoring.addError(err);
      // Inspiration: https://github.com/redux-saga/redux-saga/issues/1698#issuecomment-444291868
      store.dispatch(appFailed());
    },
  });

  // 3) Middlewares execute in the order they're passed into applyMiddleware.
  // Loading from storage should be before the saga middleware
  // Logger should be at the end and only in dev https://github.com/evgenyrodionov/redux-logger/issues/20

  const middlewares = [
    routeMiddleware,
    extendedRouterMiddleware,
    formikSubmit,
    basicAsyncCallback,
    thunk,
    sagaMiddleware,
    reconcileCatalogMiddleware,
  ];

  if (isReduxLoggingEnabled) {
    const transform = (v) => sanitizer(v);

    // QQ: Do we really need to sanitize/transform for all of these?
    // The errorTransformer seems logical, but the others seem excessive, but leaving alone for now.
    // https://github.com/LogRocket/redux-logger#statetransformer--state-object--state
    const logger = createLogger({
      actionTransformer: (action) => transform(action),
      stateTransformer: (state) => transform(state),
      errorTransformer: (error) => transform(error),
    });
    middlewares.push(logger);
  }

  const middleware = applyMiddleware(...middlewares);

  // 4) Create the actual store from the reducers
  const reducer = combineReducers(reducers);

  const store = createStore(
    reducer,
    isProduction ? middleware : composeWithDevTools(middleware)
  );
  const persistor = persistStore(store);

  // 6) Run the saga, with form middleware added
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
