import { Middleware } from "redux";

import { SUCCEEDED_SUFFIX, FAILED_SUFFIX } from "app/actionTypes";

/*
 * Automatically call the onSuccess or onFailure callback with an optional message.
 * onSuccess:
 * The action.successMessage is returned if available,
 * otherwise the default formikConfig.successMessage
 *
 * onFailure:
 * The action.failureMessage is returned if available,
 * otherwise the default formikConfig.failureMessage
 */
const middleware: Middleware = () => (next) => (action) => {
  const { formikConfig, type } = action;
  if (formikConfig) {
    if (formikConfig.onSuccess && type.endsWith(SUCCEEDED_SUFFIX)) {
      handleSuccessAction(action);
    } else if (formikConfig.onFailure && type.endsWith(FAILED_SUFFIX)) {
      handleFailureAction(action);
    }
  }

  return next(action);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleSuccessAction = (action: any) => {
  const { formikConfig } = action;
  const successMessage = action.successMessage || formikConfig.successMessage;
  const resetUponSuccess =
    action.resetUponSuccess || formikConfig.resetUponSuccess;
  formikConfig.onSuccess({
    successMessage,
    resetUponSuccess,
    updatedFormValues: formikConfig.updatedFormValues,
    transformValues: formikConfig.transformValues,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleFailureAction = (action: any) => {
  const { formikConfig } = action;
  const failureMessage = action.failureMessage || formikConfig.failureMessage;
  const resetUponFailure =
    action.resetUponFailure || formikConfig.resetUponFailure;
  formikConfig.onFailure({
    failureMessage,
    resetUponFailure,
    initialFormValues: formikConfig.initialFormValues,
    updatedFormValues: formikConfig.updatedFormValues,
    transformValues: formikConfig.transformValues,
  });
};

export default middleware;
