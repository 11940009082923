import AUTH_ACTION_TYPES from "app/actionTypes/auth";
import { getAuth } from "app/api/authCookie";
import AuthState from "app/types/state/auth";

const defaultState: AuthState = {
  loggedIn: false,
  impersonated: false,
  userId: "",
  tokens: {},
  hasRequestedPasswordReset: false,
  passwordResetRequestEmail: "",
  hasSuccessfullyResetPassword: false,
  newPasswordRequired: false,
  user: {},
};

// https://github.com/omnidan/redux-recycle#api
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialState = (_state?: AuthState, action?: any): AuthState => {
  let loggedIn = false,
    userId = "",
    tokens = {},
    impersonated = false,
    user = {};

  if (!action || action.type !== AUTH_ACTION_TYPES.LOGOUT) {
    const value = getAuth();

    if (value) {
      /* eslint-disable prefer-destructuring */
      userId = value.userId;
      tokens = value.tokens;
      impersonated = value.impersonated;
      user = value.user;
      /* eslint-enable prefer-destructuring */

      if (userId && userId.length) {
        loggedIn = true;
      }
    }
  }

  return {
    ...defaultState,
    loggedIn,
    userId,
    tokens,
    impersonated,
    user,
  };
};

export default function auth(
  state = initialState(),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any = {}
): AuthState {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN_SUCCEEDED:
      return {
        ...state,
        loggedIn: true,
        userId: action.userId,
        tokens: action.tokens,
      };

    case AUTH_ACTION_TYPES.IMPERSONATE:
      return {
        ...state,
        loggedIn: true,
        impersonated: true,
        userId: action.userId,
        tokens: {
          id_token: action.token,
        },
      };

    case AUTH_ACTION_TYPES.SAVE_CREDENTIALS:
      return {
        ...state,
        loggedIn: true,
        userId: action.userId,
        tokens: {
          id_token: action.token,
        },
      };

    case AUTH_ACTION_TYPES.REQUEST_PASSWORD_RESET_SUCCEEDED:
      return {
        ...state,
        hasRequestedPasswordReset: true,
        passwordResetRequestEmail: action.email,
      };

    case AUTH_ACTION_TYPES.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        hasSuccessfullyResetPassword: true,
      };

    case AUTH_ACTION_TYPES.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        newPasswordRequired: true,
        user: action.user,
      };

    default:
      return state;
  }
}

export function login(params) {
  return { type: AUTH_ACTION_TYPES.LOGIN, ...params };
}

export function logout(params = {}) {
  return { type: AUTH_ACTION_TYPES.LOGOUT, ...params };
}

export function requestPasswordReset(params = {}) {
  return { type: AUTH_ACTION_TYPES.REQUEST_PASSWORD_RESET, ...params };
}

export function resetPassword(params = {}) {
  return { type: AUTH_ACTION_TYPES.RESET_PASSWORD, ...params };
}

export function impersonate(params) {
  return { type: AUTH_ACTION_TYPES.IMPERSONATE, ...params };
}

export function saveCredentials(params) {
  return { type: AUTH_ACTION_TYPES.SAVE_CREDENTIALS, ...params };
}

export function updatePassword(params = {}) {
  return { type: AUTH_ACTION_TYPES.UPDATE_PASSWORD, ...params };
}

export function isLoggedIn(state: AuthState) {
  return state.loggedIn;
}

export function getUserId(state: AuthState) {
  return state.userId;
}

export function getTokens(state: AuthState) {
  return state.tokens;
}

export function getCognitoUser(state: AuthState) {
  return state.user;
}

export function isImpersonated(state: AuthState) {
  return state.impersonated;
}

export function hasRequestedPasswordReset(state: AuthState) {
  return state.hasRequestedPasswordReset;
}

export function getPasswordResetRequestEmail(state: AuthState) {
  return state.passwordResetRequestEmail;
}

export function hasSuccessfullyResetPassword(state: AuthState) {
  return state.hasSuccessfullyResetPassword;
}

export function newPasswordRequired(state: AuthState) {
  return state.newPasswordRequired;
}
