// @ts-nocheck
import { RichText, Link as PrismicLink } from "prismic-reactjs";

import { config } from "app/config";
import { CONTENT_TYPES } from "app/reducers/cms";
import { createElementFromHTML, parseURL } from "app/ui/global/utils";
import {
  accumulateSerializers,
  titleSerializer,
} from "app/ui/shared/CMS/serializers";

const domain = config.get("endpoints.website_domain");

export const linkResolver = ({ type, uid }) => {
  switch (type) {
    case CONTENT_TYPES.PAGE:
      return `/${uid}`;
    default:
      return "/";
  }
};

/* eslint-disable camelcase */
export const getPrismicLink = (linkObj) => {
  // link to other CMS-defined pages
  if (linkObj.link_type === "Document") {
    return {
      to: PrismicLink.url(linkObj, linkResolver),
    };
  }
  // link not defined
  if (linkObj.link_type === "Any") {
    return {
      to: null,
    };
  }

  const hasTarget = !!linkObj.target;

  const link = parseCMSWebLinks(linkObj.url, hasTarget);
  return {
    to: link.url,
    external: link.type !== "internal",
    externalTarget: linkObj.target || null,
    newWindow: linkObj.target && linkObj.target === "_blank",
  };
};

// Serialize is related to markup
export const getPrismicTitle = (title, titleStyle) => {
  if (!title[0] || !title[0].text) return null;
  return RichText.render(
    title,
    linkResolver,
    accumulateSerializers([
      titleSerializer(titleStyle ? `title ${titleStyle}` : "title"),
    ])
  );
};

export const parseCMSWebLinks = (url: string, hasTarget?: boolean) => {
  let parsedURL;
  const { hostname, pathname } = parseURL(url);
  const internalHostNames = [domain, `www.${domain}`];
  // partial links may not contain .com, .org etc - eg http://about-us
  const isPartial = hostname && !hostname.includes(".");
  // if a linkObj includes a target, always treat it as external
  const isInternal =
    !hasTarget && hostname && internalHostNames.includes(hostname);

  if (isPartial) {
    parsedURL = `/${hostname}`;
  } else if (isInternal) {
    parsedURL = pathname;
  } else {
    parsedURL = url;
  }

  return {
    url: parsedURL,
    type: isInternal || isPartial ? "internal" : "external",
  };
};

/* eslint-disable camelcase */
export const parseCMSEmbedLinks = ({ provider_name, html }) => {
  // Use Prismic's Embed html to ensure consistent URL structure from embed URL input
  const { src } = createElementFromHTML(html);
  if (provider_name === "YouTube") {
    const youTubeIDRegEx = /\/embed\/([0-9a-zA-Z]+)/;
    return src.match(youTubeIDRegEx)[1];
  }
  return null;
};
/* eslint-enable camelcase */
