import Box, { BoxProps } from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import { rem } from "polished";
import React, { RefObject } from "react";
import styled from "styled-components";

import ShopToolbarIcon from "app/ui/shopping/Navigation/ShopToolbar/ShopToolbarIcon";
import CartBadge from "app/ui/designSystem/molecules/badges/CartBadge";
import FilterIcon from "app/images/filter.svg";
import CartIcon from "app/images/cartNew.svg";
import breakpoints from "app/styles/breakpoints";

export enum ShopToolbarIcons {
  NONE = "none",
  CART = "cart",
  FILTER = "filter",
  SEARCH = "search",
}

type MUIBoxProps = Pick<BoxProps, "className">;

export interface ShopToolbarProps extends MUIBoxProps {
  active?: ShopToolbarIcons;
  disableCart: boolean;
  filterApplied: boolean;
  searchQueryActive: boolean;
  hasReachedMinimum: boolean;
  onClickSearch: () => void;
  onClickFilter: () => void;
  onClickCart: () => void;
  productCount: number;
  searchButtonRef?: RefObject<HTMLDivElement>;
  filterButtonRef?: RefObject<HTMLDivElement>;
}

const ShopToolbar: React.FC<ShopToolbarProps> = ({
  active,
  disableCart,
  filterApplied,
  searchQueryActive,
  hasReachedMinimum,
  onClickCart,
  onClickFilter,
  onClickSearch,
  productCount,
  className,
  searchButtonRef,
  filterButtonRef,
}) => (
  <SCList role="toolbar" className={className}>
    <ShopToolbarIcon
      tooltipText="Search"
      onClick={onClickSearch}
      active={active === ShopToolbarIcons.SEARCH || searchQueryActive}
    >
      <IconRefWrapper ref={searchButtonRef}>
        <StyledSearchIcon />
      </IconRefWrapper>
    </ShopToolbarIcon>
    <ShopToolbarIcon
      tooltipText="Filter"
      onClick={onClickFilter}
      active={active === ShopToolbarIcons.FILTER}
    >
      <StyledFilterContainer $applied={filterApplied}>
        <IconRefWrapper ref={filterButtonRef}>
          <StyledFilter className="MuiSvgIcon-root" />
        </IconRefWrapper>
      </StyledFilterContainer>
    </ShopToolbarIcon>
    <ShopToolbarIcon
      id="shop-toolbar-cart-icon"
      tooltipText="Cart"
      onClick={disableCart ? () => {} : onClickCart}
      active={active === ShopToolbarIcons.CART}
    >
      <StyledCartContainer
        $active={active === ShopToolbarIcons.CART}
        $disabled={disableCart}
      >
        <StyledCartIcon />
        <StyledCartBadgeContainer>
          {!disableCart && (
            <CartBadge
              hasReachedMinimum={hasReachedMinimum}
              productCount={productCount}
            />
          )}
        </StyledCartBadgeContainer>
      </StyledCartContainer>
    </ShopToolbarIcon>
  </SCList>
);

const SCList = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const StyledSearchIcon = styled(SearchIcon)`
  height: ${rem(32)};
  width: ${rem(32)};
`;

export const StyledCartContainer = styled(Box)<{
  $active: boolean;
  $disabled: boolean;
}>`
  position: relative;
  height: ${rem(24)};
  width: ${rem(24)};
  rect {
    fill: ${({ theme }) => theme.colors.marshmallow};
  }
  path:last-of-type {
    stroke: ${({ theme, $active }) =>
      $active ? theme.colors.beet : theme.colors.fontDark};
    stroke-width: 2.5;
  }
  path {
    fill: ${({ theme, $active }) =>
      $active ? theme.colors.beet : theme.colors.fontDark};
  }
  &:hover {
    path:last-of-type {
      stroke: ${({ theme }) => theme.colors.beet};
    }
    path {
      fill: ${({ theme }) => theme.colors.beet};
    }
  }

  ${({ $disabled, theme }) =>
    $disabled &&
    `
    rect, path, path:last-of-type {
      fill: ${theme.colors.oat};
      stroke: ${theme.colors.oat};
    }

    &:hover {
      rect, path, path:last-of-type {
        fill: ${theme.colors.oat};
        stroke: ${theme.colors.oat};
      }
    }
  `}
`;

export const StyledCartBadgeContainer = styled(Box)`
  position: absolute;
  top: ${rem(-15)};
  right: ${rem(20)};
`;

const IconRefWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledCartIcon = styled(CartIcon)`
  height: ${rem(24)};
  width: ${rem(24)};
`;

const StyledFilter = styled(FilterIcon)`
  height: ${rem(24)};
  width: ${rem(24)};
  stroke: currentColor;
`;

const StyledFilterContainer = styled(Box)<{
  $applied: boolean;
}>`
  height: ${rem(24)};
  width: ${rem(24)};
  position: relative;

  ${({ theme, $applied }) =>
    $applied &&
    `
    ${breakpoints.md`
    &:hover:after {
      background-color: ${theme.colors.beet};
    }
    `}


    &:after {
      content: "";
      width: ${rem(8)};
      height: ${rem(8)};
      background-color: ${theme.colors.beet};
      border-radius: 50%;
      position: absolute;
      top: ${rem(-1)};
      right: ${rem(-1)};
    }
  `}
`;

export default ShopToolbar;
