import queryString from "query-string";
import { call, put, select, all } from "redux-saga/effects";

import { clearModals, showModal } from "app/reducers/ui";
import { waitForInitialization } from "app/sagas/waitForInitialization";
import {
  getRoutingLocation,
  isAnyUrlEnabledModalVisible,
  shouldShowTCModal,
  shouldShowAuthFailureModal,
} from "app/selectors";
import {
  TERMS_CONDITIONS_ACCEPT_MODAL,
  AUTH_FAILURE_MODAL,
} from "app/constants/modals";

function* modalTriage() {
  yield call(waitForInitialization);
  yield put(clearModals());

  const location = yield select(getRoutingLocation);
  const modalVisible = yield select(isAnyUrlEnabledModalVisible);
  if (location && location.hash && location.hash.length) {
    const hash = queryString.parse(location.hash);
    const { modal, ...params } = hash;
    if (modal && typeof modal === "string") {
      yield put(showModal(modal, params));
    }
  } else if (modalVisible) {
    yield put(clearModals());
  }

  // Only one of these can be visible at a time
  yield all([call(termsConditionsTriage), call(authFailureTriage)]);
}

function* termsConditionsTriage() {
  yield call(waitForInitialization);
  const showTCModal = yield select(shouldShowTCModal, {
    modalId: TERMS_CONDITIONS_ACCEPT_MODAL,
  });
  if (showTCModal) {
    yield put(showModal(TERMS_CONDITIONS_ACCEPT_MODAL));
  }
}

function* authFailureTriage() {
  yield call(waitForInitialization);
  const showFailedAuthModal = yield select(shouldShowAuthFailureModal, {
    modalId: AUTH_FAILURE_MODAL,
  });
  if (showFailedAuthModal) {
    yield put(showModal(AUTH_FAILURE_MODAL));
  }
}

export default modalTriage;
