// @ts-nocheck
import Cookies from "universal-cookie";

import { config } from "app/config";

// https://github.com/reactivestack/cookies/issues/16
// Both set and remove operations on the cookie must include the same path option or the
// remove will not work
export const defaultOptions = {
  path: "/",
  secure: config.get("flags.https_only"),
};

let cookies = new Map();

export default function (header) {
  cookies = header ? new Cookies(header) : new Cookies();
}

export const get = (name) => cookies.get(name);

export const set = (name, value, options = defaultOptions) =>
  cookies.set(name, value, options);

export const remove = (name, options = defaultOptions) =>
  cookies.remove(name, options);
