import { hideVisually } from "polished";
import React from "react";
import styled from "styled-components";
import Link from "app/ui/shared/Link";
import routes from "app/router/routes.json";

const AccessiblityHelpInfo = () => (
  <SrOnlySection>
    <p>
      Need help navigating our site? Call us at{" "}
      <a href="tel:+15105956683">(510) 595-6683</a> or write to us via{" "}
      <Link to={routes.contact.url}>
        Grocery Delivery for Organic Food, Fresh Produce & More
      </Link>{" "}
      and we’ll make sure you get assistance. Gratitude and groceries!
    </p>
  </SrOnlySection>
);

const SrOnlySection = styled.section`
  ${hideVisually()};
`;

export default AccessiblityHelpInfo;
