import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { getNextDeliveryFormatted } from "app/selectors";

const CartDeliveryDate: React.FC<CartDeliveryDateProps> = ({
  nextDelivery,
}) => {
  const {
    customizationWindowFormatted: { endDate, endTime },
    deliveryDateFormatted,
  } = nextDelivery;
  return (
    <DeliveryContainer>
      <DeliveryText>Delivering on {deliveryDateFormatted}</DeliveryText>
      <ShopUntilText>
        Shop until {endTime} {endDate?.split(",")[0]}
      </ShopUntilText>
    </DeliveryContainer>
  );
};

export interface CartDeliveryDateProps {
  nextDelivery: ReturnType<typeof getNextDeliveryFormatted>;
}

const DeliveryContainer = styled.div`
  padding-bottom: ${rem(16)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.oat};
`;

const DeliveryText = styled.div`
  font-size: ${rem(15)};
  line-height: ${rem(24)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
`;

const ShopUntilText = styled.div`
  font-size: ${rem(13)};
  line-height: ${rem(20)};
`;

export default CartDeliveryDate;
