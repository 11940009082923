import React from "react";

import { Button as MUIButton, ButtonProps } from "@material-ui/core";
import styled from "styled-components";
import { rem } from "polished";

/**
 * This pattern is to allow for replacing the root node
 * (via the component prop) of Material-UI Overridable Component
 * while still preserving Typescript typing. If not done, the component
 * prop will not be present in the props of the Material-UI component
 * and Typescript errors will be incurred.
 *
 * @example
 * import { Link as RouterLink } from "react-router-dom";
 * ...
 * <Button component={RouterLink} to={'/account'}>
 *   Go To Account
 * </Button>
 *
 * @example
 * import { RouterLinkWithRef } from "app/ui/shared/Link";";
 * ...
 * <Button component={RouterLinkWithRef} to={'/account'}>
 *   Go To Account
 * </Button>
 *
 * @link [Material UI Docs](https://material-ui.com/guides/typescript/#usage-of-component-prop)
 */

export interface CustomButtonAddOnProps {
  isSquare?: boolean;
  component?: React.ElementType;
}

export type CustomButtonProps = CustomButtonAddOnProps & ButtonProps;
export const Button = <C extends React.ElementType>({
  isSquare = false,
  children,
  ...rest
}: ButtonProps<C, CustomButtonAddOnProps>) =>
  isSquare ? (
    <SCMUIButton {...rest}>{children}</SCMUIButton>
  ) : (
    <MUIButton {...rest}>{children}</MUIButton>
  );

const SCMUIButton = styled(MUIButton)<ButtonProps>`
  padding: ${rem(8)} ${rem(16)};
  border-radius: ${rem(4)};
`;

export const PrimaryButton = <C extends React.ElementType>({
  variant,
  ...rest
}: ButtonProps<C, CustomButtonAddOnProps>) => (
  <Button {...rest} variant="contained" />
);

export const SecondaryButton = <C extends React.ElementType>({
  variant,
  ...rest
}: ButtonProps<C, CustomButtonAddOnProps>) => (
  <Button {...rest} variant="outlined" />
);

export default Button;
