import { RouterLocation } from "app/types/state/extendedRouter";

interface Options {
  ignoreCase?: boolean;
  prefix?: boolean;
  replaceKey?: string;
}

/**
 * Reduces an existing [query params] _object_ by returning key-value
 * pairs whose keys match the provided `identifier`.
 *
 * If you need to parse a _query string_ into an object, consider
 * using the `query-string` library.
 */
const reduceQueryParams = (
  query: RouterLocation<unknown>["query"],
  identifier: string,
  options?: Options
): Record<string, string> => {
  let mutableKey = "";
  let mutableIdentifier = "";
  let matchFound = false;
  let outputKey = "";

  return Object.keys(query).reduce((acc, key) => {
    mutableKey = key;
    mutableIdentifier = identifier;

    if (options?.ignoreCase) {
      mutableKey = mutableKey.toLowerCase();
      mutableIdentifier = mutableIdentifier.toLowerCase();
    }

    matchFound = mutableKey === mutableIdentifier;

    if (options?.prefix) {
      matchFound = mutableKey.startsWith(mutableIdentifier);
    }

    if (matchFound) {
      outputKey = key;

      if (options?.replaceKey) {
        outputKey = options.replaceKey;
      }

      acc[outputKey] = query[key];
    }

    return acc;
  }, {});
};

export default reduceQueryParams;
