// @ts-nocheck
import _some from "lodash/some";

const mapUserTraits = (
  activeWindow = {},
  user = {},
  delivery = {},
  isInCustomization = false
) => {
  const {
    email,
    firstName,
    lastName,
    stage,
    lifetimeOrderCount,
    createDate,
    subscriptionId,
    subscriptions,
    phone,
    defaultAddressId,
    mapOfAddresses,
  } = user;

  const defaultAddressInfo = mapOfAddresses?.[defaultAddressId] || {};

  const { city, address: street, state, zip } = defaultAddressInfo;
  const address = _some(defaultAddressInfo)
    ? { city, street, state, postalCode: zip, country: "USA" }
    : undefined;

  const displayName =
    firstName && lastName ? `${firstName} ${lastName}` : firstName;

  const recentSubscription =
    subscriptionId &&
    subscriptions?.find((s) => s.subscriptionId === subscriptionId);

  const {
    fcName,
    fulfillmentCenterId,
    deliveryProvider,
    zoneName,
    marketName,
  } = activeWindow;

  const nextDeliveryDate = delivery.deliveryDate;

  const mappedTraits = {
    email,
    // https://segment.com/docs/connections/destinations/catalog/fullstory/
    displayName, // for FullStory
    firstName,
    lastName,
    phone,
    stage,
    lifetimeOrderCount,
    createDate,
    fcName,
    fulfillmentCenterId,
    deliveryProvider,
    zoneName,
    marketName,
    nextDeliveryDate,
    isInCustomization,
    // TODO: remove/update mapped userTraits NC-1859 (maybe use chat widget wrapper to push these attributes)
    // below are repeated for chat widget integration with zendesk's prop names
    // https://segment.com/docs/connections/destinations/catalog/zendesk/#identify
    name: displayName,
    membership: user.stage,
    fc: fcName,
    fulfillment_center: fulfillmentCenterId,
    delivery_provider: deliveryProvider,
    route_name: zoneName,
    store_name: marketName,
    recentSubscriptionStartDate: recentSubscription?.startDate,
    address,
  };

  return Object.keys(mappedTraits).reduce((acc, key) => {
    if (
      mappedTraits[key] !== null &&
      mappedTraits[key] !== undefined &&
      mappedTraits[key] !== false
    )
      acc[key] = mappedTraits[key];
    return acc;
  }, {});
};

export default mapUserTraits;
