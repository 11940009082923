import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "catalog";
const prefixes = {
  FETCH_CATALOG: `${domain}/FETCH_CATALOG`,
  FETCH_SMART_SHELF: `${domain}/FETCH_SMART_SHELF`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
};
