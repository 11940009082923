import { Typography } from "@material-ui/core";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { PriceType } from "app/types/selectors/crossDomain/ViewableOffering";

interface CartItemPriceProps {
  activePrice: PriceType;
  className?: string;
  fullPrice?: PriceType;
  scalePrice?: PriceType;
  scaleMinQuantity?: number | null;
  quantity: number;
}

const CartItemPrice: React.FC<CartItemPriceProps> = ({
  activePrice,
  className,
  fullPrice,
  scalePrice,
  scaleMinQuantity,
  quantity,
}) => {
  const hasScalePriceAndMeetsMinQuantity =
    !!scalePrice && !!scaleMinQuantity && quantity >= scaleMinQuantity;
  const activePriceToDisplay = hasScalePriceAndMeetsMinQuantity
    ? scalePrice
    : activePrice;

  return (
    <Container className={className}>
      {activePrice && (
        <ActivePrice
          variant="body1"
          $withFullPrice={!!fullPrice || hasScalePriceAndMeetsMinQuantity}
        >
          $
          {(
            Number.parseFloat(activePriceToDisplay as string) * quantity
          ).toFixed(2)}
        </ActivePrice>
      )}
      {fullPrice && !scalePrice && (
        <FullPrice variant="caption">
          ${(Number.parseFloat(fullPrice) * quantity).toFixed(2)}
        </FullPrice>
      )}
    </Container>
  );
};

export default CartItemPrice;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${rem(50)};
`;

const ActivePrice = styled(Typography)<{
  $withFullPrice: boolean;
}>`
  position: relative;
  font-size: ${rem(15)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  line-height: ${rem(18.29)};
  z-index: 0;

  ${({ $withFullPrice, theme }) =>
    $withFullPrice &&
    `
      ${`color: ${theme.colors.cherry};`}
    `}
`;

const FullPrice = styled(Typography)`
  color: ${({ theme }) => theme.colors.taro};
  text-decoration: line-through;
`;
