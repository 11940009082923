import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Portal } from "react-portal";

import { shouldRenderAccountPage } from "app/selectors";
import { ACCOUNT_ROUTES_SNACKBAR_CONTAINER_ID } from "app/ui/designSystem/molecules/Snackbar/SnackbarContainer";
import MuiSnackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import MuiButton from "@material-ui/core/Button";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { rem } from "polished";
import colors from "app/styles/colors";
import { sizes } from "app/styles/breakpoints";

export enum SnackbarTheme {
  DEFAULT = "default",
  RECIPE = "recipe",
}

export interface SnackbarFullProps extends SnackbarProps {
  snackbarTheme?: SnackbarTheme;
}

function Snackbar({
  open,
  message,
  onClose,
  action,
  snackbarTheme = SnackbarTheme.DEFAULT,
  ...otherProps
}: SnackbarFullProps) {
  const shouldOnlyRenderAccountPage = useSelector(shouldRenderAccountPage);
  const portalElm = document.getElementById(
    ACCOUNT_ROUTES_SNACKBAR_CONTAINER_ID
  );

  return (
    <Portal node={portalElm}>
      <InlineSnackbar
        open={open}
        message={message}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        autoHideDuration={snackbarTheme === SnackbarTheme.RECIPE ? 4000 : 5000}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        $shouldOnlyRenderAccountPage={shouldOnlyRenderAccountPage}
        $snackbarTheme={snackbarTheme}
        {...otherProps}
      />
    </Portal>
  );
}

export const InlineSnackbar = styled(MuiSnackbar)<{
  $shouldOnlyRenderAccountPage: boolean;
  $snackbarTheme: SnackbarTheme;
}>`
  && {
    z-index: 3;
    margin-top: ${rem(8)};
    top: ${({ $shouldOnlyRenderAccountPage, theme }) =>
      rem($shouldOnlyRenderAccountPage ? 0 : theme.layout.headerHeightMobile)};

    ${({ $snackbarTheme }) =>
      $snackbarTheme === SnackbarTheme.RECIPE &&
      `
        width: 100%;
        top: 0;
        left: unset;
        right: unset;
        margin-top: 0;
        transform: unset;
        z-index: 999;
        top: ${rem(56)};

        @media (min-width: ${sizes.sm + 1}px) {
          width: ${rem(375)};
          right: ${rem(16)};
          top: ${rem(50)};
        }

        @media (min-width: ${sizes.xl + 1}px) {
          top: ${rem(100)};
        }
      `}
  }

  & .MuiSnackbarContent-root {
    flex-wrap: nowrap;
    color: ${colors.white};
    min-height: ${rem(48)};
    font-size: ${rem(15)};
    font-weight: 600;
    line-height: ${rem(18)};
    background-color: ${colors.kale};
    box-shadow: none;

    ${({ $snackbarTheme }) =>
      $snackbarTheme === SnackbarTheme.DEFAULT &&
      `
      @media (max-width: ${sizes.md}px) {
        width: 94vw;
      }

      @media (min-width: ${sizes.md + 1}px) {
        min-width: ${rem(344)};
      }

      @media (max-width: 320px) {
        font-size: ${rem(13)};
      }
    `}

    ${({ $snackbarTheme }) =>
      $snackbarTheme === SnackbarTheme.RECIPE &&
      `
      background-color: ${colors.peppercorn};
      width: 100%;
      border-radius: 0;
      padding: ${rem(12)} ${rem(18)};
      height: ${rem(60)};

      @media (min-width: ${sizes.sm + 1}px) {
        border-radius: ${rem(4)};
      }
    `}
  }

  & .MuiSnackbarContent-action {
    @media (max-width: 302px) {
      display: none;
    }

    @media (min-width: 302px) {
      display: flex;
    }
  }
`;

export function SlideTransition(props: SlideProps) {
  return (
    <Slide {...props} timeout={{ enter: 300, exit: 300 }} direction="down" />
  );
}

export const ActionSnackbarButton = styled(MuiButton)`
  color: ${colors.white};
  font-size: ${rem(15)};
  font-weight: 500;
  line-height: ${rem(18)};

  & .MuiButton-label {
    text-transform: uppercase;

    @media (max-width: 320px) {
      font-size: ${rem(13)};
    }
  }
`;

export default Snackbar;
