import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import {
  blurred,
  subtitles,
  source,
  pad,
  AdvancedVideo,
  Cloudinary,
} from "app/utils/cloudinary";
import useSize from "app/ui/hooks/useSize";
import {
  videoLoadStart,
  videoPlay,
  videoEnd,
  videoError,
  videoPlaying,
} from "app/reducers/ui";

export interface ShelfPromotionVideoProps {
  className?: string;
  cloudName?: string;
  videoID: string;
  subtitleID: string | null;
  muted?: boolean;
  autoPlay?: boolean;
  controls?: boolean;
  shelfId: string;
  shelfName: string;
  orderId: string | null;
}

const ShelfPromotionVideo: React.FC<ShelfPromotionVideoProps> = React.memo(
  ({
    className,
    cloudName = "imperfect",
    videoID,
    subtitleID,
    muted = true,
    autoPlay = false,
    controls = true,
    shelfId = "",
    shelfName = "",
    orderId = "",
  }) => {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const containerSize = useSize(containerRef);

    const handleVideoLoadStart = () => {
      dispatch(videoLoadStart({ shelfId, shelfName, orderId }));
    };

    const handleVideoPlay = () => {
      dispatch(videoPlay({ shelfId, shelfName, orderId }));
    };

    const handleVideoEnd = () => {
      dispatch(videoEnd({ shelfId, shelfName, orderId }));
    };

    const handleVideoError = () => {
      dispatch(videoError({ shelfId, shelfName, orderId }));
    };

    const handleVideoPlaying = () => {
      dispatch(videoPlaying({ shelfId, shelfName, orderId }));
    };

    const visibilityCallbackFn = (entries) => {
      const [entry] = entries;
      if (containerRef.current) {
        const videoElm = containerRef.current.querySelector("video");
        if (entry.isIntersecting) {
          videoElm?.play();
        } else {
          videoElm?.pause();
        }
      }
    };

    useEffect(() => {
      const observer = new IntersectionObserver(visibilityCallbackFn, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      });
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, [containerRef]);

    const cld = new Cloudinary({
      cloud: {
        cloudName,
      },
    });

    const videoWidth = containerSize?.width
      ? Math.floor(containerSize.width)
      : undefined;
    const videoHeight = containerSize?.height;

    let endcapVideo = cld.video(videoID).resize(
      pad()
        .width(videoWidth || 240)
        .height(videoHeight || 240)
        .background(blurred().intensity(400).brightness(15))
    );

    if (subtitleID != null) {
      endcapVideo = endcapVideo.overlay(source(subtitles(subtitleID)));
    }

    return (
      <ShelfPromotionVideoContainer
        ref={containerRef}
        id="shelf-promotion-card-video"
        className={className}
      >
        {containerRef.current != null && (
          <ShelfPromotionVideoAdvancedVideo
            muted={muted}
            autoPlay={autoPlay}
            cldVid={endcapVideo}
            controls={controls}
            onEnded={handleVideoEnd}
            onError={handleVideoError}
            onLoadStart={handleVideoLoadStart}
            onPlay={handleVideoPlay}
            onPlaying={handleVideoPlaying}
            playsInline
          />
        )}
      </ShelfPromotionVideoContainer>
    );
  }
);

const ShelfPromotionVideoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
`;

const ShelfPromotionVideoAdvancedVideo = styled(AdvancedVideo)`
  flex-grow: 1;
`;

export default ShelfPromotionVideo;
