import MuiCard from "@material-ui/core/Card";
import { rem } from "polished";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { getActiveOrderId } from "app/selectors";
import { getImageURL } from "app/ui/global/utils";
import UnstyledLink from "app/ui/shared/UnstyledLink";
import { recipePageOpened } from "app/reducers/ui";
import ShelfPromotionVideo from "app/ui/shopping/SinglePageCatalog/ShelfPromotionVideo";

export enum ShelfPromotionCardProfileType {
  SLIM = "slim",
  REGULAR = "regular",
}

export interface ShelfPromotionCardProps {
  shelfId: string;
  shelfName: string;
  profileType?: ShelfPromotionCardProfileType;
  promotionImageFilename: string | null;
  promotionLink: string | null;
  promotionLinkText: string | null;
  promotionVideoFilename: string | null;
  promotionVideoSubtitleFilename: string | null;
  className?: string;
}

const SHELF_PROMO_CARD_SLIM_HEIGHT = 272;
const SHELF_PROMO_CARD_SLIP_MARGIN_BOTTOM = 10;

const ShelfPromotionCard: React.FC<ShelfPromotionCardProps> = ({
  shelfId,
  shelfName,
  profileType = ShelfPromotionCardProfileType.REGULAR,
  promotionImageFilename,
  promotionLink,
  promotionLinkText,
  promotionVideoFilename,
  promotionVideoSubtitleFilename,
  className,
}) => {
  const dispatch = useDispatch();
  const activeOrderId = useSelector(getActiveOrderId);
  const [isHovering, setIsHovering] = useState(false);
  const Profile =
    profileType === ShelfPromotionCardProfileType.REGULAR
      ? RegularProfile
      : SlimProfile;

  let promotionContent: JSX.Element | null = null;

  if (promotionImageFilename != null) {
    const recipeImage = getImageURL(promotionImageFilename);
    promotionContent = (
      <StyledBackgroundImage
        src={recipeImage}
        alt="Promotional Card"
        $isMouseHovering={isHovering}
      />
    );
  } else if (promotionVideoFilename != null) {
    promotionContent = (
      <ShelfPromotionVideo
        videoID={promotionVideoFilename}
        subtitleID={promotionVideoSubtitleFilename}
        shelfId={shelfId}
        shelfName={shelfName}
        orderId={activeOrderId}
      />
    );
  }

  const isVideo = promotionVideoFilename != null;

  return (
    <Profile
      square
      variant="outlined"
      data-testid={`promotional-card-${profileType}`}
      data-clickid="promotional-card"
      className={className}
      onMouseEnter={() => {
        if (!isVideo) {
          setIsHovering(true);
        }
      }}
      onMouseLeave={() => {
        if (!isVideo) {
          setIsHovering(false);
        }
      }}
      onClick={() => {
        dispatch(recipePageOpened(shelfId));
      }}
      $hasPromotionLink={!!promotionLink}
    >
      {promotionLink && promotionLinkText && !isVideo && (
        <>
          <StyledButton to={promotionLink} external newWindow>
            {promotionLinkText}
            <ResizedOpenInNewIcon />
          </StyledButton>
          <LinkToDetail
            to={promotionLink}
            external
            newWindow
            aria-label="promotional-card-link"
          />
        </>
      )}
      {promotionContent}
    </Profile>
  );
};

const LinkToDetail = styled(UnstyledLink)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const RegularProfile = styled(MuiCard)<{ $hasPromotionLink: boolean }>`
  position: relative;
  transition: border-color 200ms ease-in-out;
  transform: translateZ(0);
  border-style: solid;
  height: ${rem(320)};
  min-width: ${rem(220)};
  max-width: ${rem(549)};
  flex: 1 1 ${rem(256)};
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  overflow: visible;
  grid-column: span 2;
  ${({ $hasPromotionLink }) =>
    $hasPromotionLink &&
    `
      &:hover {
        cursor: pointer;
      }
    `}
`;

const SlimProfile = styled(MuiCard)<{ $hasPromotionLink: boolean }>`
  position: relative;
  min-width: ${rem(320)};
  max-width: ${rem(479)};
  height: ${rem(SHELF_PROMO_CARD_SLIM_HEIGHT)};
  border: ${({ theme }) => `${rem(1)} solid ${theme.colors.grayLighter}`};
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: ${rem(SHELF_PROMO_CARD_SLIP_MARGIN_BOTTOM)};
`;

const StyledButton = styled(UnstyledLink)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: ${rem(12)};
  left: ${rem(12)};
  height: ${rem(32)};
  padding: ${rem(8)} ${rem(6)} ${rem(8)} ${rem(8)};
  background-color: ${({ theme }) => theme.colors.plum};
  border-radius: ${rem(4)};
  font-size: ${rem(12)};
  z-index: 2;
  display: flex;
  align-items: center;
  font-weight: 700;
  &:hover {
    background-color: ${({ theme }) => theme.colors.plum};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.plum};
    box-shadow: none;
    border: none;
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.plum};
  }
`;

const ResizedOpenInNewIcon = styled(OpenInNewIcon)`
  transform: scale(0.73);
`;

const StyledBackgroundImage = styled.img<{ $isMouseHovering: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-filter: brightness(
    ${({ $isMouseHovering }) => ($isMouseHovering ? "90%" : "100%")}
  );
`;

export default ShelfPromotionCard;

export const calculateShelfPromotionCardHeight = () => {
  return SHELF_PROMO_CARD_SLIM_HEIGHT + SHELF_PROMO_CARD_SLIP_MARGIN_BOTTOM;
};
