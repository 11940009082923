import React, { forwardRef } from "react";
import styled from "styled-components";

import Wave, { WaveProps } from "app/ui/shared/WaveContainer/Wave";
import { rem } from "polished";
import { getTypographyColorForBackground, Color } from "app/styles/colors";
import breakpoints from "app/styles/breakpoints";
import Curve from "app/ui/shared/WaveContainer/Curve";

export type ShapeTypes = "wave" | "curve";

export type WaveContainerProps = React.HTMLAttributes<HTMLDivElement> &
  WaveProps & { top?: boolean; type?: ShapeTypes };

const WaveContainer = forwardRef<HTMLDivElement, WaveContainerProps>(
  (
    {
      color = "baba",
      children,
      top = true,
      bottom = true,
      type = "wave",
      ...props
    },
    ref
  ) => {
    const TopElement = type === "wave" ? Wave : Curve;
    const BottomElement = type === "wave" ? SCBottomWave : SCBottomCurve;
    return (
      <>
        {top && <TopElement color={color} />}
        <SCWaveContainer $color={color} {...props} ref={ref}>
          {children}
        </SCWaveContainer>
        {bottom && <BottomElement color={color} bottom />}
      </>
    );
  }
);

interface SCWaveContainerProps {
  $color: Color;
}

const SCWaveContainer = styled.div<SCWaveContainerProps>`
  background-color: ${({ theme, $color = "baba" }) => theme.colors[$color]};
  position: relative;
  z-index: ${({ theme }) => theme.layout.zIndex.aboveSVG};
  color: ${({ $color }) => getTypographyColorForBackground($color)};
  /* fixes weird SVG clip-path border bug */
  margin-top: -${rem(1)};
  margin-bottom: -${rem(1)};

  ${breakpoints.xl`
    margin: -${rem(40)} 0;
  `}
`;

const SCBottomWave = styled(Wave)`
  position: relative;
  bottom: 0;
`;

const SCBottomCurve = styled(Curve)`
  position: relative;
  bottom: 0;
`;

export default WaveContainer;
