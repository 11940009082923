// @ts-nocheck
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import useWasPreviouslyOnPDP from "app/ui/shopping/hooks/useWasPreviouslyOnPDP";

/*
 * Inspired by: https://blog.logrocket.com/advanced-react-router-concepts-code-splitting-animated-transitions-scroll-restoration-recursive-17096c0cf9db
 */
const ScrollToTop = ({ children, location }) => {
  const wasPreviouslyOnPDP = useWasPreviouslyOnPDP();
  const prevPathname = useRef(location.pathname);

  useEffect(() => {
    const { pathname } = location;
    // wasPreviouslyOnPDP is checked because we don't want to lose the scroll
    // position of the page when coming back from the product details page.
    if (pathname !== prevPathname.current && !wasPreviouslyOnPDP) {
      window.scrollTo(0, 0);
    }
    prevPathname.current = pathname;
  });

  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
};

export default withRouter(ScrollToTop);
