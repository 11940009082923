import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "signup";

const prefixes = {
  SET_SELECTED_ZIP_CODE: `${domain}/SET_SELECTED_ZIP_CODE`,
  CREATE_ACCOUNT: `${domain}/CREATE_ACCOUNT`,
  SAVE_OOA_USER: `${domain}/SAVE_OOA_USER`,
  SET_DELIVERY_DETAILS: `${domain}/SET_DELIVERY_DETAILS`,
  SET_PAYMENT_DETAILS: `${domain}/SET_PAYMENT_DETAILS`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
  SET_SIGNUP_VALUES: `${domain}/SET_SIGNUP_VALUES`,
  STEP_COMPLETE: `${domain}/STEP_COMPLETE`,
  CURRENT_STEP_CHANGED: `${domain}/CURRENT_STEP_CHANGED`,
  SIGNUP_STARTED: `${domain}/SIGNUP_STARTED`,
  SIGNUP_ENDED: `${domain}/SIGNUP_ENDED`,
  SIGNUP_ABANDONED: `${domain}/SIGNUP_ABANDONED`,
  SYNC_SIGNUP_PREFERENCES: `${domain}/SYNC_SIGNUP_PREFERENCES`,
  CLEAR_ADD_ON_BOX_IDS: `${domain}/CLEAR_ADD_ON_BOX_IDS`,
  UPDATE_SIGNUP_CONFIG: `${domain}/UPDATE_SIGNUP_CONFIG`,
  SET_SELECTED_ZIP_CODE_CHECKOUT: `${domain}/SET_SELECTED_ZIP_CODE_CHECKOUT`,
  QUIZ_STEP_VIEWED: `${domain}/QUIZ_STEP_VIEWED`,
  SUBSCRIPTION_ZIP_ENTERED: `${domain}/SUBSCRIPTION_ZIP_ENTERED`,
  CONFIRMATION_TO_SHOPPING_CLICKED: `${domain}/CONFIRMATION_TO_SHOPPING_CLICKED`,
  CONFIRMATION_TO_ACCOUNT_CLICKED: `${domain}/CONFIRMATION_TO_ACCOUNT_CLICKED`,
  CONFIRMATION_GROCERY_CLICKED: `${domain}/CONFIRMATION_GROCERY_CLICKED`,
  // TODO: NC-1244 productionalize signup shopping
  UPDATE_VARIANT_QUANTITY: `${domain}/UPDATE_VARIANT_QUANTITY`,
  POPULATE_VARIANTS: `${domain}/POPULATE_VARIANTS`,
  CLEAR_VARIANT_QUANTITIES: `${domain}/CLEAR_VARIANT_QUANTITIES`,
  SET_SIGNUP_START_CARTS: `${domain}/SET_SIGNUP_START_CARTS`,
  UPDATE_CART: `${domain}/UPDATE_CART`,
  SIGNUP_WITH_MISFITS_MARKET_CLICKED: `${domain}/SIGNUP_WITH_MISFITS_MARKET_CLICKED`,
};
