// import { take, fork, call, select } from "redux-saga/effects";
import { take } from "redux-saga/effects";
// import _pickBy from "lodash/pickBy";
// import _isEmpty from "lodash/isEmpty";

import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import UI_ACTION_TYPES from "app/actionTypes/ui";
import SIGNUP_ACTION_TYPES from "app/actionTypes/signup";
// import { CRITICAL_DATA_REQUEST } from "app/api/request";
// import {
//   getUserAttributes,
//   updateUser,
//   OptimizelyCustomAttributesPayloadType,
// } from "app/api/optimizely";
// import { fetchDeliveryWindowByIdFlow } from "app/sagas/deliveries";
// import {
//   canNonAdminUserCustomize,
//   getAllUserStatesSubscriptionWindow,
//   getAllUserStatesSubscriptionWindowId,
//   getAllUserStatesSubscriptionWindowSummary,
//   getUserLifetimeOrderCount,
//   getUserLifetimeOrderRevenue,
// } from "app/selectors";

export default function* rootOptimizely() {
  while (true) {
    const action = yield take([
      ACCOUNT_ACTION_TYPES.ADD_VACATION_HOLD_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.REMOVE_VACATION_HOLD_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.SKIP_ORDER_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.UNSKIP_ORDER_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED,
      SIGNUP_ACTION_TYPES.SET_DELIVERY_DETAILS_SUCCEEDED,
      SIGNUP_ACTION_TYPES.SUBSCRIPTION_ZIP_ENTERED,
      UI_ACTION_TYPES.INITIALIZE_SUCCEEDED,
    ]);

    switch (action.type) {
      case ACCOUNT_ACTION_TYPES.ADD_VACATION_HOLD_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.REMOVE_VACATION_HOLD_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.SKIP_ORDER_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.UNSKIP_ORDER_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED:
      case SIGNUP_ACTION_TYPES.SET_DELIVERY_DETAILS_SUCCEEDED:
      case SIGNUP_ACTION_TYPES.SUBSCRIPTION_ZIP_ENTERED:
      case UI_ACTION_TYPES.INITIALIZE_SUCCEEDED: {
        // yield fork(identifyOptimizelyUserAttributes);
        break;
      }
      default:
        break;
    }
  }
}

// function* identifyOptimizelyUserAttributes() {
//   // ensure a user's subscription window, if available, is in state
//   const subscriptionWindowId = yield select(
//     getAllUserStatesSubscriptionWindowId
//   );
//   // note this does not accommodate for window exception
//   const subscriptionWindow = yield select(getAllUserStatesSubscriptionWindow);
//   if (subscriptionWindowId && !subscriptionWindow) {
//     yield call(fetchDeliveryWindowByIdFlow, {
//       deliveryWindowId: subscriptionWindowId,
//       includeNextDelivery: true,
//       tags: [CRITICAL_DATA_REQUEST],
//     });
//   }

//   const {
//     deliveryDay,
//     deliveryProvider,
//     fulfillmentCenterId,
//     fcName,
//     windowId,
//     zipCode,
//     zoneId,
//     zoneName,
//   } = yield select(getAllUserStatesSubscriptionWindowSummary);
//   const canCustomize = yield select(canNonAdminUserCustomize);
//   const lifetimeOrderCount = yield select(getUserLifetimeOrderCount);
//   const lifetimeOrderRevenue = yield select(getUserLifetimeOrderRevenue);
//   const currentUserAttributes = {
//     deliveryDay,
//     deliveryProvider,
//     fulfillmentCenterId,
//     fcName,
//     inCustomization: canCustomize,
//     lifetimeOrderCount,
//     lifetimeOrderRevenue,
//     windowId,
//     zipCode,
//     zoneId,
//     zoneName,
//   };

//   const loggedUserAttributes = yield call(getUserAttributes);

//   const changedUserAttributes = _pickBy(currentUserAttributes, (value, key) => {
//     return loggedUserAttributes?.[key]?.value !== value;
//   }) as OptimizelyCustomAttributesPayloadType;

//   if (_isEmpty(changedUserAttributes)) return;

//   yield call(updateUser, changedUserAttributes);
// }
