import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import theme from "app/styles/theme";

export interface ScrollingNumberProps {
  value: number;
  fontSize?: number;
  fontWeight?: string;
  lineHeight?: number;
  shouldDisappearWhenZero?: boolean;
  className?: string;
}

const ScrollingNumber: React.FC<ScrollingNumberProps> = ({
  value,
  fontSize = 14,
  lineHeight = 18,
  fontWeight = theme.fonts.weight.normal,
  shouldDisappearWhenZero = false,
  className,
}) => {
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const hideThisNumber = shouldDisappearWhenZero && value === 0;
  return (
    <NumberContainer
      $fontSize={fontSize}
      $lineHeight={lineHeight}
      className={className}
      aria-hidden="true"
    >
      <Numbers
        $value={value}
        $fontSize={fontSize}
        $hideThisNumber={hideThisNumber}
      >
        {numbers.map((number) => {
          return (
            <Number
              $fontSize={fontSize}
              $fontWeight={fontWeight}
              key={number}
              aria-hidden="true"
            >
              {number}
            </Number>
          );
        })}
      </Numbers>
    </NumberContainer>
  );
};

const NumberContainer = styled.div<{ $fontSize: number; $lineHeight: number }>`
  display: inline-block;
  overflow: hidden;
  position: relative;
  ${({ $fontSize, $lineHeight }) =>
    `
    top: ${rem(($lineHeight - $fontSize) / 2)};
    height: ${rem($fontSize)};
    `}
`;

const Numbers = styled.div<{
  $value: number;
  $fontSize: number;
  $hideThisNumber: boolean;
}>`
  transition: transform 500ms ease-out, opacity 1s ease,
    max-width 250ms ease 250ms;
  opacity: 1;
  max-width: 50px;

  ${({ $fontSize }) => `font-size: ${rem($fontSize)}`};
  ${({ $value }) => `transform: translateY(-${$value * 10}%)`};
  ${({ $hideThisNumber }) =>
    $hideThisNumber &&
    `
    opacity: 0;
    max-width: 0;
  `};
`;

const Number = styled.span<{ $fontSize: number; $fontWeight: string }>`
  display: block;
  ${({ $fontSize }) => `line-height: ${rem($fontSize)}`};
  ${({ $fontWeight }) => `font-weight: ${$fontWeight}`}
`;

export default ScrollingNumber;
