import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { getImageURL } from "app/ui/global/utils";

export enum ImageType {
  PUMPKIN_BOTTOM_CORNER = "pumpkin-bottom-corner",
  PUMPKIN_BOTTOM_EDGE = "pumpkin-bottom-edge",
}

export interface AisleNavWidgetProps
  extends React.HTMLAttributes<HTMLDivElement> {
  imageType?: ImageType;
}

const AisleNavWidget: React.FC<AisleNavWidgetProps> = ({
  children,
  className,
  imageType = ImageType.PUMPKIN_BOTTOM_CORNER,
}) => {
  const imageUrl = getImageURL(imageType, "v1633390276/website-assets");

  return (
    <Container className={className} $imageType={imageType}>
      {children}
      <Image src={imageUrl} alt="imperfect-character" $imageType={imageType} />
    </Container>
  );
};

export default AisleNavWidget;

const Container = styled.div<{ $imageType: ImageType }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${rem(8)} ${rem(16)};
  border-radius: ${rem(4)};
  background-color: ${({ theme }) => theme.colors.baba};

  ${({ $imageType }) =>
    $imageType === ImageType.PUMPKIN_BOTTOM_CORNER &&
    `
    min-height: ${rem(56)};
    padding-left: ${rem(56)};
  `}

  ${({ $imageType }) =>
    $imageType === ImageType.PUMPKIN_BOTTOM_EDGE &&
    `
    min-height: ${rem(64)};
    padding-left: ${rem(144)};
  `}
`;

const Image = styled.img<{ $imageType: ImageType }>`
  position: absolute;
  bottom: 0;

  ${({ $imageType }) =>
    $imageType === ImageType.PUMPKIN_BOTTOM_CORNER &&
    `
    left: 0;
    height: ${rem(52)};
  `}

  ${({ $imageType }) =>
    $imageType === ImageType.PUMPKIN_BOTTOM_EDGE &&
    `
    left: ${rem(24)};
    height: ${rem(60)};
  `}
`;
