import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { Box, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import fonts from "app/styles/fonts";
import { Overline4 } from "app/ui/shared/ThemedTypography";
import RadioButton, {
  RadioButtonProps,
} from "app/ui/designSystem/molecules/buttons/Secondary/RadioButton";

export type RadioCardProps = Omit<RadioButtonProps, "isPill" | "label"> & {
  title: string;
  subtitle1?: string | React.ReactNode;
  subtitle2?: string | React.ReactNode;
  ariaLabel?: string;
};

export const RadioCard: React.FC<RadioCardProps> = (props) => {
  const {
    title,
    subtitle1,
    subtitle2,
    ariaLabel,
    disabled,
    checked,
    fullWidth,
    isCentered,
    ...radioProps
  } = props;

  return (
    <RadioButtonWithStyles
      checked={checked}
      disabled={disabled}
      fullWidth={fullWidth}
      isCentered={isCentered}
      inputProps={ariaLabel ? { "aria-label": ariaLabel } : {}}
      {...radioProps}
      label={
        <Label
          title={title}
          subtitle1={subtitle1}
          subtitle2={subtitle2}
          checked={checked}
          disabled={disabled}
        />
      }
    />
  );
};

const RadioButtonWithStyles = withStyles({
  root: {
    borderRadius: rem(8),
    padding: 0,
    position: "relative",
    minWidth: rem(192),
  },
  radio: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: rem(6),
  },
})(RadioButton);

const Label = ({ title, subtitle1, subtitle2, checked, disabled }) => (
  <Box
    alignItems="center"
    component="span"
    display="flex"
    flexDirection="column"
    p={2}
  >
    <Title
      align="center"
      component="span"
      gutterBottom
      $checked={checked}
      $disabled={disabled}
    >
      {title}
    </Title>
    {subtitle1 && (
      <Subtitle1
        align="center"
        component="span"
        variant="body1"
        $checked={checked}
      >
        {subtitle1}
      </Subtitle1>
    )}
    {subtitle2 && (
      <Subtitle2>
        <Box textAlign="center">{subtitle2}</Box>
      </Subtitle2>
    )}
  </Box>
);

const Title = styled(Overline4)<{
  $checked: RadioCardProps["checked"];
  $disabled: RadioCardProps["disabled"];
  component?: React.ElementType;
}>`
  font-size: ${rem(13)};
  color: ${({ theme, $disabled, $checked }) =>
    ($disabled && theme.colors.barley) ||
    ($checked && theme.colors.beet) ||
    theme.colors.taro};
`;

const Subtitle1 = styled(Typography)<{
  $checked: RadioCardProps["checked"];
  component?: React.ElementType;
}>`
  font-size: ${rem(16)};
  font-weight: ${({ $checked }) =>
    ($checked && +fonts.weight.semiBold) || +fonts.weight.normal};
  line-height: ${rem(24)};
`;

export const Subtitle2 = ({ children }) => (
  <SCTypography component="span" variant="body2">
    {children}
  </SCTypography>
);

const SCTypography = styled(Typography)`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
` as typeof Typography;

export default RadioCard;
