import { take, fork, call } from "redux-saga/effects";
import describeCustomerFlow from "app/sagas/chat/kustomer";

import UI_ACTION_TYPES from "app/actionTypes/ui";

export default function* rootChat() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([UI_ACTION_TYPES.INITIALIZE_SUCCEEDED]);

    switch (action.type) {
      // We re-initialize when we login, create an account (signup), and at startup
      case UI_ACTION_TYPES.INITIALIZE_SUCCEEDED: {
        if (action.loggedIn) {
          yield fork(identifyChatUserFlow);
        }
        break;
      }

      default:
        break;
    }
  }
}

export function* identifyChatUserFlow() {
  yield call(describeCustomerFlow);
}
