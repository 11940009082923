import { DeferredCoupon } from "app/types/account/Coupon";
import { SignupCadence } from "../signup";
import AddPaymentSourceParams, {
  AddPaymentSourceRequestParams,
} from "./AddPaymentSourceParams";

type AccountState = {
  user: User;
  creditBalance: number;
  creditTransactions: CreditTransaction[];
  paymentSources: {
    loading: boolean;
    defaultSourceId: string | null;
    sources: PaymentSource[];
    mapOfPaymentSources: Record<string, PaymentSource>;
  };
  signupReasons: SignUpReason[];
  cancellationReasons: CancellationReasonType[];
  removingCoupon: boolean | null;
  winbackAccepted: boolean;
  shouldAutoSkipOrder: boolean;
  shouldAutoSwitchCadence: boolean;
  lastAutoSkippedOrder: { deliveryDate: string } | null;
  skipActionInProgress: boolean | null;
  cancelSubscriptionStatus: CancelSubscriptionStatus | null;
  markMessageAsReadInProgress: boolean | null;
  addingSecondaryBoxIds: string[];
  rewardCouponUnused: boolean;
};

export interface User {
  defaultAddressId: string | null;
  addresses: { addressId: string }[];
  mapOfAddresses: Record<string, AccountAddress>;
  signupPreferences: {
    boxId: string | null;
    cadence: SignupCadence;
    zip: string | null;
  };
  messagesInQueue: {
    messageId: string;
  }[];
  neverList: string[];
  recurringItems: RecurringItems;
  phone: string | null;
  cadence: SignupCadence;
  cadenceOffset: SignupOffset;
  signupZip?: string;
  firstName?: string;
  lastName?: string;
  treatments?: Record<string, unknown>;
  preferences?: unknown | null;
  lifetimeOrderCount?: number;
  lifetimeOrderRevenue?: number;
  lifetimeOrderTimestamp?: string;
  appliedForReduceCostBox?: boolean;
  userId?: string;
  email?: string;
  alphaStatus?: boolean;
  stripeCustomerId?: string;
  dbpCustomerId?: string | null;
  created?: string;
  migrationStatus?: unknown | null;
  auth0Ids?: string[];
  referringUserId?: string | null;
  referringUserReferralCode?: string | null;
  // first-gen
  referringUserCampaignId?: string | null;
  // next-gen
  referringCampaignId?: string | null;
  referralDate?: string | null;
  updated?: string;
  isOnWarmingHold?: boolean;
  isOnWaitlist?: boolean;
  waitList?: unknown | null;
  fulfillmentCenterId?: string;
  subscriptionId?: string;
  deliveryWindowId?: string;
  subscriptionBoxId?: string;
  boxId?: string;
  addonBoxIds?: string[];
  holds?: unknown[];
  createDate?: string;
  timezone?: string;
  stage?: string;
  dishFavorites?: string[];
  subscriptions?: Subscription[];
  roles?: string[];
  groups?: Group[];
  deferredCoupon?: DeferredCoupon;
}

export type PaymentGateway = "Stripe" | "CreditService";

export type PaymentStatus =
  | "Authed"
  | "Captured"
  | "AuthCanceled"
  | "CaptureRefunded";

export interface PaymentSource {
  /* eslint-disable camelcase */
  id: string;
  object?: string;
  address_city?: string;
  address_country?: string;
  address_line1?: string;
  address_line1_check?: string;
  address_line2?: string;
  address_state?: string;
  address_zip?: string;
  address_zip_check?: string;
  brand?: string;
  country?: string;
  customer?: string;
  cvc_check?: string;
  exp_month?: number;
  exp_year?: number;
  fingerprint?: string;
  funding?: string;
  last4?: string;
  metadata?: unknown;
  name?: string;
  /* eslint-enable camelcase */
}

export interface AccountAddress {
  addressId: string;
  address: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  deliveryNotes?: string;
  timezone?: string;
  latitude?: string;
  longitude?: string;
}

export interface RecurringItemProduct {
  quantity: number;
}

export interface NeverItem {
  itemType: string;
  itemId: string;
}

export interface StripePaymentSource {
  /* eslint-disable camelcase */
  address_city?: string;
  address_country?: string;
  address_line1?: string;
  address_line2?: string;
  address_state?: string;
  address_zip?: string;
  exp_month?: string;
  exp_year?: string;
  metadata?: Record<string, unknown>;
  /* eslint-enable camelcase */
}

export type SignupOffset = number | null;

export interface AccountDeliveryInfo {
  address: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  deliveryNotes: string;
  phone: string;
  firstName: string;
  lastName: string;
  deliveryWindowId?: string;
}

export interface AddressType {
  address: AccountDeliveryInfo["address"];
  addressLine2?: AccountDeliveryInfo["addressLine2"];
  city: AccountDeliveryInfo["city"];
  state: AccountDeliveryInfo["state"];
  zip: AccountDeliveryInfo["zip"];
  deliveryWindowId?: AccountDeliveryInfo["deliveryWindowId"];
}

export interface RecurringItems {
  products: Record<string, RecurringItemProduct>;
  variants?: Record<string, RecurringItemProduct>;
}

export interface SignUpReason {
  signupReasonId: string;
  signupReason: string;
  allowsNotes: boolean;
  orderBy: number;
  archived: boolean;
}

// Refers to the cancellation reasons returned from the backend
export interface CancellationReasonType {
  id: string;
  reason: string;
  allowsNotes: boolean;
}

// Refers to the cancellation reason and optional notes chosen by the user
// in the cancellation flow
export interface CancellationReason {
  cancellationReasonId: string;
  cancellationReasonNotes?: string;
}

export enum CancelSubscriptionStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

interface CreditTransaction {
  transactionId: string;
  userId: string;
  amount: number;
  metadata: unknown;
  createdUserId: string;
  transactionDate: string;
  creditType: string;
  sequence: number;
  type?: string;
}

interface Subscription {
  subscriptionId: string;
  startDate: string;
  endDate: string | null;
}

interface Group {
  userGroupId: string;
  groupId: string;
  type: string;
  name: string;
  metadata: Record<string, unknown>;
  startDate: string;
  endDate: string | null;
  userGroupMetadata: Record<string, unknown>;
}

export type AddPaymentSourceAction = (
  params: AddPaymentSourceRequestParams
) => AddPaymentSourceParams;

export default AccountState;
