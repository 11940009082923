import React from "react";
import { useSelector } from "react-redux";

import {
  getOfferingByVariantId,
  getRecurringItemsTimeSinceLastNudge,
  shouldAllowRecurringItemsNudge,
  shouldShowRecurringItemOffer,
  canViewSignupShopping,
  isInScalePriceTreatment,
} from "app/selectors";
import { getShelfInfoFromShelfId } from "app/selectors/shelves";
import ViewableOffering from "app/types/selectors/crossDomain/ViewableOffering";
import State from "app/types/state";
import OfferingPreviewCard, {
  ProfileType,
} from "app/ui/shopping/OfferingPreviewCard";

interface OfferingPreviewCardContainerProps {
  variantId: string;
  profileType?: ProfileType;
  className?: string;
  source: string;
  rank?: number;
  fromShelfId: string;
  allowRecurringItemsNudge?: boolean;
  isRecipe?: boolean;
}

const OfferingPreviewCardContainer: React.FC<OfferingPreviewCardContainerProps> = ({
  variantId,
  profileType,
  className,
  source,
  rank,
  fromShelfId,
  allowRecurringItemsNudge,
  isRecipe,
}) => {
  const shelfInfo = useSelector((state: State) =>
    getShelfInfoFromShelfId(state, fromShelfId)
  );
  const inScalePriceTreatment = useSelector(isInScalePriceTreatment);
  const offering = useSelector((state: State) =>
    getOfferingByVariantId(state, { variantId, inScalePriceTreatment })
  ) as ViewableOffering | null;

  const defaultAllowRecurringItemsNudge = useSelector((state: State) =>
    shouldAllowRecurringItemsNudge(state)
  );
  const shouldShowRecurringNudge = useSelector((state: State) =>
    shouldShowRecurringItemOffer(state)
  );
  const timeOfLastAttemptedNudge = useSelector((state: State) =>
    getRecurringItemsTimeSinceLastNudge(state)
  );

  const isInSignupShopping = useSelector(canViewSignupShopping);

  const activeAllowRecurringItemsNudge =
    allowRecurringItemsNudge ?? defaultAllowRecurringItemsNudge;

  return (
    <OfferingPreviewCard
      profileType={profileType}
      offering={offering}
      className={className}
      source={source}
      allowRecurringItemsNudge={activeAllowRecurringItemsNudge}
      shouldShowRecurringNudge={shouldShowRecurringNudge}
      timeOfLastAttemptedNudge={timeOfLastAttemptedNudge}
      rank={rank}
      fromShelfId={fromShelfId}
      isRecipe={isRecipe}
      shelfInfo={shelfInfo}
      isInSignupShopping={!!isInSignupShopping}
    />
  );
};

export default OfferingPreviewCardContainer;
