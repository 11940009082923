// @ts-nocheck
import queryString from "query-string";
import { call } from "redux-saga/effects";

import request from "./request";

import { config } from "app/config";

const baseUrl = `${config.get("endpoints.gateway")}/deliveries`;

export function* fetchWindowById(params) {
  const { tags, deliveryWindowId, ...others } = params;
  const query = others ? `?${queryString.stringify(others)}` : "";
  const url = `${baseUrl}/windows/${deliveryWindowId}${query}`;
  return yield call(request, url, { tags }, "json", false);
}

export function* fetchWindowsByZip(params) {
  const { tags, ...others } = params;
  const url = `${baseUrl}/windows?${queryString.stringify(others)}`;
  return yield call(request, url, { tags }, "json", false);
}

export function* fetchFlexibleDeliveries(params) {
  const { zipCode, subscriptionWindowId, userId } = params;
  const url = `${baseUrl}/windows/${subscriptionWindowId}/${zipCode}/flexible-deliveries/${userId}`;
  return yield call(request, url, {}, "json", true); // authenticated call
}

export function* fetchShipmentTrackingInfo(params) {
  const { orderId, userId } = params;
  const url = `${baseUrl}/shipments/${userId}/${orderId}/order-tracking`;
  return yield call(request, url, {}, "json", true);
}
