import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "extended_split";
const prefixes = {
  GET_TREATMENTS: `${domain}/GET_TREATMENTS`,
  GET_GLOBAL_TREATMENTS: `${domain}/GET_GLOBAL_TREATMENTS`,
  INITIALIZE_SPLIT: `${domain}/INITIALIZE_SPLIT`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  // Split Redux SDK is a layer over Split JS SDK
  // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#advanced-subscribe-to-events-and-changes
  // https://help.split.io/hc/en-us/articles/360038851551-Redux-SDK#advanced-subscribe-to-events-and-changes
  // https://github.com/splitio/redux-client/blob/master/src/constants.ts

  // Redux action emitted when SDK_READY is triggered
  SPLIT_READY: `SPLIT_READY`,
  // Redux action emitted when SDK_READY_TIMED_OUT is triggered
  SPLIT_TIMEDOUT: `SPLIT_TIMEDOUT`,
  // Redux action emitted when SDK_UPDATE is triggered
  // When 'evalOnUpdate' is set, SPLIT_UPDATE_WITH_EVALUATIONS is called instead
  SPLIT_UPDATE: `SPLIT_UPDATE`,

  //
  // The following events have treatments attached
  //

  // Split calls this when treatments are first requested
  ADD_TREATMENTS: "ADD_TREATMENTS",

  // Split has new treatment evaluations when SDK_UPDATE
  // Triggered if 'evalOnUpdate' passed in @splitsoftware/splitio-redux.getTreatments
  // Currently set in app/sagas/split
  SPLIT_UPDATE_WITH_EVALUATIONS: "SPLIT_UPDATE_WITH_EVALUATIONS",

  // Split has new treatment evaluations when SDK_READY
  // Triggered if 'evalOnReady' passed in @splitsoftware/splitio-redux.getTreatments
  // Not currently used, but adding for posterity
  SPLIT_READY_WITH_EVALUATIONS: "SPLIT_READY_WITH_EVALUATIONS",
  ...actionTypes,
};
