import React, { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink,
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { parseLocationQuery } from "app/router/routes";
import { scrollWithOffset, stopPropagation } from "app/ui/global/utils";

export interface LinkProps extends RouterLinkProps {
  disabled?: boolean;
  exact?: boolean;
  external?: boolean;
  externalTarget?: string;
  newWindow?: boolean;
  activeClassName?: string;
  hash?: string;
  offset?: number;
  hashAndQuery?: boolean;
  smooth?: boolean;
}

/* eslint-disable object-curly-newline, jsx-a11y/anchor-has-content */
const Link: React.FC<LinkProps> = ({
  external,
  externalTarget = "_blank",
  exact,
  newWindow,
  hash,
  offset,
  to,
  disabled = false,
  activeClassName,
  hashAndQuery = false,
  innerRef,
  ...restProps
}) => {
  if (!to || disabled === true) {
    /* eslint-disable
      jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    return (
      <span
        ref={innerRef}
        {...restProps}
        // @ts-ignore
        onClick={disabled ? stopPropagation : restProps.onClick}
      />
    );
    /* eslint-enable
      jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  }
  // @ts-ignore
  if (to.hash && !hashAndQuery) {
    return (
      <HashLink
        innerRef={innerRef}
        to={to}
        // @ts-ignore
        scroll={
          offset ? (el) => scrollWithOffset(el, offset, restProps.smooth) : null
        }
        {...restProps}
      />
    );
  }
  if (external) {
    return (
      <a
        // @ts-ignore
        href={to}
        ref={innerRef}
        {...(newWindow
          ? { target: externalTarget, rel: "noopener noreferrer" }
          : {})}
        {...restProps}
      />
    );
  }
  if (activeClassName) {
    return (
      <NavLink
        to={parseLocationQuery(to)}
        activeClassName={activeClassName}
        exact={exact}
        innerRef={innerRef}
        {...restProps}
      />
    );
  }
  return <RouterLink to={parseLocationQuery(to)} {...restProps} />;
};
/* eslint-enable object-curly-newline, jsx-a11y/anchor-has-content */

export const StrippedLink: React.FC<LinkProps> = (props) => <Link {...props} />;

// For use with MUI, for example: <Button component={RouterLinkWithRef} to={}> // TODO: Standard link components can be sent to MUI, so I'm confused here
export const RouterLinkWithRef = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ to, ...restProps }: RouterLinkProps, ref) => (
    <RouterLink ref={ref} to={parseLocationQuery(to)} {...restProps} />
  )
);

export const LinkWithRef = forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link innerRef={ref} {...props} />
);

export default Link;
