import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "reactivation";
const prefixes = {
  COMPLETE_REACTIVATION: `${domain}/COMPLETE_REACTIVATION`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
};
