import {
  DietType,
  GroceryType,
  ShopperOptionsType,
} from "app/constants/signup";
import SubscriptionPosit from "app/types/state/account/SubscriptionPosit";
import { FullWindowData } from "app/types/state/deliveries/deliveries";
import { BaseCoupon } from "app/types/state/orders/OrderCoupon";
import Order from "app/types/state/orders/Order";

import BaseAction from "app/types/state/BaseAction";

export type SignupCadence = "WEEKLY" | "BIWEEKLY" | null;

// https://docs.google.com/drawings/d/1KhdVz3LjOMtNnmPp5uGEkaZ8I21qC0WQsGMc8zvGuE0/edit
// Analytics: Signup Started
// For Each Below: Analytics: Signup Step Viewed + step
export enum SignupSteps {
  JOIN = "join",
  SUBSCRIPTION = "subscription",
  QUIZ_WHO_DO_YOU_SHOP_FOR = "who-do-you-shop-for",
  QUIZ_FIRST_DELIVERY = "first-delivery",
  QUIZ_SHOPPING_WINDOW = "shopping-window",
  QUIZ_PRODUCE = "produce",
  QUIZ_DIETARY_RESTRICTIONS = "dietary-restrictions",
  QUIZ_WHAT_DO_YOU_BUY = "what-do-you-buy",
  QUIZ_SUMMARY = "summary",
  CREDENTIALS = "credentials",
  DELIVERY = "delivery",
  SHOP = "shop",
  PAYMENT = "payment",
  CONFIRMATION = "confirmation",
  SHOP_PRODUCT_DETAILS_PAGE = "shopping-product-details-page",
}

export type Step = {
  title: string;
  url: string;
  banner?: string;
  isQuizStep?: boolean;
  isExceptionIneligible?: boolean;
  quizValueKey?: SignupQuizKeys; // quiz steps not requiring user input will not have a quizValueKey
};

export interface SignupConfig {
  flow: SignupFlowSteps[];
  confirmation: SignupSteps;
  metadata: Record<SignupSteps, Step>;
}

export type SignupStepName = keyof SignupConfig["metadata"];

// We use this enough to warrant it being its own type
export type SignupFlowSteps = Exclude<SignupSteps, SignupSteps.CONFIRMATION>;

export type FirstDeliveryType = string;

export interface SignupValuesQuizValues {
  [SignupSteps.QUIZ_WHO_DO_YOU_SHOP_FOR]?: {
    [key in ShopperOptionsType]: number;
  };
  [SignupSteps.QUIZ_FIRST_DELIVERY]?: FirstDeliveryType;
  [SignupSteps.QUIZ_PRODUCE]?: number;
  [SignupSteps.QUIZ_DIETARY_RESTRICTIONS]?: DietType;
  [SignupSteps.QUIZ_WHAT_DO_YOU_BUY]?: GroceryType[];
}

export interface SignupValues {
  selectedFrequency: string;
  boxId: string;
  boxType: string;
  addOnBoxIds: string[];
  zip?: string;
  deliveryWindows?: string[];
  selectedDeliveryWindowId?: string;
  email?: string;
  signupOutOfArea?: boolean;
  quizValues: SignupValuesQuizValues;
  signupReasonId?: string;
  signupReasonNotes?: string;
  firstName: string;
  lastName: string;
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  phone: string;
  deliveryNotes: string;
}

// These values should be reset from signup state in the case of a zip code change after signup has started
export type ResetSignupValues = Pick<
  SignupValues,
  | "boxId"
  | "addOnBoxIds"
  | "quizValues"
  | "address"
  | "addressLine2"
  | "city"
  | "state"
  | "deliveryNotes"
>;

export type SignupQuizKeys = keyof SignupValuesQuizValues;

export type SignupQuizValues = SignupValuesQuizValues[SignupQuizKeys];

export interface SignupVariantQuantity {
  variantId: string;
  quantity: number;
}

export default interface SignupState {
  initialized: boolean;
  currentStep: SignupFlowSteps | null;
  progressStep: SignupFlowSteps | null;
  config: SignupConfig;
  values: SignupValues;
  prospectiveCoupon: BaseCoupon | null;
  subscriptionPosit: SubscriptionPosit | null;
  subscriptionPositHasExceptions?: boolean;
  variantQuantities: SignupVariantQuantity[];
  orderPosit: Order | null;
  isPrefilledCartFilled: boolean;
  utmParams: Record<string, string>;
  gclid: string | undefined;
}

export type SetSelectedZipCodeSucceededAction = BaseAction & {
  zip: string;
  deliveryWindows: FullWindowData[];
  selectedDeliveryWindowId: string;
  shouldResetSignupValues: boolean;
};
