// @ts-nocheck
import { take, fork, call, put, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/aisles";
import { fetchAisles } from "app/api/productService";
import {
  getFulfillmentCenter,
  getActiveOrder,
  getSelectedTagIds,
  getNextDeliveryPriceZoneFcAndDate,
  isInRecentlyAddedTreatment,
} from "app/selectors";
import { getRecentlyAddedShelf } from "app/reducers/shelves";

export default function* rootAisles() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([ACTION_TYPES.FETCH_AISLES]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_AISLES: {
        yield fork(fetchAislesFlow, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* fetchAislesFlow(action = {}) {
  const { overrides, tags } = action;

  try {
    // TODO: NC-1244 productionalize signup shopping
    const {
      packDate: nextDeliveryPackDate,
      fulfillmentCenterId: nextDeliveryFulfillmentCenterId,
    } = yield select(getNextDeliveryPriceZoneFcAndDate);

    const accountFulfillmentCenterId = yield select(getFulfillmentCenter);
    const activeOrder = yield select(getActiveOrder);
    const selectedTagIds = yield select(getSelectedTagIds);

    const fulfillmentCenterId =
      overrides?.fulfillmentCenterId ||
      accountFulfillmentCenterId ||
      nextDeliveryFulfillmentCenterId;

    const packDate =
      overrides?.packDate || activeOrder.packDate || nextDeliveryPackDate;

    const aisles = yield call(fetchAisles, {
      fulfillmentCenterId,
      packDate,
      tags,
    });

    const inRecentlyAddedTreatment = yield select(isInRecentlyAddedTreatment);

    if (inRecentlyAddedTreatment) {
      const featuredAisleId = "dd81968a-2dca-4b38-a23c-6e775b99c9ca";
      const recentlyAddedShelfInfo = yield select(getRecentlyAddedShelf);

      aisles.forEach((aisle) => {
        if (
          aisle.aisleId === featuredAisleId &&
          recentlyAddedShelfInfo?.recentlyAddedShelfVariantIds?.length
        ) {
          aisle.shelves.unshift(recentlyAddedShelfInfo.recentlyAddedShelf);
        }
      });
    }

    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_AISLES_SUCCEEDED,
      aisles,
      filterTag: selectedTagIds.length > 0 && selectedTagIds[0],
    });
  } catch (error) {
    yield put({ ...action, type: ACTION_TYPES.FETCH_AISLES_FAILED, error });
  }
}
