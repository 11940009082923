import { config } from "app/config";
import { SetContextFunc, Context } from "app/types/monitoring";
import * as ddrum from "app/monitoring/ddrum";
import * as ddlog from "app/monitoring/ddlog";
import { sanitizer } from "@imperfectproduce/node-logger";

export const setContext: SetContextFunc = (
  anonymousId = null,
  userId = null,
  isImpersonated = false
) => {
  const context: Context = {
    userId,
    anonymousId,
    isImpersonated,
    buildId: config.get("app.build"),
  };

  ddlog.setContext(context);
  ddrum.setContext(context);

  // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-sessions
  if (userId) {
    ddrum.setUser({ id: userId });
  } else {
    ddrum.removeUser();
  }
};

export const addError = (error: unknown, context?: object) => {
  reportToConsoleForLocalDev(error);

  // In the future, we can consider using the beforeSend callback
  // only available in the init function at this moment.
  // The benefit of this approach is that we'll sanitize events that occur outside of the app
  // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=cdnasync#modify-the-content-of-a-rum-event
  const sanitizedError = safeSanitize(error);

  ddrum.addError(sanitizedError, context);
};

export const sendLog = (data: object) => {
  reportToConsoleForLocalDev(data);

  // sanitization occurs in ddlog-layer
  // Similar to the rum init, we can use the beforeSend in the logger
  // The benefit of this approach is that we'll sanitize events that occur outside of the app
  // https://docs.datadoghq.com/logs/log_collection/javascript/#scrub-sensitive-data-from-your-browser-logs
  ddlog.sendLog(data);
};

export const safeSanitize = (data: unknown) => {
  if (data instanceof Error) {
    sanitizeErrorField(data, "message");
    sanitizeErrorField(data, "stack");
    return data;
  }
  return sanitizer(data);
};

const sanitizeErrorField = (error: Error, key: "message" | "stack") => {
  if (error[key]) {
    try {
      // eslint-disable-next-line no-param-reassign
      error[key] = sanitizer(error[key]);
    } catch (e) {
      // ignore
    }
  }
};

const reportToConsoleForLocalDev = (data: unknown) => {
  // To see errors during development, set NODE_ENV=local
  if (config.get("env") === "local") {
    // eslint-disable-next-line no-console
    console.log(data);
  }
};
