// @ts-nocheck
import { call, put, select, all } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/account";
import { fetchNextDelivery } from "app/api/userService";
import { fetchDeliveryWindowByIdFlow } from "app/sagas/deliveries";
import triageResetShoppingFlow from "app/sagas/triageResetShoppingFlow";
import {
  getUserId,
  getActiveOrder,
  getUserDeliveryWindowId,
  getBaseOrderRequestParams,
  getReactivationData,
  isInCatalogPreviewToolTreatment,
} from "app/selectors";
import { config } from "app/config";

import { fetchNextDeliverySucceededAction } from "app/reducers/deliveries";

interface FetchNextDeliveryActionType {
  type?: string;
  tags?: string[];
  params?: Record<string, unknown>;
}

function* fetchNextDeliveryFlow(action: FetchNextDeliveryActionType = {}) {
  const { tags = [], params: extraParams = {} } = action;

  try {
    const userId = yield select(getUserId);
    const windowId = yield select(getUserDeliveryWindowId);
    const activeOrder = yield select(getActiveOrder);
    const baseOrderParams = yield select(getBaseOrderRequestParams);
    const inCatalogPreviewToolTreatment = yield select(
      isInCatalogPreviewToolTreatment
    );
    // the catalog preview accounts' start carts are holding inventory, this prevents that hold
    const shouldCreateStartCart = !(
      config.get("env") === "production" && inCatalogPreviewToolTreatment
    );
    const params = {
      expand: "order",
      pushStartCartIfNeeded: shouldCreateStartCart,
      userId,
      tags,
      ...baseOrderParams,
      ...extraParams,
    };

    const [nextDelivery, window] = yield all([
      call(fetchNextDelivery, params),
      windowId &&
        call(fetchDeliveryWindowByIdFlow, {
          tags,
          deliveryWindowId: windowId,
          includeNextDelivery: true,
        }),
    ]);

    yield call(
      triageResetShoppingFlow,
      activeOrder?.orderId,
      nextDelivery?.orderId
    );
    const nextAvailableWindowDelivery = window ? window.nextDelivery : null;
    yield put(
      fetchNextDeliverySucceededAction({
        ...action,
        ...nextDelivery,
        nextAvailableWindowDelivery,
      })
    );

    // If the order windowId doesn't match the subscription, we need to fetch the order windowId
    // to support various features (like packaging return program)
    // Alternatively, fetch the reactivation window
    const latestActiveOrder = yield select(getActiveOrder);
    const activeOrderWindowId = latestActiveOrder?.deliveryWindowId;
    const reactivationData = yield select(getReactivationData);

    let altWindowId;
    if (activeOrderWindowId && activeOrderWindowId !== windowId) {
      altWindowId = activeOrderWindowId;
    } else if (reactivationData?.deliveryWindowId) {
      altWindowId = reactivationData.deliveryWindowId;
    }

    if (altWindowId) {
      yield call(fetchDeliveryWindowByIdFlow, {
        tags,
        deliveryWindowId: altWindowId,
        includeNextDelivery: true,
      });
    }

    return {
      nextDelivery,
      nextAvailableWindowDelivery,
    };
  } catch (e) {
    yield put({ ...action, type: ACTION_TYPES.FETCH_NEXT_DELIVERY_FAILED, e });
    return e;
  }
}

export default fetchNextDeliveryFlow;
