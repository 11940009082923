import { rem } from "polished";
import React, { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { toShopProductDetailPage } from "app/router/routes";
import breakpoints from "app/styles/breakpoints";
import ViewableOffering from "app/types/selectors/crossDomain/ViewableOffering";
import { stripBrandNameFromOfferingName } from "app/ui/global/utils";
import CartItemImage from "app/ui/shopping/Cart/CartItemImage";
import CartItemPrice from "app/ui/shopping/Cart/CartItemPrice";
import CartItemProductName from "app/ui/shopping/Cart/CartItemProductName";
import CartItemPurchaseButtons from "app/ui/shopping/Cart/CartItemPurchaseButtons";
import CartItemRemoveUndo from "app/ui/shopping/Cart/CartItemRemoveUndo";
import UnstyledLink from "app/ui/shared/UnstyledLink";

export interface CartItemProps {
  last: boolean;
  offering: ViewableOffering;
  onCartItemClick: () => void;
  onRemoveItemCompleted: () => void;
  onDecrement?: () => void;
  onIncrement?: () => void;
  onRecurringClick: () => void;
  onRemoveItemFromCart: () => void;
  onUndoClicked: () => void;
  showUndo: boolean;
}

const CartItem: React.FC<CartItemProps> = ({
  last,
  offering,
  onCartItemClick,
  onRemoveItemCompleted,
  onDecrement,
  onIncrement,
  onRecurringClick,
  onRemoveItemFromCart,
  onUndoClicked,
  showUndo,
}) => {
  const [collapsing, setCollapsing] = useState(false);
  const timeoutId = useRef<number>();

  const removeItemFromCart = () => {
    onRemoveItemFromCart();

    timeoutId.current = window.setTimeout(() => {
      setCollapsing(true);
    }, 3000);
  };

  const handleCollapseEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    if (event.animationName === "collapseCartItem") {
      onRemoveItemCompleted();
    }
  };

  const handleUndoClicked = () => {
    onUndoClicked();
    clearTimeout(timeoutId.current);
    timeoutId.current = undefined;
  };

  const productNameStripped = stripBrandNameFromOfferingName(
    offering.productName,
    offering.brandName
  );

  return (
    <Container
      onAnimationEnd={(e) => handleCollapseEnd(e)}
      $collapse={collapsing}
    >
      <SlidingContent $showUndo={showUndo}>
        <CartItemContainer $last={last} $showUndo={showUndo} tabIndex={-1}>
          <CartImageAndNameContainerLink
            to={toShopProductDetailPage({ variantId: offering.variantId })}
            onClick={onCartItemClick}
            data-testid="cart-item-link"
            onMouseDown={(e) => e.preventDefault()}
          >
            <StyledCartItemImage
              alt=""
              filename={offering.imageFilename}
              onRecurringClick={onRecurringClick}
              showRecurringIcon={offering.inRecurringItems}
            />
            <StyledCartItemProductName
              brandName={offering.brandName}
              productName={offering.productName}
              packagingUnitAmount={offering.packagingUnitAmount}
              packageUnitFormatted={offering.packageUnitFormatted}
              realUnitAmount={offering.realUnitAmount}
              realUnitFormatted={offering.realUnitFormatted}
              realUnitIsApproximate={offering.realUnitIsApproximate}
              realUnitIsIndividual={offering.realUnitIsIndividual}
            />
          </CartImageAndNameContainerLink>
          <StyledCartItemPurchaseButtons
            offering={offering}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            removeItemFromCart={removeItemFromCart}
            showUndo={showUndo}
          />
          <StyledCartItemPrice
            activePrice={offering.activePrice}
            fullPrice={offering.fullPrice}
            scalePrice={offering.scalePrice}
            scaleMinQuantity={offering.scaleMinQuantity}
            quantity={offering.quantity}
          />
        </CartItemContainer>
        <CSSTransition
          classNames="remove-undo-transition"
          in={showUndo}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <StyledCartItemRemoveUndo
            disabled={collapsing}
            undoClicked={handleUndoClicked}
            productName={productNameStripped}
          />
        </CSSTransition>
      </SlidingContent>
    </Container>
  );
};

export default CartItem;

const Container = styled.div<{ $collapse: boolean }>`
  overflow: hidden;

  ${breakpoints.sm`
    padding: 0 ${rem(8)};
  `}

  @keyframes collapseCartItem {
    from {
      max-height: 200px;
    }

    to {
      max-height: 0px;
    }
  }

  ${({ $collapse }) =>
    $collapse &&
    `
    animation-duration: 0.5s;
    animation-name: collapseCartItem;
    animation-fill-mode: forwards;
  `}
`;

const SlidingContent = styled.div<{ $showUndo: boolean }>`
  display: flex;
  align-items: center;
  transition: transform 0.2s linear;

  ${({ $showUndo }) =>
    $showUndo &&
    `
    transform: translateX(-100%);
  `}
`;

const CartItemContainer = styled.div<{
  $last: boolean;
  $showUndo: boolean;
}>`
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: ${rem(10)} ${rem(8)};
  background: ${({ theme }) => theme.colors.marshmallow};
  transition: opacity 0.2s linear;

  ${({ $last }) =>
    $last &&
    `
    border-radius: 0 0 ${rem(8)} ${rem(8)};
  `}

  ${({ $showUndo }) =>
    $showUndo &&
    `
    opacity: 0;
  `}
`;

const CartImageAndNameContainerLink = styled(UnstyledLink)`
  display: flex;
  width: inherit;

  &:hover {
    color: ${({ theme }) => theme.colors.eggplant};
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledCartItemImage = styled(CartItemImage)`
  flex-shrink: 0;
`;

const StyledCartItemProductName = styled(CartItemProductName)`
  flex-grow: 1;
  margin-left: ${rem(8)};
`;

const StyledCartItemPurchaseButtons = styled(CartItemPurchaseButtons)`
  flex-shrink: 0;
  align-self: center;
  margin-left: ${rem(8)};
`;

const StyledCartItemPrice = styled(CartItemPrice)`
  flex-shrink: 0;
  align-self: center;
  margin-left: ${rem(8)};
`;

const StyledCartItemRemoveUndo = styled(CartItemRemoveUndo)`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s linear;

  &.remove-undo-transition-enter {
    opacity: 0;
  }

  &.remove-undo-transition-enter-active {
    opacity: 1;
  }

  &.remove-undo-transition-exit-active {
    opacity: 1;
  }

  &.remove-undo-transition-exit {
    opacity: 0;
  }
`;
