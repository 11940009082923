// @ts-nocheck
import convict from "convict";

import schema from "./schema";

// eslint-disable-next-line import/no-mutable-exports
export let config = convict(schema);

export const initConfig = (env) => {
  config = config.load(env).validate();
};
