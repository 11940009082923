import React from "react";
import styled, { css } from "styled-components";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Info from "@material-ui/icons/Info";
import { useDispatch } from "react-redux";

import { rem } from "polished";
import breakpoints from "app/styles/breakpoints";
import { PriceType } from "app/types/selectors/crossDomain/ViewableOffering";
import { setDialog } from "app/reducers/ui";
import { DialogType } from "app/types/ui/Dialog";

export enum PriceSizes {
  SMALL_RESPONSIVE = "small responsive",
  SMALL = "small",
  LARGE = "large",
}

export interface PriceProps extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: PriceSizes;
  fullPrice?: PriceType;
  activePrice: PriceType;
  scaleMinQuantity?: number | null;
  retailPrice: PriceType;
  shouldShowRetailPrice: boolean;
  salePriceDiscount: number;
  retailPriceDiscount: number;
  scalePriceFormatted?: PriceType;
}

const Price: React.FC<PriceProps> = ({
  size = PriceSizes.SMALL,
  fullPrice,
  activePrice,
  scaleMinQuantity,
  retailPrice,
  shouldShowRetailPrice,
  salePriceDiscount,
  retailPriceDiscount,
  scalePriceFormatted,
}) => {
  const hasFullPrice = !!fullPrice;
  const hasScalePrice = !!scalePriceFormatted;

  const dispatch = useDispatch();
  const handleOpenModalClick = () => {
    dispatch(setDialog({ type: DialogType.RETAIL_PRICE }));
  };
  const isInPDP = size === PriceSizes.LARGE;

  return (
    <PriceWrapper data-testid="preview-card-price">
      <>
        <ActivePrice $size={size} $shouldBeFlex={hasScalePrice}>
          <SCSpan
            $shouldBeRed={hasFullPrice}
            aria-hidden={hasFullPrice || hasScalePrice || shouldShowRetailPrice}
          >
            ${activePrice}
          </SCSpan>
          {hasScalePrice && (
            <StyledScalePrice
              $size={size}
              aria-label={`$${activePrice} or ${scaleMinQuantity} for ${scalePriceFormatted}`}
            >
              <FlexSpan aria-hidden>
                <StyledLocalOffer />
                {scaleMinQuantity} for ${scalePriceFormatted}
              </FlexSpan>
            </StyledScalePrice>
          )}
        </ActivePrice>
        {hasFullPrice && !shouldShowRetailPrice && (
          <FullPrice
            $strikethrough
            $smallLeftMargin
            $size={size}
            data-testid="preview-card-full-price"
            aria-label={`On sale for $${activePrice}, save ${salePriceDiscount}%`}
          >
            <span aria-hidden>${fullPrice}</span>
          </FullPrice>
        )}
        {shouldShowRetailPrice && isInPDP && (
          <StyledRetailPrice
            $size={size}
            data-testid="preview-card-full-price"
            aria-label={`${activePrice} save ${retailPriceDiscount}% compared to retail price of ${retailPrice}`}
          >
            <FlexSpan aria-hidden>
              Save {retailPriceDiscount}% vs retail ${retailPrice}
              <StyledInfo onClick={handleOpenModalClick} />
            </FlexSpan>
          </StyledRetailPrice>
        )}
        {shouldShowRetailPrice && !isInPDP && (
          <FullPrice
            $size={size}
            data-testid="preview-card-full-price"
            aria-label={`${activePrice} save ${retailPriceDiscount}% compared to retail price of ${retailPrice}`}
          >
            <span aria-hidden>retail ${retailPrice}</span>
          </FullPrice>
        )}
      </>
    </PriceWrapper>
  );
};

const PriceWrapper = styled.span`
  margin: ${rem(0)};
`;

const ActivePrice = styled.p<{
  $size: PriceSizes;
  $shouldBeFlex?: boolean;
}>`
  ${({ $size }) => {
    switch ($size) {
      case PriceSizes.SMALL_RESPONSIVE:
        return css`
          font-size: ${rem(16)};
          ${breakpoints.sm`font-size: ${rem(18)};`}
        `;
      case PriceSizes.SMALL:
        return css`
          font-size: ${rem(18)};
        `;
      default:
        return css`
          font-size: ${rem(20)};
        `;
    }
  }}
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  color: ${({ theme }) => theme.colors.peppercorn};
  display: ${({ $shouldBeFlex }) => ($shouldBeFlex ? `flex` : `inline`)};
  padding-right: ${rem(3)};
  position: relative;
  margin-bottom: 0;
`;

const SCSpan = styled.span<{ $shouldBeRed?: boolean }>`
  position: inherit;
  z-index: 1;

  ${({ $shouldBeRed, theme }) =>
    $shouldBeRed
      ? css`
          color: ${theme.colors.cherry};
        `
      : css`
          color: ${({ theme }) => theme.colors.peppercorn};
        `}
`;

const FlexSpan = styled.span<{ $smallSize?: boolean }>`
  display: flex;
  align-items: center;
  ${($smallSize) => $smallSize && `font-size: ${rem(14)};`}
`;

const FullPrice = styled.span<{
  $size: PriceSizes;
  $strikethrough?: boolean;
  $smallLeftMargin?: boolean;
}>`
  ${({ $size }) => {
    switch ($size) {
      case PriceSizes.SMALL_RESPONSIVE:
        return css`
          font-size: ${rem(12)};
          ${breakpoints.sm`font-size: ${rem(13)};`}
        `;
      case PriceSizes.SMALL:
        return css`
          font-size: ${rem(13)};
        `;
      default:
        return css`
          font-size: ${rem(14)};
        `;
    }
  }}

  ${({ $strikethrough }) => $strikethrough && `text-decoration: line-through`};
  padding-left: ${({ $smallLeftMargin }) =>
    $smallLeftMargin ? rem(1) : rem(5)};
  color: ${({ theme }) => theme.colors.taro};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
`;

const StyledLocalOffer = styled(LocalOffer)`
  transform: scaleX(-1);
  margin-left: ${rem(6)};
  height: ${rem(18)};
`;

const StyledScalePrice = styled.span<{
  $size: PriceSizes;
}>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.cherry};

  ${({ $size }) => {
    switch ($size) {
      case PriceSizes.SMALL_RESPONSIVE:
        return css`
          font-size: ${rem(12)};
          ${breakpoints.sm`font-size: ${rem(14)};`}
        `;
      case PriceSizes.SMALL:
        return css`
          font-size: ${rem(14)};
        `;
      default:
        return css`
          font-size: ${rem(16)};
        `;
    }
  }}
`;

const StyledInfo = styled(Info)`
  height: ${rem(18)};
  &:hover {
    cursor: pointer;
  }
`;

const StyledRetailPrice = styled.span<{
  $size: PriceSizes;
}>`
  display: inline-flex;
  align-items: center;
  padding-left: ${rem(2)};
  color: ${({ theme }) => theme.colors.taro};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};

  ${({ $size }) => {
    switch ($size) {
      case PriceSizes.SMALL_RESPONSIVE:
        return css`
          font-size: ${rem(12)};
          ${breakpoints.sm`font-size: ${rem(14)};`}
        `;
      case PriceSizes.SMALL:
        return css`
          font-size: ${rem(14)};
        `;
      default:
        return css`
          font-size: ${rem(16)};
        `;
    }
  }}
`;

export default Price;
