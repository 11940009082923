import moment from "moment-timezone";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { FC_OPTIONS, PRICE_ZONE_OPTIONS } from "app/constants";
import CatalogPreviewToolDialog from "app/ui/shopping/CatalogPreviewToolDialog";
import {
  clearCatalogPreviewTool,
  setCatalogPreviewTool,
} from "app/reducers/ui";
import {
  getActiveOrderPriceZoneFcAndDate,
  getCatalogPreviewTool,
} from "app/selectors";
import { CatalogPreviewToolData } from "app/types/state/ui";

export const PACK_DATE_FORMAT = "YYYY-MM-DD";

const CatalogPreviewToolDialogContainer = () => {
  const dispatch = useDispatch();
  const defaultCatalogData = useSelector(getActiveOrderPriceZoneFcAndDate);
  const catalogPreviewTool = useSelector(getCatalogPreviewTool);
  const catalogPreviewToolData = catalogPreviewTool.data;
  const catalogPreviewToolStatus = catalogPreviewTool.status;

  const onReset = () => {
    dispatch(clearCatalogPreviewTool());
  };

  const onSubmit = (data: CatalogPreviewToolData) => {
    dispatch(setCatalogPreviewTool(data));
  };

  let activeCatalogData: CatalogPreviewToolData = {
    fcId: defaultCatalogData?.fcId ?? "",
    packDate: defaultCatalogData
      ? moment(defaultCatalogData.packDate).format(PACK_DATE_FORMAT)
      : moment().format(PACK_DATE_FORMAT),
    priceZoneId: defaultCatalogData?.priceZoneId ?? "",
  };

  if (catalogPreviewToolData) {
    activeCatalogData = catalogPreviewToolData;
  }

  return (
    <CatalogPreviewToolDialog
      activeCatalogData={activeCatalogData}
      fcOptions={Object.values(FC_OPTIONS)}
      onReset={onReset}
      onSubmit={onSubmit}
      priceZoneOptions={Object.values(PRICE_ZONE_OPTIONS)}
      status={catalogPreviewToolStatus}
    />
  );
};

export default CatalogPreviewToolDialogContainer;
