import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "shelves";
const prefixes = {
  UPDATE_RECENTLY_ADDED_SHELF: `${domain}/UPDATE_RECENTLY_ADDED_SHELF`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
};
