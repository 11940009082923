import FriendbuyState, {
  APIType,
  APITypeProps,
  ReferralData,
  LegacyReferralAttributes,
  ReferralAttributes,
  FriendbuyReadyAction,
  FriendbuyInitializedAction,
  FetchReferralCouponAction,
  FetchReferralCouponActionSucceeded,
  FetchReferralCouponActionFailed,
  SetLegacyReferralAction,
  CaptureReferralAction,
  FriendbuyVisitorStatusAction,
  CampaignId,
  ApplyAttributionAction,
  ApplyAttributionFailedAction,
  ApplyAttributionSuccessAction,
  UserReferralAttribution,
} from "app/types/state/friendbuy";
import { Coupon } from "app/types/account/Coupon";
import FRIENDBUY_ACTION_TYPES from "app/actionTypes/friendbuy";
import { VisitorStatus } from "app/types/api/friendbuyLegacy";
import { VisitorStatusPayload } from "app/types/api/friendbuy";

const getDefaultReferralData = (apiType: APIType): ReferralData => ({
  apiType,
  loaded: false,
  referralCouponCode: null,
  referral: null,
});

export const initialState: FriendbuyState = {
  // Returned from FRIENDBUY_INITIALIZED action
  activeAPIType: APIType.NONE,
  initialized: false,

  // Separate
  // @ts-ignore
  referralData: {
    [APIType.FIRSTGEN]: getDefaultReferralData(APIType.FIRSTGEN),
    [APIType.NEXTGEN]: getDefaultReferralData(APIType.NEXTGEN),
    [APIType.NONE]: null,
  },
};

const handleFriendbuyReady = (
  state: FriendbuyState,
  action: FriendbuyReadyAction
): FriendbuyState => {
  const referralData = state.referralData[action.apiType];
  return {
    ...state,
    referralData: {
      ...state.referralData,
      [action.apiType]: {
        ...referralData,
        loaded: true,
      },
    },
  };
};

const handleFriendbuyInitialized = (
  state: FriendbuyState,
  action: FriendbuyInitializedAction
): FriendbuyState => {
  return {
    ...state,
    activeAPIType: action.apiType,
    initialized: true,
  };
};

const handleReferralCouponSucceeded = (
  state: FriendbuyState,
  action: FetchReferralCouponActionSucceeded
): FriendbuyState => {
  const referralData = state.referralData[action.apiType];
  return {
    ...state,
    referralData: {
      ...state.referralData,
      [action.apiType]: {
        ...referralData,
        referralCouponCode: action.code,
      },
    },
  };
};

// NC-1499 Remove legacy friendbuy
const handleSetLegacyReferralActon = (
  state: FriendbuyState,
  action: SetLegacyReferralAction
): FriendbuyState => {
  const referralData = state.referralData[action.apiType];
  return {
    ...state,
    referralData: {
      ...state.referralData,
      [action.apiType]: {
        ...referralData,
        referral: action.referral,
      },
    },
  };
};

const handleCaptureReferralAction = (
  state: FriendbuyState,
  action: CaptureReferralAction
): FriendbuyState => {
  const referralData = state.referralData[action.apiType];
  return {
    ...state,
    referralData: {
      ...state.referralData,
      [action.apiType]: {
        ...referralData,
        referral: action.referral,
      },
    },
  };
};

export default function reducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any = {}
): FriendbuyState {
  switch (action.type) {
    case FRIENDBUY_ACTION_TYPES.READY: {
      return handleFriendbuyReady(state, action);
    }
    case FRIENDBUY_ACTION_TYPES.FRIENDBUY_INITIALIZED: {
      return handleFriendbuyInitialized(state, action);
    }
    case FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON_SUCCEEDED: {
      return handleReferralCouponSucceeded(state, action);
    }
    case FRIENDBUY_ACTION_TYPES.CAPTURE_REFERRAL:
      return handleCaptureReferralAction(state, action);
    // NC-1499 Remove legacy friendbuy
    case FRIENDBUY_ACTION_TYPES.SET_REFERRAL:
      return handleSetLegacyReferralActon(state, action);

    default:
      return state;
  }
}

export const getActiveAPIType = (state: FriendbuyState) => state.activeAPIType;
export const isInitialized = (state: FriendbuyState) => state.initialized;
export const getReferralData = (state: FriendbuyState) => state.referralData;

export const getReferralDataForType = (
  state: FriendbuyState,
  props: APITypeProps
) => getReferralData(state)[props.apiType];

export const getActiveReferralData = (state: FriendbuyState) =>
  getReferralDataForType(state, { apiType: state.activeAPIType as APIType });

export const friendbuyReadyAction = (
  apiType: APIType,
  loaded = true
): FriendbuyReadyAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.READY,
    apiType,
    loaded,
  };
};

export const friendbuyVisitorStatusAction = (
  apiType: APIType,
  visitorStatus: VisitorStatusPayload | VisitorStatus
): FriendbuyVisitorStatusAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.VISITOR_STATUS,
    visitorStatus,
    apiType,
  };
};

export const friendbuyInitializedAction = (
  apiType: APIType
): FriendbuyInitializedAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.FRIENDBUY_INITIALIZED,
    apiType,
  };
};

export function fetchReferralCoupon(
  campaignId: CampaignId,
  apiType: APIType
): FetchReferralCouponAction {
  return {
    type: FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON,
    campaignId,
    apiType,
  };
}

export const referralCouponSucceededAction = (
  campaignId: CampaignId,
  apiType: APIType,
  coupon: Coupon | null,
  code: string
): FetchReferralCouponActionSucceeded => {
  return {
    type: FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON_SUCCEEDED,
    campaignId,
    apiType,
    coupon,
    code,
  };
};

export const referralCouponFailedAction = (
  campaignId: CampaignId,
  apiType: APIType,
  message: string,
  error?: unknown
): FetchReferralCouponActionFailed => {
  return {
    type: FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON_FAILED,
    campaignId,
    apiType,
    message,
    error,
  };
};

export const friendbuySetLegacyReferralAction = (
  campaignId: CampaignId,
  referral: LegacyReferralAttributes | null
): SetLegacyReferralAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.SET_REFERRAL,
    campaignId,
    apiType: APIType.FIRSTGEN,
    referral,
  };
};

export const friendbuyCaptureReferralAction = (
  referral: ReferralAttributes
): CaptureReferralAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.CAPTURE_REFERRAL,
    campaignId: referral.campaignId || "",
    apiType: APIType.NEXTGEN,
    referral,
  };
};

export const applyAttributionAction = (
  userId: string,
  referral: ReferralAttributes,
  couponCode: string
): ApplyAttributionAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION,
    campaignId: referral.campaignId || "",
    apiType: APIType.NEXTGEN,
    userId,
    referral,
    couponCode,
  };
};

export const applyAttributionSuccessAction = (
  userId: string,
  referral: ReferralAttributes,
  couponCode: string,
  userReferralAttribution: UserReferralAttribution
): ApplyAttributionSuccessAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION_SUCCEEDED,
    campaignId: referral.campaignId || "",
    apiType: APIType.NEXTGEN,
    userId,
    referral,
    couponCode,
    userReferralAttribution,
  };
};

export const applyAttributionFailedAction = (
  userId: string,
  referral: ReferralAttributes,
  couponCode: string,
  error?: unknown
): ApplyAttributionFailedAction => {
  return {
    type: FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION_FAILED,
    campaignId: referral.campaignId || "",
    apiType: APIType.NEXTGEN,
    userId,
    referral,
    couponCode,
    error,
  };
};
