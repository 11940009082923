import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import theme from "app/styles/theme";

export interface ImperfectionTagProps {
  backgroundColor?: string;
  className?: string;
  imperfection: string;
}

const ImperfectionTag: React.FC<ImperfectionTagProps> = ({
  backgroundColor = theme.colors.baba,
  imperfection,
  className,
}) => (
  <SCTagWrapper $backgroundColor={backgroundColor} className={className}>
    {imperfection}
  </SCTagWrapper>
);

const SCTagWrapper = styled.li<{
  $backgroundColor: string;
}>`
  color: ${({ theme }) => `${theme.colors.peppercorn}`};
  border-radius: ${rem(2)};
  padding: ${rem(2)} ${rem(8)};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${rem(13)};
  line-height: ${rem(16)};
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  list-style: none;
`;

export default ImperfectionTag;
