import { User } from "@datadog/browser-rum-core/cjs/rawRumEvent.types";
import _noop from "lodash/noop";

import { SetContextLibFunc, datadogRumType } from "app/types/monitoring";

export const datadogRumNoop = {
  setRumGlobalContext: _noop,
  setUser: _noop,
  removeUser: _noop,
  addError: _noop,
} as datadogRumType;

const datadogRum = () => window.DD_RUM || datadogRumNoop;

export const setContext: SetContextLibFunc = (context) => {
  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#global-context
  datadogRum().setRumGlobalContext(context);
};

export const setUser = (user: User) => {
  datadogRum().setUser(user);
};

export const removeUser = () => {
  datadogRum().removeUser();
};

export const addError = (error: unknown, context?: object) => {
  datadogRum().addError(error, context);
};
