import { Backdrop, Dialog } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import * as kb from "app/router/routesKnowledgeBase";
import ConfirmationDialog from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";

const StyledSubcopyText = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  color: ${({ theme }) => theme.colors.peppercorn};
`;

const OfferingDetailRetailPriceModal = (dialog) => {
  const { open, handleClose, handleExited } = useHandleTransitions(dialog);

  const handleLearnMoreButtonClick = () => {
    window.open(kb.getPath(kb.RouteKey.PRICING), "_blank");
    handleClose();
  };

  return (
    <>
      <Dialog
        BackdropComponent={Backdrop}
        onClose={handleClose}
        open={open}
        TransitionProps={{ onExited: handleExited }}
      >
        <ConfirmationDialog
          headerText="What is retail price?"
          overlineText="LEARN MORE"
          primaryButtonText="Got it"
          secondaryButtonText="Learn more"
          subcopyText={
            <StyledSubcopyText>
              We survey a sample of grocers to find the average price for this
              product or a similar product. By shopping with us, you&apos;re
              making a positive impact on the planet AND your wallet.
            </StyledSubcopyText>
          }
          onPrimaryButtonClick={handleClose}
          onSecondaryButtonClick={handleLearnMoreButtonClick}
          onClearIconButtonClick={handleClose}
        />
      </Dialog>
    </>
  );
};

export default OfferingDetailRetailPriceModal;
