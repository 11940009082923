import React from "react";
import { Launch } from "@material-ui/icons";
import colors from "app/styles/colors";
import CustomLink from "app/ui/designSystem/molecules/Links";
import routes from "app/router/routes.json";
import { useSelector } from "react-redux";
import { getUserImpactFormatted } from "app/selectors";
import styled from "styled-components";

export enum BlobOptions {
  BLOB_ONE = "ShellBlobOne",
  BLOB_TWO = "ShellBlobTwo",
}

const UserImpact = () => {
  const userImpactFormatted = useSelector(getUserImpactFormatted);
  // default to the approximate number for the second order if value is not returned
  const produceAmount = userImpactFormatted?.produce || 6.5;
  return (
    <>{`You've already saved ${produceAmount} lbs of food! Plus water, carbon emissions, & other resources.`}</>
  );
};

const SCLink = styled(CustomLink)`
  color: ${({ theme }) => theme.colors.beet} !important;
`;

const CarouselContent = [
  [
    // Order 1
    {
      headLine: `What makes food “imperfect”?`,
      main:
        "Odd shapes & sizes, cosmetic imperfections, excess inventory. All still delicious!",
      subText: "Wonky in appearance, never in quality.",
      baseImageDirectory: "website-assets/first-time-shopping/imperfections",
      desktopImage: "Imperfect-Desktop.png",
      mobileImage: "Imperfect-Mobile2.png",
      lowerMobileImage: "Imperfect-Mobile1.png",
      blob: BlobOptions.BLOB_ONE,
      blobColor: colors.lemon,
    },
    {
      headLine: "Why is my cart pre-filled?",
      main:
        "We started you off with groceries we think you'll love. How'd we do?",
      subText: "Edit your preferences for efficient shopping.",
      baseImageDirectory: "website-assets/first-time-shopping/brussels",
      desktopImage: "Brussel-Desktop.png",
      mobileImage: "Brussel-Mobile2.png",
      lowerMobileImage: "Brussel-Mobile1.png",
      blob: BlobOptions.BLOB_TWO,
      blobColor: colors.lavenderLight,
    },
    {
      headLine: "The Imperfect promise",
      main:
        "Wonky or not, your groceries are guaranteed to be fresh and delicious.",
      subText: "Love 'em or we'll make it right.",
      baseImageDirectory: "website-assets/first-time-shopping/foods/",
      desktopImage: "Food-Desktop.png",
      mobileImage: "Food-Mobile2.png",
      lowerMobileImage: "Food-Mobile1.png",
      blob: BlobOptions.BLOB_ONE,
      blobColor: colors.papayaLight,
    },
  ],
  [
    // Order 2
    {
      headLine: "You're a sustainability superhero",
      main: <UserImpact />,
      subText: "With every order, your impact grows. ",
      baseImageDirectory: "website-assets/first-time-shopping/lemon",
      desktopImage: "Lemon-Desktop.png",
      mobileImage: "Lemon-Mobile2.png",
      lowerMobileImage: "Lemon-Mobile1.png",
      blob: BlobOptions.BLOB_ONE,
      blobColor: colors.lavenderLight,
    },
    {
      headLine: "Minimize waste with packaging return",
      main:
        "We'll pick up your gel packs, silver liners, and egg carton liners to recycle or reuse.",
      subText: "Recycle your box (or make a robot costume).",
      baseImageDirectory: "website-assets/first-time-shopping/package",
      desktopImage: "Package-Desktop.png",
      mobileImage: "Package-Mobile2.png",
      lowerMobileImage: "Package-Mobile1.png",
      blob: BlobOptions.BLOB_ONE,
      blobColor: colors.papayaLight,
    },
    {
      headLine: "Want a discount on your next order?",
      main:
        "Get a grocery credit for every new customer you refer. They'll get cash off too!",
      subText: (
        <>
          The more the berrier!{" "}
          <SCLink size="small" to={routes.referrals.url}>
            Learn more. <Launch color="primary" fontSize="inherit" />
          </SCLink>
        </>
      ),
      baseImageDirectory: "website-assets/first-time-shopping/money",
      desktopImage: "Money-Desktop.png",
      mobileImage: "Money-Mobile2.png",
      lowerMobileImage: "Money-Mobile1.png",
      blob: BlobOptions.BLOB_TWO,
      blobColor: colors.lime,
    },
  ],
  [
    // Order 3
    {
      headLine: "Snacks that save food",
      main:
        "Our only-at-Imperfect snacks rescue broken bits and tasty leftovers.",
      subText: "Chocolate covered pretzel pieces, anyone?",
      baseImageDirectory: "website-assets/first-time-shopping/snack",
      desktopImage: "Snack-Desktop.png",
      mobileImage: "Snack-Mobile2.png",
      lowerMobileImage: "Snack-Mobile1.png",
      blob: BlobOptions.BLOB_ONE,
      blobColor: colors.lemon,
    },
    {
      headLine: "Something a little off?",
      main:
        "Lettuce help! Reach out to our delightful Customer Care team anytime.",
      subText: (
        <>
          <SCLink size="small" to={routes.contact.url}>
            Let&apos;s chat <Launch color="primary" fontSize="inherit" />
          </SCLink>
          to make it right.
        </>
      ),
      baseImageDirectory: "website-assets/first-time-shopping/lettuce",
      desktopImage: "Lettuce-Desktop.png",
      mobileImage: "Lettuce-Mobile2.png",
      lowerMobileImage: "Lettuce-Mobile1.png",
      blob: BlobOptions.BLOB_TWO,
      blobColor: colors.papayaLight,
    },
    {
      headLine: "Need to skip an order?",
      main:
        "Going out of town? Fridge still full? No problem, skipping an order is easy peas-y.",
      subText: (
        <>
          <SCLink size="small" to={routes.orders.url}>
            Visit orders <Launch color="primary" fontSize="inherit" />
          </SCLink>
          to make changes.
        </>
      ),
      baseImageDirectory: "website-assets/first-time-shopping/peas",
      desktopImage: "Peas-Desktop.png",
      mobileImage: "Peas-Mobile2.png",
      lowerMobileImage: "Peas-Mobile1.png",
      blob: BlobOptions.BLOB_ONE,
      blobColor: colors.lavenderLight,
    },
  ],
];

export type CarouselContentProps = {
  headLine: string;
  main: string | React.ReactNode;
  subText: string | React.ReactNode;
  baseImageDirectory: string;
  desktopImage: string;
  mobileImage: string;
  lowerMobileImage: string;
  blob: string;
  blobColor: string;
};

export default CarouselContent;
