import _union from "lodash/union";
import { createSelector } from "reselect";

import { SFOFulfillmentCenterId } from "app/constants";
import normalizePath from "app/router/normalizePath";
import routes from "app/router/routes.json";
import * as fromAccount from "app/selectors/account";
import * as fromAuth from "app/selectors/auth";
import * as fromBoxes from "app/selectors/boxes";
import * as fromBoxesSchema from "app/selectors/boxes.schema";
import * as fromCDDeliveries from "app/selectors/crossDomain/deliveries";
import * as fromDeliveries from "app/selectors/deliveries";
import * as fromActiveOrder from "app/selectors/orderActive";
import * as fromOrders from "app/selectors/orders";
import * as fromRouting from "app/selectors/routing";
import * as fromUI from "app/selectors/ui";
import WinbackOption from "app/types/account/WinbackOption";
import State from "app/types/state";
import { User } from "app/types/state/account/Account";
import { objectValid } from "app/ui/global/utils";

export const getUserDeliveryWindow = createSelector(
  [fromAccount.getUserDeliveryWindowId, fromDeliveries.getDeliveryWindows],
  (deliveryWindowId, windows) => {
    if (!windows || !deliveryWindowId) return null;

    return windows[deliveryWindowId];
  }
);

export const getActiveBoxId = createSelector(
  [fromAccount.getSubscriptionBoxId, fromActiveOrder.getActiveOrder],
  (subscriptionBoxId, activeOrder) => {
    return (activeOrder && activeOrder.boxId) || subscriptionBoxId;
  }
);

export const getCurrentSecondaryBoxes = fromBoxes.makeGetDataForBoxIds(
  fromAccount.getUserSecondaryBoxIds,
  fromAccount.getAccountFulfillmentCenterId
);

// Only references current subscription boxid
export const getSubscriptionBoxDetails = fromBoxes.makeGetDataForBoxId(
  fromAccount.getSubscriptionBoxId,
  fromAccount.getAccountFulfillmentCenterId
);

// Uses active order boxId if present, otherwise defaults to subscription boxId
export const getCurrentBoxDetails = fromBoxes.makeGetDataForBoxId(
  getActiveBoxId,
  fromAccount.getAccountFulfillmentCenterId
);

// Get all appropriate secondary boxIds for existing users
export const makeGetAccountSecondaryBoxIds = (requiredFields: string[]) =>
  fromBoxes.makeGetVisibleSecondaryBoxIdsByFcId(
    fromAccount.getAccountFulfillmentCenterId,
    fromCDDeliveries.isUserUglyShip,
    requiredFields
  );
const getAccountSecondaryBoxIds = makeGetAccountSecondaryBoxIds(
  fromBoxesSchema.accountModalSecondaryBoxFields
);

// Merge in any boxIds already on the user subscription, even if no longer available
export const getAccountSecondaryBoxIdsForUser = createSelector(
  [getAccountSecondaryBoxIds, fromAccount.getUserSecondaryBoxIds],
  (boxIdsAvailable, subscriptionBoxIds) =>
    _union(boxIdsAvailable, subscriptionBoxIds)
);
export const getAccountSecondaryBoxes = fromBoxes.makeGetDataForBoxIds(
  getAccountSecondaryBoxIdsForUser,
  fromAccount.getAccountFulfillmentCenterId
);

export const isRemovingAccountCoupon = createSelector(
  [fromAccount.isRemovingDeferredCoupon, fromOrders.isRemovingAppliedCoupon],
  (removingDeferred, removingApplied) => removingApplied || removingDeferred
);

// First checks active order, falls back to subscription FC
export const getFulfillmentCenter = createSelector(
  [
    fromActiveOrder.getActiveOrderFulfillmentCenter,
    fromAccount.getAccountFulfillmentCenterId,
  ],
  (orderFCId, accountFCId) => {
    return orderFCId || accountFCId;
  }
);

export const isInSFOFulfillmentCenter = createSelector(
  [
    fromActiveOrder.getActiveOrderFulfillmentCenter,
    fromAccount.getAccountFulfillmentCenterId,
  ],
  (orderFCId, accountFCId) => {
    return (orderFCId || accountFCId) === SFOFulfillmentCenterId;
  }
);

// First checks active order, falls back to user priceZoneId
export const getPriceZoneId = createSelector(
  [fromActiveOrder.getActiveOrderPriceZoneId, fromAccount.getUserPriceZoneId],
  (orderPriceZoneId, accountPriceZoneId) => {
    return orderPriceZoneId || accountPriceZoneId;
  }
);

export const shouldShowAuthFailureModal = createSelector(
  [
    fromAuth.isImpersonated,
    fromAccount.hasFailedAuthMessage,
    fromRouting.getRoutingLocation,
    fromUI.isModalVisible,
  ],
  (impersonated, hasFailedAuthMessage, location, isAuthModalVisible) => {
    if (impersonated || isAuthModalVisible) return false;
    const path = normalizePath(location.pathname);
    return hasFailedAuthMessage && ![routes.billing.url].includes(path);
  }
);

export const shouldShowTCModal = createSelector(
  [
    fromAuth.isImpersonated,
    fromAccount.hasTCMessage,
    fromRouting.getRoutingLocation,
    fromUI.isModalVisible,
  ],
  (impersonated, shouldAcceptTC, location, isTermsModalVisible) => {
    if (impersonated || isTermsModalVisible) return false;
    const path = normalizePath(location.pathname);
    return (
      shouldAcceptTC && ![routes.terms.url, routes.contact.url].includes(path)
    );
  }
);

export const WB_INCENTIVE_COUPON_KEY = "incentive-coupon";

export const WB_INCENTIVE_COUPON: WinbackOption = {
  key: WB_INCENTIVE_COUPON_KEY,
  to: routes.deliveries.url,
  headerImage: {
    imageUrl: "v1629130365/Box_-_In_Kitchen_14_fqxt07",
    imageAlt: "Imperfect Food Box in Kitchen",
    imageWidth: 600,
  },
  title: `Keep fighting food waste and get $5 off your next order*`,
  caption:
    "*Limited one time use per customer applied to next subsequent order.",
  cta: "Apply discount",
  secondaryCta: "Continue to cancel",
  couponCode: "SWU5",
  couponValue: "$5",
  autoApplyCoupon: true,
  autoApplyCouponTo: routes.billing.url,
};

export const getCancellationWinBackOptions = (state: State) => {
  const isRewardCouponUnused = state.account.rewardCouponUnused;

  if (isRewardCouponUnused) {
    return [WB_INCENTIVE_COUPON];
  }

  return [];
};

// three possible outcomes:
// 1) true (includes deliveryDate and windowId)
// 2) false (returned as soon as possible if we know the eventual answer is false)
// 3) don't know yet - returns loading true (used for rendering in UI layer)
export const getAdHocOrderAvailability = createSelector(
  [
    fromUI.isInitialized,
    fromOrders.isFetchingActiveOrder,
    fromActiveOrder.getActiveOrder,
    fromDeliveries.getNextAvailableWindowDelivery,
    fromDeliveries.getNextDelivery,
  ],
  (
    initialized,
    fetchingActiveOrder,
    activeOrder,
    nextAvailableWindowDelivery,
    nextDelivery
  ) => {
    // We can probably take this line out; it's accounted for in the endpoint
    const hasActiveOrder = !!activeOrder && !!activeOrder.orderId;
    const nextAvailableWindowDeliveryLoaded = !!nextAvailableWindowDelivery;
    const activeOrderLoaded = !fetchingActiveOrder;

    if (
      !initialized ||
      !nextAvailableWindowDeliveryLoaded ||
      !activeOrderLoaded ||
      !nextDelivery
    ) {
      // we don't know yet
      return { canCreateOrder: false, loading: true };
    }
    if (hasActiveOrder) {
      return { canCreateOrder: false };
    }

    return {
      canCreateOrder: nextDelivery.canCreateAdHocOrder,
      deliveryDate: nextAvailableWindowDelivery?.deliveryDate,
      windowId: nextAvailableWindowDelivery?.windowId,
    };
  }
);

export const getMyAccountAddOnBoxesForUser = createSelector(
  [getAccountSecondaryBoxes, fromAccount.getUserSecondaryBoxIds],
  (secondaryBoxes, subscriptionBoxIds) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return secondaryBoxes.map((box: any) => {
      return {
        ...box,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        samples: (box.samples || []).filter((sample: any) =>
          objectValid(
            sample,
            "sample",
            fromBoxesSchema.accountModalSecondaryBoxSampleFields
          )
        ),
        selected: subscriptionBoxIds.includes(box.id),
      };
    });
  }
);

export const getShouldShowMyAccountAddOns = createSelector(
  [getMyAccountAddOnBoxesForUser, fromAccount.getUserLifetimeOrderCount],
  (addOnBoxes, lifetimeOrderCount: Pick<User, "lifetimeOrderCount">) => {
    const hasAddOnBoxes = !!addOnBoxes && addOnBoxes.length > 0;
    const hasLifetimeOrderCount =
      !!lifetimeOrderCount && lifetimeOrderCount > 0;

    return hasAddOnBoxes && hasLifetimeOrderCount;
  }
);

export const getShouldRedirectToBoysenberry = createSelector(
  [fromUI.isInitialized, fromAccount.isMigratedToMM],
  (initialized, isMigratedToMM) => {
    return initialized && isMigratedToMM;
  }
);
