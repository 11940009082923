import { createSelector } from "reselect";

import * as fromOrderActive from "app/selectors/orderActive";
import FreeDeliveryBannerProps from "app/types/selectors/crossDomain/FreeDeliveryBannerProps";
import FreeDeliveryWidgetProps from "app/types/selectors/crossDomain/FreeDeliveryWidgetProps";

/*
 * Return the free delivery banner data to be displayed on customize/shopping
 */
export const getFreeDeliveryBannerProps = createSelector(
  [
    fromOrderActive.isDefaultFreeDeliveryThreshold,
    fromOrderActive.getFreeDeliveryThreshold,
  ],
  (hasFreeDeliveryThreshold, freeDeliveryThreshold) => {
    if (!hasFreeDeliveryThreshold || !freeDeliveryThreshold) return null;

    return {
      freeDeliveryThreshold,
    } as FreeDeliveryBannerProps;
  }
);

/*
 * Return the free delivery widget data to be displayed in the cart
 */
export const getFreeDeliveryWidgetProps = createSelector(
  fromOrderActive.getFreeDeliveryThreshold,
  fromOrderActive.getActiveOrder,
  (freeDeliveryThreshold, order) => {
    if (!freeDeliveryThreshold || !order) return null;

    const { subtotal } = order;
    const remaining = Math.max(freeDeliveryThreshold - subtotal, 0);
    const hasFreeShipping = remaining <= 0;

    return {
      freeDeliveryThreshold,
      remaining,
      hasFreeShipping,
    } as FreeDeliveryWidgetProps;
  }
);
