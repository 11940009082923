// Defined in split.io
export type TrafficType = "logged_out" | "user";

export type HeaderBannerTreatmentType =
  | "CHI"
  | "LAX"
  | "SFO"
  | "PNW"
  | "BWI"
  | "SAT"
  | "off"
  | "null";

export enum PackagingReturnTreatments {
  INELIGIBLE = "ineligible",
  GELPACKS_LINERS_ELIGIBLE = "gelpacks-liners-eligible",
  GELPACKS_ELIGIBLE = "gelpacks-eligible",
  MAIL_RETURN_ELIGIBLE = "mail-return",
}

export type PackagingReturnTreatmentType =
  | "ineligible"
  | "gelpacks-liners-eligible"
  | "gelpacks-eligible"
  | "mail-return";

export type TreatmentType =
  | "on"
  | "off"
  | "control"
  | HeaderBannerTreatmentType
  | PackagingReturnTreatmentType;

export const ANON_TRAFFIC_TYPE: TrafficType = "logged_out";
export const USER_TRAFFIC_TYPE: TrafficType = "user";

export const ON_TREATMENT_TYPE: TreatmentType = "on";
export const OFF_TREATMENT_TYPE: TreatmentType = "off";
// https://help.split.io/hc/en-us/articles/360020528072-Control-treatment
// Control treatment is returned by split for a variety of reasons
// including network issues or misconfiguration of split
export const CONTROL_TREATMENT_TYPE: TreatmentType = "control";

// Split.io treatments
export const USER_PLUS_ENROLL = "user-plus-enroll";
export const HEADER_BANNER = "user-header-banner";
export const ANON_HEADER_BANNER = "anon-header-banner";

export const FUTURE_MARKET_LAUNCH_MODAL = "future-market-modal";
export const PACKAGING_RETURN_PROGRAM = "packaging-return-program";
export const MINIMUM_ORDER_PROGRESS = "minimum-order-progress";
export const SUBAISLE_SIDE_NAV = "subaisle-side-nav";
export const SEARCH_AND_FILTER = "search-and-filter";
export const ANON_ORDER_MIN = "anon-order-min";
export const ORDER_MIN = "order-min";
export const FLEX_DELIVERY_H1_2022 = "flex-delivery-h1-2022";
export const LOGGED_IN_HOMEPAGE = "logged-in-homepage";
export const SHOPPABLE_RECIPE_AISLE = "shoppable-recipe-aisle";
export const SUBSCRIPTION_PAUSE = "user-subscription-pause";
export const SIGNUP_SHOPPING = "user-signup-shopping";
export const REFERRAL_DASHBOARD = "user-referral-dashboard";
export const CATALOG_PREVIEW_TOOL = "catalog-preview-tool";
export const ADDRESS_VALIDATION = "user-address-validation";
export const WARNING_APP_VERSION_MINIMUM = "warning-app-version-minimum";
export const SCALE_PRICE = "scale-price";
export const MOBILE_AISLE_NAV = "mobile-aisle-nav";
export const INTENTIONAL_SOURCING = "intentional-sourcing";
export const NEW_FEATURE_MODAL = "new-feature-modal";
export const SHOW_EXCEPTION_BANNER = "show-exception-banner";
export const RECENTLY_ADDED = "recently-added";
export const FIRST_TIME_SHOPPING_CAROUSEL = "first-time-shopping-carousel";
export const HIDE_BIWEEKLY_SKIP_MODAL = "hide-biweekly-skip-modal";
export const BOYSENBERRY_SIGNUP = "boysenberry-signup";

export type SplitTreatment =
  | typeof FUTURE_MARKET_LAUNCH_MODAL
  | typeof USER_PLUS_ENROLL
  | typeof HEADER_BANNER
  | typeof PACKAGING_RETURN_PROGRAM
  | typeof MINIMUM_ORDER_PROGRESS
  | typeof SUBAISLE_SIDE_NAV
  | typeof SEARCH_AND_FILTER
  | typeof ANON_ORDER_MIN
  | typeof ORDER_MIN
  | typeof ANON_HEADER_BANNER
  | typeof FLEX_DELIVERY_H1_2022
  | typeof LOGGED_IN_HOMEPAGE
  | typeof SHOPPABLE_RECIPE_AISLE
  | typeof SUBSCRIPTION_PAUSE
  | typeof SIGNUP_SHOPPING
  | typeof CATALOG_PREVIEW_TOOL
  | typeof REFERRAL_DASHBOARD
  | typeof ADDRESS_VALIDATION
  | typeof WARNING_APP_VERSION_MINIMUM
  | typeof SCALE_PRICE
  | typeof MOBILE_AISLE_NAV
  | typeof INTENTIONAL_SOURCING
  | typeof NEW_FEATURE_MODAL
  | typeof SHOW_EXCEPTION_BANNER
  | typeof RECENTLY_ADDED
  | typeof FIRST_TIME_SHOPPING_CAROUSEL
  | typeof HIDE_BIWEEKLY_SKIP_MODAL
  | typeof BOYSENBERRY_SIGNUP;

// The following splits will be checked at key times in user flow
// Use these lists if the split applies across the user experience
// and we cannot target a specific area of the site

type GlobalSplits = {
  [USER_TRAFFIC_TYPE]: SplitTreatment[];
  [ANON_TRAFFIC_TYPE]: SplitTreatment[];
};

export const GLOBAL_SPLITS: GlobalSplits = {
  [USER_TRAFFIC_TYPE]: [
    USER_PLUS_ENROLL,
    HEADER_BANNER,
    PACKAGING_RETURN_PROGRAM,
    MINIMUM_ORDER_PROGRESS,
    SUBAISLE_SIDE_NAV,
    SEARCH_AND_FILTER,
    ORDER_MIN,
    FLEX_DELIVERY_H1_2022,
    SHOPPABLE_RECIPE_AISLE,
    SUBSCRIPTION_PAUSE,
    LOGGED_IN_HOMEPAGE,
    SIGNUP_SHOPPING,
    CATALOG_PREVIEW_TOOL,
    WARNING_APP_VERSION_MINIMUM,
    SCALE_PRICE,
    MOBILE_AISLE_NAV,
    INTENTIONAL_SOURCING,
    NEW_FEATURE_MODAL,
    SHOW_EXCEPTION_BANNER,
    RECENTLY_ADDED,
    FIRST_TIME_SHOPPING_CAROUSEL,
  ],
  [ANON_TRAFFIC_TYPE]: [ANON_ORDER_MIN, ANON_HEADER_BANNER, BOYSENBERRY_SIGNUP],
};

export const getPackagingReturnTreatmentFromValue = (
  treatment: string
): PackagingReturnTreatments | undefined => {
  // @ts-ignore
  return Object.values(PackagingReturnTreatments).includes(treatment)
    ? (treatment as PackagingReturnTreatments)
    : undefined;
};
