import isEmpty from "lodash/isEmpty";

import ACTION_TYPES from "app/actionTypes/catalog";
import ROUTING_ACTION_TYPES from "app/actionTypes/routing";
import { config } from "app/config";
import { getVariantsIndex } from "app/constants";
import CatalogPayload from "app/types/catalog/CatalogPayload";
import FetchCatalog from "app/types/catalog/FetchCatalog";
import PropsByShelf from "app/types/catalog/PropsByShelf";
import CatalogState from "app/types/state/catalog";
import sortVariantByMerchRank from "app/utils/sortOfferingByMerchRank";

const env = config.get("algolia.env");
export const DEFAULT_SEARCH_INDEX = getVariantsIndex(env);

const BUY_IT_AGAIN_SHELF_ID = "65aeaf6d-d949-4254-a6cd-0b5fe9658758";

export const initialState: CatalogState = {
  initialized: false,
  isRefetchingCatalog: false,
  initializeError: null,
  variantIdsByShelf: {},
  availabilityByShelf: {},
  algoliaDataByShelf: {},
};

type PartialCatalog = Pick<
  CatalogState,
  "variantIdsByShelf" | "availabilityByShelf" | "algoliaDataByShelf"
>;

const handleFetchCatalogSucceeded = (
  state: CatalogState,
  payload: CatalogPayload
) => {
  const {
    availabilityResultsMap,
    mapOfVariants,
    resultsMap,
    shelfIds,
    recentlyAddedShelfInfo,
  } = payload;
  const processAvailability = isEmpty(state.availabilityByShelf);
  const {
    variantIdsByShelf,
    availabilityByShelf,
    algoliaDataByShelf,
  } = shelfIds.reduce(
    (acc, shelfId) => {
      // Populate variantIdsByShelf
      const shelfResult = resultsMap[shelfId];
      acc.variantIdsByShelf[shelfId] = shelfResult?.hits
        ? shelfResult.hits
            .reduce(
              (acc, hit) =>
                mapOfVariants[hit.variantId] ? [...acc, hit.variantId] : acc,
              [] as string[]
            )
            // Filter Out of Stock offerings from Buy It Again shelf
            .filter((variantId) => {
              if (
                shelfId === BUY_IT_AGAIN_SHELF_ID &&
                !mapOfVariants[variantId]?.hasStock
              ) {
                return false;
              }
              return true;
            })
            // Added here after migration to {env}_variants index, maintaining
            // sort by hasStock and merchRank
            .sort((a, b) => sortVariantByMerchRank(a, b, mapOfVariants))
        : [];

      // Populate availabilityByShelf. Required in order to show shelf availability,
      // espectially in the case where a filter is applied on first load.
      if (processAvailability) {
        const availability = availabilityResultsMap[shelfId];
        const inMapOfVariants =
          !!availability?.hits &&
          availability.hits.some((item) => !!mapOfVariants[item.variantId]);
        acc.availabilityByShelf[shelfId] = inMapOfVariants;
      }

      // Populate algoliaDataByShelf
      if (shelfResult) {
        acc.algoliaDataByShelf[shelfId] = {
          queryID: shelfResult?.queryID,
          index: DEFAULT_SEARCH_INDEX,
        };
      }

      return acc;
    },
    {
      variantIdsByShelf: {},
      availabilityByShelf: {},
      algoliaDataByShelf: {},
    } as PartialCatalog
  );

  const recentlyAddedShelfId =
    recentlyAddedShelfInfo?.recentlyAddedShelf?.shelfId;

  if (recentlyAddedShelfId) {
    variantIdsByShelf[recentlyAddedShelfId] =
      recentlyAddedShelfInfo?.recentlyAddedShelfVariantIds;
    availabilityByShelf[recentlyAddedShelfId] = !!recentlyAddedShelfInfo
      ?.recentlyAddedShelfVariantIds.length;
    algoliaDataByShelf[recentlyAddedShelfId] = {
      queryID: "n/a",
      index: "n/a",
    };
  }

  return {
    ...state,
    initialized: true,
    isRefetchingCatalog: false,
    initializeError: null,
    algoliaDataByShelf,
    variantIdsByShelf,
    availabilityByShelf: processAvailability
      ? availabilityByShelf
      : state.availabilityByShelf,
  };
};

// Manually define action types where appropriate
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ROUTING_ACTION_TYPES.LOCATION_CHANGED: {
      return {
        ...state,
        // See reconcileCatalogMiddleware
        isRefetchingCatalog: action.isRefetchingCatalog,
      };
    }
    case ACTION_TYPES.FETCH_CATALOG_SUCCEEDED: {
      return handleFetchCatalogSucceeded(state, action.payload);
    }

    case ACTION_TYPES.FETCH_CATALOG_FAILED: {
      return {
        ...state,
        isRefetchingCatalog: false,
        initializeError: action.error,
      };
    }

    default:
      return state;
  }
}

export function isCatalogInitialized(state: CatalogState) {
  return state.initialized;
}

export function isRefetchingCatalog(state: CatalogState) {
  return state.isRefetchingCatalog;
}

export function variantIdsByShelf(state: CatalogState) {
  return state.variantIdsByShelf;
}

export function getAlgoliaDataByShelf(state: CatalogState) {
  return state.algoliaDataByShelf;
}

export function availabilityByShelf(state: CatalogState, params: PropsByShelf) {
  return state.availabilityByShelf[params.shelfId];
}

export function fetchCatalog(params: FetchCatalog = {}) {
  return { type: ACTION_TYPES.FETCH_CATALOG, ...params };
}
