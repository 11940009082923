import React from "react";

import Page, {
  BlockContainer,
  BlockWrapper,
} from "app/ui/cms/contentPage_DEPRECATED/Page_DEPRECATED";
import Spinner, { SpinnerProps } from "app/ui/shared/Spinner";

const SpinnerPage: React.FC<SpinnerProps> = (props) => {
  return (
    <Page>
      <BlockContainer>
        <BlockWrapper>
          <Spinner {...props} />
        </BlockWrapper>
      </BlockContainer>
    </Page>
  );
};

export default SpinnerPage;
