// @ts-nocheck
import { createSelector } from "reselect";

import * as fromFeatureIntros from "app/reducers/featureIntro";

export function getAllFeatureIntros(state) {
  return fromFeatureIntros.getAllFeatureIntros(state.featureIntro);
}

export const getFeatureIntro = createSelector(
  [getAllFeatureIntros, (state, props) => props],
  (featureIntros, { feature }) => {
    if (!featureIntros[feature]) return {};
    return featureIntros[feature];
  }
);
