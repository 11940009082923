import { Button, Typography } from "@material-ui/core";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { isAddingMultipleItems } from "app/selectors";
import { updateAllVariantQuantitiesFromShelf } from "app/reducers/orders";

export interface AddRecipeToCartButtonProps {
  buttonText?: string;
  disabled?: boolean;
  loadingText?: string;
  mobile: boolean;
  shelfId: string;
}

const AddRecipeToCartButton: React.FC<AddRecipeToCartButtonProps> = ({
  buttonText = "Add all ingredients to cart",
  disabled,
  loadingText = "Adding...",
  mobile,
  shelfId,
}) => {
  const { isCurrentlyAdding, addingFromShelfId } = useSelector(
    isAddingMultipleItems
  );
  const dispatch = useDispatch();
  const handleClick = () => {
    if (!isCurrentlyAdding) {
      dispatch(updateAllVariantQuantitiesFromShelf(shelfId));
    }
  };
  const shouldDisplayLoadingText =
    isCurrentlyAdding && addingFromShelfId === shelfId;

  return (
    <StyledButton
      disabled={disabled}
      onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
      onClick={handleClick}
      $isAddingItemsToCart={isCurrentlyAdding}
      $mobile={mobile}
    >
      <StyledTypography variant="button" $mobile={mobile}>
        {shouldDisplayLoadingText ? loadingText : buttonText}
      </StyledTypography>
    </StyledButton>
  );
};

export default AddRecipeToCartButton;

const StyledButton = styled(Button)<{
  $isAddingItemsToCart?: boolean;
  $mobile: boolean;
}>`
  min-width: ${rem(240)};
  padding: ${({ $mobile }) => rem($mobile ? 12 : 8)} 0;
  ${({ $mobile }) => $mobile && "width: 100%;"};
  color: ${({ theme }) => theme.colors.marshmallow};
  background-color: ${({ theme }) => theme.colors.beet};

  ${({ $isAddingItemsToCart, theme }) =>
    $isAddingItemsToCart &&
    `
    background-color: ${theme.colors.eggplant};
  `}

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.marshmallow};
    background-color: ${({ theme }) => theme.colors.barley};
  }

  &:active {
    color: ${({ theme }) => theme.colors.marshmallow};
  }

  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.eggplant};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.eggplant};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
`;

const StyledTypography = styled(Typography)<{ $mobile: boolean }>`
  font-size: ${({ $mobile }) => rem($mobile ? 16 : 14)};
`;
