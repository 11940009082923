import { call, put, select } from "redux-saga/effects";
import stripeJs from "@stripe/stripe-js";

import ACTION_TYPES from "app/actionTypes/signup";
import { signupStepComplete } from "app/reducers/signup";
import { addPaymentSourceAction } from "app/reducers/account";
import {
  fetchUserFlow,
  addPaymentSourceFlow,
  createSubscriptionFlow,
} from "app/sagas/account";
import extractResponseMessage from "app/sagas/extractResponseMessage";
import {
  getSignupValues,
  getUser,
  getSignupSelectedFirstDelivery,
  canApplyNextGenReferral,
  getCapturedReferral,
} from "app/selectors";
import { SignupValues } from "app/types/state/signup";
import stripeCreateTokenWithCardElement, {
  ParamProps,
} from "app/ui/forms/stripeCreateTokenWithCardElement";
import CreateSubscriptionParams from "app/types/account/CreateSubscriptionParams";
import {
  APIType,
  FRIEND_TAGS,
  LogEventCode,
  ReferralAttributes,
} from "app/types/state/friendbuy";
import SetPaymentDetails, {
  PaymentValues,
} from "app/types/signup/setPaymentDetails";
import AddPaymentSourceParams from "app/types/state/account/AddPaymentSourceParams";
import * as Monitoring from "app/monitoring";
import { LogLevel } from "app/types/monitoring";

const DEFAULT_FAILURE_MESSAGE =
  "Could not save payment or subscription details. Please contact Customer Service.";

export const setPaymentDetailsSucceeded = (action: SetPaymentDetails) => ({
  ...action,
  type: ACTION_TYPES.SET_PAYMENT_DETAILS_SUCCEEDED,
});

export const setPaymentDetailsFailed = (
  action: SetPaymentDetails,
  failureMessage = DEFAULT_FAILURE_MESSAGE
) => ({
  ...action,
  type: ACTION_TYPES.SET_PAYMENT_DETAILS_FAILED,
  failureMessage,
});

const getBillingParams = (values: PaymentValues): ParamProps => {
  return {
    name: values.name,
    address: values.address,
    addressLine2: values.addressLine2,
    city: values.city,
    state: values.state,
    zip: values.zip,
  };
};

// set user's token
// create subscription, set delivery window, add coupon
function* setPaymentDetailsFlow(action: SetPaymentDetails) {
  const { values, stripe, cardElement, formikConfig } = action;
  const user = yield select(getUser);
  let shouldApplyReferral: boolean = false;
  let referral: ReferralAttributes | null = null;

  const getAddPaymentSourceAction = (
    token: stripeJs.Token
  ): AddPaymentSourceParams => {
    return addPaymentSourceAction({
      token: token.id,
      rethrow: true,
      extractErrorMessage: true,
      formikConfig,
    });
  };

  const { token, error }: stripeJs.TokenResult = yield call(
    stripeCreateTokenWithCardElement,
    stripe,
    cardElement,
    getBillingParams(values)
  );

  // Stripe has returned an error, user must fix credit card details
  if (error) {
    yield put(
      setPaymentDetailsFailed(
        action,
        error.message ? error.message : "Could not process payment"
      )
    );
    // Stripe returned a token, continue ...
  } else if (token) {
    try {
      // Persist the stripe payment info on the user record in the backend
      yield call(addPaymentSourceFlow, getAddPaymentSourceAction(token));

      // We need the payment configured before creating subscription;
      // Since the endpoint implictly creates an order if within customization window
      const signupValues: SignupValues = yield select(getSignupValues);
      const {
        selectedFrequency = "WEEKLY",
        boxId,
        selectedDeliveryWindowId,
        signupReasonId,
        signupReasonNotes,
        addOnBoxIds,
      } = signupValues;

      const selectedFirstDelivery = yield select(
        getSignupSelectedFirstDelivery
      );

      const selectedFirstDeliveryDate = selectedFirstDelivery?.deliveryDate;

      shouldApplyReferral = yield select(canApplyNextGenReferral);
      referral = yield select(getCapturedReferral);

      const eventSourceUrl = window?.location?.href;
      const subscriptionParams: CreateSubscriptionParams = {
        cadence: selectedFrequency,
        boxId,
        eventSourceUrl, // Facebook tracking requirement
        eventID: user.userId, // Facebook deduplication requirement
        deliveryWindowId: selectedDeliveryWindowId,
        ...(addOnBoxIds && addOnBoxIds.length && { addOnBoxIds }),
        ...(signupReasonId && { signupReasonId }),
        ...(signupReasonNotes && { signupReasonNotes }),
        ...(selectedFirstDeliveryDate && { selectedFirstDeliveryDate }),
        // Friendbuy referral attributes
        ...(shouldApplyReferral && {
          campaignId: referral?.campaignId,
          referralCode: referral?.referralCode,
          referringUserId: referral?.referringUserId,
        }),
        rethrow: true,
      };

      // Create subscription
      yield call(createSubscriptionFlow, subscriptionParams);

      if (shouldApplyReferral) {
        Monitoring.sendLog({
          level: LogLevel.INFO,
          tags: FRIEND_TAGS,
          message: "Friendbuy referral applied",
          referral,
          campaignId: referral?.campaignId,
          advocateUserId: referral?.referringUserId,
          apiType: APIType.NEXTGEN,
          eventCode: LogEventCode.VALID_REFERRAL_APPLY_SUCCEEDED,
        });
      }

      // Emit redux action to indicate the payment details were successfully set
      yield put(setPaymentDetailsSucceeded(action));

      // Conclude signup step
      yield put(signupStepComplete());

      // Return subscription fields for testing purposes
      return subscriptionParams;
    } catch (e) {
      if (shouldApplyReferral) {
        Monitoring.sendLog({
          level: LogLevel.ERROR,
          tags: FRIEND_TAGS,
          message: "Friendbuy referral could not be applied",
          error,
          referral,
          campaignId: referral?.campaignId,
          advocateUserId: referral?.referringUserId,
          apiType: APIType.NEXTGEN,
          eventCode: LogEventCode.VALID_REFERRAL_APPLY_FAILED,
        });
      }
      yield call(fetchUserFlow);
      const message = yield call(extractResponseMessage, e);
      yield put(
        setPaymentDetailsFailed(action, message || DEFAULT_FAILURE_MESSAGE)
      );
    }
  } else {
    // Most likely will not happen, but just in case we don't get a token or error ...
    yield put(setPaymentDetailsFailed(action));
  }
  return null;
}

export default setPaymentDetailsFlow;
