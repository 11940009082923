import queryString from "query-string";
import { call } from "redux-saga/effects";

import request from "./request";

import { config } from "app/config";
import { MMValidDaysRequest } from "app/types/api/misfitsApi/subscription";

const baseUrl = `${config.get("endpoints.misfits")}`;

export function* fetchMisfitsValidDays(params: MMValidDaysRequest) {
  const query = `?${queryString.stringify(params)}`;
  const url = `${baseUrl}/subscription/v1/validDays${query}`;
  // @ts-ignore
  return yield call(request, url, {}, "json", false);
}

export function* checkCustomerExists(params) {
  const url = `${baseUrl}/customer/v1/exists`;
  const body = JSON.stringify(params);
  // @ts-ignore
  return yield call(request, url, { method: "POST", body }, "json", false);
}

export function* jalapenoValidateCoupon(code, userId?: string) {
  const url = `${baseUrl}/discounts/v1/validate/check?code=${code}${
    userId ? `&custID=${userId}` : ""
  }`;

  // @ts-ignore
  return yield call(request, url, {}, "json", false);
}
