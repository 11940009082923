// @ts-nocheck
import { createSelector } from "reselect";

import * as fromCMS from "app/reducers/cms";

export function getCMSContent(state) {
  return fromCMS.getCMSContent(state.cms);
}

export function getMapOfCMSContentByUID(state) {
  return fromCMS.getMapOfCMSContentByUID(state.cms);
}

export function getMapOfCMSContentBySingleton(state) {
  return fromCMS.getMapOfCMSContentBySingleton(state.cms);
}

const makeGetCMSContentByTypeAndUID = (contentType) =>
  createSelector(
    [getMapOfCMSContentByUID, getCMSContent, (state, props) => props],
    (mapOfContentByUID, content, { uid }) => {
      if (contentType && mapOfContentByUID[contentType]) {
        if (mapOfContentByUID[contentType][uid]) {
          const { id, metadata } = mapOfContentByUID[contentType][uid];
          if (id && content[id]) {
            return {
              ...content[id],
              status: metadata.status,
            };
          }
          if (metadata.status === 404) {
            return {
              status: metadata.status,
            };
          }
        }
        return null;
      }
      return null;
    }
  );

export const getCMSMenuByUID = makeGetCMSContentByTypeAndUID(
  fromCMS.CONTENT_TYPES.MENU
);
export const getCMSShelf = makeGetCMSContentByTypeAndUID(
  fromCMS.CONTENT_TYPES.TOP_SHELF
);
