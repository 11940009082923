import React from "react";

import Carousel from "app/ui/shopping/FirstTimeCarousel/Carousel";
import Slide from "app/ui/shopping/FirstTimeCarousel/MobileCarousel/SlideMobile";
import CarouselContent from "app/ui/shopping/FirstTimeCarousel/carouselContents";

export interface MobileCarouselProps {
  orderNumber: number;
}

const MobileCarousel: React.FC<MobileCarouselProps> = ({ orderNumber = 0 }) => {
  const items = [...CarouselContent[orderNumber]];
  const slides = items.map((content) => (
    <Slide
      key={`Slide-${orderNumber}-${content.headLine.slice(4)}`}
      {...content}
    />
  ));

  return (
    // without a wrapper div for the nuka-carousel, the controls for the carousel
    // default to height: 100% and therefore the whole page,
    // this contains them within the carousel itself
    <div>
      <Carousel showControls={false}>{slides}</Carousel>
    </div>
  );
};

export default MobileCarousel;

export interface MobileCarouselToken {
  component: typeof MobileCarousel;
  componentProps: MobileCarouselProps;
  data: {};
  listItemHeight: number;
}

export const getMobileCarouselToken = (
  props: MobileCarouselProps
): MobileCarouselToken => ({
  component: MobileCarousel,
  componentProps: props,
  data: {},
  listItemHeight: 192,
});
