import React from "react";
import { rem } from "polished";
import styled, { keyframes } from "styled-components";

// modified from https://codepen.io/thetallweeks/pen/yybGra

const asciiEllipsisCharacter = `"\\2026"`;
const ellipsisAnimation = keyframes`
  to {
    width: ${rem(16)};
  }
`;

const AnimationText = styled.span`
  &:after {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsisAnimation} steps(4, end) 1.5s infinite;
    content: ${asciiEllipsisCharacter};
    width: 0px;
  }
`;

export interface EllipsisAnimationTextProps {
  children: React.ReactNode;
}

const EllipsisAnimationText: React.FC<EllipsisAnimationTextProps> = ({
  children,
}) => {
  return <AnimationText>{children}</AnimationText>;
};

export default EllipsisAnimationText;
