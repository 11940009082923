import { rem } from "polished";

const root = {};

export const rounded = {
  borderRadius: rem(8),
  overflow: "hidden",
};

export const outlined = {};

export default root;
