import { LOCATION_CHANGE } from "connected-react-router";
import { Middleware } from "redux";

import { addLocationQuery } from "app/router/routes";
import { getRoutingLocation } from "app/selectors";

const middleware: Middleware = (store) => (next) => (action) => {
  if (action.type === LOCATION_CHANGE) {
    const { location } = action.payload;
    const modifiedAction = {
      ...action,
      payload: {
        ...action.payload,
        // query *should* be injected into location as of https://github.com/supasate/connected-react-router/releases/tag/v6.6.0
        // but just in case ...
        ...(!location.query && {
          location: addLocationQuery(location),
        }),
        // currentLocation is the location before this action completes
        currentLocation: getRoutingLocation(store.getState()),
      },
    };
    return next(modifiedAction);
  }

  return next(action);
};

export default middleware;
