import moment from "moment-timezone";
import { createSelector } from "reselect";

import { FREE_DELIVERY_THRESHOLD_DEFAULT } from "app/constants/orders";
import {
  START_CART_PUSHED,
  CANCELED,
  CREATED,
  LOCKED,
  OVER_CAPACITY,
} from "app/reducers/orders";
import * as fromOrders from "app/selectors/orders";
import * as fromUI from "app/selectors/ui";
import State from "app/types/state";
import { DEFAULT_PRICE_ZONE_ID } from "app/constants";

export const getActiveOrderId = createSelector(
  [fromOrders.getActiveOrderMetadata],
  (active) => active.orderId
);

export const getActiveOrderLoading = createSelector(
  [fromOrders.getActiveOrderMetadata],
  (active) => active.loading
);

export const getActiveOrderUpdating = createSelector(
  [fromOrders.getActiveOrderMetadata],
  (active) => active.updating
);

export const getActiveOrder = createSelector(
  [getActiveOrderId, fromOrders.getAllOrders],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  (orderId, orders) => orders[orderId!] || {}
);

export const getActiveOrderStatus = createSelector(
  [getActiveOrder],
  (order) => order.status
);

export const getActiveOrderDeliveryDate = createSelector(
  [getActiveOrder],
  (order) => order.deliveryDate || undefined
);

export const getActiveOrderPackDate = createSelector(
  [getActiveOrder],
  (order) => order.packDate || undefined
);

export const isActiveOrderDelayed = createSelector(
  [getActiveOrder],
  (activeOrder) => {
    return (
      !!activeOrder.delayedOrderOriginalDeliveryDate &&
      activeOrder.status !== CANCELED
    );
  }
);

export const getPreviousOrderCancelReasonCode = (state: State) => {
  const previousOrder = fromOrders.getPreviousOrder(state);
  if (previousOrder) {
    return previousOrder.cancelReasonCode;
  }
  return null;
};

export const isPreviousOrderOverCapacity = createSelector(
  [getPreviousOrderCancelReasonCode],
  (cancelReasonCode) => cancelReasonCode === OVER_CAPACITY
);

export const getPreviousOrderDeliveryDate = (state: State) => {
  const previousOrder = fromOrders.getPreviousOrder(state);
  if (previousOrder) {
    return previousOrder.deliveryDate;
  }
  return null;
};

export const getPreviousOrderStatus = (state: State) => {
  const previousOrder = fromOrders.getPreviousOrder(state);
  if (previousOrder) {
    return previousOrder.status;
  }
  return null;
};

export const isPreviousOrderDelayed = (state: State) => {
  const previousDeliveryDate = getPreviousOrderDeliveryDate(state);
  const previousOrderStatus = getPreviousOrderStatus(state);
  if (!previousDeliveryDate || !previousOrderStatus) {
    return false;
  }
  return (
    !["Closed", "Canceled", "Delivered"].includes(previousOrderStatus) &&
    moment(previousDeliveryDate).isSameOrAfter(moment().format("YYYY-MM-DD"))
  );
};

export const getPreviousOrderDeliveryDateFormatted = (state: State) => {
  const previousDeliveryDate = getPreviousOrderDeliveryDate(state);
  const previousDeliveryDateMoment = moment(previousDeliveryDate);
  if (previousDeliveryDate && previousDeliveryDateMoment.isValid()) {
    return previousDeliveryDateMoment.format("dddd, MMMM Do");
  }
  return null;
};

export const canUserCustomize = createSelector(
  [getActiveOrderStatus, fromUI.getCatalogPreviewTool],
  (status, catalogPreviewTool) => {
    const catalogPreviewToolData = catalogPreviewTool?.data;
    return !!(status === START_CART_PUSHED || catalogPreviewToolData);
  }
);

export const canNonAdminUserCustomize = createSelector(
  [getActiveOrderStatus],
  (status) => status === START_CART_PUSHED
);

export const canUserCancelOrder = createSelector(
  [getActiveOrderStatus],
  (status) => status && [CREATED, START_CART_PUSHED].includes(status)
);

export const activeOrderPreDispatch = createSelector(
  [getActiveOrderStatus, canUserCancelOrder],
  (status, activeOrder) => activeOrder || (status && status === LOCKED)
);

// :point_down: Stephen's Terminology for an order that is too late to cancel
export const isOrderFulfillmentInMotion = createSelector(
  [canUserCancelOrder, getActiveOrderStatus],
  (canCancel, status) => status && !canCancel && status !== CANCELED
);

export const getActiveOrderFailedInitialAuth = createSelector(
  [getActiveOrder],
  (order) => order.failedInitialAuth || false
);

export const getActiveOrderFulfillmentCenter = createSelector(
  [getActiveOrder],
  (order) => order && order.fcId
);

export const getActiveOrderPriceZoneId = createSelector(
  [getActiveOrder],
  (order) => order && order.priceZoneId
);

export const getActiveOrderVariantIds = createSelector(
  [getActiveOrder],
  (order) => order && order.lineItems
);

export const getActiveOrderCoupons = createSelector(
  [getActiveOrder],
  (order) => order.coupons || []
);

// The applied coupon means one applied from signup or account,
// ie not a membership coupon etc
export const getActiveOrderAppliedCoupon = createSelector(
  [getActiveOrderCoupons],
  (coupons) => coupons.find((c) => !c.membership)
);

export const getActiveOrderPriceZoneFcAndDate = createSelector(
  [getActiveOrder],
  (order) => {
    if (!order) return null;

    return {
      packDate: order.packDate,
      fcAbbr: order.fcAbbr,
      fcId: order.fcId,
      priceZoneId: order.priceZoneId || DEFAULT_PRICE_ZONE_ID,
    };
  }
);

export const getFreeDeliveryThreshold = createSelector(
  [getActiveOrder],
  (order) => {
    return order?.freeDeliveryThreshold?.value || null;
  }
);

export const getFreeDeliveryThresholdReason = createSelector(
  [getActiveOrder],
  (order) => {
    return order?.freeDeliveryThreshold?.reason || null;
  }
);

// Meaning, the order was assigned the Free Delivery Split Treatment
// by the order service
export const isDefaultFreeDeliveryThreshold = createSelector(
  [getFreeDeliveryThresholdReason],
  (reason) => reason === FREE_DELIVERY_THRESHOLD_DEFAULT
);
