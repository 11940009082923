import React from "react";
import { LinkProps } from "@prismicio/react";

import { LinkWithRef } from "app/ui/shared/Link";
import { getPrismicLink } from "app/ui/cms/contentPage_DEPRECATED/utils_DEPRECATED";

// See documentation here for more details:
// https://imperfectfoods.atlassian.net/wiki/spaces/HF/pages/1144291374/Imperfect+Developer+s+Guide+to+Prismic#Links
const PrismicLinkComponent = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, ...restProps }, ref) => {
    const link = getPrismicLink({ url: href, ...restProps });
    return <LinkWithRef ref={ref} {...restProps} {...link} />;
  }
);

export default PrismicLinkComponent;
