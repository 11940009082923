import { createSelector } from "reselect";

import * as fromSignup from "../reducers/signup";

import { SignupBannerStep } from "app/types/selectors/signup";
import State from "app/types/state";
import { SignupFlowSteps, SignupSteps, Step } from "app/types/state/signup";

export function isSignupStateInitialized(state: State) {
  return fromSignup.isInitialized(state.signup);
}

export function getSignupValues(state: State) {
  const values = fromSignup.getValues(state.signup);

  if (values?.addOnBoxIds) {
    return values;
  }

  // Somehow the addonBoxIds is getting overwritten for lapsed users
  // but we don't know exactly why
  // Guarantee a stable array is available to the app at the selector level
  values.addOnBoxIds = [];
  return values;
}

export const getSignupZipCode = createSelector(
  [getSignupValues],
  ({ zip }) => zip
);

export const getSignupEmail = createSelector(
  [getSignupValues],
  ({ email }) => email
);

// TODO: NC-1244 productionalize signup shopping
export const getSignupBoxId = createSelector(
  [getSignupValues],
  ({ boxId }) => boxId
);

export function getSignupSelectedDeliveryWindowId(state: State) {
  const values = fromSignup.getValues(state.signup);
  return values?.selectedDeliveryWindowId;
}

export const getSignupSelectedSecondaryBoxIds = createSelector(
  [getSignupValues],
  ({ addOnBoxIds }) => addOnBoxIds
);

// TODO: NC-1244 productionalize signup shopping
export const getSignupVariantQuantity = (state: State, variantId: string) =>
  fromSignup.getVariantQuantity(state.signup, variantId);

export const getSignupVariantQuantities = (state: State) =>
  fromSignup.getVariantQuantities(state.signup);

export const getNumberOfItemsInSignupOrder = (state: State) =>
  fromSignup.getNumberOfItemsInSignupOrder(state.signup);

// TODO: NC-1244 productionalize signup shopping
export const getOrderPosit = (state: State) =>
  fromSignup.getOrderPosit(state.signup);

export const getIsPrefilledCartFilled = (state: State) =>
  fromSignup.getIsPrefilledCartFilled(state.signup);

export const getProspectiveCoupon = (state: State) =>
  fromSignup.getProspectiveCoupon(state.signup);

export const getUTMParams = (state: State) =>
  fromSignup.getUTMParams(state.signup);

export const getGCLID = (state: State) => fromSignup.getGCLID(state.signup);

export function getSignupCurrentStep(state: State) {
  return fromSignup.getCurrentStep(state.signup);
}

export function getSignupProgressStep(state: State) {
  return fromSignup.getProgressStep(state.signup);
}

export function getSignupConfig(state: State) {
  return fromSignup.getConfig(state.signup);
}

export function getSubscriptionPosit(state: State) {
  return fromSignup.getSubscriptionPosit(state.signup);
}

export function getSignupFlow(state: State) {
  return getSignupConfig(state).flow;
}

export function getSignupConfirmationStep(state: State) {
  return getSignupConfig(state).confirmation;
}

export const getSignupQuizStepProgress = createSelector(
  [getSignupConfig, getSignupCurrentStep],
  (config, currentStep) => {
    const { metadata } = config;
    const isQuizStep = currentStep && metadata[currentStep].isQuizStep;

    if (!isQuizStep) return {};

    const quizSteps = Object.entries(metadata).filter(
      ([, stepmeta]) => stepmeta.isQuizStep
    );

    const currentStepIndex =
      quizSteps.findIndex(([step]) => step === currentStep) + 1;

    return { totalStepCount: quizSteps.length, currentStepIndex };
  }
);

const getSignupBannerSteps = createSelector(
  [getSignupConfig, getSignupCurrentStep, getSignupProgressStep],
  (config, currentStep, progressStep) => {
    const { flow, metadata } = config;

    const isEnabled = (
      step: SignupFlowSteps,
      furthestStepReached: SignupFlowSteps
    ) => {
      return flow.indexOf(furthestStepReached) >= flow.indexOf(step);
    };

    const isActive = (
      step: SignupFlowSteps | SignupSteps,
      activeStep: SignupFlowSteps | SignupSteps
    ) => activeStep === step;

    return flow.reduce((acc: SignupBannerStep[], step) => {
      const stepMetadata = metadata[step];

      if (stepMetadata.banner) {
        const enabled = isEnabled(step, progressStep!);
        const active = isActive(step, currentStep!);
        acc.push({
          step,
          name: stepMetadata.banner,
          url: stepMetadata.url,
          enabled,
          active,
          complete: enabled && !active,
        });
      }
      return acc;
    }, []);
  }
);

export const getCheckoutProgressSteps = createSelector(
  [getSignupBannerSteps],
  (steps) => {
    return steps.filter((step) => step.step !== SignupSteps.SUBSCRIPTION);
  }
);

export const getSignupPreviousStep = createSelector(
  [getSignupConfig, getSignupCurrentStep],
  (config, currentStep) => {
    const { flow } = config;
    const index = flow.indexOf(currentStep!);
    return flow[index - 1];
  }
);

export const getUrlForSignupPreviousStep = createSelector(
  [getSignupConfig, getSignupPreviousStep],
  (config, previousStep) => {
    if (!previousStep) return null;
    const previousStepMetaData = config.metadata[previousStep];
    return previousStepMetaData.url;
  }
);

export const getMetadataForSignupStep = createSelector(
  [getSignupConfig, (_: State, props: { stepId: SignupSteps }) => props],
  (config, { stepId }) => config.metadata[stepId]
);

export const getMetadataForCurrentSignupStep = createSelector(
  [getSignupConfig, getSignupCurrentStep],
  (config, currentStep): Step => {
    const step = currentStep || config.flow[0];

    return config.metadata[step];
  }
);

export const getUrlForSignupStep = createSelector(
  [getMetadataForCurrentSignupStep],
  (metadata) => metadata.url
);
