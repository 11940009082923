import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Portal } from "react-portal";
import styled, { css } from "styled-components";
import { colorUtils } from "app/styles/utils";
import { rem } from "polished";

import { hideModal } from "app/reducers/ui";
import {
  isModalVisible,
  getModalParams,
  shouldRenderAccountPage,
} from "app/selectors";
import breakpoints, { sizes } from "app/styles/breakpoints";
import grid from "app/styles/grid";
import State from "app/types/state";
import { ModalParams } from "app/types/ui/Modal";
import { stopPropagation, onEscape } from "app/ui/global/utils";
import CloseIconButton from "app/ui/shared/CloseIconButton";
import { FadeInMask, MaskProps } from "app/ui/shared/Mask";
import { maxWidth, minWidth } from "app/styles/widths";

// Should this be React.CSSProperties?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModalStylesValue = any;

interface ModalStyles {
  mask?: ModalStylesValue;
  outer?: ModalStylesValue;
  inner?: ModalStylesValue;
}

export type CloseModal = (
  _event?: React.MouseEvent,
  params?: ModalParams
) => void;
type OnCloseModal = () => void;
export type ScrollModalTo = (val?: number) => void;

export type ModalChildrenFunction = (
  closeModal: CloseModal,
  params: ModalParams,
  scrollModalTo?: ScrollModalTo
) => JSX.Element | null;

export interface ModalOuterProps extends MaskProps {
  modalId: string;
  title?: string;
  closable?: boolean;
  closeOnBackgroundClick?: boolean;
  showCloseButton?: boolean;
  wide?: boolean;
  narrow?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
  modalStyles?: ModalStyles;
  restrictHeight?: boolean;
  // This appears to come from FadeInMask but it's not typed completely?
  duration?: string;
  childHasInitialFocusRef?: boolean;
  ariaLabelledBy?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  footer?: any;
  onCloseModal?: OnCloseModal;
  scrollModalTo?: ScrollModalTo;
  visible?: boolean;
  params?: ModalParams;
  children: ModalChildrenFunction;
}

export type ModalProps = ModalOuterProps;

/*
 * Expects an existing dom node with id 'modal'.
 * Also expects only one modal to be visible at a time.
 */
const Modal: React.FC<ModalProps> = (props) => {
  const {
    title,
    children,
    closable = true,
    closeOnBackgroundClick = true,
    showCloseButton = true,
    dark = false,
    wide = false,
    narrow = false,
    fullWidth = false,
    noPadding = false,
    modalStyles,
    restrictHeight = true,
    duration = "250ms",
    footer,
    noBorder,
    ariaLabelledBy,
    childHasInitialFocusRef = false,
    modalId,
    onCloseModal,
  } = props;

  const visibleFromSelector = useSelector((state: State) =>
    isModalVisible(state, { modalId })
  );
  const paramsFromSelector = useSelector((state: State) =>
    getModalParams(state, { modalId })
  );

  const visible = visibleFromSelector || props.visible;
  const params = paramsFromSelector || props.params;

  const dispatch = useDispatch();
  const childContentRef = useRef(null) as React.RefObject<HTMLDivElement>;

  const closeModal = (
    _event?: React.MouseEvent,
    closeModalParams?: ModalParams
  ) => {
    dispatch(hideModal(modalId, closeModalParams));
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (visible && closable !== false) {
      onEscape(() => {
        dispatch(hideModal(modalId));
        if (onCloseModal) {
          onCloseModal();
        }
      })(e);
    }
  };

  const scrollModalTo = (val = 0) => {
    if (childContentRef.current === null) return;
    childContentRef.current.scrollTop = val;
  };

  const previouslyVisible = useRef(visible);
  const shouldOnlyRenderAccountPage = useSelector(shouldRenderAccountPage);

  useEffect(() => {
    if (visible) {
      document.body.classList.add("disallow-scroll");
      document.getElementsByTagName("html")[0].classList.add("html-height");
      document.addEventListener("keydown", onKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.body.classList.remove("disallow-scroll");
      document.getElementsByTagName("html")[0].classList.remove("html-height");
    };
  }, []);

  useEffect(() => {
    const modalNowOpen = visible && !previouslyVisible.current;
    const modalNowClosed = !visible && previouslyVisible.current;

    if (modalNowOpen) {
      document.body.classList.add("disallow-scroll");
      document.getElementsByTagName("html")[0].classList.add("html-height");
      document.addEventListener("keydown", onKeyDown);
    } else if (modalNowClosed) {
      document.body.classList.remove("disallow-scroll");
      document.getElementsByTagName("html")[0].classList.remove("html-height");
    }

    previouslyVisible.current = visible;
  }, [visible]);

  if (!visible) return null;

  const updatableTitle = params.title || title;
  const ariaLabelledByDefaultTitle = updatableTitle
    ? { "aria-labelledby": "modal-title" }
    : ariaLabelledBy && { "aria-labelledby": ariaLabelledBy };

  // @ts-ignore
  const defaultRefFocus = { ref: (ref) => ref && ref.focus(), tabIndex: -1 };
  const useTitleRef = updatableTitle && !childHasInitialFocusRef;
  const useContentRef = !useTitleRef && !childHasInitialFocusRef;

  return (
    <Portal>
      <ModalMask
        style={modalStyles && modalStyles.mask}
        $fullWidth={fullWidth}
        dark={dark}
        onClick={closable && closeOnBackgroundClick ? closeModal : null}
        duration={duration}
        data-clickid="modal-mask"
      >
        {/** This needs more work w.r.t accessibilty */}
        <aside role="dialog" {...ariaLabelledByDefaultTitle} aria-modal>
          {/** This use of ref for Content is a little hacky, ideally there is a focusable element in each modal */}
          <Content
            $wide={wide}
            $narrow={narrow}
            $fullWidth={fullWidth}
            $noPadding={noPadding}
            $noBorder={noBorder}
            $modalStyles={modalStyles}
            // @ts-ignore
            onClick={stopPropagation}
            {...(useContentRef && defaultRefFocus)}
          >
            {!!updatableTitle && (
              <Title {...(useTitleRef && defaultRefFocus)} id="modal-title">
                {updatableTitle}
              </Title>
            )}
            <ChildrenContent
              $restrictHeight={restrictHeight}
              $fullWidth={fullWidth}
              $modalStyles={modalStyles}
              $hasFooter={!!footer}
              $shouldOnlyRenderAccountPage={shouldOnlyRenderAccountPage}
              ref={childContentRef}
            >
              {children(closeModal, params, scrollModalTo)}
            </ChildrenContent>
            {footer && footer}
            {closable && showCloseButton && (
              <CloseButton onClick={closeModal} />
            )}
          </Content>
        </aside>
      </ModalMask>
    </Portal>
  );
};

export const ModalMask = styled(FadeInMask)<{ $fullWidth: boolean }>`
  z-index: ${({ theme }) => theme.layout.zIndex.modal};

  ${({ theme, $fullWidth }) =>
    $fullWidth &&
    css`
      margin-top: ${rem(theme.layout.headerHeightMobile)};

      ${breakpoints.lg`
      margin-top: 0;
    `}
    `};
`;

const MAX_WIDTH = 600,
  MAX_WIDTH_NARROW = 325,
  MAX_WIDTH_WIDE = 900;

const widthValue = ($wide?: boolean, $narrow?: boolean) => {
  if ($wide) {
    return MAX_WIDTH_WIDE;
  }
  if ($narrow) {
    return MAX_WIDTH_NARROW;
  }
  return MAX_WIDTH;
};

export const Content = styled.div<{
  $fullWidth?: boolean;
  $noBorder?: boolean;
  $noPadding?: boolean;
  $wide?: boolean;
  $narrow?: boolean;
  $modalStyles?: ModalStyles;
}>`
  ${grid.row};
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ $fullWidth }) => ($fullWidth ? 0 : rem(3))};
  ${({ theme, $noBorder }) => !$noBorder && `border: ${theme.borders.default}`};
  box-shadow: ${({ theme }) => theme.boxShadows.default};
  margin: ${({ $fullWidth }) => ($fullWidth ? 0 : rem(16))};
  padding: ${({ $noPadding }) => ($noPadding ? 0 : rem(16))};
  overflow: hidden;
  position: relative;

  ${({ $wide, $narrow }) =>
    minWidth(widthValue($wide, $narrow))`
    margin: ${rem(60)} auto ${rem(16)};
    max-width: ${rem(widthValue($wide, $narrow))};
  `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    maxWidth(sizes.lg - 1)`
    height: 100%;
  `};

  ${({ $modalStyles }) =>
    !!$modalStyles && !!$modalStyles.outer && $modalStyles.outer};
`;

export const ChildrenContent = styled.div<{
  $restrictHeight?: boolean;
  $fullWidth?: boolean;
  $hasFooter: boolean;
  $modalStyles?: ModalStyles;
  $shouldOnlyRenderAccountPage?: boolean;
}>`
  overflow: auto;
  padding: ${rem(4)};

  ${({ $restrictHeight, $fullWidth, $hasFooter }) => {
    if ($restrictHeight) {
      if ($fullWidth) {
        return css`
          ${breakpoints.lg`
            max-height: 75vh;
          `}
          ${breakpoints.xl`
            max-height: 90vh;
          `}
        `;
      }
      if ($hasFooter) {
        return css`
          max-height: 75vh;

          ${maxWidth(sizes.xl - 1)`
            max-height: 60vh;
          `};
        `;
      }
      return `
        max-height: 75vh;
      `;
    }
    return null;
  }}

  p {
    line-height: normal;
  }

  ${({ $modalStyles }) =>
    !!$modalStyles && !!$modalStyles.inner && $modalStyles.inner};

  ${({ $shouldOnlyRenderAccountPage }) =>
    $shouldOnlyRenderAccountPage && `max-height: 100vh;`}
`;

const Title = styled.h1`
  background-color: ${({ theme }) => theme.colors.grayLightest};
  border-bottom: ${({ theme }) => theme.borders.default};
  font-size: ${rem(20)};
  line-height: 1.25;
  margin: ${rem(-16)} ${rem(-16)} ${rem(16)};
  padding: ${rem(12)} ${rem(16)};
`;

export const CloseButton = styled(CloseIconButton)`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(16)};
  z-index: ${({ theme }) => theme.layout.zIndex.modal};

  &:focus {
    border: ${rem(2)} solid ${({ theme }) => theme.colors.beet};
    margin: ${rem(-2)};
    box-shadow: 0 0 0 ${rem(3)}
      ${({ theme }) => colorUtils.alpha(theme.colors.beet, 0.2)};
    outline: ${rem(2)} solid transparent;
  }
`;

export default Modal;
