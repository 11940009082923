import { Direction } from "app/ui/designSystem/molecules/animations/BlinkingEyes";

// https://www.figma.com/file/TwvY7BY0QGEC1yEdpuVOVK/%E2%8F%B1-Imperfect-Foods-Library?node-id=384%3A23
export type ProductType =
  | "broccoli"
  | "cabbage"
  | "clementine"
  // "fennel" |
  // "mango" |
  | "peach"
  | "pear"
  // "beet" |
  | "potato";

export type ProductLayoutConfigType = {
  topOffset: number;
  leftOffset: number;
  productWidth: number;
  eyesWidth: number;
  eyesRotation: number; // in degrees
  peekOffset: number;
};

export const getProductLayoutConfig = (
  product: ProductType,
  direction: Direction
): ProductLayoutConfigType => {
  const DEFAULT_TOP_OFFSET = 20;
  const DEFAULT_LEFT_OFFSET = 20;
  const DEFAULT_PRODUCT_WIDTH = 72;
  const DEFAULT_EYES_WIDTH = 30;
  const DEFAULT_ROTATION = 10;
  const DEFAULT_PEEK_OFFSET = 50; // for VeggieBlock component

  let productLayoutConfig;

  switch (product) {
    case "clementine": {
      productLayoutConfig = {
        left: {
          topOffset: 25,
          leftOffset: 15,
          productWidth: 110,
          eyesWidth: 25,
          eyesRotation: DEFAULT_ROTATION,
        },
        right: {
          topOffset: 25,
          leftOffset: 70,
          productWidth: 110,
          eyesWidth: 25,
          eyesRotation: -DEFAULT_ROTATION,
        },
        peekOffset: 55,
      };
      break;
    }
    case "pear": {
      productLayoutConfig = {
        left: {
          topOffset: 45,
          leftOffset: 7,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: DEFAULT_EYES_WIDTH,
          eyesRotation: DEFAULT_ROTATION,
        },
        right: {
          topOffset: 45,
          leftOffset: 35,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: DEFAULT_EYES_WIDTH,
          eyesRotation: -DEFAULT_ROTATION,
        },
        peekOffset: 75,
      };
      break;
    }
    case "broccoli": {
      productLayoutConfig = {
        left: {
          topOffset: 44,
          leftOffset: 29,
          productWidth: 100,
          eyesWidth: 22,
          eyesRotation: DEFAULT_ROTATION,
        },
        right: {
          topOffset: 48,
          leftOffset: 49,
          productWidth: 100,
          eyesWidth: 22,
          eyesRotation: -DEFAULT_ROTATION,
        },
        peekOffset: 80,
      };
      break;
    }
    case "cabbage": {
      productLayoutConfig = {
        left: {
          topOffset: 15,
          leftOffset: 14,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: 28,
          eyesRotation: DEFAULT_ROTATION,
        },
        right: {
          topOffset: 15,
          leftOffset: 30,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: 28,
          eyesRotation: -DEFAULT_ROTATION,
        },
        peekOffset: DEFAULT_PEEK_OFFSET,
      };
      break;
    }
    case "peach": {
      productLayoutConfig = {
        left: {
          topOffset: 32,
          leftOffset: 15,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: 20,
          eyesRotation: -20,
        },
        right: {
          topOffset: 32,
          leftOffset: 40,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: 20,
          eyesRotation: 20,
        },
        peekOffset: DEFAULT_PEEK_OFFSET,
      };
      break;
    }
    default: {
      productLayoutConfig = {
        left: {
          topOffset: DEFAULT_TOP_OFFSET,
          leftOffset: DEFAULT_LEFT_OFFSET,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: DEFAULT_EYES_WIDTH,
          eyesRotation: DEFAULT_ROTATION,
        },
        right: {
          topOffset: DEFAULT_TOP_OFFSET,
          leftOffset: DEFAULT_LEFT_OFFSET,
          productWidth: DEFAULT_PRODUCT_WIDTH,
          eyesWidth: DEFAULT_EYES_WIDTH,
          eyesRotation: -DEFAULT_ROTATION,
        },
        peekOffset: DEFAULT_PEEK_OFFSET,
      };
      break;
    }
  }
  return {
    ...productLayoutConfig[direction],
    peekOffset: productLayoutConfig.peekOffset,
  };
};
