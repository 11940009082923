import React from "react";
import { Grid, Box, List, ListItem, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { rem } from "polished";
import styled, { css } from "styled-components";

import { isNativeApp } from "app/selectors";
import Logo from "app/ui/designSystem/molecules/Logo";
import Button from "app/ui/designSystem/molecules/Button";
import Link from "app/ui/shared/Link";
import theme from "app/styles/theme";
import { Link3 } from "app/ui/shared/ThemedTypography";
import { getImageURL } from "app/ui/global/utils";
import routes from "app/router/routes.json";
import BCert from "app/images/b-cert";
import SvgFooterBottomLine from "app/images/footerBottomLine.svg";
import {
  SOCIAL_NETWORKS,
  FOOTER_BUTTONS,
  FOOTER_NAV_LINKS,
} from "app/ui/universal/footer/constants";
import breakpoints from "app/styles/breakpoints";
import AppBadge, {
  Variant as AppBadgeVariant,
} from "app/ui/designSystem/molecules/AppBadge";

type FooterNavLink = {
  name: string;
  url: string;
  external?: boolean;
};

type FooterButton = {
  name: string;
  url: string;
};

const renderFooterNavLinks = (lines: FooterNavLink[]): JSX.Element => (
  <NavLinksLine aria-label="Footer nav links">
    {lines.map((item: FooterNavLink) => (
      <NavLinksItem
        key={item.name}
        data-testid={`footerNav-${item.name}`}
        button={false as any} // TODO we can remove this prop when we upgrade to MUI v5
      >
        <FooterLink
          variant="text"
          disableRipple={false}
          {...(item.external
            ? {
                target: "_blank",
                rel: "noopener noreferrer",
                href: item.url,
              }
            : {
                component: RouterLink,
                to: item.url,
                role: undefined,
              })}
        >
          {item.name}
        </FooterLink>
      </NavLinksItem>
    ))}
  </NavLinksLine>
);

const renderFooterButtons = (buttons: FooterButton[]): JSX.Element => (
  <Box>
    {buttons.map((button: FooterButton) => (
      <BeetBoxButton
        key={button.name}
        size="medium"
        variant="contained"
        to={button.url}
        component={RouterLink}
        data-testid={`footerNav-${button.name}`}
      >
        {button.name}
      </BeetBoxButton>
    ))}
  </Box>
);

interface FooterProps {
  zIndex?: number;
  shouldShowBeetBox?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  zIndex = theme.layout.zIndex.footer,
  shouldShowBeetBox = false,
}) => {
  const year = new Date().getFullYear();
  const shouldAddTopMargin = !shouldShowBeetBox;
  const isInNativeApp = useSelector(isNativeApp);

  if (isInNativeApp) {
    return null;
  }

  return (
    <FooterContainer
      data-testid="beet-footer"
      container
      $shouldAddTopMargin={shouldAddTopMargin}
      $zIndex={zIndex}
      component="footer"
    >
      <Grid container item justify="center">
        {shouldShowBeetBox && (
          <BeetBoxContainer alignItems="center" flexDirection="columns">
            <BeetBoxButtonsContainer display={{ xs: "none", lg: "block" }}>
              {renderFooterButtons(FOOTER_BUTTONS.LINE_1)}
              {renderFooterButtons(FOOTER_BUTTONS.LINE_2)}
            </BeetBoxButtonsContainer>
            <BeetBox data-testid="footer-parachuting-beet" />
            <SvgFooterBottomLine />
          </BeetBoxContainer>
        )}
      </Grid>
      <Grid container item>
        <NavLinksContainer display={{ xs: "none", lg: "flex" }}>
          {renderFooterNavLinks(FOOTER_NAV_LINKS.LINE_1)}
          {renderFooterNavLinks(FOOTER_NAV_LINKS.LINE_2)}
        </NavLinksContainer>
      </Grid>
      <Grid container item>
        <Grid item lg={4}>
          <Box display={{ xs: "none", lg: "flex" }}>
            <StyledLogo
              variant="white"
              size="xlarge"
              to="/"
              data-testid="footerLogo"
            />
          </Box>
        </Grid>
        <SocialContainer container item xs={12} lg={8} alignItems="center">
          <BCertContainer display={{ xs: "none", lg: "flex" }}>
            <BCert />
          </BCertContainer>
          <SocialLinks>
            {SOCIAL_NETWORKS.map((network) => (
              <a
                key={network.name}
                href={network.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${network.ariaLabel} (opens in new window)`}
              >
                <network.ico />
              </a>
            ))}
          </SocialLinks>
          <AppBadgeContainer>
            <AppBadge variant={AppBadgeVariant.AppStore} />
            <AppBadge variant={AppBadgeVariant.GooglePlayStore} />
          </AppBadgeContainer>
        </SocialContainer>
      </Grid>
      <Grid container item xs={12}>
        <CopyContainer item xs={12}>
          <Typography variant="caption">
            Imperfect Foods &copy; {year}. All Rights Reserved
          </Typography>
        </CopyContainer>
        <CopyContainer item xs={12}>
          <Typography variant="caption">
            <Link3 component="span">
              <Link to={routes.terms.url}>Terms and Conditions</Link>
            </Link3>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link3 component="span">
              <Link to={routes.privacy.url}>Privacy Policy</Link>
            </Link3>
            <Box display={{ xs: "none", lg: "inline" }}>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </Box>
            <Box display={{ xs: "inline", lg: "none" }}>
              <br />
            </Box>
            <Link3 component="span">
              <a href="https://privacy.misfitsmarket.com/">
                Do Not Sell or Share My Personal Information
              </a>
            </Link3>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link3 component="span">
              <Link
                to={{
                  pathname: routes.privacy.url,
                  hash: "6-california-privacy-rights",
                }}
              >
                California Privacy Notice
              </Link>
            </Link3>
          </Typography>
        </CopyContainer>
      </Grid>
    </FooterContainer>
  );
};

const FooterContainer = styled(Grid)<{
  $shouldAddTopMargin: boolean;
  $zIndex: number;
  component: string;
}>`
  background-color: ${({ theme }) => theme.colors.beet};
  padding: ${rem(16)};
  position: relative;
  z-index: ${({ $zIndex }) => $zIndex};
  margin-bottom: -${rem(18)};
  padding-bottom: ${rem(32)};
  margin-top: ${({ $shouldAddTopMargin }) =>
    $shouldAddTopMargin ? rem(98) : 0};

  ${({ $shouldAddTopMargin }) => {
    return css`
      ${breakpoints.md`
        margin-top: ${$shouldAddTopMargin ? rem(114) : 0};
      `}
    `;
  }}

  ${breakpoints.lg`
    padding: ${rem(24)} ${rem(48)};
  `}

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.beet};
    clip-path: ellipse(180% 100% at 50% 100%);
    pointer-events: none;
    z-index: -1;
    left: 0;
    height: ${rem(250)};
    top: ${rem(-50)};

    ${breakpoints.lg`
      clip-path: ellipse(85% 100% at 50% 100%);
    `}

    ${breakpoints.md`
      clip-path: ellipse(120% 100% at 50% 100%);
    `}
  }
`;

const BeetBoxContainer = styled(Box)`
  position: relative;
  max-width: ${rem(934)};
  margin-top: ${rem(60)};
  margin-bottom: ${rem(24)};
  width: 75%;

  ${breakpoints.md`
    margin-bottom: ${rem(84)};
    width: 100%;
  `}
`;

const BeetBoxButtonsContainer = styled(Box)`
  & > :first-child {
    display: flex;
    padding: 0;
    justify-content: space-between;
    margin-bottom: ${rem(60)};

    ${breakpoints.lg`
      padding: 0 ${rem(56)};
    `}
  }
  & > :last-child {
    display: flex;
    justify-content: space-around;
    margin-bottom: ${rem(40)};
  }
`;

const BeetBoxButton = styled(Button)`
  min-width: ${rem(250)};
`;

const BeetBox = styled(Box)`
  pointer-events: none;
  background-image: url(${getImageURL(
    "beet-box",
    "website-assets/site-revamp"
  )});
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  width: ${rem(176)};
  height: ${rem(275)};
  margin-left: ${rem(-88)};
  bottom: ${rem(40)};

  ${breakpoints.lg`
    width: ${rem(256)};
    height: ${rem(400)};
    margin-left: ${rem(-128)};
    bottom: ${rem(176)};
  `}
`;

const NavLinksContainer = styled(Box)`
  width: inherit;
  flex-wrap: inherit;
  justify-content: center;
  margin-bottom: ${rem(34)};

  ${breakpoints.lg`
    margin-bottom: ${rem(80)};
  `}
`;

const NavLinksLine = styled(List)`
  display: flex;
  margin-bottom: ${rem(36)};

  & a {
    color: ${({ theme }) => theme.colors.marshmallow};
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
`;

// links that contain svg instead of text should have underline behavior
const NavLinksItem = styled(ListItem)`
  justify-content: center;
  width: auto;
  padding: 0 ${rem(40)};

  & svg:hover {
    border-bottom: 1px solid;
  }
`;

const FooterLink = styled(Button)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  & :active {
    color: ${({ theme }) => theme.colors.marshmallow};
  }
`;

const StyledLogo = styled(Logo)`
  margin-bottom: ${rem(14)};
`;

const AppBadgeContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(25)};
  margin-left: 0;

  ${breakpoints.md`
    margin-top: 0;
    margin-left: ${rem(46)};
  `}
`;

const SocialContainer = styled(Grid)`
  padding-bottom: ${rem(22)};
  flex-direction: column;
  justify-content: center;

  ${breakpoints.md`
    flex-direction: row;
    justify-content: center;
  `}

  ${breakpoints.lg`
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

const SocialLinks = styled(Box)`
  & a {
    padding: ${rem(4)};
    display: inline-block;

    &:first-child {
      padding-left: ${rem(0)};
    }

    &:last-child {
      padding-right: ${rem(0)};
    }
  }
`;

const BCertContainer = styled(Box)`
  margin-right: ${rem(46)};
  width: ${rem(46)};
  height: ${rem(70)};
`;

const CopyContainer = styled(Grid)`
  color: ${({ theme }) => theme.colors.baba};
  display: inline-block;
  text-align: center;

  ${breakpoints.md`
    text-align: inherit;
  `}

  & a {
    color: ${({ theme }) => theme.colors.baba};
    text-decoration: underline;
  }
`;

export default Footer;
