// NC-1499 Remove legacy friendbuy
/* eslint-disable camelcase */

import _noop from "lodash/noop";

import { config } from "app/config";
import {
  FriendbuyLegacyAPI,
  VisitorStatus,
} from "app/types/api/friendbuyLegacy";
import { User } from "app/types/state/account/Account";

const friendbuyNoop: FriendbuyLegacyAPI = {
  _loaded: false,
  push: _noop,
};

// The friendbuy script is loaded asynchronously
// Backfill a default friendbuy implementation in the intermediate
const friendbuy = () => (window && window.friendbuy) || friendbuyNoop;

// Is friendbuy loaded?
// eslint-disable-next-line no-underscore-dangle
export const isFriendbuyLegacyLoaded = () => friendbuy()._loaded;

/* wrapper around Friendbuy client-side JS functions */
export const invokeVisitorStatusApi = (): Promise<VisitorStatus> => {
  return new Promise((resolve) => {
    friendbuy().push([
      "invoke",
      "visitor_status",
      (response) => {
        // FB strongly recommends verifying response.signature (requires trip to users service)
        // consider adding that here
        resolve(response);
      },
    ]);
  });
};

/*
 * Pushes user data into FriendBuy and creates a FriendBuy customer record
 * identifiable with our own id (they also assign their own internal id).
 */
export const trackCustomer = (user: User) => {
  friendbuy().push([
    "track",
    "customer",
    {
      id: user.userId, // INSERT CUSTOMER ID PARAMETER
      email: user.email, // INSERT CUSTOMER EMAIL PARAMETER
      first_name: user.firstName, // INSERT CUSTOMER FIRST NAME PARAMETER
      last_name: user.lastName, // INSERT CUSTOMER LAST NAME PARAMETER
    },
  ]);
};

// loads all friendbuy widgets - unfortunately not easy was to make dynamic
// this needs to happen AFTER the trackCustomer call above
export const loadWidgets = () => {
  friendbuy().push([
    "widget",
    config.get("third_party.friendbuy.account_page_widget"),
  ]);
  friendbuy().push([
    "widget",
    config.get("third_party.friendbuy.referrals_page_widget"),
  ]);
};
