// @ts-nocheck
import styled from "styled-components";

import breakpoints from "app/styles/breakpoints";
import grid from "app/styles/grid";
import { toRem } from "app/styles/utils";

export default styled.section.attrs({
  role: "main",
})`
  ${grid.row};
  ${grid.cell.fit};
  justify-content: center;
  margin-top: ${({ theme }) => toRem(theme.layout.headerHeightMobile)};
  z-index: 1;

  ${breakpoints.xl`
    margin-top: ${({ theme }) => toRem(theme.layout.headerHeight)};
  `};
`;
