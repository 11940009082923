import { put } from "redux-saga/effects";
import { call } from "typed-redux-saga";

import ACTION_TYPES from "app/actionTypes/account";
import { subscriptionPosit } from "app/api/userService";
import SubscriptionPositRequestAction from "app/types/state/account/SubscriptionPositRequestAction";
import SubscriptionPositResponseAction from "app/types/state/account/SubscriptionPositResponseAction";

const DEFAULT_PARAMS = {
  includeWindows: true,
};

export function fetchSubscriptionPositSucceededAction(
  action: SubscriptionPositResponseAction
) {
  return {
    ...action,
    type: ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT_SUCCEEDED,
  };
}

export function fetchSubscriptionPositFailedAction(
  action: SubscriptionPositRequestAction
) {
  return {
    ...action,
    type: ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT_FAILED,
  };
}

function* fetchSubscriptionPositFlow(action: SubscriptionPositRequestAction) {
  try {
    const posit = yield* call(subscriptionPosit, {
      ...DEFAULT_PARAMS,
      ...action,
    });

    yield put(
      // @ts-ignore
      fetchSubscriptionPositSucceededAction({
        ...action,
        ...posit,
      })
    );
    return posit;
  } catch (e) {
    yield put(fetchSubscriptionPositFailedAction(action));
  }
  return null;
}

export default fetchSubscriptionPositFlow;
