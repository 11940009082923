import React from "react";

import { useSelector } from "react-redux";
import moment from "moment-timezone";

import { formatDate, SHORT_DATE_FORMAT } from "app/ui/global/utils";

import MinimumOrderProgress, {
  MinimumOrderProgressProps,
} from "app/ui/shopping/Cart/MinimumOrderProgress";
import {
  getSubtotalMinInActiveOrder,
  getSubtotalInActiveOrder,
  getNextDeliveryPeriod,
  getFreeDeliveryThreshold,
} from "app/selectors";
import { ProgressBarColor } from "app/ui/designSystem/molecules/ProgressBar";

const MinimumOrderBanner: React.FC<MinimumOrderBannerProps> = ({
  className,
  ...props
}) => {
  const minimumValue = useSelector(getSubtotalMinInActiveOrder);
  const freeDeliveryThreshold = useSelector(getFreeDeliveryThreshold);
  const currentValue = useSelector(getSubtotalInActiveOrder);
  const deliveryPeriod = useSelector(getNextDeliveryPeriod);
  const deliveryDate = deliveryPeriod?.startAsMoment.toDate() || new Date();
  const formattedDeliveryDate = formatDate(
    moment(deliveryDate),
    SHORT_DATE_FORMAT
  );

  let minimumOrderProgressProps: MinimumOrderProgressProps = {
    firstThresholdValue: minimumValue ?? 0,
    currentValue,
    deliveryDate: formattedDeliveryDate,
    grayDiagonalLinesActive: true,
    progressColor: ProgressBarColor.LETTUCE,
  };

  if (freeDeliveryThreshold && minimumValue && currentValue >= minimumValue) {
    minimumOrderProgressProps = {
      ...minimumOrderProgressProps,
      secondThresholdValue: freeDeliveryThreshold ?? 0,
      grayDiagonalLinesActive: false,
      progressColor: ProgressBarColor.AVOCADO,
      verticalMarkerActive: true,
      verticalMarkerLabelActive: true,
      maxBarLabelActive: true,
    };
  }

  if (!minimumValue && freeDeliveryThreshold) {
    minimumOrderProgressProps = {
      firstThresholdValue: 0,
      secondThresholdValue: freeDeliveryThreshold,
      currentValue,
      deliveryDate: formattedDeliveryDate,
      progressColor: ProgressBarColor.AVOCADO,
      maxBarLabelActive: true,
    };
  }

  if (!minimumValue && !freeDeliveryThreshold) return null;

  return (
    <>
      <div {...props}>
        <MinimumOrderProgress
          {...minimumOrderProgressProps}
          className={className}
        />
      </div>
    </>
  );
};

interface MinimumOrderBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export default MinimumOrderBanner;
