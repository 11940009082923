export enum RouteKey {
  CONTACT = "contact form",
  ORDERMIN = "order minimum",
  DELIVERY = "delivery model",
  FAQ = "faq main page",
  PRICING = "pricing",
  BILLING = "billing faq",
}

interface RouteType {
  url: string;
}

const ROUTES: Record<RouteKey, RouteType> = {
  [RouteKey.CONTACT]: {
    url: "/contact/submit-a-request-BkBrSGWOs",
  },
  [RouteKey.ORDERMIN]: {
    url: "/en_us/is-there-an-order-minimum-Bk1zakW_s",
  },
  [RouteKey.DELIVERY]: {
    url: "/en_us/when-do-you-deliver-HJCmak_s",
  },
  [RouteKey.FAQ]: {
    url: "/en_us/categories/faq-S1_ayZus",
  },
  [RouteKey.PRICING]: {
    url: "/en_us/how-does-your-pricing-work-HkkfT1_j",
  },
  [RouteKey.BILLING]: {
    url: "/en_us/categories/billing-ryO76kOj",
  },
};

const KB_BASE_PATH = "https://help.imperfectfoods.com";

export const getPath = (routeKey: RouteKey) => {
  return `${KB_BASE_PATH}${ROUTES[routeKey].url}`;
};
