import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "account";
const prefixes = {
  FETCH_USER: `${domain}/FETCH_USER`,
  FETCH_USER_SUMMARY: `${domain}/FETCH_USER_SUMMARY`,
  UPDATE_SIGNUP_ZIP: `${domain}/UPDATE_SIGNUP_ZIP`,
  UPDATE_SIGNUP_PREFERENCES: `${domain}/UPDATE_SIGNUP_PREFERENCES`,
  CREATE_USER: `${domain}/CREATE_USER`,
  CREATE_CREDENTIALS: `${domain}/CREATE_CREDENTIALS`,
  UPDATE_USER: `${domain}/UPDATE_USER`,
  CREATE_ADDRESS: `${domain}/CREATE_ADDRESS`,
  UPDATE_ADDRESS_DELIVERY_NOTES: `${domain}/UPDATE_ADDRESS_DELIVERY_NOTES`,
  CREATE_SUBSCRIPTION: `${domain}/CREATE_SUBSCRIPTION`,
  FETCH_PAYMENT_SOURCES: `${domain}/FETCH_PAYMENT_SOURCES`,
  ADD_PAYMENT_SOURCE: `${domain}/ADD_PAYMENT_SOURCE`,
  UPDATE_SUBSCRIPTION_BOX: `${domain}/UPDATE_SUBSCRIPTION_BOX`,
  UPDATE_DELIVERY_INFO: `${domain}/UPDATE_DELIVERY_INFO`,
  UPDATE_DELIVERY_INFO_VALIDATION_STEP: `${domain}/UPDATE_DELIVERY_INFO_VALIDATION_STEP`,
  UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP: `${domain}/UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP`,
  FETCH_FUTURE_ORDERS: `${domain}/FETCH_FUTURE_ORDERS`,
  SKIP_ORDER: `${domain}/SKIP_ORDER`,
  SKIP_FUTURE_ORDER: `${domain}/SKIP_FUTURE_ORDER`,
  UNSKIP_ORDER: `${domain}/UNSKIP_ORDER`,
  DONATE_FUTURE_ORDER: `${domain}/DONATE_FUTURE_ORDER`,
  UNDONATE_FUTURE_ORDER: `${domain}/UNDONATE_FUTURE_ORDER`,
  FETCH_NEXT_DELIVERY: `${domain}/FETCH_NEXT_DELIVERY`,
  FETCH_SIGNUP_REASONS: `${domain}/FETCH_SIGNUP_REASONS`,
  FETCH_CANCELLATION_REASONS: `${domain}/FETCH_CANCELLATION_REASONS`,
  APPLY_COUPON: `${domain}/APPLY_COUPON`,
  REMOVE_COUPON: `${domain}/REMOVE_COUPON`,
  CANCEL_SUBSCRIPTION: `${domain}/CANCEL_SUBSCRIPTION`,
  FETCH_CREDIT_BALANCE: `${domain}/FETCH_CREDIT_BALANCE`,
  FETCH_CREDIT_TRANSACTIONS: `${domain}/FETCH_CREDIT_TRANSACTIONS`,
  SET_MESSAGE_AS_READ: `${domain}/SET_MESSAGE_AS_READ`,
  ADD_PRODUCT_TO_NEVER_LIST: `${domain}/ADD_PRODUCT_TO_NEVER_LIST`,
  REMOVE_PRODUCT_FROM_NEVER_LIST: `${domain}/REMOVE_PRODUCT_FROM_NEVER_LIST`,
  ADD_PRODUCT_TO_RECURRING_ITEMS: `${domain}/ADD_PRODUCT_TO_RECURRING_ITEMS`,
  REMOVE_PRODUCT_FROM_RECURRING_ITEMS: `${domain}/REMOVE_PRODUCT_FROM_RECURRING_ITEMS`,
  SET_ADD_ON_BOX_IDS: `${domain}/SET_ADD_ON_BOX_IDS`,
  ENROLL_PLUS_MEMBERSHIP: `${domain}/ENROLL_PLUS_MEMBERSHIP`,
  SET_PLUS_MEMBERSHIP_AUTORENEWAL: `${domain}/SET_PLUS_MEMBERSHIP_AUTORENEWAL`,
  SET_ALLOW_RECURRING_ITEMS_NUDGE: `${domain}/SET_ALLOW_RECURRING_ITEMS_NUDGE`,
  SET_WINBACK_ACCEPTED: `${domain}/SET_WINBACK_ACCEPTED`,
  SET_SHOULD_AUTO_SKIP_ORDER: `${domain}/SET_SHOULD_AUTO_SKIP_ORDER`,
  SET_SHOULD_AUTO_SWITCH_CADENCE_TO_BIWEEKLY: `${domain}/SET_SHOULD_AUTO_SWITCH_CADENCE_TO_BIWEEKLY`,
  SET_LAST_AUTOSKIPPED_ORDER: `${domain}/SET_LAST_AUTOSKIPPED_ORDER`,
  AUTO_SWITCH_CADENCE: `${domain}/AUTO_SWITCH_CADENCE`,
  VALIDATE_REWARD_COUPON: `${domain}/VALIDATE_REWARD_COUPON`,
  SET_REWARD_COUPON_UNUSED: `${domain}/SET_REWARD_COUPON_AVAILABLE`,
  FETCH_SUBSCRIPTION_POSIT: `${domain}/FETCH_SUBSCRIPTION_POSIT`,
  ADD_VACATION_HOLD: `${domain}/ADD_VACATION_HOLD`,
  REMOVE_VACATION_HOLD: `${domain}/REMOVE_VACATION_HOLD`,
  // TODO: NC-1010 Remove if subscription pause experiment fails
  FETCH_SUB_PAUSE_NEXT_DELIVERY: `${domain}/FETCH_SUB_PAUSE_NEXT_DELIVERY`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
  CANCELLATION_REASONS_CHOSEN: `${domain}/CANCELLATION_REASONS_CHOSEN`,
  CANCELLATION_WINBACK_VIEWED: `${domain}/CANCELLATION_WINBACK_VIEWED`,
  CADENCE_OFFSET_UPDATED: `${domain}/CADENCE_OFFSET_UPDATED`,
  UPDATE_DELIVERY_INFO_VALIDATION_INVALID_ADDRESS: `${domain}/UPDATE_DELIVERY_INFO_VALIDATION_INVALID_ADDRESS`,
};
