import { useSelector } from "react-redux";

import { getShelvesAvailability } from "app/selectors";
import State from "app/types/state";
import Aisle from "app/types/state/aisles/Aisle";
import Shelf from "app/types/state/shelves/Shelf";

interface AvailabilityByShelfProps {
  [key: string]: boolean;
}

export default function useActiveMobileShelf({
  currentAnchorId,
  currentAisle,
}: {
  currentAnchorId?: string;
  currentAisle?: Aisle;
}): Shelf | undefined {
  const availabilityByShelf: AvailabilityByShelfProps = useSelector(
    (state: State) => {
      return getShelvesAvailability(state, {
        shelfIds: currentAisle?.shelves.map((shelf) => shelf.shelfId),
      });
    }
  );

  // We want to check for shelf availability before rendering its name in the header
  const getFirstAvailabileShelf = () => {
    return currentAisle?.shelves?.find((shelf) => {
      if (availabilityByShelf[shelf.shelfId]) return true;
      return false;
    });
  };

  const getActiveShelf = () => {
    if (currentAnchorId === currentAisle?.aisleId) {
      return getFirstAvailabileShelf();
    }

    return currentAisle?.shelves?.find(
      (shelf) => shelf.shelfId === currentAnchorId
    );
  };

  return getActiveShelf();
}
