// @ts-nocheck
// an Http request client that handles auth token retries
// an Http request client that handles auth token retries
// fetch is already included in react-native environment
// https://facebook.github.io/react-native/docs/network.html
// BUT, we need a polyfill for web
import "whatwg-fetch";
import { select, call, put } from "redux-saga/effects";

import AUTH_ACTION_TYPES from "app/actionTypes/auth";
import { sendLog } from "app/monitoring";
import { LogLevel } from "app/types/monitoring";
import { requestFailed } from "app/reducers/ui";
import { getIdToken } from "app/selectors";

export const CRITICAL_DATA_REQUEST = "critical-request";

// An Authorization Http Header is appended if the request is authenticated
// unauthorized responses (401's) will refresh the idToken and retry the request
export default function* (
  url: string,
  options = {},
  callback = null,
  authenticated = true
) {
  const critical = options.tags?.includes(CRITICAL_DATA_REQUEST) ?? false;
  const response = yield call(
    completeRequest,
    url,
    options,
    authenticated,
    critical
  );

  if (response.status !== 401 || !authenticated) {
    return yield call(handleResponse, response, callback, critical);
  }

  return yield put({ type: AUTH_ACTION_TYPES.LOGOUT });
}

function* completeRequest(url, options = {}, authenticated, critical) {
  let jwt;
  const headers = checkAddContentTypeHeader(options.headers);

  if (authenticated) jwt = yield select(getIdToken);

  const requestOptions = {
    // https://developer.mozilla.org/en-US/docs/Web/API/Request/mode
    mode: "cors",
    // https://developer.mozilla.org/en-US/docs/Web/API/Request/credentials
    credentials: "omit",
    // Allow overwriting of mode/credentials by callee
    ...options,
    headers: authenticated ? concatAuthHeader(headers, jwt) : headers,
  };

  return yield call(fetchWrapper, url, requestOptions, critical);
}

function* fetchWrapper(url, requestOptions = {}, critical) {
  try {
    return yield call(fetch, url, requestOptions);
  } catch (error) {
    // Intentionally don't log sensitive data (passwords and auth tokens)
    // eslint-disable-next-line no-unused-vars
    const { headers, body, tags = [], ...options } = requestOptions;
    const params = {
      url,
      code: error.code,
      message: error.message,
      stack: error.stack,
      critical,
      error,
    };
    // This was originally added for loggly integration but it seems redundant now that
    // we have RUM network visibility.
    yield call(sendLog, {
      ...params,
      options,
      tags: [...tags, "Fetch-Exception"],
    });
    yield put(requestFailed({ params }));
    throw error;
  }
}

function* handleResponse(response, cb, critical) {
  if (response && !response.ok) {
    if (console) console.log(response); // eslint-disable-line no-console
    const { status, statusText } = response;

    // Log level based on status code, like service layer
    if (response.status >= 400) {
      let level = LogLevel.WARN;
      if (response.status === 404) level = LogLevel.INFO;
      else if (response.status >= 500) level = LogLevel.ERROR;

      const body = yield call(transformLoggerContent, response);
      // This was originally added for loggly integration but it seems redundant now that
      // we have RUM network visibility.
      yield call(sendLog, {
        url: response.url,
        level,
        status,
        statusText,
        body,
      });

      if (level === LogLevel.ERROR && critical)
        return yield put(requestFailed({ params: { critical } }));
    }

    if (cb) {
      throw response;
    }

    return response;
  }

  return yield call(transformResponse, response, cb);
}

function transformLoggerContent(response) {
  try {
    return response.clone().text();
  } catch (e) {
    return null;
  }
}

function transformResponse(response, cb) {
  //  Callback function provided, call it with response value
  if (cb && typeof cb === "function") return cb(response);
  // Callback function name provided from: https://developer.mozilla.org/en-US/docs/Web/API/Body
  if (cb && typeof response[cb] === "function") return response[cb]();

  // otherwise, return the response
  return response;
}

const checkAddContentTypeHeader = (headers) => {
  // eslint-disable-next-line no-prototype-builtins
  if (headers && headers.hasOwnProperty("Content-Type")) return headers;
  return {
    ...headers,
    "Content-Type": "application/json",
  };
};

const concatAuthHeader = (headers, jwt) => {
  return {
    ...headers,
    Authorization: `Bearer ${jwt}`,
  };
};
