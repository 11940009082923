// @ts-nocheck
import { take, fork, put, select } from "redux-saga/effects";

import ACTION_TYPES from "../actionTypes/routing";
import { coreRouteSelector } from "../selectors";

export default function* rootRouting() {
  let task;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take("*");
    if (action.type !== ACTION_TYPES.CORE_ROUTE_CHANGED) {
      if (!task || !task.isRunning()) {
        task = yield fork(handleCoreRouteChange, action);
      }
    }
  }
}

function* handleCoreRouteChange() {
  const state = yield select(coreRouteSelector);
  if (state.coreRoute !== state.prevCoreRoute) {
    yield put({ type: ACTION_TYPES.CORE_ROUTE_CHANGED, state });
  }
}
