import React, { useState } from "react";

import styled, { css, keyframes } from "styled-components";
import { rem } from "polished";

import { formatCurrency, getImageURL } from "app/ui/global/utils";
import ScrollingNumber from "app/ui/designSystem/molecules/animations/ScrollingNumber";
import ProgressBar, {
  ProgressBarColor,
} from "app/ui/designSystem/molecules/ProgressBar";
import breakpoints from "app/styles/breakpoints";
import { maxWidth } from "app/styles/widths";

export interface MinimumOrderProgressProps {
  currentValue: number;
  firstThresholdValue: number;
  secondThresholdValue?: number;
  deliveryDate: string;
  verticalMarkerActive?: boolean;
  verticalMarkerLabelActive?: boolean;
  maxBarLabelActive?: boolean;
  grayDiagonalLinesActive?: boolean;
  progressColor?: ProgressBarColor;
  rippleColor?: ProgressBarColor;
  className?: string;
}

const MinimumOrderProgress: React.FC<MinimumOrderProgressProps> = ({
  currentValue,
  firstThresholdValue,
  secondThresholdValue = 0,
  deliveryDate,
  verticalMarkerActive = false,
  verticalMarkerLabelActive = false,
  maxBarLabelActive = false,
  grayDiagonalLinesActive = false,
  progressColor = ProgressBarColor.LETTUCE,
  rippleColor = ProgressBarColor.LETTUCE,
}) => {
  const [previousCartValue, setPreviousCartValue] = useState(-1);
  const previousValue = previousCartValue ?? currentValue;
  if (previousCartValue !== currentValue) {
    setPreviousCartValue(currentValue);
  }

  const belowFirstThresholdValue = currentValue < firstThresholdValue;
  const belowSecondThresholdValue = currentValue < secondThresholdValue;
  const verticalMarkerPosition =
    (firstThresholdValue / secondThresholdValue) * 100;

  const shouldCurrentlyDisplayGus =
    secondThresholdValue === 0
      ? !belowFirstThresholdValue
      : !belowSecondThresholdValue;

  const previouslyBelowFirstThresholdValue =
    previousValue < firstThresholdValue;
  const previouslyBelowSecondThresholdValue =
    previousValue < secondThresholdValue;

  const previouslyShouldDisplayGus =
    secondThresholdValue === 0
      ? !previouslyBelowFirstThresholdValue
      : !previouslyBelowSecondThresholdValue;

  const shouldPreviouslyShowGusButNotAnymore =
    previouslyShouldDisplayGus && !shouldCurrentlyDisplayGus;
  const shouldPreviouslyNotShowGusButNowShow =
    !previouslyShouldDisplayGus && shouldCurrentlyDisplayGus;
  const [shouldDisplayGus, setShouldDisplayGus] = useState(
    shouldPreviouslyShowGusButNotAnymore
      ? previouslyShouldDisplayGus
      : shouldCurrentlyDisplayGus
  );

  if (shouldPreviouslyShowGusButNotAnymore) {
    setTimeout(() => {
      setShouldDisplayGus(shouldCurrentlyDisplayGus);
    }, 1000);
  } else if (shouldPreviouslyNotShowGusButNowShow) {
    setShouldDisplayGus(shouldCurrentlyDisplayGus);
  }

  const belowFirstThresholdCurrencyArray = formatCurrency(
    firstThresholdValue - currentValue
  ).split("");
  if (firstThresholdValue - currentValue < 10) {
    belowFirstThresholdCurrencyArray.splice(1, 0, "0");
  }

  const belowSecondThresholdCurrencyArray = formatCurrency(
    secondThresholdValue - currentValue
  ).split("");
  if (secondThresholdValue - currentValue < 10) {
    belowSecondThresholdCurrencyArray.splice(1, 0, "0");
  }

  const scrollingNeededAmount = (valueArray: string[]) => {
    return (
      <StyledSpan>
        {valueArray[0]}
        <ScrollingNumber
          value={parseInt(valueArray[1], 10)}
          shouldDisappearWhenZero
        />
        <ScrollingNumber value={parseInt(valueArray[2], 10)} />
        {valueArray[3]}
        <ScrollingNumber value={parseInt(valueArray[4], 10)} />
        <ScrollingNumber value={parseInt(valueArray[5], 10)} />
      </StyledSpan>
    );
  };

  const renderMessage = () => {
    if (
      belowFirstThresholdValue ||
      (secondThresholdValue && belowSecondThresholdValue)
    ) {
      const amountToDisplay = belowFirstThresholdValue
        ? belowFirstThresholdCurrencyArray
        : belowSecondThresholdCurrencyArray;
      const beforeText = belowFirstThresholdValue ? `Add ` : `You're `;
      const afterText = belowFirstThresholdValue
        ? ` to meet the ${formatCurrency(firstThresholdValue, 0)} minimum`
        : ` away from free delivery`;

      return (
        <StyledTypography>
          {beforeText}
          {scrollingNeededAmount(amountToDisplay)}
          {afterText}
        </StyledTypography>
      );
    }

    if (secondThresholdValue && !belowSecondThresholdValue) {
      return (
        <DelayedStyledTypography>
          <StyledSpan>Your groceries will be delivered for free!</StyledSpan>
        </DelayedStyledTypography>
      );
    }

    return (
      <DelayedStyledTypography>
        <StyledSpan>Your order will arrive {deliveryDate}</StyledSpan>
      </DelayedStyledTypography>
    );
  };

  return (
    <StyledWrapper data-testid="minimum-order-banner">
      <StyledInnerWrapper>
        {renderMessage()}
        <StyledProgressBar
          currentValue={currentValue}
          firstThresholdValue={firstThresholdValue}
          secondThresholdValue={secondThresholdValue}
          verticalMarkerActive={verticalMarkerActive}
          verticalMarkerLabelActive={verticalMarkerLabelActive}
          maxBarLabelActive={maxBarLabelActive}
          grayDiagonalLinesActive={grayDiagonalLinesActive}
          progressColor={progressColor}
          verticalMarkerPosition={verticalMarkerPosition}
        />
      </StyledInnerWrapper>
      {shouldDisplayGus && (
        <StyledImg
          src={getImageURL("asparagus.png", "v1578606354/website-assets")}
          alt="Asparagus imperfect character"
          $shouldDisplayGus={shouldCurrentlyDisplayGus}
        />
      )}
      {shouldDisplayGus && <RippleEffect $rippleColor={rippleColor} />}
    </StyledWrapper>
  );
};

const StyledSpan = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.baba};
  padding: ${rem(12)};
  position: relative;
  overflow: hidden;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const gusAnimationIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const gusAnimationOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const gusAnimation = (props: { $shouldDisplayGus: boolean }) => css`
  animation: ${props.$shouldDisplayGus ? gusAnimationIn : gusAnimationOut} 250ms
    ease ${props.$shouldDisplayGus ? "600ms" : "0s"};
`;

const StyledImg = styled.img<{
  $shouldDisplayGus: boolean;
}>`
  position: absolute;
  right: ${rem(8)};
  bottom: 0;
  height: ${rem(64)};
  transform: translateY(100%);
  ${gusAnimation};
  animation-fill-mode: both;
`;

const StyledTypography = styled.div`
  text-align: center;
  font-size: ${rem(13)};
  max-width: ${rem(275)};

  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: ${rem(32)};

  ${maxWidth(330)`
    max-width: ${rem(315)};
  `}
  ${breakpoints.sm`
    max-width: ${rem(315)};
    font-size: ${rem(14)};
  `}
`;

const delayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const DelayedStyledTypography = styled(StyledTypography)`
  opacity: 0;
  animation: ${delayAnimation} 100ms linear 600ms;
  animation-fill-mode: forwards;
`;

const StyledProgressBar = styled(ProgressBar)`
  max-width: ${rem(275)};
  height: ${rem(10)};
  margin-bottom: ${rem(10)};

  ${maxWidth(330)`
    max-width: ${rem(248)};
  `}
  ${breakpoints.sm`
    max-width: ${rem(248)};
  `}
  ${breakpoints.md`
    width: calc(100% - ${rem(85)});
    max-width: unset;
  `}
`;

const rippleAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(4);
    opacity: 0;
  }
`;

const RippleEffect = styled.span<{ $rippleColor: ProgressBarColor }>`
  position: absolute;
  top: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: ${rippleAnimation} 600ms linear;
  animation-fill-mode: forwards;

  ${({ theme, $rippleColor }) =>
    `background-color: ${theme.colors[$rippleColor]};`}
`;

export default MinimumOrderProgress;
