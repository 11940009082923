// @ts-nocheck
// https://github.com/webpack/webpack/blob/d8993128c91c655a50324a069689cca01de4011c/test/configCases/web/retry-failed-import/index.js#L15
export const CHUNK_LOAD_ERROR_NAME = "ChunkLoadError";

/*
 * Test for specific message 'Loading chunk ... failed'
 * Original Inspriation: https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
 */
const chunkLoadErrorTest = (error) => {
  if (!error || !error.name) return false;
  return error.name === CHUNK_LOAD_ERROR_NAME;
};

export default chunkLoadErrorTest;
