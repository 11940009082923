import { Analytics, SegmentEvent } from "@segment/analytics-next";
import _noop from "lodash/noop";
import { sendLog } from "app/monitoring";
import { LogLevel } from "app/types/monitoring";

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#anonymous-ids
let lastAnonymousId: string;

export const analyticsNoop = {
  ready: _noop,
  setAnonymousId: _noop,
  identify: _noop,
  page: _noop,
  track: _noop,
  reset: _noop,
} as Analytics;

// @ts-ignore
export const uuid = () => window.uuid?.v4();

const analytics = () => window.analytics || analyticsNoop;

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#ready
export const ready = (callback?: Function) => {
  // @ts-ignore
  return analytics().ready(callback);
};

const hasUser = () => {
  return typeof analytics().user === "function";
};

// used to set globally available anonymous id (for Optimizely)
const setVisitorId = (visitorId: string) => {
  window.visitorId = visitorId;
  try {
    localStorage.setItem("visitor_id", JSON.stringify(visitorId));
  } catch (e) {
    sendLog({
      level: LogLevel.WARN,
      tags: ["localStorage"],
      message: `VISITOR ID NOT RESET - ${e}`,
    });
  }
};

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#override-the-default-anonymous-id-with-a-call
export const setAnonymousId = (anonymousId: string) => {
  lastAnonymousId = anonymousId;
  return analytics().setAnonymousId(anonymousId);
};

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#retrieve-the-anonymous-id
export const getAnonymousId = () => {
  return (hasUser() && analytics().user()?.anonymousId()) || lastAnonymousId;
};

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
export const identify = (
  userId?: string,
  traits?: object,
  options?: object
) => {
  return analytics().identify(userId, traits, options);
};

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
export const page = (name?: string, properties?: object, options?: object) => {
  return analytics().page(name, properties, options);
};

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
export const track = (
  eventName: string | SegmentEvent,
  traits?: object,
  options?: object
) => {
  return analytics().track(eventName, traits, options);
};

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-logout
export const reset = () => {
  analytics().reset();
  const anonymousId = uuid();
  // Manually set a new anonymousId after reset
  setAnonymousId(anonymousId);
  // Manually set the globally available anonymous id
  setVisitorId(anonymousId);
  return anonymousId;
};
