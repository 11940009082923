import { take, fork, call, select, put } from "redux-saga/effects";

import UI_ACTION_TYPES from "app/actionTypes/ui";
import ACTION_TYPES from "app/actionTypes/reactivation";

import validateUserDeferredCoupon from "app/sagas/validateUserDeferredCoupon";
import { canUserReactivate, getUserId } from "app/selectors";
import { reactivate } from "app/api/userService";
import {
  completeReactivationSucceededAction,
  completeReactivationFailedAction,
} from "app/reducers/reactivation";

export default function* rootReactivation() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      UI_ACTION_TYPES.INITIALIZE_SUCCEEDED,
      ACTION_TYPES.COMPLETE_REACTIVATION,
    ]);

    switch (action.type) {
      case UI_ACTION_TYPES.INITIALIZE_SUCCEEDED: {
        yield fork(initializeReactivation);
        break;
      }
      case ACTION_TYPES.COMPLETE_REACTIVATION: {
        yield fork(completeReactivationFlow);
        break;
      }
      default:
        break;
    }
  }
}

function* initializeReactivation() {
  const canReactivate = yield select(canUserReactivate);
  if (canReactivate) {
    yield call(validateUserDeferredCoupon);
  }
}

function* completeReactivationFlow() {
  try {
    const userId = yield select(getUserId);
    yield call(reactivate, userId);
    yield put(completeReactivationSucceededAction());
  } catch (error) {
    yield put(completeReactivationFailedAction({ error }));
  }
}
