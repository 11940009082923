import { trackEvent } from "app/api/segment";
import { call } from "redux-saga/effects";
import {
  CaptureReferralAction,
  SetLegacyReferralAction,
  ApplyAttributionFailedAction,
  ApplyAttributionSuccessAction,
} from "app/types/state/friendbuy";

export function* trackAppliedReferral(action) {
  yield call(trackEvent, "Applied Referral (Legacy)", action);
}

export function* trackAppliedReferralFailed(action) {
  yield call(trackEvent, "Applied Referral Failed (Legacy)", action);
}

export function* trackSetReferral(action: SetLegacyReferralAction) {
  yield call(trackEvent, "Set Referral (Legacy)", action);
}

export function* trackCapturedReferral(action: CaptureReferralAction) {
  yield call(trackEvent, "Captured Referral", action);
}

export function* trackFetchReferralCouponSucceeded(action) {
  yield call(trackEvent, "Fetch Referral Coupon Succeeded", action);
}

export function* trackApplyAttributionSucceeded(
  action: ApplyAttributionSuccessAction
) {
  yield call(trackEvent, "Applied Referral Attribution", action);
}

export function* trackApplyAttributionFailed(
  action: ApplyAttributionFailedAction
) {
  yield call(trackEvent, "Applied Referral Attribution Failed", action);
}
