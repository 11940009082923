import List from "@material-ui/core/List";
import { rem } from "polished";
import React, { useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { aisleClicked, aisleShelfClicked } from "app/reducers/ui";
import {
  getAisleListData,
  getAisleShelfIdsFromHash,
  getSelectedTags,
  getSubaisleListData,
} from "app/selectors";
import State from "app/types/state";
import { AisleClickedSource } from "app/types/ui/AisleClicked";
import { AisleShelfClickedSource } from "app/types/ui/AisleShelfClicked";

import AisleListItem from "./AisleListItem";
import RemoveFilterWidget from "./RemoveFilterWidget";
import SubaisleListItem from "./SubAisle/SubaisleListItem";

const SideNav = () => {
  const dispatch = useDispatch();

  const listRef = useRef<HTMLElement>(null);
  const activeSubaisleRef = useRef<HTMLAnchorElement>(null);
  const activeAisleRef = useRef<HTMLAnchorElement>(null);

  const { currentAisle, currentAnchorId } = useSelector(
    getAisleShelfIdsFromHash
  );
  const tags = useSelector(getSelectedTags);
  const aisles = useSelector(getAisleListData);
  const subaisles = useSelector((state: State) =>
    getSubaisleListData(state, { aisle: currentAisle })
  );

  /**
   * Scroll the active list item as close to 1/3 of the way
   * down the list as possible
   */
  useEffect(() => {
    const listEl = listRef.current;
    const listItemEl = activeSubaisleRef.current || activeAisleRef.current;

    if (!listEl || !listItemEl) return;
    if (listEl.clientHeight >= listEl.scrollHeight) return;

    const listItemTopOffset = listItemEl.getBoundingClientRect().top;
    const listTopOffset = listEl.getBoundingClientRect().top;
    const listScrollTopOffset = listEl.scrollTop;
    const additionalOffset = listEl.clientHeight * 0.33;

    const scrollToOffset =
      listItemTopOffset -
      listTopOffset +
      listScrollTopOffset -
      additionalOffset;

    listEl.scrollTo({
      top: scrollToOffset,
      behavior: "smooth",
    });
  }, [currentAnchorId]);

  const handleAisleClick = ({
    aisleId,
    aisleName,
  }: {
    aisleId: string;
    aisleName: string;
  }) => {
    dispatch(
      aisleClicked({
        location: "Desktop",
        aisleId,
        aisleName,
        source: AisleClickedSource.SubaisleSideNav,
      })
    );
  };

  const handleSubaisleClick = ({
    aisleId,
    aisleName,
    subaisleId,
    subaisleName,
  }: {
    aisleId: string;
    aisleName: string;
    subaisleId: string;
    subaisleName: string;
  }) => {
    dispatch(
      aisleShelfClicked({
        location: "Desktop",
        aisleId,
        aisleName,
        shelfId: subaisleId,
        shelfName: subaisleName,
        source: AisleShelfClickedSource.SubaisleSideNav,
      })
    );
  };

  return (
    <ListNav component="nav" ref={listRef}>
      {aisles?.map((aisle) => (
        <Fragment key={aisle.id}>
          <AisleListItem
            active={aisle.active}
            activeRef={aisle.active ? activeAisleRef : null}
            disabled={aisle.disabled}
            label={aisle.label}
            onClick={() => {
              handleAisleClick({ aisleId: aisle.id, aisleName: aisle.label });
            }}
            to={aisle.to}
            value={aisle.value}
          />
          {aisle.active &&
            subaisles?.map((subaisle) => (
              <SubaisleListItem
                active={subaisle.active}
                disabled={subaisle.disabled}
                key={subaisle.id}
                label={subaisle.label}
                onClick={() => {
                  handleSubaisleClick({
                    aisleId: aisle.id,
                    aisleName: aisle.label,
                    subaisleId: subaisle.id,
                    subaisleName: subaisle.label,
                  });
                }}
                activeRef={subaisle.active ? activeSubaisleRef : null}
                to={subaisle.to}
                value={subaisle.value}
              />
            ))}
        </Fragment>
      ))}
      {tags.length > 0 && <StyledRemoveFilterWidget tag={tags[0]} />}
    </ListNav>
  );
};

export default SideNav;

export const SIDE_NAV_WIDTH = 248;

const ListNav = styled(List)`
  width: ${SIDE_NAV_WIDTH}px;
  height: 100%;
  padding: ${rem(48)} ${rem(8)};
  overflow: auto;
  z-index: 1;
` as typeof List;

// `as typeof List` is required to utilize the `component` prop on `StyledList`
// https://github.com/mui-org/material-ui/issues/13921#issuecomment-484133463

const StyledRemoveFilterWidget = styled(RemoveFilterWidget)`
  margin-top: ${rem(16)};
`;
