// @ts-nocheck
import { splitReducer } from "@splitsoftware/splitio-redux";
import { connectRouter } from "connected-react-router";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import recycleState from "redux-recycle";

import * as account from "./account";
import * as aisles from "./aisles";
import analytics from "./analytics";
import * as auth from "./auth";
import * as boxes from "./boxes";
import * as catalog from "./catalog";
import cms from "./cms";
import * as deliveries from "./deliveries";
import extendedRouter from "./extendedRouter";
import * as extendedSplit from "./extendedSplit";
import featureIntro from "./featureIntro";
import * as offerings from "./offerings";
import * as orders from "./orders";
import * as search from "./search";
import * as shelves from "./shelves";
import * as signup from "./signup";
import toast from "./toast";
import ui from "./ui";
import mobile from "./mobile";
import * as reactivation from "./reactivation";
import * as friendbuy from "./friendbuy";
import * as misfits from "./misfits";

import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import AUTH_ACTION_TYPES from "app/actionTypes/auth";
import UI_ACTION_TYPES from "app/actionTypes/ui";
import history from "app/router/history";

/* The Auth LOGOUT and App State RESET actions are the `recycle` actions for the app */
const recycleActions = [
  AUTH_ACTION_TYPES.LOGOUT,
  ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED,
];

/*
  This function assumes the reducer object has the following exports:
    default: The reducer function
    initialState: The initial state of the redux store
  The actions (default: recycleActions) will prompt the reducer to reset to initialState.
 */
function recycle(reducer, actions = recycleActions) {
  return recycleState(reducer.default, actions, reducer.initialState);
}

// https://github.com/rt2zz/redux-persist/issues/963
// Persistence of root config was causing signup reducer to rehydrate completely
// causing the signup flow to think it was already initialized
const signupPersistConfig = {
  key: "signup",
  storage,
  // Unfortunately, the term "blacklist" is required by the redux-persist package
  // there are multiple PRs open for this to be changed... so we can update when those are merged.
  blacklist: signup.blacklist,
};

const featureIntroPersistConfig = {
  key: "featureIntro",
  storage,
};

const authRecycleActions = [AUTH_ACTION_TYPES.LOGOUT];
// Account reducer is handling CANCEL_SUBSCRIPTION_SUCCEEDED event
const accountRecycleActions = [
  AUTH_ACTION_TYPES.LOGOUT,
  AUTH_ACTION_TYPES.LOGIN_SUCCEEDED,
];
const userRecycleActions = [
  ...recycleActions,
  AUTH_ACTION_TYPES.LOGIN_SUCCEEDED,
];
const offeringRecycleActions = [
  ...recycleActions,
  UI_ACTION_TYPES.RESET_SHOPPING,
];

const shoppingRecycleActions = [
  ...recycleActions,
  UI_ACTION_TYPES.RESET_SHOPPING,
];

const defaultReducers = {
  extendedRouter,
  router: connectRouter(history),
  auth: recycle(auth, authRecycleActions),
  boxes: recycle(boxes),
  account: recycle(account, accountRecycleActions),
  orders: recycle(orders),
  offerings: recycle(offerings, offeringRecycleActions),
  search: recycle(search, offeringRecycleActions),
  deliveries: recycle(deliveries),
  aisles: recycle(aisles, shoppingRecycleActions),
  shelves: recycle(shelves, shoppingRecycleActions),
  catalog: recycle(catalog, shoppingRecycleActions),
  cms,
  signup: persistReducer(signupPersistConfig, recycle(signup)),
  ui,
  extendedSplit: recycle(extendedSplit, userRecycleActions),
  splitio: splitReducer,
  featureIntro: persistReducer(featureIntroPersistConfig, featureIntro),
  analytics,
  toast,
  mobile,
  reactivation: recycle(reactivation),
  friendbuy: recycle(friendbuy),
  misfits: recycle(misfits),
};

export default defaultReducers;
