import React from "react";

import { getImageURL } from "app/ui/global/utils";

export type Direction = "left" | "right";

type DirectionAndGaze =
  | "left_direct"
  | "left_down"
  | "left_side"
  | "left_up"
  | "right_direct"
  | "right_down"
  | "right_side"
  | "right_up";

export interface BlinkingEyesBaseProps {
  direction?: Direction;
  gaze?: "direct" | "down" | "side" | "up";
  className?: string;
}
export interface BlinkingEyesProps extends BlinkingEyesBaseProps {
  width: number;
}
export interface BlinkingEyesImageProps
  extends BlinkingEyesProps,
    React.HTMLAttributes<HTMLImageElement> {}

const BlinkingEyes: React.FC<BlinkingEyesImageProps> = ({
  direction = "right",
  gaze = "up",
  width,
  ...rest
}) => {
  const imageFileName = `${direction}_${gaze}` as DirectionAndGaze;
  const imageUrl = getImageURL(imageFileName, "website-assets/blinking-eyes", {
    w: width,
    dpr: 2.0,
  });
  return <img src={imageUrl} alt="" width={width} {...rest} />;
};

export default BlinkingEyes;
