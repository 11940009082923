import { createSelector } from "reselect";

import {
  ON_TREATMENT_TYPE,
  ANON_TRAFFIC_TYPE,
  USER_TRAFFIC_TYPE,
  USER_PLUS_ENROLL,
  HEADER_BANNER,
  FUTURE_MARKET_LAUNCH_MODAL,
  SplitTreatment,
  PACKAGING_RETURN_PROGRAM,
  getPackagingReturnTreatmentFromValue,
  MINIMUM_ORDER_PROGRESS,
  ORDER_MIN,
  ANON_ORDER_MIN,
  ANON_HEADER_BANNER,
  SIGNUP_SHOPPING,
  FLEX_DELIVERY_H1_2022,
  LOGGED_IN_HOMEPAGE,
  SHOPPABLE_RECIPE_AISLE,
  SUBSCRIPTION_PAUSE,
  REFERRAL_DASHBOARD,
  CATALOG_PREVIEW_TOOL,
  ADDRESS_VALIDATION,
  WARNING_APP_VERSION_MINIMUM,
  SCALE_PRICE,
  MOBILE_AISLE_NAV,
  INTENTIONAL_SOURCING,
  NEW_FEATURE_MODAL,
  SHOW_EXCEPTION_BANNER,
  RECENTLY_ADDED,
  FIRST_TIME_SHOPPING_CAROUSEL,
  HIDE_BIWEEKLY_SKIP_MODAL,
  BOYSENBERRY_SIGNUP,
} from "app/constants/treatments";
import * as fromAccount from "app/selectors/account";
import * as fromCDDeliveries from "app/selectors/crossDomain/deliveries";
import * as fromCDSignup from "app/selectors/crossDomain/signup";
import * as fromOrderActive from "app/selectors/orderActive";
import * as fromSignup from "app/selectors/signup";
import * as fromSplit from "app/selectors/split";
import * as fromUI from "app/selectors/ui";
import ExperimentConfigEntry from "app/types/split/ExperimentConfigEntry";
import ExperimentSetupEntry from "app/types/split/ExperimentSetupEntry";
import State from "app/types/state";
import OrderMinimumData from "app/types/split/OrderMinimumData";

type ExperimentSetup = {
  [key in SplitTreatment]: ExperimentSetupEntry;
};

type ExperimentConfig = {
  [key in SplitTreatment]: ExperimentConfigEntry;
};

const getExperimentSetup = (
  trafficType: string | null,
  treatments: SplitTreatment[]
) => {
  return treatments.reduce((acc: ExperimentSetup, splitName) => {
    const config = experimentConfig[splitName];
    if (config?.trafficType === trafficType) {
      acc[splitName] = {
        selector: config.selector,
        withSplitConfig: config.withSplitConfig || false,
        prerequisiteSelector: config.prerequisiteSelector,
      };
    }

    return acc;
  }, {} as ExperimentSetup);
};

export const getTreatmentSetup = createSelector(
  [
    fromSplit.getSplitTrafficType,
    (_: State, props: { splitNames: SplitTreatment[] }) => props,
  ],
  (trafficType, { splitNames }) => {
    return getExperimentSetup(trafficType, splitNames);
  }
);

export const hasAllTreatments = createSelector(
  [
    fromSplit.getSplitTreatments,
    fromSplit.getSplitIsTimedout,
    (_: State, props: { splitNames: SplitTreatment[] }) => props,
  ],
  (treatments, timeout, { splitNames }) => {
    if (timeout) {
      // let the view layer continue (usually displaying a spinner or dancing fruit)
      return true;
    }

    // TODO: Has the getTreatments changed when the SDK timeout occurs?
    // Previously split would still return control for all treatments and this worked
    // NLF: Yep, we need to get away from this logic
    return splitNames.every((name: SplitTreatment) => !!treatments[name]);
  }
);

export const getNoTreatmentAttributes = createSelector([], () => null);

export const getBaseUserTreatmentAttributes = createSelector(
  [fromUI.isInitialized, fromSplit.isSplitInitialized, fromAccount.getUser],
  (initialized, splitInitialized, user) => {
    if (!initialized || !splitInitialized) return null;
    return {
      cadence: user.cadence,
      fulfillmentCenterId: user.fulfillmentCenterId,
      stage: user.stage,
    };
  }
);

export const getExpandedUserTreatmentAttributes = createSelector(
  [getBaseUserTreatmentAttributes, fromCDDeliveries.getActiveWindow],
  (attributes, deliveryWindow) => {
    if (!attributes) return null;
    return {
      ...attributes,
      ...(deliveryWindow && {
        marketName: deliveryWindow.marketName,
        deliveryProvider: deliveryWindow.deliveryProvider,
        fcAbbreviation: deliveryWindow.fcAbbreviation,
        fulfillmentCenterId: deliveryWindow.fulfillmentCenterId,
      }),
    };
  }
);

export const getBaseAnonUserTreatmentAttributes = createSelector(
  [fromUI.isInitialized, fromSplit.isSplitInitialized],
  (initialized, splitInitialized) => {
    if (!initialized || !splitInitialized) return null;
    return {};
  }
);

export const getBaseAnonSignupUserTreatmentAttributes = createSelector(
  [
    fromUI.isInitialized,
    fromSplit.isSplitInitialized,
    (state: State) =>
      fromCDSignup.getSignupSelectedDeliveryWindowDEPRECATED(state),
  ],
  (initialized, splitInitialized, deliveryWindow) => {
    if (!initialized || !splitInitialized || !deliveryWindow) return null;
    return {
      fulfillmentCenterId: deliveryWindow.fulfillmentCenterId,
    };
  }
);

const inOnTreatment = (split: SplitTreatment) => {
  return createSelector(
    [
      fromSplit.isSplitInitialized,
      (state) => fromSplit.getSplitTreatmentValue(state, split),
    ],
    (splitInitialized, treatment) => {
      if (!splitInitialized) return null;
      return treatment === ON_TREATMENT_TYPE;
    }
  );
};

export const getNewMarketTreatment = createSelector(
  [
    fromSplit.isSplitInitialized,
    (state) =>
      fromSplit.getSplitTreatmentWithConfig(state, FUTURE_MARKET_LAUNCH_MODAL),
  ],
  (splitInitialized, treatment) => {
    if (!splitInitialized) return null;
    const config = treatment.config ? JSON.parse(treatment.config) : {};

    return { treatment: treatment?.treatment, config };
  }
);

export const getNewMarketTreatmentAttributes = createSelector(
  [
    fromUI.isInitialized,
    fromSplit.isSplitInitialized,
    fromSignup.getSignupValues,
  ],
  (initialized, splitInitialized, signupValues) => {
    if (!initialized || !splitInitialized || !signupValues) return null;
    return {
      zip: signupValues?.zip,
    };
  }
);

export const getMinimumOrderProgressTreatmentAttributes = createSelector(
  [
    fromUI.isInitialized,
    fromSplit.isSplitInitialized,
    fromCDDeliveries.getActiveWindow,
  ],
  (initialized, splitInitialized, deliveryWindow) => {
    if (!initialized || !splitInitialized) return null;
    return {
      deliveryProvider: deliveryWindow?.deliveryProvider,
    };
  }
);

export const getFlexableDeliveriesTreatmentAttributes = createSelector(
  [fromUI.isInitialized, fromSplit.isSplitInitialized, fromAccount.getUser],
  (initialized, splitInitialized, user) => {
    if (!initialized || !splitInitialized || !user) return null;

    const addressId = (user.addresses[0] as unknown) as string;
    const defaultAddress = user.mapOfAddresses[addressId];

    return {
      zip: defaultAddress?.zip,
      lifetimeOrderCount: user.lifetimeOrderCount,
    };
  }
);

export const getHeaderBannerTreatment = (state: State) => {
  if (!fromUI.isInitialized(state) || !fromSplit.isSplitInitialized(state)) {
    return null;
  }

  return fromSplit.getSplitTreatmentWithConfig(state, HEADER_BANNER);
};

export const isInHeaderBannerTreatment = createSelector(
  [getHeaderBannerTreatment],
  (experiment) => {
    // This means a user in the "control" treatment will be in isInHeaderBannerTreatment
    // It's generally discouraged to check for !== "off", since "control" is a potential treatment
    // Should this be treatment === "on"? I'm not sure of the intention, but this caught my eye!
    // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#basic-use
    return !!experiment && experiment.treatment !== "off";
  }
);

export const headerBannerSplitConfig = createSelector(
  [getHeaderBannerTreatment],
  (experiment) => {
    if (experiment && experiment.config) {
      return JSON.parse(experiment.config);
    }
    return {};
  }
);

export const getSignupShoppingTreatmentAttributes = createSelector(
  [
    fromUI.isInitialized,
    fromSplit.isSplitInitialized,
    fromSignup.getSubscriptionPosit,
  ],
  (initialized, splitInitialized, subscriptionPosit) => {
    if (!initialized || !splitInitialized) return null;

    return {
      isInCusto: subscriptionPosit?.inCustomizationWindow,
    };
  }
);

export const isInSignupShoppingTreatment = inOnTreatment(SIGNUP_SHOPPING);

export const getAnonHeaderBannerTreatment = (state: State) => {
  if (!fromUI.isInitialized(state) || !fromSplit.isSplitInitialized(state)) {
    return null;
  }

  return fromSplit.getSplitTreatmentWithConfig(state, ANON_HEADER_BANNER);
};

export const isInAnonHeaderBannerTreatment = inOnTreatment(ANON_HEADER_BANNER);

export const getAnonHeaderBannerSplitConfig = createSelector(
  [getAnonHeaderBannerTreatment],
  (experiment) => {
    if (experiment && experiment.config) {
      return JSON.parse(experiment.config);
    }
    return null;
  }
);

export const getOrderMinAttributes = createSelector(
  [fromUI.isInitialized, fromSplit.isSplitInitialized, fromAccount.getUser],
  (initialized, splitInitialized, user) => {
    if (!initialized || !splitInitialized) return null;
    const anonOrderMinTreatment = user?.treatments?.[ANON_ORDER_MIN];

    return { anonOrderMinTreatment };
  }
);

export const getAnonOrderMinAttributes = createSelector(
  [
    fromUI.isInitialized,
    fromSplit.isSplitInitialized,
    fromSignup.getSignupValues,
    fromCDDeliveries.isUserUglyShip,
  ],
  (initialized, splitInitialized, signupValues, uglyShip) => {
    // Split must be initialized and a zipcode must exist already for experiment to be valid
    if (!initialized || !splitInitialized || !signupValues.zip) return null;

    return {
      isCarrierShip: uglyShip,
    };
  }
);

export const getShoppableRecipeAisleTreatmentAttributes = createSelector(
  [fromUI.isInitialized, fromSplit.isSplitInitialized, fromAccount.getUser],
  (initialized, splitInitialized, user) => {
    if (!initialized || !splitInitialized || !user) return null;

    return {
      lifetimeOrderCount: user.lifetimeOrderCount,
      fulfillmentCenterId: user.fulfillmentCenterId,
    };
  }
);

export const getDeliveryFeeAprilTreatmentAttributes = createSelector(
  [fromUI.isInitialized, fromSplit.isSplitInitialized, fromAccount.getUser],
  (initialized, splitInitialized, user) => {
    if (!initialized || !splitInitialized || !user) return null;

    return {
      lifetimeOrderCount: user.lifetimeOrderCount,
    };
  }
);

const getHideBiweeklySkipModalTreatmentAttributes = createSelector(
  [(_: State, props: { shouldShowBiweeklySkipModal: boolean }) => props],
  (props) => {
    if (!props) return null;

    return {
      shouldShowBiweeklySkipModal: props.shouldShowBiweeklySkipModal || false,
    };
  }
);

export const getWarningAppVersionMinimumTreatment = (state: State) => {
  if (!fromUI.isInitialized(state) || !fromSplit.isSplitInitialized(state)) {
    return null;
  }

  return fromSplit.getSplitTreatmentWithConfig(
    state,
    WARNING_APP_VERSION_MINIMUM
  );
};

/* TODO: NC-1768 Productize FTUE experiment */
export const getShoppingCarouselTreatmentAttributes = createSelector(
  [
    fromUI.isInitialized,
    fromSplit.isSplitInitialized,
    fromAccount.getUserLifetimeOrderCount,
  ],
  (initialized, splitInitialized, lifetimeOrderCount) => {
    const orderCountNotPresent =
      lifetimeOrderCount === null && lifetimeOrderCount === undefined;

    if (!initialized || !splitInitialized || orderCountNotPresent) return null;
    return {
      lifetimeOrderCount,
    };
  }
);

export const getBoysenberrySignupAttributes = createSelector(
  [fromUI.isInitialized, fromSplit.isSplitInitialized],
  (initialized, splitInitialized) => {
    if (!initialized || !splitInitialized) return null;
    return {
      brand: "imperfect-foods",
    };
  }
);

const anonymousSplits = {
  [FUTURE_MARKET_LAUNCH_MODAL]: {
    selector: getNewMarketTreatmentAttributes,
    trafficType: ANON_TRAFFIC_TYPE,
  },
  [ANON_ORDER_MIN]: {
    selector: getAnonOrderMinAttributes,
    trafficType: ANON_TRAFFIC_TYPE,
  },
  [ANON_HEADER_BANNER]: {
    selector: getBaseAnonUserTreatmentAttributes,
    trafficType: ANON_TRAFFIC_TYPE,
    withSplitConfig: true,
  },
  [BOYSENBERRY_SIGNUP]: {
    selector: getBoysenberrySignupAttributes,
    trafficType: ANON_TRAFFIC_TYPE,
  },
};

export const userSplits = {
  [USER_PLUS_ENROLL]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [HEADER_BANNER]: {
    selector: getExpandedUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
    withSplitConfig: true,
  },
  [PACKAGING_RETURN_PROGRAM]: {
    selector: getExpandedUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
    prequisiteSelector: fromAccount.isUserActive,
  },
  [MINIMUM_ORDER_PROGRESS]: {
    selector: getMinimumOrderProgressTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [ORDER_MIN]: {
    selector: getOrderMinAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [FLEX_DELIVERY_H1_2022]: {
    selector: getFlexableDeliveriesTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [LOGGED_IN_HOMEPAGE]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [SHOPPABLE_RECIPE_AISLE]: {
    selector: getShoppableRecipeAisleTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
    prerequisiteSelector: fromOrderActive.canUserCustomize,
  },
  [SUBSCRIPTION_PAUSE]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [SIGNUP_SHOPPING]: {
    selector: getSignupShoppingTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [REFERRAL_DASHBOARD]: {
    selector: getNoTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [CATALOG_PREVIEW_TOOL]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [ADDRESS_VALIDATION]: {
    selector: getNoTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [WARNING_APP_VERSION_MINIMUM]: {
    selector: getWarningAppVersionMinimumTreatment,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [SCALE_PRICE]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [MOBILE_AISLE_NAV]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [NEW_FEATURE_MODAL]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [SHOW_EXCEPTION_BANNER]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [RECENTLY_ADDED]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [FIRST_TIME_SHOPPING_CAROUSEL]: {
    selector: getShoppingCarouselTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [INTENTIONAL_SOURCING]: {
    selector: getBaseUserTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
  [HIDE_BIWEEKLY_SKIP_MODAL]: {
    selector: getHideBiweeklySkipModalTreatmentAttributes,
    trafficType: USER_TRAFFIC_TYPE,
  },
};

// @ts-ignore balking at the selector types
const experimentConfig: ExperimentConfig = {
  ...anonymousSplits,
  ...userSplits,
};

export const isSignupInitializedWithSplit = createSelector(
  [
    fromSplit.isSplitInitialized,
    (state: State) => fromCDSignup.isSignupInitialized(state),
  ],
  (splitInitialized, signupInitialized) => {
    return splitInitialized && signupInitialized;
  }
);

export const getOrderMinConfig = (state: State) => {
  if (!fromUI.isInitialized(state) || !fromSplit.isSplitInitialized(state)) {
    return null;
  }

  return {
    [ORDER_MIN]: fromSplit.getSplitTreatmentWithConfig(state, ORDER_MIN),
    [ANON_ORDER_MIN]: fromSplit.getSplitTreatmentWithConfig(
      state,
      ANON_ORDER_MIN
    ),
  };
};

export const getOrderMinTreatment = createSelector(
  [fromSplit.isSplitInitialized, getOrderMinConfig],
  (splitInitialized, treatments) => {
    if (!splitInitialized || !treatments) return null;
    const {
      [ANON_ORDER_MIN]: anonOrderMinVariant,
      [ORDER_MIN]: orderMinLoggedInVariant,
    } = treatments;

    const config = anonOrderMinVariant.config || orderMinLoggedInVariant.config;
    const parsedConfig = config ? JSON.parse(config) : {};

    return {
      isInTreatment:
        anonOrderMinVariant.treatment === "on" ||
        orderMinLoggedInVariant.treatment === "on",
      config: parsedConfig,
    } as OrderMinimumData;
  }
);

export const getPackagingReturnTreatment = createSelector(
  [
    fromSplit.isSplitInitialized,
    (state) =>
      fromSplit.getSplitTreatmentValue(state, PACKAGING_RETURN_PROGRAM),
  ],
  (splitInitialized, treatment) => {
    if (!splitInitialized) return undefined;
    return getPackagingReturnTreatmentFromValue(treatment);
  }
);

export const isInMinimumOrderProgressTreatment = inOnTreatment(
  MINIMUM_ORDER_PROGRESS
);

export const isInFlexDeliveryTestTreatment = inOnTreatment(
  FLEX_DELIVERY_H1_2022
);

export const isInLoggedInHomepageTreatment = inOnTreatment(LOGGED_IN_HOMEPAGE);

export const isInShoppableRecipeAisleTreatment = inOnTreatment(
  SHOPPABLE_RECIPE_AISLE
);

export const isInSubscriptionPauseTreatment = inOnTreatment(SUBSCRIPTION_PAUSE);

export const getShoppableRecipeAisleTreatment = (state: State) => {
  if (!fromUI.isInitialized(state) || !fromSplit.isSplitInitialized(state)) {
    return null;
  }

  return fromSplit.getSplitTreatmentWithConfig(state, SHOPPABLE_RECIPE_AISLE);
};

export const getShoppableRecipeAisleConfig = createSelector(
  [getShoppableRecipeAisleTreatment],
  (experiment) => {
    if (experiment && experiment.config) {
      return JSON.parse(experiment.config);
    }
    return {};
  }
);

export const isInReferralDashboardTreatment = inOnTreatment(REFERRAL_DASHBOARD);

export const isInCatalogPreviewToolTreatment = inOnTreatment(
  CATALOG_PREVIEW_TOOL
);

export const isInAddressValidationTreatment = inOnTreatment(ADDRESS_VALIDATION);

export const getWarningAppVersionMinimumConfig = createSelector(
  [getWarningAppVersionMinimumTreatment],
  (experiment) => {
    if (experiment && experiment.config) {
      return JSON.parse(experiment.config);
    }
    return {};
  }
);

export const isInWarningAppVersionMinimum = inOnTreatment(
  WARNING_APP_VERSION_MINIMUM
);

export const isInScalePriceTreatment = inOnTreatment(SCALE_PRICE);

export const isInMobileAisleNavTreatment = inOnTreatment(MOBILE_AISLE_NAV);

export const isInIntentionalSourcingTreatment = inOnTreatment(
  INTENTIONAL_SOURCING
);

export const isInNewFeatureModalTreatment = inOnTreatment(NEW_FEATURE_MODAL);

export const isInShowExceptionBannerTreatment = inOnTreatment(
  SHOW_EXCEPTION_BANNER
);

export const isInRecentlyAddedTreatment = inOnTreatment(RECENTLY_ADDED);

export const isInShoppingCarouselTreatment = inOnTreatment(
  FIRST_TIME_SHOPPING_CAROUSEL
);

export const isInHideBiweeklySkipModalTreatment = inOnTreatment(
  HIDE_BIWEEKLY_SKIP_MODAL
);

export const isInBoysenberrySignupTreatment = createSelector(
  [
    fromSplit.isSplitInitialized,
    fromSplit.splitTreatmentsInitialized,
    (state) => fromSplit.getSplitTreatmentValue(state, BOYSENBERRY_SIGNUP),
  ],
  (splitInitialized, splitTreatmentsInitialized, treatment) => {
    if (!splitInitialized || !splitTreatmentsInitialized) return null;

    return [ON_TREATMENT_TYPE, "prefquiz", "minimal"].includes(treatment);
  }
);
