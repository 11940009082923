import React from "react";
import styled from "styled-components";
import { rem } from "polished";

interface AddressProps {
  className?: string;
}

const Address: React.FC<AddressProps> = ({ className }) => {
  return (
    <p translate="no" typeof="schema:PostalAddress" className={className}>
      <span property="schema:streetAddress">1616 Donner Ave.</span>&nbsp;
      <span property="schema:addressLocality">San Francisco</span>,&nbsp;
      <abbr title="California" property="schema:addressRegion">
        CA.
      </abbr>
      &nbsp;
      <span property="schema:postalCode">94124</span>
    </p>
  );
};

const StyledAddress = styled(Address)`
  font-size: ${rem(13)};
  margin: ${rem(16)} ${rem(0)} ${rem(8)};
  text-align: center;
  color: ${({ theme }) => theme.colors.purple};

  abbr {
    text-decoration: none;
  }
`;

export default StyledAddress;
