import React from "react";
import { Helmet } from "react-helmet";

import { config } from "app/config";

interface DefaultHeadProps {
  titleTemplate?: string;
  titleDefault?: string;
  baseHref?: string;
}

const DEFAULT_TITLE_TEMPLATE = config.get("document_data.title_template");
const DEFAULT_TITLE = config.get("document_data.title_default");
const DEFAULT_BASE_HREF = window?.location?.origin;

/*
  This component defines some default values that should be present in the head
  of the document. To override, place a <Helmet> component in the relevant file
  and place any head elements as children.

  Using environment variables to control key values so that code changes are not
  required to modify them in production.
*/
const DefaultHead: React.FC<DefaultHeadProps> = (props) => {
  const {
    titleTemplate = DEFAULT_TITLE_TEMPLATE,
    titleDefault = DEFAULT_TITLE,
    baseHref = DEFAULT_BASE_HREF,
  } = props;

  return (
    <Helmet
      titleTemplate={titleTemplate}
      defaultTitle={titleDefault}
      base={{ href: baseHref }}
    >
      <meta name="twitter:title" content={titleDefault} />
      <meta name="twitter:url" content={baseHref} />
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

export default DefaultHead;
