import BlockIcon from "@material-ui/icons/Block";
import React from "react";
import styled from "styled-components";

import { ProfileType } from ".";

import { toRem } from "app/styles/utils";

export interface NeverListIndicatorProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  profileType?: ProfileType;
}

const NeverListIndicator: React.FC<NeverListIndicatorProps> = ({
  profileType = ProfileType.REGULAR,
  onClick,
}) => (
  <NeverListWrapper $profileType={profileType} onClick={onClick}>
    <StyledBlockIcon />
    {profileType === ProfileType.REGULAR ? (
      <>
        <UnderlinedText>Remove </UnderlinedText>&nbsp;from &quot;Never&quot;
        list
      </>
    ) : (
      <UnderlinedText>&quot;Never&quot; item</UnderlinedText>
    )}
  </NeverListWrapper>
);

const StyledBlockIcon = styled(BlockIcon)`
  font-size: ${toRem(16)};
  margin-right: ${toRem(8)};
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

const NeverListWrapper = styled.button<{ $profileType: ProfileType }>`
  border: none;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  height: ${toRem(24)};
  width: 100%;
  position: absolute;
  bottom: 0;
  color: ${({ theme }) => theme.colors.white};
  justify-content: ${({ $profileType }) =>
    $profileType === ProfileType.REGULAR ? "left" : "center"};
  font-weight: 500;
  font-size: ${toRem(13)};
  line-height: ${toRem(16)};
  padding: ${({ $profileType }) =>
    $profileType === ProfileType.REGULAR
      ? toRem([3, 12])
      : toRem([2, 0, 4, 0])};
  z-index: 1;

  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
`;

export default NeverListIndicator;
