import { createSelector } from "reselect";

import routes from "app/router/routes.json";
import * as fromRouting from "app/selectors/routing";
import * as fromAccount from "app/selectors/account";
import * as fromOrderActive from "app/selectors/orderActive";
import * as fromSignup from "app/selectors/signup";
import * as fromUi from "app/selectors/ui";
import * as fromAuth from "app/selectors/auth";
import * as fromCDSignup from "app/selectors/crossDomain/signup";
import * as fromReactivation from "app/selectors/reactivation";
import * as fromReactivationCD from "app/selectors/crossDomain/reactivation";

/*
 * Defines the "core" route the user should be on if they land on a restricted route.
 */
export const coreRouteSelector = createSelector(
  [
    fromRouting.getCoreRoute,
    fromUi.isInitialized,
    fromAccount.isUserActive,
    fromAccount.isUserLapsed,
    fromAccount.isUserProspect,
    fromSignup.isSignupStateInitialized,
    fromSignup.getUrlForSignupStep,
    fromOrderActive.canUserCustomize,
    fromReactivationCD.canUserViewReactivation,
    fromReactivationCD.canUserViewGoodbye,
  ],
  (
    prevCoreRoute,
    initialized,
    activeUser,
    lapsedUser,
    prospectUser,
    signupInitialized,
    coreSignupUrl,
    canCustomize,
    canUserViewReactivation,
    canUserViewGoodbye
  ) => {
    let coreRoute = routes.login.url;

    if (initialized) {
      if (activeUser) {
        coreRoute = canCustomize ? routes.shopping.url : routes.account.url;
      } else if (canUserViewReactivation) {
        coreRoute = routes.reactivate.url;
      } else if (canUserViewGoodbye) {
        coreRoute = routes.goodbye.url;

        // Any user currently in the signup funnel (signup initialized)
        // OR lapsed OR prospect, should go to signup as the core route
      } else if (signupInitialized || lapsedUser || prospectUser) {
        coreRoute = coreSignupUrl;
      }
    }

    return {
      coreRoute,
      prevCoreRoute,
    };
  }
);

/*
 * Used for restricting routes.
 */
export const isSignupRouteAllowed = createSelector(
  [
    fromCDSignup.getCurrentSignupStepForRoute,
    fromSignup.getSignupCurrentStep,
    fromSignup.getSignupProgressStep,
    fromSignup.getSignupFlow,
    fromSignup.getSignupConfirmationStep,
    fromCDSignup.isSignupConfirmationRouteAllowed,
    fromAuth.isLoggedOut,
    fromAccount.canUserSignup,
    fromReactivation.canUserReactivate,
  ],
  (
    routeStep,
    currentStep,
    progressStep,
    flow,
    confirmationStep,
    confirmationRouteAllowed,
    loggedOut,
    canSignup,
    canUserReactivate
  ) => {
    if (canUserReactivate) return false;
    if (currentStep === confirmationStep) return confirmationRouteAllowed;
    // @ts-ignore
    const isOnPrevStep = flow.indexOf(routeStep) <= flow.indexOf(progressStep);
    return (loggedOut || canSignup) && isOnPrevStep;
  }
);
