import { rem } from "polished";

export default {
  lufga: '"Lufga", sans-serif',
  imperfectHand: '"ImperfectHand", sans-serif',
  headings: '"Yanone Kaffeesatz", sans-serif',
  body: '"Montserrat", sans-serif',
  weight: {
    lighter: "200",
    light: "300",
    normal: "400",
    semiBold: "600",
    bold: "700",
    extraBold: "800",
  },
  size: {
    mobile: {
      header1: `${rem(36)}`,
      header2: `${rem(30)}`,
      header3: `${rem(24)}`,
      header4: `${rem(18)}`,
      header5: `${rem(15)}`,
      header6: `${rem(14)}`,
      overlineOne: `${rem(40)}`,
      overlineTwo: `${rem(32)}`,
      overlineThree: `${rem(22)}`,
      caption1: `${rem(13)}`,
      caption2: `${rem(12)}`,
      link3: `${rem(13)}`,
    },
    tablet: {
      header1: `${rem(48)}`,
    },
    desktop: {
      header1: `${rem(72)}`,
      header2: `${rem(48)}`,
      header3: `${rem(30)}`,
      header4: `${rem(24)}`,
      header5: `${rem(16)}`,
      // No definition in DS
      header6: `${rem(15)}`,
      overlineOne: `${rem(46)}`,
      overlineTwo: `${rem(32)}`,
      overlineThree: `${rem(26)}`,
      caption1: `${rem(13)}`,
      caption2: `${rem(12)}`,
      link3: `${rem(12)}`,
    },
  },
};
