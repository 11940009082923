// @ts-nocheck
import ACTION_TYPES from "app/actionTypes/featureIntro";

export const MY_FOOD_PREFERENCES = "myfoodpreferences";
export enum DISMISSIBLE_BANNERS {
  PREFILLED_CART = "prefilledcartbanner",
}

const defaultInitialState = {
  completed: false,
};

export const initialState = {
  [MY_FOOD_PREFERENCES]: defaultInitialState,
  [DISMISSIBLE_BANNERS.PREFILLED_CART]: defaultInitialState,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SET_INTRO_COMPLETED: {
      return {
        ...state,
        [action.feature]: {
          ...state[action.feature],
          completed: action.completed,
        },
      };
    }

    default:
      return state;
  }
}

export function getAllFeatureIntros(state) {
  return state;
}

export function setFeatureIntroCompleted(
  feature: string,
  completed: boolean = true,
  track?: boolean
) {
  return { type: ACTION_TYPES.SET_INTRO_COMPLETED, feature, completed, track };
}
