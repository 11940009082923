// @ts-nocheck
import _find from "lodash/find";
import { createSelector } from "reselect";

import { EXPLORE_ADDONS_MODAL } from "app/constants/modals";
import routes from "app/router/routes.json";
import { getMyAccountAddOnBoxesForUser } from "app/selectors/crossDomain/account";
import * as fromPlus from "app/selectors/crossDomain/plus";
import * as fromCDRouting from "app/selectors/crossDomain/routing";
import * as fromCDSplitTreatments from "app/selectors/crossDomain/splitTreatments";
import { getModals } from "app/selectors/ui";

export const defaultNavItems = [
  {
    ...routes.account,
    icon: "home",
  },
  {
    ...routes.details,
    icon: "user",
  },
  {
    ...routes.tastePreferences,
    icon: "utensils",
  },
  {
    ...routes.orderHistory,
    icon: "box-open",
  },
  {
    ...routes.billing,
    icon: "dollar-sign",
  },
  {
    ...routes.deliveries,
    icon: "clock",
  },
  {
    ...routes.subscription,
    icon: "archive",
  },
  { ...routes.referrals, icon: "lemon" },
];

export const plusNavItem = { ...routes.plus, icon: "plus-circle" };

export const getAccountNavigationItems = createSelector(
  [fromPlus.canShowPlusFeature],
  (showPlusFeature) => {
    const navItems = [...defaultNavItems];

    if (showPlusFeature) {
      navItems.push(plusNavItem);
    }

    return navItems;
  }
);

/*
 * Returns the header banner config; defaults to null if not applicable to current route
 */
export const getHeaderBannerConfigForRoute = createSelector(
  [
    fromCDSplitTreatments.isInHeaderBannerTreatment,
    fromCDSplitTreatments.headerBannerSplitConfig,
    fromCDRouting.isOnShoppingPage,
  ],
  (showHeaderBanner, headerBannerConfig, onShopping) => {
    if (!showHeaderBanner) {
      return null;
    }

    if (!headerBannerConfig?.message?.length > 0) {
      return null;
    }

    if (headerBannerConfig?.shoppingOnly && !onShopping) {
      return null;
    }

    return headerBannerConfig;
  }
);

export const getAnonHeaderBannerConfigForRoute = createSelector(
  [
    fromCDSplitTreatments.isInAnonHeaderBannerTreatment,
    fromCDSplitTreatments.getAnonHeaderBannerSplitConfig,
    fromCDRouting.isOnLoggedOutPage,
    fromCDRouting.isOnLoggedOutSignupPage,
  ],
  (showAnonHeaderBanner, anonHeaderBannerConfig, onLoggedOut, onSignup) => {
    // if we don't have a header banner, null
    if (
      !showAnonHeaderBanner ||
      anonHeaderBannerConfig?.message?.length === 0
    ) {
      return null;
    }
    if (onLoggedOut || onSignup) {
      return anonHeaderBannerConfig;
    }

    return null;
  }
);

export const getSecondaryBoxModalById = createSelector(
  [getModals, getMyAccountAddOnBoxesForUser],
  (modals, addons) => {
    const id = modals[EXPLORE_ADDONS_MODAL]?.params?.boxId;
    return _find(addons, (addon) => addon.id === id);
  }
);
