import React from "react";
import { useSelector } from "react-redux";

import State from "app/types/state";
import { isNativeApp } from "app/selectors";

const HideOnNative = ({ children }: React.PropsWithChildren<unknown>) => {
  const isNative = useSelector((state: State) => isNativeApp(state));

  return <>{!isNative ? children : null}</>;
};

export default HideOnNative;
