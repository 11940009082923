import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import grid from "app/styles/grid";
import { toRem } from "app/styles/utils";
import Main from "app/ui/shared/Main";
import Wrapper from "app/ui/shared/Wrapper";

interface PageProps {
  title?: string;
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ title, children }) => {
  return (
    <PageContainer>
      {title && (
        <BlockContainer>
          <TitleWrapper>
            <h2>{title}</h2>
          </TitleWrapper>
        </BlockContainer>
      )}
      {children}
    </PageContainer>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

// Every CMS page inherits these styles:
const PageContainer = styled(Main)`
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  justify-content: flex-start;

  .styled {
    font-family: ${({ theme }) => theme.fonts.body};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    text-align: center;
    text-transform: none;
  }

  h1,
  h2 {
    &.styled {
      color: ${({ theme }) => theme.colors.pinkLight};
    }
  }

  h1 {
    font-size: ${toRem(40)};
  }

  h2 {
    font-size: ${toRem(32)};
  }

  h3 {
    font-size: ${toRem(24)};
  }

  h4 {
    font-size: ${toRem(20)};
  }

  h5 {
    font-size: ${toRem(18)};
  }

  h6 {
    font-size: ${toRem(16)};
  }

  p {
    line-height: 1.5;

    &:empty {
      min-height: ${toRem(24)};
    }
  }

  li {
    margin-bottom: ${toRem(12)};
  }

  hr {
    height: 0;
    margin-top: ${toRem(24)};
    margin-bottom: ${toRem(24)};
    border: 0;
    border-top: ${({ theme }) => theme.borders.default};

    &.thick {
      border-top-width: 3px;
    }
  }

  ol {
    padding-left: ${toRem(16)};
  }
`;

interface BlockContainerProps {
  $noPadding?: boolean;
  $textColor?: string;
  $titleColor?: string;
  $backgroundColor?: string;
}

export const BlockContainer = styled.div<BlockContainerProps>`
  ${grid.row}
  justify-content: center;
  padding: ${({ $noPadding }) => toRem($noPadding ? [0, 16] : 16)};

  ${({ $noPadding }) =>
    !$noPadding &&
    `
    &:first-of-type {
      padding-top: ${toRem(48)};
    }
  `}

  ${({ $textColor }) =>
    !!$textColor &&
    `
    color: ${$textColor};
  `};

  ${({ $titleColor }) =>
    !!$titleColor &&
    `
    h1.title,
    h2.title,
    h3.title,
    h4.title,
    h5.title,
    h6.title {
      color: ${$titleColor};
    }
  `};

  ${({ $backgroundColor }) =>
    !!$backgroundColor &&
    `
    background-color: ${$backgroundColor};
    `};
`;

interface BlockWrapperProps {
  $centerContent?: boolean;
}

export const BlockWrapper = styled(Wrapper)<BlockWrapperProps>`
  flex-direction: column;

  ${({ $centerContent }) =>
    !!$centerContent &&
    `
    align-items: center;
    `};
`;

const TitleWrapper = styled(Wrapper)`
  justify-content: center;

  h2 {
    margin-bottom: 0;
  }
`;

export default Page;
