// @ts-nocheck
import MomentUtils from "@date-io/moment";
import {
  library,
  config as fontConfig,
} from "@fortawesome/fontawesome-svg-core";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ThemeProvider } from "styled-components";
import { Auth } from "aws-amplify";
import * as amplitude from "@amplitude/analytics-browser";

import { config } from "app/config";
import configureStore from "app/configureStore";
import awsExports from "app/auth/awsExports";
import { AMPLITUDE_KEY_PROD } from "app/constants";

import Router from "app/router";
import icons from "app/styles/icons";
import theme from "app/styles/theme";
import { GlobalStyles } from "app/ui/global";
import "lazysizes";
import { MediaMobile, MediaTablet } from "app/ui/global/media";
import CMSProvider from "app/ui/cms/CMSProvider";

Auth.configure(awsExports);

// There is a known bug with running react-axe locally in Safari - It will cause a blank screen
// To fix locally, set LOG_ACCESSIBILITY .env to false (mimics non-local environment values)
// Additionally, this package is not included in production bundle via
// webpack "externals"
if (
  config.get("flags.log_accessibility") &&
  config.get("env") === "development"
) {
  const axe = require("react-axe"); //eslint-disable-line
  axe(React, ReactDOM, 1000);
}

// This enables using FontAwesome in CSS pseudo elements
// see: https://fontawesome.com/how-to-use/svg-with-js#pseudo-elements
fontConfig.searchPseudoElements = true;

// Preload only the fonts we'll use
library.add(icons);

/*
 * https://github.com/andyearnshaw/Intl.js#intljs-and-browserifywebpack.
 * Shim intl & en locale.
 */
/* eslint-disable prefer-arrow-callback */
if (!global.Intl) {
  require.ensure(
    ["intl", "intl/locale-data/jsonp/en.js"],
    function callback(require) {
      require("intl");
      require("intl/locale-data/jsonp/en.js");
    }
  );
}
/* eslint-enable prefer-arrow-callback */

export const { store, persistor } = configureStore();

// const isProduction = config.get("env") === "production";

amplitude.init(AMPLITUDE_KEY_PROD, {
  defaultTracking: {
    attribution: true,
    pageViews: false,
    sessions: true,
    formInteractions: false,
    fileDownloads: false,
  },
});

window.amplitude = amplitude;

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <CMSProvider>
                  <GlobalStyles />
                  <MediaTablet>
                    {(isTablet) => (
                      <MediaMobile>
                        {(isMobile) => (
                          <Router isMobile={isMobile} isTablet={isTablet} />
                        )}
                      </MediaMobile>
                    )}
                  </MediaTablet>
                </CMSProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
