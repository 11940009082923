import {
  SHOPPING_ROUTES,
  isPathInUrlList,
  LOGGED_OUT_ROUTE_PATHS,
  LOGGED_OUT_SIGNUP_ROUTES,
} from "app/constants/routes";
import normalizePath from "app/router/normalizePath";
import routes from "app/router/routes.json";
import * as fromRouting from "app/selectors/routing";
import { Route } from "app/types/router/Route";
import State from "app/types/state";

const nonCMSRoutePaths = Object.values(routes).reduce(
  (acc: string[], route: Route) => {
    // Filter out route entries that aren't specified as being CMS pages
    if (!route.cms) acc.push(route.url);
    return acc;
  },
  []
);

export function isOnHomePage(state: State) {
  return fromRouting.getRoutingLocation(state).pathname === routes.main.url;
}

export function isOnOurFoodPage(state: State) {
  return fromRouting.getRoutingLocation(state).pathname === routes.ourFood.url;
}

export function isOnHowItWorksPage(state: State) {
  return (
    fromRouting.getRoutingLocation(state).pathname === routes.how_it_works.url
  );
}

export function isOnFoodWastePage(state: State) {
  return (
    fromRouting.getRoutingLocation(state).pathname === routes.foodWaste.url
  );
}

export function isOnMyAccountPage(state: State) {
  return fromRouting.getRoutingLocation(state).pathname === routes.account.url;
}

export function isOnShoppingPage(state: State) {
  const currentPathname = fromRouting.getRoutingLocation(state).pathname;
  return isPathInUrlList(currentPathname, SHOPPING_ROUTES);
}

export function isOnPdpPage(state: State) {
  const currentPathname = fromRouting.getRoutingLocation(state).pathname;
  const productDetailPageRoute = routes.shopProductDetailPage.url.split(":")[0];
  return currentPathname.includes(productDetailPageRoute);
}

export function isOnLogoutPage(state: State) {
  return fromRouting.getRoutingLocation(state).pathname === routes.logout.url;
}

export function isOnLoggedOutPage(state: State) {
  const currentPathname = fromRouting.getRoutingLocation(state).pathname;
  return isPathInUrlList(currentPathname, LOGGED_OUT_ROUTE_PATHS);
}

export function isOnLoggedOutSignupPage(state: State) {
  const currentPathname = fromRouting.getRoutingLocation(state).pathname;
  return isPathInUrlList(currentPathname, LOGGED_OUT_SIGNUP_ROUTES);
}

export function isOnCatalogPreviewPage(state: State) {
  const currentPathname = fromRouting.getRoutingLocation(state).pathname;
  return (
    currentPathname === routes.signup.url ||
    currentPathname === routes.signupSubscription.url
  );
}

export function isOnSinglePageCatalog(state: State) {
  const currentRoute = fromRouting.getRoutingLocation(state);
  const currentPathname = currentRoute.pathname;
  const currentHash = currentRoute.hash;
  return (
    currentPathname === routes.shopping.url && currentHash.includes("#shelf=")
  );
}

export function isOnOrdersPage(state: State) {
  return fromRouting.getRoutingLocation(state).pathname === routes.orders.url;
}

export function isOnSignupCompletePage(state: State) {
  return (
    normalizePath(fromRouting.getRoutingLocation(state).pathname) ===
    routes.signupComplete.url
  );
}

export function isOnSignupPage(state: State) {
  return (
    normalizePath(fromRouting.getRoutingLocation(state).pathname) ===
    routes.signup.url
  );
}

export function isOnSignupSubscriptionPage(state: State) {
  return (
    normalizePath(fromRouting.getRoutingLocation(state).pathname) ===
    routes.signupSubscription.url
  );
}

export function isOnLogInPage(state: State) {
  return (
    normalizePath(fromRouting.getRoutingLocation(state).pathname) ===
    routes.login.url
  );
}

export function isOnForgotPasswordPage(state: State) {
  return (
    normalizePath(fromRouting.getRoutingLocation(state).pathname) ===
    routes.forgotPassword.url
  );
}

export function isOnCMSPage(state: State) {
  const pathName = normalizePath(
    fromRouting.getRoutingLocation(state).pathname
  );
  return !nonCMSRoutePaths.includes(pathName);
}
