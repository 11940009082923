// @ts-nocheck
import styled from "styled-components";

import { sizes } from "app/styles/breakpoints";
import grid from "app/styles/grid";
import { toRem } from "app/styles/utils";

export default styled.div`
  ${grid.row};
  ${grid.cell.fit};
  flex-wrap: wrap;
  max-width: ${toRem(sizes.xxl)};
`;
