/**
 * @function MaterialDrawer
 */

import MaterialDrawer, {
  SwipeableDrawerProps,
} from "@material-ui/core/SwipeableDrawer";
import React, { useState, useEffect } from "react";

export interface DrawerProps extends SwipeableDrawerProps {
  onClose: () => void;
  onOpen: () => void;
}

export const Drawer: React.FC<DrawerProps> = ({
  open = false,
  onClose,
  onOpen,
  ...muiProps
}: DrawerProps) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnOpen = () => {
    setIsOpen(true);
    onOpen();
  };

  return (
    <MaterialDrawer
      open={isOpen}
      onClose={handleOnClose}
      onOpen={handleOnOpen}
      {...muiProps}
    />
  );
};
