import * as fromShelves from "app/reducers/shelves";
import State from "app/types/state";

export function getAllShelves(state: State) {
  return fromShelves.allShelves(state.shelves);
}

export function getShelfInfoFromShelfId(state: State, shelfId: string) {
  return fromShelves.getShelfInfoFromShelfId(state.shelves, shelfId);
}

export default getAllShelves;
