import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { rem } from "polished";
import { getImageURL } from "app/ui/global/utils";
import theme from "app/styles/theme";
import breakpoints from "app/styles/breakpoints";

export enum ProfileType {
  REGULAR = "regular",
  SLIM = "slim",
}

export interface EndCapProps {
  className?: string;
  header: string;
  description?: string;
  fontColor?: string;
  backgroundColor?: string;
  imageUrl: string;
  imageHeight: number;
  profileType: ProfileType;
}

const EndCap: React.FC<EndCapProps> = ({
  className,
  description,
  header,
  fontColor = theme.colors.plum,
  backgroundColor = theme.colors.baba,
  imageHeight = 240,
  imageUrl,
  profileType,
}) => {
  const backgroundImageUrl =
    profileType === ProfileType.SLIM
      ? getImageURL(
          imageUrl,
          null,
          {
            g: "center",
            dpr: "auto",
          },
          {
            q: "auto",
            f: "auto",
          }
        )
      : getImageURL(
          imageUrl,
          null,
          {
            g: "center",
            dpr: "auto",
          },
          {
            q: "auto",
            f: "auto",
          }
        );

  const renderRegularContent = () => (
    <EndCapContainer
      id="aisle-end-cap"
      className={className}
      $backgroundColor={backgroundColor}
      $fontColor={fontColor}
      $imageHeight={imageHeight}
    >
      <EndCapTextContainer>
        <EndCapHeader variant="h2">{header}</EndCapHeader>
        <EndCapDescription variant="body2">{description}</EndCapDescription>
      </EndCapTextContainer>
      <EndCapImage $backgroundImageUrl={backgroundImageUrl} $flexBasis={50} />
    </EndCapContainer>
  );
  const renderSlimContent = () => (
    <>
      <EndCapContainer
        className={className}
        $backgroundColor={backgroundColor}
        $fontColor={fontColor}
        $imageHeight={imageHeight}
      >
        <Spacer />
        <EndCapImage
          $backgroundImageUrl={backgroundImageUrl}
          $flexBasis={100}
        />
      </EndCapContainer>
      <EndCapSlimTextContainer $fontColor={fontColor}>
        <EndCapSlimHeader variant="h2">{header}</EndCapSlimHeader>
        <EndCapDescription variant="body2">{description}</EndCapDescription>
      </EndCapSlimTextContainer>
    </>
  );
  return profileType === ProfileType.REGULAR
    ? renderRegularContent()
    : renderSlimContent();
};

const EndCapContainer = styled.div<{
  $backgroundColor: string;
  $fontColor: string;
  $imageHeight: number;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $fontColor }) => $fontColor};
  height: ${({ $imageHeight }) => rem($imageHeight)};
  display: flex;
  align-items: stretch;
`;

const EndCapTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${rem(32)};
  flex: 1;
`;

export const SLIM_TEXT_HEIGHT = 128;

const EndCapSlimTextContainer = styled.div<{ $fontColor: string }>`
  padding: ${rem(16)};
  height: ${rem(SLIM_TEXT_HEIGHT)};
  font-size: ${rem(14)};
  background-color: ${({ theme }) => theme.colors.baba};
  color: ${({ $fontColor }) => $fontColor};
`;

const EndCapSlimHeader = styled(Typography)`
  margin-bottom: ${rem(8)};
  font-size: ${rem(30)};
  line-height: ${rem(40)};
`;

const EndCapHeader = styled(Typography)`
  margin-bottom: ${rem(8)};
  font-size: ${rem(30)};
  line-height: ${rem(40)};
  ${breakpoints.xl`
    margin-bottom: ${rem(16)};
    font-size: ${rem(48)};
    line-height: ${rem(56)};
  `}
`;

const EndCapDescription = styled(Typography)`
  font-size: ${rem(14)};
  ${breakpoints.lg`
    line-height: ${rem(24)}
  `}
  ${breakpoints.xl`
    font-size: ${rem(16)};
  `}
`;

const EndCapImage = styled.div<{
  $backgroundImageUrl: string;
  $flexBasis?: number;
}>`
  position: relative;
  flex-basis: ${({ $flexBasis }) => `${$flexBasis}%` ?? "50%"};
  height: auto;
  background-image: url(${({ $backgroundImageUrl }) => $backgroundImageUrl});
  background-size: cover;
  background-position: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

export default EndCap;
