import SvgInstagram from "app/images/instagram.svg";
import SvgFacebook from "app/images/facebook.svg";
import SvgTwitter from "app/images/twitter.svg";
import SvgTikTok from "app/images/tiktok.svg";
import SvgPinterest from "app/images/pinterest.svg";
import * as kb from "app/router/routesKnowledgeBase";
import routes from "app/router/routes.json";
import { rem } from "polished";

export const BEETFOOTER_OVERLAP_HEIGHT = {
  xs: rem(250),
  lg: rem(300),
};

export const SOCIAL_NETWORKS = [
  {
    name: "Instagram",
    ariaLabel: "Find us on Instagram",
    url: "https://www.instagram.com/imperfectfoods",
    ico: SvgInstagram,
  },
  {
    name: "Facebook",
    ariaLabel: "Find us on Facebook",
    url: "https://www.facebook.com/imperfectfoods",
    ico: SvgFacebook,
  },
  {
    name: "Twitter",
    ariaLabel: "Find us on Twitter",
    url: "https://twitter.com/imperfect_foods",
    ico: SvgTwitter,
  },
  {
    name: "TikTok",
    ariaLabel: "Find us on TikTok",
    url: "https://www.tiktok.com/@imperfectfoods?",
    ico: SvgTikTok,
  },
  {
    name: "Pinterest",
    ariaLabel: "Find us on Pinterest",
    url: "https://www.pinterest.com/imperfectfruit",
    ico: SvgPinterest,
  },
];

export const FOOTER_NAV_LINKS = {
  LINE_1: [
    {
      name: "FAQs",
      url: kb.getPath(kb.RouteKey.FAQ),
      external: true,
    },
    {
      name: "Resources",
      url: "/resources-and-reports",
    },
    {
      name: "Our Packaging",
      url: "/packaging",
    },
    {
      name: "Impact",
      url: "/impact",
    },
  ],
  LINE_2: [
    {
      name: "Blog",
      url: "https://blog.imperfectfoods.com/",
      external: true,
      newWindow: true,
    },
    {
      name: "Jobs",
      url: "/jobs",
    },
    {
      name: "Contact Us",
      url: "/contact",
    },
  ],
};

export const FOOTER_BUTTONS = {
  LINE_1: [
    {
      name: "Our Food",
      url: routes.ourFood.url,
    },
    {
      name: "Food Waste",
      url: routes.foodWaste.url,
    },
  ],
  LINE_2: [
    {
      name: "How it Works",
      url: routes.how_it_works.url,
    },
  ],
};
