import { Button, IconButton, Typography } from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { Overline4 } from "app/ui/shared/ThemedTypography";
import breakpoints from "app/styles/breakpoints";

export interface ConfirmationDialogProps {
  headerText: string | JSX.Element;
  onClearIconButtonClick?: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  overlineText?: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  showClearButton?: boolean;
  subcopyText?: string | JSX.Element;
  topContent?: JSX.Element;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  topContent,
  headerText,
  onClearIconButtonClick,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  overlineText,
  primaryButtonText,
  secondaryButtonText,
  subcopyText,
  showClearButton = true,
  children,
}) => {
  return (
    <Container>
      {showClearButton && (
        <ClearIconButton
          aria-label="close"
          disableFocusRipple
          disableRipple
          onClick={onClearIconButtonClick}
          onMouseDown={(e) => e.preventDefault()}
        >
          <ClearRoundedIcon />
        </ClearIconButton>
      )}
      {topContent}
      {overlineText && <Overline>{overlineText}</Overline>}
      <Header variant="h3">{headerText}</Header>
      {subcopyText && (
        <Subcopy
          variant="body2"
          component={typeof subcopyText === "string" ? "p" : "div"}
        >
          {subcopyText}
        </Subcopy>
      )}
      {children}
      <ButtonContainer>
        <PrimaryButton
          onClick={onPrimaryButtonClick}
          size="small"
          variant="contained"
        >
          {primaryButtonText}
        </PrimaryButton>
        {onSecondaryButtonClick && secondaryButtonText && (
          <SecondaryButton
            onClick={onSecondaryButtonClick}
            size="small"
            variant="outlined"
          >
            {secondaryButtonText}
          </SecondaryButton>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default ConfirmationDialog;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(480)};
  padding: ${rem(24)};

  ${breakpoints.md`
      align-items: flex-start;
  `}
`;

const ClearIconButton = styled(IconButton)`
  align-self: flex-end;
  padding: 0;
  border-radius: 0;

  &:hover {
    background-color: unset;
  }

  &:focus {
    outline: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focusWithOutline};
  }
`;

const Overline = styled(Overline4)`
  text-align: center;

  ${breakpoints.md`
    text-align: left;
  `}
`;

const Header = styled(Typography)`
  margin-top: ${rem(4)};
  text-align: center;

  ${breakpoints.md`
    text-align: left;
  `}
`;

const Subcopy = styled(Typography)`
  margin-top: ${rem(12)};
  text-align: center;

  ${breakpoints.md`
    text-align: left;
  `}
` as typeof Typography;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${breakpoints.md`
    align-self: flex-end;
    flex-direction: row-reverse;
    margin-top: ${rem(32)};
  `}
`;

const PrimaryButton = styled(Button)`
  width: 100%;
  margin-top: ${rem(16)};

  ${breakpoints.md`
    width: auto;
    margin-top: 0;
    margin-left: ${rem(8)};
  `}
`;

const SecondaryButton = styled(Button)`
  width: 100%;
  margin-top: ${rem(16)};

  ${breakpoints.md`
    width: auto;
    margin-top: 0;
  `}
`;
