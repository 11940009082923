import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import LoopIcon from "@material-ui/icons/Loop";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Moment } from "moment";
import moment from "moment-timezone";
import { rem } from "polished";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import breakpoints from "app/styles/breakpoints";
import { LoadingStatus } from "app/types/state";
import { CatalogPreviewToolData } from "app/types/state/ui";
import { DialogType } from "app/types/ui/Dialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";
import usePrevious from "app/ui/hooks/usePrevious";
import { Overline4 } from "app/ui/shared/ThemedTypography";
import { PACK_DATE_FORMAT } from "app/ui/shopping/CatalogPreviewToolDialog/Container";

export interface CatalogPreviewToolDialogProps {
  activeCatalogData: CatalogPreviewToolData;
  fcOptions: { label: string; value: string }[];
  onReset: () => void;
  onSubmit: (data: CatalogPreviewToolData) => void;
  priceZoneOptions: { label: string; value: string }[];
  status: LoadingStatus;
}

const CatalogPreviewToolDialog: React.FC<CatalogPreviewToolDialogProps> = ({
  activeCatalogData,
  fcOptions,
  onReset,
  onSubmit,
  priceZoneOptions,
  status,
}) => {
  const { open, handleClose, handleExited } = useHandleTransitions({
    type: DialogType.CATALOG_PREVIEW_TOOL,
  });

  const [formFcId, setFormFcId] = useState(activeCatalogData.fcId);
  const [selectedDate, handleDateChange] = useState<Moment | null>(
    moment(activeCatalogData.packDate)
  );
  const [dateValidated, setDateValidated] = useState(true);
  const [formPriceZoneId, setFormPriceZoneId] = useState(
    activeCatalogData.priceZoneId
  );

  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (
      prevStatus === LoadingStatus.LOADING &&
      (status === LoadingStatus.SUCCEEDED || status === LoadingStatus.FAILED)
    ) {
      handleClose();
    }
  }, [status]);

  const handleReset = () => {
    onReset();
    handleClose();
  };

  const handleSubmit = () => {
    if (formFcId && selectedDate && formPriceZoneId) {
      onSubmit({
        fcId: formFcId,
        packDate: selectedDate.format(PACK_DATE_FORMAT),
        priceZoneId: formPriceZoneId,
      });
    }
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogContent>
        <ClearIconButton
          aria-label="close"
          disableFocusRipple
          disableRipple
          onClick={handleClose}
          onMouseDown={(e) => e.preventDefault()}
        >
          <ClearRoundedIcon />
        </ClearIconButton>
        <Overline>Internal Tool</Overline>
        <Header variant="h3">
          Catalog Preview{" "}
          <span aria-label="telescope-emoji" role="img">
            🔭
          </span>
        </Header>
        <Subcopy variant="body2">
          View the catalog for a given fulfillment center, pack date, and price
          zone.
        </Subcopy>
        <CatalogPreviewForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <FCFormControl variant="outlined">
            <InputLabel id="fc-select-label">Fulfillment Center</InputLabel>
            <Select
              disabled={status === LoadingStatus.LOADING}
              label="Fulfillment Center"
              labelId="fc-select-label"
              onChange={(e) => {
                setFormFcId(e.target.value as string);
              }}
              value={formFcId}
            >
              {fcOptions.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FCFormControl>
          <PackDatePicker
            autoOk
            disabled={status === LoadingStatus.LOADING}
            disableToolbar
            format="MM/DD/YYYY"
            inputVariant="outlined"
            label="Pack Date"
            onChange={(date) => handleDateChange(date)}
            onError={(error) => setDateValidated(!error)}
            value={selectedDate}
          />
          <PriceZoneFormControl variant="outlined">
            <InputLabel id="price-zone-select-label">Price Zone</InputLabel>
            <Select
              disabled={status === LoadingStatus.LOADING}
              label="Price Zone"
              labelId="price-zone-select-label"
              onChange={(e) => {
                setFormPriceZoneId(e.target.value as string);
              }}
              value={formPriceZoneId}
            >
              {priceZoneOptions.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </PriceZoneFormControl>
          <ButtonContainer>
            <PrimaryButton
              disabled={
                !formFcId ||
                !dateValidated ||
                !formPriceZoneId ||
                status === LoadingStatus.LOADING
              }
              size="small"
              type="submit"
              variant="contained"
              $loading={status === LoadingStatus.LOADING}
            >
              {status === LoadingStatus.LOADING && (
                <>
                  <StyledLoopIcon /> Loading...
                </>
              )}
              {status !== LoadingStatus.LOADING && "View catalog"}
            </PrimaryButton>
            <SecondaryButton
              disabled={status === LoadingStatus.LOADING}
              onClick={handleReset}
              size="small"
              variant="outlined"
            >
              Reset
            </SecondaryButton>
          </ButtonContainer>
        </CatalogPreviewForm>
      </DialogContent>
    </Dialog>
  );
};

export default CatalogPreviewToolDialog;

const DialogContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(480)};
  padding: ${rem(24)};
  border-radius: ${rem(8)};
  background-color: ${({ theme }) => theme.colors.marshmallow};
  box-shadow: ${({ theme }) => theme.boxShadows.card};

  ${breakpoints.md`
    align-items: flex-start;
  `}
`;

const ClearIconButton = styled(IconButton)`
  align-self: flex-end;
  padding: 0;
  border-radius: 0;

  &:hover {
    background-color: unset;
  }

  &:focus {
    outline: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focusWithOutline};
  }
`;

const Overline = styled(Overline4)`
  text-align: center;

  ${breakpoints.md`
    text-align: left;
  `}
`;

const Header = styled(Typography)`
  margin-top: ${rem(4)};
  text-align: center;

  ${breakpoints.md`
    text-align: left;
  `}
`;

const Subcopy = styled(Typography)`
  margin-top: ${rem(12)};
  text-align: center;

  ${breakpoints.md`
    text-align: left;
  `}
`;

const CatalogPreviewForm = styled.form`
  width: 100%;
`;

const FCFormControl = styled(FormControl)`
  width: 100%;
  margin-top: ${rem(16)};
`;

const PackDatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  margin: 0;
  margin-top: ${rem(12)};
`;

const PriceZoneFormControl = styled(FormControl)`
  width: 100%;
  margin-top: ${rem(12)};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${breakpoints.md`
    align-self: flex-end;
    flex-direction: row-reverse;
    margin-top: ${rem(32)};
  `}
`;

const PrimaryButton = styled(Button)<{ $loading: boolean }>`
  width: 100%;
  margin-top: ${rem(32)};
  white-space: pre-wrap;

  ${breakpoints.md`
    width: auto;
    margin-top: 0;
    margin-left: ${rem(8)};
  `}

  &.Mui-disabled {
    ${({ $loading, theme }) =>
      $loading &&
      `
      background-color: ${theme.colors.eggplant};
    `}
  }
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoopIcon = styled(LoopIcon)`
  animation-name: ${rotateAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const SecondaryButton = styled(Button)`
  width: 100%;
  margin-top: ${rem(16)};

  ${breakpoints.md`
    width: auto;
    margin-top: 0;
  `}
`;
