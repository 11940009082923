import React from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps } from "react-router-dom";

import { config } from "app/config";
import useScrollWithOffset from "app/router/hooks/useScrollWithOffset";
import useChatWidget from "app/router/hooks/useChatWidget";
import usePageChanged from "./hooks/usePageChanged";
import { getRouteMetadata } from "app/selectors";
import ModifyHead from "app/ui/global/ModifyHead";
import withErrorBoundary from "app/ui/shared/withErrorBoundary";
import { Color } from "app/styles/colors";
import GlobalBodyBackground from "app/ui/global/GlobalBodyBackground";
import { Route as RouteType } from "app/types/router/Route";

const DEFAULT_TITLE = config.get("document_data.title_default");
const DEFAULT_KEYWORDS = config.get("document_data.keywords");
const DEFAULT_DESCRIPTION = config.get("document_data.description_default");

export interface SmartRouteProps extends RouteProps {
  shouldUseScrollWithOffset?: boolean;
  bgColor?: Color;
  // Used by RestrictedRoute so that we only emit page events when the route is validated
  valid?: boolean;
}

const extractElementId = (hash: string | undefined) =>
  hash && !hash.includes("=") && hash.substr(1, hash.length);

const SmartRoute: React.FC<SmartRouteProps> = ({
  // Should we make this be false by default and opt-in routes that use anchor tags?
  shouldUseScrollWithOffset = true,
  bgColor,
  valid = true,
  exact,
  ...props
}) => {
  const routeMetadata: RouteType | null = useSelector(getRouteMetadata);

  /*
   * Should the chat widget be displayed given the props?
   * The chat widget is now displayed by default and is opt-out per-route.
   * To exclude the chat widget from a route, add isChatWidgetEnabled: false to the entry
   * in routes.json.
   */
  const isChatWidgetEnabled =
    (routeMetadata && routeMetadata.isChatWidgetEnabled) || false;
  useChatWidget(isChatWidgetEnabled);

  // Enable page changed events when route is exact and valid
  // exact is required so that we emit one event for a nested route
  const enabled = (valid && exact) || false;
  usePageChanged(routeMetadata, enabled);

  /*
   * We may be able to remove this?
   * Based on github, it was needed to support scroll-to-hash
   * when we moved our about pages from static pages to Prismic
   * Supposedly there's anchor tags on the privacy page but I don't even see them any longer?
   * https://github.com/imperfectproduce/website/commit/9f4e6bb98b7697746b71b4167e615c11d42bddab
   */
  const hash = props.location?.hash;
  const elementId = (shouldUseScrollWithOffset && extractElementId(hash)) || "";
  useScrollWithOffset(elementId);

  if (routeMetadata && (props.component || props.render)) {
    const { component: Component, render, ...routeProps } = props;
    const {
      url,
      title = DEFAULT_TITLE,
      keywords = DEFAULT_KEYWORDS,
      description = DEFAULT_DESCRIPTION,
    } = routeMetadata;

    const renderComponent = (renderProps: RouteProps) => {
      // @ts-ignore
      return <Component {...renderProps} title={title} />;
    };

    const renderFunction = (renderProps: RouteProps) => {
      // @ts-ignore
      return render && render({ ...renderProps, ...routeMetadata });
    };

    return (
      <>
        {bgColor && <GlobalBodyBackground bgColor={bgColor} />}
        <ModifyHead
          title={title}
          description={description}
          keywords={keywords}
        />
        <Route
          key={url || "default"}
          {...routeProps}
          render={props.component ? renderComponent : renderFunction}
        />
      </>
    );
  }
  return <Route {...props} />;
};

// @ts-ignore
export default withErrorBoundary(SmartRoute);
