import { call } from "typed-redux-saga";

import { viewPage } from "app/api/segment";
import routes from "app/router/routes.json";
import { trackAlreadyLoggedInUser } from "app/sagas/analytics";
import { waitForInitialization } from "app/sagas/waitForInitialization";

import RouteChangedAction from "app/types/router/RouteChangedAction";

/*
 * Emit a page event when the route changes
 *
 * https://segment.com/docs/sources/website/analytics.js/#page
 */
function* triagePageChangedFlow(action: RouteChangedAction) {
  // Wait for initialization to complete
  yield call(waitForInitialization);

  const { location, routeMetadata, normalizedPath } = action;

  // If the route stays the same, don't track a new page view
  const { query, state } = location;

  const pageName = (routeMetadata && routeMetadata.page) || "Unknown";

  const data = {
    ...query,
    // @ts-ignore
    ...state,
  };

  const isOnAccount = normalizedPath === routes.account.url;

  yield call(viewPage, pageName, data);

  if (isOnAccount) {
    // NLF: why are we doing this?
    yield call(trackAlreadyLoggedInUser);
  }
}

export default triagePageChangedFlow;
