import { IconName } from "@fortawesome/fontawesome-svg-core";

export enum HeaderAlertBannerColor {
  alert = "#F5C6CB",
  warning = "#FEF2A5",
  update = "#DBF5C6",
  urgent = "#9E3B3A",
}

export type HeaderAlertType = keyof typeof HeaderAlertBannerColor;

interface HeaderBannerConfig {
  icon?: IconName;
  message?: string;
  secondMessage?: string;
  link?: string;
  backgroundColor?: HeaderAlertType;
  shoppingOnly?: boolean;
}

export default HeaderBannerConfig;
