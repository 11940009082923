import React from "react";
import { rem } from "polished";
import MUITooltip, {
  TooltipProps as MUITooltipProps,
} from "@material-ui/core/Tooltip";
import { styled } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import colors from "app/styles/colors";

type OmittedMUITooltipProps = Omit<MUITooltipProps, "title">;

export interface TooltipProps extends OmittedMUITooltipProps {
  labelText: MUITooltipProps["title"];
  light?: boolean;
}

const StyledTooltip = styled(({ labelText, className, ...props }) => {
  return (
    <MUITooltip
      placement="bottom"
      TransitionComponent={Fade}
      title={labelText}
      {...props}
      classes={{ popper: className }}
    />
  );
})(
  (props: TooltipProps) =>
    props.light
      ? {
          "& .MuiTooltip-tooltip": {
            color: colors.textBlack,
            background: "transparent",
            borderRadius: rem(4),
            padding: 0,
          },
        }
      : {},
  {
    name: "MuiTooltip",
  }
);

export default StyledTooltip;
