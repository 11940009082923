import React from "react";
import styled from "styled-components";

const CustomizeToastContainer: React.FC<CustomizeToastContainerProps> = ({
  children,
}) => {
  return (
    <StyledCustomizeToastContainer>{children}</StyledCustomizeToastContainer>
  );
};

interface CustomizeToastContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const StyledCustomizeToastContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.layout.zIndex.toast};
  bottom: 0;
`;

export default CustomizeToastContainer;
