import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { addProductToNeverList } from "app/reducers/account";
import grid from "app/styles/grid";
import Offering from "app/types/state/offerings/Offering";
import { StyledButton } from "app/ui/shared/Button";
import { useDispatch } from "react-redux";

export interface RemovedCartItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  offering: Offering;
}

const RemovedCartItem: React.FC<RemovedCartItemProps> = ({ offering }) => {
  const dispatch = useDispatch();

  const handleAddProductToNeverList = () => {
    dispatch(addProductToNeverList(offering.productId, "cart", true));
  };

  return (
    <StyledRemovedCartItem>
      <RemovedOfferingName>{offering.name}</RemovedOfferingName>
      <AddToNeverListButton
        intent="secondary"
        size="small"
        onClick={handleAddProductToNeverList}
      >
        Never
      </AddToNeverListButton>
    </StyledRemovedCartItem>
  );
};

const StyledRemovedCartItem = styled.div`
  ${grid.row};
  align-items: center;
  border-top: ${({ theme }) => theme.borders.dark};
`;

const RemovedOfferingName = styled.div`
  ${grid.row};
  ${grid.cell.fit};
  color: ${({ theme }) => theme.colors.gray};
  flex-direction: column;
  font-size: ${rem(13)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  justify-content: center;
  margin-top: ${rem(2)};
  min-height: ${rem(44)};
  overflow: hidden;
  padding: ${rem(8)} ${rem(12)} ${rem(8)} 0;
  text-transform: none;
`;

const AddToNeverListButton = styled(StyledButton)`
  border-radius: ${rem(5)};
  font-size: ${rem(13)};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  max-width: ${rem(100)};
`;

export default RemovedCartItem;
