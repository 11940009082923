import { createSelector } from "reselect";

import * as fromAuth from "app/selectors/auth";
import * as fromAccount from "app/selectors/account";
import * as fromFriendbuy from "app/selectors/friendbuy";
import {
  APIType,
  LegacyReferralAttributes,
  ReferralAttributes,
} from "app/types/state/friendbuy";
import State from "app/types/state";
import { isValidLegacyReferral } from "app/utils/transformations/getReferralFromVisitorStatusLegacy";
import { isValidReferral } from "app/utils/transformations/getReferralFromVisitorStatus";

export const isFriendbuyWidgetsReady = createSelector(
  [fromFriendbuy.isFriendbuyInitialized],
  (initialized) => initialized
);

// Used by shared/waitForFriendbuyReady saga to determine whether the inferred advocate apiType
// to be initialized
export const getAdvocateAPIType = (state: State): APIType | null => {
  const loaded = fromFriendbuy.isFriendbuyAPITypeLoaded(state, {
    apiType: APIType.NEXTGEN,
  });

  return loaded ? APIType.NEXTGEN : APIType.NONE;
};

export const getFriendReferralAPIType = createSelector(
  [
    fromAuth.isLoggedIn,
    fromAccount.isUserProspect,
    fromAccount.getAccountReferringUserReferralCode,
    fromFriendbuy.getFriendbuyActiveAPIType,
    fromFriendbuy.getLegacyReferral,
    fromFriendbuy.getCapturedReferral,
  ],
  (
    loggedIn,
    isProspect,
    existingReferralCode,
    activeAPIType,
    legacyReferral,
    capturedReferral
  ) => {
    let apiType: APIType | null = null;
    // Referral is not applicable to logged-out users or non-prospects

    if (loggedIn && isProspect) {
      const hasLegacyReferral = isValidLegacyReferral(
        <LegacyReferralAttributes>legacyReferral
      );
      const hasCapturedReferral = isValidReferral(
        <ReferralAttributes>capturedReferral
      );

      // If we have a valid legacy and next-gen referral, use split to decide which one wins
      // activeAPIType is determined by split when friendbuy initialized action is emitted
      if (
        hasLegacyReferral &&
        hasCapturedReferral &&
        activeAPIType !== APIType.NONE
      ) {
        apiType = activeAPIType;
      } else if (hasLegacyReferral) {
        apiType = APIType.FIRSTGEN;
      } else if (hasCapturedReferral) {
        apiType = APIType.NEXTGEN;
      }

      // If a user already has a referralCode (legacy) on their account, we will ignore current referral
      if (apiType === APIType.FIRSTGEN && existingReferralCode) {
        return null;
      }
    }

    return apiType;
  }
);

export const getReferralCouponCode = (state: State) => {
  const apiType = getFriendReferralAPIType(state);
  if (apiType) {
    const referralData = fromFriendbuy.getReferralDataForType(state, {
      apiType,
    });
    return referralData?.referralCouponCode || null;
  }
  return null;
};

/*
 * We can apply a legacy referral for a period of time and then the referral should be applied
 * as a deferred coupon as soon as possible.
 * We should only be able to apply a referral if:
 * 1) User is logged out (not yet created a user) OR is stage prospect AND
 * 2) User does not already have a referring user referral code AND
 * 3) User is not attempting to self referral AND
 * 4) Friendbuy referral attribution has been captured
 */
export const canApplyLegacyReferral = (state: State) => {
  return getFriendReferralAPIType(state) === APIType.FIRSTGEN;
};

/*
 * We can apply a next-gen friendbuy referral if
 * 1. User is logged in and is prospect
 * 2. Referral has been captured w/ campaignId and referralCode
 *
 * NC-1496 The referral will be applied at the end of signup
 */
export const canApplyNextGenReferral = (state: State) => {
  return getFriendReferralAPIType(state) === APIType.NEXTGEN;
};

// Can any referral be applied?
// Used by existing getSignupCoupon selector, used throughout the code
export const canApplyReferral = (state: State) => {
  return canApplyLegacyReferral(state) || canApplyNextGenReferral(state);
};

// Do we need to apply the next-gen coupon?
// The referralCoupon should override any existing deferredCoupon
export const canApplyNextGenCoupon = (state: State) => {
  const referralValid = canApplyNextGenReferral(state);
  const referralCoupon = getReferralCouponCode(state);
  const deferredCoupon = fromAccount.getUserDeferredCoupon(state);
  return referralValid && referralCoupon && deferredCoupon !== referralCoupon;
};
