import moment from "moment-timezone";
import { createSelector } from "reselect";

import { config } from "app/config";
import {
  cartFilters,
  FILTERS,
  categoryIds,
  tagIds,
} from "app/reducers/offerings";
import {
  getActiveOrderMetadata,
  getActiveOrder,
  getMapOfVariants,
  getNextDelivery,
  getSkippedActiveOrder,
  getSkippedNextOrder,
  canUserCustomize,
  getUserNeverList,
  getFutureOrders,
} from "app/selectors";
import * as fromCDAccount from "app/selectors/crossDomain/account";
import State from "app/types/state";
import Offering from "app/types/state/offerings/Offering";
import { FutureOrderFormatted } from "app/types/state/orders/FutureOrder";
import Order, {
  OrderLineItemCategory,
  OrderLineItemTag,
} from "app/types/state/orders/Order";
import { LONG_DATE_FORMAT } from "app/ui/global/utils";

const holidayAlertVisible = config.get("alerts.holiday_alert_visible");

/*
An active order is the one the customer would expect to see as main order to interact with
It does not mean the same thing as the order service's definition
because the order may not be created yet.
*/
export const getActiveOrderWithDeliveryInfo = createSelector(
  [
    getActiveOrder, // empty object if none exists
    getNextDelivery,
    getSkippedActiveOrder,
    getSkippedNextOrder,
    fromCDAccount.getCurrentBoxDetails,
    canUserCustomize,
  ],
  (
    activeOrder,
    nextDelivery,
    skippedActiveOrder,
    skippedNextOrder,
    boxDetails,
    canCustomize
  ) => {
    const nextDeliveryDate = nextDelivery?.deliveryDate;

    const deliveryDate =
      (activeOrder && activeOrder.deliveryDate) ||
      // active order is skipped
      (skippedActiveOrder && skippedActiveOrder.deliveryDate) ||
      // next future order is skipped but not generated
      (skippedNextOrder && skippedNextOrder.deliveryDate) ||
      // fallback to the next subscription delivery date
      nextDeliveryDate;

    const canSkip = canCustomize && !skippedActiveOrder;
    const canUnskip = skippedActiveOrder
      ? skippedActiveOrder.canUnskip
      : !!skippedNextOrder;
    const showActiveOrderInList = (canCustomize && canSkip) || canUnskip;

    const donationPartner =
      nextDelivery?.donationPartners?.length &&
      nextDelivery?.donationPartners[0];

    return {
      ...activeOrder,
      ...boxDetails,
      deliveryDate,
      isActiveOrder: nextDelivery?.isActiveOrder || false, // only used for hold messaging logic
      deliveryDateFormatted: deliveryDate
        ? moment(deliveryDate).format(LONG_DATE_FORMAT)
        : null,
      canCustomize,
      canSkip,
      canUnskip,
      showActiveOrderInList,
      // Include metadata from next-delivery response if is active order
      ...(nextDelivery?.isActiveOrder && {
        // if isActive order false nextDelivery represents the next subscription projected future order
        originalDeliveryDate: nextDelivery?.originalDeliveryDate,
        canDonate: nextDelivery?.canDonate && !!donationPartner,
        donated: nextDelivery?.donated,
        donationPartner,
      }),
    };
  }
);

export const getActiveOrderExpanded = createSelector(
  [getActiveOrderWithDeliveryInfo, getActiveOrderMetadata],
  (order, metadata) => {
    // Return a map of filters -> variantIds for offerings (without shape)
    // Roll in the rest of the getActiveOrderExpanded logic

    const ORGANIC = tagIds[FILTERS.ORGANIC];
    const CONVENTIONAL = tagIds[FILTERS.CONVENTIONAL];
    const NON_PRODUCE = categoryIds[FILTERS.NON_PRODUCE];

    const mapOfFilteredLineItems = (order.lineItems || []).reduce(
      (acc: Record<string, string[]>, variantId: string) => {
        const lineItem = order.mapOfLineItems[variantId];
        if (!lineItem || !lineItem.quantity) return acc;
        const { tags = [], categories = [] } = lineItem;

        const tagSet = new Set(tags.map((t: OrderLineItemTag) => t.tagId));
        const catSet = new Set(
          categories.map((c: OrderLineItemCategory) => c.categoryId)
        );

        if (!catSet.has(NON_PRODUCE) && tagSet.has(ORGANIC)) {
          acc[FILTERS.ORGANIC].push(variantId);
        } else if (!catSet.has(NON_PRODUCE) && tagSet.has(CONVENTIONAL)) {
          acc[FILTERS.CONVENTIONAL].push(variantId);
        } else if (catSet.has(NON_PRODUCE)) {
          acc[FILTERS.NON_PRODUCE].push(variantId);
        } else {
          acc[FILTERS.UNCATEGORIZED].push(variantId);
        }

        // TODO: What about "Uncategorized" items?

        return acc;
      },
      {
        [FILTERS.ORGANIC]: [],
        [FILTERS.CONVENTIONAL]: [],
        [FILTERS.NON_PRODUCE]: [],
        [FILTERS.UNCATEGORIZED]: [],
      } as Record<string, string[]>
    );

    const {
      subtotal,
      deliveryFeeAfterDiscount,
      orderCredits,
      subtotalDiscount,
    } = order;

    return {
      ...order,
      subtotal: (order.subtotal || 0).toFixed(2),
      subtotalDiscount: (subtotalDiscount || 0).toFixed(2),
      deliveryFeeAfterDiscount: (order.deliveryFeeAfterDiscount || 0).toFixed(
        2
      ),
      tax: (order.tax || 0).toFixed(2),
      orderCredits: (orderCredits || 0).toFixed(2),
      subTotalWithDelivery: (
        subtotal +
        deliveryFeeAfterDiscount +
        order.tax
      ).toFixed(2),
      windowDeliveryFee: (order.windowDeliveryFee || 0).toFixed(2),
      total: (order.total || 0).toFixed(2),
      totalAfterCredits: (order.totalAfterCredits || 0).toFixed(2),
      lineItemSaleSavings: (order.lineItemSaleSavings || 0).toFixed(2),
      mapOfFilteredLineItems,
      cartFilters,
      loading: metadata.loading,
      updating: metadata.updating,
    };
  }
);

export const getRemovedDefaultOfferings = createSelector(
  [getActiveOrderWithDeliveryInfo, getMapOfVariants, getUserNeverList],
  (order, variants, neverList) => {
    const offerings = Object.values(variants) as Offering[];
    return (order.removedDefaultProductIds || []).reduce(
      (acc: Offering[], productId: string) => {
        // NOTE: OOS items will not appear as eligible for Never List
        const offering = offerings.find((v) => v.productId === productId);
        if (offering && !neverList.includes(productId)) {
          acc.push(offering);
        }
        return acc;
      },
      []
    );
  }
);

export const getOriginalOrderItem = createSelector(
  [
    getActiveOrderMetadata,
    getActiveOrder,
    (_: State, props: { optimisticId: string; variantId: string }) => props,
  ],
  (metadata, activeOrder, { optimisticId, variantId }) => {
    const orderBeforeUpdate = metadata.rollbackVersions[optimisticId];

    if (!orderBeforeUpdate) {
      return activeOrder.mapOfLineItems[variantId] || null;
    }

    return orderBeforeUpdate.mapOfLineItems[variantId];
  }
);

export const getOriginalOrderAdhocLineByVariantId = createSelector(
  [
    getActiveOrderMetadata,
    (_: State, props: { optimisticId: string; variantId: string }) => props,
  ],
  (metadata, { optimisticId, variantId }) => {
    const orderBeforeUpdate = metadata.rollbackVersions[optimisticId];

    // Defensively check against missing data
    if (!variantId || !orderBeforeUpdate || !orderBeforeUpdate.adhocLines)
      return null;

    return orderBeforeUpdate.adhocLines.find(
      (line) => line.variantId === variantId
    );
  }
);

export const getActiveOrderVariantQty = createSelector(
  [getActiveOrder, (_: State, props: { variantId: string }) => props],
  (order, { variantId }) => {
    const variantLineItem = order.mapOfLineItems[variantId];

    return variantLineItem ? variantLineItem.quantity : 0;
  }
);

export const getNumberOfItemsInActiveOrder = createSelector(
  [getActiveOrderExpanded],
  ({ lineItems, mapOfLineItems }) => {
    if (!lineItems || !lineItems.length) return 0;
    return Object.values(mapOfLineItems as Order["mapOfLineItems"]).reduce(
      (numLineItems, lineItem) => {
        const qty = lineItem.quantity || 0;
        return numLineItems + qty;
      },
      0
    );
  }
);

// adding an item, and then removing it, and then adding another item, will NOT show the nudge (when product would expect it)
// Add to known limitation with LOE
export const getNumItemsAddedByUser = createSelector(
  [getActiveOrderExpanded],
  ({ lineItems, defaultLineItems }) => {
    if (!lineItems || !lineItems.length) return 0;
    return lineItems.length - defaultLineItems.length;
  }
);

// The product ask is to not show the modal until after the user has added one item to their cart
export const shouldShowRecurringItemOffer = createSelector(
  [getNumItemsAddedByUser],
  (numItemsAddedByUser) => numItemsAddedByUser > 0
);

export const getSubtotalMinInActiveOrder = createSelector(
  [getActiveOrder],
  (order) => {
    return order.subtotalMin;
  }
);

export const getSubtotalMinInActiveOrderFormatted = createSelector(
  [getSubtotalMinInActiveOrder],
  (subtotalMin) => {
    if (!subtotalMin) return null;
    return subtotalMin.toFixed(0);
  }
);

export const getSubtotalInActiveOrder = createSelector(
  [getActiveOrder],
  (order) => {
    return order.subtotal;
  }
);

export const getRemainingUntilSubtotalMinReached = createSelector(
  [getSubtotalMinInActiveOrder, getSubtotalInActiveOrder],
  (subtotalMin, subtotal) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return (subtotalMin! - subtotal).toFixed(2);
  }
);

export const hasReachedMinimum = createSelector(
  [getRemainingUntilSubtotalMinReached],
  (remaining) => {
    // @ts-ignore - TODO: refactor `remaining` (return value from statement above) is string and used in numeric comparison
    return remaining <= 0;
  }
);

export const getFutureOrdersFormatted = createSelector(
  [getFutureOrders, getActiveOrderWithDeliveryInfo],
  (orders, activeOrder) => {
    return orders.reduce((acc, order) => {
      // double check active order doesnt bleed into future (there are edge cases)
      if (
        order.deliveryDate === activeOrder.deliveryDate &&
        activeOrder.showActiveOrderInList
      ) {
        return acc;
      }
      const deliveryDate = moment(order.deliveryDate);

      let deliveryDateFormatted;

      if (holidayAlertVisible) {
        const start = deliveryDate.clone().startOf("week");
        const end = deliveryDate.clone().endOf("week");
        const startDay = start.format("MMM D");
        const endDay = start.isSame(end, "month")
          ? end.format("D")
          : end.format("MMM D");
        deliveryDateFormatted = `Week of ${startDay}-${endDay}`;
      } else {
        deliveryDateFormatted = deliveryDate.clone().format(LONG_DATE_FORMAT);
      }

      const originalDeliveryDateFormatted = order.windowIsReplacement
        ? moment(order.originalDeliveryDate).format(LONG_DATE_FORMAT)
        : null;

      const donationPartner = (order.donationPartners || [])[0];

      const customizationStartAsMoment = moment.tz(
        `${order.deliveryWindow.customizationStartDate} ${order.deliveryWindow.customizationStartTime}`,
        order.deliveryWindow.timezone || ""
      );
      const customizationEndAsMoment = moment.tz(
        `${order.deliveryWindow.customizationEndDate} ${order.deliveryWindow.customizationEndTime}`,
        order.deliveryWindow.timezone || ""
      );

      acc.push({
        ...order,
        canDonate: order.canDonate && !!donationPartner,
        deliveryDateFormatted,
        originalDeliveryDateFormatted,
        donationPartner,
        customizationMessageFormatted: `Shop ${customizationStartAsMoment.format(
          "ha z ddd"
        )} - ${customizationEndAsMoment.format("ha z ddd")}`,
      });

      return acc;
    }, [] as FutureOrderFormatted[]);
  }
);

export const getNextSkippableOrder = createSelector(
  [getActiveOrderWithDeliveryInfo, getFutureOrders],
  (activeOrder, futureOrders) => {
    if (activeOrder.canSkip) {
      return {
        order: activeOrder,
        isActive: true,
      };
    }

    const matchingOrder = futureOrders.find((order) => {
      return order.skipped === false;
    });

    return {
      order: matchingOrder,
      isActive: false,
    };
  }
);
