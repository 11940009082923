// @ts-nocheck
import PropTypes from "prop-types";
import React from "react";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";

import { fetchCMSMenuByUID } from "app/reducers/cms";
import { getCMSMenuByUID } from "app/selectors";
import breakpoints, { sizes } from "app/styles/breakpoints";
import { getPrismicLink } from "app/ui/cms/contentPage_DEPRECATED/utils_DEPRECATED";
import Link from "app/ui/shared/Link";
import connected from "connected";

const FOOTER_NAV_UID = "footer-nav";

const FooterNav = ({ menuContent }) => {
  if (
    menuContent &&
    menuContent.data &&
    menuContent.data.nav &&
    menuContent.data.nav.length > 0
  ) {
    return (
      <>
        <StyledFooterNav aria-label="Footer">
          <ul>
            {menuContent.data.nav.map(({ items }) => {
              return items
                .filter(({ link }) => link.link_type !== "Any") // 'Any' = no link specified
                .map(({ label, link }) => {
                  const parsedLink = getPrismicLink(link);
                  return (
                    <li key={`${label}-${parsedLink}`}>
                      <Link {...parsedLink}>{label}</Link>
                    </li>
                  );
                });
            })}
          </ul>
        </StyledFooterNav>
      </>
    );
  }
  // TODO: Loading? 404 when fetching from Prismic?
  return null;
};

FooterNav.propTypes = {
  menuContent: PropTypes.object,
};

const StyledFooterNav = styled.nav`
  max-width: ${sizes.xxl};
  margin: 0 auto;
  width: 100%;
  text-align: center;
  li {
    margin-bottom: 0.625em;

    /* To do - update to be more dynamically calculated than 15% for each? */
    ${breakpoints.lg`
      display: inline-block;
      width: 15%;
    `}
  }
`;

const mapStateToProps = (state) => ({
  menuContent: getCMSMenuByUID(state, { uid: FOOTER_NAV_UID }),
});

const enhance = compose(
  connected(mapStateToProps, { fetchCMSMenuByUID }),
  lifecycle({
    componentDidMount() {
      this.props.actions.fetchCMSMenuByUID(FOOTER_NAV_UID);
    },
  })
);

export default enhance(FooterNav);
