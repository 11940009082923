// @ts-nocheck
import React from "react";

import Checkmark from "app/images/Checkmark.svg";

const MyTastePreferencesToast = () => (
  <>
    <Checkmark />
    <p>
      <span>Got it!</span> We won&apos;t ask you again.
    </p>
  </>
);

export default MyTastePreferencesToast;
