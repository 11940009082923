// @ts-nocheck
import { call } from "redux-saga/effects";

import request from "./request";

import { config } from "app/config";

const baseUrl = config.get("endpoints.gateway");

export default function* fetchSmartShelfData({ endpoint, userId }) {
  const url = `${baseUrl}/${endpoint.replace("{userId}", userId)}`;
  return yield call(request, url, {}, "json");
}
