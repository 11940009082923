import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import AUTH_ACTION_TYPES from "app/actionTypes/auth";
import ACTION_TYPES from "app/actionTypes/ui";
import REACTIVATION_ACTION_TYPES from "app/actionTypes/reactivation";
import { config } from "app/config";
import { RECURRING_ITEM_MODAL_ID } from "app/constants/modals";
import UIState, {
  CatalogPreviewToolData,
  DeviceType,
} from "app/types/state/ui";
import AisleClicked from "app/types/ui/AisleClicked";
import AisleShelfClicked from "app/types/ui/AisleShelfClicked";
import BackToTopClicked from "app/types/ui/BackToTopClicked";
import CTAClicked from "app/types/ui/CTAClicked";
import { Dialog } from "app/types/ui/Dialog";
import Modal from "app/types/ui/Modal";
import { ShopToolbarIcons } from "app/ui/shopping/Navigation/ShopToolbar";
import { LoadingStatus } from "app/types/state";
import OfferingCardClicked from "app/types/ui/OfferingCardClicked";
import RequestFailed from "app/types/ui/RequestFailed";
import UserSearchedNoResults from "app/types/ui/UserSearchedNoResults";
import { SnackbarIds } from "app/types/ui/Snackbar";

const holidayAlertVisible = config.get("alerts.holiday_alert_visible");
const holidayAlertMessage = config.get("alerts.holiday_alert_message");
const maintenanceMode = config.get("flags.maintenance_mode");

export const initialState: UIState = {
  deviceType: "",
  customize: {
    mobileCartMessageVisible: true,
  },
  holiday: {
    alert: {
      visible: holidayAlertVisible,
      message: holidayAlertMessage,
    },
  },
  modals: {},
  dialog: null,
  snackbars: {},
  topNav: {
    visible: false,
  },
  accountSubNav: {
    visible: false,
  },
  cart: {
    visible: false,
  },
  toolbar: {
    selectedItem: ShopToolbarIcons.NONE,
  },
  maintenanceMode,
  bootstrapped: false, // bootstrapped: local storage loaded
  initialized: false, // initialized: user and order(s) fetched
  criticalRequestFailed: false,
  appFailed: false,
  shoppingInitialized: false,
  signupShoppingInitialized: false,
  plusInitialized: false, // plus price & split treatment
  recurringItemsNudgeLastPresented: 0, // timestamp set every time nudge is shown, ms in unix time
  skipNextOrderSnack: null,
  switchCadenceSnack: false,
  addRecipeIngredient: {
    showRecipeToast: false,
    numberOfIngredientsAdded: 0,
    recipeName: "",
    recipesThatAlreadyOpenedToast: [],
  },
  cadenceAutoSwitched: false,
  dontClearModal: false, // this flag will keep the current modal visible on route changes (otherwise it will be cleared.)
  singlePageCatalog: {
    scrollToHistory: {},
    navigateUrl: null,
  },
  catalogPreviewTool: {
    data: null,
    status: LoadingStatus.IDLE,
    error: null,
  },
  appUpdateRequired: {
    showWarningModal: false,
  },
  localStorageData: null,
};

export const handleModalUpdate = (state: UIState, action: Modal) => {
  const defaultReturn = {
    ...state,
    modals: {
      // Intentionally clearing other modals (should only have one visible at a time)
      [action.modalId]: {
        visible: action.visible,
        params: action.params,
      },
    },
    dontClearModal: !!action.dontClear,
  };
  // If showing the Recurring Items "nudge" modal
  if (action.modalId === RECURRING_ITEM_MODAL_ID && action.visible === true) {
    return {
      ...defaultReturn,
      recurringItemsNudgeLastPresented: Date.now(),
    };
  }
  return defaultReturn;
};

// Manually define action types where appropriate
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.BOOTSTRAP_COMPLETE: {
      return {
        ...state,
        bootstrapped: true,
      };
    }
    case ACTION_TYPES.REQUEST_FAILED: {
      const { params } = action;
      const criticalRequestFailed = params.critical
        ? true
        : state.criticalRequestFailed;
      return {
        ...state,
        criticalRequestFailed,
      };
    }
    case ACTION_TYPES.APP_FAILED: {
      return {
        ...state,
        appFailed: true,
      };
    }
    case ACTION_TYPES.INITIALIZE_SUCCEEDED:
      return {
        ...state,
        initialized: true,
      };
    case ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED:
    case ACCOUNT_ACTION_TYPES.CREATE_SUBSCRIPTION_SUCCEEDED:
    case AUTH_ACTION_TYPES.LOGIN_SUCCEEDED:
    case REACTIVATION_ACTION_TYPES.COMPLETE_REACTIVATION_SUCCEEDED: {
      return {
        ...initialState,
        deviceType: state.deviceType,
        modals: state.modals,
        bootstrapped: state.bootstrapped,
      };
    }
    case ACTION_TYPES.SHOW_TOP_NAV:
      return {
        ...state,
        topNav: {
          visible: true,
        },
        cart: {
          visible: false,
        },
      };
    case ACTION_TYPES.HIDE_TOP_NAV:
      return {
        ...state,
        topNav: {
          visible: false,
        },
      };
    case ACTION_TYPES.TOGGLE_TOP_NAV:
      return {
        ...state,
        topNav: {
          visible: !state.topNav.visible,
        },
        cart: {
          visible: state.topNav.visible && false,
        },
      };
    case ACTION_TYPES.SHOW_SNACKBAR:
    case ACTION_TYPES.HIDE_SNACKBAR:
      return {
        ...state,
        snackbars: {
          ...state.snackbars,
          [action.snackbarId]: { isVisible: action.isVisible },
        },
      };
    case ACTION_TYPES.SHOW_CART:
      return {
        ...state,
        topNav: {
          visible: false,
        },
        cart: {
          visible: true,
        },
        toolbar: {
          selectedItem: ShopToolbarIcons.CART,
        },
      };
    case ACTION_TYPES.HIDE_CART:
      return {
        ...state,
        cart: {
          visible: false,
        },
        toolbar: {
          selectedItem: ShopToolbarIcons.NONE,
        },
      };
    case ACTION_TYPES.TOGGLE_CART:
      return {
        ...state,
        topNav: {
          visible: state.cart.visible && false,
        },
        cart: {
          visible: !state.cart.visible,
        },
        toolbar: {
          selectedItem: state.cart.visible
            ? ShopToolbarIcons.NONE
            : ShopToolbarIcons.CART,
        },
      };
    case ACTION_TYPES.SHOW_ACCOUNT_SUBNAV:
      return {
        ...state,
        accountSubNav: {
          visible: true,
        },
      };
    case ACTION_TYPES.HIDE_ACCOUNT_SUBNAV:
      return {
        ...state,
        accountSubNav: {
          visible: false,
        },
      };
    case ACTION_TYPES.TOGGLE_ACCOUNT_SUBNAV:
      return {
        ...state,
        accountSubNav: {
          visible: !state.accountSubNav.visible,
        },
      };
    case ACTION_TYPES.UPDATE_MODAL:
      return handleModalUpdate(state, action as Modal);

    case ACTION_TYPES.CLEAR_MODALS:
      if (state.dontClearModal) {
        return {
          ...state,
          dontClearModal: false,
        };
      }
      return {
        ...state,
        modals: {},
      };

    case ACTION_TYPES.SHOW_CUSTOMIZE_ALERT:
      return {
        ...state,
        customize: {
          ...state.customize,
          alert: {
            ...state.customize.alert,
            visible: true,
          },
        },
      };
    case ACTION_TYPES.HIDE_CUSTOMIZE_ALERT:
      return {
        ...state,
        customize: {
          ...state.customize,
          alert: {
            ...state.customize.alert,
            visible: false,
          },
        },
      };
    case ACTION_TYPES.SHOW_HOLIDAY_ALERT:
      return {
        ...state,
        holiday: {
          ...state.holiday,
          alert: {
            ...state.holiday.alert,
            visible: true,
          },
        },
      };
    case ACTION_TYPES.HIDE_HOLIDAY_ALERT:
      return {
        ...state,
        holiday: {
          ...state.holiday,
          alert: {
            ...state.holiday.alert,
            visible: false,
          },
        },
      };
    case ACTION_TYPES.HIDE_MOBILE_CART_MESSAGE:
      return {
        ...state,
        customize: {
          ...state.customize,
          mobileCartMessageVisible: false,
        },
      };
    case ACTION_TYPES.INITIALIZE_SHOPPING_SUCCEEDED:
      return {
        ...state,
        shoppingInitialized: true,
      };
    case ACTION_TYPES.INITIALIZE_SIGNUP_SHOPPING_SUCCEEDED:
      return {
        ...state,
        signupShoppingInitialized: true,
      };
    case ACTION_TYPES.INITIALIZE_PLUS_SUCCEEDED:
      return {
        ...state,
        plusInitialized: true,
      };
    case ACTION_TYPES.SET_DEVICE_TYPE:
      return {
        ...state,
        deviceType: action.deviceType,
      };
    case ACTION_TYPES.RESET_SHOPPING:
      return {
        ...state,
        shoppingInitialized: false,
      };
    case ACTION_TYPES.SET_SKIP_NEXT_ORDER_SNACK: {
      return {
        ...state,
        skipNextOrderSnack: action.value,
      };
    }
    case ACTION_TYPES.SET_SWITCH_CADENCE_SNACK: {
      return {
        ...state,
        switchCadenceSnack: action.value,
      };
    }
    case ACTION_TYPES.SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS: {
      const newRecipesThatAlreadyOpenedToast = [
        ...state.addRecipeIngredient.recipesThatAlreadyOpenedToast,
      ];
      let shouldShowRecipeToast = action.showRecipeToast;
      if (newRecipesThatAlreadyOpenedToast.indexOf(action.shelfId) === -1) {
        newRecipesThatAlreadyOpenedToast.push(action.shelfId);
      } else {
        shouldShowRecipeToast = false;
      }
      return {
        ...state,
        addRecipeIngredient: {
          showRecipeToast: shouldShowRecipeToast,
          numberOfIngredientsAdded: action.products
            ? action.products.length
            : state.addRecipeIngredient.numberOfIngredientsAdded,
          recipeName: action.recipeName
            ? action.recipeName
            : state.addRecipeIngredient.recipeName,
          recipesThatAlreadyOpenedToast: newRecipesThatAlreadyOpenedToast,
        },
      };
    }
    case ACTION_TYPES.SET_CADENCE_AUTO_SWITCHED: {
      return {
        ...state,
        cadenceAutoSwitched: action.value,
      };
    }
    case ACTION_TYPES.SET_OR_TOGGLE_TOOLBAR_ITEM: {
      return {
        ...state,
        toolbar: {
          selectedItem:
            state.toolbar.selectedItem === action.item
              ? ShopToolbarIcons.NONE
              : action.item,
        },
      };
    }
    case ACTION_TYPES.CLEAR_CATALOG_SCROLL_TO_HISTORY: {
      return {
        ...state,
        singlePageCatalog: {
          ...state.singlePageCatalog,
          scrollToHistory: {},
        },
      };
    }
    case ACTION_TYPES.SET_CATALOG_SCROLL_TO_HISTORY: {
      const { key, offset } = action;
      return {
        ...state,
        singlePageCatalog: {
          ...state.singlePageCatalog,
          scrollToHistory: {
            ...state.singlePageCatalog.scrollToHistory,
            [key]: offset,
          },
        },
      };
    }
    case ACTION_TYPES.SET_CATALOG_NAVIGATE_URL: {
      return {
        ...state,
        singlePageCatalog: {
          ...state.singlePageCatalog,
          navigateUrl: action.navigateUrl,
        },
      };
    }
    case ACTION_TYPES.CLEAR_DIALOG: {
      return {
        ...state,
        dialog: null,
      };
    }
    case ACTION_TYPES.SET_DIALOG: {
      const { dialog } = action;
      return {
        ...state,
        dialog,
      };
    }
    case ACTION_TYPES.CLEAR_CATALOG_PREVIEW_TOOL: {
      return {
        ...state,
        catalogPreviewTool: {
          data: null,
          status: LoadingStatus.IDLE,
          error: null,
        },
      };
    }
    case ACTION_TYPES.SET_CATALOG_PREVIEW_TOOL: {
      const { data } = action;
      return {
        ...state,
        catalogPreviewTool: {
          data,
          status: LoadingStatus.LOADING,
          error: null,
        },
      };
    }
    case ACTION_TYPES.INITIALIZE_CATALOG_PREVIEW_TOOL_SUCCEEDED: {
      return {
        ...state,
        catalogPreviewTool: {
          ...state.catalogPreviewTool,
          status: LoadingStatus.SUCCEEDED,
        },
        shoppingInitialized: true,
      };
    }
    case ACTION_TYPES.INITIALIZE_CATALOG_PREVIEW_TOOL_FAILED: {
      const { error } = action;
      return {
        ...state,
        catalogPreviewTool: {
          ...state.catalogPreviewTool,
          status: LoadingStatus.FAILED,
          error,
        },
        shoppingInitialized: true,
      };
    }

    case ACTION_TYPES.MOBILE_APP_SHOW_UPDATE_MODAL: {
      return {
        ...state,
        appUpdateRequired: {
          showWarningModal: true,
        },
      };
    }

    case ACTION_TYPES.MOBILE_APP_HIDE_UPDATE_MODAL: {
      return {
        ...state,
        appUpdateRequired: {
          showWarningModal: false,
        },
      };
    }

    case ACTION_TYPES.UPDATE_LOCAL_STORAGE: {
      const { key, data } = action;
      localStorage.setItem(key, data);

      return {
        ...state,
        localStorageData: {
          ...state.localStorageData,
          [key]: data,
        },
      };
    }

    default:
      return state;
  }
}

export function getBootstrapped(state: UIState) {
  return state.bootstrapped;
}

export function getInitialized(state: UIState) {
  return state.initialized;
}

export function getCriticalRequestFailed(state: UIState) {
  return state.criticalRequestFailed;
}

export function getAppFailed(state: UIState) {
  return state.appFailed;
}

export function isMobileCartMessageVisible(state: UIState) {
  return state.customize.mobileCartMessageVisible;
}

export function isHolidayAlertVisible(state: UIState) {
  return state.holiday.alert.visible;
}

export function getHolidayAlertMessage(state: UIState) {
  return state.holiday.alert.message;
}

export function getDialog(state: UIState) {
  return state.dialog;
}

export function getSnackbars(state: UIState) {
  return state.snackbars;
}

export function getModals(state: UIState) {
  return state.modals;
}

export function getTopNav(state: UIState) {
  return state.topNav;
}

export function getCart(state: UIState) {
  return state.cart;
}

export function getAccountSubNav(state: UIState) {
  return state.accountSubNav;
}

export function isShoppingInitialized(state: UIState) {
  return state.shoppingInitialized;
}

// TODO: NC-1244 productionalize signup shopping
export function isSignupShoppingInitialized(state: UIState) {
  return state.signupShoppingInitialized;
}

export function getRecurringItemsTimeSinceLastNudge(state: UIState) {
  return state.recurringItemsNudgeLastPresented;
}

export function isPlusInitialized(state: UIState) {
  return state.plusInitialized;
}

export function getDeviceType(state: UIState) {
  return state.deviceType;
}

export function getMaintenanceMode(state: UIState) {
  return state.maintenanceMode;
}

export function getShopToolbarSelectedItem(state: UIState) {
  return state.toolbar.selectedItem;
}

export function getCatalogScrollToHistory(state: UIState) {
  return state.singlePageCatalog.scrollToHistory;
}

export function getCatalogNavigateUrl(state: UIState) {
  return state.singlePageCatalog.navigateUrl;
}

export function getRecipeToastState(state: UIState) {
  return state.addRecipeIngredient;
}

export function getCatalogPreviewTool(state: UIState) {
  return state.catalogPreviewTool;
}

export function requestFailed(params: RequestFailed) {
  return { type: ACTION_TYPES.REQUEST_FAILED, ...params };
}

export function appFailed() {
  return { type: ACTION_TYPES.APP_FAILED };
}

export function updateModal(params: Modal) {
  return { type: ACTION_TYPES.UPDATE_MODAL, ...params };
}

export function showModal(
  modalId: string,
  params?: Modal["params"],
  dontClear?: boolean
) {
  return updateModal({
    modalId,
    visible: true,
    shouldTrackAnalytics: true,
    params: params || {},
    dontClear,
  });
}

export function hideModal(modalId: string, params?: Modal["params"]) {
  return updateModal({
    modalId,
    visible: false,
    shouldTrackAnalytics: true,
    params: params || {},
  });
}

export function clearModals() {
  return { type: ACTION_TYPES.CLEAR_MODALS };
}

export function showSnackbar(snackbarId: SnackbarIds) {
  return {
    type: ACTION_TYPES.SHOW_SNACKBAR,
    snackbarId,
    isVisible: true,
  };
}

export function hideSnackbar(snackbarId: SnackbarIds) {
  return {
    type: ACTION_TYPES.HIDE_SNACKBAR,
    snackbarId,
    isVisible: false,
  };
}

export function showCustomizeAlert() {
  return { type: ACTION_TYPES.SHOW_CUSTOMIZE_ALERT };
}

export function hideCustomizeAlert() {
  return { type: ACTION_TYPES.HIDE_CUSTOMIZE_ALERT };
}

export function showHolidayAlert() {
  return { type: ACTION_TYPES.SHOW_HOLIDAY_ALERT };
}

export function hideHolidayAlert() {
  return { type: ACTION_TYPES.HIDE_HOLIDAY_ALERT };
}

export function hideMobileCartMessage() {
  return { type: ACTION_TYPES.HIDE_MOBILE_CART_MESSAGE };
}

export function showTopNav() {
  return { type: ACTION_TYPES.SHOW_TOP_NAV };
}

export function hideTopNav() {
  return { type: ACTION_TYPES.HIDE_TOP_NAV };
}

export function toggleTopNav() {
  return { type: ACTION_TYPES.TOGGLE_TOP_NAV };
}

export function showCart() {
  return { type: ACTION_TYPES.SHOW_CART };
}

export function setOrToggleSelectedToolbarItem(item: ShopToolbarIcons) {
  return { type: ACTION_TYPES.SET_OR_TOGGLE_TOOLBAR_ITEM, item };
}

export function hideCart() {
  return { type: ACTION_TYPES.HIDE_CART };
}

export function toggleCart() {
  return { type: ACTION_TYPES.TOGGLE_CART };
}

export function showAccountSubNav() {
  return { type: ACTION_TYPES.SHOW_ACCOUNT_SUBNAV };
}

export function hideAccountSubNav() {
  return { type: ACTION_TYPES.HIDE_ACCOUNT_SUBNAV };
}

export function toggleAccountSubNav() {
  return { type: ACTION_TYPES.TOGGLE_ACCOUNT_SUBNAV };
}

export function initializeSignupShopping() {
  return { type: ACTION_TYPES.INITIALIZE_SIGNUP_SHOPPING };
}

// these actions are not tied to any reducer state,
// it is used to send the action through our saga layer and into analytics
export function userFocusedSearchBar() {
  return { type: ACTION_TYPES.USER_FOCUSED_SEARCH_BAR };
}
export function userSearchedNoResults({
  query,
  allProductsResultCount,
}: UserSearchedNoResults) {
  return {
    type: ACTION_TYPES.USER_SEARCHED_NO_RESULTS,
    query,
    allProductsResultCount,
  };
}

// TODO: NC-1244 productionalize signup shopping
export function initializeSignShopping() {
  return { type: ACTION_TYPES.INITIALIZE_SHOPPING };
}

export function initializeShopping(props) {
  return { type: ACTION_TYPES.INITIALIZE_SHOPPING, ...props };
}

export function neverListSuggestionsShown() {
  return { type: ACTION_TYPES.NEVER_LIST_SUGGESTIONS_SHOWN };
}

export function neverListSuggestionsHidden() {
  return { type: ACTION_TYPES.NEVER_LIST_SUGGESTIONS_HIDDEN };
}

export function reportIssueButtonClicked(location: string) {
  return { type: ACTION_TYPES.REPORT_ISSUE_BUTTON_CLICKED, location };
}

export function goToShoppingClicked(location: string) {
  return { type: ACTION_TYPES.GO_TO_SHOPPING_CLICKED, location };
}

export function referAFriendDesktopClicked(location: string) {
  return { type: ACTION_TYPES.REFER_A_FRIEND_DESKTOP_CLICKED, location };
}

export function accountMenuOpened(location: string) {
  return { type: ACTION_TYPES.ACCOUNT_MENU_OPENED, location };
}

export function moreAboutImperfectClicked() {
  return { type: ACTION_TYPES.MORE_ABOUT_IMPERFECT_CLICKED };
}

export function backToTopClicked(props: BackToTopClicked) {
  return { type: ACTION_TYPES.BACK_TO_TOP_CLICKED, ...props };
}

export function aisleClicked(props: AisleClicked) {
  return { type: ACTION_TYPES.AISLE_CLICKED, ...props };
}

export function aisleShelfClicked(props: AisleShelfClicked) {
  return { type: ACTION_TYPES.AISLE_SHELF_CLICKED, ...props };
}

export function customCTAClicked(props: CTAClicked) {
  return { type: ACTION_TYPES.CTA_CLICKED, ...props };
}

export function setInitialDeviceType(deviceType: DeviceType) {
  return {
    type: ACTION_TYPES.SET_DEVICE_TYPE,
    deviceType,
    initialDevice: true,
  };
}

export function setDeviceType(deviceType: DeviceType) {
  return {
    type: ACTION_TYPES.SET_DEVICE_TYPE,
    deviceType,
    initialDevice: false,
  };
}

export function resetShopping() {
  return {
    type: ACTION_TYPES.RESET_SHOPPING,
  };
}

export function offeringCardClicked(props: OfferingCardClicked) {
  return {
    type: ACTION_TYPES.OFFERING_CARD_CLICKED,
    ...props,
  };
}

export function ourFoodAisleClicked(aisleName: string) {
  return { type: ACTION_TYPES.OUR_FOOD_AISLE_CLICKED, aisleName };
}

export function clearCatalogScrollToHistory() {
  return {
    type: ACTION_TYPES.CLEAR_CATALOG_SCROLL_TO_HISTORY,
  };
}

export function setCatalogScrollToHistory(key: string, offset: number) {
  return {
    type: ACTION_TYPES.SET_CATALOG_SCROLL_TO_HISTORY,
    key,
    offset,
  };
}

export function setCatalogNavigateUrl(navigateUrl: string | null) {
  return {
    type: ACTION_TYPES.SET_CATALOG_NAVIGATE_URL,
    navigateUrl,
  };
}

export function addRecipeIngredientToState(
  products: object[],
  shelfId: string,
  recipeName: string,
  recipeUrl: string,
  recipeImage: string
) {
  const showRecipeToast = true;
  return {
    type: ACTION_TYPES.SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS,
    showRecipeToast,
    products,
    shelfId,
    recipeName,
    recipeUrl,
    recipeImage,
  };
}

export function recipePageOpened(shelfId: string) {
  return {
    type: ACTION_TYPES.RECIPE_PAGE_OPENED,
    shelfId,
  };
}

export function mobileAppNavigateToAccount() {
  return { type: ACTION_TYPES.MOBILE_APP_NAVIGATE_TO_ACCOUNT };
}

export function mobileAppNavigateToShopping() {
  return { type: ACTION_TYPES.MOBILE_APP_NAVIGATE_TO_SHOPPING };
}

export function clearDialog(dialog: Dialog) {
  return {
    type: ACTION_TYPES.CLEAR_DIALOG,
    dialog,
  };
}

export function setDialog(dialog: Dialog) {
  return {
    type: ACTION_TYPES.SET_DIALOG,
    dialog,
  };
}

export function clearCatalogPreviewTool() {
  return {
    type: ACTION_TYPES.CLEAR_CATALOG_PREVIEW_TOOL,
    clearAlgoliaCache: true,
    reinitializeShopping: true,
  };
}

export function setCatalogPreviewTool(data: CatalogPreviewToolData) {
  return {
    type: ACTION_TYPES.SET_CATALOG_PREVIEW_TOOL,
    clearAlgoliaCache: true,
    data,
    reinitializeShopping: true,
  };
}

export function accountNextStepsClicked(props) {
  return { type: ACTION_TYPES.ACCOUNT_NEXT_STEPS_CLICKED, ...props };
}

export function showAppUpdateRequiredWarningModal() {
  return { type: ACTION_TYPES.MOBILE_APP_SHOW_UPDATE_MODAL };
}

export function hideAppUpdateRequiredWarningModal() {
  return { type: ACTION_TYPES.MOBILE_APP_HIDE_UPDATE_MODAL };
}

export function updateLocalStorageData(key: string, data: string) {
  return { type: ACTION_TYPES.UPDATE_LOCAL_STORAGE, key, data };
}

export function firstTimeShoppingCarouselSlideChanged(props) {
  return {
    type: ACTION_TYPES.FIRST_TIME_SHOPPING_CAROUSEL_SLIDE_CHANGED,
    ...props,
  };
}

export function videoLoadStart(props) {
  return {
    type: ACTION_TYPES.VIDEO_LOAD_START,
    ...props,
  };
}

export function videoPlay(props) {
  return {
    type: ACTION_TYPES.VIDEO_PLAY,
    ...props,
  };
}

export function videoEnd(props) {
  return {
    type: ACTION_TYPES.VIDEO_END,
    ...props,
  };
}

export function videoError(props) {
  return {
    type: ACTION_TYPES.VIDEO_ERROR,
    ...props,
  };
}

export function videoPlaying(props) {
  return {
    type: ACTION_TYPES.VIDEO_PLAYING,
    ...props,
  };
}
