import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "ui";
const prefixes = {
  INITIALIZE: `${domain}/INITIALIZE`,
  INITIALIZE_SHOPPING: `${domain}/INITIALIZE_SHOPPING`,
  INITIALIZE_PLUS: `${domain}/INITIALIZE_PLUS`,
  INITIALIZE_SIGNUP_SHOPPING: `${domain}/INITIALIZE_SIGNUP_SHOPPING`,
  INITIALIZE_CATALOG_PREVIEW_TOOL: `${domain}/INITIALIZE_CATALOG_PREVIEW_TOOL`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
  BOOTSTRAP_COMPLETE: `${domain}/BOOTSTRAP_COMPLETE`,
  REQUEST_FAILED: `${domain}/REQUEST_FAILED`,
  APP_FAILED: `${domain}/APP_FAILED`,
  UPDATE_MODAL: `${domain}/UPDATE_MODAL`,
  CLEAR_MODALS: `${domain}/CLEAR_MODALS`,
  SHOW_SNACKBAR: `${domain}/SHOW_SNACKBAR`,
  HIDE_SNACKBAR: `${domain}/HIDE_SNACKBAR`,
  SHOW_TOP_NAV: `${domain}/SHOW_TOP_NAV`,
  HIDE_TOP_NAV: `${domain}/HIDE_TOP_NAV`,
  TOGGLE_TOP_NAV: `${domain}/TOGGLE_TOP_NAV`,
  SHOW_CART: `${domain}/SHOW_CART`,
  HIDE_CART: `${domain}/HIDE_CART`,
  TOGGLE_CART: `${domain}/TOGGLE_CART`,
  SHOW_ACCOUNT_SUBNAV: `${domain}/SHOW_ACCOUNT_SUBNAV`,
  HIDE_ACCOUNT_SUBNAV: `${domain}/HIDE_ACCOUNT_SUBNAV`,
  TOGGLE_ACCOUNT_SUBNAV: `${domain}/TOGGLE_ACCOUNT_SUBNAV`,
  SHOW_CUSTOMIZE_ALERT: `${domain}/SHOW_CUSTOMIZE_ALERT`,
  HIDE_CUSTOMIZE_ALERT: `${domain}/HIDE_CUSTOMIZE_ALERT`,
  SHOW_HOLIDAY_ALERT: `${domain}/SHOW_HOLIDAY_ALERT`,
  HIDE_HOLIDAY_ALERT: `${domain}/HIDE_HOLIDAY_ALERT`,
  HIDE_MOBILE_CART_MESSAGE: `${domain}/HIDE_MOBILE_CART_MESSAGE`,
  FRIENDBUY_WIDGETS_INITIALIZED: `${domain}/FRIENDBUY_WIDGETS_INITIALIZED`,
  USER_FOCUSED_SEARCH_BAR: `${domain}/USER_FOCUSED_SEARCH_BAR`,
  USER_SEARCHED_NO_RESULTS: `${domain}/USER_SEARCHED_NO_RESULTS`,
  NEVER_LIST_SUGGESTIONS_SHOWN: `${domain}/NEVER_LIST_SUGGESTIONS_SHOWN`,
  NEVER_LIST_SUGGESTIONS_HIDDEN: `${domain}/NEVER_LIST_SUGGESTIONS_HIDDEN`,
  REPORT_ISSUE_BUTTON_CLICKED: `${domain}/REPORT_ISSUE_BUTTON_CLICKED`,
  GO_TO_SHOPPING_CLICKED: `${domain}/GO_TO_SHOPPING_CLICKED`,
  REFER_A_FRIEND_DESKTOP_CLICKED: `${domain}/REFER_A_FRIEND_DESKTOP_CLICKED`,
  ACCOUNT_MENU_OPENED: `${domain}/ACCOUNT_MENU_OPENED`,
  MORE_ABOUT_IMPERFECT_CLICKED: `${domain}/MORE_ABOUT_IMPERFECT_CLICKED`,
  BACK_TO_TOP_CLICKED: `${domain}/BACK_TO_TOP_CLICKED`,
  AISLE_CLICKED: `${domain}/AISLE_CLICKED`,
  AISLE_SHELF_CLICKED: `${domain}/AISLE_SHELF_CLICKED`,
  SET_DEVICE_TYPE: `${domain}/SET_DEVICE_TYPE`,
  RESET_SHOPPING: `${domain}/RESET_SHOPPING`,
  OFFERING_CARD_CLICKED: `${domain}/OFFERING_CARD_CLICKED`,
  SET_SKIP_NEXT_ORDER_SNACK: `${domain}/SET_SKIP_NEXT_ORDER_SNACK`,
  SET_SWITCH_CADENCE_SNACK: `${domain}/SET_SWITCH_CADENCE_SNACK`,
  SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS: `${domain}/SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS`,
  SET_CADENCE_AUTO_SWITCHED: `${domain}/SET_CADENCE_AUTO_SWITCHED`,
  SET_OR_TOGGLE_TOOLBAR_ITEM: `${domain}/SET_OR_TOGGLE_TOOLBAR_ITEM`,
  OUR_FOOD_AISLE_CLICKED: `${domain}/OUR_FOOD_AISLE_CLICKED`,
  CLEAR_CATALOG_SCROLL_TO_HISTORY: `${domain}/CLEAR_CATALOG_SCROLL_TO_HISTORY`,
  SET_CATALOG_SCROLL_TO_HISTORY: `${domain}/SET_CATALOG_SCROLL_TO_HISTORY`,
  SET_CATALOG_NAVIGATE_URL: `${domain}/SET_NAVIGATE_URL`,
  CTA_CLICKED: `${domain}/CTA_CLICKED`,
  RECIPE_PAGE_OPENED: `${domain}/RECIPE_PAGE_OPENED`,
  MOBILE_APP_NAVIGATE_TO_ACCOUNT: `${domain}/MOBILE_APP_NAVIGATE_TO_ACCOUNT`,
  MOBILE_APP_NAVIGATE_TO_SHOPPING: `${domain}/MOBILE_APP_NAVIGATE_TO_SHOPPING`,
  MOBILE_APP_SHOW_UPDATE_MODAL: `${domain}/MOBILE_APP_SHOW_UPDATE_MODAL`,
  MOBILE_APP_HIDE_UPDATE_MODAL: `${domain}/MOBILE_APP_HIDE_UPDATE_MODAL`,
  CLEAR_DIALOG: `${domain}/CLEAR_DIALOG`,
  SET_DIALOG: `${domain}/SET_DIALOG`,
  CLEAR_CATALOG_PREVIEW_TOOL: `${domain}/CLEAR_CATALOG_PREVIEW_TOOL`,
  SET_CATALOG_PREVIEW_TOOL: `${domain}/SET_CATALOG_PREVIEW_TOOL`,
  ACCOUNT_NEXT_STEPS_CLICKED: `${domain}/ACCOUNT_NEXT_STEPS_CLICKED`,
  UPDATE_LOCAL_STORAGE: `${domain}/UPDATE_LOCAL_STORAGE`,
  FIRST_TIME_SHOPPING_CAROUSEL_SLIDE_CHANGED: `${domain}/FIRST_TIME_SHOPPING_CAROUSEL_SLIDE_CHANGED`,
  VIDEO_LOAD_START: `${domain}/VIDEO_LOAD_START`,
  VIDEO_PLAY: `${domain}/VIDEO_PLAY`,
  VIDEO_END: `${domain}/VIDEO_END`,
  VIDEO_ERROR: `${domain}/VIDEO_ERROR`,
  VIDEO_PLAYING: `${domain}/VIDEO_PLAYING`,
};
