import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import ACTION_TYPES from "app/actionTypes/reactivation";
import { LOCATION_CHANGE } from "connected-react-router";
import NextDeliverySuccessResponse from "app/types/account/NextDeliverySuccessResponse";
import ExtendedLocationChangeAction from "app/types/state/extendedRouter/ExtendedLocationChangeAction";
import ReactivationState, {
  ReactivationSteps,
} from "app/types/state/reactivation/ReactivationState";
import getCouponFromQuery from "app/utils/getCouponFromQuery";

export const initialState: ReactivationState = {
  reactivationData: null,
  promoCode: null,
  reactivationStep: ReactivationSteps.REVIEW,
  reactivationFailed: null,
};

const handleNextDeliverySucceeded = (
  state: ReactivationState,
  action: NextDeliverySuccessResponse
) => {
  const { reactivationData } = action;
  return {
    ...state,
    reactivationData: reactivationData || null,
    // If user has reactivationData, reset to REVIEW step
    reactivationStep: reactivationData
      ? ReactivationSteps.REVIEW
      : state.reactivationStep,
  };
};

const handleLocationChangeAction = (
  state: ReactivationState,
  action: ExtendedLocationChangeAction
) => {
  if (action.payload.isFirstRendering) {
    const { query } = action.payload.location;
    const coupon = query && getCouponFromQuery(query);
    if (coupon) {
      return {
        ...state,
        promoCode: coupon,
      };
    }
  }
  return state;
};

const handleCompleteReactivation = (state: ReactivationState) => {
  return {
    ...state,
    reactivationStep: ReactivationSteps.IN_PROGRESS,
    reactivationFailed: null,
  };
};

const handleCompleteReactivationSuccess = (state: ReactivationState) => {
  return {
    ...state,
    reactivationStep: ReactivationSteps.COMPLETE,
    reactivationFailed: false,
  };
};

const handleCompleteReactivationFailed = (state: ReactivationState) => {
  return {
    ...state,
    reactivationStep: ReactivationSteps.REVIEW,
    reactivationFailed: true,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return handleLocationChangeAction(state, action);
    }
    case ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED:
      return handleNextDeliverySucceeded(state, action);

    case ACTION_TYPES.COMPLETE_REACTIVATION:
      return handleCompleteReactivation(state);

    case ACTION_TYPES.COMPLETE_REACTIVATION_SUCCEEDED:
      return handleCompleteReactivationSuccess(state);

    case ACTION_TYPES.COMPLETE_REACTIVATION_FAILED:
      return handleCompleteReactivationFailed(state);

    default:
      return state;
  }
}

export function getReactivationData(state: ReactivationState) {
  return state.reactivationData;
}

export function getReactivationPromoCode(state: ReactivationState) {
  return state.promoCode;
}

export function getReactivationStep(state: ReactivationState) {
  return state.reactivationStep;
}

export function getReactivationFailed(state: ReactivationState) {
  return state.reactivationFailed;
}

export const completeReactivationAction = () => {
  return { type: ACTION_TYPES.COMPLETE_REACTIVATION };
};

export const completeReactivationSucceededAction = () => {
  return { type: ACTION_TYPES.COMPLETE_REACTIVATION_SUCCEEDED };
};

export const completeReactivationFailedAction = (params = {}) => {
  return { type: ACTION_TYPES.COMPLETE_REACTIVATION_FAILED, ...params };
};
