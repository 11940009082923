import { match, MatchFunction } from "path-to-regexp";

import routesObj from "app/router/routes.json";
import Routes from "app/types/router/Route";

type Matchers = Record<string, MatchFunction>;

const routes = routesObj as Routes;

// Adapted from https://dev.to/ihormaslov/reverse-patterns-to-url-react-router-config-2gb8
const matchers = Object.keys(routes).reduce((acc, key) => {
  const r = routes[key];
  if (r?.url?.length > 0) {
    acc[key] = match(r.url);
  }
  return acc;
}, {} as Matchers);

const keys = Object.keys(matchers);

const reverseRouteLookup = (path: string) => {
  if (!path || !path.length) return null;
  const key = keys.find((k) => !!matchers[k](path));
  return (key && routes[key]) || null;
};

export default reverseRouteLookup;
