import { Auth } from "aws-amplify";
import * as Monitoring from "app/monitoring";
import { LogLevel } from "app/types/monitoring";

const metadata = {
  brand: "imperfect",
};

export const login = async (email: string, password: string) => {
  const trimmedEmail = email.trim().toLowerCase();
  try {
    const user = await Auth.signIn(trimmedEmail, password, metadata);
    const { challengeName } = user;

    if (challengeName === "NEW_PASSWORD_REQUIRED") {
      Monitoring.sendLog({
        level: LogLevel.INFO,
        tags: ["cognito"],
        message: `Cognito temp password sign-in success`,
        // @ts-ignore
        email,
      });
      return {
        newPasswordRequired: true,
        user,
      };
    }

    const session = await Auth.currentSession();
    const payload = session.getIdToken().decodePayload();
    const userId = payload["custom:imperfectUserId"];
    Monitoring.sendLog({
      level: LogLevel.INFO,
      tags: ["cognito"],
      message: `Cognito sign-in success`,
      // @ts-ignore
      email,
    });
    return {
      userId,
      tokens: {
        id_token: session.getIdToken().getJwtToken(),
      },
    };
  } catch (error) {
    // @ts-ignore
    const errMsg = `Cognito sign-in error: ${error?.message}`;
    Monitoring.addError(error, { email });
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: ["cognito"],
      message: errMsg,
      // @ts-ignore
      error: error?.message,
      email,
    });
    throw new Error(
      // @ts-ignore
      `[Cognito]: Unable to sign in as ${trimmedEmail}: ${error?.message}`
    );
  }
};

export const logout = async () => {
  await Auth.signOut();
};

export const requestPasswordResetCode = async (email: string) => {
  const trimmedEmail = email.trim().toLowerCase();
  try {
    await Auth.forgotPassword(trimmedEmail);
    Monitoring.sendLog({
      level: LogLevel.INFO,
      tags: ["cognito"],
      message: `Cognito request reset code success`,
      // @ts-ignore
      email,
    });
  } catch (error) {
    // @ts-ignore
    const errMsg = `Cognito request reset code error: ${error?.message}`;
    Monitoring.addError(error, { email });
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: ["cognito"],
      message: errMsg,
      // @ts-ignore
      error: error?.message,
      email,
    });
    throw new Error(
      // @ts-ignore
      `[Cognito]: Unable to request reset code for ${trimmedEmail}: ${error?.message}`
    );
  }
};

export const resetPassword = async (
  email: string,
  confirmationCode: string,
  newPassword: string
) => {
  const trimmedEmail = email.trim().toLowerCase();
  const trimmedCode = confirmationCode.trim();
  try {
    await Auth.forgotPasswordSubmit(trimmedEmail, trimmedCode, newPassword);
    Monitoring.sendLog({
      level: LogLevel.INFO,
      tags: ["cognito"],
      message: `Cognito reset password success`,
      // @ts-ignore
      email,
    });
  } catch (error) {
    // @ts-ignore
    const errMsg = `Cognito reset password error: ${error?.message}`;
    Monitoring.addError(error, { email });
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: ["cognito"],
      message: errMsg,
      code: trimmedCode,
      // @ts-ignore
      error: error?.message,
      email,
    });
    throw new Error(
      // @ts-ignore
      `[Cognito]: Unable to reset password for ${trimmedEmail}: ${error?.message}`
    );
  }

  await Promise.resolve();
};

export const signup = async (
  email: string,
  password: string,
  userId: string
) => {
  await Auth.signUp({
    username: email,
    password,
    validationData: metadata,
    attributes: {
      email,
      "custom:login": email,
      "custom:imperfectUserId": userId,
    },
  });
};

type UserAttributes = {
  firstName: string;
  lastName: string;
};

export const updateAuthUserAttributes = async ({
  firstName,
  lastName,
}: UserAttributes) => {
  const user = await Auth.currentAuthenticatedUser();
  const attributesToUpdate = {
    ...(firstName && {
      given_name: firstName,
      "custom:firstName": firstName,
    }),
    ...(lastName && {
      family_name: lastName,
      "custom:lastName": lastName,
    }),
  };
  await Auth.updateUserAttributes(user, attributesToUpdate);
};

export const completeNewPassword = async (user, password: string) => {
  try {
    await Auth.completeNewPassword(user, password);
    const session = await Auth.currentSession();
    const payload = session.getIdToken().decodePayload();
    const userId = payload["custom:imperfectUserId"];
    Monitoring.sendLog({
      level: LogLevel.INFO,
      tags: ["cognito"],
      message: `Cognito update password success`,
      // @ts-ignore
      email: user.username,
    });
    return {
      userId,
      tokens: {
        id_token: session.getIdToken().getJwtToken(),
      },
    };
  } catch (error) {
    // @ts-ignore
    const errMsg = `Cognito update password error: ${error?.message}`;
    Monitoring.addError(error, { email: user.username });
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: ["cognito"],
      message: errMsg,
      // @ts-ignore
      error: error?.message,
      email: user.username,
    });
    throw new Error("[Cognito] Unable to update password");
  }
};
