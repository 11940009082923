import MobileState from "app/types/state/mobile";
declare global {
  interface Window {
    imperfectFoodsMobile: {
      isNativeApp: boolean;
      shouldRenderModalContent: boolean;
      shouldRenderAccountPage: boolean;
      shouldRenderOrdersPage: boolean;
    };
  }
}

export const initialState: MobileState = {
  isNativeApp: window.imperfectFoodsMobile?.isNativeApp || false,
  shouldRenderModalContent:
    window.imperfectFoodsMobile?.shouldRenderModalContent || false,
  shouldRenderAccountPage:
    window.imperfectFoodsMobile?.shouldRenderAccountPage || false,
  shouldRenderOrdersPage:
    window.imperfectFoodsMobile?.shouldRenderOrdersPage || false,
};

export default function reducer(state = initialState /* , action = {} */) {
  return state;
}

export const isNativeApp = (state: MobileState) => state.isNativeApp;
export const shouldRenderModalContent = (state: MobileState) =>
  state.shouldRenderModalContent;
export const shouldRenderAccountPage = (state: MobileState) =>
  state.shouldRenderAccountPage;
export const shouldRenderOrdersPage = (state: MobileState) =>
  state.shouldRenderOrdersPage;
