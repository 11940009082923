import React from "react";
import ShelfPromotionCard, {
  calculateShelfPromotionCardHeight,
  ShelfPromotionCardProfileType,
} from "./ShelfPromotionCard";
import useAnchorIdHash from "./useAnchorIdHash";

export interface CatalogShelfPromotionCardProps {
  shelfId: string;
  shelfName: string;
  columns: number;
  promotionImageFilename: string | null;
  promotionLink: string | null;
  promotionLinkText: string | null;
  promotionVideoFilename: string | null;
  promotionVideoSubtitleFilename: string | null;
}

interface CatalogShelfPromotionCardTokenProps
  extends CatalogShelfPromotionCardProps {
  style?: React.CSSProperties;
  updateHash?: _.DebouncedFunc<(anchorId: string) => void>;
  viewportRef?: React.RefObject<HTMLDivElement>;
}

const CatalogShelfPromotionCard: React.FC<CatalogShelfPromotionCardTokenProps> = ({
  shelfId,
  shelfName,
  columns,
  promotionImageFilename,
  promotionLink,
  promotionLinkText,
  promotionVideoFilename,
  promotionVideoSubtitleFilename,
  style,
  updateHash,
  viewportRef,
}) => {
  const ref = useAnchorIdHash(shelfId, updateHash, viewportRef);

  return (
    <div ref={ref} style={style}>
      <ShelfPromotionCard
        shelfId={shelfId}
        shelfName={shelfName}
        profileType={
          columns === 1
            ? ShelfPromotionCardProfileType.SLIM
            : ShelfPromotionCardProfileType.REGULAR
        }
        promotionImageFilename={promotionImageFilename}
        promotionLink={promotionLink}
        promotionLinkText={promotionLinkText}
        promotionVideoFilename={promotionVideoFilename}
        promotionVideoSubtitleFilename={promotionVideoSubtitleFilename}
      />
    </div>
  );
};

export default CatalogShelfPromotionCard;

export interface CatalogShelfPromotionCardToken {
  component: typeof CatalogShelfPromotionCard;
  componentProps: CatalogShelfPromotionCardTokenProps;
  data: {
    anchorId: string;
    updateHash: boolean;
  };
  listItemHeight: number;
}

export const getCatalogShelfPromotionCardToken = ({
  shelfId,
  shelfName,
  columns,
  promotionImageFilename,
  promotionLink,
  promotionLinkText,
  promotionVideoFilename,
  promotionVideoSubtitleFilename,
}: CatalogShelfPromotionCardTokenProps): CatalogShelfPromotionCardToken => ({
  component: CatalogShelfPromotionCard,
  componentProps: {
    shelfId,
    shelfName,
    columns,
    promotionImageFilename,
    promotionLink,
    promotionLinkText,
    promotionVideoFilename,
    promotionVideoSubtitleFilename,
  },
  data: {
    anchorId: shelfId,
    updateHash: true,
  },
  listItemHeight: calculateShelfPromotionCardHeight(),
});
