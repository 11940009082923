// @ts-nocheck
import { createSelector } from "reselect";

import reverseRouteLookup from "app/router/reverseRouteLookup";
import * as fromAccount from "app/selectors/account";
import * as fromCDSignup from "app/selectors/crossDomain/signup";
import * as fromOrderActive from "app/selectors/orderActive";
import * as fromOrders from "app/selectors/orders";
import * as fromRouting from "app/selectors/routing";
import * as fromUi from "app/selectors/ui";
import * as fromCDFriendbuy from "app/selectors/crossDomain/friendbuy";

export * from "./routing";
export * from "./auth";
export * from "./aisles";
export * from "./ui";
export * from "./account";
export * from "./orders";
export * from "./orderActive";
export * from "./offerings";
export * from "./deliveries";
export * from "./boxes";
export * from "./signup";
export * from "./cms";
export * from "./crossDomain";
export * from "./catalog";
export * from "./split";
export * from "./featureIntro";
export * from "./analytics";
export * from "./search";
export * from "./toast";
export * from "./mobile";
export * from "./reactivation";
export * from "./friendbuy";

// If there is no route metadata, we assume it is a CMS page, try to find it, and fire a page view if one is found.
// If a CMS page is not found, no page view gets called
export const getRouteMetadata = createSelector(
  [
    fromRouting.getRoutingLocation,
    fromCDSignup.getSignupMetadataForRoute,
    fromUi.isAnyModalVisible,
  ],
  (location, signupMetadata, modalVisible) => {
    const routeMetadata = reverseRouteLookup(location?.pathname);
    // consider chat enabled, by default
    const isChatWidgetEnabled =
      routeMetadata?.isChatWidgetEnabled !== undefined
        ? routeMetadata.isChatWidgetEnabled
        : true;

    return {
      ...(routeMetadata && routeMetadata),
      ...(signupMetadata && { title: signupMetadata.title }),
      isChatWidgetEnabled: modalVisible ? false : isChatWidgetEnabled,
    };
  }
);

// referral coupons are not removable
const isCouponRemovable = (coupon) =>
  !!coupon && !(coupon.metadata || {}).referralCampaignId;

export const getSignupCoupon = createSelector(
  [
    fromCDFriendbuy.getReferralCouponCode,
    fromAccount.getUserDeferredCoupon,
    fromOrders.getCoupons,
    fromCDFriendbuy.canApplyReferral,
  ],
  (referralCouponCode, deferredCouponCode, coupons, referralCouponValid) => {
    let couponCode = deferredCouponCode;

    if (!couponCode && referralCouponValid) {
      couponCode = referralCouponCode;
    }

    const coupon = (couponCode && coupons[couponCode]) || null;

    return {
      code: deferredCouponCode || (coupon ? coupon.code : ""),
      deferred: !!deferredCouponCode,
      message: (coupon && coupon.message) || "",
      metadata: coupon && coupon.metadata ? coupon.metadata : null,
      isSignupCoupon: couponCode === referralCouponCode,
      isRemovable: (coupon && isCouponRemovable(coupon)) || false,
    };
  }
);

export const getAccountCoupon = createSelector(
  [
    getSignupCoupon,
    fromOrderActive.getActiveOrderAppliedCoupon,
    fromOrders.getCoupons,
  ],
  (signupCoupon, appliedCoupon, coupons) => {
    if (appliedCoupon && appliedCoupon.code) {
      let { message } = appliedCoupon;
      const { code, couponId, metadata } = appliedCoupon;

      if (!message) {
        const coupon = coupons[code];
        message = coupon && coupon.message;
      }

      return {
        code,
        couponId,
        message,
        metadata,
        isSignupCoupon: false,
        isRemovable: isCouponRemovable(appliedCoupon),
      };
    }
    if (signupCoupon && signupCoupon.code) {
      return signupCoupon;
    }

    return null;
  }
);
