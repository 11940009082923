// @ts-nocheck
import makeActionTypes from "app/actionTypes";

const domain = "offerings";
const prefixes = {
  FETCH_ALL_OFFERINGS: `${domain}/FETCH_ALL_OFFERINGS`,
  FETCH_ALL_OFFERINGS_FROM_API: `${domain}/FETCH_ALL_OFFERINGS_FROM_API`,
  FETCH_ALL_TAGS: `${domain}/FETCH_ALL_TAGS`,
  FETCH_OFFERINGS_BY_ID: `${domain}/FETCH_OFFERINGS_BY_ID`,
  FETCH_SIMILAR_OFFERINGS_BY_CATEGORY: `${domain}/FETCH_SIMILAR_OFFERINGS_BY_CATEGORY`,
};

const actionTypes = makeActionTypes({ prefixes });

export default actionTypes;
