import ACTION_TYPES from "app/actionTypes/misfits";
import MisfitsState, { ZipProps } from "app/types/state/misfits";
import {
  MMValidDaysRequest,
  MMValidDaysAction,
  MMValidDaysSuccessAction,
  MMValidDaysSuccessParams,
} from "app/types/api/misfitsApi/subscription";

export const initialState: MisfitsState = {
  validDaysByZip: {},
};

const handleValidDaysAction = (
  state: MisfitsState,
  action: MMValidDaysAction
) => {
  return {
    ...state,
    validDaysByZip: {
      ...state.validDaysByZip,
      [action.zip]: {
        fetchInProgress: true,
        deliverable: null,
      },
    },
  };
};

const handleValidDaysSucceededAction = (
  state: MisfitsState,
  action: MMValidDaysSuccessAction
) => {
  return {
    ...state,
    validDaysByZip: {
      ...state.validDaysByZip,
      [action.zip]: {
        fetchInProgress: false,
        deliverable: action.deliverable,
      },
    },
  };
};

const handleValidDaysFailedAction = (
  state: MisfitsState,
  action: MMValidDaysAction
) => {
  return {
    ...state,
    validDaysByZip: {
      ...state.validDaysByZip,
      [action.zip]: {
        fetchInProgress: false,
        deliverable: null,
      },
    },
  };
};

export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.SUBSCRIPTION_VALID_DAYS: {
      return handleValidDaysAction(state, action);
    }
    case ACTION_TYPES.SUBSCRIPTION_VALID_DAYS_SUCCEEDED: {
      return handleValidDaysSucceededAction(state, action);
    }
    case ACTION_TYPES.SUBSCRIPTION_VALID_DAYS_FAILED: {
      return handleValidDaysFailedAction(state, action);
    }

    default:
      return state;
  }
}

const getValidDaysByZip = (state: MisfitsState) => state.validDaysByZip;

export const isMisfitsZipFetchInProgress = (
  state: MisfitsState,
  props: ZipProps
) => getValidDaysByZip(state)[props.zip]?.fetchInProgress;

export const isMisfitsZipDeliverable = (state: MisfitsState, props: ZipProps) =>
  getValidDaysByZip(state)[props.zip]?.deliverable;

export const fetchValidDaysByZip = (
  params: MMValidDaysRequest
): MMValidDaysAction => ({
  type: ACTION_TYPES.SUBSCRIPTION_VALID_DAYS,
  ...params,
});

export const fetchValidDaysByZipSuccess = (
  params: MMValidDaysSuccessParams
): MMValidDaysSuccessAction => ({
  type: ACTION_TYPES.SUBSCRIPTION_VALID_DAYS_SUCCEEDED,
  ...params,
});

export const fetchValidDaysByZipFailed = (
  params: MMValidDaysRequest
): MMValidDaysAction => ({
  type: ACTION_TYPES.SUBSCRIPTION_VALID_DAYS_FAILED,
  ...params,
});
