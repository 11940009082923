import {
  UpdateDeliveryInfoParams,
  UpdateDeliveryInfoWithValidationParams,
} from "app/types/account/updateDeliveryInfo";
import { AddressType } from "app/types/state/account/Account";

export enum DialogType {
  ADDRESS_UPDATE_QUERY = "address-update-query",
  ADDRESS_VALIDATION = "address-validation",
  CATALOG_PREVIEW_TOOL = "catalog-preview-tool",
  PURCHASE_FAILED = "purchase-failed",
  REFERRAL_STATUS_INFO = "referral-status-info",
  REMOVE_OUT_OF_STOCK = "remove-out-of-stock",
  RETAIL_PRICE = "retail-price",
  NEW_FEATURE = "new-feature",
}

interface BaseDialog {
  type: DialogType;
}

export enum QUERY_ACTION_OPTION {
  CANCEL_SUBSCRIPTION = "cancelSubscription",
  CANCEL_ACTIVEORDER = "cancelActiveORder",
}

export type AddressUpdateQueryDialog = {
  zip?: string;
  nextDeliveryDate?: string;
  isCustomizationOpen?: boolean;
  queryAction: QUERY_ACTION_OPTION;
  actionParams: UpdateDeliveryInfoParams;
} & BaseDialog;

export enum ADDRESS_VALIDATION_OPTION {
  SUGGESTED = "Recommended",
  USER = "You Entered",
}

export type AddressValidationDialog = {
  showUserOptionOnly?: boolean;
  addresses: {
    [ADDRESS_VALIDATION_OPTION.USER]: AddressType;
    [ADDRESS_VALIDATION_OPTION.SUGGESTED]?: AddressType;
  };
  actionParams: UpdateDeliveryInfoWithValidationParams;
} & BaseDialog;

export type CatalogPreviewToolDialog = {} & BaseDialog;

export type NewFeatureDialog = {} & BaseDialog;

export interface PurchaseFailedDialog extends BaseDialog {
  offeringName: string;
  variantId: string;
}

export type ReferralStatusInfoDialog = {} & BaseDialog;
export interface RemoveOutOfStockDialog extends BaseDialog {
  offeringName: string;
  quantity: number;
  rank?: number;
  shelfId: string | undefined;
  source: string | undefined;
  variantId: string;
}

export type RetailPriceDialog = {} & BaseDialog;

export type Dialog =
  | AddressUpdateQueryDialog
  | AddressValidationDialog
  | CatalogPreviewToolDialog
  | PurchaseFailedDialog
  | ReferralStatusInfoDialog
  | RemoveOutOfStockDialog
  | RetailPriceDialog
  | NewFeatureDialog;
