import queryString from "query-string";
import { createSelector } from "reselect";

import * as fromExtendedRouter from "app/reducers/extendedRouter";
import reverseRouteLookup from "app/router/reverseRouteLookup";
import State from "app/types/state";

// This state comes from connect-react-router internals
export function getRoutingLocation(state: State) {
  return state.router.location;
}

// This state comes from connect-react-router internals
export function getPreviousRouterAction(state: State) {
  return state.router.action;
}

export function getPreviousRoutingLocation(state: State) {
  return fromExtendedRouter.getPreviousLocation(state.extendedRouter);
}

export function getCoreRoute(state: State) {
  return fromExtendedRouter.getCoreRoute(state.extendedRouter);
}

export function getInitialLocation(state: State) {
  return fromExtendedRouter.getInitialLocation(state.extendedRouter);
}

export const getAisleAnchorId = createSelector(
  [getRoutingLocation],
  (location) => {
    const hash = queryString.parse(location.hash);
    if (!hash) return "";
    return hash.shelf as string;
  }
);

// The following selector is only used in the post login redirect auth saga
// No need to memoize so leave as simple function without reselect!
export const getInitialRouteMetadata = (state: State) => {
  const location = getInitialLocation(state);
  if (!location) return null;
  return reverseRouteLookup(location.pathname);
};
