import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { rem } from "polished";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

export type SubaisleListItemData = {
  active: boolean;
  disabled: boolean;
  id: string;
  label: string;
  value: number | null;
  endpoint: string | null;
} & Pick<LinkProps, "to">;

type MUIListItemProps = Pick<ListItemProps, "className">;
type ReactRouterLinkProps = Pick<LinkProps, "to">;
type ThirdPartyProps = MUIListItemProps & ReactRouterLinkProps;

export interface SubaisleListItemProps extends ThirdPartyProps {
  active: boolean;
  activeRef?: React.RefObject<HTMLAnchorElement> | null;
  disabled: boolean;
  label: string;
  onClick?: () => void;
  value: number | null;
}

const SubaisleListItem: React.FC<SubaisleListItemProps> = ({
  active,
  activeRef,
  className,
  disabled,
  label,
  onClick = () => {},
  to,
  value,
}) => {
  const preventFocusClick = (e: React.MouseEvent) => e.preventDefault();
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) e.preventDefault();
    onClick();
  };

  return (
    <StyledListItem
      button
      className={className}
      component={Link}
      disabled={disabled}
      disableRipple={false}
      ref={activeRef}
      onMouseDown={preventFocusClick}
      onClick={handleClick}
      to={to}
    >
      {active && <ActiveBadge />}
      <Label
        primary={label}
        primaryTypographyProps={{ variant: "caption" }}
        $active={active}
        $disabled={disabled}
      />
      {typeof value === "number" && (
        <Value
          primary={` (${value})`}
          primaryTypographyProps={{ variant: "caption" }}
          $active={active}
          $disabled={disabled}
        />
      )}
    </StyledListItem>
  );
};

export default SubaisleListItem;

const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${rem(10)} ${rem(8)};

  &:hover,
  &:active {
    color: unset;
    background-color: unset;
  }

  &:hover:not(.Mui-disabled) span {
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  }

  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    outline: none;
    background-color: unset;
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }

  &.Mui-disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }

  .MuiTouchRipple-root {
    color: ${({ theme }) => theme.colors.pink};
  }
` as typeof ListItem;

// `as typeof ListItem` is required to utilize the
// `component` prop on `StyledListItem`
// https://github.com/mui-org/material-ui/issues/13921#issuecomment-484133463

const ActiveBadge = styled.div`
  flex-shrink: 0;
  width: ${rem(8)};
  height: ${rem(8)};
  border-radius: ${rem(8)};
  background-color: ${({ theme }) => theme.colors.beet};
`;

const Label = styled(ListItemText)<{
  $active: boolean;
  $disabled: boolean;
}>`
  flex-grow: 0;
  display: flex;
  align-items: center;
  margin: ${rem(0)};
  margin-left: ${rem(16)};

  ${({ $active }) =>
    $active &&
    `
    margin-left: ${rem(8)};
  `}

  .MuiListItemText-primary {
    line-height: ${rem(20)};

    ${({ $active, theme }) =>
      $active &&
      `
      font-weight: ${theme.fonts.weight.semiBold};
    `}

    ${({ $disabled, theme }) =>
      $disabled &&
      `
      color: ${theme.colors.barley};
      text-decoration: line-through;
    `}
  }
`;

const Value = styled(ListItemText)<{
  $active: boolean;
  $disabled: boolean;
}>`
  flex-grow: 0;
  flex-shrink: 0;
  align-self: stretch;
  margin: ${rem(0)};

  .MuiListItemText-primary {
    line-height: ${rem(20)};
    white-space: pre;

    ${({ $active, theme }) =>
      $active &&
      `
      font-weight: ${theme.fonts.weight.semiBold};
    `}

    ${({ $disabled, theme }) =>
      $disabled &&
      `
      color: ${theme.colors.barley};
    `}
  }
`;
