import _isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import queryString from "query-string";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";

import { config } from "app/config";
import routes from "app/router/routes.json";
import * as fromDeliveries from "../reducers/deliveries";

import { isUserOnWarmingHold } from "app/selectors/account";
import { DateFormatType } from "app/types/selectors/deliveries";
import State from "app/types/state";
import {
  FullWindowData,
  FullWindowExtended,
  Windows,
} from "app/types/state/deliveries/deliveries";
import {
  formatDeliveryWindow,
  formatTime,
  formatDate,
  LONG_DATE_FORMAT,
  SHORT_TIME_FORMAT,
} from "app/ui/global/utils";
import { UserNextDeliveryFormatted } from "app/types/account/UserNextDelivery";

export function getDeliveryWindows(state: State): Windows {
  return fromDeliveries.getDeliveryWindows(state.deliveries);
}

export function getDeliveryWindowById(
  state: State,
  { deliveryWindowId }: { deliveryWindowId: string }
): FullWindowData | null {
  return getDeliveryWindows(state)
    ? getDeliveryWindows(state)[deliveryWindowId]
    : null;
}

export function getAllDeliveryWindowIdsByZip(state: State) {
  return fromDeliveries.getDeliveryWindowIdsByZip(state.deliveries);
}

export function getDeliveryWindowIdsByZip(
  state: State,
  { zip }: { zip: string }
): string[] {
  const deliveryWindowIdsByZip = fromDeliveries.getDeliveryWindowIdsByZip(
    state.deliveries
  );
  return !_isEmpty(deliveryWindowIdsByZip) ? deliveryWindowIdsByZip[zip] : [];
}

export function isFetchingWindowsByZip(state: State) {
  return fromDeliveries.isFetchingWindowsByZip(state.deliveries);
}

// https://github.com/toomuchdesign/re-reselect/blob/master/examples/2-avoid-selector-factories.md
export const getDeliveryWindowsByZip = createCachedSelector(
  [getDeliveryWindows, getDeliveryWindowIdsByZip],
  (windows: Windows, windowIdsByZip: string[]) => {
    return (windowIdsByZip || []).reduce(
      (acc: FullWindowExtended[], wId: string) => {
        const deliveryWindow = windows[wId];
        if (deliveryWindow) {
          acc.push({
            ...deliveryWindow,
            label: formatDeliveryWindow(deliveryWindow),
          });
        }
        return acc;
      },
      []
    );
  }
)((_, props) => props.zip);

export const getFCIdByDeliveryWindowId = createSelector(
  [getDeliveryWindowById],
  (deliveryWindow) =>
    (deliveryWindow && deliveryWindow.fulfillmentCenterId) || null
);

export function getNextDelivery(state: State) {
  return fromDeliveries.getNextDelivery(state.deliveries);
}

export function getShipmentTrackingInfo(state: State) {
  return fromDeliveries.getShipmentTrackingInfo(state.deliveries);
}

// TODO: NC-1010 Remove if subscription pause experiment fails
export function getSubPauseNextDeliveryDates(state: State) {
  return fromDeliveries.getSubPauseNextDeliveryDates(state.deliveries);
}

export function getNextDeliveryTimestamp(state: State) {
  return fromDeliveries.getNextDeliveryTimestamp(state.deliveries);
}

export function getSkippedActiveOrder(state: State) {
  return fromDeliveries.getSkippedActiveOrder(state.deliveries);
}

export function getSkippedNextOrder(state: State) {
  return fromDeliveries.getSkippedNextOrder(state.deliveries);
}

export function getNextDeliveryWindowReplacementReason(state: State) {
  return fromDeliveries.getNextDeliveryWindowReplacementReason(
    state.deliveries
  );
}

export function getNextAvailableWindowDelivery(state: State) {
  return fromDeliveries.getNextAvailableWindowDelivery(state.deliveries);
}

export function isCurrentCustomizationDelayed(state: State) {
  return fromDeliveries.getIsCustomizationDelayed(state.deliveries);
}

export function getFlexibleDeliveries(state: State) {
  return fromDeliveries.getFlexibleDeliveries(state.deliveries);
}

export const getZipCodeSummary = createSelector(
  [getNextDelivery],
  (nextDelivery) => {
    return nextDelivery?.deliveryWindow?.zipCodeSummary || null;
  }
);

export const isNextDeliveryWarming = createSelector(
  [getZipCodeSummary],
  (zipCodeSummary) => {
    return zipCodeSummary?.isWarming;
  }
);

/*
 * Foundational selector that returns the delivery start/end as moments
 */
export const getNextDeliveryPeriod = createSelector(
  [getNextDelivery],
  (nextDelivery) => {
    if (!nextDelivery?.deliveryWindow) return null;

    const { deliveryWindow } = nextDelivery;
    const { startDate, startTime, endDate, endTime, timezone } = deliveryWindow;

    if (!startDate || !startTime || !endDate || !endTime || !timezone) {
      return null;
    }
    return {
      startAsMoment: moment.tz(`${startDate} ${startTime}`, timezone),
      endAsMoment: moment.tz(`${endDate} ${endTime}`, timezone),
      nowAsMoment: moment.tz(timezone),
    };
  }
);

/*
 * Foundational selector that returns the next shopping deliveryWindow, accounting for fulfillment status of active order
 */
export const getNextShoppingDeliveryWindow = createSelector(
  [getNextDelivery],
  (nextDelivery) => {
    if (!nextDelivery) return null;

    const {
      isActiveOrderInFulfillment,
      deliveryWindow,
      upcomingDeliveryWindow,
    } = nextDelivery;
    return isActiveOrderInFulfillment ? upcomingDeliveryWindow : deliveryWindow;
  }
);

/*
 * Foundational selector that returns start/end of the next upcoming shopping period,
 * accounting for fulfillment status of active order
 */
export const getNextSubscriptionCustomizationPeriod = createSelector(
  [getNextShoppingDeliveryWindow],
  (nextShoppingWindow) => {
    if (!nextShoppingWindow) return null;

    const {
      startDate,
      startTime,
      customizationEndDate,
      customizationEndTime,
      customizationStartDate,
      customizationStartTime,
      timezone,
    } = nextShoppingWindow;

    if (
      !customizationStartDate ||
      !customizationStartTime ||
      !customizationEndDate ||
      !customizationEndTime ||
      !startDate ||
      !startTime ||
      !timezone
    ) {
      return null;
    }
    const startAsMoment = moment.tz(
      `${customizationStartDate} ${customizationStartTime}`,
      timezone
    );
    const endAsMoment = moment.tz(
      `${customizationEndDate} ${customizationEndTime}`,
      timezone
    );
    const deliveryAsMoment = moment.tz(`${startDate} ${startTime}`, timezone);
    return {
      endAsMoment,
      startAsMoment,
      // Used by OrderStatusMessaging
      // My best guess is we want this to be true when the custo open occurs in a different week from delivery?
      // Original: startDay >= deliveryStartDay, // making some ramalama/dingdong assumptions
      weekBeforeDelivery: startAsMoment.week() !== deliveryAsMoment.week(),
      timezone,
    };
  }
);

/*
 * Returns the customization open as a moment
 */
export const getCustomizationOpenAsMoment = createSelector(
  [getNextSubscriptionCustomizationPeriod],
  (nextShoppingCustomizationPeriod) => {
    return nextShoppingCustomizationPeriod?.startAsMoment;
  }
);

export const getCustomizationPeriodAsCalendarReminder = createSelector(
  [getNextSubscriptionCustomizationPeriod],
  (nextShoppingCustomizationPeriod) => {
    if (!nextShoppingCustomizationPeriod) return null;

    const host = config.get("endpoints.website_host");
    const { startAsMoment, endAsMoment } = nextShoppingCustomizationPeriod;
    const startTimestamp = startAsMoment.format();
    const endTimestamp = endAsMoment.format();
    const summary = "Reminder: your Imperfect Foods shopping window";
    const description =
      "Don't forget to shop for your Imperfect Foods order from our delicious and sustainable groceries. Want to remind yourself for each order? Make this calendar invite recurring.";
    const url = `${host}${routes.shopping.url}`;
    const alarmTimeMinutes = 30;

    const calendarReminderPayload = {
      startTimestamp,
      endTimestamp,
      summary,
      description,
      url,
      alarmTimeMinutes,
    };

    const calendarURL = `/ical-event.ics?${queryString.stringify(
      calendarReminderPayload
    )}`;

    return calendarURL;
  }
);

export const deliveryExpectedToday = createSelector(
  [getNextDeliveryPeriod],
  (nextDeliveryPeriod) => {
    if (!nextDeliveryPeriod) return false;
    return nextDeliveryPeriod?.startAsMoment?.isSame(
      nextDeliveryPeriod?.nowAsMoment,
      "day"
    );
  }
);

const DEFAULT_PROPS = {
  dateFormat: LONG_DATE_FORMAT,
};

export const getNextDeliveryDateFormatted = createCachedSelector(
  [
    getNextDeliveryPeriod,
    getZipCodeSummary,
    // TODO: This selector really belongs in crossDomain since we're pulling in a selector from account
    isUserOnWarmingHold,
    (_: State, props: DateFormatType) => props,
  ],
  (nextDeliveryPeriod, zipCodeSummary, isUserOnWH, props = DEFAULT_PROPS) => {
    if (!nextDeliveryPeriod) return "";
    let { dateFormat } = props;
    // This can become stale if the warmingEndDate has passed since the ndd was requested!
    if (
      zipCodeSummary?.isWarming &&
      !zipCodeSummary?.warmingEndDateIsExact &&
      isUserOnWH
    ) {
      // Override delivery date format for users on warming hold
      // TODO: How will this look in the gray bar?
      dateFormat = "MMM Y";
    }
    return formatDate(nextDeliveryPeriod.startAsMoment, dateFormat);
  }
)((_, props = DEFAULT_PROPS) => props.dateFormat);

export const getNextDeliveryTimeFormatted = createSelector(
  [getNextDeliveryPeriod],
  (nextDeliveryPeriod) => {
    if (!nextDeliveryPeriod) return "";

    return formatTime(nextDeliveryPeriod?.endAsMoment, SHORT_TIME_FORMAT);
  }
);

export const getNextCustomizationWindowFormatted = createCachedSelector(
  [
    getNextSubscriptionCustomizationPeriod,
    (_: State, props: DateFormatType | null) => props,
  ],
  (nextShoppingCustomizationPeriod, props = DEFAULT_PROPS) => {
    if (!nextShoppingCustomizationPeriod)
      return {
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
      };

    return {
      startDate: formatDate(
        nextShoppingCustomizationPeriod?.startAsMoment,
        props?.dateFormat
      ),
      startTime: formatTime(
        nextShoppingCustomizationPeriod?.startAsMoment,
        SHORT_TIME_FORMAT
      ),
      endDate: formatDate(
        nextShoppingCustomizationPeriod?.endAsMoment,
        props?.dateFormat
      ),
      endTime: formatTime(
        nextShoppingCustomizationPeriod?.endAsMoment,
        SHORT_TIME_FORMAT
      ),
    };
  }
)((_, props = DEFAULT_PROPS) => props?.dateFormat);

export const getNextDeliveryFormatted = createSelector(
  [
    getNextDelivery,
    getNextDeliveryDateFormatted,
    getNextDeliveryTimeFormatted,
    getNextCustomizationWindowFormatted,
  ],
  (
    nextDelivery,
    deliveryDateFormatted,
    deliveryTimeFormatted,
    customizationWindowFormatted
  ) =>
    ({
      ...nextDelivery,
      deliveryDateFormatted,
      deliveryTimeFormatted,
      customizationWindowFormatted,
    } as UserNextDeliveryFormatted)
);

export const isValidZipCode = createSelector(
  [(_: State, props: { zip: string }) => props],
  ({ zip }) => zip && /^\d{5}$/.test(zip)
);

/*
 * Has 5 minutes expired since we fetched the next delivery
 */
export const isNextDeliveryStale = (state: State) => {
  const timestamp = getNextDeliveryTimestamp(state);
  const nextDelivery = getNextDelivery(state);
  const timezoneFC = nextDelivery?.deliveryWindow?.timezoneFC || null;
  if (timestamp && nextDelivery && timezoneFC) {
    const now = moment.tz(timezoneFC);
    const staleTimestamp = moment.tz(timestamp, timezoneFC).add(5, "minutes");
    return now.isAfter(staleTimestamp, "minute");
  }
  return false;
};
