/**
 * @function MaterialIcon
 */

import { IconProps as MuiIconProps } from "@material-ui/core/Icon";
// must import one at a time to avoid a big bundle.
import {
  ErrorRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  LaunchRounded,
} from "@material-ui/icons";
import React from "react";
import styled from "styled-components";

import { StyledTheme } from "app/types/styles";

type AlertIcon = "Error";

const alertIcons: AlertIcon[] = ["Error"];

type NavigationIcon =
  | "ChevronLeft"
  | "ChevronRight"
  | "ExpandLess"
  | "ExpandMore"
  | "Launch";

const navigationIcons: NavigationIcon[] = [
  "ChevronLeft",
  "ChevronRight",
  "ExpandLess",
  "ExpandMore",
  "Launch",
];

type IconName = AlertIcon | NavigationIcon;

type Rounded<T extends string> = `${T}Rounded`;

type IconMap = {
  [key in Rounded<IconName>]: React.FC<IconProps>;
};

const MaterialIcons = {
  ErrorRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  LaunchRounded,
} as IconMap;

/* Filter icon names for easier targeting */
export const iconList = [...alertIcons, ...navigationIcons];

export interface IconProps extends MuiIconProps {
  type: NavigationIcon;
  size?: Size;
  // titleAccess is for accessibility: https://material-ui.com/api/svg-icon/
  titleAccess?: string;
  className?: string;
}

export enum Size {
  small = "small",
  medium = "default",
  large = "large",
}

function getIconComponent(type: NavigationIcon): React.FC<IconProps> {
  return MaterialIcons[`${type}Rounded` as Rounded<NavigationIcon>];
}

const Icon: React.FC<IconProps> = ({
  type,
  titleAccess,
  size = Size.medium,
  ...muiProps
}: IconProps) => {
  const IconComponent = getIconComponent(type);
  return (
    <IconComponent
      type={type}
      titleAccess={titleAccess}
      fontSize={size}
      {...muiProps}
    />
  );
};

export const muiIconOverrides = {
  MuiSvgIcon: {
    fontSizeSmall: {
      fontSize: "1rem",
    },
  },
};

function getColorFromTheme(theme: StyledTheme, color: string) {
  return theme.colors[color];
}

const StyledIcon = styled(Icon)`
  color: ${({ theme, color }) => {
    if (!color) return theme?.colors?.grayLight;
    return getColorFromTheme(theme, color);
  }};
`;

export { StyledIcon as Icon };
