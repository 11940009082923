/* eslint-disable camelcase */
import _get from "lodash/get";

import {
  VisitorStatus,
  Customer,
  VisitorStatusPayload,
} from "app/types/api/friendbuyLegacy";
import {
  APIType,
  FriendbuyUser,
  LegacyReferralAttributes,
  FRIEND_TAGS,
  LogEventCode,
} from "app/types/state/friendbuy";
import * as Monitoring from "app/monitoring";
import { LogLevel } from "app/types/monitoring";

export const isValidLegacyReferral = (
  referral: LegacyReferralAttributes | null
): boolean => {
  return !referral?.selfReferred && !!referral?.campaign;
};

/*
 * Pulls out the advocate information from the FB visitor response
 */
const getAdvocate = (
  visitorStatusResponse: VisitorStatus | null
): FriendbuyUser | null => {
  let customer: Customer | null = null;

  if (!visitorStatusResponse) {
    return null;
  }

  // using lodash get to avoid exceptions if data model can't make it to customer
  if (visitorStatusResponse.purl) {
    customer = _get(visitorStatusResponse, "purl.customer", null);
  } else if (visitorStatusResponse.reminder_email) {
    customer = _get(
      visitorStatusResponse,
      "reminder_email.sharer.customer",
      null
    );
  } else if (visitorStatusResponse.share) {
    customer = _get(visitorStatusResponse, "share.sharer.customer", null);
  }

  if (customer) {
    return {
      userId: customer.account_id,
      firstName: customer.first_name || null,
      lastName: customer.last_name || null,
      email: customer.email || null,
    };
  }

  return null;
};

const getReferralFromVisitorStatusLegacy = (
  visitorStatus: VisitorStatus | null,
  apiType = APIType.FIRSTGEN
): LegacyReferralAttributes | null => {
  const logWarning = (message: string) => {
    Monitoring.sendLog({
      tags: FRIEND_TAGS,
      level: LogLevel.WARN,
      message,
      apiType,
      visitorStatus,
      eventCode: LogEventCode.VISITOR_STATUS_VALIDATION,
    });
  };

  const logInfo = (message: string) => {
    Monitoring.sendLog({
      tags: FRIEND_TAGS,
      level: LogLevel.INFO,
      message,
      apiType,
      visitorStatus,
      eventCode: LogEventCode.VISITOR_STATUS_INFO,
    });
  };

  if (visitorStatus) {
    const {
      // One of 'share', 'purl', 'reminder_email', or 'none'
      friendbuy_click: fbClickType,
      // Indicates whether the visitor is an advocate clicking on their own referral link.
      // Note, this calculation is dependent on which fraud check settings have been enabled
      // in the Friendbuy platform, specifically the Browser and/or IP Address checks.
      self_referred: selfReferred,
    } = visitorStatus;

    if (selfReferred) {
      logInfo("Visitor status self referral");
    }

    if (fbClickType !== "none") {
      const advocate = getAdvocate(visitorStatus);
      const referralValue = visitorStatus[fbClickType];

      if (!advocate) {
        // we should always find a customer, but it lives in different places depending on the type
        logWarning(
          "Unable to find customer in visitor status service response"
        );
      }

      if (!referralValue) {
        logWarning(
          "Unable to find referral from clickType in visitor status service response"
        );
      }

      const {
        campaign,
        referral_code: referralCode,
      } = referralValue as VisitorStatusPayload;

      if (!campaign) {
        // we should always have a campaign
        logWarning("No campaign found in visitor status response");
      }

      return {
        referralCode,
        campaign,
        selfReferred,
        advocate,
        fbClickType,
        friendbuyResponse: visitorStatus, // raw
      };
    }
  }

  return null;
};

export default getReferralFromVisitorStatusLegacy;
