import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import routes from "app/router/routes.json";
import breakpoints from "app/styles/breakpoints";
import grid from "app/styles/grid";
import { getImageURL } from "app/ui/global/utils";

const alt = `Get free delivery by joining Imperfect Plus!`;

interface ImperfectPlusBannerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isMobile?: boolean;
}

const ImperfectPlusBanner: React.FC<ImperfectPlusBannerProps> = ({
  isMobile,
}) => {
  const imageSrc = isMobile
    ? getImageURL(`Imperfect_Plus_Banner_Mobile_drmfmh`, "v1576791964")
    : getImageURL(`Imperfect_Plus_Banner_Desktop_ii6n6x`, "v1576791965");

  return (
    <Wrapper>
      <a href={routes.plus.url}>
        <Image src={imageSrc} alt={alt} />
      </a>
    </Wrapper>
  );
};

ImperfectPlusBanner.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

const Wrapper = styled.div`
  ${breakpoints.xl`
    ${grid.row};
    align-items: center;
    justify-content: center;
  `}
`;

const Image = styled.img`
  display: block;
`;

export default ImperfectPlusBanner;
