import MuiRadio, { RadioProps } from "@material-ui/core/Radio";
import React from "react";
import { FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CircleCheck from "@material-ui/icons/CheckCircle";
import { rem } from "polished";
import { ExtendedMUITheme } from "app/types/styles";

export interface RadioButtonProps extends RadioProps {
  label: string | React.ReactNode;
  isCentered?: boolean;
  isPill?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles((theme: ExtendedMUITheme) =>
  createStyles({
    root: {
      backgroundColor: theme.colors.marshmallow,
      borderStyle: "solid",
      borderWidth: rem(2),
      borderColor: (props: RadioButtonProps) =>
        props.checked ? theme.colors.beet : theme.colors.barley,
      borderRadius: (props: RadioButtonProps) =>
        props.isPill ? rem(40) : rem(4),
      display: "inline-flex",
      justifyContent: (props: RadioButtonProps) =>
        props.isCentered ? "center" : "flex-start",
      margin: `${rem(8)} 0`,
      paddingRight: rem(12),
      width: (props: RadioButtonProps) => (props.fullWidth ? "100%" : "auto"),
      "&.Mui-disabled": {
        borderColor: theme.colors.barley,
      },
      "&.Mui-checked": {
        borderColor: theme.colors.beet,
      },
      "&:hover": {
        backgroundColor: theme.colors.oat,
        borderColor: theme.colors.eggplant,
      },
    },
    radio: {
      padding: rem(8),
      "&:hover": {
        background: "none",
      },
    },
    radioChecked: {
      "&&:hover": {
        background: "none",
      },
    },
    label: {
      fontSize: "unset",
      fontWeight: "unset",
      lineHeight: "unset",
    },
  })
);

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { root, radio, radioChecked, label } = useStyles(props);
  const { isCentered, isPill, fullWidth, ...radioProps } = props;

  return (
    <FormControlLabel
      classes={{ root, label }}
      control={
        <MuiRadio
          {...radioProps}
          classes={{ root: radio, checked: radioChecked }}
          size="medium"
          checkedIcon={<CircleCheck />}
        />
      }
      label={
        typeof props.label === "string" ? (
          <Typography component="span" variant="button">
            {props.label}
          </Typography>
        ) : (
          props.label
        )
      }
      disabled={props.disabled}
      checked={props.checked}
    />
  );
};

export default RadioButton;
