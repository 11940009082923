const muiBreakpoints = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 480,
      lg: 768,
      xl: 992,
      xxl: 1255,
      xxxl: 1440,
      xxxxl: 1464,
    },
  },
};

export default muiBreakpoints;
