// @ts-nocheck
import { createSelector } from "reselect";

import { USER_PLUS_ENROLL } from "app/constants/treatments";
import * as fromAccount from "app/selectors/account";
import * as fromSplit from "app/selectors/split";

/*
 * Should we unlock the plus route etc
 */
export const canShowPlusFeature = createSelector(
  [
    fromAccount.isUserPlusMember,
    (state) => fromSplit.getSplitTreatmentValue(state, USER_PLUS_ENROLL),
  ],
  (isUserPlusMember, treatment) => {
    // If the user is a member, show plus membership status page regardless of what split says
    if (isUserPlusMember) return true;
    return treatment === "on";
  }
);

/*
 * Should we display the My Account widget (Advertisements)
 */
export const canShowPlusWidget = createSelector(
  [canShowPlusFeature, fromAccount.isUserPlusMember],
  (showPlusFeature, isUserPlusMember) => {
    // Once a user is a plus member, don't show any advertisements
    if (isUserPlusMember) return false;

    return showPlusFeature;
  }
);
