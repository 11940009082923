/*
 * This function normalizes URL's with or without the trailing slash to one unique route
 */
export default function normalizePath(str: string) {
  const result = `/${str.replace(/^\/+/g, "")}`;
  if (result && result !== "/" && result.endsWith("/")) {
    // strip trailing slash only if there exists path
    return result.substr(0, result.length - 1);
  }
  return result;
}
