// @ts-nocheck
import baseLoadable from "@loadable/component";
import React from "react";

import Page, {
  BlockContainer,
  BlockWrapper,
} from "app/ui/cms/contentPage_DEPRECATED/Page_DEPRECATED";
import Spinner from "app/ui/shared/Spinner";

export const FallBack = () => {
  return (
    <Page>
      <BlockContainer>
        <BlockWrapper>
          <Spinner />
        </BlockWrapper>
      </BlockContainer>
    </Page>
  );
};

// https://loadable-components.com/docs/babel-plugin/#loadable-detection
export default (
  func,
  hideFallback = false,
  fallBackComponent = <FallBack />
) => {
  return baseLoadable(func, !hideFallback && { fallback: fallBackComponent });
};
