// @ts-nocheck
import { createSelector } from "reselect";

import * as fromBoxes from "app/reducers/boxes";
import { objectValid, formatMaxPrice } from "app/ui/global/utils";

export function getMapOfBoxes(state) {
  return fromBoxes.getMapOfBoxes(state.boxes);
}

export function getPrimaryBoxIds(state) {
  return fromBoxes.getPrimaryBoxIds(state.boxes);
}

export function getSecondaryBoxIds(state) {
  return fromBoxes.getSecondaryBoxIds(state.boxes);
}

export const getBoxSizesForSelect = createSelector(
  [getPrimaryBoxIds, getMapOfBoxes],
  (primaryBoxIds, mapOfBoxes) =>
    primaryBoxIds.reduce((acc, boxId) => {
      const box = mapOfBoxes[boxId];
      // https://github.com/JedWatson/react-select
      const value = {
        label: box.boxSize,
        value: box.id,
        imageId: box.imageId,
      };
      if (!acc[box.boxType]) {
        acc[box.boxType] = [value];
      } else {
        acc[box.boxType].push(value);
      }
      return acc;
    }, {})
);

export const getBoxTypesForSelect = createSelector(
  [getPrimaryBoxIds, getMapOfBoxes],
  (primaryBoxIds, mapOfBoxes) =>
    primaryBoxIds.reduce((acc, boxId) => {
      const box = formatBoxData(mapOfBoxes[boxId]);
      // https://github.com/JedWatson/react-select
      const selectValue = {
        label: box.boxTypeFormatted,
        value: box.boxType,
      };
      if (!acc.some(({ value }) => value === box.boxType)) {
        acc.push(selectValue);
      }
      return acc;
    }, [])
);

// check for existence of a box at the given FCId (existence = has an FC-specific record)
const boxExistsForFC = (box, fcId) => box && box.fc && !!box.fc[fcId];

// check for existence AND visibility of a box at the given FCId
const boxVisibleForFC = (box, fcId) => {
  return boxExistsForFC(box, fcId) && box.fc[fcId].visible === true;
};

// check for existence AND availableForUglyship
const boxAvailableForUglyShipForFC = (box, fcId) => {
  return (
    boxExistsForFC(box, fcId) && box.fc[fcId].availableForUglyship === true
  );
};

// check for existence AND visibility AND availableForUglyship
const boxVisibleAndAvailableForUglyShipForFC = (box, fcId) => {
  return (
    boxVisibleForFC(box, fcId) && box.fc[fcId].availableForUglyship === true
  );
};

export const makeGetBoxIdsByFcId = (
  boxIdSelector,
  fcIdSelector,
  isUglyShipSelector = () => false,
  boxMapSelector = getMapOfBoxes,
  requiredFields: string[] = null
) => {
  return createSelector(
    [boxIdSelector, fcIdSelector, isUglyShipSelector, boxMapSelector],
    (boxIds, fulfillmentCenterId, isUglyShip, mapOfBoxes) => {
      return boxIds.filter((boxId) => {
        const box = mapOfBoxes[boxId];
        const boxSelectionFunction = isUglyShip
          ? boxAvailableForUglyShipForFC
          : boxExistsForFC;
        const isBoxIdSelected = boxSelectionFunction(box, fulfillmentCenterId);
        const isBoxIdValid =
          isBoxIdSelected && requiredFields
            ? objectValid(box, "box", requiredFields)
            : true;
        return isBoxIdSelected && isBoxIdValid;
      });
    }
  );
};

export const makeGetVisibleBoxIdsByFcId = (
  boxIdSelector,
  fcIdSelector,
  isUglyShipSelector = () => false,
  boxMapSelector = getMapOfBoxes,
  requiredFields: string[] = null
) =>
  createSelector(
    [boxIdSelector, fcIdSelector, isUglyShipSelector, boxMapSelector],
    (boxIds, fulfillmentCenterId, isUglyShip, mapOfBoxes) => {
      return boxIds.filter((boxId) => {
        const box = mapOfBoxes[boxId];
        const boxSelectionFunction = isUglyShip
          ? boxVisibleAndAvailableForUglyShipForFC
          : boxVisibleForFC;
        const isBoxIdSelected = boxSelectionFunction(box, fulfillmentCenterId);
        const isBoxIdValid =
          isBoxIdSelected && requiredFields
            ? objectValid(box, "box", requiredFields)
            : true;
        return isBoxIdSelected && isBoxIdValid;
      });
    }
  );

export const makeGetVisibleSecondaryBoxIdsByFcId = (
  fcIdSelector,
  isUglyShipSelector,
  requiredFields: string[] = null
) => {
  return makeGetVisibleBoxIdsByFcId(
    getSecondaryBoxIds,
    fcIdSelector,
    isUglyShipSelector,
    getMapOfBoxes,
    requiredFields
  );
};

export const formatBoxData = (box) => {
  if (!box) return null;
  const displayData = box.display || {};
  return {
    ...box,
    ...displayData,
  };
};

export const getBoxDataForFCId = (
  box,
  fcId,
  toFormattedPrice = formatMaxPrice
) => {
  const fcData = fcId && box && box.fc && box.fc[fcId];
  return {
    ...formatBoxData(box),
    maxPrice: fcData && fcData.maxPrice,
    formattedPrice: toFormattedPrice(fcData),
  };
};

// single boxId
export const makeGetDataForBoxId = (
  boxIdSelector,
  fcIdSelector,
  formatPrice = formatMaxPrice
) =>
  createSelector(
    [boxIdSelector, fcIdSelector, getMapOfBoxes],
    (boxId, fulfillmentCenterId, mapOfBoxes) => {
      return getBoxDataForFCId(
        mapOfBoxes[boxId],
        fulfillmentCenterId,
        formatPrice
      );
    }
  );

// array of boxIds
export const makeGetDataForBoxIds = (
  boxIdsSelector,
  fcIdSelector,
  formatPrice = formatMaxPrice
) =>
  createSelector(
    [boxIdsSelector, fcIdSelector, getMapOfBoxes],
    (boxIds, fulfillmentCenterId, mapOfBoxes) => {
      // Defensively check for missing data
      if (!boxIds || !fulfillmentCenterId || !mapOfBoxes) return [];
      return boxIds.map((boxId) => {
        return getBoxDataForFCId(
          mapOfBoxes[boxId],
          fulfillmentCenterId,
          formatPrice
        );
      });
    }
  );

// single boxId with delivery info
export const makeGetDataForBoxIdWithDelivery = (
  boxIdSelector,
  deliveryWindowSelector,
  formatPrice = formatMaxPrice
) =>
  createSelector(
    [boxIdSelector, deliveryWindowSelector, getMapOfBoxes],
    (boxId, deliveryWindow, mapOfBoxes) => {
      if (!deliveryWindow || !deliveryWindow.fulfillmentCenterId) return [];
      // Should this be returning an array?

      if (!boxId) {
        return {};
      }

      return {
        ...getBoxDataForFCId(
          mapOfBoxes[boxId],
          deliveryWindow.fulfillmentCenterId,
          formatPrice
        ),
        deliveryPrice: deliveryWindow.deliveryPrice,
      };
    }
  );
