import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rem, padding } from "polished";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { toggleNeverListSuggestions } from "app/reducers/orders";
import {
  neverListSuggestionsShown,
  neverListSuggestionsHidden,
} from "app/reducers/ui";
import Button from "app/ui/shared/Button";
import RemovedFromCart from "app/ui/shopping/Cart/NeverList/RemovedFromCart";
import grid from "app/styles/grid";
import Offering from "app/types/state/offerings/Offering";
import { shouldShowNeverListSuggestions } from "app/selectors";

export interface NeverListCartSectionProps {
  removedDefaultOfferings: Offering[];
}

const NeverListCartSection: React.FC<NeverListCartSectionProps> = ({
  removedDefaultOfferings,
}) => {
  const dispatch = useDispatch();
  const showNeverListSuggestions = useSelector(shouldShowNeverListSuggestions);

  useEffect(() => {
    if (showNeverListSuggestions && !!removedDefaultOfferings?.length) {
      dispatch(neverListSuggestionsShown());
    }
  }, []);

  useEffect(() => {
    if (removedDefaultOfferings?.length) {
      dispatch(neverListSuggestionsShown());
    }
  }, [removedDefaultOfferings]);

  useEffect(() => {
    if (showNeverListSuggestions && !!removedDefaultOfferings?.length) {
      dispatch(neverListSuggestionsShown());
    }
    if (!showNeverListSuggestions) {
      dispatch(neverListSuggestionsHidden());
    }
  }, [showNeverListSuggestions]);

  const hasRemovedItems = !!removedDefaultOfferings?.length;
  const neverListSuggestions = showNeverListSuggestions && hasRemovedItems;

  return (
    <>
      {hasRemovedItems && (
        <Wrapper>
          <NeverListHeader>Removed From My Default Box</NeverListHeader>
          <StyledButton onClick={() => dispatch(toggleNeverListSuggestions())}>
            {showNeverListSuggestions ? "Collapse" : "Expand"}
            <StyledFontAwesomeIcon
              icon={showNeverListSuggestions ? "angle-up" : "angle-down"}
            />
          </StyledButton>
        </Wrapper>
      )}
      {neverListSuggestions && (
        <RemovedFromCart removedDefaultOfferings={removedDefaultOfferings} />
      )}
    </>
  );
};

const Wrapper = styled.div`
  ${grid.row};
  background: ${({ theme }) => theme.colors.marshmallow};
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${rem(8)};
  padding-top: ${rem(24)};
`;

const NeverListHeader = styled.h4`
  margin: 0 ${rem(16)};
  font-size: ${rem(16)};
  margin-bottom: 0;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: ${rem(5)};
`;

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.marshmallow};
  border: none;
  color: ${({ theme }) => theme.colors.purple};
  font-size: ${rem(12)};
  height: ${rem(18)};
  ${padding(0, 0, 0, rem(8))};
  width: ${rem(79)};

  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    border-radius: ${rem(4)};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
    color: ${({ theme }) => theme.colors.beet};
    text-decoration: none;
  }
  &:hover {
    border: ${rem(1)} solid ${({ theme }) => theme.colors.peppercorn};
    border-radius: ${rem(4)};
    color: ${({ theme }) => theme.colors.peppercorn};
    text-decoration: none;
  }
`;

export default NeverListCartSection;
