import { Typography } from "@material-ui/core";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { toToggledShoppingFilter } from "app/router/routes";
import FilterType from "app/types/ui/FilterType";
import Tag from "app/types/state/offerings/Tag";
import AisleNavWidget, {
  AisleNavWidgetProps,
} from "app/ui/shared/AisleNavWidget";
import Link from "app/ui/shared/Link";

interface RemoveFilterWidgetProps extends AisleNavWidgetProps {
  tag: Tag;
}

const RemoveFilterWidget: React.FC<RemoveFilterWidgetProps> = ({
  className,
  imageType,
  tag,
}) => (
  <AisleNavWidget imageType={imageType} className={className}>
    <WidgetText variant="caption">
      <WidgetLink
        hashAndQuery
        to={toToggledShoppingFilter({
          taxonomyId: tag.id,
          taxonomy: FilterType.TAG,
          selected: true,
        })}
      >
        Remove filter
      </WidgetLink>{" "}
      to see more products
    </WidgetText>
  </AisleNavWidget>
);

export default RemoveFilterWidget;

const WidgetText = styled(Typography)`
  line-height: ${rem(20)};
`;

const WidgetLink = styled(Link)`
  color: ${({ theme }) => theme.colors.pink};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  text-decoration: underline;

  &:active {
    color: ${({ theme }) => theme.colors.pink};
  }
`;
