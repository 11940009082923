import { ThemeOptions } from "@material-ui/core";

import muiTypography from "app/styles/muiTheme/muiTypography";
import muiPalette from "app/styles/muiTheme/muiPalette";
import muiBreakpoints from "app/styles/muiTheme/muiBreakpoints";

const muiTheme: ThemeOptions = {
  ...muiTypography,
  ...muiPalette,
  ...muiBreakpoints,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiUseMediaQuery: {
      noSsr: true, // There is a bug when not explicitly set https://github.com/mui-org/material-ui/issues/21142
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiPaper: {
      elevation: 3,
    },
  },
  spacing: 4,
};

export default muiTheme;
