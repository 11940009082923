// @ts-nocheck
import { actionChannel, take, call } from "redux-saga/effects";

const defaultActionMapper = (action) => action;

/**
 * Buffers processing of an action to process at most one at a time,
 * like a sequential task queue.
 */
// eslint-disable-next-line import/prefer-default-export
export function* bufferedActionProcessor(params = {}) {
  const {
    actionType, // action type name
    processorFn, // generator function (or promise??) to process the action
    actionMapper = defaultActionMapper, // optional function to map the action processor fn params
  } = params;

  const channel = yield actionChannel(actionType);
  const forever = true;

  while (forever) {
    const action = yield take(channel);
    yield call(processorFn, actionMapper(action));
  }
}
