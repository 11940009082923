// @ts-nocheck
import queryString from "query-string";
import { call } from "redux-saga/effects";

import request from "./request";

import { config } from "app/config";

const baseUrl = config.get("endpoints.gateway");

export function* fetchOrderSummaries({ userId, params }) {
  const url = `${baseUrl}/users/${userId}/order-snapshots?${queryString.stringify(
    params
  )}`;
  return yield call(request, url, {}, "json");
}

export function* fetchOrder({ orderId, params, tags }) {
  const url = `${baseUrl}/orders/${orderId}?${queryString.stringify(params)}`;
  return yield call(request, url, { tags }, "json");
}

export function* authActiveOrder(activeOrderId) {
  const url = `${baseUrl}/orders/${activeOrderId}/payments/authorize`;

  return yield call(request, url, { method: "POST" });
}

// eslint-disable-next-line object-curly-newline
export function* updateVariantQuantity({
  orderId,
  variantId,
  quantity,
  params,
  metadata,
}) {
  const paramsWithDefaults = {
    expand: "order",
    ...params, // spread after defaults to allow overrides
  };
  const url = `${baseUrl}/orders/${orderId}/items/${variantId}/set?${queryString.stringify(
    paramsWithDefaults
  )}`;
  const body = JSON.stringify({ quantity, metadata });
  return yield call(request, url, { method: "PUT", body }, "json");
}

// eslint-disable-next-line object-curly-newline
export function* setOrderStatus({ orderId, status, reason, reasonCode }) {
  const url = `${baseUrl}/orders/${orderId}/status`;
  const body = JSON.stringify({ status, reason, reasonCode });

  return yield call(request, url, { method: "POST", body });
}

export function* validateCoupon(code, userId?: string) {
  let url = `${baseUrl}/coupons/code/${encodeURIComponent(code)}/validate`;

  if (userId) {
    url = `${url}?userId=${userId}`;
  }

  return yield call(request, url, {}, "json");
}

export function* removeCoupon(orderId, couponId) {
  const url = `${baseUrl}/orders/${orderId}/coupons/${couponId}?expand=order&coupons=true`;
  return yield call(request, url, { method: "DELETE" }, "json");
}

// From a previous experiment, recommended-products
// Not currently being used, but leaving the setup for the API here
export function* fetchProductRecommendations({ userId, ...params }) {
  const url = `${baseUrl}/users/${userId}/product-recommendations?${queryString.stringify(
    params
  )}`;
  return yield call(request, url, {}, "json");
}

export function* createOrder(params) {
  const url = `${baseUrl}/orders/create-order`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* addBoxesToOrder(orderId, params) {
  const url = `${baseUrl}/orders/${orderId}/push-boxes?expand=order`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* fetchOrderPosit(params) {
  const url = `${baseUrl}/orders/posit`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json");
}
