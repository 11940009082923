import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { getImageURL } from "app/ui/global/utils";

import BlinkingEyes, {
  Direction,
  BlinkingEyesBaseProps,
} from "app/ui/designSystem/molecules/animations/BlinkingEyes";
import {
  ProductType,
  ProductLayoutConfigType,
  getProductLayoutConfig,
} from "app/ui/designSystem/molecules/BlinkingEyedProduct/utils";

export interface BlinkingEyedProductProps
  extends BlinkingEyesBaseProps,
    React.HTMLAttributes<HTMLDivElement> {
  product: ProductType;
}

interface WrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  $productLayoutConfig: ProductLayoutConfigType;
}

interface ProductProps extends React.HTMLAttributes<HTMLImageElement> {
  $direction: Direction;
}

const BlinkingEyedProduct: React.FC<BlinkingEyedProductProps> = ({
  product = "potato",
  direction = "right",
  id = "blinking-eyed-product",
  gaze,
  className,
}) => {
  const productLayoutConfig = getProductLayoutConfig(product, direction);
  const imageUrl = getImageURL(
    product,
    "website-assets/product-illustrations",
    {
      e: "trim",
      w: productLayoutConfig.productWidth,
      dpr: 2.0,
    }
  );
  return (
    <SCBlinkingEyedProductWrapper
      className={className}
      $productLayoutConfig={productLayoutConfig}
      id={id}
    >
      <SCProductWrapper $direction={direction} src={imageUrl} alt="" />
      <SCBlinkingEyesWrapper $productLayoutConfig={productLayoutConfig}>
        <BlinkingEyes
          direction={direction}
          gaze={gaze}
          width={productLayoutConfig.eyesWidth}
        />
      </SCBlinkingEyesWrapper>
    </SCBlinkingEyedProductWrapper>
  );
};

const SCBlinkingEyedProductWrapper = styled.div<WrapperProps>`
  position: relative;
  width: ${({ $productLayoutConfig }) =>
    rem($productLayoutConfig.productWidth)};
`;

const SCProductWrapper = styled.img<ProductProps>`
  ${({ $direction }) =>
    $direction === "left" &&
    `
    transform: scaleX(-1);
  `}
`;

const SCBlinkingEyesWrapper = styled.div<WrapperProps>`
  position: absolute;
  ${({ $productLayoutConfig }) => {
    return `
      top: ${rem($productLayoutConfig.topOffset)};
      left: ${rem($productLayoutConfig.leftOffset)};
      width: ${rem($productLayoutConfig.eyesWidth)};
      transform: rotate(${$productLayoutConfig.eyesRotation}deg);
    `;
  }}
`;

export default BlinkingEyedProduct;
