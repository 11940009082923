import { take, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/split";
import { isSplitInitialized, splitTreatmentsInitialized } from "app/selectors";

// eslint-disable-next-line import/prefer-default-export
export function* waitForSplitInitialization() {
  const ready = yield select(isSplitInitialized);

  if (!ready) {
    yield take([
      ACTION_TYPES.SPLIT_READY,
      ACTION_TYPES.SPLIT_READY_WITH_EVALUATIONS,
      ACTION_TYPES.SPLIT_TIMEDOUT,
    ]);
  }
}

export function* waitForSplitTreatmentsInitialized() {
  const treatmentsInitialized = yield select(splitTreatmentsInitialized);
  if (!treatmentsInitialized) {
    yield take([
      ACTION_TYPES.GET_TREATMENTS_SUCCEEDED,
      ACTION_TYPES.GET_TREATMENTS_FAILED,
    ]);
  }
}
