import baseTheme from "app/styles/theme";

const { offeringPreviewCard } = baseTheme.layout;

export const DEFAULT_CATALOG_SCROLL_DEBOUNCE_PERIOD = 0;
export const DEFAULT_CATALOG_SCROLL_REFRESH_INTERVAL = 100;

export const RECURRING_ITEMS_NUDGE_THROTTLE = 75; // in seconds

// Offering Grid

const DEFAULT_GRID_COLUMN_GAP = 16;
const DEFAULT_GRID_MARGIN = 48;

export const getGridColMinWidth = ({
  numColumns,
  columnGap = DEFAULT_GRID_COLUMN_GAP,
  margin = DEFAULT_GRID_MARGIN,
  offset = 0,
}: {
  numColumns: number;
  columnGap?: number;
  margin?: number;
  offset?: number;
}) => {
  return (
    offeringPreviewCard.minWidth * numColumns +
    columnGap * (numColumns - 1) +
    margin * 2 +
    offset
  );
};

export const getGridColMaxWidth = ({
  numColumns,
  columnGap = DEFAULT_GRID_COLUMN_GAP,
  margin = DEFAULT_GRID_MARGIN,
  offset = 0,
}: {
  numColumns: number;
  columnGap?: number;
  margin?: number;
  offset?: number;
}) => {
  return (
    offeringPreviewCard.maxWidth * numColumns +
    columnGap * (numColumns - 1) +
    margin * 2 +
    offset
  );
};
