// @ts-nocheck
// https://fontawesome.com/how-to-use/with-the-api/other/tree-shaking
// Use deep imports to reduce bundle size
import {
  faFacebookF,
  faInstagram,
  faPinterestP,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowCircleLeft,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faBars,
  faBoxOpen,
  faCircle,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faClock,
  faDollarSign,
  faExclamationTriangle,
  faFilter,
  faGift,
  faHome,
  faInfoCircle,
  faLemon,
  faLock,
  faMinus,
  faPlus,
  faPlusCircle,
  faShoppingCart,
  faShare,
  faSearch,
  faTruck,
  faTimes,
  faTimesCircle,
  faUtensils,
  faUser,
} from "@fortawesome/free-solid-svg-icons/";

export default [
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowCircleLeft,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faBars,
  faBoxOpen,
  faCircle,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faClock,
  faDollarSign,
  faExclamationTriangle,
  faFilter,
  faGift,
  faHome,
  faInfoCircle,
  faLemon,
  faLock,
  faMinus,
  faPlus,
  faPlusCircle,
  faShoppingCart,
  faShare,
  faSearch,
  faTruck,
  faTimes,
  faTimesCircle,
  faUtensils,
  faUser,
  faFacebookF,
  faTwitter,
  faInstagram,
  faPinterestP,
];
