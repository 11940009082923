export enum ShopperOptionsType {
  ADULTS = "adults",
  KIDS = "kids",
  PETS = "pets",
}

export type ShopperType = {
  [key in ShopperOptionsType]: number;
};

export enum DietType {
  VEGETARIAN = "Vegetarian",
  VEGAN = "Vegan",
  OMNIVORE = "I eat it all",
}

export enum ProduceType {
  ORGANIC = "organic",
  CONVENTIONAL = "conventional",
  BOTH_PRODUCE = "both",
}

export const PRODUCE_SLIDER_MIN = 0;
export const PRODUCE_SLIDER_MAX = 100;

export const PRODUCE_TYPE_RANGES = {
  [ProduceType.CONVENTIONAL]: {
    min: PRODUCE_SLIDER_MIN,
    max: (PRODUCE_SLIDER_MAX - PRODUCE_SLIDER_MIN) / 2 + PRODUCE_SLIDER_MIN - 1,
  },
  [ProduceType.BOTH_PRODUCE]: {
    min: (PRODUCE_SLIDER_MAX - PRODUCE_SLIDER_MIN) / 2 + PRODUCE_SLIDER_MIN,
    max: (PRODUCE_SLIDER_MAX - PRODUCE_SLIDER_MIN) / 2 + PRODUCE_SLIDER_MIN,
  },
  [ProduceType.ORGANIC]: {
    min: (PRODUCE_SLIDER_MAX - PRODUCE_SLIDER_MIN) / 2 + PRODUCE_SLIDER_MIN + 1,
    max: PRODUCE_SLIDER_MAX,
  },
};

export enum GroceryType {
  SNACKS = "snacks",
  MEAT_AND_FISH = "meat and fish",
  PLANT_BASED = "plant-based",
  PRODUCE = "produce",
  PANTRY = "pantry",
  DAIRY = "dairy",
}

export enum HouseholdType {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const HOUSEHOLD_RANGES = {
  [HouseholdType.SMALL]: {
    min: 1,
    max: 2,
  },
  [HouseholdType.MEDIUM]: {
    min: 3,
    max: 4,
  },
  [HouseholdType.LARGE]: {
    min: 5,
    max: 15,
  },
};
