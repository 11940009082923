import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "friendbuy";

const prefixes = {
  FETCH_REFERRAL_COUPON: `${domain}/FETCH_REFERRAL_COUPON`,
  // NC-1499 Remove legacy friendbuy
  APPLY_REFERRAL: `${domain}/APPLY_REFERRAL`,
  APPLY_ATTRIBUTION: `${domain}/APPLY_ATTRIBUTION`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
  READY: `${domain}/READY`,
  FRIENDBUY_INITIALIZED: `${domain}/FRIENDBUY_INITIALIZED`,

  VISITOR_STATUS: `${domain}/VISITOR_STATUS`,
  COUPON_RECEIVED: `${domain}/COUPON_RECEIVED`,
  WIDGET_TRIGGERED: `${domain}/WIDGET_TRIGGERED`,

  CAPTURE_REFERRAL: `${domain}/CAPTURE_REFERRAL`,

  // NC-1499 Remove legacy friendbuy
  SET_REFERRAL: `${domain}/SET_REFERRAL`,
};
