// @ts-nocheck
import { rem } from "polished";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { compose, withHandlers, lifecycle } from "recompose";
import styled from "styled-components";

import { fetchCMSMenuByUID } from "app/reducers/cms";
import {
  toggleTopNav,
  toggleCart,
  hideTopNav,
  hideModal,
  showModal,
  accountMenuOpened,
} from "app/reducers/ui";
import { goToCloseModal } from "app/router/routes";
import {
  isInitialized,
  isTopNavVisible,
  isImpersonated,
  getCMSMenuByUID,
  getUserEmail,
  isLoggedIn,
  isUserActive,
  canUserSignup,
  isUserLapsed,
  isMaintenanceMode,
  isAnyFullScreenModalVisible,
  isCartVisible,
  getOrderMinimum,
  canUserCustomize,
  getHeaderBannerConfigForRoute,
  isOnShoppingPage,
  isOnHomePage,
  isInShoppingMobileTreatment,
  getAnonHeaderBannerConfigForRoute,
  shouldRenderModalContent,
  shouldRenderAccountPage,
  shouldRenderOrdersPage,
} from "app/selectors";
import { HEADER_NAV_UID } from "app/constants";
import breakpoints, {
  useXLargeScreen,
  breakpointsNonScaling,
} from "app/styles/breakpoints";
import grid from "app/styles/grid";
import loadable from "app/ui/global/loadable";
import AccessiblityHelpInfo from "app/ui/header/AccessibilityHelpInfo";
import HeaderBanner from "app/ui/header/HeaderAlertBanner";
import SnackbarContainer, {
  ACCOUNT_ROUTES_SNACKBAR_CONTAINER_ID,
} from "app/ui/designSystem/molecules/Snackbar/SnackbarContainer";
import BackToTopTarget from "app/ui/shopping/BackToTopTarget";
import connected from "connected";
import LoggedOutAlertBanner from "./LoggedOutHeaderAlertBanner";

const hideFallback = true; // we don't want to show a loading notice within the Header
const HeaderMobile = loadable(() => import("./HeaderMobile"), hideFallback);
const HeaderDesktop = loadable(() => import("./HeaderDesktop"), hideFallback);

const mapStateToProps = (state) => {
  return {
    initialized: isInitialized(state),
    maintenanceMode: isMaintenanceMode(state),
    menuVisible: isTopNavVisible(state),
    menuContent: getCMSMenuByUID(state, { uid: HEADER_NAV_UID }),
    impersonated: isImpersonated(state),
    email: getUserEmail(state),
    loggedIn: isLoggedIn(state),
    userActive: isUserActive(state),
    canSignup: canUserSignup(state),
    userLapsed: isUserLapsed(state),
    fullScreenModalVisible: isAnyFullScreenModalVisible(state),
    cartVisible: isCartVisible(state),
    orderMinimum: getOrderMinimum(state),
    withinCustomizeWindow: canUserCustomize(state),
    headerBannerConfig: getHeaderBannerConfigForRoute(state),
    anonHeaderBannerConfig: getAnonHeaderBannerConfigForRoute(state),
    onShopping: isOnShoppingPage(state),
    onHomePage: isOnHomePage(state),
    shoppingMobileTreatment: isInShoppingMobileTreatment(state),
  };
};

const mapDispatchToProps = {
  toggleTopNav,
  toggleCart,
  hideTopNav,
  fetchCMSMenuByUID,
  hideModal,
  showModal,
  accountMenuOpened,
};

const enhanced = compose(
  connected(mapStateToProps, mapDispatchToProps),
  withHandlers({
    closeModal: () => () => {
      goToCloseModal();
    },
    handleMenuClick: ({
      actions,
      menuVisible, // refers to top nav
      fullScreenModalVisible,
    }) => () => {
      if (!menuVisible) actions.accountMenuOpened({ location: "Mobile" });
      if (fullScreenModalVisible) {
        actions.hideModal();
      }
      actions.toggleTopNav();
    },
    handleLogoClick: ({ actions, menuVisible, fullScreenModalVisible }) => (
      mobileMenuStyle
    ) => {
      if (mobileMenuStyle && menuVisible) {
        actions.hideTopNav();
      }
      if (fullScreenModalVisible) {
        actions.hideModal();
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.actions.fetchCMSMenuByUID(HEADER_NAV_UID);
    },
  })
);

const HeaderWrapper = (props) => {
  const {
    impersonated,
    className,
    loggedIn,
    headerBannerConfig,
    anonHeaderBannerConfig,
    shoppingMobileTreatment,
    onShopping,
    alertBannerHidden,
  } = props;

  const isNativeAndshouldRenderOrdersPage = useSelector(shouldRenderOrdersPage);
  const isNativeAndshouldRenderModalContent = useSelector(
    shouldRenderModalContent
  );
  const isNativeAndshouldRenderAccountPage = useSelector(
    shouldRenderAccountPage
  );
  const isInNativeAndShouldHideHeader =
    isNativeAndshouldRenderModalContent || isNativeAndshouldRenderAccountPage;

  if (isInNativeAndShouldHideHeader) {
    return null;
  }

  const showMobileHeader = useXLargeScreen();

  const renderBanner = () => {
    if (headerBannerConfig && loggedIn && !alertBannerHidden) {
      return (
        <HeaderBanner
          headerBannerConfig={headerBannerConfig}
          onShopping={onShopping}
          shoppingMobileTreatment={shoppingMobileTreatment}
        />
      );
    }

    if (anonHeaderBannerConfig) {
      return (
        <LoggedOutAlertBanner headerBannerConfig={anonHeaderBannerConfig} />
      );
    }

    return null;
  };

  return (
    <>
      <AccessiblityHelpInfo />
      {onShopping && <HeaderPlaceholder />}
      {showMobileHeader && <HeaderMobile {...props} />}
      {!showMobileHeader && (
        <StyledHeader
          role="banner"
          impersonated={impersonated}
          className={className}
          $loggedIn={loggedIn}
          $shoppingMobileTreatment={shoppingMobileTreatment}
        >
          <HeaderDesktop {...props} />
        </StyledHeader>
      )}
      <BackToTopTarget />
      {!isNativeAndshouldRenderOrdersPage && renderBanner()}
      <SnackbarContainer elementId={ACCOUNT_ROUTES_SNACKBAR_CONTAINER_ID} />
    </>
  );
};

HeaderWrapper.propTypes = {
  cartVisible: PropTypes.bool.isRequired,
  initialized: PropTypes.bool,
  impersonated: PropTypes.bool,
  email: PropTypes.string,
  loggedIn: PropTypes.bool,
  userActive: PropTypes.bool,
  canSignup: PropTypes.bool,
  userLapsed: PropTypes.bool,
  menuContent: PropTypes.object,
  menuVisible: PropTypes.bool.isRequired,
  maintenanceMode: PropTypes.bool.isRequired,
  fullScreenModalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleLogoClick: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    toggleTopNav: PropTypes.func.isRequired,
    toggleCart: PropTypes.func.isRequired,
    hideTopNav: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    fetchCMSMenuByUID: PropTypes.func.isRequired,
    accountMenuOpened: PropTypes.func.isRequired,
  }).isRequired,
  orderMinimum: PropTypes.number,
  withinCustomizeWindow: PropTypes.bool,
  className: PropTypes.string,
  onHomePage: PropTypes.bool.isRequired,
  headerBannerConfig: PropTypes.shape({
    icon: PropTypes.string,
    message: PropTypes.string,
    secondMessage: PropTypes.string,
    link: PropTypes.string,
    backgroundColor: PropTypes.string,
    shoppingOnly: PropTypes.bool,
  }),
  anonHeaderBannerConfig: PropTypes.shape({
    icon: PropTypes.string,
    message: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  shoppingMobileTreatment: PropTypes.bool,
};

const StyledHeader = styled.header`
  ${grid.row};
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadows.bottom};
  color: ${({ theme }) => theme.colors.greenDark};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.layout.zIndex.header};

  ${({ impersonated, theme }) =>
    impersonated &&
    `border-bottom: ${rem(5)} solid ${theme.colors.grapefruit}`};

  align-items: center;
  height: ${({ theme }) => rem(theme.layout.headerHeightMobile)};
  padding: 0.5em 1em;

  ${({ $loggedIn, $shoppingMobileTreatment }) =>
    $loggedIn &&
    !$shoppingMobileTreatment &&
    breakpoints.lg`
      justify-content: space-between;
    align-items: stretch;
    height: ${({ theme }) => rem(theme.layout.headerHeight)};
  `}

  ${breakpoints.xl`
    justify-content: space-between;
    align-items: stretch;
    height: ${({ theme }) => rem(theme.layout.headerHeight)};
  `}
`;

// use non scaling breakpoint here or header will cover the alert banner
const HeaderPlaceholder = styled.div`
  height: ${({ theme }) => rem(theme.layout.headerHeightMobile)};

  ${breakpointsNonScaling.xl`
    height: ${({ theme }) => rem(theme.layout.headerHeight)};
  `}
`;

export default enhanced(HeaderWrapper);
