import { FormikConfig } from "formik";
import _isNil from "lodash/isNil";
import _pick from "lodash/pick";
import { take, fork, call, put, select, all } from "redux-saga/effects";
import moment from "moment-timezone";

import ACTION_TYPES from "app/actionTypes/account";
import UI_ACTION_TYPES from "app/actionTypes/ui";
import { validateCoupon } from "app/api/orderService";
import {
  fetchUser,
  updateUser,
  fetchPaymentSources,
  addPaymentSource,
  updateSubscriptionBox,
  updateCadence,
  updateSubscriptionAddressQuery,
  updateSubscriptionAddress,
  validateAddress,
  createAddress,
  updateAddressDeliveryNotes,
  fetchUserSummary,
  createUserRecord,
  createSubscription,
  fetchSignupReasons,
  fetchCancellationReasons,
  fetchFutureOrders,
  updateSignupPreferences,
  addVacationHold,
  removeVacationHold,
  skipFutureOrder,
  unskipOrder,
  donateFutureOrder,
  undonateFutureOrder,
  applyCoupon,
  removeDeferredCoupon,
  cancelSubscription,
  fetchDateOffset,
  fetchUserCreditBalance,
  setSubscriptionDeliveryWindow,
  fetchUserCreditTransactions,
  setMessageAsRead,
  upsertRecurringItem,
  removeFromRecurringItems,
  setCadenceOffsetByDate,
  setAddOnBoxIds,
  enrollPlusMembership,
  setPlusMembershipAutoRenewal,
  addNeverItem,
  removeNeverItem,
} from "app/api/userService";
import { signup, updateAuthUserAttributes } from "app/api/cognito";
import { USER_CADENCE_WEEKLY, USER_CADENCE_BIWEEKLY } from "app/constants";
import { CancelReasonCode } from "app/constants/orders";
import { sendLog } from "app/monitoring";
import { showSnackbar, showModal, setDialog } from "app/reducers/ui";
import * as accountActions from "app/reducers/account";
import { cancelActiveOrder, PaymentStatus } from "app/reducers/orders";
import extractResponseMessage, {
  extractResponse,
} from "app/sagas/extractResponseMessage";
import fetchNextDeliveryFlow from "app/sagas/fetchNextDeliveryFlow";
import fetchSubPauseNextDeliveryFlow from "app/sagas/fetchSubPauseNextDeliveryFlow";
import fetchSubscriptionPositFlow from "app/sagas/fetchSubscriptionPositFlow";
import { reconcileOrderByAvailableActions } from "app/sagas/orderReconciliation";
import {
  authActiveOrderFlow,
  addBoxesToOrderFlow,
  setOrderStatusFlow,
} from "app/sagas/orders";
import {
  getUserId,
  getActiveOrder,
  getSubscriptionBoxDetails,
  getUserCadence,
  getUserCadenceOffset,
  getDefaultAddress,
  getUser,
  getCustomizationOpenAsMoment,
  getActiveOrderWithDeliveryInfo,
  getCurrentTimeForUserTimezone,
  getUserDeliveryWindowId,
  getFCTimezone,
  hasFailedAuthMessage,
  canUserCustomize,
  getUserSecondaryBoxIds,
  FAILED_AUTH_MESSAGE_ID,
  isNativeApp as getIsNativeApp,
  isOrderFulfillmentInMotion,
  WB_INCENTIVE_COUPON,
  getNextDeliveryDateFormatted,
  getAccountUserId,
} from "app/selectors";
import { LogLevel } from "app/types/monitoring";
import { SkipOrder } from "app/types/account/SkipOrderAction";
import ChangeAddressQueryResponse from "app/types/account/ChangeAddressQueryResponse";
import {
  AddVacationHold,
  RemoveVacationHold,
} from "app/types/account/VacationHoldActions";
import {
  UpdateDeliveryInfoWithValidationAction,
  UpdateDeliveryInfoQueryAction,
  UpdateDeliveryInfoAction,
} from "app/types/account/updateDeliveryInfo";
import CreateSubscriptionParams from "app/types/account/CreateSubscriptionParams";
import { AddressType } from "app/types/state/account/Account";
import AddPaymentSourceParams from "app/types/state/account/AddPaymentSourceParams";
import { SnackbarIds } from "app/types/ui/Snackbar";
import {
  DialogType,
  ADDRESS_VALIDATION_OPTION,
  QUERY_ACTION_OPTION,
} from "app/types/ui/Dialog";
import { LONG_DATE_FORMAT } from "app/ui/global/utils";
import { convertMomentTimezone } from "app/utils/momentUtils";
import { CancelSubscriptionAction } from "app/types/state/account/CancelSubscription";

export default function* rootAccount() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      ACTION_TYPES.FETCH_USER,
      ACTION_TYPES.FETCH_PAYMENT_SOURCES,
      ACTION_TYPES.ADD_PAYMENT_SOURCE,
      ACTION_TYPES.UPDATE_SUBSCRIPTION_BOX,
      ACTION_TYPES.UPDATE_DELIVERY_INFO,
      ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_STEP,
      ACTION_TYPES.UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP,
      ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED,
      ACTION_TYPES.FETCH_SIGNUP_REASONS,
      ACTION_TYPES.FETCH_CANCELLATION_REASONS,
      ACTION_TYPES.FETCH_NEXT_DELIVERY,
      ACTION_TYPES.FETCH_SUB_PAUSE_NEXT_DELIVERY,
      ACTION_TYPES.FETCH_FUTURE_ORDERS,
      ACTION_TYPES.SKIP_ORDER,
      ACTION_TYPES.SKIP_FUTURE_ORDER,
      ACTION_TYPES.UNSKIP_ORDER,
      ACTION_TYPES.DONATE_FUTURE_ORDER,
      ACTION_TYPES.UNDONATE_FUTURE_ORDER,
      ACTION_TYPES.APPLY_COUPON,
      ACTION_TYPES.REMOVE_COUPON,
      ACTION_TYPES.CANCEL_SUBSCRIPTION,
      ACTION_TYPES.FETCH_CREDIT_BALANCE,
      ACTION_TYPES.FETCH_CREDIT_TRANSACTIONS,
      ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST,
      ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST,
      ACTION_TYPES.SET_MESSAGE_AS_READ,
      ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS,
      ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS,
      ACTION_TYPES.SET_ADD_ON_BOX_IDS,
      ACTION_TYPES.ENROLL_PLUS_MEMBERSHIP,
      ACTION_TYPES.SET_PLUS_MEMBERSHIP_AUTORENEWAL,
      ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE,
      ACTION_TYPES.AUTO_SWITCH_CADENCE,
      ACTION_TYPES.VALIDATE_REWARD_COUPON,
      ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT,
      ACTION_TYPES.ADD_VACATION_HOLD,
      ACTION_TYPES.REMOVE_VACATION_HOLD,
      ACTION_TYPES.REMOVE_VACATION_HOLD_SUCCEEDED,
    ]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_USER: {
        yield fork(fetchUserFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_PAYMENT_SOURCES: {
        yield fork(fetchPaymentSourcesFlow);
        break;
      }
      case ACTION_TYPES.ADD_PAYMENT_SOURCE: {
        yield fork(addPaymentSourceFlow, action);
        break;
      }
      case ACTION_TYPES.UPDATE_SUBSCRIPTION_BOX: {
        yield fork(manageSubscriptionFlow, action);
        break;
      }
      case ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_STEP: {
        yield fork(updateDeliveryInfoValidationStepFlow, action);
        break;
      }
      case ACTION_TYPES.UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP: {
        yield fork(updateDeliveryInfoQueryFlow, action);
        break;
      }
      case ACTION_TYPES.UPDATE_DELIVERY_INFO: {
        yield fork(updateDeliveryInfoFlow, action);
        break;
      }
      case ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED: {
        yield put(showSnackbar(SnackbarIds.ADDRESS_UPDATED));
        break;
      }
      case ACTION_TYPES.FETCH_SIGNUP_REASONS: {
        yield fork(fetchSignupReasonsFlow);
        break;
      }
      case ACTION_TYPES.FETCH_CANCELLATION_REASONS: {
        yield fork(fetchCancellationReasonsFlow);
        break;
      }
      case ACTION_TYPES.FETCH_NEXT_DELIVERY: {
        yield fork(fetchNextDeliveryFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_SUB_PAUSE_NEXT_DELIVERY: {
        yield fork(fetchSubPauseNextDeliveryFlow);
        break;
      }
      case ACTION_TYPES.FETCH_FUTURE_ORDERS: {
        yield fork(fetchFutureOrdersFlow);
        break;
      }
      case ACTION_TYPES.SKIP_ORDER: {
        yield fork(skipOrderFlow, action);
        break;
      }
      case ACTION_TYPES.SKIP_FUTURE_ORDER: {
        yield fork(skipFutureOrderFlow, action);
        break;
      }
      case ACTION_TYPES.UNSKIP_ORDER: {
        yield fork(unskipOrderFlow, action);
        break;
      }
      case ACTION_TYPES.DONATE_FUTURE_ORDER: {
        yield fork(donateFutureOrderFlow, action);
        break;
      }
      case ACTION_TYPES.UNDONATE_FUTURE_ORDER: {
        yield fork(undonateFutureOrderFlow, action);
        break;
      }
      case ACTION_TYPES.APPLY_COUPON: {
        yield fork(applyCouponFlow, action);
        break;
      }
      case ACTION_TYPES.REMOVE_COUPON: {
        yield fork(removeCouponFlow);
        break;
      }
      case ACTION_TYPES.CANCEL_SUBSCRIPTION: {
        yield fork(cancelSubscriptionFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_CREDIT_BALANCE: {
        yield fork(fetchUserCreditBalanceFlow);
        break;
      }
      case ACTION_TYPES.FETCH_CREDIT_TRANSACTIONS: {
        yield fork(fetchUserCreditTransactionsFlow, action);
        break;
      }
      case ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST: {
        yield fork(addToNeverListFlow, action);
        break;
      }
      case ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST: {
        yield fork(removeFromNeverListFlow, action);
        break;
      }
      case ACTION_TYPES.SET_MESSAGE_AS_READ: {
        yield fork(setMessageAsReadFlow, action);
        break;
      }
      case ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS: {
        yield fork(addProductToRecurringItemsFlow, action);
        break;
      }

      case ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS: {
        yield fork(removeProductFromRecurringItemsFlow, action);
        break;
      }
      case ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE: {
        yield fork(setAllowRecurringItemsNudgeFlow, action);
        break;
      }
      case ACTION_TYPES.SET_ADD_ON_BOX_IDS: {
        yield fork(setAddOnBoxIdsFlow, action);
        break;
      }
      case ACTION_TYPES.ENROLL_PLUS_MEMBERSHIP: {
        yield fork(enrollPlusMembershipFlow, action);
        break;
      }
      case ACTION_TYPES.SET_PLUS_MEMBERSHIP_AUTORENEWAL: {
        yield fork(setPlusMembershipAutoRenewalFlow, action);
        break;
      }
      case ACTION_TYPES.AUTO_SWITCH_CADENCE: {
        yield fork(autoSwitchCadenceFlow);
        break;
      }
      case ACTION_TYPES.VALIDATE_REWARD_COUPON: {
        yield fork(validateRewardCoupon);
        break;
      }
      case ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT: {
        yield fork(fetchSubscriptionPositFlow, action);
        break;
      }
      case ACTION_TYPES.ADD_VACATION_HOLD: {
        yield fork(addVacationHoldFlow, action);
        break;
      }
      case ACTION_TYPES.REMOVE_VACATION_HOLD: {
        yield fork(removeVacationHoldFlow, action);
        break;
      }
      case ACTION_TYPES.REMOVE_VACATION_HOLD_SUCCEEDED: {
        yield put(showSnackbar(SnackbarIds.REMOVE_SUBSCRIPTION_PAUSE));
        break;
      }
      default:
        break;
    }
  }
}

function* resyncUI() {
  yield all([
    yield call(fetchFutureOrdersFlow),
    yield call(fetchNextDeliveryFlow),
    yield call(fetchUserFlow),
  ]);
}

export function* fetchUserFlow(action: { tags?: unknown } = {}) {
  try {
    const { tags } = action;
    const userId = yield select(getUserId);

    const user = yield call(fetchUser, { userId, tags });
    yield put({ ...action, type: ACTION_TYPES.FETCH_USER_SUCCEEDED, user });
  } catch (error) {
    yield put({ ...action, type: ACTION_TYPES.FETCH_USER_FAILED, error });
  }
}

export function* fetchUserSummaryFlow({ email }: { email: string }) {
  let user;
  try {
    user = yield call(fetchUserSummary, email);
    yield put({ type: ACTION_TYPES.FETCH_USER_SUMMARY_SUCCEEDED, user });
  } catch (error) {
    yield put({ type: ACTION_TYPES.FETCH_USER_SUMMARY_FAILED, email, error });
  }
  return user;
}

interface createUserFlowProps {
  email: string;
  attributes: Record<string, unknown>;
  signupPreferences: Record<string, unknown>;
  rethrow: boolean;
  futureMarket: boolean;
  impactClickId: string;
}

export function* createUserFlow({
  email,
  attributes,
  signupPreferences,
  rethrow = false,
  futureMarket,
  impactClickId,
}: createUserFlowProps) {
  try {
    // TODO: add branch session (if using), campaign, channel, etc
    const user = yield call(createUserRecord, {
      email,
      attributes,
      signupPreferences,
      futureMarket,
      impactClickId,
    });
    yield put({ type: ACTION_TYPES.CREATE_USER_SUCCEEDED, user });
  } catch (error) {
    yield put({ type: ACTION_TYPES.CREATE_USER_FAILED, email, error });
    if (rethrow) throw error;
  }
}

interface createCredentialsFlowProps {
  email: string;
  password: string;
  rethrow: boolean;
}

export function* createCredentialsFlow({
  email,
  password,
  rethrow = false,
}: createCredentialsFlowProps) {
  try {
    const userId = yield select(getAccountUserId);
    const response = yield call(signup, email, password, userId);
    yield put({ type: ACTION_TYPES.CREATE_CREDENTIALS_SUCCEEDED, ...response });
  } catch (e) {
    yield put({
      type: ACTION_TYPES.CREATE_CREDENTIALS_FAILED,
      error: e,
    });
    if (rethrow) throw e;
  }
}

interface updateUserFlowProps {
  values: Record<string, unknown>;
  rethrow: boolean;
}

export function* updateUserFlow({
  values,
  rethrow = false,
}: updateUserFlowProps) {
  const userId = yield select(getUserId);
  try {
    const user = yield call(updateUser, userId, values);
    yield put({ type: ACTION_TYPES.UPDATE_USER_SUCCEEDED, user });
  } catch (e) {
    yield put({ type: ACTION_TYPES.UPDATE_USER_FAILED, values });
    if (rethrow) throw e;
  }
}

interface updateUserFlowProps {
  values: Record<string, unknown>;
}

export function* setAllowRecurringItemsNudgeFlow({
  values,
}: updateUserFlowProps) {
  const userId = yield select(getUserId);
  try {
    const user = yield call(updateUser, userId, values);
    yield put({
      type: ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE_SUCCEEDED,
      user,
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE_FAILED,
      error,
      initialValue: !values.allowRecurringItemsNudge,
    });
  }
}

interface createAddressFlowProps {
  values: AddressType;
  rethrow: boolean;
  fuzzyValidation?: boolean;
}

export function* createAddressFlow({
  values,
  rethrow = false,
  fuzzyValidation,
}: createAddressFlowProps) {
  const userId = yield select(getUserId);
  try {
    const address = yield call(createAddress, userId, {
      ...values,
      fuzzyValidation, // Only use fuzzy validation while user is in address validation treatment
    });
    yield put({ type: ACTION_TYPES.CREATE_ADDRESS_SUCCEEDED, address });
  } catch (e) {
    yield put({ type: ACTION_TYPES.CREATE_ADDRESS_FAILED, values, e });
    if (rethrow) throw e;
  }
}

interface updateAddressDeliveryNotesFlowProps {
  addressId: string;
  deliveryNotes: string;
  rethrow: boolean;
}

export function* updateAddressDeliveryNotesFlow({
  addressId,
  deliveryNotes,
  rethrow = false,
}: updateAddressDeliveryNotesFlowProps) {
  try {
    yield call(updateAddressDeliveryNotes, addressId, deliveryNotes);
    yield put({
      type: ACTION_TYPES.UPDATE_ADDRESS_DELIVERY_NOTES_SUCCEEDED,
      addressId,
      deliveryNotes,
    });
  } catch (e) {
    yield put({
      type: ACTION_TYPES.UPDATE_ADDRESS_DELIVERY_NOTES_FAILED,
      addressId,
      deliveryNotes,
    });
    if (rethrow) throw e;
  }
}

export function* fetchPaymentSourcesFlow() {
  try {
    const userId = yield select(getUserId);

    // eslint-disable-next-line camelcase
    const { default_source, sources } = yield call(fetchPaymentSources, userId);
    yield put({
      type: ACTION_TYPES.FETCH_PAYMENT_SOURCES_SUCCEEDED,
      sources,
      default_source,
    });
  } catch (e) {
    const error = yield call(extractResponse, e);
    yield put({ type: ACTION_TYPES.FETCH_PAYMENT_SOURCES_FAILED, error });
  }
}

function* maybeCreateOrder() {
  const limitActionTypes = ["CREATE_ORDER"];
  const processedActions = yield call(
    reconcileOrderByAvailableActions,
    limitActionTypes
  );

  // can simply look at length since we limited to only one possible action
  const newOrderCreated = processedActions.length > 0;
  if (newOrderCreated) yield put(showModal("new-order-created", {}));
}

export function* addPaymentSourceFlow(action: AddPaymentSourceParams) {
  const { token, extractErrorMessage = true, rethrow = false } = action;
  try {
    const user = yield select(getUser);
    const { userId, subscriptionId } = user;
    const activeOrder = yield select(getActiveOrder);

    const card = yield call(addPaymentSource, userId, token);

    if (yield select(hasFailedAuthMessage)) {
      if (activeOrder && activeOrder.failedInitialAuth) {
        yield authActiveOrderFlow();
        yield fetchNextDeliveryFlow();
      } else {
        // Hasselback will set message as read upon successful order auth, so we only need to
        // explicitly set message as read if payment source update happens outside of order re-auth
        yield setMessageAsReadFlow({
          userId,
          messageId: FAILED_AUTH_MESSAGE_ID,
        });
      }
    }

    if (subscriptionId) {
      yield put({
        ...action,
        type: ACTION_TYPES.ADD_PAYMENT_SOURCE_SUCCEEDED,
        card,
      });
      yield put(accountActions.fetchPaymentSources({ resetStatus: false }));

      // a possible side effect of adding a payment source is the cancellation of an
      // internal billing hold - refetch user to capture that and try to create an order
      yield [call(fetchUserFlow), call(maybeCreateOrder)];

      // billing hold can be removed as well as an order created
      yield call(fetchNextDeliveryFlow);
    }
  } catch (error) {
    let failureMessage;

    if (extractErrorMessage) {
      failureMessage = yield call(extractResponseMessage, error);
    }

    yield put({
      ...action,
      type: ACTION_TYPES.ADD_PAYMENT_SOURCE_FAILED,
      error,
      failureMessage,
    });
    if (rethrow) throw error;
  }
}

export function* createSubscriptionFlow(params: CreateSubscriptionParams) {
  const userId = yield select(getUserId);
  const { rethrow = false, ...values } = params;
  try {
    const subscription = yield call(createSubscription, userId, values);
    yield put({
      type: ACTION_TYPES.CREATE_SUBSCRIPTION_SUCCEEDED,
      subscription,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.CREATE_SUBSCRIPTION_FAILED, e });
    if (rethrow) throw e;
  }
}

interface manageSubscriptionFlowProps {
  boxId: string;
  cadence: string;
  cadenceOffset: number;
  // FormikConfig generic to be typed in NC-292 - Not really sure if this should be a union
  formikConfig: FormikConfig<unknown> & { failureMessage: string };
}

export function* manageSubscriptionFlow({
  boxId,
  cadence,
  cadenceOffset,
  formikConfig,
}: manageSubscriptionFlowProps) {
  const { id: currentBoxId } = yield select(getSubscriptionBoxDetails);
  const currentCadence = yield select(getUserCadence);
  const currentCadenceOffset = yield select(getUserCadenceOffset);

  // First check if we're changing our cadence
  let shouldUpdateCadence = cadence !== currentCadence;

  // If the new cadence is not WEEKLY, check if the cadence needs to be changed
  // NOTE: current cadence offset can be null and the cadence from the form may be 0
  // we should only check if the cadence offset needs to change if the selected cadence
  // is biweekly (not weekly)
  if (cadence !== USER_CADENCE_WEEKLY) {
    shouldUpdateCadence =
      shouldUpdateCadence || cadenceOffset !== currentCadenceOffset;
  }

  try {
    // NOTE: if only one of these succeeds, the UI may still show an update error
    yield all([
      boxId !== currentBoxId && call(updateSubscriptionBoxFlow, boxId),
      shouldUpdateCadence && call(updateCadenceFlow, cadence, cadenceOffset),
    ]);
    yield call(fetchUserFlow);
    yield put({
      type: ACTION_TYPES.UPDATE_SUBSCRIPTION_BOX_SUCCEEDED,
      formikConfig,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_SUBSCRIPTION_BOX_FAILED,
      formikConfig: {
        ...formikConfig,
        failureMessage: `${formikConfig.failureMessage}`,
      },
    });
  }
}

function* updateSubscriptionBoxFlow(boxId: string, rethrow = true) {
  try {
    const userId = yield select(getUserId);
    return yield call(updateSubscriptionBox, userId, boxId);
  } catch (err) {
    if (rethrow) {
      throw err;
    }
    // @ts-ignore
    const { message } = yield err.json();
    return message;
  }
}

export function* updateCadenceFlow(
  cadence: string,
  cadenceOffset: number,
  rethrow: boolean = true
) {
  // can be null if going weekly > biweekly
  let offset = cadence === USER_CADENCE_WEEKLY ? null : cadenceOffset;
  try {
    const userId = yield select(getUserId);
    if (cadence !== USER_CADENCE_WEEKLY && _isNil(offset)) {
      // this looks like it can flip the offset value from what the user selected
      // going forward when we use actual dates in the UI for the offset drop down
      // we need to stop doing this.
      offset = yield call(getProjectedCadenceOffset);
    }
    const updateCadenceResult = yield call(
      updateCadence,
      userId,
      cadence,
      offset
    );

    try {
      yield call(maybeCreateOrder);
    } catch (error) {
      yield fork(sendLog, {
        message: "Error reconciling order after cadence update",
        level: LogLevel.ERROR,
        error,
      });
    }

    return updateCadenceResult;
  } catch (err) {
    if (rethrow) {
      throw err;
    }
    // @ts-ignore
    const { message } = yield err.json();
    return message;
  }
}

function* getProjectedCadenceOffset() {
  const { deliveryDate } = yield select(getActiveOrderWithDeliveryInfo);
  const customizationStart = yield select(getCustomizationOpenAsMoment);
  const now = yield select(getCurrentTimeForUserTimezone);

  const { biweekly } = yield call(fetchDateOffset, deliveryDate);
  if (now.isBefore(customizationStart)) {
    // flip cadence offset
    return biweekly === 0 ? 1 : 0;
  }
  return biweekly;
}

function* updateDeliveryInfoValidationStepFlow(
  action: UpdateDeliveryInfoWithValidationAction
) {
  const userId = yield select(getUserId);
  try {
    const address = _pick(action.params.values, [
      "address",
      "addressLine2",
      "city",
      "state",
      "zip",
      "deliveryWindowId",
    ]);

    const validatedAddress = yield call(validateAddress, userId, {
      ...address,
      fuzzyValidation: true,
    });
    const { isValid, suggestedAddress, flags } = validatedAddress;

    const updatedAction = { ...action };
    updatedAction.params.fuzzyValidation = true;
    if (!isValid) {
      // if invalid, track event
      yield put({
        type: ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_INVALID_ADDRESS,
        address,
        flags,
      });

      // if invalid, show address validation dialog
      yield put(
        setDialog({
          type: DialogType.ADDRESS_VALIDATION,
          addresses: {
            ...(suggestedAddress
              ? { [ADDRESS_VALIDATION_OPTION.SUGGESTED]: suggestedAddress }
              : {}),
            [ADDRESS_VALIDATION_OPTION.USER]: address,
          },
          showUserOptionOnly: !suggestedAddress,
          actionParams: updatedAction.params,
        })
      );
    } else {
      // else continue with usual delivery address update flow
      yield put(
        // @ts-ignore TODO: update updateAddressNextAction type to generic
        updatedAction.params.updateAddressNextAction(updatedAction.params)
      );
    }

    yield put({
      type: ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_STEP_SUCCEEDED,
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_STEP_FAILED,
      formikConfig: {
        ...action.params.formikConfig,
        failureMessage: `${action.params.formikConfig.failureMessage}`,
      },
    });
  }
}

// Figure out what we need to update when submitting the My Account > Delivery Address form
function* updateDeliveryInfoQueryFlow(action: UpdateDeliveryInfoQueryAction) {
  const userId = yield select(getUserId);
  const user = yield select(getUser);
  const {
    addressId,
    address,
    addressLine2,
    city,
    state,
    zip,
    deliveryNotes,
  } = yield select(getDefaultAddress);
  const deliveryWindowId = yield select(getUserDeliveryWindowId);

  const shouldUpdateAddress =
    action.address !== address ||
    (action.addressLine2 && action.addressLine2 !== addressLine2) || // adding/editing addressLine2
    (!action.addressLine2 && !!addressLine2) || // removing addressLine2
    action.city !== city ||
    action.state !== state ||
    action.zip !== zip;

  // Allow for updating delivery notes if nothing else in address has changed.  If updating full
  // address (creates new record), deliveryNotes will also get sent
  const shouldUpdateDeliveryNotes =
    !shouldUpdateAddress && action.deliveryNotes !== deliveryNotes;

  const shouldUpdateUser =
    action.phone !== user.phone ||
    action.firstName !== user.firstName ||
    action.lastName !== user.lastName;

  const shouldUpdateDeliveryWindow =
    action.deliveryWindowId !== deliveryWindowId;

  try {
    let queryResults;
    // Before updating the address, see which side effects need to be handled and allow for customer
    // confirmation before proceeding
    if (shouldUpdateAddress) {
      queryResults = yield call(updateAddressQueryFlow, userId, action);
    }

    if (shouldUpdateAddress) {
      if (!queryResults.isDeliverable) {
        // additional user confirmation required to cancel subscription
        yield put(
          setDialog({
            type: DialogType.ADDRESS_UPDATE_QUERY,
            queryAction: QUERY_ACTION_OPTION.CANCEL_SUBSCRIPTION,
            zip: action.zip,
            actionParams: {
              action, // includes formikConfig
              addressId,
              queryResults,
              shouldUpdateUser,
              shouldUpdateDeliveryNotes,
              shouldUpdateAddress: true,
              userId,
            },
          })
        );
      } else if (!queryResults.isSameZone && !!queryResults.activeOrderId) {
        // additional user confirmation required to cancel active order

        const nextDeliveryDate = yield select(getNextDeliveryDateFormatted, {
          dateFormat: LONG_DATE_FORMAT,
        });

        yield put(
          setDialog({
            type: DialogType.ADDRESS_UPDATE_QUERY,
            queryAction: QUERY_ACTION_OPTION.CANCEL_ACTIVEORDER,
            nextDeliveryDate,
            isCustomizationOpen: queryResults.isCustomizationOpen,
            actionParams: {
              action, // includes formikConfig
              addressId,
              queryResults,
              shouldUpdateUser,
              shouldUpdateDeliveryNotes,
              shouldUpdateAddress: true,
              userId,
            },
          })
        );
      } else {
        // No additional user confirmation required to update address
        yield put({
          type: ACTION_TYPES.UPDATE_DELIVERY_INFO,
          userId,
          addressId,
          action, // includes formikConfig
          shouldUpdateUser,
          shouldUpdateDeliveryNotes,
          shouldUpdateAddress: true,
          queryResults,
        });
      }
    } else {
      // No additional user confirmation required without an address update
      yield put({
        type: ACTION_TYPES.UPDATE_DELIVERY_INFO,
        userId,
        addressId,
        action, // includes formikConfig
        shouldUpdateUser,
        shouldUpdateDeliveryNotes,
        shouldUpdateDeliveryWindow,
        shouldUpdateAddress: false,
      });
    }
    yield put({
      type: ACTION_TYPES.UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP_SUCCEEDED,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP_FAILED,
      formikConfig: {
        ...action.formikConfig,
        failureMessage: `${action.formikConfig.failureMessage}`,
      },
    });
  }
}

function* updateAddressQueryFlow(
  userId: string,
  action: {
    zip: string;
    deliveryWindowId?: string;
  }
) {
  // send deliveryWindowId when new address is deliverable, also use zip if available
  const { zip, deliveryWindowId } = action;

  const updateAddressQueryParams = {
    ...(deliveryWindowId && { deliveryWindowId }),
    ...(zip && { zipCode: zip }),
  };

  return yield call(
    updateSubscriptionAddressQuery,
    userId,
    updateAddressQueryParams
  );
}

// We do not need to fetch NDD if the user is in the same zone
// If isSameZone is null, it means the user's address is no longer deliverable so we still want to fetch NDDs
export const getShouldFetchNextDeliveryInfo = (
  queryResults?: Pick<ChangeAddressQueryResponse, "isSameZone">
) => {
  return queryResults ? !queryResults.isSameZone : false;
};

function* updateDeliveryInfoFlow({
  userId,
  addressId,
  shouldUpdateUser,
  shouldUpdateDeliveryNotes,
  shouldUpdateAddress,
  shouldUpdateDeliveryWindow,
  action,
  queryResults,
}: UpdateDeliveryInfoAction) {
  try {
    // NOTE: if only one of these succeeds, the UI may still show an update error
    const [updateAddressResponse] = yield all([
      shouldUpdateAddress &&
        call(updateAddressFlow, { userId, action, queryResults }),
      shouldUpdateUser &&
        call(updateUser, userId, {
          phone: action.phone,
          firstName: action.firstName,
          lastName: action.lastName,
        }),
      shouldUpdateUser &&
        updateAuthUserAttributes({
          firstName: action.firstName,
          lastName: action.lastName,
        }),
      shouldUpdateDeliveryNotes &&
        call(updateAddressDeliveryNotes, addressId, action.deliveryNotes),
      shouldUpdateDeliveryWindow &&
        call(setSubscriptionDeliveryWindow, userId, action.deliveryWindowId),
    ]);

    // Refetch user to ensure FE is synched
    yield call(fetchUserFlow);

    const updatedAddress = yield select(getDefaultAddress);
    const updatedUser = yield select(getUser);

    // If a user changes their address, which results in a delivery window update, fetch the new delivery info so that
    // ad-hoc orders can be created with correct information and the rest of the UX is consistent with their new fulfillment data
    const shouldFetchNextDeliveryInfo = getShouldFetchNextDeliveryInfo(
      queryResults
    );

    if (shouldFetchNextDeliveryInfo) {
      yield call(fetchNextDeliveryFlow);
    }

    if (updateAddressResponse && !!updateAddressResponse.newOrderCreated) {
      yield put(showModal("new-order-created", { addressChange: true }));
    }

    // TODO: if address change caused subscription cancel, we'll want to update signupValues with
    // new address info.  Anything else to do upon subscription cancel?

    if (shouldUpdateAddress && queryResults && !queryResults.isDeliverable) {
      yield put(showModal("subscription-cancel-confirmation", {}));
    }

    yield put({
      type: ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED,
      phone: updatedUser.phone,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      address: updatedAddress,
      formikConfig: action.formikConfig,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_DELIVERY_INFO_FAILED,
      formikConfig: {
        ...action.formikConfig,
        failureMessage: `${action.formikConfig.failureMessage}`,
      },
    });
  }
}

interface updateAddressFlowProps {
  userId: UpdateDeliveryInfoAction["userId"];
  action: UpdateDeliveryInfoAction["action"];
  queryResults?: UpdateDeliveryInfoAction["queryResults"];
}

function* updateAddressFlow({
  userId,
  action,
  queryResults,
}: updateAddressFlowProps) {
  if (!queryResults) {
    return null;
  }
  const {
    isDeliverable,
    isSameZone,
    isCustomizationOpen,
    activeOrderId,
  } = queryResults;
  const address = {
    address: action.address,
    addressLine2: action.addressLine2,
    city: action.city,
    state: action.state,
    zip: action.zip,
    deliveryNotes: action.deliveryNotes,
  };

  return yield call(updateSubscriptionAddress, userId, {
    address,
    deliveryWindowId: action.deliveryWindowId,
    orderIdToUpdate: !!activeOrderId && isSameZone ? activeOrderId : null,
    orderIdToCancel: !!activeOrderId && !isSameZone ? activeOrderId : null,
    createNewOrder: !isSameZone && isCustomizationOpen,
    cancelSubscription: !isDeliverable,
    // cancelSubscriptionNotes,  // TODO: allow user to enter subsc cancellation notes
    notifyOOA: !isDeliverable, // TODO: allow for user opt-out
    fuzzyValidation: action.fuzzyValidation, // Only use fuzzy validation while user is in address validation treatment
  });
}

function* fetchSignupReasonsFlow() {
  try {
    const reasons = yield call(fetchSignupReasons);
    yield put({ type: ACTION_TYPES.FETCH_SIGNUP_REASONS_SUCCEEDED, reasons });
  } catch (e) {
    yield put({ type: ACTION_TYPES.FETCH_SIGNUP_REASONS_FAILED, e });
  }
}

function* fetchCancellationReasonsFlow() {
  try {
    const reasons = yield call(fetchCancellationReasons);
    yield put({
      type: ACTION_TYPES.FETCH_CANCELLATION_REASONS_SUCCEEDED,
      reasons,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.FETCH_CANCELLATION_REASONS_FAILED, e });
  }
}

export function* fetchFutureOrdersFlow() {
  try {
    const userId = yield select(getUserId);
    const orders = yield call(fetchFutureOrders, userId);
    yield put({ type: ACTION_TYPES.FETCH_FUTURE_ORDERS_SUCCEEDED, orders });
  } catch (e) {
    yield put({ type: ACTION_TYPES.FETCH_FUTURE_ORDERS_FAILED, e });
  }
}

interface updateSignupPreferencesFlow {
  userId: string;
  signupPreferences: Record<string, unknown>;
  rethrow: boolean;
}

export function* updateSignupPreferencesFlow({
  userId,
  signupPreferences,
  rethrow = false,
}: updateSignupPreferencesFlow) {
  try {
    const result = yield call(
      updateSignupPreferences,
      userId,
      signupPreferences
    );
    yield put({
      type: ACTION_TYPES.UPDATE_SIGNUP_PREFERENCES_SUCCEEDED,
      userId,
      signupPreferences: result,
    });
  } catch (e) {
    yield put({
      type: ACTION_TYPES.UPDATE_SIGNUP_PREFERENCES_FAILED,
      userId,
      signupPreferences,
      e,
    });
    if (rethrow) throw e;
  }
}

export function* addVacationHoldFlow({
  holdDurationUnit,
  holdDurationValue,
}: AddVacationHold) {
  try {
    const userId = yield select(getUserId);
    const userCurrentTimeMoment = yield select(getCurrentTimeForUserTimezone);
    const timezoneFC = yield select(getFCTimezone);

    if (!userCurrentTimeMoment || !timezoneFC) {
      throw Error("insufficient delivery window or timezone data");
    }

    // vacation holds are assumed to be in the FC timezone
    const startMoment = convertMomentTimezone({
      momentValue: userCurrentTimeMoment,
      timezoneToConvertTo: timezoneFC,
    });
    const endMoment = convertMomentTimezone({
      momentValue: userCurrentTimeMoment,
      timezoneToConvertTo: timezoneFC,
      addDurationValue: holdDurationValue,
      addDurationUnit: holdDurationUnit as moment.unitOfTime.DurationConstructor,
    });

    if (!startMoment || !endMoment) {
      throw Error("undefined moment");
    }
    const holdStartDate = startMoment.format("YYYY-MM-DD");
    const holdEndDate = endMoment.format("YYYY-MM-DD");

    const orderFulfillmentInMotion = yield select(isOrderFulfillmentInMotion);
    const activeOrder = yield select(getActiveOrder);
    // if there is an active order that is not yet locked, cancel the order.
    const shouldCancelOrder = activeOrder.orderId && !orderFulfillmentInMotion;

    yield all([
      shouldCancelOrder &&
        put(cancelActiveOrder(CancelReasonCode.SUBSCRIPTION_HOLD_SET)),
      call(addVacationHold, userId, { holdStartDate, holdEndDate }),
    ]);
    yield call(resyncUI);
    yield put({ type: ACTION_TYPES.ADD_VACATION_HOLD_SUCCEEDED });
  } catch (e) {
    yield put({ type: ACTION_TYPES.ADD_VACATION_HOLD_FAILED, e });
  }
}

export function* removeVacationHoldFlow({
  subscriptionHoldId,
}: RemoveVacationHold) {
  try {
    yield call(removeVacationHold, subscriptionHoldId);
    yield call(resyncUI);
    yield put({ type: ACTION_TYPES.REMOVE_VACATION_HOLD_SUCCEEDED });
  } catch (e) {
    yield put({ type: ACTION_TYPES.REMOVE_VACATION_HOLD_FAILED, e });
  }
}

export function* skipOrderFlow(params: SkipOrder) {
  const { newCadenceOffsetBasisDate } = params;
  try {
    yield call(setOrderStatusFlow, {
      newCadenceOffsetBasisDate,
      status: PaymentStatus.CANCELED,
      reasonCode: CancelReasonCode.SKIPPED_BY_CUSTOMER,
      reason: "",
    });
    yield put({ type: ACTION_TYPES.SKIP_ORDER_SUCCEEDED });
  } catch (e) {
    yield put({ type: ACTION_TYPES.SKIP_ORDER_FAILED, e });
  }
}

export function* skipFutureOrderFlow({
  deliveryDate,
  originalDeliveryDate,
  newCadenceOffsetBasisDate,
}: SkipOrder) {
  try {
    const userId = yield select(getUserId);
    const params = {
      deliveryDate,
      ...(originalDeliveryDate && { originalDeliveryDate }),
    };
    yield call(skipFutureOrder, userId, params);
    if (newCadenceOffsetBasisDate) {
      yield call(setCadenceOffsetFlow, { userId, newCadenceOffsetBasisDate });
    }
    yield call(resyncUI);
    yield put({ type: ACTION_TYPES.SKIP_FUTURE_ORDER_SUCCEEDED });
  } catch (e) {
    yield put({ type: ACTION_TYPES.SKIP_FUTURE_ORDER_FAILED, e });
  }
}

interface donateFutureOrderFlowProps {
  deliveryDate: string;
  originalDeliveryDate: string;
  donationPartnerId: string;
  donationPartner: string;
}

export function* donateFutureOrderFlow({
  deliveryDate,
  originalDeliveryDate,
  donationPartnerId,
  donationPartner,
}: donateFutureOrderFlowProps) {
  try {
    const userId = yield select(getUserId);
    const params = {
      deliveryDate,
      ...(originalDeliveryDate && { originalDeliveryDate }),
      ...(donationPartnerId && { donationPartnerId }),
      ...(donationPartner && { donationPartner }),
    };
    yield call(donateFutureOrder, userId, params);
    yield call(resyncUI);
    yield put({
      type: ACTION_TYPES.DONATE_FUTURE_ORDER_SUCCEEDED,
      deliveryDate,
      originalDeliveryDate,
      donationPartner,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.DONATE_FUTURE_ORDER_FAILED, e });
  }
}

interface undonateFutureOrderFlowProps {
  deliveryDate: string;
  originalDeliveryDate: string;
}

export function* undonateFutureOrderFlow({
  deliveryDate,
  originalDeliveryDate,
}: undonateFutureOrderFlowProps) {
  try {
    const userId = yield select(getUserId);
    const params = {
      deliveryDate,
      ...(originalDeliveryDate && { originalDeliveryDate }),
    };
    yield call(undonateFutureOrder, userId, params);
    yield call(resyncUI);
    yield put({
      type: ACTION_TYPES.UNDONATE_FUTURE_ORDER_SUCCEEDED,
      deliveryDate,
      originalDeliveryDate,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.UNDONATE_FUTURE_ORDER_FAILED, e });
  }
}

interface setCadenceOffsetFlowProps {
  userId: string;
  newCadenceOffsetBasisDate: string;
}

export function* setCadenceOffsetFlow({
  userId,
  newCadenceOffsetBasisDate,
}: setCadenceOffsetFlowProps) {
  const newOffset = yield call(
    setCadenceOffsetByDate,
    userId,
    newCadenceOffsetBasisDate
  );
  yield put({
    type: ACTION_TYPES.CADENCE_OFFSET_UPDATED,
    offset: newOffset,
  });
}

interface unskipOrderFlowProps {
  deliveryDate: string;
  originalDeliveryDate: string;
}

export function* unskipOrderFlow({
  deliveryDate,
  originalDeliveryDate,
}: unskipOrderFlowProps) {
  try {
    const userId = yield select(getUserId);
    const params = {
      deliveryDate,
      ...(originalDeliveryDate && { originalDeliveryDate }),
    };
    yield call(unskipOrder, userId, params);
    yield call(resyncUI);
    yield put({ type: ACTION_TYPES.UNSKIP_ORDER_SUCCEEDED });
  } catch (e) {
    yield put({ type: ACTION_TYPES.UNSKIP_ORDER_FAILED, e });
  }
}

export function* validateRewardCoupon() {
  const userId = yield select(getUserId);
  try {
    yield call(validateCoupon, WB_INCENTIVE_COUPON.couponCode, userId);
    yield put({ type: ACTION_TYPES.SET_REWARD_COUPON_UNUSED, value: true });
  } catch (error) {
    // If error during validation, we skip the coupon step
    yield put({ type: ACTION_TYPES.SET_REWARD_COUPON_UNUSED, value: false });
  }
}

export function* applyCouponFlow(action: { code: string; source: unknown }) {
  const { code, source } = action;
  let order, coupon;
  try {
    const userId = yield select(getUserId);
    const response = yield call(applyCoupon, userId, code, source);
    if (response.orderId) {
      order = response;
    } else {
      coupon = response;
      yield call(fetchUserFlow);
    }
    yield put({
      ...action,
      type: ACTION_TYPES.APPLY_COUPON_SUCCEEDED,
      order,
      coupon,
    });
  } catch (response) {
    const failureMessage = `Coupon '${code}' could not be applied`;
    yield put({
      ...action,
      type: ACTION_TYPES.APPLY_COUPON_FAILED,
      failureMessage,
    });
  }
}

export function* removeCouponFlow() {
  try {
    const userId = yield select(getUserId);
    const response = yield call(removeDeferredCoupon, userId);
    if (!response.ok) throw response;
    yield call(fetchUserFlow);
    yield put({ type: ACTION_TYPES.REMOVE_COUPON_SUCCEEDED });
  } catch (e) {
    yield put({ type: ACTION_TYPES.REMOVE_COUPON_FAILED, e });
  }
}

export function* cancelSubscriptionFlow(action: CancelSubscriptionAction) {
  const isNativeApp = yield select(getIsNativeApp);
  const { values, showConfirmation = !isNativeApp } = action;
  try {
    const userId = yield select(getUserId);
    const data = {
      userId,
      ...values,
    };
    const response = yield call(cancelSubscription, data);
    if (!response.ok) throw response;
    yield put({ ...action, type: ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED });
    if (showConfirmation)
      yield put(showModal("subscription-cancel-confirmation", {}, true));
  } catch (response) {
    yield put({ ...action, type: ACTION_TYPES.CANCEL_SUBSCRIPTION_FAILED });
  }
}

export function* fetchUserCreditBalanceFlow() {
  try {
    const userId = yield select(getUserId);
    const { balance } = yield call(fetchUserCreditBalance, userId);
    yield put({ type: ACTION_TYPES.FETCH_CREDIT_BALANCE_SUCCEEDED, balance });
  } catch (e) {
    yield put({ type: ACTION_TYPES.FETCH_CREDIT_BALANCE_FAILED, e });
  }
}

interface fetchUserCreditTransactionsFlowProps {
  creditType: string;
}

export function* fetchUserCreditTransactionsFlow({
  creditType: type,
}: fetchUserCreditTransactionsFlowProps) {
  try {
    const userId = yield select(getUserId);
    const transactions = yield call(fetchUserCreditTransactions, userId, type);
    yield put({
      type: ACTION_TYPES.FETCH_CREDIT_TRANSACTIONS_SUCCEEDED,
      transactions,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.FETCH_CREDIT_TRANSACTIONS_FAILED, e });
  }
}

function* setMessageAsReadFlow(
  params: { userId?: string; messageId: string },
  rethrow = true
) {
  const { messageId } = params;
  let { userId } = params;
  try {
    if (!userId) userId = yield select(getUserId);
    yield call(setMessageAsRead, userId, messageId);
    yield call(fetchUserFlow);
    yield put({
      ...params,
      type: ACTION_TYPES.SET_MESSAGE_AS_READ_SUCCEEDED,
      userId,
      messageId,
    });
  } catch (error) {
    if (rethrow) throw error;
    yield put({
      ...params,
      type: ACTION_TYPES.SET_MESSAGE_AS_READ_FAILED,
      error,
    });
  }
}

type addToNeverListFlowProps = {
  productId: string;
  skipToast: boolean;
};

function* addToNeverListFlow({
  productId,
  skipToast,
}: addToNeverListFlowProps) {
  try {
    const userId = yield select(getUserId);

    const response = yield call(addNeverItem, userId, {
      itemType: "product",
      itemId: productId,
    });
    if (!response.ok) throw response;
    yield put({
      type: ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST_SUCCEEDED,
      skipToast,
      productId,
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST_FAILED,
      productId,
      error,
    });
  }
}

interface removeFromNeverListFlowProps {
  productId: string;
}

function* removeFromNeverListFlow({ productId }: removeFromNeverListFlowProps) {
  try {
    const userId = yield select(getUserId);

    const response = yield call(removeNeverItem, userId, {
      itemType: "product",
      itemId: productId,
    });
    if (!response.ok) throw response;
    yield put({
      type: ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_SUCCEEDED,
      productId,
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_FAILED,
      productId,
      error,
    });
  }
}

interface addProductToRecurringItemsFlowProps {
  productId: string;
  quantity: number;
  metadata: Record<string, unknown>;
  skipToast: boolean;
  name: string;
}

function* addProductToRecurringItemsFlow({
  productId,
  quantity,
  metadata,
  skipToast,
  name,
}: addProductToRecurringItemsFlowProps) {
  const userId = yield select(getUserId);
  try {
    const response = yield call(upsertRecurringItem, {
      userId,
      itemType: "product",
      itemId: productId,
      quantity,
      metadata,
    });
    if (!response.ok) throw response;
    yield put({
      type: ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS_SUCCEEDED,
      userId,
      productId,
      quantity,
      metadata,
      skipToast,
      name,
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS_FAILED,
      userId,
      productId,
      quantity,
      metadata,
      error,
    });
    yield call(fetchUserFlow); // re-fetch to ensure UI in sync
  }
}

interface removeProductFromRecurringItemsFlowProps {
  productId: string;
}

function* removeProductFromRecurringItemsFlow({
  productId,
}: removeProductFromRecurringItemsFlowProps) {
  const userId = yield select(getUserId);
  try {
    const response = yield call(removeFromRecurringItems, {
      userId,
      itemId: productId,
    });
    if (!response.ok) throw response;
    yield put({
      type: ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS_SUCCEEDED,
      productId,
      userId,
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS_FAILED,
      productId,
      userId,
      error,
    });
    yield call(fetchUserFlow); // re-fetch to ensure UI in sync
  }
}

function* setAddOnBoxIdsFlow({ addOnBoxIds }: { addOnBoxIds: string[] }) {
  const userId = yield select(getUserId);
  const existingAddOnBoxIds = yield select(getUserSecondaryBoxIds);
  try {
    const response = yield call(setAddOnBoxIds, userId, addOnBoxIds);
    if (!response.ok) throw response;
    yield put({
      type: ACTION_TYPES.SET_ADD_ON_BOX_IDS_SUCCEEDED,
      addOnBoxIds,
      userId,
    });

    // Also add to active order, if within custo
    const withinCustomization = yield select(canUserCustomize);
    const newAddOnBoxIds = addOnBoxIds.filter(
      (boxId) => !existingAddOnBoxIds.includes(boxId)
    );
    if (withinCustomization && newAddOnBoxIds.length > 0) {
      yield call(addBoxesToOrderFlow, { boxIds: newAddOnBoxIds });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.SET_ADD_ON_BOX_IDS_FAILED,
      addOnBoxIds,
      userId,
      error,
    });
  }
  yield call(fetchUserFlow); // re-fetch to ensure UI in sync
}

function* enrollPlusMembershipFlow(action: { type: string }) {
  try {
    const userId = yield select(getUserId);

    const membership = yield call(enrollPlusMembership, { userId });
    yield put({
      ...action,
      type: ACTION_TYPES.ENROLL_PLUS_MEMBERSHIP_SUCCEEDED,
      userId,
      membership,
    });
  } catch (error) {
    const failureMessage =
      "Unable to complete plus enrollment. Please try again.";
    yield put({
      ...action,
      type: ACTION_TYPES.ENROLL_PLUS_MEMBERSHIP_FAILED,
      failureMessage,
      error,
    });
  }
  yield call(fetchUserFlow); // re-fetch to ensure UI in sync
}

function* setPlusMembershipAutoRenewalFlow(action: {
  type: string;
  autorenew: boolean;
}) {
  const { autorenew } = action;
  try {
    const userId = yield select(getUserId);

    const membership = yield call(setPlusMembershipAutoRenewal, {
      userId,
      enabled: autorenew,
    });
    yield put({
      ...action,
      type: ACTION_TYPES.SET_PLUS_MEMBERSHIP_AUTORENEWAL_SUCCEEDED,
      userId,
      membership,
    });
  } catch (error) {
    const failureMessage =
      "Unable to change auto-enrollment. Please try again.";
    yield put({
      ...action,
      type: ACTION_TYPES.SET_PLUS_MEMBERSHIP_AUTORENEWAL_FAILED,
      failureMessage,
      error,
    });
  }
  yield call(fetchUserFlow); // re-fetch to ensure UI in sync
}

export function* autoSwitchCadenceFlow() {
  yield put({
    type: ACTION_TYPES.SET_SHOULD_AUTO_SWITCH_CADENCE_TO_BIWEEKLY,
    value: false,
  });
  const currentCadence = yield select(getUserCadence);
  if (currentCadence !== USER_CADENCE_BIWEEKLY) {
    yield call(updateCadenceFlow, USER_CADENCE_BIWEEKLY, 0);
    yield call(fetchUserFlow);
    yield put({
      type: UI_ACTION_TYPES.SET_SWITCH_CADENCE_SNACK,
      value: true,
    });
    yield put({
      type: UI_ACTION_TYPES.SET_CADENCE_AUTO_SWITCHED,
      value: true,
    });
  }
}
