import _noop from "lodash/noop";
import { sanitizer } from "@imperfectproduce/node-logger";

import { config } from "app/config";
import {
  SetContextLibFunc,
  LogLevel,
  datadogLogsType,
} from "app/types/monitoring";

export const datadogLogsNoop = {
  logger: {
    debug: _noop,
    info: _noop,
    warn: _noop,
    error: _noop,
  },
  setLoggerGlobalContext: _noop,
} as datadogLogsType;

const datadogLogs = () => window.DD_LOGS || datadogLogsNoop;

const globalCtx = {
  tags: ["client-side", config.get("datadog.tag")],
};

export const setContext: SetContextLibFunc = (context) => {
  datadogLogs().setLoggerGlobalContext({
    ...globalCtx,
    ...context,
  });
};

export const sendLog = ({
  level = LogLevel.ERROR,
  tags: lineTags = [],
  ...others
}) => {
  // https://github.com/imperfectproduce/node-logger/blob/main/src/logger.ts#L81
  const { tags: ctxTags = [], ...ctx } = globalCtx;
  const tags = [...new Set(ctxTags.concat(lineTags))];

  const sanitizedParams = sanitizer(
    {
      ...ctx,
      ...others,
      tags,
      timestamp: new Date(),
    },
    ["creditcard"],
    ["dd"]
  );

  datadogLogs().logger[level](sanitizedParams.message, sanitizedParams);
};
