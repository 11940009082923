import * as Monitoring from "app/monitoring";
import _debounce from "lodash/debounce";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import moment, { Moment } from "moment-timezone";
import { withPropsOnChange, mapProps } from "recompose";

import {
  LOCKED,
  DISPATCHED,
  ROUTED,
  PACKED,
  SHIPPED,
  WAVED,
  PaymentStatus,
} from "app/reducers/orders";
import reverseRouteLookup from "app/router/reverseRouteLookup";
import theme from "app/styles/theme";
import Box from "app/types/state/boxes/Box";
import { FormatDeliveryWindow } from "app/types/selectors/deliveries";
import { Location } from "app/types/state/extendedRouter";
import {
  CloudinaryConditional,
  CloudinaryTransformOptions,
} from "app/types/cloudinary";
import { LogLevel } from "app/types/monitoring";
import escapeRegExp from "app/utils/escapeRegExp";

export const UI_DEBOUNCE_RATE = 500;

// Generic object type
interface Object<T> {
  [key: string]: T;
}

/* eslint-disable import/prefer-default-export */
export const getTitle = (path: string) => {
  const selectedRoute = reverseRouteLookup(path);
  return selectedRoute && selectedRoute.title ? selectedRoute.title : null;
};
/* eslint-enable import/prefer-default-export */

export const convertObjectToCloudinaryParams = (
  obj: CloudinaryTransformOptions = {}
) => {
  return (
    Object.keys(obj)
      // @ts-ignore
      .filter((p) => !!obj[p])
      .map((p) => {
        // @ts-ignore
        return `${p}_${obj[p]}`;
      })
      .join(",")
  );
};

export const convertObjectToCloudinaryParamsNoDefaultVersion = (
  obj: CloudinaryTransformOptions = {}
) => {
  return (
    Object.keys(obj)
      // @ts-ignore
      .filter((p) => !!obj[p])
      .map((p) => {
        if (p === "d" && obj[p]?.startsWith("v")) {
          // remove version if it exists
          const versionLastIndex = obj[p]?.indexOf(":");
          // @ts-ignore
          return `${p}_${obj[p]?.slice(versionLastIndex + 1)}`;
        }
        // @ts-ignore
        return `${p}_${obj[p]}`;
      })
      .join(",")
  );
};

export const convertObjectToCloudinaryParamsWithConditionals = (
  ifConditionalAndParams: {
    conditions: CloudinaryConditional[];
    params: CloudinaryTransformOptions;
  },
  elseParams: CloudinaryTransformOptions
) => {
  const { conditions, params } = ifConditionalAndParams;
  const conditional = conditions
    .map(({ property, operator, value }) => `${property}_${operator}_${value}`)
    .join("_and_");
  let url = `if_${conditional}/${convertObjectToCloudinaryParams(params)}`;
  if (elseParams) {
    const elseParamCloudinaryParam = convertObjectToCloudinaryParamsNoDefaultVersion(
      elseParams
    );
    if (elseParamCloudinaryParam)
      url = `${url}/if_else/${elseParamCloudinaryParam}`;
  }
  return `${url}/if_end`;
};

export const addImageTransformations = (
  imageUrl: string,
  transforms: CloudinaryTransformOptions
) => {
  const insertionPoint = "upload/";
  const insertIndex =
    imageUrl.lastIndexOf(insertionPoint) + insertionPoint.length;
  if (!insertIndex) return imageUrl;
  const transformString = convertObjectToCloudinaryParams(transforms);
  return `${imageUrl.slice(0, insertIndex)}${transformString}/${imageUrl.slice(
    insertIndex
  )}`;
};

export const defaultQualityParams = { fl: "lossy", q: "auto", f: "auto" };

/*
  Default quality params to let Cloudinary determine best image size (usually < 200kb)
  Technical details:
    https://cloudinary.com/documentation/image_optimization#how_to_optimize_image_format
  Specific recommendation to use these three parameters for web:
    https://support.cloudinary.com/hc/en-us/articles/202521522-How-can-I-make-my-images-load-faster-
  Orders for these flags matters in some cases, so maintain order (see above)
  f_auto (fetch_format auto) converts file formats best for browser if applicable
    (i.e. deliver images as WebP to Chrome)
  fl_lossy (flag lossy) converts non-transparent PNGs to JPGs, or GIFs with lossless compression
  q_auto (quality auto) reduces file size using intelligent quality and encoding algorithm
*/
export const getImageURL = (
  imageId: string | number,
  subdirectory: string | null = null,
  params: CloudinaryTransformOptions = {},
  qualityParams: CloudinaryTransformOptions = defaultQualityParams
) => {
  const subDirPath = subdirectory ? `/${subdirectory}/` : "/";
  const paramsArray = convertObjectToCloudinaryParams({
    ...params,
    ...qualityParams,
  });
  // @ts-ignore
  const imageOpts = paramsArray.length ? `/${paramsArray}` : ""; // eslint-disable-line @typescript-eslint/no-unused-vars
  let imageIdString = imageId.toString();
  if (!imageIdString.match(/\.(jpg|jpeg|png|gif|bmp|tiff|svg)$/i)) {
    imageIdString += ".png";
  }
  return `${theme.images.baseUrl}${subDirPath}${imageIdString}`;
};

export const getImageURLWithConditions = (
  imageId: string | number,
  subdirectory: string | null = null,
  baseParams: CloudinaryTransformOptions = {},
  qualityParams: CloudinaryTransformOptions = defaultQualityParams,
  ifConditionsAndParams: {
    conditions: CloudinaryConditional[];
    params: CloudinaryTransformOptions;
  },
  elseConditionsAndParams: CloudinaryTransformOptions,
  ext: string = ""
) => {
  const subDirPath = subdirectory ? `/${subdirectory}/` : "/";
  const paramsArray = convertObjectToCloudinaryParams({
    ...baseParams,
    ...qualityParams,
  });
  const conditionalParamsArray = convertObjectToCloudinaryParamsWithConditionals(
    ifConditionsAndParams,
    elseConditionsAndParams
  );
  let imageOpts = paramsArray.length ? `/${paramsArray}` : "";
  imageOpts = conditionalParamsArray.length
    ? `${imageOpts}/${conditionalParamsArray}`
    : imageOpts;
  return `${theme.images.baseUrl}${imageOpts}${subDirPath}${imageId}${
    ext && `.${ext}`
  }`;
};

/**
 * Gets the cloudinary URL for an image using getImageURL but also specifies an extension.
 * Some images being uploaded for products are PDF's so they need to be made into other image
 * file formats.
 */
export const getImageURLWithExt = (
  imageId: string | number,
  subdirectory: string | null = null,
  params: CloudinaryTransformOptions = {},
  ext: string,
  qualityParams: CloudinaryTransformOptions = defaultQualityParams
) => {
  return `${getImageURL(imageId, subdirectory, params, qualityParams)}.${ext}`;
};

export const getVideoURL = (
  videoId: string,
  subdirectory: string | null = null,
  params: Object<string> = {}
) => {
  const subDirPath = subdirectory ? `/${subdirectory}/` : "/";
  const paramsArray = convertObjectToCloudinaryParams(params);
  const videoOpts = paramsArray.length ? `/${paramsArray}` : "";
  return `${theme.videos.baseUrl}${videoOpts}${subDirPath}${videoId}`;
};

// strips existing file name (eg: '.jpg') and appends new one (eg: '.png')
export const changeFileFormat = (name: string, newExt: string) =>
  `${name.slice(0, name.indexOf("."))}.${newExt}`;

// Formats a value for use in React Select
// Optionally pass a labelTransform fn to apply formatting for the label,
// otherwise both use the same value
export const formatValueForSelect = (
  val: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelTransform?: ((args: any) => string) | unknown
) => {
  return {
    value: val,
    label:
      labelTransform && typeof labelTransform === "function"
        ? labelTransform(val)
        : val,
  };
};

const statesList = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  // VI: 'Virgin Islands',
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const states = Object.keys(statesList).map(formatValueForSelect);

export const cadences = [
  {
    value: "WEEKLY",
    label: "Every Week",
  },
  {
    value: "BIWEEKLY",
    label: "Every Other Week",
  },
];

export const cadenceOffsets = [
  {
    value: 0,
    label: "Even Weeks",
  },
  {
    value: 1,
    label: "Odd Weeks",
  },
];

export const phoneMask = "(000) 000-0000"; // uses https://github.com/uNmAnNeR/imaskjs

export const stripNonNumeric = (str: string) => str.replace(/\D/g, "");

export const LONG_DATE_FORMAT = "dddd, MMMM Do";
export const SHORT_DATE_FORMAT = "ddd, MMM D";
export const SHORT_DATE_FORMAT_2 = "ddd, MMM Do";
export const SHORT_DATE_FORMAT_3 = "dddd, MMM D";
export const DEFAULT_DAY_FORMAT = "ddd";
export const LONG_TIME_FORMAT = "h:mma";
export const SHORT_TIME_FORMAT = "ha";
export const TIME_DAY_FORMAT = "ha ddd";

export const formatTime = (m: Moment, format = LONG_TIME_FORMAT) =>
  m?.format(format);
export const formatDay = (m: Moment, format = DEFAULT_DAY_FORMAT) =>
  m?.format(format);
export const formatDate = (m: Moment, format = LONG_DATE_FORMAT) =>
  m?.format(format);

// Returns a human-readable version of delivery window
// eslint-disable-next-line object-curly-newline
export const formatDeliveryWindow = ({
  startDay,
  endDay,
  startTime,
  endTime,
  timezone,
}: FormatDeliveryWindow) => {
  const start = moment.tz(startTime, "HH:mm:ss", timezone).day(startDay);
  const end = moment.tz(endTime, "HH:mm:ss", timezone).day(endDay);

  if (startDay === endDay) {
    return `${formatDay(start)} - Before ${formatTime(end)}`;
  }

  return `${formatDay(start)} - Before ${formatDay(end)} ${formatTime(end)}`;
};

/*
 * Formats a number to currency.
 * https://stackoverflow.com/a/16233919
 */
export const formatCurrency = (value = 0, fractionDigits = 2) => {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
};

export const formatCurrencyWithoutSymbol = (value = 0, fractionDigits = 2) => {
  return value.toLocaleString("en-US", {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
};

export const formatMaxPrice = (box?: Box, fractionDigits = 2) => {
  return box && formatCurrency(box.maxPrice, fractionDigits);
};

export const orderIsInFulfillment = (status: PaymentStatus) =>
  status === LOCKED ||
  status === DISPATCHED ||
  status === ROUTED ||
  status === WAVED ||
  status === PACKED ||
  status === SHIPPED;

export const stripSpecialCharacters = (s: string) =>
  s.replace(/[^a-zA-Z0-9]/g, "");

export const stopPropagation = (e: MouseEvent | KeyboardEvent) =>
  e.stopPropagation();

// utility for chopping minutes off moment times that are on the hour
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMinuteFormat = (momentObj: any) => {
  return momentObj.clone().format("mm") === "00" ? "h" : "h:mm";
};

export const scrollWithOffset = (
  el: HTMLElement,
  offset = 0,
  smooth = false
) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    ...(smooth ? { behavior: "smooth" } : {}),
  });
};

// https://stackoverflow.com/a/494348
export const createElementFromHTML = (htmlString: string) => {
  const div = document.createElement("div");
  div.innerHTML = htmlString.trim();
  return div.firstChild;
};

export const parseURL = (url: string) => {
  const parser = document.createElement("a");
  parser.href = url;
  return parser;
};

// Adds tag/category to router location query object
export const applyFilter = (
  id: string,
  filterType: string | number,
  location: Location
) => {
  return {
    ...location,
    query: {
      // by default only allows one tag and category to be set
      ...location.query,
      [filterType]: id,
    },
  };
};

// Removes tag/category from router location query object
// Also see stripFilter in sagas/offerings.js for URL cleanup
export const removeFilter = (
  id: string,
  filterType: string,
  location: Location
) => {
  const filterQuery = location.query[filterType];

  if (filterQuery === id) {
    const newQuery = _omit(location.query, filterType);
    return {
      ...location,
      query: newQuery,
    };
  }
  return location;
};

export const makeDebouncedFunction = (
  functionName: string,
  debounceRate = UI_DEBOUNCE_RATE
) => {
  // recomposed debounce
  return withPropsOnChange(
    [functionName],
    (props: Object<(arg: unknown) => unknown>) => {
      return {
        [functionName]: _debounce(props[functionName], debounceRate),
      };
    }
  );
};

/**
 * collapseProps - a wrapper to recompose's mapProps, to pull props
 *  from the top level and collapse under a new key
 *
 * parentKey - the key to collapse the props under when returned
 * propsToCollapse - an array of strings corresponding to top-level props
 * mapAdditional - optional funciton to do extra prop mapping. Result is folded under parentKey
 * */
export const collapseProps = (
  parentKey: string,
  propsToCollapse: string[],
  mapAdditional?: (args: unknown) => Object<unknown>
) => {
  return mapProps((props: Object<unknown>) => {
    const relevantProps = _pick(props, propsToCollapse);
    const additionalProps =
      mapAdditional && typeof mapAdditional === "function"
        ? mapAdditional(props)
        : {};
    return {
      ..._omit(props, propsToCollapse),
      [parentKey]: {
        ...relevantProps,
        ...additionalProps,
      },
    };
  });
};

// https://gist.github.com/YuCJ/0a42afc1b578b2545195a7b688dcbab6
/**
 * A pure version of Array.prototype.splice
 * It will return a new array rather than mutate the array
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
 * @param {Array} array The target array
 * @param {number} start Index at which to start changing the array
 * @param {number} deleteCount An integer indicating the number of old array elements to remove
 * @param {any} items The elements to add to the array, beginning at the start index
 * @returns {Array}
 */
/* eslint-disable no-underscore-dangle */
export function pureSplice<T>(
  array: T[],
  start = 0,
  deleteCount = 0,
  ...items: T[]
) {
  const arrayLength = array.length;
  const _deleteCount = deleteCount < 0 ? 0 : deleteCount;
  let _start;
  if (start < 0) {
    if (Math.abs(start) > arrayLength) {
      _start = 0;
    } else {
      _start = arrayLength + start;
    }
  } else if (start > arrayLength) {
    _start = arrayLength;
  } else {
    _start = start;
  }
  return [
    ...array.slice(0, _start),
    ...items,
    ...array.slice(_start + _deleteCount, arrayLength),
  ];
}
/* eslint-enable no-underscore-dangle */

export const objectHasOwnNestedProperty = (
  obj: Object<unknown> = {},
  propertyPath?: string
) => {
  if (!propertyPath) {
    return false;
  }

  const properties = propertyPath.split(".");
  let tempObj = obj;

  for (let i = 0; i < properties.length; i++) {
    const prop = properties[i];
    if (!tempObj || !Object.prototype.hasOwnProperty.call(tempObj, prop)) {
      return false;
    }
    tempObj = tempObj[prop] as Object<unknown>;
  }

  return true;
};

export const objectValid = (
  object: Object<unknown>,
  type: string,
  requiredFields: string[]
) => {
  const missingFields = requiredFields.filter((fieldPath) => {
    return !objectHasOwnNestedProperty(object, fieldPath);
  });
  const objectIsValid = missingFields.length < 1;
  if (!objectIsValid) {
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      message: `Invalid ${type} data`,
      missingFields,
      object,
    });
  }
  return objectIsValid;
};

export const isEnter = (key: string) => key === "Enter";

export const isSpace = (key: string) => key === " ";

export const isEscape = (key: string) => key === "Escape";

const onSpecificKeyPress = <T extends HTMLElement>(
  conditional: (key: string) => boolean
) => (handler: (args?: unknown) => unknown) => (
  e: React.KeyboardEvent<T> | KeyboardEvent
) => {
  if (conditional(e.key)) {
    e.preventDefault();
    handler();
  }
};

export const onEnterOrSpaceDown = onSpecificKeyPress(
  (key) => isEnter(key) || isSpace(key)
);

export const onEscape = onSpecificKeyPress((key) => isEscape(key));

export const onEnter = onSpecificKeyPress((key) => isEnter(key));

export const capitalize = (value: string = "") => {
  const lowercase = value.toLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const stripBrandNameFromOfferingName = (
  offeringName: string,
  brandName: string | null
) =>
  brandName
    ? offeringName
        .replace(new RegExp(`^${escapeRegExp(brandName)}`, "i"), "")
        .trim()
    : offeringName;
