import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Switch, Route } from "react-router-dom";

import history from "app/router/history";
import routes from "app/router/routes.json";
import Container from "app/ui/global";
import DefaultHead from "app/ui/global/DefaultHead";
import ScrollToTop from "app/ui/global/ScrollToTop";
import loadable from "app/ui/global/loadable";
import useMediaListener from "app/ui/global/media/useMediaListener";
import withErrorBoundary from "app/ui/shared/withErrorBoundary";

import LoggedOutRoutes from "app/router/loggedOut";
import AccountRoutes, { accountRoutePaths } from "app/router/account";
import SignupRoutes from "app/router/signup";
import UniversalRoutes, { universalRoutePaths } from "app/router/universal";
import { LOGGED_OUT_ROUTE_PATHS } from "app/constants/routes";
import useAppsflyerSmartBanner from "app/router/hooks/useAppsflyerSmartBanner";
import SnackbarContainer, {
  SnackbarContainerId,
} from "app/ui/designSystem/molecules/Snackbar/SnackbarContainer";
import RootDialog from "app/ui/global/dialogs";
import useFetchOfferingsMessage from "app/ui/hooks/useFetchOfferingsMessage";
import Shopping from "app/ui/shopping";

// @ts-ignore
const ContainerWithErrorBoundary = withErrorBoundary(Container);

// Lazy-load routes
const GlobalModals = loadable(() => import("./GlobalModals"), true);

const Router = ({
  isMobile,
  isTablet,
}: {
  isMobile: boolean;
  isTablet: boolean;
}) => {
  useMediaListener(isMobile, isTablet);
  useAppsflyerSmartBanner();
  useFetchOfferingsMessage();

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <ContainerWithErrorBoundary>
          <DefaultHead />
          <Switch>
            <Route path={LOGGED_OUT_ROUTE_PATHS} component={LoggedOutRoutes} />

            <Route path={accountRoutePaths} component={AccountRoutes} />

            <Route path={routes.shopping.url} component={Shopping} />

            <Route path={routes.signup.url} component={SignupRoutes} />

            <Route path={universalRoutePaths} component={UniversalRoutes} />
          </Switch>
          <GlobalModals />
          <RootDialog />
          <SnackbarContainer elementId={SnackbarContainerId.ROOT} />
        </ContainerWithErrorBoundary>
      </ScrollToTop>
    </ConnectedRouter>
  );
};

export default Router;
