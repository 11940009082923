// @ts-nocheck
import { em } from "polished";
import PropTypes from "prop-types";
import React from "react";
import Media from "react-media";

import { sizes } from "app/styles/breakpoints";

const propTypes = {
  children: PropTypes.func.isRequired,
};

/*
 * Tablet and smaller, a common media query
 */
export const MediaTablet = ({ children }) => {
  return <Media query={{ maxWidth: em(sizes.xl - 1) }}>{children}</Media>;
};
MediaTablet.propTypes = propTypes;

/*
 * Mobile and smaller, a common media query
 */
export const MediaMobile = ({ children }) => {
  return <Media query={{ maxWidth: em(sizes.lg - 1) }}>{children}</Media>;
};
MediaMobile.propTypes = propTypes;
