import React from "react";
import { ToastProps, TransitionState } from "react-toast-notifications";
import styled from "styled-components";

import { toRem } from "app/styles/utils";

const CustomizeToast: React.FC<ToastProps> = (props) => {
  const { children, transitionDuration, transitionState } = props;
  return (
    <StyledCustomizeToast
      $transitionDuration={transitionDuration}
      $transitionState={transitionState}
    >
      {children}
    </StyledCustomizeToast>
  );
};

enum ToastStateTransformations {
  entering = "translate3d(120%, 0, 0)",
  entered = "translate3d(0, 0, 0)",
  exiting = "translate3d(120%, 0, 0)",
  exited = "translate3d(120%, 0, 0)",
}

interface StyledCustomizeToastProps {
  children: React.ReactNode;
  $transitionDuration: number;
  $transitionState: TransitionState;
}

export const StyledCustomizeToast = styled.div<StyledCustomizeToastProps>`
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.blueDark};
  color: ${({ theme }) => theme.colors.white};
  width: 100vw;
  padding: ${toRem(10)} ${toRem(20)};
  z-index: 1;
  transform-origin: right;
  transition-duration: ${({ $transitionDuration }) => $transitionDuration}ms;
  ${({ $transitionState }) =>
    `transform: ${ToastStateTransformations[$transitionState]}`};

  svg {
    margin-right: ${toRem(8)};
  }

  span {
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  }

  p {
    align-self: center;
    font-size: ${toRem(15)};
    margin: 0 0 0 ${toRem(8)};
  }

  > * {
    margin: auto 0;
  }
`;

export default CustomizeToast;
