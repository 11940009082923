import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import { ProfileType } from "app/ui/shopping/OfferingPreviewCard";
import ImperfectionTag from "./ImperfectionTag";

export interface ImperfectionTagsProps {
  className?: string;
  imperfections: string[];
  profileType?: ProfileType;
  tagBackgroundColor?: string;
  wrap?: boolean;
}

const ImperfectionTags: React.FC<ImperfectionTagsProps> = ({
  className,
  imperfections,
  profileType = ProfileType.REGULAR,
  tagBackgroundColor,
  wrap = false,
}) => (
  <SCTagsWrapper className={className} $wrap={wrap}>
    {imperfections.map((imperfection) => (
      <StyledTag
        key={imperfection}
        $profileType={profileType}
        imperfection={imperfection}
        backgroundColor={tagBackgroundColor}
      />
    ))}
  </SCTagsWrapper>
);

const SCTagsWrapper = styled.ul<{ $wrap: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ $wrap }) =>
    $wrap &&
    `
    flex-wrap: wrap;
    row-gap: ${rem(8)};
  `}
`;

const StyledTag = styled(ImperfectionTag)<{ $profileType: ProfileType }>`
  &:not(:last-of-type) {
    margin-right: ${rem(4)};
  }
  ${({ $profileType }) =>
    $profileType === ProfileType.REGULAR &&
    `
    &:last-of-type {
      flex-shrink: 1;
    }
  `}
  ${({ $profileType }) =>
    $profileType === ProfileType.SLIM &&
    `
    flex-shrink: 1;
  `}
`;

export default ImperfectionTags;
