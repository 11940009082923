import colorPrimitives from "app/styles/colors";
import fonts from "app/styles/fonts";
import { colorUtils } from "app/styles/utils";
import { rem } from "polished";

const colorDerivatives = {
  fontDark: "#453D3D",
  fontMedium: colorPrimitives.gray,
  fontLight: "#B1B3B1",
  fontLighter: colorPrimitives.grayLight,
};

const boxShadow = colorUtils.fade(colorPrimitives.black, 0.85);
const boxShadowHeavy = colorUtils.fade(colorPrimitives.black, 0.78);

export const loggedOutHeaderHeights = {
  loggedOutHeaderHeightMobile: 56,
  loggedOutHeaderHeightDesktop: 106,
};

export const loggedInHeaderHeights = {
  // Top nav gray bar height
  headerHeightDesktop: 40,
  headerHeightMobile: 56,
  headerHeight: 112,
};

// TO DO: https://imperfectfoods.atlassian.net/browse/NC-193
const theme = {
  colors: {
    ...colorPrimitives,
    ...colorDerivatives,
    boxShadow,
    boxShadowHeavy,
  },
  colorUtils,
  layout: {
    shop: {
      verticalCardsRowHeight: 330,
      horizontalCardsRowHeight: 124,
      shelfNavHeight: 54,
      shelfSubpageHeaderHeight: 50,
      headerSectionMobileHeight: 150,
      minimumOrderBannerHeight: 50,
    },
    ...loggedOutHeaderHeights,
    ...loggedInHeaderHeights,
    offeringPreviewCard: {
      minWidth: 220,
      maxWidth: 300,
    },
    sidebar: {
      widthSmall: 320,
      width: 375,
    },
    zIndex: {
      modal: 900,
      toast: 900,
      header: 100,
      cart: 99,
      default: 1,
      stickySubHeader: 2,
      aboveSVG: 2,
      accountNav: 4,
      zendesk: 999998,
      aboveClipPath: 2,
      footer: 2,
    },
    revamp: {
      headerIsScrolled: 32,
    },
  },
  borders: {
    default: `1px solid ${colorPrimitives.grayLighter}`,
    white: `1px solid ${colorPrimitives.white}`,
    medium: `1px solid ${colorPrimitives.tanLight}`,
    dark: `1px solid ${colorPrimitives.grayLight}`,
    error: `1px solid ${colorPrimitives.grapefruit}`,
    green: `1px solid ${colorPrimitives.green}`,
    experimentGreen: `2px solid ${colorPrimitives.experimentGreen}`,
    shelvesBorder: `5px solid ${colorPrimitives.greenDark}`,
    homePageCarouselDots: `1px solid ${colorPrimitives.pink}`,
    newGreen: `1px solid ${colorPrimitives.newGreen}`,
    gray: `2px solid ${colorPrimitives.gray}`,
    delayed: `1px solid ${colorPrimitives.coral}`,
    pink: `${rem(1)} solid ${colorPrimitives.pinkDeep}`,
    eggplant: `${rem(1)} solid ${colorPrimitives.eggplant}`,
    activeThumbnail: `${rem(2)} solid ${colorPrimitives.pinkDeep}`,
    inactiveThumbnail: `${rem(1)} solid ${colorPrimitives.grayLight}`,
    header: `${rem(0.5)} solid ${colorPrimitives.barley}`,
    focus: `${rem(1)} solid ${colorPrimitives.beet}`,
    taro: `${rem(1)} solid ${colorPrimitives.taro}`,
  },
  boxShadows: {
    textShadow: "0px 5.2058px 5.2058px rgba(0, 0, 0, 0.25)",
    default: `3px 3px 5px -1px ${boxShadow}`,
    heavy: `5px 5px 8px -1px ${boxShadowHeavy}`,
    top: `0 -4px 6px -4px ${boxShadow}`,
    right: `6px 0 4px -4px ${boxShadow}`,
    bottom: `0 6px 4px -4px ${boxShadow}`,
    left: `-4px 0 6px -4px ${boxShadow}`,
    all: `0 0 5px ${boxShadow}`, // use sparingly
    allHeavy: `0 0 5px ${boxShadowHeavy}`, // use sparingly
    allLarge: `0 0 8px ${boxShadow}`, // use sparingly
    allLargeHeavy: `0 0 8px ${boxShadowHeavy}`, // use sparingly
    focus: `0 0 0 ${rem(4)} rgba(179, 34, 116, .2)`,
    focusWithOutline: `0 0 0 ${rem(5)} rgba(179, 34, 116, .2)`,
    card: `0 ${rem(4)} ${rem(34)} ${boxShadow}`,
  },
  images: {
    baseUrl: "//static.misfitsmarket.com/imperfect",
  },
  videos: {
    baseUrl: "//static.misfitsmarket.com/imperfect",
  },
  fonts,
  // Do we want these as jpegs?
  logo: {
    mini: {
      height: 40,
      width: 95,
      url: "website-assets/Imperfect_Logo.png",
    },
    vertical: {
      height: 48,
      width: 116,
      // TO DO: Remove once our logged out nav heights match logged in
      // 48px is our new default height for our logo on desktop
      loggedOutHeight: 80,
      loggedOutWidth: 130,
      url: "website-assets/Imperfect_Logo.png",
    },
    carrot: {
      width: 21,
      height: 51,
    },
    small: {
      width: 58,
      height: 24,
    },
    medium: {
      width: 78,
      height: 32,
    },
    large: {
      width: 118,
      height: 51,
    },
    xlarge: {
      width: 167,
      height: 72,
    },
  },
};

export default theme;
