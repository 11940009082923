import { createSelector } from "reselect";

import * as fromAisles from "app/reducers/aisles";
import State from "app/types/state";
import Aisle from "app/types/state/aisles/Aisle";

export function getAisleConfig(state: State) {
  return fromAisles.getAisleConfig(state.aisles);
}

export function isAislesConfigInitialized(state: State) {
  return fromAisles.areAislesInitialized(state.aisles);
}

export function getAllAisles(state: State) {
  return fromAisles.allAisles(state.aisles);
}

export const getAisleShelfIds = createSelector(
  [getAisleConfig],
  (config: Aisle[]) => {
    return config.reduce((shelfIds: string[], aisle: Aisle) => {
      return [...shelfIds, ...aisle.shelves.map((shelf) => shelf.shelfId)];
    }, []);
  }
);
