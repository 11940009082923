import theme from "app/styles/theme";
import type { LogoVariantTypes, LogoSizeTypes } from "./index";

export const getVariant = (
  variant: LogoVariantTypes
): { primaryColor: string; secondaryColor: string } => {
  let primaryColor;
  let secondaryColor;

  switch (variant) {
    case "solid": {
      primaryColor = theme.colors.beet;
      secondaryColor = theme.colors.beet;
      break;
    }
    case "white": {
      primaryColor = theme.colors.marshmallow;
      secondaryColor = theme.colors.marshmallow;
      break;
    }
    case "black": {
      primaryColor = theme.colors.black;
      secondaryColor = theme.colors.black;
      break;
    }
    case "primary":
    default: {
      primaryColor = theme.colors.beet;
      secondaryColor = theme.colors.avocado;
      break;
    }
  }

  return { primaryColor, secondaryColor };
};

export const getSize = (
  size: LogoSizeTypes
): { width: number; height: number } => {
  let width;
  let height;

  switch (size) {
    case "carrot": {
      width = theme.logo.carrot.width;
      height = theme.logo.carrot.height;
      break;
    }
    case "small": {
      width = theme.logo.small.width;
      height = theme.logo.small.height;
      break;
    }
    case "medium": {
      width = theme.logo.medium.width;
      height = theme.logo.medium.height;
      break;
    }
    case "large": {
      width = theme.logo.large.width;
      height = theme.logo.large.height;
      break;
    }
    case "xlarge":
    default: {
      width = theme.logo.xlarge.width;
      height = theme.logo.xlarge.height;
      break;
    }
  }

  return { width, height };
};
