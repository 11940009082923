import React from "react";

export const DEFAULT_TARGET_ID = "shopping-top-target";

interface BackToTopTargetProps {
  targetId?: string;
}

const BackToTopTarget: React.FC<BackToTopTargetProps> = ({
  targetId = DEFAULT_TARGET_ID,
}) => {
  return <span id={targetId} tabIndex={-1} />;
};

export default BackToTopTarget;
