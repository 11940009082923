import React from "react";

import { LinkProps } from "app/ui/shared/Link";
import FullSizeLogo from "./FullSizeLogo";
import CarrotLogo from "./CarrotLogo";
import { getVariant, getSize } from "./utils";
import SCLink from "./styles";

export type LogoVariantTypes = "primary" | "solid" | "white" | "black";
export type LogoSizeTypes = "xlarge" | "large" | "medium" | "small" | "carrot";
export interface LogoProps extends LinkProps {
  variant?: LogoVariantTypes;
  size?: LogoSizeTypes;
}

const Logo: React.FC<LogoProps> = ({
  variant = "primary",
  size = "xlarge",
  ...rest
}) => {
  const { primaryColor, secondaryColor } = getVariant(variant);
  const { width, height } = getSize(size);

  return (
    <SCLink
      aria-label="ImperfectFoods Logo"
      $width={width}
      $height={height}
      {...rest}
    >
      {size === "carrot" ? (
        <CarrotLogo
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      ) : (
        <FullSizeLogo
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      )}
    </SCLink>
  );
};

export default Logo;
