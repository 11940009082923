/**
 * @function MaterialButton
 */

import MaterialButton from "@material-ui/core/Button";
import MuiIconButton from "@material-ui/core/IconButton";
import React from "react";
import styled, { css } from "styled-components";

import Theme from "app/styles/theme";
import { toRem } from "app/ui/shared/components/utils";

export enum Intent {
  primary = "primary",
  secondary = "secondary",
  icon = "icon",
}

export enum Size {
  default = "default",
  xSmall = "xSmall",
  small = "small",
  regular = "regular",
  large = "large",
  xLarge = "xLarge",
}

export enum Shape {
  round = "round",
}
export interface ButtonProps {
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
  shape?: Shape;
  intent?: Intent;
  disabled?: boolean;
  size?: Size;
  minWidth?: number;
  fullWidth?: boolean;
  rest?: Record<string, unknown>;
}

const getColorFromIntent = (theme: typeof Theme, intent?: Intent) => {
  switch (intent) {
    case "primary":
      return theme.colors.pink;
    case "secondary":
      return theme.colors.green;
    default:
      return theme.colors.gray;
  }
};

const Button = (props: ButtonProps) => {
  const { onClick, children, className, rest } = props;
  return (
    <MaterialButton
      variant="contained"
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MaterialButton>
  );
};

const StyledButton = styled(Button)`
  border: none;
  font-weight: 600;
  line-height: unset;
  text-align: center;
  text-transform: none;
  border-radius: ${({ shape }) => {
    switch (shape) {
      case "round":
        return toRem(10);
      default:
        return toRem(3);
    }
  }};
  background-color: ${({ theme, intent, disabled }) => {
    if (disabled) return theme.colors.grayLight;
    return getColorFromIntent(theme, intent);
  }};
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: ${({ size }) => {
    switch (size) {
      case "xSmall":
        return toRem(12);
      case "small":
        return toRem(14);
      case "regular":
        return toRem(16);
      default:
        return toRem(16);
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case "xSmall":
        return toRem(8, 12);
      case "small":
        return toRem(8, 12);
      case "regular":
        return toRem(8, 12);
      case "large":
        return toRem(12, 16);
      case "xLarge":
        return toRem(16, 20);
      default:
        return toRem(8, 12);
    }
  }};

  ${({ minWidth }) =>
    minWidth &&
    `min-width: ${toRem(Number.isInteger(minWidth) ? minWidth : 160)};`};

  ${({ fullWidth }) => fullWidth && "width: 100%"};
`;

export { StyledButton as Button };

export interface IconButtonProps extends React.ComponentProps<"button"> {
  ariaLabel: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  className,
  children,
  disabled,
  onClick,
  ariaLabel,
}) => {
  return (
    <StyledMuiIconButton
      className={className}
      disabled={disabled}
      disableFocusRipple
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      aria-label={ariaLabel}
    >
      {children}
    </StyledMuiIconButton>
  );
};

const BaseHaloStyles = css`
  content: "";
  position: absolute;
  width: calc(100% + ${toRem(6)});
  height: calc(100% + ${toRem(6)});
  transform: translate(${toRem(0)}, ${toRem(0)});
  border-radius: ${toRem(32)};
  z-index: -1;
`;

const StyledMuiIconButton = styled(MuiIconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${toRem(38)};
  height: ${toRem(38)};
  padding: unset;
  color: ${({ theme }) => theme.colors.white};
  border: ${toRem(1)} solid ${({ theme }) => theme.colors.secondaryPink};
  background-color: ${({ theme }) => theme.colors.secondaryPink};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.white};
    border: ${toRem(1)} solid #c5c5c5;
    background-color: #c5c5c5;
  }

  &::before {
    ${BaseHaloStyles}
    background-color: ${({ theme }) => theme.colors.eggplant};
    opacity: 0;
    transition: 250ms opacity;
  }

  &::after {
    ${BaseHaloStyles}
    border: ${toRem(0)} solid rgba(179, 34, 116, 0);
    transition: 250ms border;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.eggplant};
    border: ${toRem(1)} solid ${({ theme }) => theme.colors.white};
    box-shadow: none;

    &:before {
      opacity: 1;
    }
  }

  &:focus :after {
    border: ${toRem(4)} solid rgba(179, 34, 116, 0.2);
  }
`;
