import { includesCategory, FILTERS } from "app/reducers/offerings";
import ViewableOffering from "app/types/selectors/crossDomain/ViewableOffering";
import { RecurringItems } from "app/types/state/account/RecurringItem";
import Offering from "app/types/state/offerings/Offering";
import Order from "app/types/state/orders/Order";

const getViewableOffering = (
  offering: Offering,
  order: Order | null = null,
  neverList: string[] | null = null,
  recurringItems: RecurringItems | null = null,
  isPlusMember: boolean = false,
  userFulfillmentCenterId: string | null = null,
  variantId: string | null = null,
  inScalePriceTreatment: boolean | null = null
): ViewableOffering | null => {
  if (!offering && !variantId) return null;

  // if offering is null, use variantId parameter and get data from the order lineItem
  const resolvedVariantId = offering ? offering.variantId : variantId;
  if (resolvedVariantId === null) return null;

  const itemInOrder =
    (order?.mapOfLineItems && order?.mapOfLineItems[resolvedVariantId]) ||
    false;
  const lineItem = itemInOrder
    ? order?.mapOfLineItems[resolvedVariantId]
    : null;
  const quantity = (lineItem && lineItem.quantity) || 0;

  // if we don't have an offering or a lineItem, we can't return anything
  if (!offering && !lineItem) return null;

  const scalePriceAsString =
    offering?.scalePrice && offering?.scaleMinQuantity && inScalePriceTreatment
      ? (offering?.scalePrice * offering?.scaleMinQuantity).toFixed(2)
      : null;
  const scalePriceFormatted =
    !!scalePriceAsString && scalePriceAsString.endsWith(".00")
      ? scalePriceAsString.substring(0, scalePriceAsString.length - 3)
      : scalePriceAsString;

  /*
    Active price is shown if the item is in the cart, to ensure pricing accuracy
    If salePrice exists, prioritize that over regular price, which mimics backend logic
    If not in cart, and no sale price, show regular price
  */
  const calcActivePrice = () => {
    if (itemInOrder) {
      if (lineItem?.scaleUnitPrice) {
        return offering?.salePrice || lineItem.regularUnitPrice;
      }
      if (offering?.scalePrice) {
        return offering?.salePrice || offering?.price;
      }
      return lineItem?.unitPrice || offering?.salePrice || offering?.price;
    }
    return offering?.salePrice || offering?.price;
  };

  const activePrice = calcActivePrice();

  const cartFullPrice = () => {
    if (lineItem) {
      if (lineItem.optimistic) {
        // needed to prevent empty full price flash during api call
        return offering?.price;
      }
      return lineItem.regularUnitPrice;
    }
    return 0;
  };
  const fullPrice = itemInOrder ? cartFullPrice() : offering?.price;
  const shouldDisplayFullPrice = activePrice < fullPrice;
  const salePriceInDecimal = fullPrice > 0 ? activePrice / fullPrice : 0;
  const percentOff = shouldDisplayFullPrice
    ? Math.floor(100 - salePriceInDecimal * 100)
    : null;
  const percentOffMeetsBottomThreshold = percentOff && percentOff >= 5;
  const activePlusPrice =
    itemInOrder && lineItem?.plusUnitPrice
      ? lineItem.plusUnitPrice
      : offering?.plusPrice;
  const salePriceDiscount = getNumericalDiscount(activePrice, fullPrice);
  const saleDiscountInDollars = fullPrice - activePrice;
  const retailPriceDiscount = getNumericalDiscount(
    activePrice,
    offering?.retailPrice
  );
  const retailPriceOffMeetsBottomThreshold = retailPriceDiscount >= 5;
  const shouldShowRetailPrice =
    !!offering?.retailPrice &&
    !offering?.scalePrice &&
    !shouldDisplayFullPrice &&
    !!activePrice &&
    offering?.retailPrice > activePrice;
  const shouldShowRetailProductFlag =
    shouldShowRetailPrice &&
    !percentOff &&
    retailPriceOffMeetsBottomThreshold &&
    offering?.hasStock;

  const isNonProduce = includesCategory(
    offering?.categories || [],
    FILTERS.NON_PRODUCE
  );

  const availableForNeverList =
    userFulfillmentCenterId &&
    offering?.availableForNeverListFcs?.includes(userFulfillmentCenterId);

  const inNeverList =
    !!neverList?.length && neverList.includes(offering?.productId);
  const availableForRecurring =
    userFulfillmentCenterId &&
    offering?.allowRecurringFcs?.includes(userFulfillmentCenterId);

  const inRecurringItems =
    !!recurringItems?.products &&
    !!offering?.productId &&
    !!recurringItems?.products[offering.productId];

  const scalePricePercentOff =
    offering?.scalePrice && fullPrice > 0
      ? Math.floor(100 - (offering.scalePrice / fullPrice) * 100)
      : null;

  const {
    packageUnit,
    packageUnitAmount,
    name,
    description,
    productDescription,
  } = offering || {};
  const titleWithUnits =
    packageUnit && packageUnitAmount
      ? `${name} (${packageUnitAmount} ${packageUnit})`
      : name;

  return {
    ...offering,
    ...lineItem,
    brandName: offering?.brandName, // brandName is always null in lineItem
    // categories have more data in offering but are otherwise the same
    categories: offering?.categories,
    description: description || productDescription,
    titleWithUnits,
    isNonProduce,
    inNeverList,
    availableForNeverList,
    availableForRecurring,
    allowRecurring: availableForRecurring,
    inRecurringItems,
    quantity,
    // TODO: isOnSale, sale affecting activePrice, etc
    activePriceAsNumber: activePrice,
    // @ts-ignore refactor to handle activePrice = 0 (activePrice || 0).toFixed(2)
    activePrice: activePrice && activePrice.toFixed(2),
    activePlusPrice:
      isPlusMember && activePlusPrice ? activePlusPrice.toFixed(2) : null,
    retailPrice: offering?.retailPrice ? offering.retailPrice.toFixed(2) : null,
    fullPrice: shouldDisplayFullPrice ? fullPrice.toFixed(2) : null,
    percentOff:
      percentOffMeetsBottomThreshold && percentOff ? percentOff : null,
    hasVisibleOffering: !!offering,
    shouldShowRetailPrice,
    shouldShowRetailProductFlag,
    salePriceDiscount,
    retailPriceDiscount,
    scalePriceFormatted,
    scalePricePercentOff: inScalePriceTreatment ? scalePricePercentOff : null,
    saleDiscountInDollars: saleDiscountInDollars.toFixed(2),
  };
};

const getNumericalDiscount = (lowPrice, highPrice) =>
  Number(highPrice) !== 0
    ? Math.floor(100 - (100 * Number(lowPrice)) / Number(highPrice))
    : 0;

export default getViewableOffering;
