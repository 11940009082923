import { useEffect } from "react";

export default function useBodyScrollLock(conditional = true) {
  useEffect(() => {
    if (conditional) {
      document.body.classList.add("disallow-scroll");
    }

    return () => {
      document.body.classList.remove("disallow-scroll");
    };
  }, [conditional]);
}
