// @ts-nocheck
/*
 * Note this file is used by server-side for creating the sitemap.
 */
import Prismic from "prismic-javascript";

import { config } from "../config";

const accessToken = config.get("third_party.prismic.access_token");
const apiEndpoint = config.get("third_party.prismic.endpoint");

export function getApi() {
  return Prismic.getApi(apiEndpoint, { accessToken });
}

export function fetchContentByUID({ uid, contentType, params = {} }) {
  return getApi().then((api) => api.getByUID(contentType, uid, params));
}

export function fetchContentAll({ contentTypes, params }) {
  const predicate = Prismic.Predicates.any("document.type", contentTypes);
  return getApi().then((api) => api.query(predicate, params));
}
