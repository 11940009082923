// @ts-nocheck
// TODO: How do we handle dynamic types? TS isn't happy with the ACTION_SUCCEEDED/ACTION_FAILEDs
import { take, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/ui";
import { isInitialized } from "app/selectors";

// eslint-disable-next-line import/prefer-default-export
export function* waitForInitialization() {
  const initialized = yield select(isInitialized);
  if (!initialized) {
    yield take([
      ACTION_TYPES.INITIALIZE_SUCCEEDED,
      ACTION_TYPES.INITIALIZE_FAILED,
    ]);
  }
}
