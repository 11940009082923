export const RECURRING_ITEM_MODAL_ID = "recurring-item";
export const ORDER_DETAILS_MODAL_ID = "order-details-modal";
export const EXPLORE_ADDONS_MODAL = "secondary-boxes-explore-modal";
export const JOIN_PLUS_MODAL_ID = "join-plus";
export const OUT_OF_STOCK_MODAL_ID = "out-of-stock";
export const WAITLIST_MODAL_ID = "waitlist";
export const OFFERING_DETAILS_PAGE_IMAGE_HOVER_MODAL_ID =
  "offering-details-page-image-hover";
export const PACKAGING_RETURN_MODAL_ID = "packaging-return";
export const OOA_ZIP_CHANGE_MODAL_ID = "ooa-zip-change";
export const PDP_INTENTIONAL_SOURCING_MODAL = "pdp-intentional-sourcing-modal";
export const PDP_IMPERFECTIONS_MODAL = "pdp-imperfections-modal`";
export const TERMS_CONDITIONS_ACCEPT_MODAL = "terms-conditions-accept-global";
export const AUTH_FAILURE_MODAL = "update-billing-global";
export const CANCEL_SUBSCRIPTION_MODAL = "cancel-subscription-modal";

// Modals that display close button in the header on mobile
export const FULL_SCREEN_MODAL_IDS = [
  EXPLORE_ADDONS_MODAL,
  WAITLIST_MODAL_ID,
  OUT_OF_STOCK_MODAL_ID,
];

// Modals that are triggered by a URL parameter
export const URL_ENABLED_MODAL_IDS = [
  JOIN_PLUS_MODAL_ID,
  OUT_OF_STOCK_MODAL_ID,
  WAITLIST_MODAL_ID,
  EXPLORE_ADDONS_MODAL,
  PACKAGING_RETURN_MODAL_ID,
];
