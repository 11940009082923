// @ts-nocheck
import { take, fork, call, put, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/deliveries";
import {
  fetchFlexibleDeliveries,
  fetchShipmentTrackingInfo,
  fetchWindowById,
  fetchWindowsByZip,
} from "app/api/deliveriesService";
import { getDefaultAddress } from "app/selectors";

export default function* rootDeliveries() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      ACTION_TYPES.FETCH_WINDOW_BY_ID,
      ACTION_TYPES.FETCH_WINDOWS_BY_ZIP,
      ACTION_TYPES.FETCH_AVAILABLE_DELIVERIES,
      ACTION_TYPES.FETCH_SHIPMENT_TRACKING_INFO,
    ]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_WINDOW_BY_ID: {
        yield fork(fetchDeliveryWindowByIdFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_WINDOWS_BY_ZIP: {
        yield fork(fetchDeliveryWindowsByZipFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_AVAILABLE_DELIVERIES: {
        yield fork(fetchFlexibleDeliveriesFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_SHIPMENT_TRACKING_INFO: {
        yield fork(fetchShipmentTrackingInfoFlow, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* fetchDeliveryWindowByIdFlow(action) {
  const { deliveryWindowId, includeNextDelivery, tags } = action;

  let timezone;
  const defaultAddress = yield select(getDefaultAddress);
  if (defaultAddress) {
    timezone = defaultAddress.timezone; // eslint-disable-line prefer-destructuring
  }

  const params = {
    deliveryWindowId,
    zoneRelative: true,
    includeNextDelivery,
    timezone,
    tags,
  };
  try {
    if (!deliveryWindowId) {
      throw Error("deliveryWindowId not provided");
    }

    const deliveryWindow = yield call(fetchWindowById, params);
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_WINDOW_BY_ID_SUCCEEDED,
      deliveryWindow,
    });
    return deliveryWindow;
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_WINDOW_BY_ID_FAILED,
      error,
    });
  }

  return null;
}

export function* fetchDeliveryWindowsByZipFlow(action) {
  const { zipCode, includeNextDelivery, tags } = action;
  if (zipCode) {
    let windows, timezone;
    const defaultAddress = yield select(getDefaultAddress);
    if (defaultAddress) {
      if (zipCode === defaultAddress.zipCode) {
        timezone = defaultAddress.timezone; // eslint-disable-line prefer-destructuring
      }
    }

    const params = {
      zipCode,
      timezone,
      zoneRelative: true,
      includeNextDelivery,
      tags,
    };
    try {
      windows = zipCode ? yield call(fetchWindowsByZip, params) : yield [];
      yield put({
        ...action,
        type: ACTION_TYPES.FETCH_WINDOWS_BY_ZIP_SUCCEEDED,
        windows,
        zipCode,
      });
    } catch (error) {
      yield put({
        ...action,
        type: ACTION_TYPES.FETCH_WINDOWS_BY_ZIP_FAILED,
        error,
      });
    }
  }
}

export function* fetchFlexibleDeliveriesFlow(action) {
  try {
    const { zipCode, subscriptionWindowId, userId } = action;
    const flexibleDeliveries = yield call(fetchFlexibleDeliveries, {
      zipCode,
      subscriptionWindowId,
      userId,
    });
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_AVAILABLE_DELIVERIES_SUCCEEDED,
      flexibleDeliveries,
      zipCode,
    });
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_AVAILABLE_DELIVERIES_FAILED,
      error,
    });
  }
}

export function* fetchShipmentTrackingInfoFlow(action) {
  try {
    const { orderId, userId } = action;
    const trackingInfo = yield call(fetchShipmentTrackingInfo, {
      orderId,
      userId,
    });
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_SHIPMENT_TRACKING_INFO_SUCCEEDED,
      trackingInfo,
    });
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_SHIPMENT_TRACKING_INFO_FAILED,
      error,
    });
  }
}
