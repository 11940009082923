type CssSize = number | "auto" | "inherit";
type CssUnit = "rem" | "em";

const ROOTSIZE = 16;

export const toRem = (size: CssSize, ...rest: CssSize[]): string =>
  toUnit("rem", size, ...rest);

const toUnit = (unit: CssUnit, ...sizes: CssSize[]): string => {
  return sizes
    .map((size) => {
      if (size === "auto" || size === "inherit") {
        return size;
      }

      // Exhaustiveness check on union type.
      assertIsNumber(size);
      return `${size / ROOTSIZE}${unit}`;
    })
    .join(" ");
};

const assertIsNumber = (n: number): void => {
  if (typeof n !== "number") {
    throw new TypeError(`Expected a number, got ${typeof n}: ${n}`);
  }
};
