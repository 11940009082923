import { Hit } from "@algolia/client-search";
import _flatMap from "lodash/flatMap";
import _intersection from "lodash/intersection";
import _uniq from "lodash/uniq";
import moment from "moment-timezone";

import ACTION_TYPES from "app/actionTypes/offerings";
import ROUTING_ACTION_TYPES from "app/actionTypes/routing";
import { config } from "app/config";
import { PLUS_PRICE_VARIANT_ID, getVariantsIndex } from "app/constants";
import normalizePath from "app/router/normalizePath";
import { addLocationQuery } from "app/router/routes";
import routes from "app/router/routes.json";
import ExtendedLocationChangeAction from "app/types/state/extendedRouter/ExtendedLocationChangeAction";
import { FetchSimilarOfferingsByCategoryAction } from "app/types/state/offerings/FetchSimilarOfferingsByCategoryAction";
import FetchedAllOfferingsAction, {
  FetchedAllOfferingsActionParams,
} from "app/types/state/offerings/FetchedAllOfferingsAction";
import FetchedAllTagsAction, {
  FetchedAllTagsActionParams,
} from "app/types/state/offerings/FetchedAllTagsAction";
import { FetchedSimilarOfferingsByCategoryAction } from "app/types/state/offerings/FetchedSimilarOfferingsByCategoryAction";
import Offering, { OfferingCategory } from "app/types/state/offerings/Offering";
import OfferingsState from "app/types/state/offerings/OfferingsState";

const env = config.get("algolia.env");
export const DEFAULT_OFFERING_INDEX = getVariantsIndex(env);

export const FILTERS = {
  ALL: "All",
  ORGANIC: "Organic Produce", // tag = Organic
  CONVENTIONAL: "Regular Produce", // tag = Conventional
  NON_PRODUCE: "Non-Produce", // category = Non-Produce,
  UNCATEGORIZED: "Uncategorized", // exists in All, but not in above tags/categories
};

export const tagIds = {
  [FILTERS.ORGANIC]: "dbd39713-f45f-4840-bb09-8b04c90638a5",
  [FILTERS.CONVENTIONAL]: "d57eb677-073a-4f48-b043-e31874e22958",
};

export const categoryIds = {
  [FILTERS.NON_PRODUCE]: "31442fe7-cf35-4bd5-888b-e28fcd6919af",
};

// For supporting the cart view
export const cartFilters = [
  FILTERS.ORGANIC,
  FILTERS.CONVENTIONAL,
  FILTERS.NON_PRODUCE,
  FILTERS.UNCATEGORIZED,
];

/*
 * This reducer is oriented around variants (aka offerings), not products.
 */
export const initialState: OfferingsState = {
  orderedVariantIds: [],
  mapOfVariants: {}, // variantId -> variant data
  mapOfSimilarVariantsByCategory: {},
  fetchComplete: false,
  tags: [],
  mapOfTags: {},
  selected: {
    tags: [],
  },
  previousSelected: {
    tags: [],
  },
  fetchOfferingsTimestamp: null,
  algoliaData: null,
};

type PartialOfferings = Pick<
  OfferingsState,
  "orderedVariantIds" | "mapOfVariants"
>;

type PartialTagState = Pick<OfferingsState, "tags" | "mapOfTags">;

export const getTimestamp = () =>
  moment().format("YYYY-MM-DD HH:mm:ss").toString();

export const includesCategory = (
  categoryArray: OfferingCategory[],
  referenceCategory: string
) => {
  return categoryArray
    .map((c) => c.categoryId)
    .includes(categoryIds[referenceCategory]);
};

const handleFetchAllOfferings = (
  state: OfferingsState,
  action: FetchedAllOfferingsAction
) => {
  const { clearExistingVariants } = action;

  const fetchOfferingsTimestamp = getTimestamp();

  const allResults = action.results.reduce(
    (acc, result) => {
      return {
        hits: [...acc.hits, ...result.hits],
        queryIDs: result.queryID
          ? [...acc.queryIDs, result.queryID]
          : acc.queryIDs,
      };
    },
    { hits: [], queryIDs: [] } as { hits: Hit<Offering>[]; queryIDs: string[] }
  );

  const { mapOfVariants, orderedVariantIds } = allResults.hits.reduce(
    (acc, offering) => {
      const { variantId } = offering;
      const existingOffering = state.mapOfVariants[variantId] || {};
      acc.orderedVariantIds.push(variantId);
      acc.mapOfVariants[variantId] = {
        ...(!clearExistingVariants ? existingOffering : {}),
        ...offering,
      };
      return acc;
    },
    {
      orderedVariantIds: [],
      mapOfVariants: {},
    } as PartialOfferings
  );

  return {
    ...state,
    orderedVariantIds,
    mapOfVariants: {
      ...(!clearExistingVariants ? state.mapOfVariants : {}),
      ...mapOfVariants,
    },
    fetchOfferingsTimestamp,
    fetchComplete: true,
    algoliaData: {
      queryIDs: allResults.queryIDs,
      index: DEFAULT_OFFERING_INDEX,
    },
  };
};

const handleFetchAllOfferingsFromAPI = (state: OfferingsState, action: any) => {
  const { clearExistingVariants, results } = action;

  const { orderedVariantIds, mapOfVariants } = results.reduce(
    (acc, result) => {
      const { variantId } = result;
      const existingOffering = state.mapOfVariants[variantId] || {};

      acc.orderedVariantIds.push(variantId);
      acc.mapOfVariants[variantId] = {
        ...(clearExistingVariants ? {} : existingOffering),
        ...result,
      };

      return acc;
    },
    {
      orderedVariantIds: [],
      mapOfVariants: {},
    } as PartialOfferings
  );

  return {
    ...state,
    fetchOfferingsTimestamp: getTimestamp(),
    fetchComplete: true,
    orderedVariantIds,
    mapOfVariants: {
      ...(clearExistingVariants ? {} : state.mapOfVariants),
      ...mapOfVariants,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addOfferingsById = (state: OfferingsState, action: any) => {
  const { mapOfVariants } = action.offerings.reduce(
    (acc: PartialOfferings, offering: Offering) => {
      const { variantId } = offering;
      const existingOffering = state.mapOfVariants[variantId] || {};
      acc.mapOfVariants[variantId] = {
        ...existingOffering,
        ...offering,
      };

      return acc;
    },
    {
      mapOfVariants: {},
    } as PartialOfferings
  );

  return {
    ...state,
    mapOfVariants: {
      ...state.mapOfVariants,
      ...mapOfVariants,
    },
  };
};

// accepts react-router-parsed query param and returns array for preselecting in redux
const extractQueryParam = (param: string | string[]) => {
  if (Array.isArray(param)) {
    return param;
  }
  if (typeof param === "string") {
    return [param];
  }
  return [];
};

const setSelectedShoppingFilterFromURL = (
  state: OfferingsState,
  action: ExtendedLocationChangeAction
) => {
  const { query } = addLocationQuery(action.payload.location);
  const previousSelected = state.selected;
  const hasQueryTag = !!query?.tag;

  // @ts-ignore
  let tags, prevTags;

  if (hasQueryTag) {
    // ?tag=foo or ?tag=foo&tag=bar for multiple
    tags = extractQueryParam(query.tag);

    if (state.selected.tags.length) {
      prevTags = [];
    }
  } else if (state.selected.tags.length) {
    prevTags = state.selected.tags;
  }

  return {
    ...state,
    selected: {
      tags: tags || [],
    },
    previousSelected: {
      // @ts-ignore
      tags: prevTags && !!prevTags.length ? prevTags : previousSelected.tags,
    },
  };
};

const handleFetchAllTags = (
  state: OfferingsState,
  action: FetchedAllTagsAction
) => {
  const { tags, mapOfTags } = action.result.reduce(
    (acc, tag) => {
      const { tagId } = tag;
      acc.tags.push(tagId);
      acc.mapOfTags[tagId] = { ...tag, id: tagId };
      return acc;
    },
    { tags: [], mapOfTags: {} } as PartialTagState
  );
  // remove any tags set by URL that don't exist
  const selectedTags = state.selected.tags.filter((t) => tags.includes(t));

  return {
    ...state,
    tags,
    mapOfTags,
    selected: {
      ...state.selected,
      tags: selectedTags,
    },
  };
};

const handleFetchSimilarOfferingsByCategorySucceeded = (
  state: OfferingsState,
  action: FetchedSimilarOfferingsByCategoryAction
) => {
  const similarOfferings = _uniq(
    _flatMap(action.payload.multiQueryResults, (category) =>
      _intersection(
        category.map(({ objectID }) => objectID),
        state.orderedVariantIds
      )
    )
  );

  const availableSimilarOfferings = similarOfferings
    .filter((variantId) => state.mapOfVariants[variantId].hasStock)
    .slice(0, 12);

  return {
    ...state,
    mapOfSimilarVariantsByCategory: {
      ...state.mapOfSimilarVariantsByCategory,
      [action.payload.variantId]:
        availableSimilarOfferings.length < 3 ? [] : availableSimilarOfferings,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL_OFFERINGS_SUCCEEDED:
      return handleFetchAllOfferings(state, action);

    case ACTION_TYPES.FETCH_ALL_OFFERINGS_FROM_API_SUCCEEDED:
      return handleFetchAllOfferingsFromAPI(state, action);

    case ACTION_TYPES.FETCH_OFFERINGS_BY_ID_SUCCEEDED:
      return addOfferingsById(state, action);

    case ACTION_TYPES.FETCH_ALL_OFFERINGS_FAILED:
      return {
        ...state,
        fetchComplete: true,
      };

    case ROUTING_ACTION_TYPES.LOCATION_CHANGED: {
      const { pathname } = addLocationQuery(action.payload.location);
      const normalizedPath = normalizePath(pathname);
      if (
        normalizedPath === routes.shopping.url ||
        normalizedPath === routes.shopAll.url
      ) {
        return setSelectedShoppingFilterFromURL(state, action);
      }
      return state;
    }

    case ACTION_TYPES.FETCH_ALL_TAGS_SUCCEEDED: {
      return handleFetchAllTags(state, action);
    }

    case ACTION_TYPES.FETCH_SIMILAR_OFFERINGS_BY_CATEGORY_SUCCEEDED: {
      return handleFetchSimilarOfferingsByCategorySucceeded(state, action);
    }

    default:
      return state;
  }
}

export function getOrderedVariantIds(state: OfferingsState) {
  return state.orderedVariantIds;
}

export function getMapOfVariants(state: OfferingsState) {
  return state.mapOfVariants;
}

export function getMapOfSimilarVariantsByCategory(state: OfferingsState) {
  return state.mapOfSimilarVariantsByCategory;
}

export function getOrderedTagIds(state: OfferingsState) {
  return state.tags;
}

export function getMapOfTags(state: OfferingsState) {
  return state.mapOfTags;
}

export function getSelectedTagIds(state: OfferingsState) {
  return state.selected.tags;
}

export function getPreviouslySelectedTagIds(state: OfferingsState) {
  return state.previousSelected.tags;
}

export function isOfferingsFetchComplete(state: OfferingsState) {
  return state.fetchComplete;
}

export function getFetchOfferingsTimestamp(state: OfferingsState) {
  return state.fetchOfferingsTimestamp;
}

export function getOfferingsAlgoliaData(state: OfferingsState) {
  return state.algoliaData;
}

export function fetchAllOfferings({
  clearAlgoliaCache,
}: {
  clearAlgoliaCache?: boolean;
}) {
  return {
    type: ACTION_TYPES.FETCH_ALL_OFFERINGS,
    clearAlgoliaCache,
  };
}

export function fetchedAllOfferingsAction(
  params: FetchedAllOfferingsActionParams
): FetchedAllOfferingsAction {
  return { ...params, type: ACTION_TYPES.FETCH_ALL_OFFERINGS_SUCCEEDED };
}

export function fetchedAllTagsAction(
  params: FetchedAllTagsActionParams
): FetchedAllTagsAction {
  return { ...params, type: ACTION_TYPES.FETCH_ALL_TAGS_SUCCEEDED };
}

export function fetchAllTags() {
  return { type: ACTION_TYPES.FETCH_ALL_TAGS };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchOfferingsById(params: any) {
  return { type: ACTION_TYPES.FETCH_OFFERINGS_BY_ID, ...params };
}

export function fetchPlusPrice() {
  return fetchOfferingsById({ variantIds: [PLUS_PRICE_VARIANT_ID] });
}

export function fetchSimilarOfferingsByCategory(
  params: FetchSimilarOfferingsByCategoryAction
) {
  return {
    ...params,
    type: ACTION_TYPES.FETCH_SIMILAR_OFFERINGS_BY_CATEGORY,
  };
}
