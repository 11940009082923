import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import routes from "app/router/routes.json";
import normalizePath from "app/router/normalizePath";
import WrappedFooter from "app/router/signup/WrappedFooter";
import loadable from "app/ui/global/loadable";
import SignupHeader from "app/ui/signup/Header";
import { getWhiteLabelSignUpRoute } from "app/constants/routes";

// Lazy-load routes
const Signup = loadable(() => import("../../ui/signup"));

const checkoutRoutes = [
  routes.signupAccount.url,
  routes.signupDelivery.url,
  routes.signupPayment.url,
];

const quizStepRoutes = [
  routes.signupQuizWhoDoYouShopFor.url,
  routes.signupQuizFirstDelivery.url,
  routes.signupQuizShoppingWindow.url,
  routes.signupQuizProduce.url,
  routes.signupQuizDietaryRestrictions.url,
  routes.signupQuizWhatDoYouBuy.url,
];

const SignupRoutes = () => {
  window.location.replace(getWhiteLabelSignUpRoute());
  return null;

  const { pathname } = useLocation();
  const normalizedPath = normalizePath(pathname); // this removes trailing slash from the path if present

  const {
    signupQuizSummary,
    signup,
    signupSubscription,
    signupComplete,
  } = routes;

  const checkRoute = (route) => {
    if (Array.isArray(route)) {
      return route.includes(normalizedPath);
    }
    return normalizedPath === route.url;
  };

  const routePageMap = {
    isOnQuizStepRoute: checkRoute(quizStepRoutes),
    isOnCheckoutRoute: checkRoute(checkoutRoutes),
    isOnJoinPage: checkRoute(signup),
    isOnSignupSummaryPage: checkRoute(signupQuizSummary),
    isOnSubscriptionPage: checkRoute(signupSubscription),
    isOnSignupCompletePage: checkRoute(signupComplete),
  };

  return (
    <>
      <SignupHeader isOnCheckoutRoute={routePageMap.isOnCheckoutRoute} />
      <Switch>
        <Route path={signup.url} component={Signup} />
      </Switch>
      <WrappedFooter {...routePageMap} />
    </>
  );
};

export default SignupRoutes;
