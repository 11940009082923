import stripeJs, { StripeCardElement } from "@stripe/stripe-js";

export interface ParamProps {
  name: string;
  address: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
}

const stripeCreateTokenWithCardElement = async (
  stripe: stripeJs.Stripe,
  cardElement: StripeCardElement,
  params: ParamProps
) => {
  /* eslint-disable camelcase */
  const card = {
    name: params.name,
    address_line1: params.address,
    address_line2: params.addressLine2,
    address_city: params.city,
    address_state: params.state,
    address_zip: params.zip,
    address_country: "US",
  };
  /* eslint-enable camelcase */

  return stripe.createToken(cardElement, card);
};

export default stripeCreateTokenWithCardElement;
