import React from "react";
import theme from "app/styles/theme";

interface BlobProps {
  fillColor?: string;
}

const ShellBlobTwo = ({
  fillColor = theme.colors.lavenderLight,
}: BlobProps) => {
  return (
    <svg
      width="100%"
      height="192"
      viewBox="0 0 350 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.716 -76.2529C156.33 -87.1396 193.319 -41.0855 234.656 -14.1245C277.742 13.9775 338.953 28.6482 347.788 79.6767C356.985 132.805 313.191 177.08 273.97 212.963C236.273 247.451 190.896 275.937 140.195 269.601C88.3355 263.121 44.0145 227.669 18.7698 181.075C-5.43687 136.396 -2.84076 84.0563 13.8289 36.3658C30.976 -12.6907 58.61 -64.7967 108.716 -76.2529Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ShellBlobTwo;
