import _noop from "lodash/noop";

import {
  FriendbuyAPI,
  CouponReceivedCallback,
  WidgetActionTriggeredCallback,
  VisitorStatusCallback,
} from "app/types/api/friendbuy";

const friendbuyNoop: FriendbuyAPI = {
  loaded: false,
  merchantId: "",
  push: _noop,
};

// The friendbuy script is loaded asyncrhonously
// Backfill a default friendbuy implementation in the intermediate
const friendbuy = () => (window && window.friendbuyAPI) || friendbuyNoop;

// Is friendbuy loaded?
export const isFriendbuyLoaded = () => friendbuy().loaded;

// https://developers.friendbuy.com/?javascript#couponreceived
// This is how we surface a coupon to the user in signup
// https://imperfectfoods.atlassian.net/wiki/spaces/HF/pages/1172701201/Spike+Friendbuy+2.0+Upgrade#How-will-we-surface-the-coupon-incentive-in-the-Friend-Signup-Flow-and-will-the-signup-UX-change-as-a-result-of-this-work?
export const onCouponReceived = (cb: CouponReceivedCallback) => {
  friendbuy().push(["subscribe", "couponReceived", cb]);
};

// https://developers.friendbuy.com/?javascript#widgetactiontriggered
// No plans to use this yet but added to POC out of curiosity
export const onWidgetActionTriggered = (cb: WidgetActionTriggeredCallback) => {
  friendbuy().push(["subscribe", "widgetActionTriggered", cb]);
};

// developers.friendbuy.com/?javascript#visitor-status
// This is how we pull back the referralCode and campaignId to store on the user record at signup
// https://imperfectfoods.atlassian.net/wiki/spaces/HF/pages/1172701201/Spike+Friendbuy+2.0+Upgrade#Do-we-persist-all-referral-attribution-data-ourselves-and-apply-to-Purchase-event-or-rely-on-the-coupon?
export const getVisitorStatus = (cb: VisitorStatusCallback) => {
  friendbuy().push(["getVisitorStatus", cb]);
};

// https://developers.friendbuy.com/#event-rules-by-type

export const emailCapture = (email: string, name?: string) => {
  const properties = {
    email,
    ...(name && { name }),
  };
  friendbuy().push(["track", "email_capture", properties]);
};
