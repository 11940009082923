import React from "react";
import { JSXMapSerializer } from "@prismicio/react";
import { Typography } from "@material-ui/core";

import { RichTextLabels } from "app/cms/fields/shared/labels";
import {
  SemiBold,
  SemiBoldPrimary,
  Italic,
} from "app/ui/shared/ThemedTypography";

// https://prismic.io/docs/technical-reference/prismicio-react#prismicrichtext
const richTextComponents: JSXMapSerializer = {
  paragraph: ({ children }) => <Typography paragraph>{children}</Typography>,
  listItem: ({ children }) => (
    <li>
      <Typography>{children}</Typography>
    </li>
  ),
  oListItem: ({ children }) => (
    <li>
      <Typography>{children}</Typography>
    </li>
  ),
  label: ({
    children,
    node: {
      data: { label },
    },
  }) => {
    switch (label as RichTextLabels) {
      case "body2":
        return (
          <Typography component="span" variant="body2">
            {children}
          </Typography>
        );
      case "body2-semiBold":
        return (
          <SemiBold component="span" variant="body2">
            {children}
          </SemiBold>
        );
      case "body2-semiBold-primary":
        return (
          <SemiBoldPrimary component="span" variant="body2">
            {children}
          </SemiBoldPrimary>
        );
      case "body2-italic":
        return (
          <Italic component="span" variant="body2">
            {children}
          </Italic>
        );

      default:
        return <Typography component="span">{children}</Typography>;
    }
  },
};

export default richTextComponents;
