import _isEmpty from "lodash/isEmpty";
import moment from "moment";
import { createSelector } from "reselect";

import * as fromAccount from "app/selectors/account";
import * as fromDeliveries from "app/selectors/deliveries";
import * as fromOrderActive from "app/selectors/orderActive";
import * as fromReactivation from "app/selectors/reactivation";
import * as fromSignup from "app/selectors/signup";

import { DEFAULT_DAY_FORMAT } from "app/ui/global/utils";

// Precedence is order, subscription
// NOTE: if a user adjusts their address after an order is locked
// their active order will not have their most recent window id
export const getActiveUserWindowId = createSelector(
  [fromOrderActive.getActiveOrder, fromAccount.getUserDeliveryWindowId],
  (activeOrder, userWindowId) => {
    const activeSubscriptionWindowId =
      (activeOrder && activeOrder.deliveryWindowId) || userWindowId;
    return activeSubscriptionWindowId;
  }
);

// Precedence is order, subscription
// NOTE: if a user adjusts their address after an order is locked
// their active order will not have their most recent window id
export const getActiveUserWindow = createSelector(
  [getActiveUserWindowId, fromDeliveries.getDeliveryWindows],
  (windowId, windows) => windowId && windows?.[windowId]
);

// this window will not be the most current or have accurate dates
// for next delivery for all cases
// ex. does not acknowledge reactivation state or
// window exceptions for prospect users or
// deferred first delivery date for prospect users or
// changed addresses after order locked for active users
export const getActiveWindow = createSelector(
  [
    getActiveUserWindowId,
    fromSignup.getSignupSelectedDeliveryWindowId,
    fromDeliveries.getDeliveryWindows,
  ],
  (activeUserWindowId, prospectUserWindowId, windows) => {
    const windowId = activeUserWindowId || prospectUserWindowId;
    return windowId && windows?.[windowId];
  }
);

// NOTE: subscription window will not accommodate active order with differing window id
// ex. for window exceptions or user changed addresses after order locked
export const getActiveUserSubscriptionWindow = createSelector(
  [fromAccount.getUserDeliveryWindowId, fromDeliveries.getDeliveryWindows],
  (windowId, windows) => windowId && windows?.[windowId]
);

// Precedence is subscription, reactivation, signup (Does not accommodate for active order)
// NOTE: this may not be the same as a user's active order or next delivery window id
// ex. for window exceptions or user changed addresses after order locked
export const getAllUserStatesSubscriptionWindowId = createSelector(
  [
    fromAccount.getUserDeliveryWindowId,
    fromReactivation.getReactivationSubscriptionWindowId,
    fromSignup.getSignupSelectedDeliveryWindowId,
  ],
  (activeUserWindowId, lapsedUserWindowId, prospectUserWindowId) => {
    return activeUserWindowId || lapsedUserWindowId || prospectUserWindowId;
  }
);

// Precedence is subscription, reactivation, signup (Does not accommodate for active order)
// NOTE: this may not be the same as a user's active order or next delivery window id
// ex. for window exceptions or user changed addresses after order locked
export const getAllUserStatesSubscriptionWindow = createSelector(
  [getAllUserStatesSubscriptionWindowId, fromDeliveries.getDeliveryWindows],
  (windowId, windows) => {
    return windowId && windows?.[windowId];
  }
);

// Precedence is subscription, reactivation, signup (Does not accommodate for active order)
// NOTE: this may not be the same as a user's active order or next delivery window zip
export const getAllUserStatesSubscriptionZipcode = createSelector(
  [
    getActiveUserSubscriptionWindow,
    fromDeliveries.getZipCodeSummary,
    fromReactivation.getReactivationZipCode,
    fromSignup.getSignupZipCode,
  ],
  (
    activeUserSubscriptionWindow,
    zipCodeSummary,
    lapsedUserZip,
    prospectUserZip
  ) => {
    return (
      activeUserSubscriptionWindow?.zipCodeSummary?.zipCode ||
      zipCodeSummary?.zipCode ||
      lapsedUserZip ||
      prospectUserZip
    );
  }
);

// Precedence is subscription, reactivation, signup (Does not accommodate for active order)
// NOTE: this may not be the same as a user's active order or next delivery window id
// ex. for window exceptions or user changed addresses after order locked
// this method does not get window data from the most "accurate" source for
// lapsed users (state > reactivation > reactivationData > deliveryWindow) and
// prospect users (state > signup > subscriptionPosit)
export const getAllUserStatesSubscriptionWindowSummary = createSelector(
  [getAllUserStatesSubscriptionWindow, getAllUserStatesSubscriptionZipcode],
  (window, zipCode) => {
    const {
      deliveryProvider,
      fulfillmentCenterId,
      fcName,
      startDay,
      timezone,
      windowId,
      zoneId,
      zoneName,
    } = window || {};

    return {
      deliveryProvider,
      deliveryDay:
        startDay &&
        moment.tz(timezone).day(startDay).format(DEFAULT_DAY_FORMAT),
      fulfillmentCenterId,
      fcName,
      windowId,
      zipCode,
      zoneId,
      zoneName,
    };
  }
);

// TODO: NC-1010 Remove if subscription pause experiment fails
export const getNowInUserFC = createSelector(
  [getAllUserStatesSubscriptionWindow],
  (window) => {
    const { timezoneFC } = window || {};
    return timezoneFC && moment.tz(timezoneFC);
  }
);

// TODO: NC-1010 Remove if subscription pause experiment fails
export const getFCTimezone = createSelector(
  [getAllUserStatesSubscriptionWindow],
  (window) => {
    return window?.timezoneFC;
  }
);

// https://app.asana.com/0/0/1140690085094426/f
export const isUserUglyShip = createSelector(
  [fromOrderActive.getActiveOrder, getActiveWindow],
  (activeOrder, window) => {
    if (activeOrder && !_isEmpty(activeOrder))
      return activeOrder.deliveryProvider === "FedEx";
    return window?.deliveryProvider === "FedEx" || false;
  }
);

export const isWindowUglyShip = createSelector(
  [fromDeliveries.getDeliveryWindowById],
  (window) => (window && window.deliveryProvider === "FedEx") || false
);

export const getOrderMinimum = createSelector(
  [getActiveWindow],
  (window) => window?.subtotalMin
);

export const windowHasOrderMinimum = createSelector(
  [getOrderMinimum],
  (orderMinimum) => !!orderMinimum
);

export const isNextDeliveryFuzzyWarming = createSelector(
  [fromDeliveries.getZipCodeSummary, fromAccount.isUserOnWarmingHold],
  (zipCodeSummary, isUserOnWH) => {
    return (
      isUserOnWH &&
      zipCodeSummary?.isWarming &&
      zipCodeSummary?.warmingEndDateIsExact === false
    );
  }
);
