// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";

import App from "app/ui/App";

const renderApp = () => {
  ReactDOM.render(<App />, document.getElementById("app"));
};

export default renderApp;
