import React from "react";
import { Dialog, DialogProps } from "@material-ui/core";

import {
  updateDeliveryInfo,
  cancelUpdateDeliveryInfoOnQueryStep,
} from "app/reducers/account";
import {
  AddressUpdateQueryDialog as AddressUpdateQueryDialogType,
  QUERY_ACTION_OPTION,
} from "app/types/ui/Dialog";
import DialogContent from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";
import { useDispatch } from "react-redux";

export interface AddressUpdateQueryDialogProps
  extends Omit<
    DialogProps,
    "open" | "maxWidth" | "onClose" | "TransitionProps"
  > {
  dialog: AddressUpdateQueryDialogType;
}

type makeContentUtilityParams = Pick<
  AddressUpdateQueryDialogType,
  "queryAction" | "zip" | "nextDeliveryDate" | "isCustomizationOpen"
>;

const makeContentUtility = ({
  queryAction,
  zip,
  nextDeliveryDate,
  isCustomizationOpen,
}: makeContentUtilityParams) => {
  switch (queryAction) {
    case QUERY_ACTION_OPTION.CANCEL_SUBSCRIPTION:
      return {
        headerText: (
          <>
            Oh no!
            <br />
            We don&apos;t deliver to {zip || "you"} yet!
          </>
        ),
        subcopyText: (
          <>
            <p>We&apos;ll have to cancel your subscription.</p>
            <p>
              Are you sure you&apos;d like to change your address now and cancel
              your subscription?
            </p>
          </>
        ),
        primaryButtonText: "Yes, cancel my subscription",
        secondaryButtonText: "No thanks",
      };
      break;
    case QUERY_ACTION_OPTION.CANCEL_ACTIVEORDER:
      return {
        headerText: (
          <>
            Heads Up!
            <br />
            You have an active order!
          </>
        ),
        subcopyText: (
          <>
            <p>
              If you change your address now, we&apos;ll have to cancel your
              order{nextDeliveryDate && ` for ${nextDeliveryDate}`}.
              {isCustomizationOpen &&
                ` We'll immediately make you a new order with a new cart.`}
            </p>
            <p>
              Are you sure you&apos;d like to change your address now
              {isCustomizationOpen
                ? " and create a new order?"
                : " and cancel your current order?"}
            </p>
          </>
        ),
        primaryButtonText: isCustomizationOpen
          ? "Yes, create a new order"
          : "Yes, cancel my order",
        secondaryButtonText: "No thanks",
      };
      break;
    // NOTE: this should never get hit - it is a needless confirmation
    default:
      return {
        headerText: "Let's go!",
        subcopyText: "Are you sure you'd like to update your address?",
        primaryButtonText: "Confirm",
        secondaryButtonText: "Edit",
      };
  }
};

export const AddressUpdateQueryDialog: React.FC<AddressUpdateQueryDialogProps> = ({
  dialog,
  ...dialogProps
}) => {
  const dispatch = useDispatch();
  const {
    actionParams,
    queryAction,
    zip,
    nextDeliveryDate,
    isCustomizationOpen,
  } = dialog;
  const content = makeContentUtility({
    queryAction,
    zip,
    nextDeliveryDate,
    isCustomizationOpen,
  });
  const { open, handleClose, handleExited } = useHandleTransitions(dialog);
  const handlePrimaryButtonClick = () => {
    dispatch(updateDeliveryInfo(actionParams));
    handleClose();
  };
  const handleCancelClick = () => {
    dispatch(
      cancelUpdateDeliveryInfoOnQueryStep({
        formikConfig: actionParams.action.formikConfig,
      })
    );
    handleClose();
  };

  return (
    <Dialog
      {...dialogProps}
      data-testid="dialog-address-update-query"
      maxWidth={false}
      open={open}
      onClose={handleCancelClick}
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogContent
        headerText={content.headerText}
        subcopyText={content.subcopyText}
        primaryButtonText={content.primaryButtonText}
        onPrimaryButtonClick={handlePrimaryButtonClick}
        secondaryButtonText={content.secondaryButtonText}
        onSecondaryButtonClick={handleCancelClick}
        onClearIconButtonClick={handleCancelClick}
      />
    </Dialog>
  );
};

export default AddressUpdateQueryDialog;
