import { Typography } from "@material-ui/core";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { breakpointsNonScaling, sizes } from "app/styles/breakpoints";
import { SIDE_NAV_WIDTH } from "app/ui/shopping/Navigation/SideNav";

import useAnchorIdHash from "./useAnchorIdHash";
import AddRecipeToCartButton from "../ShoppableRecipes/AddRecipeToCartButton";

interface CatalogShelfHeaderTokenProps {
  columns: number;
  description: string | null;
  shelfId: string;
  shelfName: string;
  canAddToCart: boolean;
}

export interface CatalogShelfHeaderProps extends CatalogShelfHeaderTokenProps {
  style?: React.CSSProperties;
  updateHash?: _.DebouncedFunc<(anchorId: string) => void>;
  viewportRef?: React.RefObject<HTMLDivElement>;
}

const CatalogShelfHeader: React.FC<CatalogShelfHeaderProps> = React.memo(
  ({
    columns,
    description,
    shelfId,
    shelfName,
    style,
    updateHash,
    viewportRef,
    canAddToCart,
  }) => {
    const ref = useAnchorIdHash(shelfId, updateHash, viewportRef);

    return (
      <ListItemContainer ref={ref} style={style} $columns={columns}>
        {canAddToCart && !buttonOnBottom(columns) && (
          <AddToCartTopContainer>
            <AddRecipeToCartButton mobile={false} shelfId={shelfId} />
          </AddToCartTopContainer>
        )}
        <ShelfName variant="h3">{shelfName}</ShelfName>
        {description && (
          <ShelfDescription variant="body2" $columns={columns}>
            {description}
          </ShelfDescription>
        )}
        {canAddToCart && buttonOnBottom(columns) && (
          <AddToCartBottomContainer>
            <AddRecipeToCartButton mobile shelfId={shelfId} />
          </AddToCartBottomContainer>
        )}
      </ListItemContainer>
    );
  }
);

export default CatalogShelfHeader;

const buttonOnBottom = (columns: number) => columns <= 2;

const SHELF_NAME_PADDING_TOP = 24;
const SHELF_NAME_LINE_HEIGHT_SINGLE_COL = 32;
const SHELF_NAME_LINE_HEIGHT_MULTI_COL = 40;

const SHELF_DESCRIPTION_MARGIN_TOP_SINGLE_COL = 8;
const SHELF_DESCRIPTION_MARGIN_TOP_MULTI_COL = 12;
const SHELF_DESCRIPTION_HEIGHT_LT_THREE_COL = 72;
const SHELF_DESCRIPTION_HEIGHT_GTE_THREE_COL = 48;

const SINGLE_COL_PADDING_BOTTOM = 16;
const MULTI_COL_PADDING_BOTTOM = 24;

const ADD_TO_CART_BUTTON_HEIGHT = 48;
const ADD_TO_CART_BUTTON_PADDING_TOP = 22;
const ADD_TO_CART_BUTTON_PADDING_BOTTOM = 8;

const ListItemContainer = styled.div<{ $columns: number }>`
  width: 100%;
  max-width: ${rem(sizes.xxxl)};
  padding-top: ${rem(SHELF_NAME_PADDING_TOP)};
  padding-bottom: ${({ $columns }) =>
    $columns === 1
      ? rem(SINGLE_COL_PADDING_BOTTOM)
      : rem(MULTI_COL_PADDING_BOTTOM)};
  padding-right: ${rem(16)};
  padding-left: ${rem(16)};

  ${breakpointsNonScaling.xl`
    padding-right: ${48}px;
    padding-left: ${SIDE_NAV_WIDTH + 64}px;
  `}
`;

const ShelfName = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.lufga};
`;

const ShelfDescription = styled(Typography)<{ $columns: number }>`
  width: 100%;
  height: ${rem(SHELF_DESCRIPTION_HEIGHT_LT_THREE_COL)};
  margin-top: ${rem(SHELF_DESCRIPTION_MARGIN_TOP_SINGLE_COL)};
  font-size: ${rem(16)};
  overflow: hidden;

  ${({ $columns }) =>
    $columns > 1 &&
    `
    margin-top: ${rem(SHELF_DESCRIPTION_MARGIN_TOP_MULTI_COL)};
  `}

  ${({ $columns }) =>
    $columns >= 3 &&
    `
    height: ${rem(SHELF_DESCRIPTION_HEIGHT_GTE_THREE_COL)};
  `}
`;

const AddToCartTopContainer = styled.div`
  float: right;
`;

const AddToCartBottomContainer = styled.div`
  padding: ${rem(22)} 0 ${rem(7)} 0;
  width: 100%;
`;

export interface CatalogShelfHeaderToken {
  component: typeof CatalogShelfHeader;
  componentProps: {
    columns: number;
    description: string | null;
    shelfId: string;
    shelfName: string;
    canAddToCart: boolean;
  };
  data: {
    anchorId: string;
    updateHash: boolean;
  };
  listItemHeight: number;
}

const calculateShelfNameHeight = (columns: number) =>
  columns === 1
    ? SHELF_NAME_PADDING_TOP + SHELF_NAME_LINE_HEIGHT_SINGLE_COL
    : SHELF_NAME_PADDING_TOP + SHELF_NAME_LINE_HEIGHT_MULTI_COL;

const calculateShelfDescriptionHeight = (
  columns: number,
  description: string | null
) => {
  let shelfDescriptionHeight = 0;

  if (description) {
    if (columns === 1) {
      shelfDescriptionHeight += SHELF_DESCRIPTION_MARGIN_TOP_SINGLE_COL;
    }
    if (columns > 1) {
      shelfDescriptionHeight += SHELF_DESCRIPTION_MARGIN_TOP_MULTI_COL;
    }

    if (columns < 3) {
      shelfDescriptionHeight += SHELF_DESCRIPTION_HEIGHT_LT_THREE_COL;
    }
    if (columns >= 3) {
      shelfDescriptionHeight += SHELF_DESCRIPTION_HEIGHT_GTE_THREE_COL;
    }
  }

  return shelfDescriptionHeight;
};

export const calculateShelfHeaderHeight = (
  columns: number,
  description: string | null,
  canAddToCart: boolean
) =>
  calculateShelfNameHeight(columns) +
  calculateShelfDescriptionHeight(columns, description) +
  (columns === 1 ? SINGLE_COL_PADDING_BOTTOM : MULTI_COL_PADDING_BOTTOM) +
  (canAddToCart && buttonOnBottom(columns)
    ? ADD_TO_CART_BUTTON_HEIGHT +
      ADD_TO_CART_BUTTON_PADDING_TOP +
      ADD_TO_CART_BUTTON_PADDING_BOTTOM
    : 0);

export const getCatalogShelfHeaderToken = ({
  columns,
  description,
  shelfId,
  shelfName,
  canAddToCart,
}: CatalogShelfHeaderTokenProps): CatalogShelfHeaderToken => ({
  component: CatalogShelfHeader,
  componentProps: {
    columns,
    description,
    shelfId,
    shelfName,
    canAddToCart,
  },
  data: {
    anchorId: shelfId,
    updateHash: true,
  },
  listItemHeight: calculateShelfHeaderHeight(
    columns,
    description,
    canAddToCart
  ),
});
