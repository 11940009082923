// @ts-nocheck
import makeActionTypes from "app/actionTypes";

const domain = "deliveries";
const prefixes = {
  FETCH_WINDOW_BY_ID: `${domain}/FETCH_WINDOW_BY_ID`,
  FETCH_WINDOWS_BY_ZIP: `${domain}/FETCH_WINDOWS_BY_ZIP`,
  FETCH_AVAILABLE_DELIVERIES: `${domain}/FETCH_AVAILABLE_DELIVERIES`,
  FETCH_SHIPMENT_TRACKING_INFO: `${domain}/FETCH_SHIPMENT_TRACKING_INFO`,
};

const actionTypes = makeActionTypes({ prefixes });

export default {
  ...actionTypes,
};
