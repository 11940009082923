// @ts-nocheck
import { take, fork, call, put, select, all } from "redux-saga/effects";

import AISLES_ACTION_TYPES from "app/actionTypes/aisles";
import ACTION_TYPES from "app/actionTypes/catalog";
import {
  algoliaOfferingsClient,
  AlgoliaQueryOfferingIds,
} from "app/api/algolia";
import {
  getMapOfVariants,
  getAisleShelfIds,
  getUserId,
  isRefetchingCatalog,
} from "app/selectors";
import getAllShelves from "app/selectors/shelves";
import fetchSmartShelfData from "app/api/smartShelves";
import CatalogSearchResponse from "app/types/algolia/CatalogSearchResponse";
import { getRecentlyAddedShelf } from "app/reducers/shelves";

export default function* rootCatalog() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      AISLES_ACTION_TYPES.FETCH_AISLES_SUCCEEDED,
      ACTION_TYPES.FETCH_CATALOG,
    ]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_CATALOG:
      case AISLES_ACTION_TYPES.FETCH_AISLES_SUCCEEDED: {
        yield fork(fetchCatalogFlow, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* fetchCatalogFlow(action = {}) {
  try {
    const { filterTag, filterFetch } = action;
    const filters = filterTag ? `tags.tagId: ${filterTag}` : "";

    const mapOfVariants = yield select(getMapOfVariants);
    const allShelfIds = yield select(getAisleShelfIds);
    const allShelves = yield select(getAllShelves);

    const baseShelfIds = [];
    const smartShelfIds = [];

    // Separate base shelf ids and smart shelf ids into separate lists
    allShelfIds.forEach((shelfId) => {
      if (allShelves[shelfId].endpoint) {
        smartShelfIds.push(shelfId);
      } else {
        baseShelfIds.push(shelfId);
      }
    });

    // Async call to fetch variants for both base shelves and smart shelves
    const [shelfResults, smartShelfResults] = yield all([
      call(
        fetchOfferingsForShelves,
        baseShelfIds,
        filters,
        filterTag,
        filterFetch
      ),
      call(
        fetchOfferingsForSmartShelves,
        smartShelfIds,
        filters,
        filterTag,
        filterFetch
      ),
    ]);

    // Combine results from base and smart shelves togethers
    const resultsMap = {
      ...shelfResults.algoliaResultsMap,
      ...smartShelfResults.algoliaResultsMap,
    };
    const availabilityResultsMap = {
      ...shelfResults.algoliaAvailabilityResultsMap,
      ...smartShelfResults.algoliaAvailabilityResultsMap,
    };

    const recentlyAddedShelfInfo = yield select(getRecentlyAddedShelf);

    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_CATALOG_SUCCEEDED,
      payload: {
        availabilityResultsMap,
        mapOfVariants,
        resultsMap,
        shelfIds: allShelfIds,
        recentlyAddedShelfInfo: {
          recentlyAddedShelf:
            recentlyAddedShelfInfo?.recentlyAddedShelf ?? null,
          recentlyAddedShelfVariantIds:
            recentlyAddedShelfInfo?.recentlyAddedShelfVariantIds ?? [],
        },
      },
    });
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_CATALOG_FAILED,
      error: error.message,
    });
  }
}

export function* fetchOfferingsForShelves(
  shelfIds: string[],
  filters: string,
  filterTag: string,
  filterFetch: boolean
) {
  const algoliaResults = yield call(algoliaOfferingsClient.shelfMultiQuery, {
    shelfIds,
    filters,
  });

  let algoliaAvailabilityResults = algoliaResults;
  if (filterTag && !filterFetch) {
    algoliaAvailabilityResults = yield call(
      algoliaOfferingsClient.shelfMultiQuery,
      {
        shelfIds,
      }
    );
  }

  const algoliaResultsMap = yield call(
    reduceAlgoliaResults,
    algoliaResults,
    shelfIds
  );
  const algoliaAvailabilityResultsMap = yield call(
    reduceAlgoliaResults,
    algoliaAvailabilityResults,
    shelfIds
  );

  return {
    algoliaResultsMap,
    algoliaAvailabilityResultsMap,
  };
}

export function* fetchOfferingsForSmartShelves(
  shelfIds: string[],
  filters: string,
  filterTag: string,
  filterFetch: boolean
) {
  const userId = yield select(getUserId);
  const allShelves = yield select(getAllShelves);

  // Async requests to all smart shelf endpoints
  const endpointResults = yield all(
    shelfIds.map((shelfId) =>
      call(callFetchSmartShelves, {
        endpoint: allShelves[shelfId].endpoint,
        shelfId,
        userId,
      })
    )
  );

  const {
    offeringIdLists,
    successfulShelfIds,
  } = reduceSmartShelfEndpointResults(endpointResults);

  const algoliaResults = yield call(
    algoliaOfferingsClient.offeringListMultiQuery,
    {
      offeringIdLists,
      filters,
    }
  );

  let algoliaAvailabilityResults = algoliaResults;
  if (filterTag && !filterFetch) {
    algoliaAvailabilityResults = yield call(
      algoliaOfferingsClient.offeringListMultiQuery,
      {
        offeringIdLists,
      }
    );
  }

  const algoliaResultsMap = yield call(
    reduceAlgoliaResults,
    algoliaResults,
    successfulShelfIds
  );
  const algoliaAvailabilityResultsMap = yield call(
    reduceAlgoliaResults,
    algoliaAvailabilityResults,
    successfulShelfIds
  );

  return {
    algoliaResultsMap,
    algoliaAvailabilityResultsMap,
  };
}

/**
 * This call requires a separate generator in order to prevent
 * yield all from rejecting all calls for a single failure.
 */
export function* callFetchSmartShelves({ endpoint, shelfId, userId }) {
  try {
    const result = yield call(fetchSmartShelfData, { endpoint, userId });
    return { [shelfId]: result };
  } catch (error) {
    // This currently only serves to aid debugging. It does not store nor log
    // the error data anywhere outside of Redux dev tools.
    yield put({
      type: ACTION_TYPES.FETCH_SMART_SHELF_FAILED,
      error: error.message,
      endpoint,
      shelfId,
      userId,
    });
    return { [shelfId]: null };
  }
}

/**
 * Reduces each smart shelf endpoint result into an object containing
 * the id type (variant or product) as well as a list of offering ids.
 *
 * Returns a list containing each reduction as well as a list of shelf ids
 * that successfully returned a response from their endpoint.
 *
 * GS-748: This function does not currently account for additional
 *   properties on the result values, such as "rank".
 */
export const reduceSmartShelfEndpointResults = (
  endpointResults: { [string]: Object[] }[]
): AlgoliaQueryOfferingIds[] => {
  const successfulShelfIds = [];

  const offeringIdLists = endpointResults.reduce((acc, endpointResult) => {
    const resultKey = Object.keys(endpointResult)[0];
    const resultValues = Object.values(endpointResult)[0];

    let type = null;
    if (resultValues?.[0]?.variantId) type = "variant";
    if (resultValues?.[0]?.productId) type = "product";

    if (type !== null) {
      successfulShelfIds.push(resultKey);
      return [
        ...acc,
        {
          type,
          ids: resultValues.map(
            (result) => result.variantId || result.productId
          ),
        },
      ];
    }
    return acc;
  }, []);

  return {
    offeringIdLists,
    successfulShelfIds,
  };
};

/**
 * Returns an object containing Algolia results keyed by
 * their corresponding shelf id.
 */
export const reduceAlgoliaResults = (
  results: { results: CatalogSearchResponse[] }[],
  shelfIds: string[]
) => {
  // Reduces a chunked algolia response into a single list.
  const flatResults = results.reduce(
    (acc, result) => [...acc, ...result.results],
    []
  );
  return flatResults.reduce(
    (acc, result, index) => ({
      ...acc,
      [shelfIds[index]]: result,
    }),
    {}
  );
};

export function* waitForCatalogRefetch() {
  const refetching = yield select(isRefetchingCatalog);
  if (refetching) {
    yield take([
      ACTION_TYPES.FETCH_CATALOG_SUCCEEDED,
      ACTION_TYPES.FETCH_CATALOG_FAILED,
    ]);
  }
}
