import MuiCardMedia from "@material-ui/core/CardMedia/CardMedia";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { RECURRING_ITEM_MODAL_ID } from "app/constants/modals";
import RecurringIconSVG from "app/images/RecurringIcon.svg";
import { showModal } from "app/reducers/ui";
import { rem } from "polished";
import { NEVER_LIST_MODAL_ID } from "app/ui/global/modals/ListModalWrapper";
import { getImageURL } from "app/ui/global/utils";
import {
  getPPCConfigFromProfileType,
  ProfileType,
} from "app/ui/shopping/OfferingPreviewCard";
import NeverListIndicator from "app/ui/shopping/OfferingPreviewCard/NeverListIndicator";
import ProductFlag, {
  ProductFlagTypes,
} from "app/ui/designSystem/molecules/ProductFlag";
import { OfferingFlags } from "app/types/state/offerings/Offering";
import { PriceType } from "app/types/selectors/crossDomain/ViewableOffering";

export interface OfferingImageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  profileType?: ProfileType;
  productId: string;
  variantId: string;
  name: string;
  imageFilename: string | null;
  hasStock: boolean;
  inNeverList: boolean;
  inRecurringItems: boolean;
  fromShelfId?: string;
  percentOff: number | null;
  flags: OfferingFlags[] | null;
  retailPriceDiscount: number;
  shouldShowRetailProductFlag: boolean;
  saleDiscountInDollars: string;
  scalePriceFormatted: PriceType;
  scalePricePercentOff: number | null;
}

const OfferingImage: React.FC<OfferingImageProps> = ({
  profileType = ProfileType.REGULAR,
  productId,
  variantId,
  name,
  imageFilename,
  hasStock,
  inNeverList,
  inRecurringItems,
  fromShelfId,
  percentOff,
  flags,
  retailPriceDiscount,
  shouldShowRetailProductFlag,
  saleDiscountInDollars,
  scalePriceFormatted,
  scalePricePercentOff,
}) => {
  const dispatch = useDispatch();
  const hasFlags = flags && flags.length > 0;
  const shouldShowDiscountInDollars =
    saleDiscountInDollars.endsWith(".00") ||
    saleDiscountInDollars.endsWith(".50");

  const { c, b, height, width, dpr, ar } = getPPCConfigFromProfileType(
    profileType
  );

  const image = getImageURL(
    imageFilename || "null",
    null,
    {
      h: height,
      w: imageFilename == null && height ? height * 1.6 : width,
      c,
      d: "products:no_photo_small.png",
      dpr,
      ar,
      b,
    },
    { fl: "lossy", q: "auto" }
  );

  const showRecurringModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      showModal(RECURRING_ITEM_MODAL_ID, {
        offering: {
          productId,
          variantId,
          name,
          imageFilename,
        },
        inRecurringItems,
      })
    );
  };

  const showNeverListModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      showModal(NEVER_LIST_MODAL_ID, {
        offering: {
          productId,
          name,
          imageFilename,
        },
      })
    );
  };

  return (
    <ImageWrapper
      $hasStock={hasStock}
      $profileType={profileType}
      $height={height}
      $width={width}
      data-testid={`${profileType}-offering-image`}
      data-shelfid={fromShelfId}
    >
      {percentOff && hasStock && !scalePriceFormatted && (
        <StyledProductFlag
          $profileType={profileType}
          type={ProductFlagTypes.ON_SALE}
        >
          {shouldShowDiscountInDollars
            ? `$${saleDiscountInDollars} off`
            : `${percentOff}% off`}
        </StyledProductFlag>
      )}
      {scalePricePercentOff && hasStock && (
        <StyledProductFlag
          $profileType={profileType}
          type={ProductFlagTypes.ON_SALE}
        >
          {`${scalePricePercentOff}% off`}
        </StyledProductFlag>
      )}
      {!hasStock && (
        <StyledProductFlag
          $profileType={profileType}
          type={ProductFlagTypes.SOLD_OUT}
        >
          Sold Out
        </StyledProductFlag>
      )}
      {shouldShowRetailProductFlag && (
        <StyledProductFlag
          $profileType={profileType}
          type={ProductFlagTypes.RETAIL_PRICE}
        >
          Save {retailPriceDiscount}%
        </StyledProductFlag>
      )}
      {hasStock && hasFlags && !percentOff && !shouldShowRetailProductFlag && (
        <StyledProductFlag
          $profileType={profileType}
          type={ProductFlagTypes.MISC}
        >
          {flags && flags[0].name}
        </StyledProductFlag>
      )}
      {inNeverList && (
        <NeverListIndicator
          onClick={showNeverListModal}
          profileType={profileType}
        />
      )}
      {inRecurringItems && (
        <RecurringIconWrapper
          aria-label="remove item from recurring list"
          onClick={showRecurringModal}
        >
          <RecurringIconSVG />
        </RecurringIconWrapper>
      )}
      <CardMedia
        $height={height}
        $width={width}
        image={image}
        // @ts-ignore
        component="img"
        alt={name}
      />
    </ImageWrapper>
  );
};

const StyledProductFlag = styled(ProductFlag)<{ $profileType: ProfileType }>`
  opacity: 1 !important;

  ${({ $profileType }) =>
    $profileType === ProfileType.REGULAR &&
    `
  position: absolute;
  top: ${rem(8)};
  left: 0;
  `}

  ${({ $profileType }) =>
    $profileType === ProfileType.SLIM &&
    `
    position: absolute;
    top: 0;
    left: 0;
  `}
`;

const CardMedia = styled(MuiCardMedia)<{
  $height?: number;
  $width?: number;
}>`
  height: ${({ $height }) => ($height ? rem($height) : "100%")};
  width: ${({ $width }) => ($width ? rem($width) : "100%")};
`;

const ImageWrapper = styled.div<{
  $profileType: ProfileType;
  $height?: number;
  $width?: number;
  $hasStock: boolean;
}>`
  position: relative;
  display: flex;
  height: ${({ $height }) => $height && rem($height)};
  width: ${({ $width }) => $width && rem($width)};
  & > * {
    ${({ $hasStock }) => (!$hasStock ? `opacity: 0.6;` : undefined)};
  }
`;

const RecurringIconWrapper = styled.button`
  width: ${rem(32)};
  height: ${rem(32)};
  margin: ${rem(4)};
  position: absolute;
  z-index: 1;
  border: 0;
  background: none;
  padding: 0;
  right: 0;
  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
`;

export default OfferingImage;
