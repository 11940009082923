// @ts-nocheck
import { take, fork, call, put } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/boxes";
import { fetchBoxes } from "app/api/productService";

export default function* rootBoxes() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([ACTION_TYPES.FETCH_BOXES]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_BOXES: {
        yield fork(fetchBoxesFlow, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* fetchBoxesFlow(action = {}) {
  const { tags = [] } = action;
  try {
    const boxes = yield call(fetchBoxes, { tags });
    yield put({ ...action, type: ACTION_TYPES.FETCH_BOXES_SUCCEEDED, boxes });
  } catch (error) {
    yield put({ ...action, type: ACTION_TYPES.FETCH_BOXES_FAILED, error });
  }
}
