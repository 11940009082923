// @ts-nocheck
import queryString from "query-string";
import { call } from "redux-saga/effects";

import request from "./request";

import { config } from "app/config";
import SubscriptionPositRequest from "app/types/api/userService/SubscriptionPositRequest";
import SubscriptionPositResponse from "app/types/api/userService/SubscriptionPositResponse";
import AddVacationHoldRequest from "app/types/api/userService/AddVacationHoldRequest";
import AddressValidationRequest from "app/types/api/userService/AddressValidationRequest";
import AddressValidationResponse from "app/types/api/userService/AddressValidationResponse";

const baseUrl = config.get("endpoints.gateway");

export function* login(email, password) {
  const url = `${baseUrl}/users/login`;
  const body = JSON.stringify({ email, password });
  return yield call(request, url, { method: "POST", body }, "json", false);
}

export function* changePassword(email) {
  const url = `${baseUrl}/users/change-password`;
  const body = JSON.stringify({ email });
  return yield call(request, url, { method: "POST", body }, "json", false);
}

export function* fetchUser(params) {
  const { userId, tags } = params;
  const url = `${baseUrl}/users/${userId}`;
  return yield call(request, url, { tags }, "json");
}

export function* fetchUserSummary(email) {
  const url = `${baseUrl}/users/summary?email=${encodeURIComponent(email)}`;
  return yield call(request, url, {}, "json", false);
}

export function* updateSignupPreferences(userId, signupPreferences = {}) {
  const url = `${baseUrl}/users/${userId}/signup-preferences`;
  const body = JSON.stringify(signupPreferences);
  return yield call(request, url, { method: "PATCH", body }, "json", false);
}

interface CreateUserRecordParams {
  email: string;
  sessionId?: unknown;
  campaign?: unknown;
  channel?: unknown;
  attributes: unknown;
  signupPreferences: unknown;
  futureMarket: boolean;
  impactClickId: string;
}

export function* createUserRecord(params: CreateUserRecordParams) {
  const {
    email,
    sessionId,
    campaign,
    channel,
    attributes = {},
    signupPreferences = {},
    futureMarket,
    impactClickId,
  } = params;
  const url = `${baseUrl}/users`;
  const body = JSON.stringify({
    email,
    sessionId,
    campaign,
    channel,
    attributes,
    signupPreferences,
    futureMarket,
    impactClickId,
  });
  return yield call(request, url, { method: "POST", body }, "json", false);
}

export function* createCredentials(userId, password) {
  const url = `${baseUrl}/users/${userId}/signup`;
  const body = JSON.stringify({
    password,
  });
  return yield call(request, url, { method: "POST", body }, "json", false);
}

export function* updateUser(userId, values) {
  const url = `${baseUrl}/users/${userId}`;
  const body = JSON.stringify(values);
  return yield call(request, url, { method: "PATCH", body }, "json");
}

export function* fetchPaymentSources(userId) {
  const url = `${baseUrl}/payment-sources/${userId}`;
  return yield call(request, url, {}, "json");
}

export function* addPaymentSource(
  userId: string,
  token: string,
  makeDefault: boolean = true
) {
  const url = `${baseUrl}/payment-sources/${userId}`;
  const body = JSON.stringify({ token, makeDefault });
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* createSubscription(userId, fields = {}) {
  const url = `${baseUrl}/users/${userId}/subscriptions/create`;
  const body = JSON.stringify({
    ...fields,
    cadence: fields.cadence || "WEEKLY",
  });
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* updateSubscriptionBox(userId, boxId) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/change-box`;
  const body = JSON.stringify({ boxId });
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* updateCadence(userId, cadence, cadenceOffset) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/set-cadence`;
  const body = JSON.stringify({ cadence, cadenceOffset });
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* validateAddress(
  userId: string,
  params: AddressValidationRequest
): Generator<AddressValidationRequest, AddressValidationResponse, unknown> {
  const url = `${baseUrl}/users/${userId}/addresses/validate`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "PUT", body }, "json");
}

export function* createAddress(userId, fields) {
  const url = `${baseUrl}/users/${userId}/addresses`;
  const body = JSON.stringify(fields);
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* updateSubscriptionAddressQuery(userId, params) {
  const query = params ? `?${queryString.stringify(params)}` : "";
  const url = `${baseUrl}/users/${userId}/subscriptions/current/change-address/query${query}`;
  return yield call(request, url, {}, "json");
}

export function* updateSubscriptionAddress(userId, params) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/change-address`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* updateAddressDeliveryNotes(addressId, notes) {
  const url = `${baseUrl}/addresses/${addressId}/deliveryNotes`;
  const body = JSON.stringify({ notes });
  return yield call(request, url, { method: "PUT", body });
}

export function* setSubscriptionDeliveryWindow(userId, deliveryWindowId) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/set-delivery-window`;
  const body = JSON.stringify({ deliveryWindowId });
  return yield call(request, url, { method: "POST", body });
}

export function* fetchSignupReasons() {
  const query = `?${queryString.stringify({ randomize: true })}`;
  const url = `${baseUrl}/subscriptions/signup-reasons${query}`;
  return yield call(request, url, {}, "json");
}

export function* fetchCancellationReasons() {
  const url = `${baseUrl}/subscriptions/cancellation-reasons?${queryString.stringify(
    { randomize: true }
  )}`;
  return yield call(request, url, {}, "json");
}

export function* fetchNextDelivery(params) {
  const { userId, tags, ...opts } = params;
  const query = opts ? `?${queryString.stringify(opts)}` : "";
  const url = `${baseUrl}/users/${userId}/subscriptions/current/next-delivery${query}`;
  return yield call(request, url, { tags }, "json");
}

export function* fetchFutureOrders(userId) {
  // NOTE: This endpoint can accept an optional "orders=x" param for specifying number of orders to
  // return - default is 5
  const url = `${baseUrl}/users/${userId}/subscriptions/current/future-orders`;
  return yield call(request, url, {}, "json");
}

// TODO: NC-1010 Remove if subscription pause experiment fails
export function* addVacationHold(userId, params: AddVacationHoldRequest) {
  const holdType = "vacation";
  const holdReason = "self-serve";
  const url = `${baseUrl}/users/${userId}/subscriptions/current/hold`;
  const body = JSON.stringify({ ...params, holdType, holdReason });
  const response = yield call(request, url, { method: "POST", body });
  if (!response.ok) throw response;
}

export function* removeVacationHold(subscriptionHoldId) {
  const url = `${baseUrl}/subscriptions/holds/${subscriptionHoldId}/cancel`;
  const response = yield call(request, url, { method: "POST" });
  if (!response.ok) throw response;
}

export function* skipFutureOrder(userId, params) {
  const query = queryString.stringify(params);
  const url = `${baseUrl}/users/${userId}/subscriptions/current/skip-order?${query}`;
  const response = yield call(request, url, { method: "POST" });
  if (!response.ok) throw response;
}

export function* unskipOrder(userId, params) {
  const query = queryString.stringify(params);
  const url = `${baseUrl}/users/${userId}/subscriptions/current/unskip-order?${query}`;
  const response = yield call(request, url, { method: "POST" });
  if (!response.ok) throw response;
}

export function* donateFutureOrder(userId, params) {
  const query = queryString.stringify(params);
  const url = `${baseUrl}/users/${userId}/subscriptions/current/donate-order?${query}`;
  const response = yield call(request, url, { method: "POST" });
  if (!response.ok) throw response;
}

export function* undonateFutureOrder(userId, params) {
  const query = queryString.stringify(params);
  const url = `${baseUrl}/users/${userId}/subscriptions/current/undonate-order?${query}`;
  const response = yield call(request, url, { method: "POST" });
  if (!response.ok) throw response;
}

export function* getReferralCoupon(campaignId) {
  const url = `${baseUrl}/referrals/campaigns/${campaignId}/coupon`;

  try {
    return yield call(request, url, {}, "json", false);
  } catch (response) {
    // a 404 isn't an error, it just means no coupon exists for this campaign
    if (response.status === 404) return null;
    throw response;
  }
}

// NC-1499 Remove legacy friendbuy
export function* applyReferralLink(
  userId,
  { referringUserId, referralCode, campaignId }
) {
  const url = `${baseUrl}/referrals/apply/${userId}`;
  const body = JSON.stringify({ referringUserId, referralCode, campaignId });
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* applyReferralAttribution(userId: string, params: any) {
  const url = `${baseUrl}/referrals/attribution/${userId}`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* applyCoupon(userId, code, source) {
  const url = `${baseUrl}/users/${userId}/codes/add?expand=order&coupons=true`;
  const body = JSON.stringify({
    code,
    source,
  });
  return yield call(request, url, { method: "POST", body }, "json");
}

export function* removeDeferredCoupon(userId) {
  const url = `${baseUrl}/users/${userId}/deferred-coupons/pop`;
  return yield call(request, url, { method: "POST" });
}
export function* cancelSubscription({ userId, ...params }) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/cancel-expanded`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body });
}
export function* fetchDateOffset(date) {
  const url = `${baseUrl}/subscriptions/get-date-offset/${date}`;
  return yield call(request, url, {}, "json");
}
export function* fetchUserCreditBalance(userId) {
  const url = `${baseUrl}/credits/users/${userId}/current-balance`;
  return yield call(request, url, {}, "json");
}
export function* fetchUserCreditTransactions(userId, type) {
  if (type === "billing") {
    const url = `${baseUrl}/credits/users/${userId}/billing-transactions`;
    return yield call(request, url, {}, "json");
  }
  const query = type ? `?type=${type}` : "";
  const url = `${baseUrl}/credits/users/${userId}/transactions${query}`;
  return yield call(request, url, {}, "json");
}
export function* setMessageAsRead(userId, messageId) {
  const url = `${baseUrl}/messages/${messageId}/users/${userId}/set-read`;
  return yield call(request, url, { method: "PUT" });
}

export function* fetchOrderReconciliationActions(userId) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/reconcile-order-actions`;
  return yield call(request, url, {}, "json");
}

export function* reconcileOrder(userId, params) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/reconcile-order`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json", true);
}

export function* upsertRecurringItem({
  userId,
  itemType,
  itemId,
  quantity,
  metadata,
}) {
  const url = `${baseUrl}/users/${userId}/recurring-items`;
  const body = JSON.stringify({ itemType, itemId, quantity, metadata });
  return yield call(request, url, { method: "PUT", body });
}

export function* removeFromRecurringItems({ userId, itemId }) {
  const url = `${baseUrl}/users/${userId}/recurring-items/${itemId}`;
  return yield call(request, url, { method: "DELETE" });
}

export function* setCadenceOffsetByDate(userId, date) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/set-cadence-offset?date=${date}`;
  return yield call(request, url, { method: "POST" }, "json");
}

export function* setAddOnBoxIds(userId, params) {
  const url = `${baseUrl}/users/${userId}/subscriptions/current/set-add-on-box-ids`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body });
}

export function* enrollPlusMembership({ userId }) {
  const url = `${baseUrl}/users/${userId}/enroll-plus-membership`;
  return yield call(request, url, { method: "POST" }, "json");
}

export function* setPlusMembershipAutoRenewal({ userId, ...params }) {
  const url = `${baseUrl}/users/${userId}/plus-membership-enable-renewal`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "PUT", body }, "json");
}

export function* addNeverItem(userId, params) {
  const url = `${baseUrl}/users/${userId}/never-items`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "PUT", body });
}

export function* removeNeverItem(userId, params) {
  const url = `${baseUrl}/users/${userId}/never-items`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "DELETE", body });
}

export function* reactivate(userId, params = {}) {
  const url = `${baseUrl}/users/${userId}/subscriptions/reactivate`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body });
}

export function* subscriptionPosit(
  params
): Generator<SubscriptionPositRequest, SubscriptionPositResponse, unknown> {
  const url = `${baseUrl}/subscriptions/posit`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json");
}
