import { replace } from "connected-react-router";
import { Middleware } from "redux";

import ROUTING_ACTION_TYPES from "app/actionTypes/routing";
import { fetchCatalog } from "app/reducers/catalog";
import reverseRouteLookup from "app/router/reverseRouteLookup";
import { addLocationQuery } from "app/router/routes";
import routes from "app/router/routes.json";
import {
  getSelectedTagIds,
  getPreviouslySelectedTagIds,
  isShoppingInitialized,
} from "app/selectors";
import {
  RouterLocation,
  ShoppingCatalogState,
} from "app/types/state/extendedRouter";
import ExtendedLocationChangeAction from "app/types/state/extendedRouter/ExtendedLocationChangeAction";

const reconcileCatalogMiddleware: Middleware = (store) => (next) => (
  action
) => {
  const result = next(action);
  if (action.type === ROUTING_ACTION_TYPES.LOCATION_CHANGED) {
    const { payload } = action;
    const { pathname, query } = addLocationQuery(payload.location);
    const route = reverseRouteLookup(pathname);
    const state = store.getState();
    const isOnShoppingRoute = route?.url === routes.shopping.url;
    const isOnShopAllRoute = route?.url === routes.shopAll.url;

    // exit early if not on a shopping route or shopping is not initialized
    if (
      !(isOnShoppingRoute || isOnShopAllRoute) ||
      !isShoppingInitialized(state)
    ) {
      return result;
    }

    if (isOnShoppingRoute && shouldRemoveScrollingState(action)) {
      store.dispatch(replace(removeScrollingState(payload.location)));
    }

    const filterTag = query?.tag;
    const currentlySelected = getSelectedTagIds(state);
    const previouslySelected = getPreviouslySelectedTagIds(state);
    const filtersHaveChanged = query?.tag !== previouslySelected[0];

    // exit early if not adding/removing tags
    if (!filtersHaveChanged) {
      return result;
    }

    const isRefetchingCatalog = currentlySelected !== previouslySelected;

    if (isRefetchingCatalog) {
      store.dispatch(fetchCatalog({ filterTag, filterFetch: true }));
    }

    return next({
      ...action,
      isRefetchingCatalog,
    });
  }

  return result;
};

const shouldRemoveScrollingState = (
  action: ExtendedLocationChangeAction<ShoppingCatalogState>
) => {
  const { isFirstRendering, location } = action.payload;
  const isScrollingState = location?.state?.scrolling;
  return isFirstRendering && isScrollingState;
};

const removeScrollingState = (
  location: RouterLocation<ShoppingCatalogState>
) => {
  return {
    ...location,
    state: {},
  };
};

export default reconcileCatalogMiddleware;
