import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "aisles";
const prefixes = {
  FETCH_AISLES: `${domain}/FETCH_AISLES`,
  ADD_RECENTLY_ADDED_SHELF_TO_FEATURED: `${domain}/ADD_RECENTLY_ADDED_SHELF_TO_FEATURED`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
};
