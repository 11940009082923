import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import breakpoints from "app/styles/breakpoints";
import HeaderBannerConfig, {
  HeaderAlertType,
  HeaderAlertBannerColor,
} from "app/types/split/HeaderBannerConfig";
import shoppingMobileTreatmentMixin from "app/ui/shopping/shoppingMobileTreatmentMixin";

// NOTE: The content here is powered by the header-banner Split on a Per-FC level

export interface HeaderAlertBannerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  headerBannerConfig?: HeaderBannerConfig;
  onShopping?: boolean;
  shoppingMobileTreatment: boolean;
}

const HeaderAlertBanner: React.FC<HeaderAlertBannerProps> = ({
  headerBannerConfig = {},
  onShopping = false,
  shoppingMobileTreatment,
}) => {
  const {
    icon = "info-circle",
    message = "",
    secondMessage = "",
    link = "",
    backgroundColor = "warning",
  } = headerBannerConfig;

  const secondMessageContent = () => {
    if (secondMessage?.length && link?.length) {
      return (
        <StyledLink
          data-testid="second-message-link"
          as="a"
          $backgroundColor={backgroundColor}
          href={link}
        >
          {secondMessage}
        </StyledLink>
      );
    }
    if (secondMessage?.length) {
      return (
        <StyledSecondMessage
          data-testid="second-message-text"
          $backgroundColor={backgroundColor}
        >
          {secondMessage}
        </StyledSecondMessage>
      );
    }
    return null;
  };

  return (
    <StyledBanner
      $backgroundColor={backgroundColor}
      $onShopping={onShopping}
      $shoppingMobileTreatment={shoppingMobileTreatment}
    >
      <FirstLine>
        <StyledFontAwesomeIcon $backgroundColor={backgroundColor} icon={icon} />
        <StyledFirstMessage
          data-testid="first-message"
          $backgroundColor={backgroundColor}
        >
          {message}
        </StyledFirstMessage>
      </FirstLine>
      {secondMessageContent()}
    </StyledBanner>
  );
};

interface StyledBannerProps {
  $backgroundColor: HeaderAlertType;
  $onShopping: boolean;
  $shoppingMobileTreatment: boolean;
}

export const StyledBanner = styled.div<StyledBannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(15)} ${rem(20)} 0 ${rem(20)};
  position: relative;
  top: ${({ theme }) => rem(theme.layout.headerHeightMobile)};
  background-color: ${({ $backgroundColor }) => {
    return (
      HeaderAlertBannerColor[$backgroundColor] || HeaderAlertBannerColor.alert
    );
  }};
  z-index: ${({ theme }) => theme.layout.zIndex.stickySubHeader};

  ${({ $shoppingMobileTreatment, $onShopping, theme }) =>
    shoppingMobileTreatmentMixin($shoppingMobileTreatment)`
      top: ${rem(theme.layout.headerHeight)};

      ${$onShopping && `top: unset;`}
    `};

  ${({ $onShopping }) =>
    $onShopping &&
    `
    top: unset;
  `}
`;

interface StyledMessageProps {
  $backgroundColor: HeaderAlertType;
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: (prop) => !["backgroundColor"].includes(prop),
})<StyledMessageProps>`
  color: ${({ theme, $backgroundColor }) => {
    return $backgroundColor === "urgent"
      ? theme.colors.white
      : theme.colors.fontDark;
  }};
`;

const FirstLine = styled.div`
  display: flex;
  align-self: center;
`;

const StyledFirstMessage = styled.p<StyledMessageProps>`
  font-size: ${rem(13)};
  padding: 0 0 0 14px;
  color: ${({ theme, $backgroundColor }) => {
    return $backgroundColor === "urgent"
      ? theme.colors.white
      : theme.colors.fontDark;
  }};
`;

const StyledSecondMessage = styled.p<StyledMessageProps>`
  text-align: center;
  font-size: ${rem(13)};
  padding-bottom: ${rem(15)};

  color: ${({ theme, $backgroundColor }) => {
    return $backgroundColor === "urgent"
      ? theme.colors.white
      : theme.colors.fontDark;
  }};

  ${breakpoints.lg`
  margin: 0 auto;
`};
`;

const StyledLink = styled(StyledSecondMessage)<StyledMessageProps>`
  text-decoration: underline;
`;

export default HeaderAlertBanner;
