import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { renderCoupons } from "app/ui/shopping/Cart/shared";
import { useDispatch } from "react-redux";
import {
  DETAILED_TAX_ORDER_PARAMS,
  hasTaxDetailedOrderParams,
} from "app/constants/orders";
import { fetchOrder } from "app/reducers/orders";
import { onEnterOrSpaceDown } from "app/ui/global/utils";

export interface CartTotalBreakdownProps {
  cartSummaryItemRef?: React.Ref<HTMLDivElement>;
  order: ActiveOrderExpanded;
  numOrderItems: number;
  freeDelivery: boolean;
  automaticFreeDelivery: boolean;
}

const CartTotalBreakdown: React.FC<CartTotalBreakdownProps> = ({
  cartSummaryItemRef,
  order,
  numOrderItems,
  freeDelivery,
  automaticFreeDelivery,
}) => {
  const orderHasTax =
    order && order.params && hasTaxDetailedOrderParams(order.params);

  const dispatch = useDispatch();
  const loadOrderTax = () => {
    if (order && order.params && !orderHasTax) {
      dispatch(fetchOrder(order.orderId, DETAILED_TAX_ORDER_PARAMS));
    }
  };

  return (
    <CartTotalContainer>
      <CartSummaryItem>
        <Description>
          Subtotal ({numOrderItems} Item{numOrderItems !== 1 && "s"})
        </Description>
        <Price data-testid="subtotal">${order.subtotal}</Price>
      </CartSummaryItem>
      {(order.orderCredits || 0) > 0 && (
        <CartSummaryItem>
          <Description>Credits</Description>
          <Price $bold data-testid="credits">
            -${order.orderCredits}
          </Price>
        </CartSummaryItem>
      )}
      {(order.subtotalDiscount || 0) > 0 && (
        <CartSummaryItem>
          <Description>Discount ({renderCoupons(order)})</Description>
          <Price $bold data-testid="discount">
            -${order.subtotalDiscount}
          </Price>
        </CartSummaryItem>
      )}
      <CartSummaryItem>
        <Description>Delivery fee</Description>
        <Price data-testid="delivery-fee">
          {freeDelivery ? (
            <>
              {!automaticFreeDelivery && (
                <>
                  <StrikethroughPrice>
                    ${order.windowDeliveryFee}
                  </StrikethroughPrice>{" "}
                </>
              )}
              <FreeDeliveryText>FREE</FreeDeliveryText>
            </>
          ) : (
            `$${order.deliveryFeeAfterDiscount}`
          )}
        </Price>
      </CartSummaryItem>
      {orderHasTax && (
        <CartSummaryItem>
          <Description>Tax</Description>
          <Price>${order.tax}</Price>
        </CartSummaryItem>
      )}
      <CartSummaryItem ref={cartSummaryItemRef} $total>
        <Description>
          <strong>Cart Total{!orderHasTax && "*"}</strong>
          {!orderHasTax && <PreTaxText>(pre-tax)</PreTaxText>}
        </Description>
        <Price>
          <strong data-testid="grand-total">${order.totalAfterCredits}</strong>
        </Price>
      </CartSummaryItem>
      {order.lineItemSaleSavings !== "0.00" && (
        <CartSummaryItem>
          <TotalSavingsText>
            Total savings from sale items ${order.lineItemSaleSavings}
          </TotalSavingsText>
        </CartSummaryItem>
      )}
      {!orderHasTax && (
        <TaxNote
          tabIndex={0}
          aria-label="calculate tax"
          role="button"
          onKeyDown={onEnterOrSpaceDown(() => loadOrderTax())}
          onClick={() => loadOrderTax()}
        >
          <span>*</span>
          <span>Click to calculate tax.</span>
        </TaxNote>
      )}
    </CartTotalContainer>
  );
};

// export interface OrderExpanded extends Order {
export interface ActiveOrderExpanded {
  orderId: string;
  deliveryFeeAfterDiscount?: string;
  windowDeliveryFee: string;
  subtotal: string;
  subtotalDiscount: string;
  orderCredits: string;
  totalAfterCredits: string;
  subTotalWithDelivery: string;
  lineItemSaleSavings?: string;
  updating?: boolean;
  coupons: {
    code: string;
  }[];
  tax: string;
  version: number;
  params?: {
    tax: boolean;
  };
}

const CartTotalContainer = styled.div`
  max-width: ${rem(375)};
`;

const PreTaxText = styled.span`
  margin-left: ${rem(5)};
  font-size: ${rem(12)};
`;

const CartSummaryItem = styled.div<{ $total?: boolean }>`
  ${({ $total, theme }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: ${rem(8)};
    color: ${$total ? theme.colors.plum : theme.colors.peppercorn};
    font-size: ${rem($total ? 20 : 13)};
  `}
`;

const Description = styled.span`
  width: 100%;
  white-space: nowrap;
`;

const Price = styled.span<{ $bold?: boolean }>`
  width: 100%;
  text-align: right;
  font-weight: ${({ $bold, theme }) =>
    $bold ? theme.fonts.weight.semiBold : theme.fonts.weight.normal};
`;

const StrikethroughPrice = styled.span`
  text-decoration: line-through;
`;

const FreeDeliveryText = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
`;

const TotalSavingsText = styled.span`
  width: 100%;
  padding: ${rem(4)};
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  border-radius: ${rem(4)};
  background-color: ${({ theme }) => theme.colors.lemonLight};
`;

const TaxNote = styled.div`
  margin-top: ${rem(8)};
  font-size: ${rem(13)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  text-decoration: underline;
  float: left;

  &:focus {
    outline: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focusWithOutline};
  }
`;

export default CartTotalBreakdown;
