import { Backdrop, Dialog } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import * as kb from "app/router/routesKnowledgeBase";
import { getImageURL } from "app/ui/global/utils";
import ConfirmationDialog from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";
import { NewFeatureDialog as NewFeatureDialogType } from "app/types/ui/Dialog";

export interface NewFeatureDialogProps {
  dialog: NewFeatureDialogType;
}

const NewFeatureDialog: React.FC<NewFeatureDialogProps> = ({ dialog }) => {
  const { open, handleClose, handleExited } = useHandleTransitions(dialog);

  const handleLearnMoreButtonClick = () => {
    window.open(kb.getPath(kb.RouteKey.PRICING), "_blank");
    handleClose();
  };

  return (
    <Dialog
      BackdropComponent={Backdrop}
      onClose={handleClose}
      open={open}
      TransitionProps={{ onExited: handleExited }}
      maxWidth={false}
    >
      <ConfirmationDialog
        headerText="See how much you save"
        overlineText="NEW FEATURE"
        primaryButtonText="Start shopping"
        secondaryButtonText="Learn more"
        subcopyText={
          <StyledSubcopyText>
            Now you can see how Imperfect stacks up against the average grocery
            store, and celebrate that you&apos;re getting a good value for the
            planet AND your wallet. Plus, watch for more sales and deals than
            ever.
          </StyledSubcopyText>
        }
        onPrimaryButtonClick={handleClose}
        onSecondaryButtonClick={handleLearnMoreButtonClick}
        onClearIconButtonClick={handleClose}
        topContent={
          <img
            src={getImageURL("RetailPricingPopup.gif", "v1663603888/shop")}
            alt="Tomato pricing compared to grocery store and on sale."
          />
        }
        showClearButton={false}
      />
    </Dialog>
  );
};

const StyledSubcopyText = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  color: ${({ theme }) => theme.colors.peppercorn};
`;

export default NewFeatureDialog;
