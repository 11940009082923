import { createSelector } from "reselect";

import { BASE_ORDER_PARAMS, DETAILED_ORDER_PARAMS } from "app/constants/orders";
import {
  formatBoxData,
  getOrderById,
  getMapOfBoxes,
  getAllOrderSummariesFormatted,
  getUserCreditTransactionsFormatted,
  isCartVisible,
} from "app/selectors";
import State from "app/types/state";
import mergeSortedArraysBy from "app/utils/mergeSortedArraysBy";

// eslint-disable-next-line import/prefer-default-export
export const getOrderByIdExpanded = createSelector(
  [getOrderById, getMapOfBoxes],
  (order, mapOfBoxes) => {
    if (!order) return {};
    const orderBoxDetails = mapOfBoxes[order.boxId] || {};
    const { orderCredits, subtotalDiscount } = order;
    return {
      ...order,
      ...formatBoxData(orderBoxDetails),
      lineItems: (order.lineItems as string[]).map(
        (itemId: string) => order.mapOfLineItems[itemId]
      ),
      subtotal: (order.subtotal || 0).toFixed(2),
      subtotalDiscount: (subtotalDiscount || 0).toFixed(2),
      deliveryFeeAfterDiscount: (
        (order.deliveryFeeAfterDiscount as number) || 0
      ).toFixed(2),
      tax: (order.tax || 0).toFixed(2),
      orderCredits: (orderCredits || 0).toFixed(2),
      total: (order.total || 0).toFixed(2),
      totalAfterCredits: (order.totalAfterCredits || 0).toFixed(2),
    };
  }
);

export const getOrderSummariesWithCredits = createSelector(
  [getAllOrderSummariesFormatted, getUserCreditTransactionsFormatted],
  (orderSummaries, credits) => {
    // NOTE: orderSummaries and credits arrays are already ordered by date desc
    // @ts-ignore --TODO: fix credits type error -- merging array of credits with array of orders
    return mergeSortedArraysBy(orderSummaries, credits, (order, credit) => {
      return order.dateAsMoment.isAfter(credit.dateAsMoment);
    });
  }
);

export const getBaseOrderRequestParams = (state: State) => {
  return isCartVisible(state) ? DETAILED_ORDER_PARAMS : BASE_ORDER_PARAMS;
};

export const hasFetchedOrderSummaries = (state: State) =>
  state.orders.fetchedOrderSummary;
