import { createSelector } from "reselect";
import semver from "semver";

import * as fromMobile from "app/reducers/mobile";
import State from "app/types/state";
import {
  getWarningAppVersionMinimumConfig,
  isInWarningAppVersionMinimum,
} from "app/selectors/crossDomain/splitTreatments";
import { getAppVersionInfo } from "app/utils/mobile";
import { addError } from "app/monitoring/ddrum";

// eslint-disable-next-line import/prefer-default-export
export const isNativeApp = (state: State): boolean => {
  return fromMobile.isNativeApp(state.mobile);
};

export const shouldRenderModalContent = (state: State): boolean => {
  return fromMobile.shouldRenderModalContent(state.mobile);
};

export const shouldRenderAccountPage = (state: State): boolean => {
  return fromMobile.shouldRenderAccountPage(state.mobile);
};

export const shouldRenderOrdersPage = (state: State): boolean => {
  return fromMobile.shouldRenderOrdersPage(state.mobile);
};

enum UsageType {
  WEB = "web",
  MOBILE = "mobile",
}

export const getUsageType = createSelector([isNativeApp], (isNativeApp) =>
  isNativeApp ? UsageType.MOBILE : UsageType.WEB
);

export const showAppRequiresUpdate = createSelector(
  [
    isInWarningAppVersionMinimum,
    isNativeApp,
    getWarningAppVersionMinimumConfig,
  ],
  (
    isInWarningAppVersionMinimum,
    isNativeApp,
    warningAppVersionMinimumConfig
  ) => {
    // If not in native app, or in the split
    if (!isNativeApp || !isInWarningAppVersionMinimum) {
      return false;
    }
    const appInfo = getAppVersionInfo();
    // In very old versions of the app, custom userAgent string is not
    // set, so version is `null`
    if (appInfo.version == null) {
      return true;
    }
    if (warningAppVersionMinimumConfig?.warning_app_version_cutoff == null) {
      // eslint-disable-next-line no-console
      addError({ warningAppVersionMinimumConfig });
      return false;
    }
    // appInfo.version < warning_app_version_cutoff
    const result = semver.lt(
      appInfo.version,
      warningAppVersionMinimumConfig.warning_app_version_cutoff
    );
    return result;
  }
);
