// Merge together two pre-sorted arrays of objects using a given comparator function
// Based on: https://stackoverflow.com/a/41170834
const mergeSortedArraysBy = <T extends unknown>(
  array1: T[],
  array2: T[],
  comparator: (a1: T, a2: T) => boolean
) => {
  const mergedArray: T[] = [];
  let i = 0;
  let j = 0;
  while (i < array1.length && j < array2.length) {
    if (comparator(array1[i], array2[j])) {
      mergedArray.push(array1[i]);
      i++;
    } else {
      mergedArray.push(array2[j]);
      j++;
    }
  }
  if (i < array1.length) {
    for (let p = i; p < array1.length; p++) {
      mergedArray.push(array1[p]);
    }
  } else {
    for (let p = j; p < array2.length; p++) {
      mergedArray.push(array2[p]);
    }
  }
  return mergedArray;
};

export default mergeSortedArraysBy;
