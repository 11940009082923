import React from "react";
import styled from "styled-components";
import { rem } from "polished";

export enum ProgressBarColor {
  LETTUCE = "lettuce",
  AVOCADO = "avocado",
}

export interface ProgressBarProps {
  currentValue: number;
  firstThresholdValue: number;
  secondThresholdValue: number;
  progressColor: ProgressBarColor;
  verticalMarkerActive?: boolean;
  verticalMarkerLabelActive?: boolean;
  verticalMarkerPosition?: number;
  maxBarLabelActive?: boolean;
  grayDiagonalLinesActive?: boolean;
  className?: string;
  height?: number;
  maxWidth?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentValue,
  firstThresholdValue,
  secondThresholdValue,
  progressColor,
  maxWidth,
  verticalMarkerPosition,
  verticalMarkerActive = false,
  verticalMarkerLabelActive = false,
  maxBarLabelActive = false,
  grayDiagonalLinesActive = false,
  className,
  height = 24,
}) => {
  if (currentValue < 0) {
    return null;
  }
  const secondThresholdValueExists = secondThresholdValue !== 0;
  const maxProgressValue = secondThresholdValueExists
    ? secondThresholdValue
    : firstThresholdValue;
  const percentComplete = Math.min(
    Math.max(Math.floor((currentValue / maxProgressValue) * 100), 0),
    100
  );

  return (
    <ProgressBarWrapper
      className={className}
      $height={height}
      $maxWidth={maxWidth}
    >
      <ProgressBarContainer $grayDiagonalLinesActive={grayDiagonalLinesActive}>
        <ProgressBarFill
          $percentComplete={percentComplete}
          $progressColor={progressColor}
        />
        {verticalMarkerActive && secondThresholdValueExists && (
          <VerticalMarker $verticalMarkerPosition={verticalMarkerPosition} />
        )}
      </ProgressBarContainer>
      {verticalMarkerLabelActive && secondThresholdValueExists && (
        <VerticalMarkerLabel $verticalMarkerPosition={verticalMarkerPosition}>
          ${firstThresholdValue} min
        </VerticalMarkerLabel>
      )}
      {maxBarLabelActive && secondThresholdValueExists && (
        <MaxBarLabel>${secondThresholdValue}</MaxBarLabel>
      )}
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled.div<{ $height: number; $maxWidth?: number }>`
  height: ${({ $height }) => rem($height)};
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ $maxWidth }) =>
    $maxWidth &&
    `
    max-width: ${rem($maxWidth)};
  `}
`;

const ProgressBarContainer = styled.div<{ $grayDiagonalLinesActive: boolean }>`
  background-color: rgba(190, 179, 180, 0.64);
  border-radius: ${rem(24)};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${({ $grayDiagonalLinesActive, theme }) =>
    $grayDiagonalLinesActive &&
    `background: repeating-linear-gradient(
    -55deg,
    rgba(190, 179, 180, 0.64),
    rgba(190, 179, 180, 0.64) ${rem(10)},
    ${theme.colors.barley} ${rem(10)},
    ${theme.colors.barley} ${rem(12)}
  );`};
`;

const ProgressBarFill = styled.div<{
  $percentComplete: number;
  $progressColor: ProgressBarColor;
}>`
  height: 100%;
  ${({ $percentComplete }) => `width: ${$percentComplete}%`};
  border-radius: ${rem(24)} 0 0 ${rem(24)};
  transition: width 300ms ease-out;

  ${({ $progressColor, theme }) =>
    `background-color: ${theme.colors[$progressColor]};`}
`;

const VerticalMarker = styled.span<{ $verticalMarkerPosition?: number }>`
  position: absolute;
  bottom: ${rem(-4)};
  width: ${rem(2)};
  height: ${rem(10)};
  background-color: white;

  ${({ $verticalMarkerPosition }) => `left: ${$verticalMarkerPosition}%;`}
`;

const VerticalMarkerLabel = styled.span<{
  $verticalMarkerPosition?: number;
}>`
  position: absolute;
  top: ${rem(12)};
  transform: translate(-50%, 0);
  font-size: ${rem(12)};

  ${({ $verticalMarkerPosition }) => `left: ${$verticalMarkerPosition}%;`}
`;

const MaxBarLabel = styled.span`
  position: absolute;
  top: ${rem(12)};
  right: 0;
  font-size: ${rem(12)};
`;

export default ProgressBar;
