import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export default function useAnchorIdHash(
  anchorId: string,
  updateHash?: _.DebouncedFunc<(anchorId: string) => void>,
  viewportRef?: React.RefObject<HTMLDivElement>
) {
  const { ref, inView } = useInView({
    root: viewportRef?.current,
  });

  useEffect(() => {
    if (inView && updateHash) {
      updateHash(anchorId);
    }
  }, [inView]);

  return ref;
}
