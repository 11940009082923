export enum SnackbarIds {
  REMOVE_SUBSCRIPTION_PAUSE = "Resume Subscription",
  ADDRESS_UPDATED = "Updated Address",
}

interface Snackbar {
  isVisible: boolean;
}

export default Snackbar;
