import React from "react";
import styled from "styled-components";

import { config } from "app/config";
import { rem, em } from "polished";
import { getImageURL } from "app/ui/global/utils";
import Link from "app/ui/shared/Link";

const SocialNav = () => {
  const iconClasses = "fa-layers fa-fw fa-2x",
    imageDir = "v1566911972/website-assets";

  const socialMedia = {
    icons: [
      {
        link: "social_urls.instagram",
        image: "Instagram.png",
        alt: "Instagram",
      },
      {
        link: "social_urls.facebook",
        image: "Facebook.png",
        alt: "Facebook",
      },
      {
        link: "social_urls.twitter",
        image: "Twitter.png",
        alt: "Twitter",
      },
      {
        link: "social_urls.pinterest",
        image: "pinterest.png",
        alt: "Pinterest",
      },
    ],
  };

  return (
    <Wrapper>
      <h3>FOLLOW US FOR RECIPES AND FOOD SAVING TIPS</h3>
      <StyledList>
        {socialMedia.icons.map((icon) => (
          <ListItem key={icon.image}>
            <StyledLink
              external
              newWindow
              // @ts-ignore
              to={config.get(icon.link)}
              className={iconClasses}
            >
              <img
                alt={`${icon.alt} (opens in new window)`}
                src={getImageURL(icon.image, imageDir)}
              />
            </StyledLink>
          </ListItem>
        ))}
      </StyledList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.avocado};
  color: ${({ theme }) => theme.colors.marshmallow};
  font-family: ${({ theme }) => theme.fonts.headings};
  padding: ${rem(40)};
  text-align: center;
  h3 {
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    font-size: ${rem(20)};
  }
`;

const StyledList = styled.ul`
  list-style: none;
  display: inline-flex;
  margin: 0 auto;
  padding: 0;
  img {
    height: ${rem(40)};
  }
`;

const ListItem = styled.li`
  padding: 0 ${rem(6)};
`;

const StyledLink = styled(Link)`
  height: ${em(20)};

  &:focus,
  &:hover {
    outline: ${rem(2)} solid ${({ theme }) => theme.colors.marshmallow};
  }
`;

export default SocialNav;
