import moment from "moment-timezone";
import { createSelector } from "reselect";

import * as fromOrders from "../reducers/orders";

import State from "app/types/state";

export function getAllOrders(state: State) {
  return fromOrders.getAllOrders(state.orders);
}

export function getAllOrderSummaries(state: State) {
  return fromOrders.getAllOrderSummaries(state.orders);
}

export function getOrderById(state: State, orderId: string) {
  return fromOrders.getOrderById(state.orders, orderId);
}

export function getPreviousOrder(state: State) {
  return fromOrders.getPreviousOrder(state.orders);
}

export function getActiveOrderMetadata(state: State) {
  return fromOrders.getActiveOrder(state.orders);
}

export function isFetchingActiveOrder(state: State) {
  return fromOrders.isFetchingActiveOrder(state.orders);
}

export function getFutureOrders(state: State) {
  return fromOrders.getFutureOrders(state.orders);
}

export function getJalapenoCoupon(state: State) {
  return fromOrders.getJalapenoCoupon(state.orders);
}

export function getCoupons(state: State) {
  return fromOrders.getCoupons(state.orders);
}

export function isRemovingAppliedCoupon(state: State) {
  return fromOrders.isRemovingCoupon(state.orders);
}

export function isCreatingAdHocOrder(state: State) {
  return fromOrders.isCreatingAdHocOrder(state.orders);
}

export function getAdHocOrderCreationError(state: State) {
  return fromOrders.getAdHocOrderCreationError(state.orders);
}

export function shouldShowNeverListSuggestions(state: State) {
  return fromOrders.shouldShowNeverListSuggestions(state.orders);
}

export function isAddingMultipleItems(state: State) {
  return fromOrders.isAddingMultipleItems(state.orders);
}

export const getAllOrderSummariesFormatted = createSelector(
  [getAllOrderSummaries],
  (orderSummaries) =>
    orderSummaries.map((o) => {
      return {
        ...o,
        id: o.orderId,
        dateAsMoment: moment(o.deliveryDate),
      };
    })
);
