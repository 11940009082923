// @ts-nocheck
import makeActionTypes from "app/actionTypes";

const domain = "boxes";
const prefixes = {
  FETCH_BOXES: `${domain}/FETCH_BOXES`,
};

const actionTypes = makeActionTypes({ prefixes });

export default {
  ...actionTypes,
};
