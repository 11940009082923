import queryString from "query-string";
import { call, delay, select, fork } from "typed-redux-saga";

import { trackEvent } from "app/api/segment";

import { waitForCatalogRefetch } from "app/sagas/catalog";
import { waitForInitialization } from "app/sagas/waitForInitialization";
import { waitForShoppingInitialization } from "app/sagas/waitForShoppingInitialization";
import { getSelectedTags, getShoppingContextFromRoute } from "app/selectors";
import {
  getAisleProductListData,
  getProductListData,
} from "app/selectors/crossDomain/analytics";
import ExtendedLocationChangeAction from "app/types/state/extendedRouter/ExtendedLocationChangeAction";
import ShoppingContext from "app/types/ui/ShoppingContext";

/*
 * Handle side-effects as a result of route changes
 * Multiple events may be emitted when user enters a restricted route
 */
function* triageRouteChangeFlow(action: ExtendedLocationChangeAction) {
  // Slight delay
  yield delay(100);

  // Wait for initialization to complete
  yield call(waitForInitialization);
  // Kick off triage to determine if
  yield* fork(triageLocationProductListFiltered, action);
}

function* triageLocationProductListFiltered(
  action: ExtendedLocationChangeAction
) {
  yield call(waitForShoppingInitialization);
  yield call(waitForCatalogRefetch);
  const selectedTags = yield* select(getSelectedTags);
  const shoppingContext = yield* select(getShoppingContextFromRoute);
  const { location, currentLocation } = action.payload;

  const hash = queryString.parse(location?.hash) || {};
  const currentHash = queryString.parse(currentLocation?.hash) || {};
  const modalActive = !!hash.modal;
  const modalClosed = !!currentHash.modal;
  const tagCatfilterActive = selectedTags?.length > 0;

  // if product modal is open don't emit event
  // if modal just closed don't emit event
  if (modalActive || modalClosed) {
    return;
  }

  // The most recent shelf stays "active" in the URL but if searching, they are not viewing the aisle
  // Therefore, allow the trackProductsFiltered to be callled if the user applied a tag/category after searching
  if (shoppingContext === ShoppingContext.CATALOG) {
    yield call(trackAisleShelfProductsRanked);
  } else if (tagCatfilterActive) {
    yield call(trackProductsRanked);
  }
}

function* trackAisleShelfProductsRanked() {
  const data = yield* select(getAisleProductListData);
  // GS-250 we are hiding the aisle shelf when there are no results
  if (data && data.totalResults > 0) {
    const event = "Product List Filtered";
    // https://www.algolia.com/doc/guides/getting-insights-and-analytics/connectors/segment/reference/events/#product-list-viewed
    yield call(trackEvent, event, data);
  }
}

function* trackProductsRanked() {
  const data = yield* select(getProductListData);
  if (data) {
    const event = data.filters?.length
      ? "Product List Filtered"
      : "Product List Viewed";
    // https://www.algolia.com/doc/guides/getting-insights-and-analytics/connectors/segment/reference/events/#product-list-viewed
    yield call(trackEvent, event, data);
  }
}

export default triageRouteChangeFlow;
