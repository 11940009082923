import styled, { createGlobalStyle } from "styled-components";
import { rem } from "polished";

import "app/styles/normalize.css";
import ImperfectHand from "app/fonts/ImperfectHand.woff";
import LufgaWoff from "app/fonts/Lufga-ExtraBold.woff";
import LufgaWoff2 from "app/fonts/Lufga-ExtraBold.woff2";

export const GlobalStyles = createGlobalStyle`  
  @font-face {
    font-family: Lufga;
    font-display: swap;
    font-weight: 800;
    src:  url(${LufgaWoff2}) format('woff2'),
          url(${LufgaWoff}) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: ImperfectHand;
    font-display: swap;
    font-weight: 400;
    ${
      /* setting the font-weight resolves a bug where the fonts letter-spacing is 
      higher on real mobile devices than when run locally
      https://stackoverflow.com/a/57463452/11694676
      Slack context: https://imperfectfoods.slack.com/archives/C01EED0C33R/p1626714433237700 */ ""
    }
    src: url(${ImperfectHand}) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  body {
    background-color:${(props) => props.theme.colors.white};
    ${
      /* We should change this bg image to something more in-brand!
    background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB3aWR0aD0nMTAwJyBoZWlnaHQ9JzUwJz4KCTxkZWZzPgoJCTxnIGlkPSdsaW5lcyc+CgkJCTxsaW5lIHgxPScwJyB5MT0nMCcgeDI9JzIwJyB5Mj0nMjUnIHN0cm9rZS13aWR0aD0nMScgc3Ryb2tlPScjZjVmNWY1Jy8+CgkJCTxsaW5lIHgxPScxNScgeTE9JzAnIHgyPSczNScgeTI9JzI1JyBzdHJva2Utd2lkdGg9JzEnIHN0cm9rZT0nI2ZiZmJmYicvPgoJCQk8bGluZSB4MT0nMzAnIHkxPScwJyB4Mj0nNTAnIHkyPScyNScgc3Ryb2tlLXdpZHRoPScxJyBzdHJva2U9JyNmNWY1ZjUnLz4KCQk8L2c+Cgk8L2RlZnM+Cgk8cmVjdCB4PSctMTAlJyB5PSctMTAlJyB3aWR0aD0nMTIwJScgaGVpZ2h0PScxMjAlJyBmaWxsPScjZmZmJy8+Cgk8dXNlIHhsaW5rOmhyZWY9JyNsaW5lcycgeD0nNTAnIHk9JzI1JyAvPgoJPHVzZSB4bGluazpocmVmPScjbGluZXMnIHg9JzUwJyB5PSctMjUnIHRyYW5zZm9ybT0nbWF0cml4KDEgMCAwIC0xIDAgMCknLz4KCTx1c2UgeGxpbms6aHJlZj0nI2xpbmVzJyAgLz4KCTx1c2UgeGxpbms6aHJlZj0nI2xpbmVzJyB4PScwJyB5PSctNTAnIHRyYW5zZm9ybT0nbWF0cml4KDEgMCAwIC0xIDAgMCknLz4gIAo8L3N2Zz4=); */ ""
    }
    ${/* TODO: This should be abstracted into a theme file */ ""}
    color: ${(props) => props.theme.colors.fontDark};
    font-family: ${(props) => props.theme.fonts.body};
  }

  button,
  [role=button] {
    outline: ${rem(1)} solid transparent;
    font-family: ${(props) => props.theme.fonts.body};
    cursor: pointer;

    &:focus {
      /* benefits WHCM users without breaking design */
      outline: ${rem(3)} solid transparent;
    }
  }

  input,
  textarea {
    font-family: ${(props) => props.theme.fonts.body};
  }

  a {
    color: ${(props) => props.theme.colors.avocado};
    text-decoration: none;

    &:focus {
      outline: ${rem(1)} solid currentColor;
    }

    &:active {
      color: ${(props) => props.theme.colors.greenDark};
    }
    /* &:visited {
      color: ${(props) => props.theme.colors.grayDark};
    } */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
`;

export default Container;
