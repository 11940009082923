// NC-1499 Remove legacy friendbuy
import { take, fork, call, put, select } from "redux-saga/effects";

import UI_ACTION_TYPES from "app/actionTypes/ui";
import FRIENDBUY_ACTION_TYPES from "app/actionTypes/friendbuy";
import {
  invokeVisitorStatusApi,
  isFriendbuyLegacyLoaded,
} from "app/api/friendbuyLegacy";
import { VisitorStatus } from "app/types/api/friendbuyLegacy";
import { applyReferralLink } from "app/api/userService";
import * as Monitoring from "app/monitoring";
import { LogLevel } from "app/types/monitoring";
import {
  friendbuySetLegacyReferralAction,
  friendbuyVisitorStatusAction,
} from "app/reducers/friendbuy";
import {
  getLegacyReferral,
  getUserId,
  canApplyLegacyReferral,
} from "app/selectors";
import {
  FRIEND_TAGS,
  APIType,
  LegacyReferralAttributes,
  CampaignId,
  LogEventCode,
} from "app/types/state/friendbuy";

import {
  friendbuyBootstrapFlow,
  waitForFriendbuyReady,
  fetchReferralCouponFlow,
  waitForFriendbuyInitialized,
} from "./shared";
import getReferralFromVisitorStatusLegacy, {
  isValidLegacyReferral,
} from "app/utils/transformations/getReferralFromVisitorStatusLegacy";

export const apiType = APIType.FIRSTGEN;

interface ApplyReferralAction {
  type: string;
  userId: string;
  referral: LegacyReferralAttributes | null;
  campaignId: CampaignId;
  referringUserId: string | null;
  referralCode: string | null;
}

export const applyReferralAction = (
  userId: string,
  referral: LegacyReferralAttributes | null
): ApplyReferralAction => {
  const campaignId = referral && referral.campaign && referral.campaign.id;
  const referringUserId =
    referral && referral.advocate && referral.advocate.userId;
  const referralCode = referral && referral.referralCode;
  return {
    type: FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL,
    userId,
    referral,
    campaignId,
    referringUserId,
    referralCode,
  };
};

/* eslint-disable max-len */
/*
 * Relevant Information:
 * a) Initialization occurs at app bootstrap time, after login, and after user creation (in signup)
 * b) After INITIALIZE_SUCCEEDED, we are guaranteed to have accurate information on the logged in user
 * c) The user's service apply referral endpoint currently applies the multi-use coupon automatically when referral attribution is saved
 *
 * Main Flows:
 * 1) Capture Referral should happen as early in app initialization as possible (bootstrap), but only once in a session.
 * 2) Fetch referral coupon from referral campaign id shortly after referral capture (for display purposes)
 * 3) Initialize FB Widgets as soon as we have a user (after initialization) and if the user should see FB Widgets (defined in selector)
 * 4) Apply the FB referral as soon as we have a user (after initialization) and if the user can have it applied (defined in selector)
 */
/* eslint-enable max-len */

export default function* rootFriendbuyFirstGen() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      FRIENDBUY_ACTION_TYPES.SET_REFERRAL,
      FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON,
      UI_ACTION_TYPES.INITIALIZE_SUCCEEDED,
      UI_ACTION_TYPES.BOOTSTRAP_COMPLETE,
      FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL,
    ]);

    switch (action.type) {
      case UI_ACTION_TYPES.BOOTSTRAP_COMPLETE: {
        yield fork(bootstrapFlow);
        yield fork(captureReferral);

        break;
      }

      case UI_ACTION_TYPES.INITIALIZE_SUCCEEDED: {
        if (action.loggedIn) {
          // We re-initialize when we login, create an account (signup), and at startup
          yield fork(applyReferralTriageFlow);
        }
        break;
      }

      case FRIENDBUY_ACTION_TYPES.SET_REFERRAL:
      case FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON: {
        yield fork(fetchReferralCouponFlow, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL: {
        yield fork(applyReferralFlow, action);
        break;
      }

      default:
        break;
    }
  }
}

export function* bootstrapFlow() {
  yield call(friendbuyBootstrapFlow, isFriendbuyLegacyLoaded, apiType);
}

export function* waitForLoaded() {
  return yield call(waitForFriendbuyReady, apiType);
}

/*
 * Returns referral data object if applicable with referralCode, campaignId, selfReferred, response
 * Returns null otherwise
 * Note there is currently no error available, so this promise will hang if it doesn't return
 */
function* getReferralData() {
  let visitorStatusResponse: VisitorStatus | null = null;
  try {
    visitorStatusResponse = yield call(invokeVisitorStatusApi);
    if (visitorStatusResponse) {
      yield put(friendbuyVisitorStatusAction(apiType, visitorStatusResponse));
      Monitoring.sendLog({
        level: LogLevel.INFO,
        tags: FRIEND_TAGS,
        message: "Friendbuy visitor status received",
        visitorStatus: visitorStatusResponse,
        apiType,
        eventCode: LogEventCode.VISITOR_STATUS_RECEIVED,
      });
    }
  } catch (error) {
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: FRIEND_TAGS,
      message: "Error invoking Friendbuy visitor status API",
      error,
      apiType,
      eventCode: LogEventCode.VISITOR_STATUS_FAILED,
    });

    return null;
  }

  return yield call(getReferralFromVisitorStatusLegacy, visitorStatusResponse);
}

/*
 * This should be called early in the app initialization flow so that
 * the FriendBuy referral data can be captured and saved in state.
 */
export function* captureReferral() {
  yield call(waitForLoaded);
  let campaignId: number | null = null;
  let advocateUserId: string | null = null;
  let referral: LegacyReferralAttributes | null = null;
  try {
    referral = yield call(getReferralData);

    if (isValidLegacyReferral(referral)) {
      campaignId =
        (referral && referral.campaign && referral.campaign.id) || null;
      advocateUserId = referral?.advocate?.userId || null;

      yield put(friendbuySetLegacyReferralAction(campaignId, referral));
      Monitoring.sendLog({
        level: LogLevel.INFO,
        tags: FRIEND_TAGS,
        message: "Friendbuy referral captured",
        referral,
        apiType,
        campaignId,
        advocateUserId,
        eventCode: LogEventCode.VALID_REFERRAL_CAPTURED,
      });
    }
  } catch (error) {
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: FRIEND_TAGS,
      message: "Friendbuy referral not captured",
      referral,
      error,
      apiType,
      campaignId,
      advocateUserId,
    });
  }
}

export function* applyReferralTriageFlow() {
  // Wait for friendbuy initialized so that active apiType is set for tie-breaker
  // Both next-gen & first-gen referrals are present, and split is tie-breaker
  yield call(waitForFriendbuyInitialized);
  const shouldApplyReferral = yield select(canApplyLegacyReferral);
  if (shouldApplyReferral) {
    const userId = yield select(getUserId);
    const referral = yield select(getLegacyReferral);
    yield put(applyReferralAction(userId, referral));
  }
}

export function* applyReferralFlow(action: ApplyReferralAction) {
  const { userId, referral, campaignId } = action;
  const advocateUserId = action.referringUserId || null;
  try {
    const {
      coupon, // order service full coupon entity (can use for dynamic $ amounts/types)
      deferredCoupon, // coupon as it's represented on the user record { code, source }
      // remainder are user record properties we can bring into state
      referringUserId,
      referringUserCampaignId,
      referringUserReferralCode,
      referralDate,
    } = yield call(applyReferralLink, userId, {
      referralCode: action.referralCode,
      campaignId,
      referringUserId: advocateUserId,
    });
    // get coupon data into redux on the user record
    yield put({
      type: FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL_SUCCEEDED,
      referral,
      coupon, // will be used to overwrite what was retrieved earlier - should be the same
      deferredCoupon, // this goes on the user record
      referringUserId,
      referringUserCampaignId,
      referringUserReferralCode,
      referralDate,
    });
    Monitoring.sendLog({
      level: LogLevel.INFO,
      tags: FRIEND_TAGS,
      message: "Friendbuy referral applied",
      referral,
      coupon,
      deferredCoupon,
      campaignId,
      advocateUserId,
      apiType,
      eventCode: LogEventCode.VALID_REFERRAL_APPLY_SUCCEEDED,
    });
  } catch (error) {
    yield put({
      type: FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL_FAILED,
      referral,
      error,
    });
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: FRIEND_TAGS,
      message: "Friendbuy referral could not be applied",
      error,
      campaignId,
      advocateUserId,
      apiType,
      eventCode: LogEventCode.VALID_REFERRAL_APPLY_FAILED,
    });
  }
}
