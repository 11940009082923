import { createSelector } from "reselect";
import moment, { Moment } from "moment-timezone";

import * as fromReactivation from "app/selectors/reactivation";
import * as fromAccount from "app/selectors/account";
import * as fromDeliveries from "app/selectors/deliveries";
import * as fromBoxes from "app/selectors/boxes";
import * as fromCDSplitTreatments from "app/selectors/crossDomain/splitTreatments";
import {
  SHORT_DATE_FORMAT_2,
  TIME_DAY_FORMAT,
  capitalize,
  formatCurrency,
} from "app/ui/global/utils";
import EnhancedReactivationData from "app/types/selectors/crossDomain/EnhancedReactivationData";
import Box from "app/types/state/boxes/Box";
import ReactivationData from "app/types/account/ReactivationData";
import State from "app/types/state";
import {
  DeliveryProvider,
  FullWindowData,
} from "app/types/state/deliveries/deliveries";
import { FREE_SHIPPING_THRESHOLD } from "app/constants";
import OrderMinimumData from "app/types/split/OrderMinimumData";
import { CancelSubscriptionStatus } from "app/types/state/account/Account";

interface GetEnhancedReactivationDataProps {
  now?: Moment;
}

/*
 * A user can view the reactivation page if they can reactivate and they did not just cancel
 */
export const canUserViewReactivation = createSelector(
  [fromReactivation.canUserReactivate, fromAccount.getCancelSubscriptionStatus],
  (canReactivate, cancelSubscriptionStatus) =>
    canReactivate && cancelSubscriptionStatus === null
);

/*
 * A user can view the reactivation page if they can reactivate and they just cancelled
 */
export const canUserViewGoodbye = createSelector(
  [fromReactivation.canUserReactivate, fromAccount.getCancelSubscriptionStatus],
  (canReactivate, cancelSubscriptionStatus) =>
    canReactivate &&
    cancelSubscriptionStatus === CancelSubscriptionStatus.COMPLETE
);

export const getOrderMinimumValue = (
  deliveryWindow: FullWindowData | null,
  orderMinimumData: OrderMinimumData | null
) => {
  // The order of operations is defined in
  // https://imperfectfoods.atlassian.net/wiki/spaces/E/pages/932577461/Tech+LOE+Order+Minimums+a.k.a.+Minimum+AOV+Pilot#How-should-the-order-minimum-be-derived-between-the-delivery-window-and-split%3F
  if (deliveryWindow?.subtotalMin) {
    return deliveryWindow.subtotalMin;
  }
  if (
    orderMinimumData?.isInTreatment &&
    orderMinimumData?.config?.subtotalMin
  ) {
    return orderMinimumData.config.subtotalMin;
  }

  return null;
};

export const getEnhancedReactivationData = createSelector(
  [
    fromReactivation.getReactivationData,
    fromAccount.getDefaultPaymentSource,
    fromAccount.getDefaultAddress,
    fromDeliveries.getDeliveryWindows,
    fromBoxes.getMapOfBoxes,
    fromCDSplitTreatments.getOrderMinTreatment,
    (_: State, props?: GetEnhancedReactivationDataProps) => props,
  ],
  (
    reactivationData,
    defaultPaymentSource,
    defaultAddress,
    windows,
    mapOfBoxes,
    orderMinimumData,
    props
  ) => {
    if (!reactivationData) {
      return null;
    }

    const deliveryWindowId = reactivationData?.deliveryWindowId;
    const deliveryWindow =
      (deliveryWindowId && windows[deliveryWindowId]) || null;
    const deliveryPrice = deliveryWindow?.deliveryPrice;
    const deliveryProvider = deliveryWindow?.deliveryProvider;

    const primaryBox =
      reactivationData?.boxId && mapOfBoxes[reactivationData.boxId];
    const secondaryBoxes =
      reactivationData?.addOnBoxIds.map((boxId) => mapOfBoxes[boxId]) ||
      ([] as Box[]);

    const shippingCost = (deliveryPrice && formatCurrency(deliveryPrice)) || "";

    // Source of truth _should_ be split but we're doing this temporarily
    // This may change when we productize free delivery threshold
    // https://imperfectfoods.atlassian.net/browse/MER-231
    const freeShippingThreshold =
      deliveryProvider === DeliveryProvider.FedEx
        ? null
        : FREE_SHIPPING_THRESHOLD;

    const orderMinimumValue = getOrderMinimumValue(
      deliveryWindow,
      orderMinimumData
    );

    // Process order minimum data
    const orderMinimumProps = orderMinimumValue
      ? {
          orderMinimum: formatCurrency(orderMinimumValue, 0),
          shippingMinimum: freeShippingThreshold,
          hasRadius: true,
        }
      : null;

    return {
      invoiceInfo: {
        costPerOrder: getReactivationCostPerOrder(
          deliveryWindow?.fulfillmentCenterId || "",
          primaryBox as Box,
          secondaryBoxes as Box[]
        ),
        // If the order mimimum is present, don't display free delivery threshold in invoice section
        freeShippingThreshold: !orderMinimumValue && freeShippingThreshold,
        shippingCost,
      },
      cadence: capitalize(reactivationData?.cadence || ""),
      itemsPerOrder: getReactivationItemsPerOrder(primaryBox, secondaryBoxes),
      zipCode: defaultAddress.zip,
      paymentSource: defaultPaymentSource,
      // Used to control the control checklist component
      freeShippingThreshold,
      orderMinimumProps,
      ...(reactivationData?.deliveryWindow &&
        getReactivationShoppingData(reactivationData, props?.now)),
    } as EnhancedReactivationData;
  }
);

export const getReactivationShoppingData = (
  reactivationData: ReactivationData,
  providedNow?: Moment
) => {
  if (!reactivationData?.deliveryWindow) {
    return null;
  }
  const { deliveryWindow, canCreateOrderSyncronously } = reactivationData;
  const {
    startDate,
    startTime,
    customizationEndDate,
    customizationEndTime,
    customizationStartDate,
    customizationStartTime,
    timezone: localTimezone,
    timezoneFC,
  } = deliveryWindow;

  const timezone = localTimezone || timezoneFC;
  const now = providedNow || moment.tz(timezone);
  const startAsMoment = moment.tz(
    `${customizationStartDate} ${customizationStartTime}`,
    timezone
  );
  const endAsMoment = moment.tz(
    `${customizationEndDate} ${customizationEndTime}`,
    timezone
  );
  const deliveryAsMoment = moment.tz(`${startDate} ${startTime}`, timezone);

  const canShopToday =
    canCreateOrderSyncronously || now.isSame(startAsMoment, "day");
  const shoppingNote = canShopToday
    ? "Shop today!"
    : `Shop ${now.to(startAsMoment)}`;

  const shoppingWindow = `${startAsMoment.format(
    TIME_DAY_FORMAT
  )} - ${endAsMoment.format(TIME_DAY_FORMAT)}`;

  return {
    firstDeliveryDate: deliveryAsMoment.format(SHORT_DATE_FORMAT_2),
    shoppingWindow,
    shoppingNote,
  };
};

export const getReactivationCostPerOrder = (
  fcId: string,
  primaryBox: Box,
  secondaryBoxes: Box[]
) => {
  const { minPrice, maxPrice } = getReactivationBoxRange(
    fcId,
    primaryBox,
    secondaryBoxes
  );
  return `${formatCurrency(minPrice, 0)}-${formatCurrency(maxPrice, 0)}`;
};

export const getReactivationBoxRange = (
  fcId: string,
  primaryBox: Box,
  secondaryBoxes: Box[]
) => {
  return {
    minPrice: getBoxPrice(fcId, primaryBox, secondaryBoxes, "minPrice"),
    maxPrice: getBoxPrice(fcId, primaryBox, secondaryBoxes, "maxPrice"),
  };
};

const getBoxPrice = (
  fcId: string,
  primaryBox: Box,
  secondaryBoxes: Box[],
  field: "minPrice" | "maxPrice"
) => {
  if (!fcId || !primaryBox) {
    return 0;
  }

  const primaryBoxPrices = primaryBox.fc[fcId];
  let price = (primaryBoxPrices && primaryBoxPrices[field]) || 0;
  if (secondaryBoxes?.length) {
    price = secondaryBoxes.reduce((p, box) => {
      const boxPrices = box.fc[fcId];
      if (boxPrices) {
        return p + (boxPrices[field] || 0);
      }
      return p;
    }, price);
  }
  return price;
};

export const getReactivationItemsPerOrder = (
  primaryBox: Box,
  secondaryBoxes: Box[]
) => {
  const boxNames: string[] = [];

  const primaryBoxName = getReactivationPrimaryBoxName(primaryBox);
  if (primaryBoxName) {
    // Add the primary box to the front of the list
    boxNames.push(primaryBoxName);
  }

  // Sort all add-on packs
  secondaryBoxes.forEach((box) => {
    const secondaryBoxName = getReactivationSecondaryBoxName(box);
    if (secondaryBoxName) {
      boxNames.push(secondaryBoxName);
    }
  });

  // Separate w/ commas
  return boxNames.join(", ");
};

export const getReactivationPrimaryBoxName = (box: Box) => {
  // Requirements
  // boxType + "Produce"
  return `${box.boxType} Produce`;
};

export const getReactivationSecondaryBoxName = (box: Box) => {
  // Requirements
  // 1. Remove "Pack"
  // 2. Append "-based" to to the Plant Pack
  // 3. Append "s" to the Snack Pack
  let displayName = box.displayName.trim().replace(" Pack", "");

  if (displayName === "Plant") {
    displayName = displayName.concat("-based");
  } else if (displayName === "Snack") {
    displayName = displayName.concat("s");
  }
  return displayName;
};
