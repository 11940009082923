import { Typography } from "@material-ui/core";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import OfferingNameText from "app/ui/shared/OfferingDetail/OfferingNameText";
import Offering from "app/types/state/offerings/Offering";

type OfferingProps = Pick<
  Offering,
  | "productName"
  | "packagingUnitAmount"
  | "packageUnitFormatted"
  | "realUnitAmount"
  | "realUnitFormatted"
  | "realUnitIsApproximate"
  | "realUnitIsIndividual"
>;

interface CartItemProductNameProps extends OfferingProps {
  brandName: string | null;
  className?: string;
}

const CartItemProductName: React.FC<CartItemProductNameProps> = ({
  brandName,
  className,
  productName,
  packagingUnitAmount,
  packageUnitFormatted,
  realUnitAmount,
  realUnitFormatted,
  realUnitIsApproximate,
  realUnitIsIndividual,
}) => (
  <ProductDetailContainer className={className} tabIndex={-1}>
    {brandName && <BrandName variant="caption">{brandName}</BrandName>}
    <OfferingNameContainer variant="body1">
      <OfferingNameText
        brandName={brandName}
        name={productName}
        packagingUnitAmount={packagingUnitAmount}
        packageUnitFormatted={packageUnitFormatted}
        realUnitAmount={realUnitAmount}
        realUnitFormatted={realUnitFormatted}
        realUnitIsApproximate={realUnitIsApproximate}
        realUnitIsIndividual={realUnitIsIndividual}
      />
    </OfferingNameContainer>
  </ProductDetailContainer>
);

export default CartItemProductName;

const ProductDetailContainer = styled.span`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:focus {
    text-decoration: none;
    border: none;
    outline: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focusWithOutline};
  }

  &:hover {
    text-decoration: none;
  }
`;

const BrandName = styled(Typography)`
  color: ${({ theme }) => theme.colors.taro};
  font-size: ${rem(12)};
  line-height: ${rem(14.63)};
`;

const OfferingNameContainer = styled(Typography)`
  margin-top: ${rem(2)};
  font-size: ${rem(13)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  line-height: ${rem(15.85)};
`;
