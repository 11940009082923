// @ts-nocheck
import moment from "moment-timezone";

import { get, set, remove, defaultOptions } from "./cookie";

const AUTH_COOKIE = "imperfect_auth";

const getOptions = () => {
  return {
    ...defaultOptions,
    expires: moment().add(30, "days").toDate(),
  };
};

export const getAuth = () => {
  return get(AUTH_COOKIE);
};

export const setAuth = (userId, tokens, impersonated) => {
  return set(
    AUTH_COOKIE,
    JSON.stringify({ userId, tokens, impersonated }),
    getOptions()
  );
};

export const removeAuth = () => remove(AUTH_COOKIE);
