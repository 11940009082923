import { rem, padding } from "polished";
import React from "react";
import styled from "styled-components";
import breakpoints from "app/styles/breakpoints";
import grid from "app/styles/grid";
import Offering from "app/types/state/offerings/Offering";
import { getImageURL } from "app/ui/global/utils";
import { TextButton } from "app/ui/shared/Button";
import { CartSection } from "app/ui/shopping/Cart/Cart";
import RemovedCartItem from "app/ui/shopping/Cart/NeverList/RemovedCartItem";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "app/reducers/ui";
import NeverListFeatureIntro, {
  NEVER_LIST_INFO_MODAL,
} from "app/ui/global/modals/NeverListFeatureIntro";

interface RemovedFromCartProps {
  removedDefaultOfferings: Offering[];
}

const RemovedFromCart: React.FC<RemovedFromCartProps> = ({
  removedDefaultOfferings,
}) => {
  const dispatch = useDispatch();
  return (
    <Wrapper data-testid="neverlist-removed-from-cart">
      <CartSection>
        <BetterCurators>
          <BetterCuratorsText>
            <h4>Do you want to see these items in future default boxes?</h4>
            <StyledTextButton
              onClick={() => dispatch(showModal(NEVER_LIST_INFO_MODAL))}
            >
              Learn more
            </StyledTextButton>
          </BetterCuratorsText>
        </BetterCurators>
        {removedDefaultOfferings.map((offering) => {
          // Why would we have null offerings removed from the cart?
          // Yet, it still happens regularly. https://app.honeybadger.io/projects/63435/faults/53132549
          if (!offering) return null;
          return (
            <RemovedCartItem key={offering.variantId} offering={offering} />
          );
        })}
      </CartSection>
      <NeverListFeatureIntro
        hideNeverListInfo={() => dispatch(hideModal(NEVER_LIST_INFO_MODAL))}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.grayLight}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grayLight}`};
  ${padding(rem(16), rem(16), 0, rem(16))};
  background: ${({ theme }) => theme.colors.graySmoke};
  margin-bottom: ${rem(32)};
`;

const betterCuratorsBgImage = getImageURL(
  "NeverListBox2.png",
  "v1562695826/website-assets",
  {
    h: 191,
    w: 239,
    dpr: "auto",
  }
);

const BetterCurators = styled.div`
  ${grid.row};
  background: calc(100%) calc(100% - 5px) / ${rem(160)} ${rem(126)}
    url("${betterCuratorsBgImage}") no-repeat;
  margin-bottom: ${rem(8)};
  position: relative;

  ${breakpoints.lg`
    background-size: 140px auto;
  `}
`;

const BetterCuratorsText = styled.div`
  line-height: normal;
  min-height: ${rem(130)};

  & > h4 {
    color: ${({ theme }) => theme.colors.purple};
    font-size: ${rem(17)};
    margin-bottom: 0;
    max-width: 58%;
  }
`;

const StyledTextButton = styled(TextButton)`
  font-size: ${rem(13)};
  padding: 0;
  margin-top: ${rem(8)};
  margin-bottom: ${rem(16)};
  text-decoration: underline;

  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
    color: ${({ theme }) => theme.colors.beet};
    text-decoration: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.eggplant};
  }
`;

export default RemovedFromCart;
