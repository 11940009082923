import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { removeProductFromNeverList } from "app/reducers/account";
import { rem } from "polished";
import { getImageURL } from "app/ui/global/utils";
import { StyledButton, GhostButton } from "app/ui/shared/Button";

import Offering from "app/types/state/offerings/Offering";

interface NeverListModalProps {
  closeModal: () => void;
  offering: Offering;
}

const NeverListModal = ({ closeModal, offering }: NeverListModalProps) => {
  const dispatch = useDispatch();

  const updateNeverList = () => {
    const { productId } = offering;
    dispatch(removeProductFromNeverList(productId));
    closeModal();
  };

  const { imageFilename, name } = offering;
  return (
    <ModalWrapper>
      <ModalHeader>Did you change your mind?</ModalHeader>
      <ImageContainer>
        {imageFilename && (
          <Image
            width="250px"
            height="125px"
            alt={`${name}`}
            src={getImageURL(imageFilename, null, {
              w: 500,
              h: 250,
              b: "auto",
              c: "pad",
              d: "products:no-image-found.png",
            })}
          />
        )}
        <ItemDescription>
          By taking this off of your “Never” list, it may show up in weekly
          boxes.
        </ItemDescription>
      </ImageContainer>
      <div>
        <ModalButton intent="cta" onClick={updateNeverList}>
          Yes, allow it in future boxes
        </ModalButton>
      </div>
      <div>
        <ModalGhostButton dark onClick={closeModal}>
          No, keep it on my &ldquo;Never&rdquo; list
        </ModalGhostButton>
      </div>
    </ModalWrapper>
  );
};

const ModalButton = styled(StyledButton)`
  border-radius: 10px;
  margin-bottom: ${rem(16)};
  height: ${rem(48)};
  width: 100%;
`;

const Image = styled.img`
  width: 250px;
  height: 125px;
`;

const ModalGhostButton = styled(GhostButton)`
  border-radius: 10px;
  height: ${rem(48)};
  width: 100%;
`;

const ModalHeader = styled.h3`
  font-weight: 600;
  font-size: ${rem(20)};
  line-height: ${rem(24)};
  color: #282828;
  text-transform: none;
  margin: ${rem(32)} 0 0 0;
`;

const ModalWrapper = styled.div`
  text-align: center;
`;

const ImageContainer = styled.div`
  margin: ${rem(32)} 0;
`;

const ItemDescription = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(17)};
  margin-top: ${rem(16)};
`;

export default NeverListModal;
