// @ts-nocheck
import { RichText } from "prismic-reactjs";
import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { getPrismicLink } from "app/ui/cms/contentPage_DEPRECATED/utils_DEPRECATED";
import Link from "app/ui/shared/Link";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";

export const accumulateSerializers = (serializers) => (
  type,
  element,
  content,
  children,
  key
) => {
  const serializer = serializers.find((s) => {
    return Array.isArray(s.type) ? s.type.includes(type) : s.type === type;
  });
  if (
    serializer &&
    serializer.serialize &&
    typeof serializer.serialize === "function"
  ) {
    return serializer.serialize(type, element, content, children, key);
  }
  return null;
};

// Renders internal_links as react router Links, instead of <a> tags
export const hyperlinkSerializer = () => {
  return {
    type: RichText.Elements.hyperlink,
    serialize: (type, element, content, children, key) => {
      if (element.data.link_type === "Web") {
        const link = getPrismicLink({ ...element.data });
        return (
          <StyledLink key={key} {...link}>
            {children}
            {link.newWindow ? (
              <LaunchIcon titleAccess="(opens in new window)" />
            ) : null}
            {link.to.endsWith(".pdf") ? <DocIcon titleAccess="(PDF)" /> : null}
          </StyledLink>
        );
      }
      return null;
    },
  };
};

// custom pieces of markup that are not available in prismic selections
export const labelSerializer = () => {
  return {
    type: RichText.Elements.label,
    serialize: (type, element, content, children, key) => {
      if (!element.data || !element.data.label) return null;
      switch (element.data.label) {
        case "superscript": {
          return <sup key={key}>{children}</sup>;
        }
        case "small-text": {
          return <small key={key}>{children}</small>;
        }
        default:
          return null;
      }
    },
  };
};

// TODO: refactor code to use this serializer instead of the one in app/ui/shared/CMS/CMSGridItem.js
export const titleSerializer = (titleStyle) => {
  return {
    type: [
      RichText.Elements.heading1,
      RichText.Elements.heading2,
      RichText.Elements.heading3,
      RichText.Elements.heading4,
      RichText.Elements.heading5,
      RichText.Elements.heading6,
    ],
    serialize: (type, element, content, children, key) => {
      const id = element?.text?.replace(/\W+/g, "-")?.toLowerCase();
      switch (type) {
        case RichText.Elements.heading1:
          return (
            <h1 key={key} className={titleStyle} id={id}>
              {children}
            </h1>
          );
        case RichText.Elements.heading2:
          return (
            <h2 key={key} className={titleStyle} id={id}>
              {children}
            </h2>
          );
        case RichText.Elements.heading3:
          return (
            <h3 key={key} className={titleStyle} id={id}>
              {children}
            </h3>
          );
        case RichText.Elements.heading4:
          return (
            <h4 key={key} className={titleStyle} id={id}>
              {children}
            </h4>
          );
        case RichText.Elements.heading5:
          return (
            <h5 key={key} className={titleStyle} id={id}>
              {children}
            </h5>
          );
        case RichText.Elements.heading6:
          return (
            <h6 key={key} className={titleStyle} id={id}>
              {children}
            </h6>
          );
        default:
          return null;
      }
    },
  };
};

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const sharedIconStyles = `
  margin-inline-start: ${rem(3)};
  color: currentColor;
  vertical-align: middle;
`;

const LaunchIcon = styled(LaunchRoundedIcon)`
  ${sharedIconStyles}
`;

const DocIcon = styled(PictureAsPdfRoundedIcon)`
  ${sharedIconStyles}
`;
