import { trackEvent } from "app/api/segment";
import { call, all, select } from "redux-saga/effects";

import {
  getSelectedCancellationReason,
  ACTIVE_TC_MESSAGE_ID,
  DECLINED_TERMS_CANCELLATION_REASON,
} from "app/selectors";
import { CancellationReasonsChosen } from "app/types/state/account/CancellationReasonsChosen";
import { CancellationReason } from "app/types/state/account/Account";
import { CancelSubscription } from "app/types/state/account/CancelSubscription";
import { CancellationWinbackViewed } from "app/types/state/account/CancellationWinbackViewed";

export function* trackCancellationReasonsChosenFlow(
  action: CancellationReasonsChosen
) {
  const { cancellationReasons } = action;
  yield all(
    cancellationReasons.map((reason) =>
      call(trackCancellationReasonChosenFlow, reason)
    )
  );
}

function* trackCancellationReasonChosenFlow({
  cancellationReasonId,
}: CancellationReason) {
  const reason = yield select(getSelectedCancellationReason, {
    cancellationReasonId,
  });
  yield call(trackEvent, "Subscription Cancellation Reason Chosen", {
    cancellationReasonId,
    reason,
  });
}

export function* trackCancellationWinbackViewedFlow({
  key,
  step,
}: CancellationWinbackViewed) {
  yield call(trackEvent, "Subscription Cancellation Win Back Option Viewed", {
    key,
    step,
  });
}

export function* trackCancelSubscription({ values }: CancelSubscription) {
  yield call(trackEvent, "Subscription Cancelled", values);
}

export function* trackCancelSubscriptionDeclinedTerms({
  values,
}: CancelSubscription) {
  if (
    values.cancellationReasons.some(
      (r) => r.cancellationReasonId === DECLINED_TERMS_CANCELLATION_REASON
    )
  ) {
    yield call(trackEvent, "Declined Terms", {
      messageId: ACTIVE_TC_MESSAGE_ID,
      cancellationReasonId: DECLINED_TERMS_CANCELLATION_REASON,
    });
  }
}
