import _isEmpty from "lodash/isEmpty";

import moment from "moment-timezone";
import { createSelector } from "reselect";
import getOrderMinimumValue from "app/ui/signup/shared/orderMinUtils";
import {
  SFOFulfillmentCenterId,
  FREE_SHIPPING_THRESHOLD,
  DEFAULT_PRICE_ZONE_ID,
} from "app/constants";
import {
  ProduceType,
  ShopperOptionsType,
  DietType,
  HouseholdType,
  HOUSEHOLD_RANGES,
  PRODUCE_TYPE_RANGES,
} from "app/constants/signup";
import { matchPath } from "react-router";
import ALL_PACKS_BY_DIETARY_AND_HOUSEHOLDRANGE from "app/constants/signupDecisionTree";
import normalizePath from "app/router/normalizePath";
import * as fromAccount from "app/selectors/account";
import * as fromBoxes from "app/selectors/boxes";
import { signupSecondaryBoxFields } from "app/selectors/boxes.schema";
import * as fromCDDeliveries from "app/selectors/crossDomain/deliveries";
import * as fromDeliveries from "app/selectors/deliveries";
import * as fromRouting from "app/selectors/routing";
import * as fromSignup from "app/selectors/signup";
import * as fromUi from "app/selectors/ui";
import * as fromCDSplitTreatments from "app/selectors/crossDomain/splitTreatments";
import {
  Step,
  SignupSteps,
  SignupStepName,
  SignupValues,
} from "app/types/state/signup";
import {
  DecisionTreeContentType,
  BoxRecommendationType,
  SignupSubscriptionSummaryType,
} from "app/types/selectors/crossDomain/SignupSummary";
import SignupSelectedWindowValuesFormatted from "app/types/selectors/crossDomain/SignupSelectedWindowValuesFormatted";

import {
  NextDelivery,
  ZipCodeSummary,
  FullWindowData,
  SignupSelectedWindowData,
  DeliveryWindow,
} from "app/types/state/deliveries/deliveries";
import Box, { AddOnBox } from "app/types/state/boxes/Box";
import {
  formatCurrency,
  capitalize,
  TIME_DAY_FORMAT,
  SHORT_DATE_FORMAT,
} from "app/ui/global/utils";
import { getPreviousDate } from "app/utils/momentUtils";

import { RequiredNonNullableProperties } from "app/types/utils";
import { getExceptionInlineAlertProps } from "app/ui/signup/shared";

export const isSignupInitialized = createSelector(
  [fromUi.isInitialized, fromSignup.isSignupStateInitialized],
  (initialized, signupInitialized) => initialized && signupInitialized
);

const UrlToStepMap = (signupConfig) => {
  return Object.keys(signupConfig.metadata).reduce((acc, step) => {
    const metadata: Step = signupConfig.metadata[step as SignupStepName];
    acc[metadata.url] = step as SignupStepName;
    return acc;
  }, {} as Record<Step["url"], SignupSteps>);
};

const getSignupStepForRoute = (location, config) => {
  if (!location) return undefined;
  const urlToStep = UrlToStepMap(config);
  const pathname = normalizePath(location.pathname);
  const standardSignupStep = location && urlToStep[pathname];
  if (standardSignupStep) return standardSignupStep;
  // TODO: NC-1244 productionalize signup shopping.
  // We need to make variable route matching generic so
  // this is more flexible.
  const PDPUrl = config.metadata[SignupSteps.SHOP_PRODUCT_DETAILS_PAGE]?.url;
  if (!PDPUrl) return undefined;
  const matchPDP = matchPath(pathname, {
    path: PDPUrl,
    exact: true,
    strict: false,
  });
  return matchPDP?.path ? urlToStep[matchPDP.path] : undefined;
};

export const getCurrentSignupStepForRoute = createSelector(
  [fromRouting.getRoutingLocation, fromSignup.getSignupConfig],
  (location, signupConfig) => {
    return getSignupStepForRoute(location, signupConfig);
  }
);

export const getSignupStepForPreviousRoute = createSelector(
  [fromRouting.getPreviousRoutingLocation, fromSignup.getSignupConfig],
  (location, signupConfig) => {
    return getSignupStepForRoute(location, signupConfig);
  }
);

export const getSignupMetadataForRoute = createSelector(
  [getCurrentSignupStepForRoute, fromSignup.getSignupConfig],
  (step, signupConfig) => {
    return step && signupConfig.metadata[step as SignupStepName];
  }
);

/*
 * Used for restricting the confirmation signup route
 * A user in this state has recently signed up; should be active and currentStep
 * should be set to the confirmation step
 */
export const isSignupConfirmationRouteAllowed = createSelector(
  [
    fromAccount.isUserActive,
    fromSignup.getSignupCurrentStep,
    fromSignup.getSignupConfirmationStep,
  ],
  (activeUser, currentStep, confirmationStep) => {
    return activeUser && currentStep === confirmationStep;
  }
);

// TO DO: NC-882 - update selector to be conscious of signup-quiz-driven first delivery adjustments
// Does NOT accommodate for signup-quiz-driven first delivery adjustments
// when outputting delivery date information.
// Do not use to determine users ACTUAL delivery dates in Signup
// Instead, use getSignupSelectedWindowValuesFormatted or getSubscriptionPosit directly
export const getSignupSelectedDeliveryWindowDEPRECATED = createSelector(
  [fromSignup.getSignupValues, fromDeliveries.getDeliveryWindows],
  (signupValues, windows) => {
    const { selectedDeliveryWindowId, zip } = signupValues;
    if (!windows || !selectedDeliveryWindowId) return null;
    const deliveryWindow = windows[
      selectedDeliveryWindowId
    ] as SignupSelectedWindowData;
    if (!deliveryWindow) return null;

    const {
      timezone,
      zipCodeSummary = {} as ZipCodeSummary,
      nextDelivery = {} as NextDelivery,
    } = deliveryWindow;
    const deliveryDate = moment.tz(nextDelivery.deliveryDate, timezone);
    const replacementOriginalDeliveryDate =
      nextDelivery.replacementOriginalDeliveryDate &&
      moment.tz(nextDelivery.replacementOriginalDeliveryDate, timezone);
    const {
      isWarming,
      warmingMessageToUser,
      warmingEndDateIsExact,
    } = zipCodeSummary;

    let { messageToUser = "We deliver to you!" } = deliveryWindow;

    const normalDeliveryDayOfWeek = (
      replacementOriginalDeliveryDate || deliveryDate
    ).format(`dddd`);

    // If the zip is actively warming and there's a message,
    // replace the messageToUser with the warming message
    if (isWarming) {
      // warmingEnd = moment.tz(warmingEndDate, timezone);
      if (warmingMessageToUser) messageToUser = warmingMessageToUser;
    }

    const isFuzzyDate = isWarming && !warmingEndDateIsExact;
    const deliveryDay =
      !isFuzzyDate &&
      (replacementOriginalDeliveryDate || deliveryDate).format("dddd");
    const firstDeliveryDate = isFuzzyDate
      ? deliveryDate.format("MMMM Y")
      : deliveryDate.format("MMMM D");

    return {
      ...deliveryWindow,
      messageToUser,
      isWarming,
      deliveryDay,
      firstDeliveryDate,
      normalDeliveryDayOfWeek,
      zip,
    };
  }
);

// TODO: Is this ok?  I want to show prices on the /join page, but we don't have a zip yet
// Default to SFO FC if we don't know where you belong yet?
// I don't think prices really differ anyhow?
export const getSignupSelectedFulfillmentCenterId = createSelector(
  [getSignupSelectedDeliveryWindowDEPRECATED], // only use to fetch FC (not delivery date)
  (deliveryWindow) =>
    (deliveryWindow && deliveryWindow.fulfillmentCenterId) ||
    SFOFulfillmentCenterId
);

export const getSignupSelectedDeliveryProvider = createSelector(
  [getSignupSelectedDeliveryWindowDEPRECATED], // only use to fetch Delivery Provider (not delivery date)
  (deliveryWindow) => (deliveryWindow ? deliveryWindow.deliveryProvider : null)
);

// use the subscription Fc Id if Active, otherwise fallback to signup
export const getSignupFulfillmentCenterId = createSelector(
  [
    fromAccount.getAccountFulfillmentCenterId,
    getSignupSelectedFulfillmentCenterId, // note this defaults to SF if we don't have a zip yet
  ],
  (subscriptionFCId, signupFCId) => subscriptionFCId || signupFCId || null
);

// TODO: NC-1244 productionalize signup shopping
export const getNextDeliveryPriceZoneFcAndDate = createSelector(
  [fromCDDeliveries.getActiveWindow, fromAccount.getUserPriceZoneId],
  (activeWindow, priceZoneId) => {
    return {
      fcAbbr: activeWindow?.fcAbbreviation,
      packDate: activeWindow?.nextDelivery?.packDate,
      deliveryDate: activeWindow?.nextDelivery?.deliveryDate,
      windowId: activeWindow?.nextDelivery?.windowId,
      fulfillmentCenterId: activeWindow?.fulfillmentCenterId,
      priceZoneId: priceZoneId || DEFAULT_PRICE_ZONE_ID,
    };
  }
);
export const makeGetSignupSecondaryBoxIds = (requiredFields: string[]) =>
  fromBoxes.makeGetVisibleSecondaryBoxIdsByFcId(
    getSignupFulfillmentCenterId,
    fromCDDeliveries.isUserUglyShip,
    requiredFields
  );
export const getSignupSecondaryBoxIds = makeGetSignupSecondaryBoxIds(
  signupSecondaryBoxFields
);

export const getSignupSelectedSecondaryBoxes = fromBoxes.makeGetDataForBoxIds(
  fromSignup.getSignupSelectedSecondaryBoxIds,
  getSignupSelectedFulfillmentCenterId
);

const getBoxPricing = (
  box: Box,
  addOnBoxes: AddOnBox[],
  priceTemplate?: (totalPrice: number) => string
) => {
  const primaryBoxPrice = box.displayPrice || box.maxPrice;
  const subtotalMaxPrice = addOnBoxes.reduce((boxMaxPrice, secondaryBox) => {
    // eslint-disable-next-line no-param-reassign
    boxMaxPrice += secondaryBox.displayPrice || secondaryBox.maxPrice;
    return boxMaxPrice;
  }, primaryBoxPrice);

  const priceRange =
    typeof priceTemplate === "function"
      ? priceTemplate(Math.round(subtotalMaxPrice))
      : null;

  return {
    primaryBoxPrice,
    subtotalMaxPrice,
    priceRange,
  };
};

const parseQuizValues = (
  quizValues: NonNullable<Required<SignupValues["quizValues"]>>
) => {
  const {
    [SignupSteps.QUIZ_WHO_DO_YOU_SHOP_FOR]: {
      [ShopperOptionsType.ADULTS]: household = 1, // set minimum household size to 1 adult to please TS
    } = {},
    [SignupSteps.QUIZ_DIETARY_RESTRICTIONS]: dietary,
    [SignupSteps.QUIZ_PRODUCE]: produceValue, // number 0-100 on the Regular <-> Organic spectrum
  } = quizValues;

  // determine size bucket that household falls into
  const householdRange = (Object.keys(
    HOUSEHOLD_RANGES
  ) as HouseholdType[]).find(
    (size) =>
      household >= HOUSEHOLD_RANGES[size].min &&
      household <= HOUSEHOLD_RANGES[size].max
  );

  // determine produce type from slider value
  const produce = (Object.keys(PRODUCE_TYPE_RANGES) as ProduceType[]).find(
    (produceType) =>
      produceValue >= PRODUCE_TYPE_RANGES[produceType].min &&
      produceValue <= PRODUCE_TYPE_RANGES[produceType].max
  );

  return { householdRange, dietary, produce };
};

const getDecisionTreeContent = createSelector(
  [fromSignup.getSignupValues],
  (signupValues): DecisionTreeContentType | null => {
    const { quizValues } = signupValues;

    if (!quizValues || _isEmpty(quizValues)) return null;
    const { householdRange, dietary, produce } = parseQuizValues(
      quizValues as NonNullable<Required<SignupValues["quizValues"]>>
    );

    const produceValueAdjusted = (produce === ProduceType.BOTH_PRODUCE
      ? ProduceType.ORGANIC
      : produce) as ProduceType.CONVENTIONAL | ProduceType.ORGANIC;

    const { boxId, packsSuggested, title, subtitle, priceTemplate } = {
      ...ALL_PACKS_BY_DIETARY_AND_HOUSEHOLDRANGE[dietary as DietType],
      ...ALL_PACKS_BY_DIETARY_AND_HOUSEHOLDRANGE[dietary as DietType][
        produceValueAdjusted
      ][householdRange as HouseholdType],
    };

    return {
      boxId,
      packsSuggested,
      title,
      subtitle,
      priceTemplate,
    };
  }
);

export const getBoxRecommendation = createSelector(
  [
    getDecisionTreeContent,
    getSignupSelectedDeliveryWindowDEPRECATED, // only use to fetch FC and delivery price (not delivery date)
    fromBoxes.getMapOfBoxes,
    getSignupSecondaryBoxIds,
  ],
  (
    decisionTreeContent,
    selectedDeliveryWindow,
    mapOfBoxes,
    secondaryBoxIds
  ): BoxRecommendationType | null => {
    const {
      fulfillmentCenterId,
      deliveryPrice,
    } = selectedDeliveryWindow as FullWindowData;
    if (!decisionTreeContent || !fulfillmentCenterId) return null;

    const {
      boxId,
      packsSuggested,
      title,
      subtitle,
      priceTemplate,
    } = decisionTreeContent;

    // not all packs are available to all locations.
    // include only suggested packs that are available
    const addOnPackIds = packsSuggested.filter((packId) =>
      secondaryBoxIds.includes(packId)
    );

    const selectedBox = fromBoxes.getBoxDataForFCId(
      mapOfBoxes[boxId],
      fulfillmentCenterId
    );

    const selectedAddOnPacks = addOnPackIds.map((boxId) =>
      fromBoxes.getBoxDataForFCId(mapOfBoxes[boxId], fulfillmentCenterId)
    );

    const { subtotalMaxPrice, priceRange } = getBoxPricing(
      selectedBox,
      selectedAddOnPacks,
      priceTemplate
    ) as { subtotalMaxPrice: number; priceRange: string };

    const formattedDeliveryPrice = formatCurrency(deliveryPrice);

    return {
      boxId,
      addOnPackIds,
      title,
      subtitle,
      subtotalMaxPrice,
      formattedDeliveryPrice,
      priceRange,
    };
  }
);

export const getSignupSelectedFirstDelivery = createSelector(
  [fromSignup.getSubscriptionPosit, fromSignup.getSignupValues],
  (subscriptionPosit, signupValues) => {
    if (!subscriptionPosit) return null;

    if (!signupValues?.quizValues?.[SignupSteps.QUIZ_FIRST_DELIVERY])
      return subscriptionPosit;

    const selectedFirstDeliveryDate =
      signupValues.quizValues[SignupSteps.QUIZ_FIRST_DELIVERY];

    if (selectedFirstDeliveryDate === subscriptionPosit.deliveryDate)
      return subscriptionPosit;

    const selectedAlternateDate = subscriptionPosit.alternateDates?.find(
      (alternateDate) => {
        return [
          alternateDate.deliveryDate,
          alternateDate.replacementOriginalDeliveryDate,
        ].includes(selectedFirstDeliveryDate);
      }
    );

    if (selectedAlternateDate) return selectedAlternateDate;

    return subscriptionPosit;
  }
);

// standard function syntax to avoid circular dependency issue
// https://github.com/reduxjs/reselect/issues/169#issuecomment-274690285
export function getSignupSelectedDeliveryWindow() {
  return createSelector(
    [getSignupSelectedFirstDelivery],
    (firstDeliveryData) => firstDeliveryData?.deliveryWindow
  );
}

export const getSignupSelectedWindowValuesFormatted = createSelector(
  [
    getSignupSelectedFirstDelivery,
    fromSignup.getSignupValues,
    fromDeliveries.getDeliveryWindows,
  ],
  (
    selectedFirstDelivery,
    signupValues,
    deliveryWindows
  ): SignupSelectedWindowValuesFormatted | null => {
    if (!selectedFirstDelivery) return null;

    const selectedDeliveryWindowId = signupValues?.selectedDeliveryWindowId;
    const selectedDeliveryWindow =
      selectedDeliveryWindowId && deliveryWindows[selectedDeliveryWindowId];

    // Choose raw delivery fields from the selectedAlternateDate, or the default posit
    const {
      deliveryWindow,
      isReplacementDeliveryDate,
      replacementOriginalDeliveryDate,
    } = selectedFirstDelivery;

    // If an OOA zip is chosen, the deliveryWindow will not exist so return early
    if (!deliveryWindow) {
      return null;
    }

    const {
      startDate,
      startTime,
      customizationStartDate,
      customizationStartTime,
      customizationEndDate,
      customizationEndTime,
      timezone,
    } = deliveryWindow as RequiredNonNullableProperties<DeliveryWindow>;

    return {
      deliveryDateAsMoment: moment.tz(`${startDate} ${startTime}`, timezone),
      customizationStartAsMoment: moment.tz(
        `${customizationStartDate} ${customizationStartTime}`,
        timezone
      ),
      customizationEndAsMoment: moment.tz(
        `${customizationEndDate} ${customizationEndTime}`,
        timezone
      ),
      isReplacementDeliveryDate,
      ...(isReplacementDeliveryDate &&
        replacementOriginalDeliveryDate &&
        selectedDeliveryWindow && {
          ...getOriginalDeliveryWindow(
            replacementOriginalDeliveryDate,
            selectedDeliveryWindow,
            timezone
          ),
        }),
    };
  }
);

const getOriginalDeliveryWindow = (
  replacementOriginalDeliveryDate: string,
  deliveryWindow: FullWindowData,
  timezone: string
) => {
  const {
    startTime,
    customizationStartDay,
    customizationStartTime,
    customizationEndDay,
    customizationEndTime,
  } = deliveryWindow;

  // Start at delivery date in the timezone
  const originalDeliveryDateAsMoment = moment.tz(
    `${replacementOriginalDeliveryDate} ${startTime}`,
    timezone
  );

  // Move back to custo end
  const originalCustomizationEndAsMoment = getPreviousDate(
    originalDeliveryDateAsMoment,
    customizationEndDay,
    customizationEndTime
  );

  return {
    originalDeliveryDateAsMoment,
    originalCustomizationEndAsMoment,
    // Move back to custo start
    originalCustomizationStartAsMoment: getPreviousDate(
      originalCustomizationEndAsMoment,
      customizationStartDay,
      customizationStartTime
    ),
  };
};

// Checkout flow belonging to this epic: https://imperfectfoods.atlassian.net/browse/NC-321
export const getSignupSubscriptionSummary = createSelector(
  [
    (state) => fromCDSplitTreatments.getOrderMinTreatment(state),
    getBoxRecommendation,
    fromSignup.getSignupValues,
    fromCDDeliveries.isUserUglyShip,
    fromCDDeliveries.getActiveWindow,
    getSignupSelectedWindowValuesFormatted,
  ],
  (
    orderMinTreatmentData,
    boxRecommendation,
    signupValues,
    isCarriership,
    activeWindow,
    selectedWindowValues
  ): SignupSubscriptionSummaryType | null => {
    if (
      !boxRecommendation ||
      _isEmpty(boxRecommendation) ||
      !activeWindow ||
      !selectedWindowValues
    )
      return null;

    const formattedShoppingStart = selectedWindowValues.customizationStartAsMoment.format(
      TIME_DAY_FORMAT
    );
    const formattedShoppingEnd = selectedWindowValues.customizationEndAsMoment.format(
      TIME_DAY_FORMAT
    );
    const shoppingWindow = `${formattedShoppingStart} - ${formattedShoppingEnd}`;

    const formattedDeliveryDate = selectedWindowValues.deliveryDateAsMoment.format(
      SHORT_DATE_FORMAT
    );

    const deliveryDayOfWeek = selectedWindowValues.deliveryDateAsMoment.format(
      "dddd"
    );

    const {
      boxId,
      addOnPackIds,
      title,
      subtitle,
      formattedDeliveryPrice,
      priceRange,
    } = boxRecommendation;

    const orderMin = getOrderMinimumValue(
      isCarriership,
      orderMinTreatmentData,
      activeWindow
    );

    const freeShippingThreshold = !isCarriership
      ? FREE_SHIPPING_THRESHOLD
      : undefined;

    return {
      boxId,
      addOnPackIds,
      title,
      subtitle,
      priceRange,
      formattedDeliveryPrice,
      orderMin,
      freeShippingThreshold,
      shoppingWindow,
      deliveryDate: formattedDeliveryDate,
      deliveryDayOfWeek,
      cadence: capitalize(signupValues?.selectedFrequency),
      ...(selectedWindowValues.isReplacementDeliveryDate && {
        firstDeliveryInlineAlert: getExceptionInlineAlertProps(
          selectedWindowValues
        ),
      }),
    };
  }
);

const makeGetSecondaryBoxesByDeliveryWindowId = (requiredFields: string[]) => {
  return fromBoxes.makeGetVisibleSecondaryBoxIdsByFcId(
    fromDeliveries.getFCIdByDeliveryWindowId,
    fromCDDeliveries.isWindowUglyShip,
    requiredFields
  );
};
export const getSecondaryBoxesByDeliveryWindowId = makeGetSecondaryBoxesByDeliveryWindowId(
  signupSecondaryBoxFields
);
