import React from "react";
import { Dialog, DialogProps } from "@material-ui/core";

import { ReferralStatusInfoDialog as ReferralStatusInfoDialogType } from "app/types/ui/Dialog";
import DialogContent from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";
import { SemiBold } from "app/ui/shared/ThemedTypography";

export interface ReferralStatusInfoDialogProps
  extends Omit<
    DialogProps,
    "open" | "maxWidth" | "onClose" | "TransitionProps"
  > {
  dialog: ReferralStatusInfoDialogType;
}

export const ReferralStatusInfoDialog: React.FC<ReferralStatusInfoDialogProps> = ({
  dialog,
  ...dialogProps
}) => {
  const { open, handleClose, handleExited } = useHandleTransitions(dialog);
  const handlePrimaryButtonClick = () => {
    handleClose();
  };
  const handleCancelClick = () => {
    handleClose();
  };

  return (
    <Dialog
      {...dialogProps}
      data-testid="dialog-referral-status"
      maxWidth={false}
      open={open}
      onClose={handleCancelClick}
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogContent
        overlineText="Referral Status"
        headerText="Keep track of your referrals"
        subcopyText={
          <>
            You can{" "}
            <SemiBold component="strong" variant="inherit">
              resend
            </SemiBold>{" "}
            an invite to a referral who has not signed up yet. Referrals are
            marked{" "}
            <SemiBold component="strong" variant="inherit">
              pending
            </SemiBold>{" "}
            when someone has signed up but not placed an order. Once
            they&apos;ve placed their first order, the credit lands in your
            account &amp; the referral is marked{" "}
            <SemiBold component="strong" variant="inherit">
              complete
            </SemiBold>
            .
          </>
        }
        primaryButtonText="Close"
        onPrimaryButtonClick={handlePrimaryButtonClick}
        onClearIconButtonClick={handleCancelClick}
      />
    </Dialog>
  );
};

export default ReferralStatusInfoDialog;
