import queryString from "query-string";
import { createSelector } from "reselect";

import { toAisle } from "app/router/routes";
import { getSelectedTags } from "app/selectors";
import {
  isInShoppableRecipeAisleTreatment,
  getShoppableRecipeAisleConfig,
  isInSignupShoppingTreatment,
} from "app/selectors/crossDomain/splitTreatments";
import * as fromAisles from "app/selectors/aisles";
import * as fromCatalog from "app/selectors/catalog";
import * as fromRouting from "app/selectors/routing";
import * as fromSearch from "app/selectors/search";
import * as fromUI from "app/selectors/ui";
import * as fromAccount from "app/selectors/account";
import AlgoliaData from "app/types/algolia/AlgoliaData";
import State from "app/types/state";
import Aisle from "app/types/state/aisles/Aisle";
import Shelf from "app/types/state/shelves/Shelf";
import ShoppingContext from "app/types/ui/ShoppingContext";

interface AisleShelfIdsFromHash {
  currentAnchorId?: string;
  currentAisle?: Aisle;
  currentShelf?: Shelf;
}

interface ShoppingContextProps {
  context: ShoppingContext;
}

function getAisleAndShelfForAnchorId(
  aisleConfig: Aisle[],
  anchorId: string | undefined
) {
  let currentShelf: Shelf | undefined;
  const currentAisle = aisleConfig.find((aisle) => {
    if (aisle.aisleId === anchorId) {
      // eslint-disable-next-line prefer-destructuring
      currentShelf = aisle.shelves[0];
      return true;
    }
    currentShelf = aisle.shelves.find((shelf) => shelf.shelfId === anchorId);
    return !!currentShelf;
  });

  return {
    currentAnchorId: anchorId,
    currentAisle,
    currentShelf,
  };
}

export const getAisleShelfIdsFromHash = createSelector(
  [fromRouting.getRoutingLocation, fromAisles.getAisleConfig],
  (location, aisleConfig): AisleShelfIdsFromHash => {
    const hash = queryString.parse(location.hash) as { shelf?: string };
    return getAisleAndShelfForAnchorId(aisleConfig, hash.shelf);
  }
);

export const getAisleForShelfId = createSelector(
  [fromAisles.getAisleConfig, (_: State, props: { shelfId?: string }) => props],
  (aisleConfig, { shelfId }): AisleShelfIdsFromHash => {
    return getAisleAndShelfForAnchorId(aisleConfig, shelfId);
  }
);

export const getVariantIdsPerActiveAisleShelf = createSelector(
  [
    fromCatalog.isCatalogInitialized,
    fromAisles.isAislesConfigInitialized,
    fromCatalog.getVariantIdsByShelf,
    getAisleShelfIdsFromHash,
  ],
  (
    isCatalogInitialized,
    isAislesConfigInitialized,
    variantIdsByShelf,
    aisleShelfIds
  ) => {
    if (
      !isCatalogInitialized ||
      !isAislesConfigInitialized ||
      !variantIdsByShelf
    ) {
      return [];
    }
    return aisleShelfIds.currentAisle?.shelves?.map(
      ({ shelfId }) => variantIdsByShelf[shelfId]
    );
  }
);

export const isShoppingReady = createSelector(
  [
    fromCatalog.isCatalogInitialized,
    fromUI.isShoppingInitialized,
    fromUI.getCatalogPreviewTool,
  ],
  (catalogInitialized, shoppingInitialized, catalogPreviewTool) =>
    (catalogInitialized || catalogPreviewTool?.data) && shoppingInitialized
);

export const canViewSignupShopping = createSelector(
  [isInSignupShoppingTreatment, fromAccount.isUserProspect],
  (isInTreatment, isUserProspect) => isInTreatment && isUserProspect
);

export const getShoppingContextFromRoute = createSelector(
  [getAisleShelfIdsFromHash, fromSearch.isSearching],
  (aisleShelfIds, isSearching) => {
    if (isSearching) {
      return ShoppingContext.SEARCH;
    }
    if (aisleShelfIds.currentAisle?.shelves?.length) {
      return ShoppingContext.CATALOG;
    }

    return null;
  }
);

export const getActiveAlgoliaData = createSelector(
  [
    getShoppingContextFromRoute,
    getAisleShelfIdsFromHash,
    fromSearch.getSearchAlgoliaData,
    fromCatalog.getAlgoliaDataByShelf,
    (_: State, props?: ShoppingContextProps) => props,
  ],
  (
    defaultContext,
    aisleRouteData,
    searchAlgoliaData,
    catalogAlgoliaData,
    props
  ) => {
    // Allow the context to be overriden, though should not be used very often if at all
    // Example: What context would we use for clicking an offering in the cart while the search results are active?
    const context = props?.context ? props.context : defaultContext;
    let algoliaData: AlgoliaData = { queryID: "" };

    if (context === ShoppingContext.SEARCH && searchAlgoliaData) {
      algoliaData = searchAlgoliaData;
    } else if (context === ShoppingContext.CATALOG) {
      const shelf = aisleRouteData.currentShelf;
      if (shelf) {
        algoliaData = catalogAlgoliaData[shelf.shelfId];
      }
    }

    return {
      ...algoliaData,
      context,
    };
  }
);

export const getAisleListData = createSelector(
  [
    getAisleShelfIdsFromHash,
    getSelectedTags,
    fromCatalog.getVariantIdsByShelf,
    fromCatalog.isRefetchingCatalog,
    fromAisles.getAisleConfig,
    isInShoppableRecipeAisleTreatment,
    getShoppableRecipeAisleConfig,
    canViewSignupShopping,
  ],
  (
    aisleShelfIds,
    tags,
    variantIdsByShelf,
    isRefetchingCatalog,
    aisleConfig,
    inShoppableRecipeAisleTreatment,
    shoppableRecipeAisleConfig,
    canViewSignupShopping
  ) => {
    const { currentAisle } = aisleShelfIds;
    const tagPath = tags.length
      ? queryString.stringify({ tag: tags[0].tagId })
      : "";

    return (
      aisleConfig
        // remove recipe aisles if the user is not in the experiment
        .filter(
          (aisle) =>
            inShoppableRecipeAisleTreatment ||
            !shoppableRecipeAisleConfig?.aisleIds?.includes(aisle.aisleId)
        )
        .map((aisle) => {
          const numVariants = aisle.shelves.reduce((acc, shelf) => {
            return acc + (variantIdsByShelf?.[shelf.shelfId]?.length ?? 0);
          }, 0);
          const value =
            tags.length > 0 && !isRefetchingCatalog ? numVariants : null;

          return {
            active: aisle.aisleId === currentAisle?.aisleId,
            disabled: false,
            id: aisle.aisleId,
            label: aisle.name,
            to: toAisle({
              shelf: { shelfId: aisle.aisleId },
              tagPath,
              isInSignupShopping: canViewSignupShopping,
            }),
            endpoint: aisle.endpoint,
            value,
          };
        })
    );
  }
);

export const getSubaisleListData = createSelector(
  [
    getAisleShelfIdsFromHash,
    getSelectedTags,
    fromCatalog.getVariantIdsByShelf,
    fromCatalog.isRefetchingCatalog,
    canViewSignupShopping,
    (_: State, props: { aisle?: Aisle }) => props,
  ],
  (
    aisleShelfIds,
    tags,
    variantIdsByShelf,
    isRefetchingCatalog,
    canViewSignupShopping,
    { aisle }
  ) => {
    const { currentAnchorId } = aisleShelfIds;
    const tagPath = tags.length
      ? queryString.stringify({ tag: tags[0].tagId })
      : "";

    return aisle?.shelves.map((shelf) => {
      const numVariants = variantIdsByShelf?.[shelf.shelfId]?.length ?? 0;
      const value =
        tags.length > 0 && !isRefetchingCatalog ? numVariants : null;

      return {
        active: shelf.shelfId === currentAnchorId && !!numVariants,
        disabled: !numVariants,
        id: shelf.shelfId,
        label: shelf.name,
        to: toAisle({
          shelf,
          tagPath,
          isInSignupShopping: canViewSignupShopping,
        }),
        endpoint: shelf.endpoint,
        value,
      };
    });
  }
);
