// @ts-nocheck
export const COUPONCODE_QUERY_KEY = "promoCode";

export const SFOFulfillmentCenterId = "f686fb75-377b-44fb-9073-40ff277bef1d";
export const LAFulfillmentCenterId = "9da947fe-5a46-424f-b328-e9414c197a32";
export const CHIFulfillmentCenterId = "c059b389-ff65-4283-8edb-06ffc96193af";
export const SATFulfillmentCenterId = "c2fcd071-f479-4f68-a54d-a382bd9fa552";
export const PNWFulfillmentCenterId = "62759d07-dda5-4e5e-a0b9-fce960493522";
export const BWIFulfillmentCenterId = "25729cbe-719c-4314-ab07-0fbc45d38a11";

export const FC_OPTIONS = {
  [BWIFulfillmentCenterId]: {
    value: BWIFulfillmentCenterId,
    label: "BWI",
  },
  [CHIFulfillmentCenterId]: {
    value: CHIFulfillmentCenterId,
    label: "CHI",
  },
  [LAFulfillmentCenterId]: {
    value: LAFulfillmentCenterId,
    label: "LA",
  },
  [PNWFulfillmentCenterId]: {
    value: PNWFulfillmentCenterId,
    label: "PNW",
  },
  [SATFulfillmentCenterId]: {
    value: SATFulfillmentCenterId,
    label: "SAT",
  },
  [SFOFulfillmentCenterId]: {
    value: SFOFulfillmentCenterId,
    label: "SFO",
  },
};

export const CONTROL_PRICE_ZONE_ID = "939c21d5-43a6-4e94-a9c8-317ffc9caba5";
export const LOW_PRICE_ZONE_ID = "3c98eb8f-8876-444a-a7e6-1066f01ba9ca";
export const MEDIUM_PRICE_ZONE_ID = "41fdc1df-5906-4437-8672-e2de66a4700f";
export const HIGH_PRICE_ZONE_ID = "fb737857-40fd-4daa-aaf6-d0f3a48b7a83";

export const PRICE_ZONE_OPTIONS = {
  [CONTROL_PRICE_ZONE_ID]: {
    value: CONTROL_PRICE_ZONE_ID,
    label: "Control",
  },
  [LOW_PRICE_ZONE_ID]: {
    value: LOW_PRICE_ZONE_ID,
    label: "Low",
  },
  [MEDIUM_PRICE_ZONE_ID]: {
    value: MEDIUM_PRICE_ZONE_ID,
    label: "Medium",
  },
  [HIGH_PRICE_ZONE_ID]: {
    value: HIGH_PRICE_ZONE_ID,
    label: "High",
  },
};

export const PLUS_PRICE_VARIANT_ID = "695ad90b-ff1c-43d1-ac5a-2ff1a25ffa94";
export const PLUS_MEMBERSHIP_GROUP_ID = "b50e3778-be33-4136-a64b-c93e2ea0d912";

export const MEDIUM_MIXED_BOX = "73f12c07-3437-458e-9a89-2bf75c3f193d";
export const MEDIUM_ORGANIC_BOX = "c17a7bf2-f276-4c70-abc2-852fe276991c";
export const LARGE_MIXED_BOX = "7e35bba7-63cd-41dc-bc7e-01ffd3203647";
export const LARGE_ORGANIC_BOX = "36c07fd2-0739-4125-bbe2-c28391aaeff7";
export const EXTRA_LARGE_MIXED_BOX = "b236e2a6-339f-4045-aa56-c56678e82fb6";
export const EXTRA_LARGE_ORGANIC_BOX = "9c4ea5fd-55d6-45f8-abc7-79a8c988e234";

export const SNACK_BOX = "ba2bfb0e-ab11-4520-8d78-5dfc39a54035";
export const GRAINS_BOX = "cea5343a-80ea-415d-997a-088446c4e933";
export const DAIRY_BOX = "3a7c2623-f674-4731-80cd-272630cfa049";
export const MEAT_AND_FISH_BOX = "83377c44-7cf4-4cb8-b9b7-fbf84cb22143";
export const PLANT_BOX = "6f6125c9-75ad-45d2-8643-33b571087eb9";

export const UGLYSHIP_DELIVERY_NOTES = "Delivered via FedEx";
export const DEFAULT_PRICE_ZONE_ID = "939c21d5-43a6-4e94-a9c8-317ffc9caba5"; // Medium price zone
export const getRankedIndex = (env) => `${env}_offerings_ranked`;
export const getVariantsIndex = (env) => `${env}_variants`;

// truncate products to 30 to not exceed Segment payload kb limit
export const PRODUCT_LIST_EVENT_LIMIT = 30;

export const SEARCH_QUERY_MIN = 2;

// As of right now, all users have $60 free delivery threshold
// This may change when we productize free delivery threshold
// https://imperfectfoods.atlassian.net/browse/MER-231
export const FREE_SHIPPING_THRESHOLD = "$60";

export const USER_CADENCE_WEEKLY = "WEEKLY";
export const USER_CADENCE_BIWEEKLY = "BIWEEKLY";

export const HEADER_NAV_UID = "header-nav";

export const STRIPE_FONT_URL =
  "https://fonts.googleapis.com/css2?family=Montserrat&display=swap";

// public keys
export const AMPLITUDE_KEY_PROD = "a55a37a212024ad62e933b63270830aa";
export const AMPLITUDE_KEY_STAGING = "887c7f25061624ccc970005c608ceb44";
