import colorPrimitives from "app/styles/colors";

export default {
  palette: {
    // this custom key adds our design system colors to be accessed by "color"
    // and "bgcolor" props in MUI components
    custom: {
      type: {
        peppercorn: colorPrimitives.peppercorn,
        plum: colorPrimitives.plum,
        eggplant: colorPrimitives.eggplant,
        kale: colorPrimitives.kale,
      },
      neutral: {
        marshmallow: colorPrimitives.marshmallow,
        baba: colorPrimitives.baba,
        oat: colorPrimitives.oat,
        barley: colorPrimitives.barley,
        taro: colorPrimitives.taro,
        baseGray: colorPrimitives.baseGray,
      },
      background: {
        frosting: colorPrimitives.frosting,
        lavender: colorPrimitives.lavender,
        rose: colorPrimitives.rose,
        papaya: colorPrimitives.papaya,
        lemon: colorPrimitives.lemon,
        lime: colorPrimitives.lime,
      },
      ui: {
        guava: colorPrimitives.guava,
        watermelon: colorPrimitives.watermelon,
      },
    },
    primary: {
      main: colorPrimitives.beet,
    },
    secondary: {
      main: colorPrimitives.plum,
    },
    background: {
      paper: colorPrimitives.marshmallow,
    },
    error: {
      main: colorPrimitives.cherry,
    },
    info: {
      main: colorPrimitives.robinEgg,
    },
    success: {
      main: colorPrimitives.lettuce,
    },
    warning: {
      main: colorPrimitives.lemon,
    },
  },
};
