import _pick from "lodash/pick";
import _omit from "lodash/omit";
import _isUndefined from "lodash/isUndefined";
import { LOCATION_CHANGE } from "connected-react-router";

import { config } from "app/config";
import routes from "app/router/routes.json";
import { GroceryType } from "app/constants/signup";
import { COUPONCODE_QUERY_KEY, MEDIUM_ORGANIC_BOX } from "app/constants";
import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import ORDER_ACTION_TYPES from "app/actionTypes/orders";
import ACTION_TYPES from "app/actionTypes/signup";

import { LocationChangeAction } from "app/types/state/extendedRouter";
import CreateAccount from "app/types/signup/createAccount";
import OrderLineItems from "app/types/signup/orderLineItems";
import { SetDeliveryDetailsParams } from "app/types/signup/setDeliveryDetails";
import SetPaymentDetails from "app/types/signup/setPaymentDetails";
import SignupState, {
  SignupConfig,
  SignupSteps,
  SignupValues,
  ResetSignupValues,
  SignupValuesQuizValues,
  SetSelectedZipCodeSucceededAction,
  SignupVariantQuantity,
} from "app/types/state/signup";
import { User } from "app/types/state/account/Account";
import SubscriptionPositResponseAction from "app/types/state/account/SubscriptionPositResponseAction";
import SubscriptionPosit from "app/types/state/account/SubscriptionPosit";
import { OrderLineItem } from "app/types/state/orders/Order";
import reduceQueryParams from "app/utils/reduceQueryParams";

// Configuration object to encapsulate analytics + flow
// Keep an eye toward A-B testing supporting multiple configs
// Metadata: page title, url, banner text
// Determine current, next step based on url in metadata?
export const signupConfig: SignupConfig = {
  flow: [
    SignupSteps.JOIN,
    SignupSteps.SUBSCRIPTION,
    SignupSteps.QUIZ_WHO_DO_YOU_SHOP_FOR,
    SignupSteps.QUIZ_FIRST_DELIVERY,
    SignupSteps.QUIZ_SHOPPING_WINDOW,
    SignupSteps.QUIZ_PRODUCE,
    SignupSteps.QUIZ_DIETARY_RESTRICTIONS,
    SignupSteps.QUIZ_WHAT_DO_YOU_BUY,
    SignupSteps.QUIZ_SUMMARY,
    SignupSteps.CREDENTIALS,
    SignupSteps.DELIVERY,
    SignupSteps.PAYMENT,
  ],
  confirmation: SignupSteps.CONFIRMATION,
  metadata: {
    [SignupSteps.JOIN]: {
      title: "Get Fresh Produce, Organic Food & More Delivered",
      url: routes.signup.url,
    },
    [SignupSteps.SUBSCRIPTION]: {
      title: "Personalize",
      url: routes.signupSubscription.url,
    },
    [SignupSteps.QUIZ_WHO_DO_YOU_SHOP_FOR]: {
      title: "Who are you shopping for?",
      url: routes.signupQuizWhoDoYouShopFor.url,
      isQuizStep: true,
      quizValueKey: SignupSteps.QUIZ_WHO_DO_YOU_SHOP_FOR,
    },
    [SignupSteps.QUIZ_FIRST_DELIVERY]: {
      title: "When do you want your first weekly delivery?",
      url: routes.signupQuizFirstDelivery.url,
      isQuizStep: true,
      isExceptionIneligible: true,
      quizValueKey: SignupSteps.QUIZ_FIRST_DELIVERY,
    },
    [SignupSteps.QUIZ_SHOPPING_WINDOW]: {
      title: "Here's your weekly shopping window",
      isQuizStep: true,
      url: routes.signupQuizShoppingWindow.url,
    },
    [SignupSteps.QUIZ_PRODUCE]: {
      title: "What kind of produce do you tend to eat?",
      url: routes.signupQuizProduce.url,
      isQuizStep: true,
      quizValueKey: SignupSteps.QUIZ_PRODUCE,
    },
    [SignupSteps.QUIZ_DIETARY_RESTRICTIONS]: {
      title: "Do you have any dietary restrictions?",
      url: routes.signupQuizDietaryRestrictions.url,
      isQuizStep: true,
      quizValueKey: SignupSteps.QUIZ_DIETARY_RESTRICTIONS,
    },
    [SignupSteps.QUIZ_WHAT_DO_YOU_BUY]: {
      title: "What do you buy each week?",
      url: routes.signupQuizWhatDoYouBuy.url,
      isQuizStep: true,
      quizValueKey: SignupSteps.QUIZ_WHAT_DO_YOU_BUY,
    },
    [SignupSteps.QUIZ_SUMMARY]: {
      title: "Review your summary",
      isQuizStep: true,
      url: routes.signupQuizSummary.url,
    },
    [SignupSteps.CREDENTIALS]: {
      title: "Create Your Account",
      banner: "Create an account",
      url: routes.signupAccount.url,
    },
    [SignupSteps.DELIVERY]: {
      title: "Set Up Delivery",
      banner: "Set up delivery",
      url: routes.signupDelivery.url,
    },
    [SignupSteps.SHOP]: {
      title: "Shop Imperfect Foods",
      url: routes.signupShopping.url,
    },
    [SignupSteps.SHOP_PRODUCT_DETAILS_PAGE]: {
      title: "Product Details",
      url: routes.signupShoppingProductDetailPage.url,
    },
    [SignupSteps.PAYMENT]: {
      title: "Add Your Payment Info",
      banner: "Check out",
      url: routes.signupPayment.url,
    },
    [SignupSteps.CONFIRMATION]: {
      title: "Thanks for Registering",
      url: routes.signupComplete.url,
    },
  },
};

// These are the reducer values we should exclude from persistence
export const blacklist = [
  "initialized",
  "currentStep", // this isn't persisted so that the user is sent back to the progressStep (furthest reached step) at the start of each session
  "config",
  "subscriptionPosit",
  "subscriptionPositHasExceptions",
  // TODO: NC-1244 productionalize signup shopping
  "orderPosit",
];

export const initialState: SignupState = {
  initialized: false,
  currentStep: null,
  progressStep: null,
  config: signupConfig,
  values: {
    selectedFrequency: "WEEKLY",
    boxId: MEDIUM_ORGANIC_BOX,
    boxType: "Organic",
    addOnBoxIds: [],
    quizValues: {},
    firstName: "",
    lastName: "",
    address: "",
    addressLine2: "",
    city: "",
    state: "",
    phone: "",
    deliveryNotes: "",
  },
  prospectiveCoupon: null,
  subscriptionPosit: null,
  subscriptionPositHasExceptions: false,
  // TODO: NC-1244 productionalize signup shopping
  variantQuantities: [],
  orderPosit: null,
  isPrefilledCartFilled: false,
  utmParams: {},
  gclid: undefined,
};

/*
 * Seed the user's values, referenced by the signup flow.
 * The signupZip/signupOutOfArea indicate to the flow an out of area zip code
 * the user has used to subscribe to the newsletter.
 */
const seedSignupValues = (state: SignupState, { user }: { user: User }) => {
  let values = {};
  const { defaultAddressId, signupPreferences } = user;

  values = {
    ...values,
    ..._pick(user, ["firstName", "lastName", "email", "phone"]),
  };

  if (signupPreferences) {
    const { zip, cadence, boxId } = signupPreferences;
    values = {
      ...values,
      zip,
      boxId,
      selectedFrequency: cadence,
    };
  }
  if (defaultAddressId) {
    const address = user.addresses.find(
      ({ addressId }) => defaultAddressId === addressId
    );
    values = {
      ...values,
      ..._pick(address, [
        "address",
        "addressLine2",
        "city",
        "state",
        "zip",
        "deliveryNotes",
      ]),
    };
  }

  return {
    ...state,
    values: {
      // Intentionally prioritizing new signup values chosen!
      ...values,
      ...state.values,
    },
  };
};

const findProgress = (currentProgress: number, currentStep: number) => {
  return currentProgress > currentStep ? currentProgress : currentStep;
};

const handleStepComplete = (
  state: SignupState,
  { values }: { values: Partial<SignupValues> }
) => {
  const { config, currentStep, progressStep } = state;
  const { flow, confirmation } = config;

  // Determine current index of flow array based on current step
  let index = flow.indexOf(currentStep!);

  // Move index forward (TODO: account for auto-completion of box here?)
  index += 1;
  let nextStep: SignupSteps = flow[index];
  let nextProgress;

  if (nextStep) {
    // Determine the index of the next progress based on overall progress & nextStep
    // Determine current index of flow array based on current step
    index = findProgress(flow.indexOf(progressStep!), index);
    nextProgress = flow[index];
  } else {
    nextStep = confirmation;
  }

  return {
    ...state,
    currentStep: nextStep,
    progressStep: nextProgress,
    values: {
      ...state.values,
      ...values,
      quizValues: {
        ...state.values.quizValues,
        ...values.quizValues,
      },
    },
  };
};

const positHasExceptions = (posit: SubscriptionPosit) => {
  if (posit?.isReplacementDeliveryDate) {
    return true;
  }
  return posit?.alternateDates.some(
    (alternateDate) => alternateDate.isReplacementDeliveryDate
  );
};

// TODO: NC-1244 productionalize signup shopping
const addRemoveShopForFlow = (isInSignupShopping, flow) => {
  if (_isUndefined(isInSignupShopping)) return flow;
  const isInFlow = flow.includes(SignupSteps.SHOP);
  // Add step if user is in treatment and shopping step doesnt exist in flow
  if (isInSignupShopping && !isInFlow) {
    const flowWithShopping = [...flow];
    const indexOfPayment = flow.indexOf(SignupSteps.PAYMENT);
    flowWithShopping.splice(indexOfPayment, 0, SignupSteps.SHOP);

    return flowWithShopping;
  }

  // Remove step if user switches to off treatment and shopping step is in flow.
  if (!isInSignupShopping && isInFlow) {
    const flowWithoutShopping = flow.filter(
      (step) => step !== SignupSteps.SHOP
    );
    return flowWithoutShopping;
  }

  return flow;
};

const updateConfigWithExceptions = ({
  firstDeliveryHasException,
  subscriptionPositHasExceptions,
}) => {
  let updatedConfig = { ...signupConfig };
  if (subscriptionPositHasExceptions) {
    // Filter out any steps that are isExceptionIneligible
    const onlyEligibleFlow = signupConfig.flow.filter(
      (step) => !signupConfig.metadata[step].isExceptionIneligible
    );

    updatedConfig = { ...updatedConfig, flow: onlyEligibleFlow };
  }

  if (firstDeliveryHasException) {
    // Replace the title on the shopping window step if the first delivery is on exception
    // The UI will be replaced as well
    const updatedShoppingWindowMetadata = {
      ...signupConfig.metadata,
      [SignupSteps.QUIZ_SHOPPING_WINDOW]: {
        ...signupConfig.metadata[SignupSteps.QUIZ_SHOPPING_WINDOW],
        title: "Here’s your weekly shopping and delivery schedule",
      },
    };

    updatedConfig = {
      ...updatedConfig,
      metadata: updatedShoppingWindowMetadata,
    };
  }
  return updatedConfig;
};

const getUpdatedConfig = ({
  currentConfig,
  subscriptionPositHasExceptions,
  firstDeliveryHasException,
  isInSignupShopping,
}: {
  currentConfig: SignupConfig;
  subscriptionPositHasExceptions?: boolean;
  firstDeliveryHasException?: boolean;
  isInSignupShopping?: boolean;
}) => {
  // If the user has a window exception we do not want to show signup shopping
  if (subscriptionPositHasExceptions || firstDeliveryHasException) {
    return updateConfigWithExceptions({
      firstDeliveryHasException,
      subscriptionPositHasExceptions,
    });
  }

  // TODO: NC-1244 productionalize signup shopping
  const updatedConfig = { ...currentConfig };
  return {
    ...updatedConfig,
    flow: addRemoveShopForFlow(isInSignupShopping, updatedConfig.flow),
  };
};

const handleSubscriptionPositSucceeded = (
  state: SignupState,
  action: SubscriptionPositResponseAction
) => {
  const subscriptionPosit = _omit(action, ["type", "windows", "tags"]);
  const subscriptionPositHasExceptions = positHasExceptions(subscriptionPosit);

  return {
    ...state,
    subscriptionPosit,
    subscriptionPositHasExceptions,
    config: getUpdatedConfig({
      currentConfig: state.config,
      subscriptionPositHasExceptions,
      firstDeliveryHasException: subscriptionPosit.isReplacementDeliveryDate,
    }),
    ...(subscriptionPositHasExceptions && {
      values: {
        ...state.values,
        quizValues: {
          ...state.values.quizValues,
          // Default to the upcoming delivery if we have exceptions
          [SignupSteps.QUIZ_FIRST_DELIVERY]: subscriptionPosit.deliveryDate,
        },
      },
    }),
  };
};

export const handleLocationChangeAction = (
  state: SignupState,
  action: LocationChangeAction
) => {
  const { location: { query = {} } = {}, isFirstRendering } = action.payload;

  if (!isFirstRendering) return state;

  const couponParams = reduceQueryParams(query, COUPONCODE_QUERY_KEY, {
    ignoreCase: true,
    replaceKey: COUPONCODE_QUERY_KEY,
  });
  const utmParams = reduceQueryParams(query, "utm_", { prefix: true });
  const { gclid } = reduceQueryParams(query, "gclid");

  return {
    ...state,
    ...(COUPONCODE_QUERY_KEY in couponParams && {
      prospectiveCoupon: {
        code: couponParams[COUPONCODE_QUERY_KEY],
      },
    }),
    utmParams,
    gclid,
  };
};

/*
 * Set the zip code because CHANGE zip code does not cause a STEP_COMPLETE action!
 */
export const handleSetSelectedZipCodeSucceeded = (
  state: SignupState,
  action: SetSelectedZipCodeSucceededAction
) => {
  let progressStep,
    resetValues: Partial<ResetSignupValues> = {};
  const { config, values } = state;
  const hasZipChangedWithProgressPastDelivery =
    action.zip !== values.zip &&
    config.flow === signupConfig.flow &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    config.flow.indexOf(state.progressStep!) >
      config.flow.indexOf(SignupSteps.DELIVERY);

  // Has our zip changed AND we have progressed passed the delivery step?
  // Force the user to submit the delivery form again
  if (hasZipChangedWithProgressPastDelivery) {
    progressStep = SignupSteps.DELIVERY;
  }

  // If zip change results in new FC or new deliveryProvider, reset box values (setSelectedZipCodeFlow) & reset progress
  if (action.shouldResetSignupValues) {
    progressStep = SignupSteps.SUBSCRIPTION;
    resetValues = _pick(initialState.values, [
      "boxId",
      "addOnBoxIds",
      "quizValues",
      "address",
      "addressLine2",
      "city",
      "state",
      "deliveryNotes",
    ]);
  }

  return {
    ...state,
    ...(progressStep && { progressStep }),
    values: {
      ...state.values,
      ...resetValues,
      zip: action.zip,
      deliveryWindows: action.deliveryWindows, // TODO: deliveryWindows shouldn't be needed in signup slice of state
      selectedDeliveryWindowId: action.selectedDeliveryWindowId,
    },
  };
};

export function updateSignupFlowConfig(action) {
  return { type: ACTION_TYPES.UPDATE_SIGNUP_CONFIG, ...action };
}

// TO DO: update for Signup Shopping productize
export const handleUpdateSignupConfig = (state, action) => {
  return {
    ...state,
    config: getUpdatedConfig({
      currentConfig: state.config,
      subscriptionPositHasExceptions: state.subscriptionPositHasExceptions,
      firstDeliveryHasException:
        state.subscriptionPosit?.isReplacementDeliveryDate,
      isInSignupShopping: action.isInSignupShopping,
    }),
  };
};

// TODO: NC-1244 productionalize signup shopping
// This is taking from addOrder function in reducers/orders
// If this productionalized, we might want move this piece of code out of addOrders
//  and export it to share. I duplicated the code because I did not want introduce regressions in shopping
const createOrderLineItems = (state, order) => {
  const previousVersion = state.orderPosit;

  const lineItems = ((order.lineItems as OrderLineItem[]) || []).reduce(
    (acc: OrderLineItems, lineItem: OrderLineItem) => {
      const { variantId, productId } = lineItem;
      const isDefaultLineItem = order.adhocLines.find(
        ({ variantId: vId, metadata }) => {
          return variantId === vId && !!metadata.boxId;
        }
      );
      acc.lineItems.push(variantId);
      acc.mapOfLineItems[variantId] = lineItem;
      if (isDefaultLineItem) {
        acc.defaultLineItems.push(variantId);

        acc.removedDefaultProductIds = acc.removedDefaultProductIds.filter(
          (pId) => pId !== productId
        );
      }
      return acc;
    },
    {
      lineItems: [],
      mapOfLineItems: {},
      defaultLineItems: [],
      removedDefaultProductIds: previousVersion
        ? previousVersion.removedDefaultProductIds
        : [],
    }
  );

  return lineItems;
};

// Manually define action types where appropriate
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (
        action.payload.location.pathname === routes.logout.url &&
        config.get("env") !== "production"
      ) {
        // this is a lil tool for us developers: by appending `/logout` at any time during
        // the signup flow in dev, this will clear your signup state to start the flow over.
        return initialState;
      }

      return handleLocationChangeAction(state, action);
    }

    case ACCOUNT_ACTION_TYPES.FETCH_USER_SUCCEEDED:
      return seedSignupValues(state, action);

    case ACCOUNT_ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT_SUCCEEDED: {
      return handleSubscriptionPositSucceeded(state, action);
    }

    case ACTION_TYPES.SIGNUP_STARTED: {
      return {
        ...state,
        values: {
          /* Seed initial values from account, but don't overwrite new signup values! */
          ...action.values,
          ...state.values,
        },
        currentStep: action.currentStep,
        progressStep: action.currentStep,
        initialized: true,
      };
    }
    case ACTION_TYPES.SIGNUP_ENDED: {
      return {
        ...state,
        currentStep: state.currentStep,
        progressStep: initialState.progressStep,
        values: initialState.values,
      };
    }
    case ACTION_TYPES.SIGNUP_ABANDONED: {
      return {
        ...state,
        initialized: false,
        currentStep: initialState.currentStep,
      };
    }
    case ACTION_TYPES.SET_SIGNUP_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.values,
        },
      };
    case ACTION_TYPES.SET_SELECTED_ZIP_CODE_SUCCEEDED: {
      return handleSetSelectedZipCodeSucceeded(state, action);
    }
    case ACTION_TYPES.SET_SELECTED_ZIP_CODE_FAILED: {
      let progressStep;
      const { config } = state;
      // Has our zip changed AND we have progressed passed the delivery step?
      // Force the user to submit the delivery form again
      if (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        config.flow.indexOf(state.progressStep!) >
        config.flow.indexOf(SignupSteps.JOIN)
      ) {
        progressStep = SignupSteps.JOIN;
      }
      return {
        ...state,
        ...(progressStep && { currentStep: progressStep, progressStep }),
        values: {
          ...state.values,
          zip: action.zip,
          deliveryWindows: action.deliveryWindows,
          selectedDeliveryWindowId: action.selectedDeliveryWindowId,
        },
      };
    }
    case ACTION_TYPES.SET_SELECTED_ZIP_CODE: {
      return {
        ...state,
        values: {
          ...state.values,
          zip: action.zip,
        },
      };
    }
    case ACTION_TYPES.UPDATE_SIGNUP_CONFIG: {
      return handleUpdateSignupConfig(state, action);
    }
    case ACTION_TYPES.CURRENT_STEP_CHANGED: {
      return {
        ...state,
        currentStep: action.currentStep,
      };
    }
    case ACTION_TYPES.STEP_COMPLETE: {
      return handleStepComplete(state, action);
    }
    case ACCOUNT_ACTION_TYPES.FETCH_USER_SUMMARY_SUCCEEDED: {
      if (!action.user.signupZip) return state;

      return {
        ...state,
        values: {
          ...state.values,
          signupZip: action.user.signupZip,
          signupOutOfArea: action.user.signupOutOfArea,
        },
      };
    }
    case ACTION_TYPES.SAVE_OOA_USER_SUCCEEDED: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.values,
        },
      };
    }
    case ACTION_TYPES.CLEAR_ADD_ON_BOX_IDS: {
      return {
        ...state,
        values: {
          ...state.values,
          addOnBoxIds: [],
        },
      };
    }
    case ACTION_TYPES.UPDATE_VARIANT_QUANTITY: {
      const variantQuantities = state.variantQuantities.slice();
      const { variantId, quantity } = action.variantQuantity;
      const existingVariantQuantity = variantQuantities.find(
        (vq) => vq.variantId === variantId
      );
      if (existingVariantQuantity) {
        if (quantity === existingVariantQuantity.quantity) {
          return state;
        }
        if (quantity === 0) {
          variantQuantities.splice(
            variantQuantities.indexOf(existingVariantQuantity),
            1
          );
        } else {
          existingVariantQuantity.quantity = quantity;
        }
      } else {
        variantQuantities.push({ variantId, quantity });
      }

      return {
        ...state,
        variantQuantities,
      };
    }
    case ACTION_TYPES.POPULATE_VARIANTS: {
      if (state.variantQuantities.length > 0) return state;

      return {
        ...state,
        variantQuantities: action.variantQuantities,
      };
    }
    case ACTION_TYPES.CLEAR_VARIANT_QUANTITIES: {
      return {
        ...state,
        variantQuantities: [],
      };
    }
    // TODO: NC-1244 productionalize signup shopping
    case ORDER_ACTION_TYPES.FETCH_ORDER_POSIT_SUCCEEDED: {
      const {
        lineItems,
        mapOfLineItems,
        defaultLineItems,
        removedDefaultProductIds,
      } = createOrderLineItems(state, action.order);
      return {
        ...state,
        orderPosit: {
          ...action.order,
          lineItems,
          mapOfLineItems,
          defaultLineItems,
          removedDefaultProductIds,
          lineItemSaleSavings: "0.00", // don't show "total saving after order banner"
          totalAfterCredits: action.order.total,
        },
      };
    }
    case ACTION_TYPES.SET_SIGNUP_START_CARTS: {
      return {
        ...state,
        isPrefilledCartFilled: true,
      };
    }
    default:
      return state;
  }
}

export function isInitialized(state: SignupState) {
  return state.initialized;
}

export function getValues(state: SignupState) {
  return state.values;
}

export function getCurrentStep(state: SignupState) {
  return state.currentStep;
}

export function getProgressStep(state: SignupState) {
  return state.progressStep;
}

export function getConfig(state: SignupState) {
  return state.config;
}

export function getProspectiveCoupon(state: SignupState) {
  return state.prospectiveCoupon;
}

export function getSubscriptionPosit(state: SignupState) {
  return state.subscriptionPosit;
}

export function getSubscriptionPositHasExceptions(state: SignupState) {
  return state.subscriptionPositHasExceptions;
}

export function getVariantQuantities(state: SignupState) {
  return state.variantQuantities;
}

// TODO: NC-1244 productionalize signup shopping
export function getOrderPosit(state: SignupState) {
  return state.orderPosit;
}

export function getIsPrefilledCartFilled(state: SignupState) {
  return state.isPrefilledCartFilled;
}

export function getUTMParams(state: SignupState) {
  return state.utmParams;
}

export function getGCLID(state: SignupState) {
  return state.gclid;
}

export function getVariantQuantity(state: SignupState, variantId: string) {
  const variantQuantity = state.variantQuantities.find(
    (v) => v.variantId === variantId
  );
  if (variantQuantity) {
    return variantQuantity.quantity;
  }
  return 0;
}

export function getNumberOfItemsInSignupOrder(state: SignupState) {
  return state.variantQuantities.reduce((acc, vq) => acc + vq.quantity, 0);
}

export function signupStarted(
  currentStep: SignupSteps,
  values: Partial<SignupValues> = {}
) {
  return { type: ACTION_TYPES.SIGNUP_STARTED, currentStep, values };
}

export function signupEnded() {
  return { type: ACTION_TYPES.SIGNUP_ENDED };
}

export function signupAbandoned() {
  return { type: ACTION_TYPES.SIGNUP_ABANDONED };
}

export function quizStepViewed(
  quizStep: string,
  values?: SignupValuesQuizValues
) {
  return { type: ACTION_TYPES.QUIZ_STEP_VIEWED, quizStep, values };
}

export function currentStepChanged(currentStep: SignupSteps) {
  return { type: ACTION_TYPES.CURRENT_STEP_CHANGED, currentStep };
}

export function signupStepComplete(values: Partial<SignupValues> = {}) {
  return { type: ACTION_TYPES.STEP_COMPLETE, values };
}

export function selectBoxType(boxType: string) {
  return setSignupValues({ boxType });
}

export function selectFrequency(selectedFrequency: string) {
  return setSignupValues({ selectedFrequency });
}

export function setSignupValues(values: Partial<SignupValues>) {
  return { type: ACTION_TYPES.SET_SIGNUP_VALUES, values };
}

export function setSelectedZipCode(params: { zip: string }) {
  return { type: ACTION_TYPES.SET_SELECTED_ZIP_CODE, ...params };
}

export function setSelectedZipCodeCheckout(params: { zip: string }) {
  return {
    type: ACTION_TYPES.SET_SELECTED_ZIP_CODE_CHECKOUT,
    ...params,
  };
}

export function createAccount(params: CreateAccount) {
  return { type: ACTION_TYPES.CREATE_ACCOUNT, ...params };
}

export function setDeliveryDetails(params: SetDeliveryDetailsParams) {
  return { type: ACTION_TYPES.SET_DELIVERY_DETAILS, ...params };
}

export function setPaymentDetails(params: SetPaymentDetails) {
  return { type: ACTION_TYPES.SET_PAYMENT_DETAILS, ...params };
}

export function saveOOAUser(params: { email: string }) {
  return { type: ACTION_TYPES.SAVE_OOA_USER, ...params };
}

export function confirmationGoToShoppingClicked() {
  return { type: ACTION_TYPES.CONFIRMATION_TO_SHOPPING_CLICKED };
}

export function confirmationGoToMyAccountClicked() {
  return { type: ACTION_TYPES.CONFIRMATION_TO_ACCOUNT_CLICKED };
}

export function confirmationGroceryClicked(params: { grocery: GroceryType }) {
  return { type: ACTION_TYPES.CONFIRMATION_GROCERY_CLICKED, ...params };
}

export function updateVariantQuantity(variantQuantity: SignupVariantQuantity) {
  return { type: ACTION_TYPES.UPDATE_VARIANT_QUANTITY, variantQuantity };
}

export function updateVariantQuantities(
  variantQuantities: SignupVariantQuantity[]
) {
  return { type: ACTION_TYPES.UPDATE_VARIANT_QUANTITY, variantQuantities };
}

export function clearVariantQuantities() {
  return { type: ACTION_TYPES.CLEAR_VARIANT_QUANTITIES };
}

export function updateCart(params: SignupVariantQuantity) {
  return { type: ACTION_TYPES.UPDATE_CART, ...params };
}

export function signupWithMisfitsMarketClicked() {
  return { type: ACTION_TYPES.SIGNUP_WITH_MISFITS_MARKET_CLICKED };
}
