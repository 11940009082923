import { rem } from "polished";
import linkRoot, {
  underlined as linkUnderlined,
} from "app/styles/muiTheme/overrides/muiLink";
import buttonRoot, {
  contained as buttonContained,
  outlined as buttonOutlined,
  sizeLarge as buttonSizeLarge,
  sizeSmall as buttonSizeSmall,
} from "app/styles/muiTheme/overrides/muiButton";
import paperRoot, {
  rounded as paperRounded,
  outlined as paperOutlined,
} from "app/styles/muiTheme/overrides/muiPaper";
import { OverridesFunc } from "app/types/styles";

const muiOverrides: OverridesFunc = (theme) => ({
  MuiSvgIcon: {
    fontSizeSmall: {
      fontSize: `${rem(16)}`,
    },
  },
  MuiSelect: {
    outlined: {
      padding: `${rem(26)} ${rem(12)} ${rem(10)} ${rem(12)}`,
    },
    root: {
      fontSize: rem(16),
      lineHeight: rem(16),
    },
  },
  MuiListItem: {
    button: {
      "&:hover": {
        backgroundColor: theme.colors.secondaryPink,
        borderColor: "transparent",
        color: theme.colors.white,
      },
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: rem(16),
      lineHeight: rem(16),
    },
    outlined: {
      "&$shrink": {
        transform: "translate(12px, 10px) scale(0.75)",
      },
      "&.MuiInputLabel-marginDense": {
        transform: "translate(12px, 21px) scale(1)",
        "&$shrink": {
          transform: "translate(12px, 7px) scale(0.75)",
        },
      },
    },
    asterisk: {
      "&$error": {
        color: theme.colors.cherry,
      },
    },
  },
  MuiCheckbox: {
    root: {
      "&&$disabled": {
        color: theme.colors.barley,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&$checked": {
        "&.Mui-focusVisible": {
          backgroundColor: `${theme.colorUtils.alpha(theme.colors.taro, 0.2)}`, // default styling
        },
      },
      "&.MuiCheckbox-colorSecondary": {
        "&$checked": {
          "&.Mui-focusVisible": {
            backgroundColor: `${theme.colorUtils.alpha(
              theme.colors.avocado,
              0.2
            )}`,
          },
          "&$checked:hover": {
            backgroundColor: "transparent",
          },
        },
      },
      "&.MuiCheckbox-colorPrimary": {
        "&$checked": {
          "&.Mui-focusVisible": {
            backgroundColor: `${theme.colorUtils.alpha(
              theme.colors.beet,
              0.2
            )}`,
          },
        },
        "&$checked:hover": {
          backgroundColor: "transparent",
        },
      },
      "&:not($checked)": {
        "&.Mui-focusVisible": {
          backgroundColor: `${theme.colorUtils.alpha(theme.colors.taro, 0.2)}`,
        },
      },
    },
    checked: {},
    disabled: {},
  },
  MuiTextField: {
    root: {
      "& .MuiInputBase-root": {
        lineHeight: rem(24),
        fontSize: rem(16),
      },
      "& label.Mui-focused": {
        color: theme.colors.beet,
        "& .MuiFormLabel-asterisk": {
          color: theme.colors.beet,
        },
        "&.Mui-error": {
          color: theme.colors.cherry,
        },
      },
      "& label.Mui-disabled": {
        color: "default",
      },
      "& .MuiOutlinedInput-root": {
        "& $input": {
          padding: `${rem(26)} ${rem(12)} ${rem(10)} ${rem(12)}`,
        },
        "&.Mui-focused": {
          fieldset: {
            borderColor: theme.colors.beet,
            boxShadow: `0 0 0 3px ${theme.colorUtils.alpha(
              theme.colors.beet,
              0.2
            )}`,
          },
        },
        "&.MuiInputBase-multiline": {
          padding: `${rem(26)} ${rem(12)} ${rem(10)} ${rem(12)}`, // text area
        },
        "& :not(.MuiOutlinedInput-multiline) .MuiOutlinedInput-inputMarginDense": {
          paddingTop: rem("23px"),
          paddingBottom: rem("6px"),
        },
      },
      "& .MuiFormHelperText-root": {
        marginLeft: "0",
        "&.Mui-error": {
          fontWeight: theme.fonts.weight.bold,
          fontSize: rem(13),
        },
      },
      // Autocomplete select input
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: `0 0 0 ${rem(6)}`,
      },
      // Autocomplete select input
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        paddingTop: `${rem(26)}`,
      },
    },
  },
  MuiButton: {
    root: buttonRoot,
    sizeLarge: buttonSizeLarge,
    // size "medium" is the default - so simply don't pass in a size prop if medium is needed
    sizeSmall: buttonSizeSmall,
    contained: buttonContained,
    containedPrimary: buttonContained,
    containedSecondary: buttonContained,
    outlined: buttonOutlined,
    outlinedPrimary: buttonOutlined,
    outlinedSecondary: buttonOutlined,
    text: {
      color: theme.colors.plum,
    },
  },
  MuiRadio: {
    root: {
      "&.Mui-focusVisible": {
        backgroundColor: theme.colorUtils.alpha(theme.colors.taro, 0.2),
      },
    },
    colorSecondary: {
      "&$checked": {
        color: theme.colors.beet,
        "&:hover, &.Mui-focusVisible": {
          backgroundColor: theme.colorUtils.alpha(theme.colors.beet, 0.2),
        },
      },
    },
  },
  MuiAccordion: {
    root: {
      boxShadow: "none",
      background: "transparent",
    },
  },
  MuiAccordionSummary: {
    root: {
      alignItems: "flex-start",

      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    content: {
      "&.Mui-expanded": {
        margin: `${rem(12)} 0`,
      },

      "& .MuiTypography-root": {
        color: theme.colors.beet,
        fontWeight: "bold",
        textAlign: "left",
        maxWidth: "80%",
        marginTop: rem(8),

        [theme.breakpoints.down("lg")]: {
          maxWidth: "90%",
        },

        [theme.breakpoints.down("md")]: {
          maxWidth: "95%",
        },
      },
    },
    expandIcon: {
      "& .MuiIconButton-label": {
        padding: rem(2),
        borderRadius: "50%",
        backgroundColor: theme.colors.rose,
      },

      "& .MuiSvgIcon-root": {
        width: rem(48),
        height: rem(48),

        [theme.breakpoints.down("lg")]: {
          width: rem(40),
          height: rem(40),
        },
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: `${rem(16)} 22% ${rem(16)} ${rem(16)}`,

      [theme.breakpoints.down("lg")]: {
        padding: `${rem(16)} ${rem(80)} ${rem(16)} ${rem(16)}`,
      },

      [theme.breakpoints.down("md")]: {
        padding: rem(16),
      },

      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
  },
  MuiLink: {
    root: linkRoot,
    underlineNone: linkUnderlined,
    underlineHover: linkUnderlined,
    underlineAlways: linkUnderlined,
  },
  MuiPaper: {
    root: paperRoot,
    outlined: paperOutlined,
    rounded: paperRounded,
  },
  MuiBackdrop: {
    root: {
      backgroundColor: theme.colorUtils.alpha(theme.colors.plum, 0.5),
    },
  },
});

export default muiOverrides;
