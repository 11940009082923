import { LOCATION_CHANGE } from "connected-react-router";

import ACTION_TYPES from "app/actionTypes/routing";
import { ExtendedRouterState } from "app/types/state/extendedRouter";

const initialState: ExtendedRouterState = {
  previousLocation: null,
  coreRoute: null,
  previousCoreRoute: null,
  initialLocation: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.CORE_ROUTE_CHANGED: {
      return {
        ...state,
        coreRoute: action.state.coreRoute,
        previousCoreRoute: action.state.prevCoreRoute,
      };
    }

    case LOCATION_CHANGE: {
      const { location: newLocation, isFirstRendering } = action.payload;
      const oldLocation = isFirstRendering
        ? null
        : action.payload.currentLocation;

      return {
        ...state,
        previousLocation: oldLocation,
        ...(isFirstRendering && {
          initialLocation: newLocation,
        }),
      };
    }

    default:
      return state;
  }
}

export function getPreviousLocation(state: ExtendedRouterState) {
  return state.previousLocation;
}

export function getCoreRoute(state: ExtendedRouterState) {
  return state.coreRoute;
}

export function getInitialLocation(state: ExtendedRouterState) {
  return state.initialLocation;
}
