// @ts-nocheck
import { css } from "styled-components";

import breakpoints from "app/styles/breakpoints";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (shoppingMobileTreatment?: boolean) => (...args: any[]) => css`
  ${shoppingMobileTreatment
    ? // @ts-ignore
      breakpoints.xl(...args)
    : // @ts-ignore
      breakpoints.lg(...args)}
`;
