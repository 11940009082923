import React from "react";
import { Helmet } from "react-helmet";

interface ModifyHeadProps {
  key?: string;
  defer?: boolean;
  title?: string;
  keywords?: string;
  description?: string;
}

/*
  This component modifies the head, given provided values
*/
const ModifyHead: React.FC<ModifyHeadProps> = ({
  key = "helmet",
  defer = false,
  title,
  keywords,
  description,
}) => {
  if (!title && !keywords && !description) return null;
  return (
    <Helmet key={key} defer={defer}>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
};

export default ModifyHead;
