import { call } from "redux-saga/effects";

import UI_ACTION_TYPES from "app/actionTypes/ui";
import { trackEvent } from "app/api/segment";
import { waitForInitialization } from "app/sagas/waitForInitialization";
import {
  Dialog,
  DialogType,
  PurchaseFailedDialog,
  RemoveOutOfStockDialog,
} from "app/types/ui/Dialog";

type TrackedActionType =
  | typeof UI_ACTION_TYPES.CLEAR_DIALOG
  | typeof UI_ACTION_TYPES.SET_DIALOG;

function* trackDialogActions({
  dialog,
  type,
}: {
  dialog: Dialog;
  type: TrackedActionType;
}) {
  yield call(waitForInitialization);

  switch (type) {
    case UI_ACTION_TYPES.CLEAR_DIALOG:
      yield call(trackClearDialogActions, dialog);
      break;

    case UI_ACTION_TYPES.SET_DIALOG:
      yield call(trackSetDialogAcitons, dialog);
      break;

    default:
      break;
  }
}

function* trackClearDialogActions(dialog: Dialog) {
  switch (dialog.type) {
    case DialogType.PURCHASE_FAILED: {
      const purchaseFailedDialog = dialog as PurchaseFailedDialog;
      yield call(trackEvent, "Closed Purchase Failed Dialog", {
        variantId: purchaseFailedDialog.variantId,
      });
      break;
    }

    case DialogType.REMOVE_OUT_OF_STOCK: {
      const removeOutOfStockDialog = dialog as RemoveOutOfStockDialog;
      yield call(trackEvent, "Closed Remove Out of Stock Dialog", {
        variantId: removeOutOfStockDialog.variantId,
      });
      break;
    }

    default:
      break;
  }
}

function* trackSetDialogAcitons(dialog: Dialog) {
  switch (dialog.type) {
    case DialogType.REFERRAL_STATUS_INFO: {
      yield call(trackEvent, "Viewed Referral Status Info Dialog");
      break;
    }

    case DialogType.PURCHASE_FAILED: {
      const purchaseFailedDialog = dialog as PurchaseFailedDialog;
      yield call(trackEvent, "Viewed Purchase Failed Dialog", {
        variantId: purchaseFailedDialog.variantId,
      });
      break;
    }

    case DialogType.REMOVE_OUT_OF_STOCK: {
      const removeOutOfStockDialog = dialog as RemoveOutOfStockDialog;
      yield call(trackEvent, "Viewed Remove Out of Stock Dialog", {
        variantId: removeOutOfStockDialog.variantId,
      });
      break;
    }

    default:
      break;
  }
}

export default trackDialogActions;
