import React from "react";

export const ACCOUNT_ROUTES_SNACKBAR_CONTAINER_ID =
  "snackbar-container-account";

export enum SnackbarContainerId {
  ROOT = "snackbar-container-root",
}

interface SnackbarContainerProps {
  elementId: string;
}

const SnackbarContainer: React.FC<SnackbarContainerProps> = ({ elementId }) => (
  <div id={elementId} />
);

export default SnackbarContainer;
