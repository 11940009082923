import React from "react";
import { Switch } from "react-router-dom";

import RestrictedRoute from "app/router/RestrictedRoute";
import routes from "app/router/routes.json";

import loadable from "app/ui/global/loadable";
import { isLoggedOut } from "app/selectors";
import Header from "app/ui/universal/header";
import theme from "app/styles/theme";

// Lazy-load routes
const Login = loadable(() => import("../../ui/login"));
const ForgotPassword = loadable(() => import("../../ui/forgotPassword"));
const Footer = loadable(() => import("../../ui/universal/footer"), true);

const LoggedOutRoutes = () => {
  return (
    <>
      <Header />
      <Switch>
        <RestrictedRoute
          exact
          path={routes.login.url}
          selector={isLoggedOut}
          spinnerBeforeInitialized
          component={Login}
          bgColor={theme.colors.baba}
          overrideBoysenberryRedirect
        />
        <RestrictedRoute
          exact
          path={routes.forgotPassword.url}
          selector={isLoggedOut}
          component={ForgotPassword}
          bgColor={theme.colors.baba}
          overrideBoysenberryRedirect
        />
      </Switch>
      <Footer />
    </>
  );
};

export default LoggedOutRoutes;
