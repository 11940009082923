import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import breakpoints from "app/styles/breakpoints";
import Tooltip from "app/ui/designSystem/molecules/Tooltip";

type PickedMUIBoxProps = Pick<
  React.HTMLAttributes<HTMLLIElement>,
  "children" | "className" | "onClick" | "id"
>;

export interface ShopToolbarIconProps extends PickedMUIBoxProps {
  active: boolean;
  tooltipText: string;
}

const ShopToolbarIcon: React.FC<ShopToolbarIconProps> = ({
  active,
  children,
  className,
  onClick,
  tooltipText,
  ...props
}) => {
  const onKeyPress: React.KeyboardEventHandler<HTMLLIElement> = (e) => {
    if (e.key === "Enter" && onClick) {
      onClick(
        (new MouseEvent("click") as unknown) as React.MouseEvent<
          HTMLLIElement,
          MouseEvent
        >
      );
    }
  };
  return (
    <Tooltip labelText={tooltipText} disableFocusListener>
      <StyledListItem
        tabIndex={0}
        active={active}
        onKeyPress={onKeyPress}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onClick}
        className={className}
        role="button"
        {...props}
      >
        {children}
      </StyledListItem>
    </Tooltip>
  );
};

// TODO: Replace the breakpoints.lg styles for margins with a conditional using profileTypes
const StyledListItem = styled.li<{
  active: boolean;
}>`
  width: ${rem(32)};
  height: ${rem(32)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(8)};
  margin-right: ${rem(8)};
  color: ${({ theme, active }) =>
    active ? theme.colors.beet : theme.colors.fontDark};
  fill: ${({ theme, active }) =>
    active ? theme.colors.beet : theme.colors.fontDark};

  &:focus-visible {
    outline: none;
    ${({ theme }) => `
      background-color: ${theme.colors.marshmallow};
      border: ${theme.borders.focus};
      box-shadow: ${theme.boxShadows.focus};
    `}
  }

  ${breakpoints.md`
      &:hover {
        cursor: pointer;
        ${({ theme }) => `
          color: ${theme.colors.beet};
          fill: ${theme.colors.beet};
        `}
      }
  `}

  ${breakpoints.lg`
    margin-left: ${rem(12)};
    margin-right: ${rem(12)};
  `}
`;

export default ShopToolbarIcon;
