import moment, { Moment } from "moment-timezone";

/*
 * Given a moment instance, set the time in format HH:mm:ss
 * Mutates momentInstance!
 */
const setMomentTime = (momentInstance: Moment, time: string) => {
  const [hour, minute, second] = time.split(":");

  return momentInstance
    .hour(Number(hour))
    .minute(Number(minute))
    .second(Number(second));
};

/*
 * Given a moment instance, return the previous date from day and time string
 * https://github.com/imperfectproduce/deliveries/blob/8529af46295dfa8cfc29fc84fba15d005f596fea/src/lib/deliveryDates.ts#L41-L49
 * day is 0-6
 * time is formatted as HH:MM:SS - ex 15:30:10 for 3:30 and 10 seconds
 */
export const getPreviousDate = (now: Moment, day: number, time: string) => {
  const currentWeeksMoment = setMomentTime(now.clone().day(day), time);

  // is the current week's moment in the past? if so, good to go, return it
  if (now.isAfter(currentWeeksMoment)) return currentWeeksMoment;

  // otherwise move it backward a week
  return currentWeeksMoment.subtract(1, "weeks");
};

/*
 * Generate a formatted time given a time in format HH:mm:ss
 */
export const formattedTime = (time: string, format = "ha") => {
  return moment(time, "HH:mm:ss").format(format);
};

export const isValidTimezone = (timezone: string): boolean => {
  return !!moment.tz.zone(timezone);
};

/*
 * Given a moment instance convert the moment to another timezone and return a moment
 * if additional add duration params are supplied execute the correct addition
 * then return the converted moment.
 */
export const convertMomentTimezone = ({
  momentValue,
  timezoneToConvertTo,
  addDurationValue,
  addDurationUnit,
}: {
  momentValue: Moment;
  timezoneToConvertTo: string;
  addDurationValue?: number;
  addDurationUnit?: moment.unitOfTime.DurationConstructor;
}) => {
  if (!isValidTimezone(timezoneToConvertTo)) return undefined;

  const momentValueClone = momentValue.clone().tz(timezoneToConvertTo);

  if (addDurationValue && addDurationUnit)
    return momentValueClone.add(addDurationValue, addDurationUnit);

  return momentValueClone;
};
