import { Backdrop, Dialog } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { useMediumScreen } from "app/styles/breakpoints";
import { PurchaseFailedDialog as PurchaseFailedDialogType } from "app/types/ui/Dialog";
import ConfirmationDialog from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";

export interface PurchaseFailedDialogProps {
  dialog: PurchaseFailedDialogType;
}

const PurchaseFailedDialog: React.FC<PurchaseFailedDialogProps> = ({
  dialog,
}) => {
  const { open, handleClose, handleExited } = useHandleTransitions(dialog);

  const isMediumScreenOrBelow = useMediumScreen();

  let subcopyText: string | JSX.Element =
    "Because we source groceries that might have otherwise gone to waste, our supply isn't always predictable.";

  if (!isMediumScreenOrBelow) {
    subcopyText = (
      <>
        <OfferingName>{dialog.offeringName}</OfferingName> just went out of
        stock. Because we source groceries that might have otherwise gone to
        waste, our supply isn&apos;t always predictable.
      </>
    );
  }

  return (
    <Dialog
      BackdropComponent={StyledBackdrop}
      maxWidth={false}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <ConfirmationDialog
        headerText="This item can't be added"
        onClearIconButtonClick={handleClose}
        onPrimaryButtonClick={handleClose}
        overlineText="Out of stock"
        primaryButtonText="Okay"
        subcopyText={subcopyText}
      />
    </Dialog>
  );
};

export default PurchaseFailedDialog;

const StyledBackdrop = styled(Backdrop)`
  backdrop-filter: unset;
`;

const OfferingName = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
`;
