import { COUPONCODE_QUERY_KEY } from "app/constants";
import { RouterLocation } from "app/types/state/extendedRouter";

type RouterQuery = RouterLocation<unknown>["query"];

// Returns a case-insensitive match of the query param for coupon codes
// (ie: promoCode, promocode, PROMOCODE will all match)
const getCouponFromQuery = (
  query: RouterQuery,
  couponQueryKey: string = COUPONCODE_QUERY_KEY
): string | undefined => {
  const caseInsensitiveQuery = Object.keys(query).reduce<RouterQuery>(
    (acc, key) => {
      acc[key.toLowerCase()] = query[key];
      return acc;
    },
    {}
  );
  return caseInsensitiveQuery[couponQueryKey.toLowerCase()];
};

export default getCouponFromQuery;
