import * as FriendbuyLegacy from "app/types/api/friendbuyLegacy";
import BaseAction from "app/types/state/BaseAction";
import { Coupon, DeferredCoupon } from "app/types/account/Coupon";
import { VisitorStatusPayload } from "app/types/api/friendbuy";

const FRIENDBUY_TAG = "friendbuy";
const FRIEND_TAG = "friend-flow";
const ADVOCATE_TAG = "advocate-flow";

// Default tags applied to common friendbuy logs
export const DEFAULT_TAGS = [FRIENDBUY_TAG, FRIEND_TAG, ADVOCATE_TAG];
// Applied to logs related to the friend flow
export const FRIEND_TAGS = [FRIENDBUY_TAG, FRIEND_TAG];
// Applied to logs related to the advocate flow
export const ADVOCATE_TAGS = [FRIENDBUY_TAG, ADVOCATE_TAG];

export enum APIType {
  NONE = "none",
  NEXTGEN = "next-gen",
  FIRSTGEN = "first-gen",
}

// Inspired by auth0 log event codes
// https://auth0.com/docs/deploy-monitor/logs/log-event-type-codes
// cc = client-side core
// fs = friend signup
// as = advocate share
export enum LogEventCode {
  // alf = api load failed
  API_LOAD_FAILED = "fb-cc-alf",
  // atc = api type changed
  ACTIVE_API_TYPE_CHANGED = "fb-as-atc",

  // rcs = referral coupon load (succeeded/failed)
  REFERRAL_COUPON_LOAD_SUCCEEDED = "fb-fs-rcls",
  REFERRAL_COUPON_LOAD_FAILED = "fb-fs-rclf",

  // rc = referral coupon (applied/failed)
  REFERRAL_COUPON_APPLIED = "fb-fs-rca",
  REFERRAL_COUPON_FAILED = "fb-fs-rcf",

  // wat = widget action triggered
  WIDGET_ACTION_TRIGGERED = "fb-as-wat",
  // vs = visitor status (received/failed/validation/info)
  VISITOR_STATUS_RECEIVED = "fb-fs-vsr",
  VISITOR_STATUS_FAILED = "fb-fs-vsf",
  VISITOR_STATUS_VALIDATION = "fb-fs-vsv",
  VISITOR_STATUS_INFO = "fb-fs-vsi",

  // fcr = friendbuy coupon received
  COUPON_RECEIVED = "fb-fs-fcr",

  // vr = valid referral (captured/apply succeeded/apply failed)
  VALID_REFERRAL_CAPTURED = "fb-fs-vrc",
  VALID_REFERRAL_APPLY_SUCCEEDED = "fb-fs-vras",
  VALID_REFERRAL_APPLY_FAILED = "fb-fs-vraf",
}

export interface APITypeProps {
  apiType: APIType;
}

export type LegacyCampaignId = number | null;
export type NextGenCampaignId = string | null;
export type CampaignId = LegacyCampaignId | NextGenCampaignId;

export interface UserReferralAttribution {
  referringUserId: string | null;
  referringCampaignId: string | null;
  referringUserReferralCode: string | null;
  referralDate: string | null;
  deferredCoupon: DeferredCoupon | null;
}

export interface BaseReferralAttributes {
  campaignId?: NextGenCampaignId;
  referralCode: string | null;
  // advocate
  referringUserId: string | null;
}

// captured from visitor status response
// store locally and pass to backend when creating subscription
export interface ReferralAttributes extends BaseReferralAttributes {
  isSelfReferral: boolean;
}

// NC-1499 Remove legacy friendbuy
export interface FriendbuyUser {
  userId: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

// NC-1499 Remove legacy friendbuy
export interface LegacyReferralAttributes {
  referralCode: string | null;
  campaign: FriendbuyLegacy.Campaign | null;
  selfReferred: boolean;
  advocate: FriendbuyUser | null;
  fbClickType: FriendbuyLegacy.FriendbuyClickType;
  friendbuyResponse: FriendbuyLegacy.VisitorStatus; // raw
}

export interface ReferralData {
  apiType: APIType;
  loaded: boolean;
  referralCouponCode: string | null;
  referral: ReferralAttributes | LegacyReferralAttributes | null;
}

export interface FriendbuyReadyAction extends BaseAction {
  loaded: boolean;
  apiType: APIType;
}

export interface FriendbuyVisitorStatusAction extends BaseAction {
  apiType: APIType;
  visitorStatus: VisitorStatusPayload | FriendbuyLegacy.VisitorStatus;
}

export interface FriendbuyInitializedAction extends BaseAction {
  apiType: APIType;
}

export interface FetchReferralCouponAction extends BaseAction {
  campaignId: CampaignId;
  apiType: APIType;
}

export interface SetLegacyReferralAction extends FetchReferralCouponAction {
  referral: LegacyReferralAttributes | null;
}

export interface CaptureReferralAction extends FetchReferralCouponAction {
  referral: ReferralAttributes | null;
}

export interface ApplyAttributionAction extends FetchReferralCouponAction {
  referral: ReferralAttributes;
  userId: string;
  couponCode: string;
}

export interface ApplyAttributionSuccessAction extends ApplyAttributionAction {
  userReferralAttribution: UserReferralAttribution;
}

export interface ApplyAttributionFailedAction extends ApplyAttributionAction {
  error?: unknown;
}

export interface FetchReferralCouponActionSucceeded
  extends FetchReferralCouponAction {
  apiType: APIType;
  coupon: Coupon | null;
  code: string | null;
}

export interface FetchReferralCouponActionFailed
  extends FetchReferralCouponAction {
  campaignId: CampaignId;
  apiType: APIType;
  message: string;
  error?: unknown;
}

// NC-1499 Remove legacy friendbuy
// apiType and LegacyReferralAttributes will be removed and state can be simplified
export default interface FriendbuyState {
  activeAPIType: APIType;
  initialized: boolean;
  referralData: Record<APIType, ReferralData | null>;
}
