// @ts-nocheck
import ACTION_TYPES from "app/actionTypes/boxes";

export const initialState = {
  loading: false,
  boxIds: [], // list of boxIds
  primaryBoxIds: [], // list of primary boxIds
  secondaryBoxIds: [], // list of secondary boxIds
  mapOfBoxes: {}, // map of boxId => box data
};

const handleFetchBoxes = (state, action) => {
  const { boxes = [] } = action;
  const { mapOfBoxes, primaryBoxIds, secondaryBoxIds, boxIds } = boxes.reduce(
    (acc, box) => {
      const { id, grouping } = box;

      acc.mapOfBoxes[id] = box;
      if (grouping === "primary") {
        acc.primaryBoxIds.push(id);
      } else {
        acc.secondaryBoxIds.push(id);
      }
      acc.boxIds.push(id);

      return acc;
    },
    { mapOfBoxes: {}, primaryBoxIds: [], secondaryBoxIds: [], boxIds: [] }
  );

  return {
    ...state,
    mapOfBoxes,
    primaryBoxIds,
    secondaryBoxIds,
    boxIds,
    loading: false,
  };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BOXES:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.FETCH_BOXES_SUCCEEDED:
      return handleFetchBoxes(state, action);
    case ACTION_TYPES.FETCH_BOXES_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export function isLoading(state) {
  return state.loading;
}

export function getPrimaryBoxIds(state) {
  return state.primaryBoxIds;
}

export function getSecondaryBoxIds(state) {
  return state.secondaryBoxIds;
}

export function getMapOfBoxes(state) {
  return state.mapOfBoxes;
}

export function fetchBoxes() {
  return { type: ACTION_TYPES.FETCH_BOXES };
}
