// @ts-nocheck
import { initSplitSdk, getTreatments } from "@splitsoftware/splitio-redux";
import { take, fork, call, select, all, put } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/split";
import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import { config } from "app/config";
import {
  ANON_TRAFFIC_TYPE,
  USER_TRAFFIC_TYPE,
  GLOBAL_SPLITS,
} from "app/constants/treatments";
import { sendLog } from "app/monitoring";
import { LogLevel } from "app/types/monitoring";
import { waitForAnalyticsInitialization } from "app/sagas/waitForAnalyticsInitialization";
import { waitForSplitInitialization } from "app/sagas/waitForSplit";
import {
  isLoggedIn,
  getUserId,
  getAnonymousId,
  getTreatmentSetup,
  getSplitKey,
  getSplitTrafficType,
} from "app/selectors";
import ExperimentSetupEntry from "app/types/split/ExperimentSetupEntry";
import {
  getTreatments as getTreatmentsAction,
  getGlobalTreatments as getGlobalTreatmentsAction,
  initializeSplitSucceeded as initializeSplitSucceededAction,
} from "app/reducers/extendedSplit";

export default function* rootSplit() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take((a) =>
      [
        ACTION_TYPES.GET_GLOBAL_TREATMENTS,
        ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED,
        ACTION_TYPES.INITIALIZE_SPLIT,
        ACTION_TYPES.SPLIT_TIMEDOUT,
        ACTION_TYPES.INITIALIZE_SPLIT_SUCCEEDED,
        ACTION_TYPES.GET_TREATMENTS,
      ].includes(a.type)
    );

    switch (action.type) {
      case ACTION_TYPES.INITIALIZE_SPLIT: {
        yield fork(initializeSplitFlow, action);
        break;
      }
      case ACTION_TYPES.SPLIT_TIMEDOUT: {
        yield fork(sendLog, {
          level: LogLevel.WARN,
          tags: ["split"],
          message: `SPLIT_TIMEDOUT - readyTimeout: ${config.get(
            "split.timeout"
          )}`,
        });
        break;
      }
      // Re-fetch user global treatments
      // The NDD is fetched when trafficType changes to user and also whenever
      // user status changes (eg can shop)
      case ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED: {
        yield fork(triageGlobalTreatments, { trafficType: USER_TRAFFIC_TYPE });
        break;
      }
      // Re-fetch anon global treatments whenever split is initialized
      // Once at startup and if trafficType changes to anon
      case ACTION_TYPES.INITIALIZE_SPLIT_SUCCEEDED: {
        yield fork(triageGlobalTreatments, { trafficType: ANON_TRAFFIC_TYPE });
        break;
      }
      case ACTION_TYPES.GET_GLOBAL_TREATMENTS: {
        yield fork(getGlobalTreatments, action);
        break;
      }
      case ACTION_TYPES.GET_TREATMENTS: {
        yield fork(handleTreatments, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* initializeSplitFlow() {
  yield call(waitForAnalyticsInitialization);

  let key, trafficType;
  const loggedIn = yield select(isLoggedIn);
  const currentTrafficType = yield select(getSplitTrafficType);
  const currentKey = yield select(getSplitKey);

  if (loggedIn) {
    key = yield select(getUserId);
    trafficType = USER_TRAFFIC_TYPE;
  } else {
    key = yield select(getAnonymousId);
    trafficType = ANON_TRAFFIC_TYPE;
  }

  if (key !== currentKey || trafficType !== currentTrafficType) {
    // https://help.split.io/hc/en-us/articles/360038851551-Redux-SDK#advanced-instantiate-multiple-sdk-clients
    // From Split:
    // We recommend keeping only one instance of the factory at all times (singleton pattern) and reusing it throughout your application.
    // TODO: NC-928 to implement as a singleton
    yield call(initializeSdk, key, trafficType);

    yield put(
      initializeSplitSucceededAction({
        key,
        trafficType,
      })
    );
  }
}

function* initializeSdk(key: string, trafficType: string) {
  const sdkBrowserConfig = {
    core: {
      authorizationKey: config.get("split.key"),
      key,
      trafficType,
      streamingEnabled: config.get("split.streaming"),
    },
    debug: config.get("split.debug"),
    startup: {
      readyTimeout: config.get("split.timeout"),
    },
  };
  yield put(initSplitSdk({ config: sdkBrowserConfig }));
}

function* triageGlobalTreatments({ trafficType }) {
  yield call(waitForSplitInitialization);
  const activeTrafficType = yield select(getSplitTrafficType);
  if (trafficType === activeTrafficType) {
    yield put(getGlobalTreatmentsAction({ trafficType }));
  }
}

function* getGlobalTreatments() {
  yield call(waitForSplitInitialization);
  const trafficType = yield select(getSplitTrafficType);
  const splitNames = GLOBAL_SPLITS[trafficType];
  if (splitNames?.length) {
    yield put(
      getTreatmentsAction({
        splitNames,
      })
    );
  }
  yield put({
    type: ACTION_TYPES.GET_GLOBAL_TREATMENTS_SUCCEEDED,
    splitNames,
    trafficType,
  });
}

export function* handleTreatments({ splitNames, props }) {
  if (!splitNames?.length) {
    return;
  }
  yield call(waitForSplitInitialization);
  let treatmentNames = [];

  try {
    const treatmentSetup = yield select(getTreatmentSetup, { splitNames });

    treatmentNames = treatmentSetup && Object.keys(treatmentSetup);
    if (treatmentNames?.length > 0) {
      yield all(
        treatmentNames.map((name) => {
          const experimentSetup = treatmentSetup[name];
          return call(handleTreatment, name, experimentSetup, props);
        })
      );

      yield put({
        type: ACTION_TYPES.GET_TREATMENTS_SUCCEEDED,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPES.GET_TREATMENTS_FAILED,
      treatments: treatmentNames,
    });
  }
}

function* handleTreatment(
  treatmentName: string,
  experimentSetup: ExperimentSetupEntry,
  props: unknown
) {
  const { prerequisiteSelector, selector } = experimentSetup;
  const meetsPrerequisite = yield call(
    verifyPrerequisite,
    prerequisiteSelector,
    props
  );
  if (meetsPrerequisite) {
    const key = yield select(getSplitKey);
    const attributes = yield select(selector, props);
    const data = {
      splitNames: [treatmentName],
      attributes,
      // Pass the active key to bind this split to the correct key
      // Eventually we'll want to implement the split sdk as a singleton
      // https://help.split.io/hc/en-us/articles/360038851551-Redux-SDK#advanced-instantiate-multiple-sdk-clients
      key,
      // From https://help.split.io/hc/en-us/articles/360038851551-Redux-SDK#advanced-subscribe-to-events-and-changes
      // When evalOnUpdate is explicitly set to true, the given treatment will be re-evaluated in the event of an
      // SDK_UPDATE being triggered by the underlying SDK. You can use it to re-render your components whenever
      // there is a change due to a rollout update or a feature being killed.
      // Setting this here so that the splitio slice of state is automatically synced w/ new treatments
      // The extendedSplit reducer is also looking for SPLIT_UPDATE_WITH_EVALUATIONS to update treatments as well
      evalOnUpdate: true,
    };
    yield put(getTreatments(data));
  }
  return null;
}

function* verifyPrerequisite(prerequisiteSelector, props: unknown) {
  if (prerequisiteSelector) {
    return yield select(prerequisiteSelector, props);
  }

  return true;
}
