import ACTION_TYPES from "app/actionTypes/aisles";
import AislesState from "app/types/state/aisles";
import Aisle from "app/types/state/aisles/Aisle";

const initialState: AislesState = {
  initialized: false,
  initializeError: null,
  config: [],
  allAisles: {},
};

// Manually define action types where appropriate
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_AISLES_SUCCEEDED: {
      const allAisles = action.aisles.reduce(
        (acc: Record<string, Aisle>, aisle: Aisle) => {
          return {
            ...acc,
            [aisle.aisleId]: aisle,
          };
        },
        {}
      );
      return {
        ...state,
        initialized: true,
        config: action.aisles,
        allAisles,
      };
    }

    case ACTION_TYPES.FETCH_AISLES_FAILED: {
      return {
        ...state,
        initializeError: action.error,
      };
    }

    case ACTION_TYPES.ADD_RECENTLY_ADDED_SHELF_TO_FEATURED: {
      const featuredAisleId = "dd81968a-2dca-4b38-a23c-6e775b99c9ca";
      const allAislesCopy = JSON.parse(JSON.stringify(state.allAisles));
      allAislesCopy[featuredAisleId] = action.recentlyAddedShelfInfo;
      return {
        ...state,
        allAisles: allAislesCopy,
      };
    }

    default:
      return state;
  }
}

export function areAislesInitialized(state: AislesState) {
  return state.initialized;
}

export function getAisleConfig(state: AislesState) {
  return state.config;
}

export function allAisles(state: AislesState) {
  return state.allAisles;
}
