// @ts-nocheck
const baseRequiredBoxFields = [
  "id",
  "displayName",
  // 'fc.maxPrice'  // TODO: how to allow wild-card match? eg: fc.$.maxPrice
];

const basePrimaryRequiredBoxFields = [
  ...baseRequiredBoxFields,
  "grouping",
  "boxType",
  "boxSize",
  "imageId",
];

export const signupPrimaryBoxFields = [
  ...basePrimaryRequiredBoxFields,
  "display.pounds",
  "display.tagLine",
  "display.sideLabel",
  "display.displayName",
  "display.boxTypeFormatted",
  "display.thumbnailName",
  "display.thumbnailVersion",
  "display.color",
  "display.icon.name",
  "display.icon.path",
  "display.signupButtonBgImg.name",
  "display.signupButtonBgImg.path",
];

const baseSecondaryRequiredBoxFields = [
  ...baseRequiredBoxFields,
  "grouping",
  "display.quantity.min",
  "display.quantity.max",
  "display.displayName",
  "display.displayPrice",
];

export const signupSecondaryBoxFields = [
  ...baseSecondaryRequiredBoxFields,
  "display.thumbnailName",
  "display.thumbnailVersion",
  "display.description",
];

export const accountModalSecondaryBoxFields = [
  ...baseSecondaryRequiredBoxFields,
  "display.imageId",
  "display.imageVersion",
];

export const accountModalSecondaryBoxSampleFields = [
  "name",
  "description",
  "imageId",
  "imageVersion",
];
