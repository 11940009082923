import { Typography } from "@material-ui/core";
import { rem } from "polished";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  DETAILED_ORDER_PARAMS,
  hasDetailedOrderParams,
} from "app/constants/orders";
import {
  fetchOrder,
  fetchOrderSummaries,
  updateVariantQuantity,
} from "app/reducers/orders";
import {
  isInitialized,
  isShoppingInitialized,
  isCartVisible,
  getActiveOrderExpanded,
  getNextDeliveryFormatted,
  getRemovedDefaultOfferings,
  getNumberOfItemsInActiveOrder,
  hasFetchedOrderSummaries,
  isNativeApp,
  getFreeDeliveryThresholdReason,
  getFreeDeliveryThreshold,
  getSubtotalInActiveOrder,
  getOfferingsByVariantIds,
  canViewSignupShopping,
  isSignupShoppingInitialized,
} from "app/selectors";
import {
  hideCart,
  mobileAppNavigateToAccount,
  showModal,
} from "app/reducers/ui";
import breakpoints from "app/styles/breakpoints";
import Spinner from "app/ui/shared/Spinner";
import CartDeliveryDate from "./CartDeliveryDate";
import CartEmptyState from "./CartEmptyState";
import CartTotalBreakdown from "app/ui/shopping/Cart/CartTotalBreakdown";
import NeverListCartSection from "app/ui/shopping/Cart/NeverList/NeverListCartSection";
import { RECURRING_ITEM_MODAL_ID } from "app/constants/modals";
import { SHORT_DATE_FORMAT } from "app/ui/global/utils";
import State from "app/types/state";
import CartBackToAccount from "./CartBackToAccount";
import CartItem from "./CartItem";
import ViewableOffering from "app/types/selectors/crossDomain/ViewableOffering";
import MinimumOrderBanner from "./MinimumOrderProgress/MinimumOrderBanner";
import EllipsisAnimationText from "app/ui/shared/EllipsisAnimationText";
import * as fromSignup from "app/selectors/signup";

export interface CartProps {
  cartContainerRef: React.RefObject<HTMLDivElement>;
}

const Cart: React.FC<CartProps> = ({ cartContainerRef }) => {
  const [
    cartPriceSummaryIntersectionElRef,
    cartPriceSummaryIntersectionElInView,
    cartPriceSummaryIntersectionElEntry,
  ] = useInView({ root: cartContainerRef.current });

  const [cartSummaryItemRef, cartSummaryItemInView] = useInView({
    root: cartContainerRef.current,
    rootMargin: "0px 0px -74px 0px",
  });

  const dispatch = useDispatch();

  // NC-1244 productionalize signup shopping
  const isInSignupShopping = !!useSelector(canViewSignupShopping);

  const cartVisible = useSelector(isCartVisible);

  // NC-1244 productionalize signup shopping
  const shoppingInitialized = isInSignupShopping
    ? useSelector(isSignupShoppingInitialized)
    : useSelector(isShoppingInitialized);

  const initialized = useSelector(isInitialized);

  // NC-1244 productionalize signup shopping
  const order = isInSignupShopping
    ? useSelector(fromSignup.getOrderPosit)
    : useSelector(getActiveOrderExpanded);
  const removedDefaultOfferings = useSelector(getRemovedDefaultOfferings);

  const isNative = useSelector(isNativeApp);
  const fetchedOrderSummaries = useSelector(hasFetchedOrderSummaries);
  const numOrderItems = useSelector(getNumberOfItemsInActiveOrder);
  const cartPriceSummaryRef = useRef(null);

  const freeDeliveryThreshold = useSelector(getFreeDeliveryThreshold);

  const freeDeliveryThresholdReason = useSelector(
    getFreeDeliveryThresholdReason
  );
  const currentSubTotal = useSelector(getSubtotalInActiveOrder);
  const freeDelivery =
    !!freeDeliveryThreshold && currentSubTotal >= freeDeliveryThreshold;
  const automaticFreeDelivery =
    !!freeDeliveryThresholdReason &&
    freeDeliveryThresholdReason !== "NotApplicable" &&
    !freeDeliveryThreshold;

  const displayMinimumOrderBanner = !!freeDeliveryThreshold;

  const nextDelivery = useSelector((state: State) =>
    getNextDeliveryFormatted(state, { dateFormat: SHORT_DATE_FORMAT })
  );

  const viewableOfferings: ViewableOffering[] = useSelector((state: State) =>
    getOfferingsByVariantIds(state, {
      variantIds:
        order.lineItems && order.mapOfLineItems
          ? order.lineItems.map(
              (liId: string) => order.mapOfLineItems[liId]?.variantId
            )
          : [],
    })
  );

  // store a sorted copy of the offerings in this state so the sort only has to be done once
  // and so the offering can be displayed for a few seconds after it is deleted from the order
  // removed = true means that the undo UI is being displayed
  const [cartItemData, setCartItemData] = useState<
    {
      offering: ViewableOffering;
      removed: boolean;
    }[]
  >([]);

  // wait until offerings are actually loaded before caching them, in case cart is opened to quickly
  useEffect(() => {
    setCartItemData(
      viewableOfferings
        .sort((o1, o2) => o2.merchRank - o1.merchRank)
        .map((offering) => ({ offering, removed: false }))
    );
  }, [shoppingInitialized]);

  useEffect(() => {
    if (!fetchedOrderSummaries) {
      dispatch(fetchOrderSummaries());
    }
  }, []);

  useEffect(() => {
    if (cartVisible && !hasDetailedOrderParams(order.params)) {
      dispatch(fetchOrder(order.orderId, DETAILED_ORDER_PARAMS));
    }
  }, [cartVisible, order.params, order.version]);

  const focusCloseCart = (event: KeyboardEvent<HTMLDivElement>) => {
    const shiftTab = event.shiftKey && event.code === "Tab";
    const ctrlAltLeft =
      event.altKey && event.ctrlKey && event.key === "ArrowLeft";

    if (shiftTab || ctrlAltLeft) {
      (document.querySelector("#close-cart-button") as HTMLElement)?.focus();
      event.preventDefault();
    }
  };

  const showRecurringModal = (viewableOffering: ViewableOffering) =>
    dispatch(
      showModal(RECURRING_ITEM_MODAL_ID, {
        offering: viewableOffering,
        inRecurringItems: true,
      })
    );

  const removeOfferingFromComponentState = (cartItemIndex: number) => {
    setCartItemData((cartItemData) =>
      cartItemData.filter((_, index) => index !== cartItemIndex)
    );
  };

  const removeOfferingFromOrder = (
    variantId: string,
    cartItemIndex: number
  ) => {
    setCartItemData((cartItemData) =>
      cartItemData.map((data, index) =>
        index === cartItemIndex ? { ...data, removed: true } : data
      )
    );

    dispatch(
      updateVariantQuantity({
        variantId,
        quantity: 0,
        source: "Cart",
        isInSignupShopping,
      })
    );
  };

  const addOfferingToOrder = (variantId: string, cartItemIndex: number) => {
    setCartItemData((cartItemData) =>
      cartItemData.map((data, index) =>
        index === cartItemIndex ? { ...data, removed: false } : data
      )
    );

    dispatch(
      updateVariantQuantity({
        variantId,
        quantity: 1,
        source: "cart",
        isInSignupShopping,
      })
    );
  };

  const emptyCart = !cartItemData.length;

  return (
    <StyledCart aria-label="Shopping Cart" data-testid="shopping-cart">
      {!shoppingInitialized ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {!emptyCart && displayMinimumOrderBanner && (
            <StyledMinimumOrderBanner />
          )}
          <CartPriceSummaryIntersectionEl
            ref={cartPriceSummaryIntersectionElRef}
          />
          {!emptyCart && (
            <CartPriceSummary
              ref={cartPriceSummaryRef}
              tabIndex={0}
              id="cart-total"
              onKeyDown={(e) => focusCloseCart(e)}
              $stuck={!cartSummaryItemInView}
              $floating={
                !cartSummaryItemInView &&
                !!cartPriceSummaryIntersectionElEntry &&
                !cartPriceSummaryIntersectionElInView
              }
            >
              <CartPriceSummaryLabel variant="h3">
                Cart Total
              </CartPriceSummaryLabel>
              <CartPriceSummaryValue variant="h4">
                {hasDetailedOrderParams(order.params) || isInSignupShopping ? (
                  `$${order.totalAfterCredits}`
                ) : (
                  <TotalLoadingContainer>
                    <EllipsisAnimationText>.</EllipsisAnimationText>
                  </TotalLoadingContainer>
                )}
              </CartPriceSummaryValue>
            </CartPriceSummary>
          )}
          {cartItemData.map((data, index) => {
            // display the offering from redux unless the remove undo is active
            const viewableOffering = data.removed
              ? data.offering
              : viewableOfferings.find(
                  (v) => v.variantId === data.offering.variantId
                );
            return (
              viewableOffering?.variantId && (
                <CartItem
                  key={viewableOffering.variantId}
                  last={index === cartItemData.length - 1}
                  offering={viewableOffering}
                  onRecurringClick={() => showRecurringModal(viewableOffering)}
                  onRemoveItemFromCart={() =>
                    removeOfferingFromOrder(viewableOffering.variantId, index)
                  }
                  onCartItemClick={() => dispatch(hideCart())}
                  onRemoveItemCompleted={() =>
                    removeOfferingFromComponentState(index)
                  }
                  onUndoClicked={() =>
                    addOfferingToOrder(viewableOffering.variantId, index)
                  }
                  showUndo={data.removed}
                />
              )
            );
          })}
          <CartPaddedSection>
            {emptyCart ? (
              <CartEmptyState onShopClick={() => dispatch(hideCart())} />
            ) : (
              <>
                <CartDeliveryDate nextDelivery={nextDelivery} />
                <CartTotal>
                  <CartTotalBreakdown
                    cartSummaryItemRef={cartSummaryItemRef}
                    order={order}
                    freeDelivery={freeDelivery}
                    automaticFreeDelivery={automaticFreeDelivery}
                    numOrderItems={numOrderItems}
                  />
                </CartTotal>
              </>
            )}
          </CartPaddedSection>
          {!isInSignupShopping && (
            <>
              {!emptyCart &&
                initialized &&
                (isNative ? (
                  <CartBackToAccount
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(mobileAppNavigateToAccount());
                    }}
                  />
                ) : (
                  <CartBackToAccount useLink />
                ))}
              <NeverListCartSection
                removedDefaultOfferings={removedDefaultOfferings}
              />
            </>
          )}
        </>
      )}
    </StyledCart>
  );
};

export const CartPriceSummary = styled.div<{
  $stuck: boolean;
  $floating: boolean;
}>`
  position: sticky;
  top: ${rem(-48)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${rem(48)};
  padding: 0 ${rem(16)};
  border-radius: ${rem(8)} ${rem(8)} 0 0;
  background-color: ${({ theme }) => theme.colors.marshmallow};
  z-index: 2;
  transition: background-color 0.15s ease-in, top 0.2s ease-in;

  &:focus {
    border: none;
    outline: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focusWithOutline};
  }

  ${breakpoints.sm`
    margin: 0 ${rem(8)};
  `}

  ${({ $stuck }) =>
    $stuck &&
    `
    top: 0;
  `}

  ${({ $floating, theme }) =>
    $floating &&
    `
    background-color: ${theme.colors.baba};
    border-radius: 0;
  `}
`;

const CartPriceSummaryLabel = styled(Typography)`
  font-size: ${rem(24)};
`;

const CartPriceSummaryValue = styled(Typography)`
  font-size: ${rem(20)};
`;

const CartPriceSummaryIntersectionEl = styled.div`
  width: 100%;
`;

const StyledCart = styled.div`
  ${breakpoints.md`
    width: ${rem(400)};
  `}
  width: 100vw;
  min-height: 100%;
  padding-bottom: ${rem(8)};
  box-shadow: ${({ theme }) => theme.boxShadows.default};
  background: ${({ theme }) => theme.colors.baba};
  ${breakpoints.lg`
    padding-bottom: ${rem(
      74
    )}; /* prevents box shadows from getting chopped off */
  `}
`;

export const CartSection = styled.div`
  margin-bottom: ${rem(16)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const CartPaddedSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(16)};
  padding: ${rem(16)} ${rem(16)} ${rem(8)} ${rem(16)};
  background: ${({ theme }) => theme.colors.marshmallow};
`;

const CartTotal = styled.div`
  padding-top: ${rem(16)};
  margin-bottom: ${rem(14)};
`;

const StyledMinimumOrderBanner = styled(MinimumOrderBanner)`
  position: fixed;
  left: 0;
  right: 0;
  height: ${({ theme }) => rem(theme.layout.shop.minimumOrderBannerHeight)};
  z-index: ${({ theme }) => theme.layout.zIndex.default};
  ${breakpoints.lg`
    position: relative;
    margin-left: ${rem(-16)};
    margin-right: ${rem(-16)};
    top: ${rem(-16)};
  `}
`;

const SpinnerContainer = styled.div`
  padding-top: ${rem(32)};
  display: flex;
  justify-content: center;
  width: 100%;
`;

const TotalLoadingContainer = styled.div`
  padding-right: ${rem(32)};
`;

export default Cart;
