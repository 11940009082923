// @ts-nocheck
import { fork } from "redux-saga/effects";

import rootAccount from "./account";
import rootAisles from "./aisles";
import rootAnalytics from "./analytics";
import rootAuth from "./auth";
import rootBoxes from "./boxes";
import rootCatalog from "./catalog";
import rootCMS from "./cms";
import rootDeliveries from "./deliveries";
import rootFriendbuy from "./friendbuy";
// NC-1499 Remove legacy friendbuy
import rootFriendbuyLegacy from "./friendbuy/friendbuyLegacy";
import rootMobile from "./mobile";
import rootOfferings from "./offerings";
import rootOptimizely from "./optimizely";
import rootOrders from "./orders";
import rootRouting from "./routing";
import rootSearch from "./search";
import rootSignup from "./signup";
import rootSplit from "./split";
import rootUi from "./ui";
import rootReactivation from "./reactivation";
import rootMisfits from "./misfits";
import rootChat from "./chat";

export default function* rootSaga() {
  yield fork(rootRouting);
  yield fork(rootUi);
  yield fork(rootAuth);
  yield fork(rootOptimizely);
  yield fork(rootSignup);
  yield fork(rootBoxes);
  yield fork(rootAccount);
  yield fork(rootOrders);
  yield fork(rootOfferings);
  yield fork(rootAnalytics);
  yield fork(rootDeliveries);
  yield fork(rootFriendbuy);
  // NC-1499 Remove legacy friendbuy
  yield fork(rootFriendbuyLegacy);
  yield fork(rootCMS);
  yield fork(rootSplit);
  yield fork(rootSearch);
  yield fork(rootAisles);
  yield fork(rootCatalog);
  yield fork(rootMobile);
  yield fork(rootReactivation);
  yield fork(rootMisfits);
  yield fork(rootChat);
}
