import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { setDeviceType, setInitialDeviceType } from "app/reducers/ui";

const getDeviceType = (isMobile: boolean, isTablet: boolean) => {
  if (isMobile) return "mobile";
  if (isTablet) return "tablet";
  return "desktop";
};

const useMediaListener = (isMobile: boolean, isTablet: boolean) => {
  const dispatch = useDispatch();
  const deviceTypeRef = useRef(getDeviceType(isMobile, isTablet));
  useEffect(() => {
    const deviceType = getDeviceType(isMobile, isTablet);
    if (deviceTypeRef.current !== deviceType) {
      dispatch(setDeviceType(deviceType));
      deviceTypeRef.current = deviceType;
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    dispatch(setInitialDeviceType(getDeviceType(isMobile, isTablet)));
  }, []);
};

export default useMediaListener;
