import _omit from "lodash/omit";
import _uniq from "lodash/uniq";

import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import SIGNUP_ACTION_TYPES from "app/actionTypes/signup";
import FRIENDBUY_ACTION_TYPES from "app/actionTypes/friendbuy";
import ACTION_TYPES from "app/actionTypes/orders";
import { OrderParamsType } from "app/constants/orders";
import OrdersState from "app/types/state/orders";
import Order, {
  OrderLineItem,
  OrderOptimisticItem,
  CancelReasonCode,
} from "app/types/state/orders/Order";
import OrderCoupon, { BaseCoupon } from "app/types/state/orders/OrderCoupon";
import OrderSummary from "app/types/state/orders/OrderSummary";
import SetOrderStatusAction, {
  SetOrderStatusExtraParams,
} from "app/types/order/SetOrderStatusAction";

export enum PaymentStatus {
  START_CART_PUSHED = "StartCartPushed",
  CREATED = "Created",
  LOCKED = "Locked",
  DISPATCHED = "Dispatched",
  WAVED = "Waved",
  SHIPPED = "Shipped",
  ROUTED = "Routed",
  PACKED = "Packed",
  CANCELED = "Canceled",
  OVER_CAPACITY = "OverCapacity",
  AUTH_FAILED = "OrderAuthorizationFailed",
}

// todo: use enum instead of consts
export const START_CART_PUSHED = "StartCartPushed",
  CREATED = "Created",
  LOCKED = "Locked",
  DISPATCHED = "Dispatched",
  WAVED = "Waved",
  SHIPPED = "Shipped",
  ROUTED = "Routed",
  PACKED = "Packed",
  CANCELED = "Canceled",
  OVER_CAPACITY = "OverCapacity",
  AUTH_FAILED = "OrderAuthorizationFailed"; // NOTE: this is a paymentStatus, not orderStatus

export const CANCEL_STATUS_DISPATCH_FAILED_UNDER_MINIMUM =
    "DispatchFailedUnderMinimum",
  CANCEL_STATUS_DELIVERY_FAILED = "DeliveryFailed";

export const initialState: OrdersState = {
  allOrders: {},
  allOrderSummaries: [],
  active: {
    orderId: null,
    rollbackVersions: {},
    loading: true,
    updating: true,
  },
  futureOrders: [],
  coupons: {},
  jalapenoCoupon: {},
  removingCoupon: null,
  showNeverListSuggestions: true,
  creatingAdHocOrder: false,
  adHocOrderCreateError: null,
  addingMultipleItems: {
    isCurrentlyAdding: false,
    addingFromShelfId: "",
  },
};

let optimisticCount = 0;

const addOrder = (
  state: OrdersState,
  order: Order,
  optimisticId?: string // (optional)
) => {
  const previousVersion = state.allOrders[order.orderId];
  const theLineItems = ((order.lineItems as OrderLineItem[]) || []).reduce(
    (
      acc: {
        lineItems: string[];
        mapOfLineItems: Order["mapOfLineItems"];
        defaultLineItems: Order["defaultLineItems"];
        removedDefaultProductIds: Order["removedDefaultProductIds"];
      },
      lineItem: OrderLineItem
    ) => {
      const { variantId, productId } = lineItem;
      const isDefaultLineItem = order.adhocLines.find(
        ({ variantId: vId, metadata }) => {
          return variantId === vId && !!metadata.boxId;
        }
      );
      acc.lineItems.push(variantId);
      acc.mapOfLineItems[variantId] = lineItem;
      if (isDefaultLineItem) {
        acc.defaultLineItems.push(variantId);

        acc.removedDefaultProductIds = acc.removedDefaultProductIds.filter(
          (pId) => pId !== productId
        );
      }
      return acc;
    },
    {
      lineItems: [],
      mapOfLineItems: {},
      defaultLineItems: [],
      removedDefaultProductIds: previousVersion
        ? previousVersion.removedDefaultProductIds
        : [],
    } as {
      lineItems: string[];
      mapOfLineItems: Order["mapOfLineItems"];
      defaultLineItems: Order["defaultLineItems"];
      removedDefaultProductIds: Order["removedDefaultProductIds"];
    }
  );

  const {
    lineItems,
    mapOfLineItems,
    defaultLineItems,
    removedDefaultProductIds,
  } = theLineItems;

  return {
    allOrders: {
      ...state.allOrders,
      [order.orderId]: {
        ...order,
        defaultLineItems,
        lineItems,
        mapOfLineItems,
        removedDefaultProductIds,
        failedInitialAuth:
          order.status === LOCKED && order.paymentStatus === AUTH_FAILED,
        failedAuth:
          order.status === CANCELED && order.paymentStatus === AUTH_FAILED,
        deliveryFailed:
          order.status === CANCELED &&
          order.cancelReasonCode === CANCEL_STATUS_DELIVERY_FAILED,
        canceledBecauseEmpty:
          order.status === CANCELED &&
          order.cancelReasonCode ===
            CANCEL_STATUS_DISPATCH_FAILED_UNDER_MINIMUM,
      },
    },
    ...(optimisticId && {
      active: {
        ...state.active,
        updating: false,
        // updatesInProgress: state.active.updatesInProgress.filter((id) => id !== updateId),
        rollbackVersions: _omit(state.active.rollbackVersions, optimisticId),
      },
    }),
  };
};

// TODO: pull generic optimisticUpdate wrapper into redux middleware? (to use in other state slices)
const optimisticUpdate = (
  state: OrdersState,
  updateId: string,
  updateFn: (current: Order, original: Order) => Order
) => {
  const { orderId } = state.active;
  const original = state.active.rollbackVersions[updateId];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const current = state.allOrders[orderId!];

  return {
    ...state,
    active: {
      ...state.active,
      updating: true,
      rollbackVersions: {
        ...state.active.rollbackVersions,
        [updateId]: original || current,
      },
    },
    // TODO: make use of addOrder funciton above for the updateFn?
    allOrders: {
      ...state.allOrders,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [orderId!]: updateFn(current, original),
    },
  };
};

/*
  Use this for all optimistic rollbacks; the original order at `updateId` will
  be removed from the optimisticUpdates cache and set in the allOrders map
 */
const optimisticRollback = (state: OrdersState, updateId: string) => {
  const { orderId } = state.active;
  const previousOrderState = state.active.rollbackVersions[updateId];

  return {
    ...state,
    active: {
      ...state.active,
      updating: false,
      // updatesInProgress: state.active.updatesInProgress.filter((id) => id !== updateId),
      rollbackVersions: _omit(state.active.rollbackVersions, updateId),
    },
    allOrders: {
      ...state.allOrders,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [orderId!]: previousOrderState,
    },
  };
};

const optimisticUpdateQuantity = (
  state: OrdersState,
  {
    variantId,
    shelfId,
    quantity,
    optimisticId,
  }: {
    variantId: string;
    shelfId?: string;
    quantity: number;
    optimisticId: string;
  }
) => {
  // @ts-ignore TODO: how to handle mapOfLineItems: Record<string, OrderLineItem | OrderOptimisticItem>;
  return optimisticUpdate(state, optimisticId, (current, original) => {
    const order = original || current;
    const item = order.mapOfLineItems[variantId];
    let optimisticItem;
    let { total, subtotal } = order;

    const isRemovingDefaultItem =
      quantity === 0 && order.defaultLineItems.includes(variantId);

    if (item) {
      const qtyDelta = quantity - item.quantity;
      optimisticItem = {
        ...item,
        quantity,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        price: item.unitPrice! * quantity,
        adhocQuantity: item.adhocQuantity + qtyDelta,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const totalChange = qtyDelta * item.unitPrice!;

      total += totalChange;
      subtotal += totalChange;
    } else {
      // Other lineItem fields are handled @ the selector level if optimistic === true
      // eslint-disable-next-line object-curly-newline
      optimisticItem = {
        variantId,
        shelfId,
        quantity,
        adhocQuantity: quantity,
        optimistic: true,
      };
    }
    const lineItems = _uniq((order.lineItems as string[]).concat(variantId));
    // NOTE: doesn't exclude those recently added to NeverList
    // this happens in the getActiveOrderExpanded selector
    const removedDefaultProductIds = isRemovingDefaultItem
      ? _uniq(order.removedDefaultProductIds.concat(item.productId))
      : order.removedDefaultProductIds;

    return {
      ...order,
      subtotal,
      total,
      // TODO: this can be rewritten to use addOrder funciton above for the updateFn?
      lineItems,
      removedDefaultProductIds,
      mapOfLineItems: {
        ...order.mapOfLineItems,
        [variantId]: optimisticItem,
      },
    };
  });
};

const filterOrderSummaries = (
  state: OrdersState,
  action: {
    orders: OrderSummary[];
  }
) => {
  const excludedStatusArray = ["Created", "StartCartPushed", "Canceled"];
  return {
    ...state,
    fetchedOrderSummary: true,
    allOrderSummaries: action.orders.filter(
      (o: OrderSummary) =>
        !excludedStatusArray.includes(o.status) ||
        o.cancelReasonCode === "DeliveredNotCharged"
    ),
  };
};

const removeProductFromNeverListSuggestions = (
  state: OrdersState,
  { productId }: { productId: string }
) => {
  const activeOrderId = state.active.orderId;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const activeOrder = state.allOrders[activeOrderId!];
  // TODO: what if optimistic order update is in progress when this is called?
  if (!activeOrder) {
    return state;
  }
  return {
    ...state,
    allOrders: {
      ...state.allOrders,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [activeOrderId!]: {
        ...activeOrder,
        removedDefaultProductIds: activeOrder.removedDefaultProductIds.filter(
          (i) => i !== productId
        ),
      },
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action = {} as any) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ACTIVE_ORDER:
      return {
        ...state,
        active: {
          ...state.active,
          loading: true,
        },
      };
    case ACTION_TYPES.FETCH_ACTIVE_ORDER_SUCCEEDED:
    case ACTION_TYPES.FETCH_ACTIVE_ORDER_FAILED: {
      return {
        ...state,
        active: {
          ...state.active,
          loading: false,
        },
      };
    }

    case ACTION_TYPES.CREATE_AD_HOC_ORDER:
      return {
        ...state,
        creatingAdHocOrder: true,
        adHocOrderCreateError: null,
      };
    case ACTION_TYPES.CREATE_AD_HOC_ORDER_SUCCEEDED:
    case ACTION_TYPES.CREATE_AD_HOC_ORDER_FAILED:
      return {
        ...state,
        creatingAdHocOrder: false,
        adHocOrderCreateError: action.error,
      };

    case ACTION_TYPES.FETCH_ORDER_SUMMARIES_SUCCEEDED:
      return filterOrderSummaries(state, action);

    case ACTION_TYPES.UPDATE_VARIANT_QUANTITY:
      return action.optimistic
        ? optimisticUpdateQuantity(state, action)
        : state;

    case ACTION_TYPES.UPDATE_VARIANT_QUANTITY_SUCCEEDED:
      return {
        ...state,
        ...addOrder(state, action.order, action.optimisticId),
      };

    case ACTION_TYPES.FETCH_ORDER_SUCCEEDED:
    case ACTION_TYPES.ADD_BOXES_TO_ORDER_SUCCEEDED:
      return {
        ...state,
        ...addOrder(state, action.order),
      };

    case ACTION_TYPES.UPDATE_VARIANT_QUANTITY_FAILED:
      // TODO: error handling!
      return action.optimistic
        ? optimisticRollback(state, action.optimisticId)
        : state;

    case ACTION_TYPES.UPDATE_VARIANT_QUANTITY_CANCELED:
      return optimisticRollback(state, action.optimisticId);

    case ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED:
      return {
        ...state,
        ...(action.order ? addOrder(state, action.order) : {}),
        active: {
          ...state.active,
          orderId: action.orderId,
          loading: false,
          updating: false,
        },
        previousOrder: action.previousOrder,
      };

    case ACCOUNT_ACTION_TYPES.FETCH_FUTURE_ORDERS_SUCCEEDED:
      return {
        ...state,
        futureOrders: action.orders || [],
      };
    case ACTION_TYPES.REMOVE_COUPON: {
      return {
        ...state,
        removingCoupon: true,
      };
    }
    case ACTION_TYPES.REMOVE_COUPON_FAILED: {
      return {
        ...state,
        removingCoupon: false,
      };
    }
    case ACTION_TYPES.JALAPENO_VALIDATE_COUPON_SUCCEEDED: {
      const { coupon } = action;
      return {
        ...state,
        jalapenoCoupon: coupon.data.disc,
      };
    }
    case ACCOUNT_ACTION_TYPES.APPLY_COUPON_SUCCEEDED:
    case ACTION_TYPES.REMOVE_COUPON_SUCCEEDED:
    case ACTION_TYPES.VALIDATE_COUPON_SUCCEEDED:
    case FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON_SUCCEEDED: {
      // Order came back from response?
      if (action.order) {
        return {
          ...state,
          removingCoupon: false,
          ...addOrder(state, action.order),
        };

        // Coupon came back from response?
      }
      if (action.coupon) {
        const { coupon } = action;
        const code = action.code || coupon.code;
        return {
          ...state,
          coupons: {
            ...state.coupons,
            [code]: { ...coupon },
          },
        };
      }

      return state;
    }

    case ACTION_TYPES.TOGGLE_NEVER_LIST_SUGGESTIONS: {
      return {
        ...state,
        showNeverListSuggestions: !state.showNeverListSuggestions,
      };
    }

    case ACCOUNT_ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST_SUCCEEDED:
    case ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_SUGGESTIONS: {
      return removeProductFromNeverListSuggestions(state, action);
    }

    case ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF: {
      return {
        ...state,
        addingMultipleItems: {
          isCurrentlyAdding: true,
          addingFromShelfId: action.shelfId,
        },
      };
    }
    case ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF_FAILED:
    case ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF_SUCCEEDED: {
      return {
        ...state,
        addingMultipleItems: {
          isCurrentlyAdding: false,
          addingFromShelfId: "",
        },
      };
    }

    default:
      return state;
  }
}

export function getAllOrders(state: OrdersState) {
  return state.allOrders;
}

export function getPreviousOrder(state: OrdersState) {
  return state.previousOrder;
}

export function getAllOrderSummaries(state: OrdersState) {
  return state.allOrderSummaries;
}

export function getOrderById(state: OrdersState, orderId: string) {
  return state.allOrders[orderId];
}

export function getActiveOrder(state: OrdersState) {
  return state.active;
}

export function isFetchingActiveOrder(state: OrdersState) {
  return state.active.loading === true;
}

export function getFutureOrders(state: OrdersState) {
  return state.futureOrders || [];
}

export function getJalapenoCoupon(state: OrdersState) {
  return state.jalapenoCoupon;
}

export function getCoupons(state: OrdersState) {
  return state.coupons;
}

export function isRemovingCoupon(state: OrdersState) {
  return state.removingCoupon;
}

export function isCreatingAdHocOrder(state: OrdersState) {
  return state.creatingAdHocOrder;
}

export function getAdHocOrderCreationError(state: OrdersState) {
  return state.adHocOrderCreateError;
}

export function shouldShowNeverListSuggestions(state: OrdersState) {
  return state.showNeverListSuggestions;
}

export function isAddingMultipleItems(state: OrdersState) {
  return state.addingMultipleItems;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function incrementVariantsQuantity(props: any) {
  return {
    type: ACTION_TYPES.INCREMENT_VARIANTS_QUANTITY,
    ...props,
  };
}

export function updateVariantQuantity({
  variantId,
  shelfId,
  optimistic = true,
  isInSignupShopping = false,
  ...others
}: OrderOptimisticItem) {
  if (isInSignupShopping) {
    return {
      type: SIGNUP_ACTION_TYPES.UPDATE_CART,
      variantId,
      quantity: others.quantity,
    };
  }

  return {
    type: ACTION_TYPES.UPDATE_VARIANT_QUANTITY,
    ...others,
    variantId,
    shelfId,
    optimistic,
    // eslint-disable-next-line no-plusplus
    optimisticId: `update-qty-${optimisticCount++}__${variantId}`,
  };
}

export function updateAllVariantQuantitiesFromShelf(shelfId: string) {
  return {
    type: ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF,
    shelfId,
  };
}

export function cancelActiveOrder(
  reasonCode: CancelReasonCode,
  extraParams: SetOrderStatusExtraParams = {}
) {
  return {
    type: ACTION_TYPES.SET_ORDER_STATUS,
    ...extraParams,
    status: "Canceled",
    reasonCode,
    reason: "",
  } as SetOrderStatusAction;
}

// hard remove an order, as if it never existed
export function removeOrder(extraParams: SetOrderStatusExtraParams = {}) {
  return {
    type: ACTION_TYPES.SET_ORDER_STATUS,
    ...extraParams,
    status: "Canceled",
    reasonCode: "Misbegotten",
    reason: "",
  } as SetOrderStatusAction;
}

export function autoSkipNextOrder() {
  return {
    type: ACTION_TYPES.AUTO_SKIP_NEXT_ORDER,
  };
}

export function fetchActiveOrder(params: Record<string, unknown>) {
  return { type: ACTION_TYPES.FETCH_ACTIVE_ORDER, ...params };
}

export function fetchOrderSummaries() {
  return { type: ACTION_TYPES.FETCH_ORDER_SUMMARIES };
}

// TODO - params should include orderId
export function fetchOrder(orderId: string, params: OrderParamsType) {
  return { type: ACTION_TYPES.FETCH_ORDER, orderId, params };
}

export function validateCoupon(params: BaseCoupon) {
  return { type: ACTION_TYPES.VALIDATE_COUPON, ...params };
}

export function removeCoupon(params: OrderCoupon) {
  return { type: ACTION_TYPES.REMOVE_COUPON, ...params };
}

export function createAdHocOrder(
  deliveryDate: string,
  windowId: string,
  flexibleDelivery: boolean
) {
  return {
    type: ACTION_TYPES.CREATE_AD_HOC_ORDER,
    deliveryDate,
    windowId,
    flexibleDelivery,
  };
}
export function createAdHocOrderSucceeded(orderId: string) {
  return { type: ACTION_TYPES.CREATE_AD_HOC_ORDER_SUCCEEDED, orderId };
}
export function createAdHocOrderFailed(error: {}) {
  return { type: ACTION_TYPES.CREATE_AD_HOC_ORDER_FAILED, error };
}
export function createAdHocOrderIneligible() {
  return createAdHocOrderFailed({ notAvailable: true });
}

export function toggleNeverListSuggestions() {
  return { type: ACTION_TYPES.TOGGLE_NEVER_LIST_SUGGESTIONS };
}

export function removeNeverListSuggestion(productId: string, source = "card") {
  return {
    type: ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_SUGGESTIONS,
    productId,
    source,
  };
}
