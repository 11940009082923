import ACTION_TYPES from "app/actionTypes/analytics";
import AnalyticsInitializing from "app/types/analytics/AnalyticsInitializing";
import AnalyticsReady from "app/types/analytics/AnalyticsReady";
import AnalyticsReset from "app/types/analytics/AnalyticsReset";
import AnalyticsState, { ImpactClick } from "app/types/state/analytics";

export const initialState: AnalyticsState = {
  analyticsInitialized: false,
  anonymousId: null,
  isLoggingOut: false,
  impactClick: {
    id: null,
    dateAdded: null, // the ID is valid for 45 days
  },
};

const handleInitializing = (
  state: AnalyticsState,
  action: AnalyticsInitializing
): AnalyticsState => {
  const { isLoggingOut, anonymousId = null } = action;

  return {
    ...state,
    isLoggingOut,
    anonymousId: isLoggingOut ? state.anonymousId : anonymousId,
  };
};

const handleSdkReady = (
  state: AnalyticsState,
  action: AnalyticsReady
): AnalyticsState => {
  const { anonymousId = null } = action;
  return {
    ...state,
    analyticsInitialized: true,
    anonymousId,
  };
};

const handleSdkReset = (
  _state: AnalyticsState,
  action: AnalyticsReset
): AnalyticsState => {
  return {
    ...initialState,
    anonymousId: action.anonymousId,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.SDK_RESET: {
      return handleSdkReset(state, action);
    }

    case ACTION_TYPES.SDK_INITIALIZING: {
      return handleInitializing(state, action);
    }

    case ACTION_TYPES.SDK_READY:
      return handleSdkReady(state, action);
    case ACTION_TYPES.SET_IMPACT_CLICK:
      return {
        ...state,
        impactClick: {
          id: action.id,
          dateAdded: action.dateAdded,
        },
      };
    default:
      return state;
  }
}

export function isAnalyticsInitialized(state: AnalyticsState): boolean {
  return state.analyticsInitialized;
}

export function getAnonymousId(state: AnalyticsState): string | null {
  return state.anonymousId;
}

export function isLoggingOut(state: AnalyticsState) {
  return state.isLoggingOut;
}

export function getImpactClick(state: AnalyticsState): ImpactClick {
  return state.impactClick;
}

export function analyticsReset(params: AnalyticsReset) {
  return { type: ACTION_TYPES.SDK_RESET, ...params };
}

export function analyticsInitializing(params: AnalyticsInitializing) {
  return { type: ACTION_TYPES.SDK_INITIALIZING, ...params };
}

export function analyticsReady(params: AnalyticsReady) {
  return { type: ACTION_TYPES.SDK_READY, ...params };
}

export function setImpactClick(params: ImpactClick) {
  return { type: ACTION_TYPES.SET_IMPACT_CLICK, ...params };
}
