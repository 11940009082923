// @ts-nocheck
import delay from "@redux-saga/delay-p";
import Moment from "moment-timezone";
import { take, fork, call, all, put, select, cancel } from "redux-saga/effects";

import { REHYDRATE } from "app/actionTypes";
import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import AUTH_ACTION_TYPES from "app/actionTypes/auth";
import ORDERS_ACTION_TYPES from "app/actionTypes/orders";
import ROUTING_ACTION_TYPES from "app/actionTypes/routing";
import SEARCH_ACTION_TYPES from "app/actionTypes/search";
import ACTION_TYPES from "app/actionTypes/ui";
import REACTIVATION_ACTION_TYPES from "app/actionTypes/reactivation";
import { CRITICAL_DATA_REQUEST } from "app/api/request";
import { RECURRING_ITEM_MODAL_ID } from "app/constants/modals";
import { SHOPPING_ROUTES, isPathInUrlList } from "app/constants/routes";
import { setContext } from "app/monitoring";
import { setImpactClick } from "app/reducers/analytics";
import { initializeSplit } from "app/reducers/extendedSplit";
import { fetchPlusPrice } from "app/reducers/offerings";
import { showDisableRecurringNudgeToast } from "app/reducers/toast";
import {
  userSearchedNoResults,
  setOrToggleSelectedToolbarItem,
  setCatalogPreviewTool,
} from "app/reducers/ui";
import { fetchUserFlow } from "app/sagas/account";
import { fetchAislesFlow } from "app/sagas/aisles";
import { handleCredentials } from "app/sagas/auth";
import { fetchBoxesFlow } from "app/sagas/boxes";
import fetchNextDeliveryFlow from "app/sagas/fetchNextDeliveryFlow";
import modalTriage from "app/sagas/modalTriage";
import {
  fetchAllOfferingsFlow,
  fetchAllOfferingsFromAPIFlow,
  fetchAllTagsFlow,
} from "app/sagas/offerings";
import { waitForSplitInitialization } from "app/sagas/waitForSplit";
import { waitForAnalyticsInitialization } from "app/sagas/waitForAnalyticsInitialization";
import {
  getUserId,
  getAnonymousId,
  isLoggedIn,
  getRoutingLocation,
  getPreviousRoutingLocation,
  getShopToolbarSelectedItem,
  getSearchQuery,
  getSearchResultInAllProducts,
  getOrderedOfferingsWithSearch,
  isPlusInitialized,
  canShowPlusWidget,
  getPlusPrice,
  shouldAllowRecurringItemsNudge,
  isShoppingInitialized,
  isSignupShoppingInitialized,
  canUserCustomize,
  isImpersonated,
  isInSignupShoppingTreatment,
  getSignupVariantQuantities,
  getOrderPosit,
  getSignupBoxId,
  getIsPrefilledCartFilled,
} from "app/selectors";
import { ShopToolbarIcons } from "app/ui/shopping/Navigation/ShopToolbar";

import routes from "app/router/routes.json";
import { goTo } from "app/router/routes";
import queryString from "query-string";

export default function* rootUi() {
  let bootStrapTask;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      REHYDRATE,
      ACTION_TYPES.BOOTSTRAP_COMPLETE,
      AUTH_ACTION_TYPES.LOGIN_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.CREATE_SUBSCRIPTION_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED,
      ORDERS_ACTION_TYPES.CREATE_AD_HOC_ORDER_SUCCEEDED,
      ROUTING_ACTION_TYPES.LOCATION_CHANGED,
      ACTION_TYPES.INITIALIZE_SHOPPING,
      ACTION_TYPES.INITIALIZE_SIGNUP_SHOPPING,
      SEARCH_ACTION_TYPES.SEARCH_RESULT_CHANGED,
      ACTION_TYPES.INITIALIZE_SUCCEEDED,
      ACTION_TYPES.UPDATE_MODAL,
      ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED,
      REACTIVATION_ACTION_TYPES.COMPLETE_REACTIVATION_SUCCEEDED,
      ACTION_TYPES.CLEAR_CATALOG_PREVIEW_TOOL,
      ACTION_TYPES.SET_CATALOG_PREVIEW_TOOL,
    ]);

    switch (action.type) {
      case REHYDRATE: {
        if (bootStrapTask) {
          yield cancel(bootStrapTask);
        }
        bootStrapTask = yield fork(bootStrapFlow);
        break;
      }
      case ROUTING_ACTION_TYPES.LOCATION_CHANGED: {
        yield fork(handleShoppingToolbarRouteChange, action);
        yield fork(modalTriage); // clears all modals, so do this first
        yield fork(setImpactId, action);
        break;
      }
      // NOTE: if any of the actions below are removed or no longer trigger the initializeFlow
      // then the action needs to manually trigger the optimizely identifyOptimizelyUserAttributes saga
      // to ensure the user's attributes are sent to optimizely when the user's state changes
      case AUTH_ACTION_TYPES.LOGIN_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.CREATE_SUBSCRIPTION_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED:
      case ORDERS_ACTION_TYPES.CREATE_AD_HOC_ORDER_SUCCEEDED:
      case REACTIVATION_ACTION_TYPES.COMPLETE_REACTIVATION_SUCCEEDED:
      case ACTION_TYPES.BOOTSTRAP_COMPLETE: {
        yield fork(initializeFlow, action);
        break;
      }
      case ACTION_TYPES.INITIALIZE_SUCCEEDED: {
        yield fork(initializePlus);
        break;
      }
      case ACTION_TYPES.INITIALIZE_SHOPPING:
      case ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED: {
        const location = yield select(getRoutingLocation);
        const hasCatalogPreviewQuerysString =
          !!location.query?.fcId &&
          !!location.query?.packDate &&
          !!location.query?.priceZoneId;
        if (hasCatalogPreviewQuerysString) {
          const data = {
            fcId: location.query.fcId,
            packDate: location.query.packDate,
            priceZoneId: location.query.priceZoneId,
          };
          // this is hit when a preview query string was on initial page
          yield put(setCatalogPreviewTool(data));
          yield fork(initializeCatalogPreviewTool, {
            data,
          });
        } else {
          // reguar shopping
          yield fork(initializeShopping, action);
        }
        break;
      }
      case ACTION_TYPES.CLEAR_CATALOG_PREVIEW_TOOL: {
        // clear the query strings or it will go back into preview
        goTo({ pathname: routes.shopping.url }, "replace");
        yield fork(initializeShopping, action);
        break;
      }
      case ACTION_TYPES.SET_CATALOG_PREVIEW_TOOL: {
        // add query strings for the preview parameters so preview can be bookmarked

        goTo(
          {
            pathname: routes.shopping.url,
            search: queryString.stringify(action.data),
          },
          "replace"
        );
        yield fork(initializeCatalogPreviewTool, action);
        break;
      }
      case ACTION_TYPES.INITIALIZE_SIGNUP_SHOPPING: {
        yield fork(initializeSignupShopping, action);
        break;
      }
      case SEARCH_ACTION_TYPES.SEARCH_RESULT_CHANGED: {
        yield fork(handleSearchResultChanged);
        break;
      }
      case ACTION_TYPES.UPDATE_MODAL: {
        yield fork(maybeShowDisableRecurringNudgeToast, action);
        break;
      }

      default:
        return;
    }
  }
}

export function* bootStrapFlow() {
  yield delay(5);
  yield put({ type: ACTION_TYPES.BOOTSTRAP_COMPLETE });
}

export function* initializeFlow() {
  yield call(handleCredentials);
  yield call(waitForAnalyticsInitialization);
  yield put(initializeSplit());
  const anonymousId = yield select(getAnonymousId);
  const userId = yield select(getUserId);
  const impersonating = yield select(isImpersonated);
  yield call(setContext, anonymousId, userId, impersonating);
  try {
    const loggedIn: boolean = yield select(isLoggedIn);
    if (loggedIn) {
      const params = { tags: [CRITICAL_DATA_REQUEST] };
      yield all([call(fetchUserFlow, params), call(fetchBoxesFlow, params)]);
      yield call(fetchNextDeliveryFlow, params);
    }
    yield put({ type: ORDERS_ACTION_TYPES.JALAPENO_VALIDATE_COUPON });
    yield put({ type: ACTION_TYPES.INITIALIZE_SUCCEEDED, loggedIn });
  } catch (error) {
    yield put({ type: ACTION_TYPES.INITIALIZE_FAILED, error });
  }
}

function* setImpactId() {
  const location = yield select(getRoutingLocation);

  if (location.query && location.query.irclickid) {
    yield put(
      setImpactClick({
        id: location.query.irclickid,
        dateAdded: new Moment(),
      })
    );
  }
}

export function* handleShoppingToolbarRouteChange() {
  const productDetailPageRoute = routes.shopProductDetailPage.url.split(":")[0];
  const location = yield select(getRoutingLocation);
  const previousLocation = yield select(getPreviousRoutingLocation);
  const selectedToolbarItem = yield select(getShopToolbarSelectedItem);
  const query = yield select(getSearchQuery);
  const isPdp = location.pathname.includes(productDetailPageRoute);
  const wasPdp =
    !!previousLocation &&
    previousLocation.pathname.includes(productDetailPageRoute);
  const wasShopping =
    !!previousLocation &&
    isPathInUrlList(previousLocation.pathname, SHOPPING_ROUTES);
  const isShopping =
    !!location && isPathInUrlList(location.pathname, SHOPPING_ROUTES);
  const hadTag = !!previousLocation?.query["tag"];
  const hasTag = !!location?.query["tag"];

  // Keep toolbar open when clicking on a toolbar icon from outside shopping and navigating to shopping
  if (
    !wasShopping &&
    isShopping &&
    selectedToolbarItem !== ShopToolbarIcons.NONE
  )
    return;

  // Close the nav bar when navigating to the pdp invariantly
  if (isPdp && !wasPdp && selectedToolbarItem !== ShopToolbarIcons.NONE) {
    yield put(setOrToggleSelectedToolbarItem(ShopToolbarIcons.NONE));
    return;
  }

  // When navigating away from the PDP with the filter toolbar selected leave it selected
  if (wasPdp && !isPdp && selectedToolbarItem === ShopToolbarIcons.FILTER)
    return;

  // If a tag was removed and the filter navbar is open keep it open
  if (hadTag && !hasTag && selectedToolbarItem === ShopToolbarIcons.FILTER)
    return;

  // If the user did not add or remove a tag and is navigating between routes, close the filter bar
  if (!hadTag && !hasTag && selectedToolbarItem === ShopToolbarIcons.FILTER) {
    yield put(setOrToggleSelectedToolbarItem(ShopToolbarIcons.NONE));
    return;
  }

  // If the user navigates back from the PDP with a search query active open the search toolbar
  if (wasPdp && !!query && selectedToolbarItem !== ShopToolbarIcons.FILTER) {
    if (selectedToolbarItem !== ShopToolbarIcons.SEARCH) {
      yield put(setOrToggleSelectedToolbarItem(ShopToolbarIcons.SEARCH));
    }
    return;
  }
  // If the user navigates back from the PDP with a tag selected open the filter toolbar
  if (wasPdp && hasTag && selectedToolbarItem !== ShopToolbarIcons.SEARCH) {
    if (selectedToolbarItem !== ShopToolbarIcons.FILTER) {
      yield put(setOrToggleSelectedToolbarItem(ShopToolbarIcons.FILTER));
    }
    return;
  }

  // Whenever non of the previous conditions are met and navigating to a non shopping route
  // close the navnavbar invariantly.
  if (!isShopping && selectedToolbarItem !== ShopToolbarIcons.NONE)
    yield put(setOrToggleSelectedToolbarItem(ShopToolbarIcons.NONE));
}

// TODO: NC-1244 productionalize signup shopping
export function* initializeSignupShopping() {
  try {
    const isInSignupShopping = yield select(isInSignupShoppingTreatment);
    const signupShoppingInitialized = yield select(isSignupShoppingInitialized);

    if (isInSignupShopping && !signupShoppingInitialized) {
      const params = { tags: [CRITICAL_DATA_REQUEST] };
      yield call(fetchAllTagsFlow, params);
      yield call(fetchAllOfferingsFlow, params);
      yield call(fetchAislesFlow, params);
      const boxId = yield select(getSignupBoxId);
      const items = yield select(getSignupVariantQuantities);
      const orderPosit = yield select(getOrderPosit);
      const isPrefilledCartFilled = yield select(getIsPrefilledCartFilled);

      // refetch on revisit. Variant quantity will be populated but order posit would not be
      // if user closes browser and returns.
      // request this even if there are no items, so posit order is never null.
      if (orderPosit === null) {
        yield put({
          type: ORDERS_ACTION_TYPES.FETCH_ORDER_POSIT,
          items,
          boxId,
          isPrefilledCartFilled,
        });
      }

      yield put({ type: ACTION_TYPES.INITIALIZE_SIGNUP_SHOPPING_SUCCEEDED });
    }
  } catch (error) {
    yield put({ type: ACTION_TYPES.INITIALIZE_SIGNUP_SHOPPING_FAILED, error });
  }
  return null;
}

export function* initializeShopping(action) {
  const { reinitializeShopping } = action;
  try {
    const canUserShop = yield select(canUserCustomize);
    const shoppingInitialized = yield select(isShoppingInitialized);

    if ((canUserShop && !shoppingInitialized) || reinitializeShopping) {
      const defaultParams = { tags: [CRITICAL_DATA_REQUEST] };
      yield call(fetchAllTagsFlow, defaultParams);
      yield call(fetchAllOfferingsFlow, {
        ...defaultParams,
        clearAlgoliaCache: reinitializeShopping,
      });
      yield call(fetchAislesFlow, defaultParams);
      yield put({ type: ACTION_TYPES.INITIALIZE_SHOPPING_SUCCEEDED });
    }
  } catch (error) {
    yield put({ type: ACTION_TYPES.INITIALIZE_SHOPPING_FAILED, error });
  }
  return null;
}

export function* initializeCatalogPreviewTool(action) {
  const { data } = action;
  const defaultParams = { tags: [CRITICAL_DATA_REQUEST] };

  try {
    yield call(fetchAllTagsFlow, defaultParams);
    yield call(fetchAllOfferingsFromAPIFlow, {
      ...defaultParams,
      clearExistingVariants: true,
      overrides: {
        fc: data.fcId,
        packDate: data.packDate,
        priceZone: data.priceZoneId,
      },
    });
    yield call(fetchAislesFlow, {
      ...defaultParams,
      overrides: {
        fulfillmentCenterId: data.fcId,
        packDate: data.packDate,
      },
    });
    yield put({ type: ACTION_TYPES.INITIALIZE_CATALOG_PREVIEW_TOOL_SUCCEEDED });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.INITIALIZE_CATALOG_PREVIEW_TOOL_FAILED,
      error: error.message,
    });
  }
}

export function* initializePlus() {
  try {
    const loggedIn = yield select(isLoggedIn);

    // Synchronously wait for Split initialization & get treatment for core global feature flags
    yield call(waitForSplitInitialization);
    if (loggedIn) {
      // Only need the price in advertisements for plus; check if we need to show any widgets
      const needsPlusPrice = yield select(canShowPlusWidget);
      if (needsPlusPrice) {
        // Are we missing the plus price? Fetch it if necessary
        const plusPrice = yield select(getPlusPrice);
        if (!plusPrice) {
          yield put(fetchPlusPrice());
        }
      }
    }

    yield put({ type: ACTION_TYPES.INITIALIZE_PLUS_SUCCEEDED });
  } catch (error) {
    yield put({ type: ACTION_TYPES.INITIALIZE_PLUS_FAILED, error });
  }
}

export function* waitForPlusInitialization() {
  const plusInitialized = yield select(isPlusInitialized);
  if (!plusInitialized) {
    yield take([
      ACTION_TYPES.INITIALIZE_PLUS_SUCCEEDED,
      ACTION_TYPES.INITIALIZE_PLUS_FAILED,
    ]);
  }
}

// TODO: Extract all of these into a customization-specific saga?
function* handleSearchResultChanged() {
  const query = yield select(getSearchQuery);
  const allProductsResultCount = yield select(getSearchResultInAllProducts);
  const filteredSearchResults = yield select(getOrderedOfferingsWithSearch);

  if (query && !filteredSearchResults.length) {
    yield put(userSearchedNoResults({ query, allProductsResultCount }));
  }
}

function* maybeShowDisableRecurringNudgeToast(modalId, visible, params) {
  if (modalId === RECURRING_ITEM_MODAL_ID && !visible) {
    const allowRecurringItemsNudge = yield select(
      shouldAllowRecurringItemsNudge
    );
    if (!allowRecurringItemsNudge && !params.skipNudgeToast) {
      yield put(showDisableRecurringNudgeToast());
    }
  }
}
