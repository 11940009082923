import React from "react";
import { PrismicProvider, PrismicToolbar } from "@prismicio/react";
import { getRepositoryEndpoint, createClient } from "@prismicio/client";

import { config } from "app/config";
import { PRISMIC_REPOSITORY_NAME } from "app/ui/cms/shared/utils/constants";
import linkResolver from "app/ui/cms/shared/utils/linkResolver";
import richTextComponents from "app/ui/cms/shared/utils/richTextComponents";
import PrismicLinkComponent from "app/ui/cms/shared/PrismicLinkComponent";

const accessToken = config.get("third_party.prismic.access_token");
const endpoint = getRepositoryEndpoint(PRISMIC_REPOSITORY_NAME);
const client = createClient(endpoint, { accessToken });

const CMSProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <PrismicProvider
      linkResolver={linkResolver}
      client={client}
      internalLinkComponent={PrismicLinkComponent}
      externalLinkComponent={PrismicLinkComponent}
      richTextComponents={richTextComponents}
    >
      {children}
      <PrismicToolbar repositoryName={PRISMIC_REPOSITORY_NAME} />
    </PrismicProvider>
  );
};

export default CMSProvider;
