import { ReactNativeWebViewMessageType } from "@imperfectproduce/frontend-shared";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isNativeApp as getIsNativeApp } from "app/selectors";
import { initializeShopping } from "app/reducers/ui";

const useFetchOfferingsMessage = () => {
  const dispatch = useDispatch();
  const isNativeApp = useSelector(getIsNativeApp);

  const handleMessage = (message) => {
    if (message.data === ReactNativeWebViewMessageType.FETCH_OFFERINGS) {
      dispatch(initializeShopping({ reinitializeShopping: true }));
    }
  };

  useEffect(() => {
    if (isNativeApp) {
      // iOS requires an event listener on window
      window.addEventListener("message", (message) => handleMessage(message));

      // Android requires an event listener on document
      document.addEventListener("message", (message) => handleMessage(message));
    }

    return () => {
      window.removeEventListener("message", (message) =>
        handleMessage(message)
      );

      document.removeEventListener("message", (message) =>
        handleMessage(message)
      );
    };
  }, []);
};

export default useFetchOfferingsMessage;
