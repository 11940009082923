import {
  track as amplitudeTrack,
  flush as flushAmplitudeBuffer,
  getDeviceId,
} from "@amplitude/analytics-browser";

import reverseRouteLookup from "app/router/reverseRouteLookup";
import routes from "app/router/routes.json";

export const SHOPPING_ROUTES = [
  routes.shopping.url,
  routes.shopAll.url,
  routes.shopProductDetailPage.url,
];

export const LOGGED_OUT_ROUTE_PATHS = [
  routes.login.url,
  routes.forgotPassword.url,
];

// For displaying header banner to logged-out pages with auth actions
export const LOGGED_OUT_SIGNUP_ROUTES = [
  routes.signup.url,
  routes.signupSubscription.url,
  routes.signupQuizWhoDoYouShopFor.url,
  routes.signupQuizFirstDelivery.url,
  routes.signupQuizShoppingWindow.url,
  routes.signupQuizProduce.url,
  routes.signupQuizWhatDoYouBuy.url,
  routes.signupQuizDietaryRestrictions.url,
  routes.signupQuizSummary.url,
  routes.signupAccount.url,
];

export const isPathInUrlList = (path: string, urlList: string[]) => {
  const route = reverseRouteLookup(path);
  if (!route) return false;
  return urlList.includes(route.url);
};

export const BOYSENBERRY_SIGN_UP_ROUTE = "/join";

export const GTM_ID =
  window?.location?.host === "localhost:8080" ? "GTM-W9FX4H8" : "GTM-M9NVN7L";

export const getWhiteLabelSignUpRoute = ({
  boysenberrySignup,
}: { boysenberrySignup?: boolean } = {}) => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const promo = searchParams.get("promo") || searchParams.get("promocode");

  const gtm = window?.google_tag_manager?.[GTM_ID]?.dataLayer;

  /* eslint-disable */
  const utm_campaign = gtm?.get("utm_campaign");
  const utm_content = gtm?.get("utm_content");
  const utm_medium = gtm?.get("utm_medium");
  const utm_source = gtm?.get("utm_source");
  const utm_term = gtm?.get("utm_term");
  const gclid = gtm?.get("gclid");
  const fbclid = gtm?.get("fbclid");
  const deviceId = getDeviceId();

  const params = new URLSearchParams({
    ...(utm_campaign && { utm_campaign }),
    ...(utm_content && { utm_content }),
    ...(utm_medium && { utm_medium }),
    ...(utm_source && { utm_source }),
    ...(utm_term && { utm_term }),
    ...(gclid && { gclid }),
    ...(fbclid && { fbclid }),
    ...(promo && { promo }),
    ...(deviceId && { deviceId }),
  }).toString();
  /* eslint-enable */

  // Redirect ALL users to Boysenberry Signup regardless of Split treatment.
  const route = boysenberrySignup
    ? BOYSENBERRY_SIGN_UP_ROUTE
    : BOYSENBERRY_SIGN_UP_ROUTE;

  return route + (params !== "" ? `?${params}` : "");
};

const BOYSENBERRY_REDIRECT_MSG = "boysenberry signup redirect";

// Redirect ALL users to Boysenberry Signup regardless of Split treatment.
export const trackSignupRedirect = ({
  boysenberrySignup,
}: {
  boysenberrySignup?: boolean;
}) => {
  if (boysenberrySignup) {
    amplitudeTrack(BOYSENBERRY_REDIRECT_MSG);
  } else {
    amplitudeTrack(BOYSENBERRY_REDIRECT_MSG);
  }

  flushAmplitudeBuffer();
};
