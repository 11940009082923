import _pick from "lodash/pick";
import moment from "moment-timezone";
import { createSelector } from "reselect";

import { PRODUCT_LIST_EVENT_LIMIT } from "app/constants";
import * as fromBoxes from "app/selectors/boxes";
import * as fromCatalog from "app/selectors/catalog";
import * as fromCDOfferings from "app/selectors/crossDomain/offerings";
import * as fromCDShopping from "app/selectors/crossDomain/shopping";
import * as fromOfferings from "app/selectors/offerings";
import * as fromActiveOrder from "app/selectors/orderActive";
import * as fromSearch from "app/selectors/search";
import SegmentProductListViewedEvent, {
  EventProduct,
  EventFilter,
} from "app/types/analytics/SegmentProductListViewedEvent";
import TrackUpdateVariantQuantityParams from "app/types/order/TrackUpdateVariantQuantityParams";
import State from "app/types/state";
import Filter from "app/types/state/offerings/Filter";
import Offering from "app/types/state/offerings/Offering";

// simplify which tag/cat fields are sent with analytics
const stripTaxonomy = (filters: Filter[]) => {
  return filters.map((filter) => _pick(filter, ["id", "name"]));
};

// Note - these would be better captured in component state & action creator
// at the time they happened to ensure correctness.
// Though likely, there is no guarantee these values are still
// the same as their original value at the time the action happened.
export const getSelectedFilters = createSelector(
  [fromOfferings.getSelectedTags],
  (selectedTags) => {
    return {
      selectedTags: stripTaxonomy(selectedTags),
    };
  }
);

export const getUpdateVariantQuantityData = createSelector(
  [
    fromActiveOrder.getActiveOrderDeliveryDate,
    fromOfferings.getVariantByVariantId,
    fromSearch.isSearching,
    fromSearch.getSearchQuery,
    fromBoxes.getMapOfBoxes,
    fromCDShopping.getAisleForShelfId,
    getSelectedFilters,
    (_: State, props: TrackUpdateVariantQuantityParams) => props,
  ],
  (
    deliveryDate,
    offering,
    searching,
    searchQuery,
    mapOfBoxes,
    aisleShelfIds,
    appliedFilters,
    props
  ) => {
    const {
      orderId,
      variantId,
      quantity,

      originalItem,
      originalAdhocLine,
      isCollectionAdd,

      source,
      rank,
      // the metadata should contain algolia click analytics for add to cart use case already
      metadata,
    } = props;

    // Prefer the actual price on the line item over the catalog price (they can differ)
    // Defer to catalog offering price if this is a new line item (Add to Cart)
    const price = originalItem ? originalItem.unitPrice : offering?.price;
    const offeringName = originalItem ? originalItem.name : offering?.name;
    const boxId = originalAdhocLine?.metadata?.boxId || null;
    const subscriptionItemId =
      originalAdhocLine?.metadata?.subscriptionItemId || null;

    const boxName = (boxId && mapOfBoxes[boxId]?.displayName) || null;

    const shouldIncludeAisleData = !searching && !!aisleShelfIds.currentAisle;

    return {
      orderId,
      variantId,
      deliveryDate,
      offeringName,
      price,
      quantity,
      source,
      subscriptionItemId,
      boxId,
      boxName,
      ...(metadata && {
        // Micah from Algolia suggests we link a Product Add to the Order Completed algolia event
        // https://www.algolia.com/doc/guides/getting-insights-and-analytics/connectors/segment/reference/events/#order-completed
        index: metadata.index,
        queryID: metadata.queryID,
        // Intentionally adding the objectID twice so that we can potentially
        // support either the Product Added OR the Order Completed event
        // by simply changing the Algolia destination in segment
        objectID: variantId,
        products: [
          {
            objectID: variantId,
          },
        ],
      }),
      ...(rank !== undefined && {
        rank,
        position: rank + 1,
      }),
      ...(!!appliedFilters && appliedFilters),
      ...(isCollectionAdd === true || isCollectionAdd === false
        ? { isCollectionAdd }
        : {}),
      ...(searching && { searching, searchQuery }),
      ...(shouldIncludeAisleData && {
        aisleId: aisleShelfIds.currentAisle?.aisleId,
        aisleName: aisleShelfIds.currentAisle?.name,
        shelfId: aisleShelfIds.currentShelf?.shelfId,
        shelfName: aisleShelfIds.currentShelf?.name,
      }),
    };
  }
);

export const getProductListData = createSelector(
  [
    fromOfferings.getMapOfVariants,
    fromOfferings.getFetchOfferingsTimestamp,
    fromCDOfferings.getOrderedOfferingsByFilter,
    fromSearch.isSearching,
    fromSearch.getSearchQuery,
    fromCDOfferings.getFilteredSearchVariantIds,
    fromOfferings.getSelectedTagIds,
    fromActiveOrder.getActiveOrder,
    fromCDShopping.getActiveAlgoliaData,
  ],
  (
    mapOfVariants,
    offeringsTimestamp,
    orderedOfferingByFilter,
    isSearching,
    searchQuery,
    searchResultVariantIds,
    selectedTagIds,
    activeOrder,
    algoliaData
  ) => {
    if (!algoliaData || !activeOrder) return null;

    const { queryID, index } = algoliaData;

    let totalOOS = 0;
    let products: EventProduct[] = [];

    // handle search first so we can filter results
    if (isSearching && searchResultVariantIds) {
      products = searchResultVariantIds.reduce((acc, variantId, position) => {
        const offering = mapOfVariants[variantId];
        if (offering) {
          // if product doesn't have one of the selected tags/categories, ignore it
          if (selectedTagIds.length > 0) {
            const isInTags = selectedTagIds.some((tagId) => {
              return offering.tags.find((tag) => tag.tagId === tagId);
            });
            if (isInTags === false) return acc;
          }
          if (!offering.hasStock) totalOOS += 1;
          acc.push(pickOfferingForProductList(offering, position));
        }
        return acc;
      }, [] as EventProduct[]);
    } else {
      products = orderedOfferingByFilter.reduce((acc, variantId, position) => {
        const offering = mapOfVariants[variantId];
        // Check for offering
        // https://app.honeybadger.io/projects/63435/faults/71064286#notice-trace
        if (offering) {
          if (!offering.hasStock) totalOOS += 1;
          acc.push(pickOfferingForProductList(offering, position));
        }
        return acc;
      }, [] as EventProduct[]);
    }

    const productsTrimmed = products.slice(0, PRODUCT_LIST_EVENT_LIMIT);
    const productsTrackedOOS = productsTrimmed.filter(
      (p) => p.hasStock === false
    ).length;

    const { orderId, fcId, deliveryWindowId } = activeOrder;
    const data: SegmentProductListViewedEvent = {
      index,
      queryID,
      products: productsTrimmed,
      productsTrackedMax: PRODUCT_LIST_EVENT_LIMIT,
      productsTracked: productsTrimmed.length,
      productsTrackedOOS,
      timestamp: moment(offeringsTimestamp).format("MM/DD/YYYY HH:mm:ss"),
      searchQuery: isSearching ? searchQuery : "",
      hasSelectedTags: selectedTagIds.length > 0,
      selectedTagIds,
      orderId,
      fcId,
      deliveryWindowId,
      totalResults: products.length,
      totalOOS,
    };

    const filters: EventFilter[] = [];
    if (data.selectedTagIds.length) {
      data.selectedTagIds.forEach((tagId) => {
        filters.push({
          type: "tags.tagId",
          value: tagId,
        });
      });
    }
    if (filters.length) {
      data.filters = filters;
    }

    return data;
  }
);

export const getAisleProductListData = createSelector(
  [
    fromOfferings.getMapOfVariants,
    fromOfferings.getFetchOfferingsTimestamp,
    fromOfferings.getSelectedTagIds,
    fromCatalog.getVariantIdsByShelf,
    fromActiveOrder.getActiveOrder,
    fromCDShopping.getAisleShelfIdsFromHash,
    fromCDShopping.getActiveAlgoliaData,
  ],
  (
    mapOfVariants,
    offeringsTimestamp,
    selectedTagIds,
    variantIdsByShelf,
    activeOrder,
    aisleRouteData,
    algoliaData
  ) => {
    if (
      !algoliaData ||
      !aisleRouteData.currentAnchorId ||
      !aisleRouteData.currentAisle ||
      !aisleRouteData.currentShelf ||
      !variantIdsByShelf ||
      !activeOrder
    ) {
      return null;
    }

    const aisle = aisleRouteData.currentAisle;
    const shelf = aisleRouteData.currentShelf;

    const aisleId = aisle?.aisleId;
    const aisleName = aisle?.name;
    const shelfId = shelf?.shelfId;
    const subAisleName = shelf?.name;

    const { queryID, index } = algoliaData;
    const variantIds: string[] =
      (shelfId && variantIdsByShelf[shelfId]) || ([] as string[]);

    let totalOOS = 0;
    let productsTrackedOOS = 0;

    let products: EventProduct[] = [];

    products = variantIds.reduce((acc, variantId, position) => {
      const offering = mapOfVariants[variantId];
      // Check for offering
      // https://app.honeybadger.io/projects/63435/faults/71064286#notice-trace
      if (offering) {
        if (!offering.hasStock) {
          totalOOS += 1;
        }

        if (acc.length < PRODUCT_LIST_EVENT_LIMIT) {
          acc.push(pickOfferingForProductList(offering, position));
          if (!offering.hasStock) {
            productsTrackedOOS += 1;
          }
        }
      }

      return acc;
    }, [] as EventProduct[]);

    const { orderId, fcId, deliveryWindowId } = activeOrder;
    const data: SegmentProductListViewedEvent = {
      timestamp: moment(offeringsTimestamp).format("MM/DD/YYYY HH:mm:ss"),
      aisleName,
      aisleId,
      subAisleName,
      orderId,
      fcId,
      deliveryWindowId,
      totalResults: variantIds.length,
      totalOOS,
      products,
      productsTrackedMax: PRODUCT_LIST_EVENT_LIMIT,
      productsTracked: products.length,
      productsTrackedOOS,
      index,
      queryID,
      selectedTagIds,
      hasSelectedTags: selectedTagIds.length > 0,
      filters: [
        {
          type: "shelves.shelfId",
          value: shelfId,
        },
      ],
    };

    if (selectedTagIds.length) {
      selectedTagIds.forEach((tagId) => {
        // @ts-ignore
        data.filters.push({
          type: "tags.tagId",
          value: tagId,
        });
      });
    }

    return data;
  }
);

const pickOfferingForProductList = (
  offering: Offering,
  index: number
): EventProduct => {
  return {
    objectID: offering.variantId,
    ..._pick(offering, [
      "productId",
      "variantId",
      "name",
      "productName",
      "sku",
      "price",
      "hasStock",
      "stockLevel",
    ]),
    // position: the absolute position of the record. Maps to positions in the Insights event payload. The position starts with 1 (not 0).
    position: index + 1,
  } as EventProduct;
};
