import { call, put } from "redux-saga/effects";

import { resetUser } from "app/api/segment";
import { analyticsReset } from "app/reducers/analytics";

function* resetAnalytics() {
  // Reset segment user
  const anonymousId = yield call(resetUser);
  // Reset analytics in state
  yield put(analyticsReset({ anonymousId }));
}

export default resetAnalytics;
