import { rem } from "polished";
import fonts from "app/styles/fonts";

const root = {
  padding: rem(4),
  borderRadius: rem(4),
  fontWeight: parseInt(fonts.weight.semiBold, 10),
  "&:active": {
    textDecorationLine: "none",
  },
  "&:focus": {
    borderRadius: rem(0),
  },
};

export default root;

export const underlined = {
  textDecorationLine: "underline",
  "&:hover": {
    textDecorationLine: "none",
  },
  "&:focus": {
    textDecorationLine: "underline",
  },
  "&:active": {
    textDecorationLine: "none",
  },
};
