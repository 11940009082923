import * as fromSearch from "app/reducers/search";
import State from "app/types/state";

export function getSearchQuery(state: State) {
  return fromSearch.getSearchQuery(state.search);
}

export function getSearchResultVariantIds(state: State) {
  return fromSearch.getSearchVariantIds(state.search);
}

export function isLoadingNewSearchResults(state: State) {
  return fromSearch.isLoadingNewSearchResults(state.search);
}

export function getSearchAlgoliaData(state: State) {
  return fromSearch.getSearchAlgoliaData(state.search);
}

export function isSearching(state: State) {
  return fromSearch.searchQueryMeetsMinimum(getSearchQuery(state));
}
