// @ts-nocheck
import React from "react";

import ErrorBoundary from "app/ui/shared/ErrorBoundary";

// HOC for wrapping a component with an ErrorBoundary
const withErrorBoundary = (Component, FallbackComponent) => {
  const WrappedComponent = (props) => (
    <ErrorBoundary FallbackComponent={FallbackComponent} {...props}>
      <Component {...props} />
    </ErrorBoundary>
  );
  const name = Component.displayName || Component.name;
  WrappedComponent.displayName = name
    ? `WithErrorBoundary(${name})`
    : "WithErrorBoundary";

  return WrappedComponent;
};

export default withErrorBoundary;
