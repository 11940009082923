// @ts-nocheck
import { call, select, all } from "redux-saga/effects";

import {
  fetchOrderReconciliationActions,
  reconcileOrder,
} from "app/api/userService";
import fetchNextDeliveryFlow from "app/sagas/fetchNextDeliveryFlow";
import { getUserId } from "app/selectors";

/*
Helper functions for use across all sagas
No try catches, let caller handle errors
*/

// fetches _available_ order reconciliation actions to take
// actions are an array of { type: 'CONSTANT', ...data }
export function* getOrderReconciliationActions() {
  const userId = yield select(getUserId);
  return yield call(fetchOrderReconciliationActions, userId);
}

// each processed action returns the action object and it's result (data varies)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* handleProcessedAction({ action, result }) {
  switch (action.type) {
    case "CREATE_ORDER": {
      return yield call(fetchNextDeliveryFlow);
    }
    default:
      return null;
  }
}

function* reconcileOrderFlow(params) {
  const userId = yield select(getUserId);
  const processedActions = yield call(reconcileOrder, userId, params);

  // the endpoint returns an array of actions that were executed, and their result
  yield all(
    processedActions.map((result) => {
      return call(handleProcessedAction, result);
    })
  );

  return processedActions;
}

// execute all available order reconciliation actions, optionally limit to specified types
// limitActionTypes can optionally be supplied as an array of ['TYPE_NAME_CONSTANT']
export function* reconcileOrderByAvailableActions(
  limitActionTypes: string[] | null = null
) {
  return yield call(reconcileOrderFlow, {
    executeAvailableActions: true,
    limitActionTypes,
  });
}

// execute specified order reconciliation actions only
export function* reconcileOrderByActions(actions) {
  return yield call(reconcileOrderFlow, { actions });
}
