import styled from "styled-components";
import Link from "app/ui/shared/Link";
import { rem } from "polished";

interface SCLinkInterface {
  $width: number;
  $height: number;
}

const SCLink = styled(Link)<SCLinkInterface>`
  position: relative;
  display: inline-block;
  width: ${({ $width }) => rem($width)};
  height: ${({ $height }) => rem($height)};
`;

export default SCLink;
