// @ts-nocheck
import React from "react";
import styled from "styled-components";

import { rem, padding } from "polished";
import Button from "app/ui/shared/Button";
import Link from "app/ui/shared/Link";

const Pill = styled(({ id, className, children, onClick }) => {
  return (
    <Button id={id} className={className} onClick={onClick}>
      {children}
    </Button>
  );
})`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.greenDark : "transparent"};
  border-radius: ${rem(5)};
  border: none;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.fontDark};
  font-size: ${rem(14)};
  font-weight: 600;
  ${padding(rem(5), rem(12))};

  &:focus {
    outline: ${rem(3)} solid transparent;
  }
`;

export default Pill;

const PillAsLink = (props) => <Pill {...props} as={Link} />;

export const PillLink = styled(PillAsLink)`
  &:active {
    color: ${({ theme }) => theme.colors.white};
  }
`;
