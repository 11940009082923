import { Button, Typography } from "@material-ui/core";
import { rem } from "polished";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import routes from "app/router/routes.json";

export interface CartBackToAccountProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  useLink?: boolean;
}

const CartBackToAccount: React.FC<CartBackToAccountProps> = ({
  onClick,
  useLink = true,
}) => (
  <Container>
    <Label variant="body2">All done? Your order is automatically saved</Label>
    <StyledButton
      onClick={onClick}
      variant="outlined"
      {...(useLink && {
        component: Link,
        to: routes.account.url,
      })}
    >
      Back to My Account
    </StyledButton>
  </Container>
);

export default CartBackToAccount;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${rem(16)} ${rem(8)};
  background-color: ${({ theme }) => theme.colors.baba};
`;

const Label = styled(Typography)`
  margin-bottom: ${rem(8)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  text-align: center;
`;

const StyledButton = styled(Button)`
  &:active {
    color: ${({ theme }) => theme.colors.peppercorn};
  }
`;
