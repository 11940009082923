import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

export type datadogLogsType = typeof datadogLogs;
export type datadogRumType = typeof datadogRum;

export type Identifier = string | null;

// Using same values as User's API
export enum LogLevel {
  ERROR = "error",
  INFO = "info",
  WARN = "warn",
}

// External Function
export type SetContextFunc = (
  anonymousId?: Identifier,
  userId?: Identifier,
  isImpersonated?: boolean
) => void;

export interface Context {
  anonymousId: Identifier;
  userId: Identifier;
  buildId: string;
  isImpersonated: boolean;
}

// Internal Library Function
export type SetContextLibFunc = (context: Context) => void;

export interface browserDatadogLogs extends datadogLogsType {
  onReady: () => void;
}
export interface browserDatadogRum extends datadogRumType {
  onReady: () => void;
}
