// @ts-nocheck
import { push } from "connected-react-router";
import queryString from "query-string";
import { take, fork, call, put, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/auth";
import { setAuth, removeAuth } from "app/api/authCookie";
import {
  login,
  logout,
  resetPassword,
  requestPasswordResetCode,
  completeNewPassword,
} from "app/api/cognito";
import { checkCustomerExists } from "app/api/misfits";
import { impersonate, saveCredentials } from "app/reducers/auth";
import routes from "app/router/routes.json";
import {
  isLoggedIn,
  getUserId,
  getTokens,
  getInitialLocation,
  getInitialRouteMetadata,
  isImpersonated,
  getRoutingLocation,
  isMaintenanceMode,
  getCognitoUser,
} from "app/selectors";

export default function* rootAuth() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      ACTION_TYPES.LOGOUT,
      ACTION_TYPES.LOGIN,
      ACTION_TYPES.LOGIN_SUCCEEDED,
      ACTION_TYPES.REQUEST_PASSWORD_RESET,
      ACTION_TYPES.RESET_PASSWORD,
      ACTION_TYPES.IMPERSONATE,
      ACTION_TYPES.SAVE_CREDENTIALS,
      ACTION_TYPES.NEW_PASSWORD_REQUIRED,
      ACTION_TYPES.UPDATE_PASSWORD,
    ]);

    switch (action.type) {
      case ACTION_TYPES.LOGIN: {
        yield fork(loginFlow, action);
        break;
      }
      case ACTION_TYPES.REQUEST_PASSWORD_RESET: {
        yield fork(requestPasswordResetFlow, action);
        break;
      }
      case ACTION_TYPES.RESET_PASSWORD: {
        yield fork(resetPasswordFlow, action);
        break;
      }
      case ACTION_TYPES.LOGOUT: {
        const isUnderConstruction = yield select(isMaintenanceMode);
        if (isUnderConstruction) break;
        yield fork(syncAuthToCookie);
        if (action.redirect) yield fork(handlePostLogoutRedirect, action);
        break;
      }
      case ACTION_TYPES.LOGIN_SUCCEEDED: {
        yield fork(syncAuthToCookie);
        if (action.shouldRedirect) yield fork(handlePostLoginRedirect);
        break;
      }
      case ACTION_TYPES.SAVE_CREDENTIALS:
      case ACTION_TYPES.IMPERSONATE: {
        yield fork(syncAuthToCookie);
        break;
      }
      case ACTION_TYPES.UPDATE_PASSWORD: {
        yield call(updatePasswordFlow, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* loginFlow(action) {
  const { email, password, shouldRedirect = true } = action;
  try {
    const customerExistsResponse = yield call(checkCustomerExists, {
      email,
      confirmCognitoExists: true,
    });

    if (
      customerExistsResponse?.data?.exists &&
      customerExistsResponse?.data?.brand !== "imperfect_foods"
    ) {
      throw new Error("Misfits customer");
    }

    // 1. Call Cognito `login` API.
    const response = yield call(login, email, password);

    if (response.newPasswordRequired) {
      yield put({
        ...action,
        type: ACTION_TYPES.NEW_PASSWORD_REQUIRED,
        ...response,
        shouldRedirect,
      });
      return;
    }

    // 2. Cognito login succeeded, server-side navigate to /account.
    window.location.href = "https://www.imperfectfoods.com/account";

    // yield put({
    //   ...action,
    //   type: ACTION_TYPES.LOGIN_SUCCEEDED,
    //   ...response,
    //   shouldRedirect,
    // });
  } catch (response) {
    // eslint-disable-next-line prefer-destructuring
    let failureMessage = action.failureMessage;

    // This is a network issue!  No response from server
    if (response.message === "Misfits customer") {
      failureMessage =
        "You are a member of our sister brand Misfits Market. Please log in at www.misfitsmarket.com";
    } else if (!response.status) {
      failureMessage = "Login issue. Please try again later.";
      // 500 response is for any auth0 issue like being blocked
    } else if (response.status === 500) {
      failureMessage =
        "Oops! We're experiencing some site issues at the moment. Please hang tight!";
    }

    yield put({
      ...action,
      type: ACTION_TYPES.LOGIN_FAILED,
      failureMessage,
      error: response,
    });
  }
}

export function* resetPasswordFlow(action) {
  const { email, confirmationCode, newPassword } = action;
  try {
    yield call(resetPassword, email, confirmationCode, newPassword);
    yield put({
      ...action,
      type: ACTION_TYPES.RESET_PASSWORD_SUCCEEDED,
      successMessage: "New message here",
    });
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.RESET_PASSWORD_FAILED,
      error,
    });
  }
}
export function* requestPasswordResetFlow(action) {
  const { email } = action;
  try {
    yield call(requestPasswordResetCode, email);
    yield put({
      ...action,
      type: ACTION_TYPES.REQUEST_PASSWORD_RESET_SUCCEEDED,
    });
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.REQUEST_PASSWORD_RESET_FAILED,
      error,
    });
  }
}

export function* syncAuthToCookie() {
  const loggedIn = yield select(isLoggedIn);

  if (loggedIn) {
    const userId = yield select(getUserId);
    const tokens = yield select(getTokens);
    const impersonated = yield select(isImpersonated);
    yield call(setAuth, userId, tokens, impersonated);
  } else {
    yield call(logout);
    yield call(removeAuth);
  }
}

const shouldPostLoginRedirect = (metadata) => {
  // Return false for a 404 page (no metadata)
  if (!metadata) return false;

  // Default value for postLoginRedirect is true
  // The routes should opt-out of this feature
  const { postLoginRedirect = true } = metadata;

  return postLoginRedirect;
};

export function* handlePostLoginRedirect() {
  const initialLocation = yield select(getInitialLocation);
  const initialLocationMetadata = yield select(getInitialRouteMetadata);
  const shouldRedirect = yield call(
    shouldPostLoginRedirect,
    initialLocationMetadata
  );

  if (initialLocation && shouldRedirect) {
    const { pathname, search, query, hash } = initialLocation;
    if (pathname) {
      yield put(push({ pathname, search, query, hash }));
    }
  }
}

export function* handlePostLogoutRedirect({ redirect }) {
  yield put(push({ pathname: redirect }));
}

export function* handleCredentials() {
  const location = yield select(getRoutingLocation);
  if (location && location.pathname === routes.login.url && location.hash) {
    const vals = queryString.parse(location.hash);
    if (vals.token && vals.userId) {
      if (vals.impersonating) {
        yield put(impersonate(vals));
      } else {
        yield put(saveCredentials(vals));
      }
    }
  }
}

export function* updatePasswordFlow(action) {
  const { newPassword } = action;
  const user = yield select(getCognitoUser);
  try {
    yield call(completeNewPassword, user, newPassword);

    // Cognito login succeeded as a result of `completeNewPassword`; navigate to /account.
    window.location.href = "https://www.imperfectfoods.com/account";
  } catch (response) {
    // eslint-disable-next-line prefer-destructuring
    let failureMessage = action.failureMessage;

    // This is a network issue!  No response from server
    if (!response.status) {
      failureMessage = "Login issue. Please try again later.";

      // 500 response is for any auth0 issue like being blocked
    } else if (response.status === 500) {
      failureMessage =
        "Oops! We're experiencing some site issues at the moment. Please hang tight!";
    }
    yield put({
      ...action,
      type: ACTION_TYPES.LOGIN_FAILED,
      failureMessage,
      error: response,
    });
  }
}
