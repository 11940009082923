import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { rem } from "polished";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

export type SubaisleListItemData = {
  active: boolean;
  disabled: boolean;
  id: string;
  label: string;
  value: number | null;
  endpoint: string | null;
} & Pick<LinkProps, "to">;

type MUIListItemProps = Pick<ListItemProps, "className">;
type ReactRouterLinkProps = Pick<LinkProps, "to">;
type ThirdPartyProps = MUIListItemProps & ReactRouterLinkProps;

export interface SubaisleListItemProps extends ThirdPartyProps {
  active: boolean;
  activeRef: React.RefObject<HTMLAnchorElement> | null;
  disabled: boolean;
  label: string;
  onClick?: () => void;
  value: number | null;
}

const SubaisleListItem: React.FC<SubaisleListItemProps> = ({
  active,
  activeRef,
  className,
  label,
  onClick = () => {},
  to,
  value,
}) => {
  const preventFocusClick = (e: React.MouseEvent) => e.preventDefault();

  return (
    <StyledListItem
      button
      className={className}
      component={Link}
      disableRipple={false}
      onMouseDown={preventFocusClick}
      onClick={onClick}
      ref={activeRef}
      to={to}
    >
      <Label
        primary={label}
        primaryTypographyProps={{ variant: "caption" }}
        $active={active}
      />
      {typeof value === "number" && (
        <Value
          primary={` (${value})`}
          primaryTypographyProps={{ variant: "caption" }}
          $active={active}
        />
      )}
    </StyledListItem>
  );
};

export default SubaisleListItem;

const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${rem(8)};

  &:hover,
  &:active {
    color: unset;
    background-color: unset;
  }

  &:hover:not(.Mui-disabled) span {
    color: ${({ theme }) => theme.colors.beet};
  }

  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    outline: none;
    background-color: unset;
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }

  .MuiTouchRipple-root {
    color: ${({ theme }) => theme.colors.pink};
  }
` as typeof ListItem;

// `as typeof ListItem` is required to utilize the
// `component` prop on `StyledListItem`
// https://github.com/mui-org/material-ui/issues/13921#issuecomment-484133463

const Label = styled(ListItemText)<{ $active: boolean }>`
  flex-grow: 0;
  display: flex;
  align-items: center;
  margin: ${rem(0)};

  .MuiListItemText-primary {
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};

    ${({ $active, theme }) =>
      $active &&
      `
      color: ${theme.colors.beet};
    `}
  }
`;

const Value = styled(ListItemText)<{ $active: boolean }>`
  flex-grow: 0;
  flex-shrink: 0;
  margin: ${rem(0)};

  .MuiListItemText-primary {
    white-space: pre;
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};

    ${({ $active, theme }) =>
      $active &&
      `
      color: ${theme.colors.beet};
    `}
  }
`;
