// @ts-nocheck
import _map from "lodash/map";
import React from "react";

import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import ORDERS_ACTION_TYPES from "app/actionTypes/orders";
import TOAST_ACTION_TYPES from "app/actionTypes/toast";
import Checkmark from "app/images/Checkmark.svg";
import MyTastePreferencesToast from "app/ui/account/Toast/MyTastePreferencesToast";

export const initialState = {
  content: null,
  options: undefined,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ORDERS_ACTION_TYPES.ADD_BOXES_TO_ORDER_SUCCEEDED: {
      if (
        action.previousOrder.lineItems.length < action.order.lineItems.length
      ) {
        const boxNames = _map(action.boxes, "display.displayName").join(" / ");
        return {
          ...state,
          content: `${boxNames} items have been added to your current order!`,
        };
      }
      return state;
    }

    case ACCOUNT_ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST_SUCCEEDED: {
      if (action.skipToast) {
        return state;
      }
      return {
        ...state,
        content: (
          <span>You added 1 item to your &ldquo;Never&rdquo; list!</span>
        ),
      };
    }

    case ACCOUNT_ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS_SUCCEEDED: {
      if (action.skipToast) {
        return state;
      }
      const message = action.name ? (
        <>
          <Checkmark />
          <div>
            <strong>{action.name}</strong> added to every order!
          </div>
        </>
      ) : (
        <>You added 1 item to your Recurring list!</>
      );
      return {
        ...state,
        content: message,
      };
    }

    case TOAST_ACTION_TYPES.SHOW_DISABLE_RECURRING_MODAL_TOAST: {
      return {
        ...state,
        content: <MyTastePreferencesToast />,
      };
    }
    default:
      return initialState;
  }
}

export const getContent = (state) => state.content;
export const getOptions = (state) => state.options;
export const showDisableRecurringNudgeToast = (params) => {
  return {
    type: TOAST_ACTION_TYPES.SHOW_DISABLE_RECURRING_MODAL_TOAST,
    params,
  };
};
