import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { breakpointsNonScaling, sizes } from "app/styles/breakpoints";
import { SIDE_NAV_WIDTH } from "app/ui/shopping/Navigation/SideNav";
import Carousel from "app/ui/shopping/FirstTimeCarousel/Carousel";
import Slide from "app/ui/shopping/FirstTimeCarousel/DesktopCarousel/SlideDesktop";
import CarouselContent from "app/ui/shopping/FirstTimeCarousel/carouselContents";

export interface DesktopCarouselProps {
  orderNumber: number;
}

// This component is used for tablet & desktop
const DesktopCarousel: React.FC<DesktopCarouselProps> = ({
  orderNumber = 0,
}) => {
  const items = [...CarouselContent[orderNumber]];
  const slides = items.map((content) => (
    <Slide
      key={`Slide-${orderNumber}-${content.headLine.slice(4)}`}
      {...content}
    />
  ));

  return (
    <Container>
      <InnerContainer>
        <Carousel>{slides}</Carousel>
      </InnerContainer>
    </Container>
  );
};

export default DesktopCarousel;

const Container = styled.div`
  ${breakpointsNonScaling.lg`
    padding-top: ${rem(16)};
  `}

  ${breakpointsNonScaling.xl`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${rem(48)};
    padding-bottom: ${rem(24)};
  `}
`;

const InnerContainer = styled.div`
  ${breakpointsNonScaling.lg`
    padding: 0 ${rem(16)};
  `}

  ${breakpointsNonScaling.xl`
    width: 100%;
    max-width: ${rem(sizes.xxxl)};
    padding: 0 ${rem(48)} 0 ${SIDE_NAV_WIDTH + 64}px;
  `};
`;

export interface DesktopCarouselToken {
  component: typeof DesktopCarousel;
  componentProps: DesktopCarouselProps;
  data: {};
  listItemHeight: number;
}

export const getDesktopCarouselToken = (
  props: DesktopCarouselProps
): DesktopCarouselToken => ({
  component: DesktopCarousel,
  componentProps: props,
  data: {},
  listItemHeight: 320,
});
