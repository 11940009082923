import { select } from "redux-saga/effects";

import { KustomerAPIListenerEvents } from "app/types/api/kustomer";
import { getUserEmail } from "app/selectors/account";

export default function* describeCustomerFlow() {
  const email: string = yield select(getUserEmail);

  const onOpenHandler = () => {
    window.Kustomer?.describeCustomer({
      attributes: {
        emails: [email],
      },
    });
  };

  if (window && window.Kustomer) {
    // in theory we should removeListener when logging out, but logout causes a page refresh
    // which destroys any active event listeners, so no need
    window.Kustomer.addListener(KustomerAPIListenerEvents.OPEN, onOpenHandler);
  }
}
