import React from "react";
import { withProps } from "recompose";
import styled from "styled-components";
import { rem } from "polished";
import Box from "@material-ui/core/Box";

import theme from "app/styles/theme";
import routes from "app/router/routes.json";
import breakpoints from "app/styles/breakpoints";
import grid from "app/styles/grid";
import StyledAddress from "app/ui/footer/Address";
import FooterNav from "app/ui/footer/FooterNav";
import SocialNav from "app/ui/footer/SocialNav";
import Link from "app/ui/shared/Link";
import Logo from "app/ui/shared/Logo";
import HideOnNative from "app/ui/global/HideOnNative";
import BCert from "app/images/b-cert";

interface FooterProps {
  year: number;
  onlyShowCopyright?: boolean;
}

export const Footer: React.FC<FooterProps> = React.memo(
  ({ year, onlyShowCopyright }) => {
    return (
      <HideOnNative>
        <StyledFooter role="contentinfo" data-testid="original-footer">
          {onlyShowCopyright || (
            <>
              <StyledSocialNav />
              <FooterWrapper>
                <FooterNav />
                <LogoWrapper>
                  <ImperfectLogo>Imperfect Foods home</ImperfectLogo>
                  <Box width={rem(34)}>
                    <BCert fillColor={theme.colors.beet} />
                  </Box>
                </LogoWrapper>
                <StyledAddress />
              </FooterWrapper>
            </>
          )}
          <Copyright>
            Imperfect Foods &copy; {year}. All Rights Reserved
            <br />
            <Link to={routes.terms.url}>Terms and Conditions</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to={routes.privacy.url}>Privacy Policy</Link>
            <br />
            <a href="https://privacy.misfitsmarket.com/">
              Do Not Sell My Personal Information
            </a>
          </Copyright>
        </StyledFooter>
      </HideOnNative>
    );
  }
);

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.white};

  a {
    color: ${({ theme }) => theme.colors.purple};
  }
`;

const FooterWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayLighter};
  padding: ${rem(24)} ${rem(16)};

  a {
    display: block;
    color: ${({ theme }) => theme.colors.beet};
    padding: ${rem(0)} ${rem(5)};

    &:focus {
      outline: ${rem(1)} solid
        ${({ theme }) => {
          // TODO: NC-1315 Fix broken theme references
          // @ts-ignore
          return theme.borders.beet;
        }};
      box-shadow: ${({ theme }) => theme.boxShadows.focus};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.eggplant};
      text-decoration: underline;
    }
  }
`;

const ImperfectLogo = styled(Logo.Vertical)`
  margin-right: ${rem(30)};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(60)};
`;

const StyledSocialNav = styled(SocialNav)`
  ${grid.cell.full}
  ul {
    display: flex;
    justify-content: flex-start;

    li {
      margin-right: 0.75em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  ${breakpoints.lg`
    ${grid.cell.span(2 / 6)};
  `}
  ${breakpoints.xl`
    ${grid.cell.span(3 / 12)};
  `}
`;

const Copyright = styled.p`
  ${grid.cell.full}

  ${({ theme }) => `
    background-color: ${theme.colors.grayDark};
    color: ${theme.colors.marshmallow};
  `};
  font-size: ${rem(12)};
  line-height: 1.4;
  /* Something external is making this extra whitespace at the bottom: */
  margin-bottom: ${rem(-40)};
  padding: ${rem(32)} ${rem(24)} ${rem(65)} ${rem(24)};
  text-align: center;

  a {
    ${({ theme }) => `
      color: ${theme.colors.marshmallow};
      font-weight: ${theme.fonts.weight.bold};

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    `};
  }

  ${breakpoints.md`
    padding: ${rem(32)} ${rem(24)};
  `}
`;

const enhance = withProps({ year: new Date().getFullYear() });

export default enhance(Footer);
