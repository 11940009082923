import { useSelector } from "react-redux";

import { getShelvesAvailability } from "app/selectors";
import State from "app/types/state";
import { SubaisleListItemData } from "app/ui/shopping/Navigation/SubAisle/SubaisleListItem";

const useFilterOutEmptySmartShelves = (
  subaisles: SubaisleListItemData[] | undefined
) => {
  const allShelfAvailability: { [key: string]: boolean } = useSelector(
    (state: State) => {
      return getShelvesAvailability(state, {
        shelfIds: subaisles?.map((shelf) => shelf.id),
      });
    }
  );

  return (
    subaisles?.filter((subaisle) => {
      if (subaisle.endpoint) {
        return allShelfAvailability[subaisle.id];
      }
      return true;
    }) ?? []
  );
};

export default useFilterOutEmptySmartShelves;
