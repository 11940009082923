import React from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import RestrictedRoute from "app/router/RestrictedRoute";
import routes from "app/router/routes.json";
import normalizePath from "app/router/normalizePath";

import loadable from "app/ui/global/loadable";
import {
  isMaintenanceMode,
  splitTreatmentsInitialized,
  isInLoggedInHomepageTreatment,
  canUserCustomize,
  isOnCMSPage,
} from "app/selectors";
import SmartRoute from "app/router/SmartRoute";
import Header from "app/ui/universal/header";
import Footer from "app/ui/universal/footer";
import LoadingSkeleton from "app/router/universal/LoadingSkeleton";

// Lazy-load routes
const UnderConstruction = loadable(() => import("../../ui/UnderConstruction"));

const CMSPages = loadable(
  () => import("../../ui/cms"),
  false,
  <LoadingSkeleton />
);

// Revamp routes
const Home = loadable(
  () => import("../../ui/universal/home"),
  false,
  <LoadingSkeleton />
);

const HowItWorks = loadable(
  () => import("../../ui/universal/howItWorks"),
  false,
  <LoadingSkeleton />
);

const FoodWaste = loadable(
  () => import("../../ui/universal/foodWaste"),
  false,
  <LoadingSkeleton />
);

const OurFood = loadable(
  () => import("../../ui/universal/ourFood"),
  false,
  <LoadingSkeleton />
);

export const universalRoutePaths = [
  routes.underConstruction.url,
  routes.main.url,
  routes.ourFood.url,
  routes.how_it_works.url,
  routes.foodWaste.url,
  "/preview",
  "/:uid",
];

const UniversalRoutes = () => {
  const splitTreatmentsLoaded = useSelector(splitTreatmentsInitialized);

  // TO DO: Productionalize in NC-994
  const isInLoggedInHomepageExp = useSelector(isInLoggedInHomepageTreatment);
  const isInCustoWindow = useSelector(canUserCustomize);

  const onCMSPage = useSelector(isOnCMSPage);

  const { pathname } = useLocation();
  const normalizedPath = normalizePath(pathname);
  const isOnMaintenanceModeRoute =
    normalizedPath === routes.underConstruction.url;

  // matches the loading state we use in the logged out homepage
  if (!splitTreatmentsLoaded) {
    return null;
  }

  return (
    <>
      {!onCMSPage && <Header />}
      <Switch>
        <RestrictedRoute
          exact
          path={routes.underConstruction.url}
          selector={isMaintenanceMode}
          component={UnderConstruction}
          overrideBoysenberryRedirect
        />
        <SmartRoute exact path={routes.main.url}>
          {isInLoggedInHomepageExp ? (
            <Redirect
              to={isInCustoWindow ? routes.shopping.url : routes.account.url}
            />
          ) : (
            <Home />
          )}
        </SmartRoute>
        <SmartRoute exact path={routes.ourFood.url} component={OurFood} />
        <SmartRoute
          exact
          path={routes.how_it_works.url}
          component={HowItWorks}
        />
        <SmartRoute exact path={routes.foodWaste.url} component={FoodWaste} />
        {/* CMS Landing Page and Content Pages (current and legacy) */}
        {/* Note: no path – matches all remaining routes (catch all) */}
        <SmartRoute component={CMSPages} />
      </Switch>
      {!onCMSPage && <Footer shouldShowBeetBox={!isOnMaintenanceModeRoute} />}
    </>
  );
};

export default UniversalRoutes;
