import { Backdrop, Dialog } from "@material-ui/core";
import React, { useCallback } from "react";
import styled from "styled-components";

import { getImageURL } from "app/ui/global/utils";
import ConfirmationDialog from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { hideAppUpdateRequiredWarningModal } from "app/reducers/ui";
import { isAppUpdateRequiredWarningModalVisible } from "app/selectors";
import { getStoreURL } from "app/utils/mobile";

const StyledHeaderText = styled.span`
  color: ${({ theme }) => theme.colors.peppercorn};
`;

const StyledSubcopyText = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  color: ${({ theme }) => theme.colors.peppercorn};
`;

const StyledImage = styled.img`
  max-height: 112px;
`;

const AppUpdateRequiredModal = () => {
  const dispatch = useDispatch();

  const handleUpdateButtonClick = () => {
    window.open(getStoreURL(), "_blank");
    dispatch(hideAppUpdateRequiredWarningModal());
  };

  const hideRequiredUpdateModal = useCallback(() => {
    dispatch(hideAppUpdateRequiredWarningModal());
  }, []);

  const isModalVisible = useSelector(isAppUpdateRequiredWarningModalVisible);

  return (
    <>
      <Dialog
        BackdropComponent={Backdrop}
        onClose={hideRequiredUpdateModal}
        open={isModalVisible}
      >
        <ConfirmationDialog
          headerText={
            <StyledHeaderText>
              Keep fresh, update your app today
            </StyledHeaderText>
          }
          overlineText="New Version"
          primaryButtonText="Update"
          secondaryButtonText="Not now"
          topContent={
            <StyledImage
              src={getImageURL(
                "GooglyStrawberry.png",
                "v1659100938/website-assets"
              )}
              alt="top-image"
            />
          }
          subcopyText={
            <StyledSubcopyText>
              A new version of this app is available and we recommend updating
              for the sweetest experience.
            </StyledSubcopyText>
          }
          onPrimaryButtonClick={handleUpdateButtonClick}
          onSecondaryButtonClick={hideRequiredUpdateModal}
          showClearButton={false}
        />
      </Dialog>
    </>
  );
};

export default AppUpdateRequiredModal;
