import { Link, LinkProps } from "@material-ui/core";
import _omit from "lodash/omit";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { rem } from "polished";
import styled from "styled-components";
import colorPrimitives from "app/styles/colors";
import { colorUtils } from "app/styles/utils";

type CustomLinkAddOnProps = {
  colorOverride?: "beet" | "peppercorn" | "taro" | "marshmallow";
  component?: React.ElementType;
  size?: "small" | "medium" | "large";
};

type CustomLinkStyleProps = {
  colorOverride: "beet" | "peppercorn" | "taro" | "marshmallow";
  component?: React.ElementType;
  size: "small" | "medium" | "large";
};

export type CustomLinkProps = LinkProps & CustomLinkAddOnProps;
export type DarkBackgroundLinkProps = LinkProps &
  Omit<CustomLinkAddOnProps, "colorOverride" | "background">;

const CustomLinkBase = <C extends React.ElementType>(
  props: LinkProps<C, CustomLinkAddOnProps>
) => {
  return <Link {..._omit(props, ["colorOverride", "size", "background"])} />;
};

const CustomLinkStyled = styled(CustomLinkBase)<
  LinkProps & CustomLinkStyleProps
>`
  background-color: transparent;
  color: ${({ colorOverride }) => colorPrimitives[colorOverride]};
  height: ${(props) => (props.size === "small" ? rem(16) : rem(24))};
  font-size: ${(props) => {
    switch (props.size) {
      case "small":
        return rem(13);
      case "large":
        return rem(16);
      case "medium":
      default:
        return rem(14);
    }
  }};
  line-height: ${(props) => (props.size === "small" ? rem(16) : rem(24))};
  vertical-align: inherit;

  &:hover {
    background-color: ${({ colorOverride }) =>
      colorUtils.alpha(colorPrimitives[colorOverride], 0.05)};
  }

  &:active {
    color: ${({ colorOverride }) => colorPrimitives[colorOverride]};
  }

  &:focus {
    border: 1px solid
      ${({ colorOverride }) =>
        colorOverride === "marshmallow"
          ? colorPrimitives.marshmallow
          : colorPrimitives.beet};
    outline: ${rem(4)} solid
      ${({ colorOverride }) =>
        colorUtils.alpha(
          colorOverride === "marshmallow"
            ? colorPrimitives.marshmallow
            : colorPrimitives.beet,
          0.2
        )};
  }

  &.Mui-focusVisible {
    outline-offset: 0;
  }
`;

export const DarkBackgroundLink = <C extends React.ElementType>(
  props: LinkProps<
    C,
    Omit<CustomLinkAddOnProps, "colorOverride" | "background">
  >
) => {
  return <CustomLink colorOverride="marshmallow" {...props} />;
};

const CustomLink = <C extends React.ElementType>(
  props: LinkProps<C, CustomLinkAddOnProps>
) => {
  const {
    colorOverride = "beet",
    size = "medium",
    component = RouterLink,
  } = props;

  const propsWithDefaults = { ...props, colorOverride, component, size };
  delete propsWithDefaults.color;
  delete propsWithDefaults.variant;
  return <CustomLinkStyled {...propsWithDefaults} />;
};

export default CustomLink;
