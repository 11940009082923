import { rem } from "polished";
import React, { useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  getAisleShelfIdsFromHash,
  getShopToolbarSelectedItem,
  isInMobileAisleNavTreatment,
  isNativeApp,
  isOnSinglePageCatalog,
  isShoppingReady as getIsShoppingReady,
} from "app/selectors";
import breakpoints from "app/styles/breakpoints";
import useWindowEvent from "app/ui/hooks/useWindowEvent";
import ImperfectPlusBanner from "app/ui/shopping/ImperfectPlusBanner";
import { SearchProps } from "app/ui/shopping/Search/SearchProps";
import { ShopToolbarIcons } from "app/ui/shopping/Navigation/ShopToolbar";
import MobileNavigation from "app/ui/shopping/MobileNavigation";
import MobileNavigationScrolling from "app/ui/shopping/MobileNavigationScrolling";
import { isTouchDevice } from "app/utils/mobile";

interface ShoppingNavigation {
  enabled: boolean;
  shopAllActive: boolean;
  renderMobileNavigation: boolean;
  showPlusBanner: boolean;
  isMobile: boolean;
  searchProps: SearchProps;
}

const ShoppingNavigation: React.FC<ShoppingNavigation> = ({
  enabled,
  renderMobileNavigation,
  showPlusBanner,
  searchProps,
  shopAllActive,
}) => {
  const inMobileAisleNavTreatment = useSelector(isInMobileAisleNavTreatment);
  const isNative = useSelector(isNativeApp);
  const isShoppingReady = useSelector(getIsShoppingReady);
  const { currentAnchorId, currentAisle } = useSelector(
    getAisleShelfIdsFromHash
  );
  const selectedToolbarItem = useSelector(getShopToolbarSelectedItem);
  const onSinglePageCatalog = useSelector(isOnSinglePageCatalog);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  const [aislesDropdownOpen, setAislesDropdown] = useState(false);
  const [shelvesDropdownOpen, setShelvesDropdown] = useState(false);
  const [stickyHeaderBottom, setStickyHeaderBottom] = useState<
    number | undefined
  >(0);

  const toggleAislesDropdown = () => {
    if (shelvesDropdownOpen) setShelvesDropdown(false);
    setAislesDropdown(!aislesDropdownOpen);
    setStickyHeaderBottom(
      stickyHeaderRef.current?.getBoundingClientRect().bottom
    );
  };
  const toggleShelvesDropdown = () => {
    if (aislesDropdownOpen) setAislesDropdown(false);
    setShelvesDropdown(!shelvesDropdownOpen);
    setStickyHeaderBottom(
      stickyHeaderRef.current?.getBoundingClientRect().bottom
    );
  };

  const checkStickyHeaderPos = useCallback(() => {
    const newPos = stickyHeaderRef.current?.getBoundingClientRect().bottom;
    if (newPos !== stickyHeaderBottom) {
      setStickyHeaderBottom(newPos);
    }
  }, []);

  useWindowEvent("resize", checkStickyHeaderPos);

  if (!enabled) return null;

  // hide the mobile nav when a search is in progress
  const hideMobileNavOnSearch =
    searchProps.isSearching ||
    searchProps.showNoSearchResults ||
    selectedToolbarItem === ShopToolbarIcons.SEARCH;

  const hideOnSinglePageCatalog = onSinglePageCatalog;

  const shopToolbarOpen =
    selectedToolbarItem === ShopToolbarIcons.SEARCH ||
    selectedToolbarItem === ShopToolbarIcons.FILTER;

  return (
    <>
      {showPlusBanner && !hideOnSinglePageCatalog && <ImperfectPlusBanner />}
      {renderMobileNavigation && isShoppingReady && (
        <StickyHeader ref={stickyHeaderRef} $shopToolbarOpen={shopToolbarOpen}>
          {!hideMobileNavOnSearch &&
            (inMobileAisleNavTreatment && (isNative || isTouchDevice()) ? (
              <MobileNavigationScrolling currentAisle={currentAisle} />
            ) : (
              <MobileNavigation
                toggleAislesDropdown={toggleAislesDropdown}
                toggleShelvesDropdown={toggleShelvesDropdown}
                aislesDropdownOpen={aislesDropdownOpen}
                shelvesDropdownOpen={shelvesDropdownOpen}
                currentAnchorId={currentAnchorId}
                currentAisle={currentAisle}
                shopAllActive={shopAllActive}
                stickyHeaderBottom={stickyHeaderBottom}
              />
            ))}
        </StickyHeader>
      )}
    </>
  );
};

const StickyHeader = styled.div<{ $shopToolbarOpen: boolean }>`
  position: sticky;
  top: ${({ theme }) => rem(theme.layout.headerHeightMobile - 1)};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: ${({ theme }) => theme.layout.zIndex.stickySubHeader};

  ${breakpoints.xl`
    top: ${({ theme }) => rem(theme.layout.headerHeight - 1)};
  `}

  ${({ theme, $shopToolbarOpen }) =>
    $shopToolbarOpen &&
    `
    top: ${rem(theme.layout.headerHeightMobile + 45)};
  `}
`;

export default ShoppingNavigation;
