// @ts-nocheck
import delay from "@redux-saga/delay-p";
import moment from "moment-timezone";
import { take, fork, call, select, cancel } from "redux-saga/effects";

import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import ACTION_TYPES from "app/actionTypes/analytics";
import AUTH_ACTION_TYPES from "app/actionTypes/auth";
import FEATURE_ACTION_TYPES from "app/actionTypes/featureIntro";
import ORDER_ACTION_TYPES from "app/actionTypes/orders";
import ROUTING_ACTION_TYPES from "app/actionTypes/routing";
import SEARCH_ACTION_TYPES from "app/actionTypes/search";
import SIGNUP_ACTION_TYPES from "app/actionTypes/signup";
import UI_ACTION_TYPES from "app/actionTypes/ui";
import FRIENDBUY_ACTION_TYPES from "app/actionTypes/friendbuy";
import { trackEvent, identifyUser } from "app/api/segment";

import trackDialogActions from "app/sagas/analytics/trackDialogActions";
import trackModalActions from "app/sagas/analytics/trackModalActions";
import trackUpdateVariantQuantity from "app/sagas/analytics/trackUpdateVariantQuantity";
import triageRouteChangeFlow from "app/sagas/analytics/triageRouteChangeFlow";
import triagePageChangedFlow from "app/sagas/analytics/triagePageChangedFlow";
import initializeAnalytics from "app/sagas/analytics/initializeAnalytics";
import trackHoldPlaced from "app/sagas/analytics/trackHoldPlaced";
import {
  triageSkipOrder,
  triageSkipFutureOrder,
} from "app/sagas/analytics/trackSkipDeliveryClicked";
import resetAnalytics from "app/sagas/analytics/resetAnalytics";
import { waitForInitialization } from "app/sagas/waitForInitialization";
import { waitForShoppingInitialization } from "app/sagas/waitForShoppingInitialization";
import {
  trackAppliedReferral,
  trackSetReferral,
  trackCapturedReferral,
  trackFetchReferralCouponSucceeded,
  trackAppliedReferralFailed,
  trackApplyAttributionSucceeded,
  trackApplyAttributionFailed,
} from "app/sagas/analytics/trackFriendbuy";
import {
  trackCancellationReasonsChosenFlow,
  trackCancellationWinbackViewedFlow,
  trackCancelSubscriptionDeclinedTerms,
  trackCancelSubscription,
} from "app/sagas/analytics/trackSubscriptionCancel";
import {
  isInitialized,
  getActiveOrderId,
  getUser,
  isLoggedIn,
  getSignupCurrentStep,
  getSignupPreviousStep,
  getSignupValues,
  getSignupStepForPreviousRoute,
  getSignupSelectedSecondaryBoxes,
  getMapOfBoxes,
  isSignupRouteAllowed,
  canUserSignup,
  ACTIVE_TC_MESSAGE_ID,
  isUserOnInternalBillingHold,
  isUserOnInternalSubtotalHold,
  isUserActive,
  getUserDeliveryWindow,
  getCart,
  getActiveWindow,
  getActiveOrderPackDate,
  getOfferingByVariantId,
  getFetchOfferingsTimestamp,
  getNextDelivery,
  canUserCustomize,
  getUserId,
  getActiveAlgoliaData,
  getShelfVariantIds,
} from "app/selectors";
import { getShelfInfoFromShelfId } from "app/selectors/shelves";
import {
  getSelectedFilters,
  getProductListData,
} from "app/selectors/crossDomain/analytics";
import { SignupSteps } from "app/types/state/signup";
import { FullWindowData } from "app/types/state/deliveries/deliveries";
import { getImageURL } from "app/ui/global/utils";

import mapUserTraits from "app/utils/mapUserTraits";

export default function* rootAnalytics() {
  yield fork(initializeAnalytics, { isFirstRun: true });
  let routeTask;
  let searchTask;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      ACTION_TYPES.SDK_RESET,

      ROUTING_ACTION_TYPES.LOCATION_CHANGED,
      ROUTING_ACTION_TYPES.PAGE_CHANGED,

      AUTH_ACTION_TYPES.LOGIN_SUCCEEDED,
      AUTH_ACTION_TYPES.LOGOUT,

      UI_ACTION_TYPES.INITIALIZE_SUCCEEDED,
      UI_ACTION_TYPES.BOOTSTRAP_COMPLETE,
      UI_ACTION_TYPES.UPDATE_MODAL,
      UI_ACTION_TYPES.USER_FOCUSED_SEARCH_BAR,
      UI_ACTION_TYPES.USER_SEARCHED_NO_RESULTS,
      UI_ACTION_TYPES.NEVER_LIST_SUGGESTIONS_SHOWN,
      UI_ACTION_TYPES.NEVER_LIST_SUGGESTIONS_HIDDEN,
      UI_ACTION_TYPES.TOGGLE_CART,
      UI_ACTION_TYPES.REPORT_ISSUE_BUTTON_CLICKED,
      UI_ACTION_TYPES.GO_TO_SHOPPING_CLICKED,
      UI_ACTION_TYPES.REFER_A_FRIEND_DESKTOP_CLICKED,
      UI_ACTION_TYPES.ACCOUNT_MENU_OPENED,
      UI_ACTION_TYPES.MORE_ABOUT_IMPERFECT_CLICKED,
      UI_ACTION_TYPES.BACK_TO_TOP_CLICKED,
      UI_ACTION_TYPES.AISLE_CLICKED,
      UI_ACTION_TYPES.AISLE_SHELF_CLICKED,
      UI_ACTION_TYPES.OFFERING_CARD_CLICKED,
      UI_ACTION_TYPES.OUR_FOOD_AISLE_CLICKED,
      UI_ACTION_TYPES.CTA_CLICKED,
      UI_ACTION_TYPES.SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS,
      UI_ACTION_TYPES.RECIPE_PAGE_OPENED,
      UI_ACTION_TYPES.CLEAR_DIALOG,
      UI_ACTION_TYPES.SET_DIALOG,
      UI_ACTION_TYPES.ACCOUNT_NEXT_STEPS_CLICKED,
      UI_ACTION_TYPES.FIRST_TIME_SHOPPING_CAROUSEL_SLIDE_CHANGED,
      UI_ACTION_TYPES.VIDEO_END,
      UI_ACTION_TYPES.VIDEO_LOAD_START,
      UI_ACTION_TYPES.VIDEO_ERROR,
      UI_ACTION_TYPES.VIDEO_PLAY,
      UI_ACTION_TYPES.VIDEO_PLAYING,

      FEATURE_ACTION_TYPES.SET_INTRO_COMPLETED,

      ORDER_ACTION_TYPES.UPDATE_VARIANT_QUANTITY_SUCCEEDED,
      ORDER_ACTION_TYPES.UPDATE_VARIANT_QUANTITY_FAILED,
      ORDER_ACTION_TYPES.CREATE_AD_HOC_ORDER_SUCCEEDED,
      ORDER_ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_SUGGESTIONS,

      SIGNUP_ACTION_TYPES.SIGNUP_STARTED,
      SIGNUP_ACTION_TYPES.SIGNUP_ENDED,
      SIGNUP_ACTION_TYPES.SIGNUP_ABANDONED,
      SIGNUP_ACTION_TYPES.CURRENT_STEP_CHANGED,
      SIGNUP_ACTION_TYPES.STEP_COMPLETE,
      SIGNUP_ACTION_TYPES.SET_SIGNUP_VALUES,
      SIGNUP_ACTION_TYPES.SET_DELIVERY_DETAILS,
      SIGNUP_ACTION_TYPES.SET_PAYMENT_DETAILS,
      SIGNUP_ACTION_TYPES.QUIZ_STEP_VIEWED,
      SIGNUP_ACTION_TYPES.SUBSCRIPTION_ZIP_ENTERED,
      SIGNUP_ACTION_TYPES.CONFIRMATION_GROCERY_CLICKED,
      SIGNUP_ACTION_TYPES.CONFIRMATION_TO_SHOPPING_CLICKED,
      SIGNUP_ACTION_TYPES.CONFIRMATION_TO_ACCOUNT_CLICKED,
      SIGNUP_ACTION_TYPES.SIGNUP_WITH_MISFITS_MARKET_CLICKED,

      ACCOUNT_ACTION_TYPES.ADD_PAYMENT_SOURCE_SUCCEEDED,

      ACCOUNT_ACTION_TYPES.APPLY_COUPON_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.APPLY_COUPON_FAILED,
      ACCOUNT_ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST,
      ACCOUNT_ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST,
      ACCOUNT_ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS,
      ACCOUNT_ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS,
      ACCOUNT_ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_INVALID_ADDRESS,
      ACCOUNT_ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.UPDATE_USER_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.SKIP_ORDER,
      ACCOUNT_ACTION_TYPES.SKIP_FUTURE_ORDER,
      ACCOUNT_ACTION_TYPES.SKIP_ORDER_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.SKIP_FUTURE_ORDER_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.ADD_VACATION_HOLD_SUCCEEDED,

      ACCOUNT_ACTION_TYPES.CANCELLATION_REASONS_CHOSEN,
      ACCOUNT_ACTION_TYPES.CANCELLATION_WINBACK_VIEWED,
      ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED,

      ACCOUNT_ACTION_TYPES.SET_MESSAGE_AS_READ_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.SET_ADD_ON_BOX_IDS_SUCCEEDED,

      ACCOUNT_ACTION_TYPES.DONATE_FUTURE_ORDER_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.UNDONATE_FUTURE_ORDER_SUCCEEDED,

      SEARCH_ACTION_TYPES.SEARCH_RESULT_CHANGED,
      FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL_SUCCEEDED,
      FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL_FAILED,
      FRIENDBUY_ACTION_TYPES.SET_REFERRAL,
      FRIENDBUY_ACTION_TYPES.CAPTURE_REFERRAL,
      FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON_SUCCEEDED,
      FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION_SUCCEEDED,
      FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION_FAILED,
    ]);

    switch (action.type) {
      case UI_ACTION_TYPES.BOOTSTRAP_COMPLETE:
      case ACCOUNT_ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.UPDATE_USER_SUCCEEDED: {
        yield fork(trackAlreadyLoggedInUser);
        break;
      }
      case ACTION_TYPES.SDK_RESET: {
        yield fork(initializeAnalytics, {
          isResetting: true,
          anonymousId: action.anonymousId,
        });
        break;
      }
      case ROUTING_ACTION_TYPES.PAGE_CHANGED: {
        yield fork(triagePageChangedFlow, action);
        break;
      }
      case ROUTING_ACTION_TYPES.LOCATION_CHANGED: {
        // We want to override a route task that is in progress during initialization
        // This will effectively debounce any route changes that were overriden
        // by the RestrictedRoute logic
        if (routeTask && routeTask.isRunning()) yield cancel(routeTask);
        routeTask = yield fork(triageRouteChangeFlow, action);
        break;
      }

      case UI_ACTION_TYPES.INITIALIZE_SUCCEEDED: {
        yield fork(trackInitializationSucceeded);
        break;
      }

      case UI_ACTION_TYPES.USER_FOCUSED_SEARCH_BAR: {
        yield fork(trackUserFocusedSearchBar);
        break;
      }

      case UI_ACTION_TYPES.USER_SEARCHED_NO_RESULTS: {
        yield fork(trackUserSearchedNoResults, action);
        break;
      }

      case SEARCH_ACTION_TYPES.SEARCH_RESULT_CHANGED: {
        // debounce event to give user chance to finish typing
        if (searchTask && searchTask.isRunning()) yield cancel(searchTask);
        searchTask = yield fork(triageSearchResultChanged, action);
        break;
      }

      case UI_ACTION_TYPES.TOGGLE_CART: {
        const isCartOpen = yield select(getCart);
        if (isCartOpen.visible) {
          yield fork(trackOpenCart);
        } else {
          yield fork(trackCloseCart);
        }
        break;
      }

      case UI_ACTION_TYPES.REPORT_ISSUE_BUTTON_CLICKED: {
        yield fork(trackReportIssueFlow, action);
        break;
      }

      case UI_ACTION_TYPES.GO_TO_SHOPPING_CLICKED: {
        yield fork(trackGoToShoppingClicked, action);
        break;
      }

      case UI_ACTION_TYPES.REFER_A_FRIEND_DESKTOP_CLICKED: {
        yield fork(trackReferAFriendClicked, action);
        break;
      }

      case UI_ACTION_TYPES.ACCOUNT_MENU_OPENED: {
        yield fork(trackAccountMenuOpened, action);
        break;
      }

      case UI_ACTION_TYPES.MORE_ABOUT_IMPERFECT_CLICKED: {
        yield fork(trackMoreAboutImperfectClicked, action);
        break;
      }

      case UI_ACTION_TYPES.BACK_TO_TOP_CLICKED: {
        yield fork(trackBackToTopClicked, action);
        break;
      }

      case UI_ACTION_TYPES.AISLE_CLICKED: {
        yield fork(trackAisleClicked, action);
        break;
      }

      case UI_ACTION_TYPES.AISLE_SHELF_CLICKED: {
        yield fork(trackAisleShelfClicked, action);
        break;
      }

      case UI_ACTION_TYPES.CTA_CLICKED: {
        yield fork(trackCTAClicked, action);
        break;
      }

      case UI_ACTION_TYPES.SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS: {
        yield fork(trackAddOneOrMoreRecipeItemsToCart, action);
        break;
      }

      case UI_ACTION_TYPES.RECIPE_PAGE_OPENED: {
        yield fork(trackRecipePageOpened, action);
        break;
      }

      case UI_ACTION_TYPES.ACCOUNT_NEXT_STEPS_CLICKED: {
        yield fork(trackAccountNextStepsClicked, action);
        break;
      }

      case UI_ACTION_TYPES.FIRST_TIME_SHOPPING_CAROUSEL_SLIDE_CHANGED: {
        yield fork(trackFirstTimeShoppingCarouselSlideChanged, action);
        break;
      }

      case AUTH_ACTION_TYPES.LOGIN_SUCCEEDED: {
        yield fork(trackLogInFlow);
        break;
      }

      case AUTH_ACTION_TYPES.LOGOUT: {
        yield fork(resetAnalytics);
        yield fork(trackLogOutFlow);
        break;
      }

      case FEATURE_ACTION_TYPES.SET_INTRO_COMPLETED: {
        yield fork(trackFeatureIntroCompleted, action);
        break;
      }

      case ORDER_ACTION_TYPES.UPDATE_VARIANT_QUANTITY_SUCCEEDED:
        yield fork(trackUpdateVariantQuantity, action);
        break;

      case ORDER_ACTION_TYPES.CREATE_AD_HOC_ORDER_SUCCEEDED:
        yield fork(adHocOrderCreated, action);
        break;

      case ORDER_ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_SUGGESTIONS:
        yield fork(trackDismissNeverListSuggestion, action);
        break;

      case SIGNUP_ACTION_TYPES.SIGNUP_STARTED: {
        const shouldTrack = yield select(isSignupRouteAllowed);
        if (shouldTrack) {
          yield fork(trackSignupStarted, action);
          yield fork(trackSignupStepViewed, action);
        }
        break;
      }

      case SIGNUP_ACTION_TYPES.QUIZ_STEP_VIEWED: {
        yield fork(trackQuizStepViewed, action);
        break;
      }

      case SIGNUP_ACTION_TYPES.SUBSCRIPTION_ZIP_ENTERED: {
        yield fork(trackSubscriptionZipEntered, action);
        break;
      }

      case SIGNUP_ACTION_TYPES.SIGNUP_ENDED:
        yield fork(trackSignupEnded, action);
        break;

      case SIGNUP_ACTION_TYPES.SIGNUP_ABANDONED:
        yield fork(trackSignupAbandoned, action);
        break;

      case SIGNUP_ACTION_TYPES.STEP_COMPLETE: {
        /* Track subscription chosen when @ step_complete ONLY */
        yield fork(trackSubscriptionChosen, action);
        /* FALL THROUGH */
      }
      // eslint-disable-next-line no-fallthrough
      case SIGNUP_ACTION_TYPES.CURRENT_STEP_CHANGED: {
        /* Track signup step veiwed when @ step_complete & step_changed */
        yield fork(trackSignupStepViewed, action);
        break;
      }

      case SIGNUP_ACTION_TYPES.SET_DELIVERY_DETAILS:
        yield fork(trackDeliveryEntered, action);
        break;

      case SIGNUP_ACTION_TYPES.SET_PAYMENT_DETAILS:
        yield fork(trackPaymentEntered, action);
        break;
      case SIGNUP_ACTION_TYPES.CONFIRMATION_TO_ACCOUNT_CLICKED:
        yield fork(trackSignupGoToAccountClicked, action);
        break;
      case SIGNUP_ACTION_TYPES.CONFIRMATION_TO_SHOPPING_CLICKED:
        yield fork(trackSignupGoToShoppingClicked, action);
        break;
      case SIGNUP_ACTION_TYPES.CONFIRMATION_GROCERY_CLICKED:
        yield fork(trackSignupGoToShoppingGroceryClicked, action);
        break;

      case SIGNUP_ACTION_TYPES.SIGNUP_WITH_MISFITS_MARKET_CLICKED:
        yield fork(trackSignupWithMisfitsMarketClicked, action);
        break;

      case ACCOUNT_ACTION_TYPES.ADD_PAYMENT_SOURCE_SUCCEEDED: {
        yield fork(trackPaymentMethodUpdate, action);
        break;
      }
      case ACCOUNT_ACTION_TYPES.APPLY_COUPON_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.APPLY_COUPON_FAILED: {
        yield fork(trackCouponFlow, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.CANCELLATION_REASONS_CHOSEN: {
        yield fork(trackCancellationReasonsChosenFlow, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.CANCELLATION_WINBACK_VIEWED: {
        yield fork(trackCancellationWinbackViewedFlow, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED: {
        yield fork(trackCancelSubscription, action);
        yield fork(trackCancelSubscriptionDeclinedTerms, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST: {
        yield fork(trackAddToNeverListFlow, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST: {
        yield fork(trackRemoveFromNeverListFlow, action);
        break;
      }

      case UI_ACTION_TYPES.NEVER_LIST_SUGGESTIONS_SHOWN: {
        yield fork(trackNeverListSuggestionsShown, action);
        break;
      }

      case UI_ACTION_TYPES.NEVER_LIST_SUGGESTIONS_HIDDEN: {
        yield fork(trackNeverListSuggestionsHidden, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS: {
        yield fork(trackAddToRecurringItemsFlow, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS: {
        yield fork(trackRemoveFromRecurringItemsFlow, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_INVALID_ADDRESS: {
        yield fork(trackInvalidAddress, action);
        break;
      }

      case UI_ACTION_TYPES.UPDATE_MODAL: {
        yield fork(trackModalActions, action);
        break;
      }

      case UI_ACTION_TYPES.OFFERING_CARD_CLICKED: {
        yield fork(trackOfferingCardClicked, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.SET_MESSAGE_AS_READ_SUCCEEDED: {
        yield fork(trackMessageReadFlow, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.SET_ADD_ON_BOX_IDS_SUCCEEDED: {
        yield fork(trackModifiedAddOnBoxIds, action);
        break;
      }

      case ACCOUNT_ACTION_TYPES.DONATE_FUTURE_ORDER_SUCCEEDED: {
        yield fork(trackDonatedOrder, action);
        break;
      }
      case ACCOUNT_ACTION_TYPES.UNDONATE_FUTURE_ORDER_SUCCEEDED: {
        yield fork(trackUndonatedOrder, action);
        break;
      }
      case ORDER_ACTION_TYPES.UPDATE_VARIANT_QUANTITY_FAILED: {
        yield fork(trackOosPurchaseRejected, action);
        break;
      }
      // This will track for autoSkipNextOrder in addition to individual skips
      case ACCOUNT_ACTION_TYPES.SKIP_ORDER: {
        yield fork(triageSkipOrder, action);
        break;
      }
      // This will track for autoSkipNextOrder in addition to individual skips
      case ACCOUNT_ACTION_TYPES.SKIP_FUTURE_ORDER: {
        yield fork(triageSkipFutureOrder);
        break;
      }
      case ACCOUNT_ACTION_TYPES.SKIP_ORDER_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.SKIP_FUTURE_ORDER_SUCCEEDED:
      case ACCOUNT_ACTION_TYPES.ADD_VACATION_HOLD_SUCCEEDED: {
        yield fork(trackHoldPlaced, action);
        break;
      }
      case UI_ACTION_TYPES.OUR_FOOD_AISLE_CLICKED: {
        yield fork(trackOurFoodAisleClicked, action);
        break;
      }
      case UI_ACTION_TYPES.CLEAR_DIALOG:
      case UI_ACTION_TYPES.SET_DIALOG: {
        yield fork(trackDialogActions, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL_SUCCEEDED: {
        // NC-1499 Remove legacy friendbuy
        yield fork(trackAppliedReferral, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL_FAILED: {
        // NC-1499 Remove legacy friendbuy
        yield fork(trackAppliedReferralFailed, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.SET_REFERRAL: {
        // NC-1499 Remove legacy friendbuy
        yield fork(trackSetReferral, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.CAPTURE_REFERRAL: {
        // next-gen friendbuy
        yield fork(trackCapturedReferral, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.FETCH_REFERRAL_COUPON_SUCCEEDED: {
        yield fork(trackFetchReferralCouponSucceeded, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION_SUCCEEDED: {
        yield fork(trackApplyAttributionSucceeded, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION_FAILED: {
        yield fork(trackApplyAttributionFailed, action);
        break;
      }
      case UI_ACTION_TYPES.VIDEO_LOAD_START: {
        yield fork(trackVideoLoadStart, action);
        break;
      }
      case UI_ACTION_TYPES.VIDEO_PLAY: {
        yield fork(trackVideoPlay, action);
        break;
      }
      case UI_ACTION_TYPES.VIDEO_END: {
        yield fork(trackVideoEnd, action);
        break;
      }
      case UI_ACTION_TYPES.VIDEO_ERROR: {
        yield fork(trackVideoError, action);
        break;
      }
      case UI_ACTION_TYPES.VIDEO_PLAYING: {
        yield fork(trackVideoPlaying, action);
        break;
      }
      default:
        break;
    }
  }
}

/*
 * https://app.asana.com/0/511453562545209/1128329092181133/f
 * Tunnel various user-level fields to Segment for GTM
 */
function* trackInitializationSucceeded() {
  const data = {};
  const loggedIn = yield select(isLoggedIn);
  data.loggedIn = loggedIn;

  if (loggedIn) {
    const user = yield select(getUser);
    const isOnBillingHold = yield select(isUserOnInternalBillingHold);
    const isOnSubtotalHold = yield select(isUserOnInternalSubtotalHold);
    data.userId = user?.userId;
    data.isOnBillingHold = isOnBillingHold;
    data.isOnSubtotalHold = isOnSubtotalHold;
    data.eventTime = moment().toDate();

    const active = yield select(isUserActive);
    if (active) {
      const delivery = yield select(getUserDeliveryWindow);

      data.fulfillmentCenterId = user?.fulfillmentCenterId;
      data.cadence = user?.cadence;
      data.cadenceOffset = user?.cadenceOffset;
      data.lifetimeOrderCount = user?.lifetimeOrderCount;
      data.lifetimeOrderRevenue = user?.lifetimeOrderRevenue;
      data.membership = user?.stage;

      data.deliveryProvider = delivery?.deliveryProvider;
      data.zoneName = delivery?.zoneName;
      data.marketName = delivery?.marketName;
      data.startDay = delivery?.startDay;
      data.startTime = delivery?.startTime;
      data.endTime = delivery?.endTime;
    }
  }
  yield call(trackEvent, "Initialized", data, true);
}

export function* trackAlreadyLoggedInUser() {
  const loggedIn = yield select(isLoggedIn);

  if (loggedIn) {
    const initialized = yield select(isInitialized);
    if (!initialized) {
      // Wait for initialization to complete
      yield call(waitForInitialization);
    }

    yield call(saveAnalyticsUser);
  }
}

export function* trackLogInFlow() {
  const initialized = yield select(isInitialized);
  if (!initialized) {
    // Wait for initialization to complete
    yield call(waitForInitialization);
  }

  const cb = call(trackEvent, "Logged In");
  yield call(saveAnalyticsUser, cb);
}

export function* trackLogOutFlow() {
  yield call(trackEvent, "Logged Out");
}

export function* saveAnalyticsUser(cb?: (args?: unknown) => unknown) {
  const user = yield select(getUser);
  const activeWindow = yield select(getActiveWindow);
  const nextDeliveryData = yield select(getNextDelivery);
  const isInCustomization = yield select(canUserCustomize);

  const traits = mapUserTraits(
    activeWindow,
    user,
    nextDeliveryData,
    isInCustomization
  );

  yield call(identifyUser, user.userId, traits);
  if (cb) yield cb;
}

function* trackFeatureIntroCompleted({ feature, completed, track }) {
  if (!track) return;
  yield call(trackEvent, "Feature Intro Completed", { feature, completed });
}

function* adHocOrderCreated({ orderId }) {
  yield call(trackEvent, "Ad Hoc Order Created", { orderId });
}

function* trackDismissNeverListSuggestion({ productId, source }) {
  yield call(trackEvent, "Never List Suggestion Dismissed", {
    productId,
    source,
  });
}

function* trackSignupStarted({ values = {} }) {
  const canSignup = yield select(canUserSignup);
  if (canSignup) {
    const data = {
      referralCode: values.referralCode,
    };
    yield call(trackEvent, "Signup Started", data);
  }
}

function* trackQuizStepViewed({ quizStep, values = {} }) {
  yield call(trackEvent, "Quiz Step Viewed", { quizStep, values });
}

type DeliveryInfo = {
  windowId: string;
  fulfillmentCenterId: string;
  deliveryProvider: DeliveryProvider;
  inCustomizationPeriod: boolean;
};

function* trackSubscriptionZipEntered(values: {
  zip: string;
  selectedDeliveryWindow: FullWindowData;
}) {
  const { zip, selectedDeliveryWindow } = values;

  let deliveryInfo: DeliveryInfo = {},
    isDeliverable = false;

  if (selectedDeliveryWindow) {
    const {
      windowId,
      fulfillmentCenterId,
      deliveryProvider,
      nextDelivery: { inCustomizationPeriod } = { inCustomizationPeriod: null },
    } = selectedDeliveryWindow;

    isDeliverable = true;

    deliveryInfo = {
      windowId,
      fulfillmentCenterId,
      deliveryProvider,
      inCustomizationPeriod,
    };
  }

  const data = {
    zip,
    deliveryInfo,
    isDeliverable,
  };

  yield call(trackEvent, "Signup Zip Entered", data);
}

function* trackSignupEnded() {
  const userId = yield select(getUserId); // userID is used here as the fb eventID
  yield call(trackEvent, "Signup Ended", { eventID: userId }, true);
}

function* trackSignupAbandoned() {
  const canSignup = yield select(canUserSignup);
  if (canSignup) {
    const step = yield select(getSignupStepForPreviousRoute);
    yield call(trackEvent, "Signup Abandoned", { step });
  }
}

function* trackSignupStepViewed() {
  const step = yield select(getSignupCurrentStep);
  const data = { step };
  yield call(trackEvent, "Signup Step Viewed", data);
}

function* trackDeliveryEntered({ values }) {
  const { deliveryWindowId, zip } = values;
  yield call(trackEvent, "Delivery Entered", { deliveryWindowId, zip });
}

function* trackPaymentEntered() {
  yield call(trackEvent, "Payment Entered");
}

function* trackSubscriptionChosen() {
  const previousStep = yield select(getSignupPreviousStep);

  // Did we just finish the recommendation / summary step in the signup flow
  if (previousStep === SignupSteps.QUIZ_SUMMARY) {
    const signupValues = yield select(getSignupValues);
    const { boxId, selectedFrequency, addOnBoxIds = [] } = signupValues;

    const data = {};

    if (boxId) {
      const boxes = yield select(getMapOfBoxes);
      const { displayName } = boxes[boxId];
      data.boxId = boxId;
      data.boxName = displayName;
    }
    if (selectedFrequency) {
      data.selectedFrequency = selectedFrequency;
    }
    if (addOnBoxIds.length) {
      const addOnBoxes = yield select(getSignupSelectedSecondaryBoxes);
      data.addOnBoxes = addOnBoxes.map(({ id, displayName }) => ({
        id,
        displayName,
      }));
    }
    yield call(trackEvent, "Subscription Chosen", data);
  }
}

function* trackPaymentMethodUpdate() {
  yield call(trackEvent, "Payment Method Update Success");
}

function* trackCouponFlow({ type, ...restCouponData }) {
  // eslint-disable-next-line object-curly-newline
  const { code, source, order } = restCouponData;
  if (type === ACCOUNT_ACTION_TYPES.APPLY_COUPON_FAILED) {
    const data = {
      code,
      source,
      failureMessage: restCouponData.failureMessage,
    };
    yield call(trackEvent, "Coupon Rejected", data);
  } else {
    const data = { code, source };
    if (order && order.orderId) {
      data.appliedTo = "order";
      data.orderId = order.orderId;
    } else {
      data.appliedTo = "user";
    }
    yield call(trackEvent, "Coupon Applied", data);
  }
}

function* trackAddToNeverListFlow({ productId, source }) {
  yield call(trackEvent, "Added to Never List", { productId, source });
}

function* trackRemoveFromNeverListFlow({ productId }) {
  yield call(trackEvent, "Removed from Never List", { productId });
}

function* trackNeverListSuggestionsShown() {
  yield call(trackEvent, "Never List Suggestions Shown");
}

function* trackNeverListSuggestionsHidden() {
  yield call(trackEvent, "Never List Suggestions Hidden");
}

function* trackReportIssueFlow({ location }) {
  yield call(trackEvent, "Report Issue Button Clicked", { location });
}

function* trackAddToRecurringItemsFlow({ productId, quantity, metadata }) {
  yield call(trackEvent, "Added to Recurring Items", {
    productId,
    quantity,
    metadata,
  });
}

function* trackRemoveFromRecurringItemsFlow({ productId, source }) {
  yield call(trackEvent, "Removed from Recurring Items", { productId, source });
}

function* trackInvalidAddress({ address, flags }) {
  yield call(trackEvent, "Address Validation Invalid", {
    address,
    flags: flags || "unrecognized address",
  });
}

function* trackUserFocusedSearchBar() {
  const selectedFilters = yield select(getSelectedFilters);
  const orderId = yield select(getActiveOrderId);

  const properties = {
    ...selectedFilters,
    orderId,
  };

  yield call(trackEvent, "Search Bar Focused", properties);
}

function* trackUserSearchedNoResults({ query, allProductsResultCount }) {
  const selectedFilters = yield select(getSelectedFilters);
  const orderId = yield select(getActiveOrderId);

  const properties = {
    ...selectedFilters,
    orderId,
    query,
    allProductsResultCount,
  };

  yield call(trackEvent, "Searched With No Results", properties);
}

function* trackMessageReadFlow({ messageId }) {
  if (messageId === ACTIVE_TC_MESSAGE_ID) {
    yield call(trackEvent, "Accepted Terms", { messageId });
  }
}

function* trackModifiedAddOnBoxIds({ addOnBoxIds }) {
  yield call(trackEvent, "Modified Add On Box Selection", { addOnBoxIds });
}

function* trackDonatedOrder({
  deliveryDate,
  originalDeliveryDate,
  donationPartner,
}) {
  yield call(trackEvent, "Delivery Donated", {
    deliveryDate,
    originalDeliveryDate,
    donationPartner,
  });
}

function* trackUndonatedOrder({ deliveryDate, originalDeliveryDate }) {
  yield call(trackEvent, "Delivery Undonated", {
    deliveryDate,
    originalDeliveryDate,
  });
}

function* trackOpenCart() {
  yield call(trackEvent, "Clicked to open cart");
}

function* trackCloseCart() {
  yield call(trackEvent, "Clicked to close cart");
}

function* trackGoToShoppingClicked({ location }) {
  yield call(trackEvent, "Go To Shopping Clicked", location);
}

function* trackOosPurchaseRejected({
  isOutOfStock,
  // originalItem may not exist if it's not yet represented on the order
  originalItem,
  variantId,
  orderId,
  quantity,
}) {
  if (isOutOfStock) {
    const packDate = yield select(getActiveOrderPackDate);

    const offering = yield select(getOfferingByVariantId, { variantId });
    const catalogRetrievedTimestamp = yield select(getFetchOfferingsTimestamp);

    const productData = {
      variantId,
      orderId,
      packDate,
      price: originalItem ? originalItem.unitPrice : offering.price,
      productName: originalItem
        ? originalItem.productName
        : offering.productName,
      offeringName: originalItem ? originalItem.name : offering.name,
      productId: originalItem ? originalItem.productId : offering.productId,

      // Replace stockLevel as proxy to stock
      lastStockLevel: offering.stockLevel,
      requestedQuantity: quantity,
      catalogRetrievedTimeDiff: moment().diff(catalogRetrievedTimestamp),
    };

    yield call(trackEvent, "OOS Purchase Rejected", productData);
  }
}

function* trackReferAFriendClicked({ location }) {
  yield call(trackEvent, "Refer a Friend Clicked", location);
}

function* trackAccountMenuOpened({ location }) {
  yield call(trackEvent, "Account Menu Opened", location);
}

function* trackMoreAboutImperfectClicked() {
  yield call(trackEvent, "More About Imperfect Clicked");
}

function* trackBackToTopClicked({ aisleName, aisleId }) {
  yield call(trackEvent, "Back To Top Clicked", { aisleName, aisleId });
}

function* trackSignupGoToAccountClicked() {
  yield call(trackEvent, "Signup confirmation go to my account clicked");
}

function* trackSignupGoToShoppingGroceryClicked({ grocery }) {
  yield call(trackEvent, "Signup confirmation go to shopping grocery clicked", {
    grocery,
  });
}

function* trackSignupWithMisfitsMarketClicked() {
  yield call(trackEvent, "Signup with Misfits Market clicked");
}

function* trackSignupGoToShoppingClicked() {
  yield call(trackEvent, "Signup confirmation go to shopping clicked");
}

function* trackAisleClicked({ location, aisleName, aisleId, source }) {
  yield call(trackEvent, "Aisle Clicked", {
    location,
    aisleName,
    aisleId,
    source,
  });
}

function* trackAisleShelfClicked({
  location,
  shelfName,
  shelfId,
  aisleName,
  aisleId,
  source,
}) {
  yield call(trackEvent, "Aisle Shelf Clicked", {
    location,
    shelfName,
    shelfId,
    aisleName,
    aisleId,
    source,
  });
}

function* trackCTAClicked({ name, meta }) {
  yield call(trackEvent, `CTA_CLICKED: ${name}`, meta);
}

function* triageSearchResultChanged() {
  yield delay(500);
  yield call(waitForShoppingInitialization);
  yield call(trackProductsSearched);
}

function* trackProductsSearched() {
  const data = yield select(getProductListData);
  if (data) {
    const event = data.filters?.length
      ? "Product List Filtered"
      : "Product List Viewed";

    // https://www.algolia.com/doc/guides/getting-insights-and-analytics/connectors/segment/reference/events/#product-list-viewed
    yield call(trackEvent, event, data);
  }
}

function* trackOfferingCardClicked(params) {
  const { variantId, rank } = params;
  const offering = yield select(getOfferingByVariantId, { variantId });
  const algoliaData =
    rank !== undefined ? yield select(getActiveAlgoliaData) : null;
  const data = {
    ...params,
    ...(algoliaData && {
      queryID: algoliaData.queryID,
      index: algoliaData.index,
    }),
    ...(rank !== null && { position: rank + 1 }),
    name: offering?.name,
    objectID: offering?.variantId,
  };
  yield call(trackEvent, "Product Card Clicked", data);
}

function* trackOurFoodAisleClicked(aisleName: string) {
  yield call(trackEvent, "Our Food Aisle Clicked", { aisleName });
}

function* trackAddOneOrMoreRecipeItemsToCart({
  products,
  recipeName,
  recipeUrl,
  recipeImage,
  showRecipeToast,
}) {
  if (showRecipeToast) {
    const onlyProductNames = products.map((product) => product.name);
    yield call(trackEvent, "Add Recipe Item To Cart", {
      recipeName,
      recipeUrl,
      recipeImage,
      eventTime: Date.now(),
      products: onlyProductNames,
    });
  }
}

function* trackRecipePageOpened({ shelfId }) {
  const variantIds = yield select(getShelfVariantIds, { shelfId });
  const shelfInfo = yield select(getShelfInfoFromShelfId, shelfId);
  const products = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const variantId of variantIds) {
    products.push(yield select(getOfferingByVariantId, { variantId }));
  }

  const onlyProductNames = products.map((product) => product.name);
  yield call(trackEvent, "Recipe Page Opened", {
    recipeName: shelfInfo.name,
    recipeUrl: shelfInfo.promotionLink,
    recipeImage: getImageURL(shelfInfo.promotionImageFilename),
    eventTime: Date.now(),
    products: onlyProductNames,
  });
}

function* trackAccountNextStepsClicked(params) {
  const data = { ...params };
  yield call(trackEvent, "Account Next Steps Clicked", data);
}

function* trackFirstTimeShoppingCarouselSlideChanged(params) {
  const data = { ...params };
  yield call(trackEvent, "First Time Shopping Carousel Clicked", data);
}

function* trackVideoLoadStart({ shelfId, shelfName, orderId }) {
  yield call(trackEvent, "Video Load Start", { shelfId, shelfName, orderId });
}

function* trackVideoPlay({ shelfId, shelfName, orderId }) {
  yield call(trackEvent, "Video Play", { shelfId, shelfName, orderId });
}

function* trackVideoEnd({ shelfId, shelfName, orderId }) {
  yield call(trackEvent, "Video End", { shelfId, shelfName, orderId });
}

function* trackVideoError({ shelfId, shelfName, orderId }) {
  yield call(trackEvent, "Video Error", { shelfId, shelfName, orderId });
}

function* trackVideoPlaying({ shelfId, shelfName, orderId }) {
  yield call(trackEvent, "Video Playing", { shelfId, shelfName, orderId });
}
