import { call, put, fork, select } from "redux-saga/effects";
import {
  setAnonymousId,
  enableGACrossDomainTracking,
  getAnonymousId,
} from "app/api/segment";
import { analyticsReady, analyticsInitializing } from "app/reducers/analytics";
import { config } from "app/config";
import { isOnLogoutPage, isLoggedIn } from "app/selectors";

interface InitializeAnalyticsParams {
  isFirstRun?: boolean;
  isResetting?: boolean;
  anonymousId?: string;
}

function* initializeAnalytics(params: InitializeAnalyticsParams = {}) {
  const { isFirstRun, isResetting } = params;
  let { anonymousId } = params;
  const onLogoutPage = yield select(isOnLogoutPage);
  const loggedIn = yield select(isLoggedIn);
  const isLoggingOut = loggedIn && onLogoutPage && !isResetting;

  // The anonymousId is manually generated in index.html.ejs if not found in local storage
  const initialAnonymousId = window.initialCtx?.anonymousId;

  if (isFirstRun) {
    // Regardless of timeout, we want to enable GA Cross Domain Tracking
    yield fork(enableGACrossDomainTracking);
  }

  yield put(
    analyticsInitializing({
      isLoggingOut,
      anonymousId: anonymousId || initialAnonymousId,
    })
  );

  if (isLoggingOut) {
    // Stop early
    // SDK_RESET will be called after analytics is reset
    // and will pass isResetting = true and fresh anonymousId
    return;
  }

  if (isFirstRun && initialAnonymousId) {
    // Segment support suggested overriding anonymousId
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#override-the-default-anonymous-id-with-a-call
    yield call(setAnonymousId, initialAnonymousId);
  }

  if (!anonymousId) {
    anonymousId = yield call(getAnonymousId);
  }
  if (config.get("logging.anon_id")) {
    /* eslint-disable no-console */
    console.log(`AnonymousId: ${anonymousId}`);
    console.log(
      `InitialAnonymousId: ${initialAnonymousId} (isFirstRun=${isFirstRun})`
    );
    /* eslint-enable no-console */
  }

  yield put(analyticsReady({ anonymousId: anonymousId || initialAnonymousId }));
}

export default initializeAnalytics;
