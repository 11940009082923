import { call, put, select } from "redux-saga/effects";

import { createOrder } from "app/api/orderService";
import {
  createAdHocOrderSucceeded,
  createAdHocOrderIneligible,
  createAdHocOrderFailed,
} from "app/reducers/orders";
import { showModal } from "app/reducers/ui";
import fetchNextDeliveryFlow from "app/sagas/fetchNextDeliveryFlow";
import { getUserId, isNextDeliveryStale } from "app/selectors";

interface CreateAdhocOrderActionType {
  deliveryDate: string;
  windowId: string;
  flexibleDelivery?: boolean;
}

function* createAdHocOrderFlow(action: CreateAdhocOrderActionType) {
  const { deliveryDate, windowId, flexibleDelivery = false } = action;
  try {
    yield put(
      showModal(
        flexibleDelivery
          ? "creating-flexible-delivery-order"
          : "creating-ad-hoc-order",
        { deliveryDate }
      )
    );

    if (!flexibleDelivery) {
      const isEligible = yield call(verifyUserEligibleForAdhocOrder, action);
      if (!isEligible) {
        yield put(createAdHocOrderIneligible());
        return;
      }
    }

    const userId = yield select(getUserId);
    const params = {
      userId,
      deliveryDate,
      windowId,
      cancelCurrent: false,
      pushStartCart: true,
      isRedelivery: false,
      ignoreInventory: false,
    };
    const orderId = yield call(createOrder, params);

    yield put(createAdHocOrderSucceeded(orderId));
  } catch (error) {
    // @ts-ignore
    yield put(createAdHocOrderFailed(error));
  }
}

function* verifyUserEligibleForAdhocOrder(action: CreateAdhocOrderActionType) {
  const isStale = yield select(isNextDeliveryStale);
  if (isStale) {
    // refetch next available delivery, and check to ensure the requested date is still available.
    // calling the flow also dispatches actions to update redux and therefore the connected UI,
    // but we need to explicitly grab the result from within the function, as there is no
    // guarantee redux is updated in time to select from it.
    const data = yield call(fetchNextDeliveryFlow);
    const canCreateAdHocOrder =
      data?.nextDelivery?.nextDelivery?.canCreateAdHocOrder;
    const deliveryDate = data?.nextAvailableWindowDelivery?.deliveryDate;

    return canCreateAdHocOrder && deliveryDate === action.deliveryDate;
  }

  return true;
}

export default createAdHocOrderFlow;
