import * as fromReactivation from "app/reducers/reactivation";
import State from "app/types/state";

export function getReactivationData(state: State) {
  return fromReactivation.getReactivationData(state.reactivation);
}

// accommodates window exceptions
export function getReactivationWindowId(state: State) {
  const reactivationData = fromReactivation.getReactivationData(
    state.reactivation
  );
  return reactivationData?.deliveryWindow?.windowId;
}

export function getReactivationSubscriptionWindowId(state: State) {
  const reactivationData = fromReactivation.getReactivationData(
    state.reactivation
  );
  return reactivationData?.deliveryWindowId;
}

export function getReactivationZipCode(state: State) {
  const reactivationData = fromReactivation.getReactivationData(
    state.reactivation
  );
  return reactivationData?.deliveryWindow?.zipCodeSummary?.zipCode;
}

export function getReactivationPromoCode(state: State) {
  return fromReactivation.getReactivationPromoCode(state.reactivation);
}

export function getReactivationStep(state: State) {
  return fromReactivation.getReactivationStep(state.reactivation);
}

export function getReactivationFailed(state: State) {
  return fromReactivation.getReactivationFailed(state.reactivation);
}

export function canUserReactivate(state: State) {
  const reactivationData = getReactivationData(state);

  return !!reactivationData?.canReactivate;
}
