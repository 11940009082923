// @ts-nocheck
export const toPercent = (percent) =>
  `${parseFloat((100 * percent).toFixed(4))}%`;

const grid = {
  row: `display: flex;`,
  cell: {
    fit: `flex: 1;`,
    full: `flex: 0 0 100%;`,
    // span calculates a percent based on "column" count - eg: 1/2 = 50%, 1/3 = 33.3333%
    span: (percent, initialFlex = 0) =>
      `flex: ${initialFlex} 0 ${toPercent(percent)};`,
    // spanWidth sets a fixed width - strings are used as is, numbers are assumed to be px
    spanWidth: (width, initialFlex = 0) =>
      `flex: ${initialFlex} 0 ${width}${
        typeof width === "string" ? "" : "px"
      };`,
    maxWidth: (percent) => `max-width: ${toPercent(percent)};`,
  },
};

export default grid;
