import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "misfits";

const prefixes = {
  SUBSCRIPTION_VALID_DAYS: `${domain}/SUBSCRIPTION_VALID_DAYS`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default actionTypes;
