import { FullWindowData } from "app/types/state/deliveries/deliveries";
import OrderMinimumData from "app/types/split/OrderMinimumData";
import { formatCurrency } from "app/ui/global/utils";
import _isString from "lodash/isString";

function getOrderMinimumValue(
  isUserUglyShip: boolean,
  orderMinimumData: OrderMinimumData | null,
  windowData?: FullWindowData
) {
  if (isUserUglyShip && windowData?.subtotalMin) {
    return parseOrderMinValue(windowData.subtotalMin);
  }

  if (
    orderMinimumData &&
    orderMinimumData?.isInTreatment &&
    orderMinimumData?.config?.subtotalMin
  ) {
    return parseOrderMinValue(orderMinimumData.config.subtotalMin);
  }

  return null;
}

export function parseOrderMinValue(orderMinimum: string | number) {
  const orderMinAsFloat = _isString(orderMinimum)
    ? parseFloat(orderMinimum)
    : orderMinimum;
  return `${formatCurrency(orderMinAsFloat, 0)}`;
}

export default getOrderMinimumValue;
