import {
  DEFAULT_TAGS,
  FRIEND_TAGS,
  APIType,
  FriendbuyReadyAction,
  FetchReferralCouponAction,
  LogEventCode,
  ADVOCATE_TAGS,
} from "app/types/state/friendbuy";
import { take, select, call, put } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";

import {
  isFriendbuyAPITypeLoaded,
  getAdvocateAPIType,
  isFriendbuyInitialized,
} from "app/selectors";
import FRIENDBUY_ACTION_TYPES from "app/actionTypes/friendbuy";
import {
  friendbuyReadyAction,
  referralCouponSucceededAction,
  referralCouponFailedAction,
  friendbuyInitializedAction,
  getActiveAPIType,
} from "app/reducers/friendbuy";
import { waitForSplitTreatmentsInitialized } from "app/sagas/waitForSplit";
import * as Monitoring from "app/monitoring";
import { LogLevel } from "app/types/monitoring";
import { getReferralCoupon } from "app/api/userService";

import { Coupon } from "app/types/account/Coupon";

/*
 * - A friendbuy API Type is "loaded" when it is determined that the API is available on window and
 *    the API can accept requests, by checking a boolean variable on the API object.
 *
 * Shared sagas:
 * 1) friendbuyBootstrapFlow used by both next-gen and first-gen in a custom "bootstrapFlow",
 *    triggered via UI_ACTION_TYPES.BOOTSTRAP_COMPLETE at session start.
 * 2) waitForFriendbuyReady used by both next-gen and first-gen in a custom "waitForLoaded" saga,
 *    which causes various friendbuy sagas to delay execution until the friendbuy API is "loaded".
 *
 */

// https://cloud.google.com/iot/docs/how-tos/exponential-backoff#example_algorithm
const MAX_RETRIES = 50;
export const MAX_BACKOFF_MS = 64 * 1000; // 64 sec

export const backoffDelay = (n: number, maxBackoff = MAX_BACKOFF_MS) => {
  const jitterMs = Math.random() * 1000;
  return Math.min(2 ** n * 1000 + jitterMs, maxBackoff);
};

// Poll for the friendbuy script to be loaded
// Use truncated exponential backoff strategy, as specced by google
export function* friendbuyBootstrapFlow(
  loadedCallback: () => boolean,
  apiType: APIType,
  maxBackoff = MAX_BACKOFF_MS,
  maxRetries = MAX_RETRIES
) {
  let loaded = false;
  let n = 0;
  let delayTimeMs = 0;

  // Continue to retry polling if friendbuy is loaded
  // Stop polling once we've reached maxRetries
  const shouldContinueRetry = () => {
    if (loaded) return false;
    return n < maxRetries;
  };

  do {
    loaded = yield call(loadedCallback);
    if (!loaded) {
      delayTimeMs = backoffDelay(n, maxBackoff);
      yield call(delay, delayTimeMs);
      n += 1;
    }
  } while (shouldContinueRetry());
  if (loaded) {
    yield put(friendbuyReadyAction(apiType, loaded));
  } else {
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: DEFAULT_TAGS,
      message: "Unable to load friendbuy API",
      attempt: n,
      apiType,
      eventCode: LogEventCode.API_LOAD_FAILED,
    });
  }
  return { loaded, n, delayTimeMs };
}

// Used by various friendbuy sagas to wait for the friendbuyBootstrapFlow
// to emit a friendbuy READY action
export function* waitForFriendbuyReady(
  apiType: APIType,
  // If true, wait for split treatments
  isAdvocateFlow = false
) {
  yield call(waitForAPITypeReady, apiType);

  // Advocate flow is dependent on split treatment before allowing widgets to be displayed
  if (isAdvocateFlow) {
    return yield call(waitForAdvocateAPIType, apiType);
  }

  return null;
}

function* waitForAPITypeReady(apiType: APIType) {
  const loaded: boolean = yield select(isFriendbuyAPITypeLoaded, { apiType });
  let action: FriendbuyReadyAction | null = null;

  // If not loaded, wait for a READY action matching the apiType
  if (!loaded) {
    const shouldContinueRetry = () => {
      return !action || action.apiType !== apiType;
    };
    do {
      action = yield take(FRIENDBUY_ACTION_TYPES.READY);
    } while (shouldContinueRetry());
  }
}

export function* waitForAdvocateAPIType(apiType: APIType) {
  // Wait until for split
  yield call(waitForSplitTreatmentsInitialized);

  // Determine active apitype for advocate
  const advocateApiType: APIType | null = yield select(getAdvocateAPIType);
  return advocateApiType === apiType;
}

export function* triageAPITypeChanged(apiType: APIType, callback?: any) {
  const activeAPIType: APIType | null = yield select(getActiveAPIType);
  const isApiActive: boolean = yield call(waitForFriendbuyReady, apiType, true);
  if (isApiActive && activeAPIType !== apiType) {
    // NC-1499 Remove legacy friendbuy
    if (callback) {
      yield call(callback);
    }
    yield put(friendbuyInitializedAction(apiType));
    Monitoring.sendLog({
      level: LogLevel.INFO,
      tags: ADVOCATE_TAGS,
      message: "Active API Type Changed",
      apiType,
      eventCode: LogEventCode.ACTIVE_API_TYPE_CHANGED,
    });
  }
}

export const NO_COUPON_FOUND_MESSAGE = "No coupon found for Friendbuy campaign";
export const ERROR_COUPON_MESSAGE =
  "Error loading the referral coupon for Friendbuy campaign";

// https://imperfectfoods.atlassian.net/wiki/spaces/TEC/pages/1112702987/Friendbuy#Coupons
export function* fetchReferralCouponFlow({
  campaignId,
  apiType,
}: FetchReferralCouponAction) {
  let code: string | null = null;
  try {
    const coupon: Coupon | null = yield call(getReferralCoupon, campaignId);
    code = coupon && coupon.code;
    if (code) {
      yield put(
        referralCouponSucceededAction(campaignId, apiType, coupon, code)
      );
      Monitoring.sendLog({
        level: LogLevel.INFO,
        tags: FRIEND_TAGS,
        message: "Referral Coupon Found for Campaign",
        couponCode: code,
        apiType,
        campaignId,
        eventCode: LogEventCode.REFERRAL_COUPON_LOAD_SUCCEEDED,
      });
    } else {
      const message = NO_COUPON_FOUND_MESSAGE;
      yield put(referralCouponFailedAction(campaignId, apiType, message));
      Monitoring.sendLog({
        tags: FRIEND_TAGS,
        level: LogLevel.ERROR,
        message,
        campaignId,
        apiType,
        couponCode: code,
        eventCode: LogEventCode.REFERRAL_COUPON_LOAD_FAILED,
      });
    }
  } catch (error) {
    const message = ERROR_COUPON_MESSAGE;
    yield put(referralCouponFailedAction(campaignId, apiType, message, error));
    Monitoring.sendLog({
      tags: FRIEND_TAGS,
      level: LogLevel.ERROR,
      message,
      error,
      campaignId,
      apiType,
      couponCode: code,
      eventCode: LogEventCode.REFERRAL_COUPON_LOAD_FAILED,
    });
  }
  return code;
}

export function* waitForFriendbuyInitialized() {
  const initialized = yield select(isFriendbuyInitialized);
  if (!initialized) {
    yield take([FRIENDBUY_ACTION_TYPES.FRIENDBUY_INITIALIZED]);
  }
}
