import React from "react";
import { useSelector } from "react-redux";

import { getDialog } from "app/selectors";
import {
  DialogType,
  AddressUpdateQueryDialog as AddressUpdateQueryDialogType,
  AddressValidationDialog as AddressValidationDialogType,
  PurchaseFailedDialog as PurchaseFailedDialogType,
  ReferralStatusInfoDialog as ReferralStatusInfoDialogType,
  RemoveOutOfStockDialog as RemoveOutOfStockDialogType,
  RetailPriceDialog as RetailPriceDialogType,
  NewFeatureDialog as NewFeatureDialogType,
} from "app/types/ui/Dialog";
import AddressUpdateQueryDialog from "app/ui/global/dialogs/AddressUpdateQueryDialog";
import AddressValidationDialog from "app/ui/global/dialogs/AddressValidationDialog";
import CatalogPreviewToolDialogContainer from "app/ui/shopping/CatalogPreviewToolDialog/Container";
import PurchaseFailedDialog from "app/ui/shopping/PurchaseFailedDialog";
import ReferralStatusInfoDialog from "app/ui/account/ReferFriends/ReferralStatusInfoDialog";
import RemoveOutOfStockDialog from "app/ui/shopping/RemoveOutOfStockDialog";
import OfferingDetailRetailPriceModal from "app/ui/shared/OfferingDetailPage/OfferingDetailRetailPriceModal";
import NewFeatureDialog from "app/ui/shopping/NewFeatureDialog/NewFeatureDialog";

const RootDialog: React.FC = () => {
  const dialog = useSelector(getDialog);

  switch (dialog?.type) {
    case DialogType.ADDRESS_UPDATE_QUERY: {
      return (
        <AddressUpdateQueryDialog
          dialog={dialog as AddressUpdateQueryDialogType}
        />
      );
    }

    case DialogType.ADDRESS_VALIDATION: {
      return (
        <AddressValidationDialog
          dialog={dialog as AddressValidationDialogType}
        />
      );
    }

    case DialogType.CATALOG_PREVIEW_TOOL: {
      return <CatalogPreviewToolDialogContainer />;
    }

    case DialogType.PURCHASE_FAILED: {
      const purchaseFailedDialog = dialog as PurchaseFailedDialogType;
      return <PurchaseFailedDialog dialog={purchaseFailedDialog} />;
    }

    case DialogType.REFERRAL_STATUS_INFO: {
      const referralStatusDialog = dialog as ReferralStatusInfoDialogType;
      return <ReferralStatusInfoDialog dialog={referralStatusDialog} />;
    }

    case DialogType.REMOVE_OUT_OF_STOCK: {
      const removeOutOfStockDialog = dialog as RemoveOutOfStockDialogType;
      return <RemoveOutOfStockDialog dialog={removeOutOfStockDialog} />;
    }

    case DialogType.RETAIL_PRICE: {
      const retailPriceDialog = dialog as RetailPriceDialogType;
      return <OfferingDetailRetailPriceModal dialog={retailPriceDialog} />;
    }

    case DialogType.NEW_FEATURE: {
      const newFeatureDialog = dialog as NewFeatureDialogType;
      return <NewFeatureDialog dialog={newFeatureDialog} />;
    }

    default:
      return null;
  }
};

export default RootDialog;
