import { rem } from "polished";
import React from "react";
import { animateScroll } from "react-scroll";
import styled from "styled-components";

import { toToggledShoppingFilter } from "app/router/routes";
import breakpoints from "app/styles/breakpoints";
import Tag from "app/types/state/offerings/Tag";
import Link from "app/ui/shared/Link";

interface RemoveFilterProps {
  scrollToTop?: boolean;
  selectedTag: Tag;
}

const RemoveFilter: React.FC<RemoveFilterProps> = ({
  scrollToTop = true,
  selectedTag,
}) => {
  return (
    <StyledLink
      hashAndQuery
      to={toToggledShoppingFilter({
        taxonomyId: selectedTag.id,
        taxonomy: "tag",
        selected: true,
      })}
      onClick={() => scrollToTop && animateScroll.scrollToTop({ duration: 0 })}
    >
      Remove Filter: {selectedTag.name}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  margin-top: ${rem(8)};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.beet};
  text-decoration: underline;

  &:active {
    color: ${({ theme }) => theme.colors.beet};
  }

  ${breakpoints.xl`
    margin-top: 0;
    margin-left: ${rem(8)};
  `}
`;

export default RemoveFilter;
