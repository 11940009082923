import { useLayoutEffect, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";

interface Size {
  width: number;
  height: number;
}

const useSize = (target, threshold = 5): Size | undefined => {
  const [size, setSize] = useState<Size>();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, ({ contentRect }) => {
    const safeSize = size || { height: 0, width: 0 };
    const deltaHeight = contentRect.height - safeSize.height;
    const deltaWidth = contentRect.width - safeSize.width;
    if (Math.sqrt(deltaHeight ** 2 + deltaWidth ** 2) > threshold) {
      setSize({ height: contentRect.height, width: contentRect.width });
    }
  });
  return size;
};

export default useSize;
