export interface SortableOffering {
  hasStock: boolean;
  merchRank: number;
}

export default function (
  a: string,
  b: string,
  mapOfVariants: Record<string, SortableOffering>
): number {
  const variantA = mapOfVariants[a];
  const variantB = mapOfVariants[b];
  if (!variantA || !variantB) {
    return 0;
  }
  if (variantA.hasStock && !variantB.hasStock) return -1;
  if (!variantA.hasStock && variantB.hasStock) return 1;
  if (
    (variantA.hasStock && variantB.hasStock) ||
    (!variantA.hasStock && !variantB.hasStock)
  ) {
    return variantB.merchRank - variantA.merchRank;
  }
  return 0;
}
