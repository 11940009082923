import { config } from "app/config";

const awsExports = {
  Auth: {
    region: config.get("third_party.cognito.region"),
    userPoolId: config.get("third_party.cognito.user_pool_id"),
    userPoolWebClientId: config.get("third_party.cognito.client_id"),
    mandatorySignIn: true,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  clientMetadata: {
    brand: "imperfect",
  },
};

export default awsExports;
