// @ts-nocheck
// TODO: How do we handle dynamic types? TS isn't happy with the ACTION_SUCCEEDED/ACTION_FAILEDs
import { take, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/ui";
import { isShoppingInitialized } from "app/selectors";

// eslint-disable-next-line import/prefer-default-export
export function* waitForShoppingInitialization() {
  const shoppingInitialized = yield select(isShoppingInitialized);
  if (!shoppingInitialized) {
    yield take([
      ACTION_TYPES.INITIALIZE_SHOPPING_SUCCEEDED,
      ACTION_TYPES.INITIALIZE_SHOPPING_FAILED,
    ]);
  }
}
