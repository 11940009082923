import { take, fork, select, put } from "redux-saga/effects";
import SPLIT_ACTION_TYPES from "app/actionTypes/split";
import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import AUTH_ACTION_TYPES from "app/actionTypes/auth";
import REACTIVATION_ACTION_TYPES from "app/actionTypes/reactivation";
import ORDER_ACTION_TYPES from "app/actionTypes/orders";
import UI_ACTION_TYPES from "app/actionTypes/ui";
import {
  canUserCustomize,
  isNativeApp,
  showAppRequiresUpdate,
} from "app/selectors";
import {
  ReactNativeWebViewMessage,
  ReactNativeWebViewMessageType,
} from "@imperfectproduce/frontend-shared";
import { showAppUpdateRequiredWarningModal } from "app/reducers/ui";

export default function* rootMobile() {
  while (true) {
    const action = yield take([
      AUTH_ACTION_TYPES.LOGIN_SUCCEEDED,
      AUTH_ACTION_TYPES.LOGOUT,
      ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.FETCH_USER_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.ADD_PAYMENT_SOURCE_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED,
      REACTIVATION_ACTION_TYPES.COMPLETE_REACTIVATION_SUCCEEDED,
      ACCOUNT_ACTION_TYPES.UNSKIP_ORDER_SUCCEEDED,
      ORDER_ACTION_TYPES.SET_ORDER_STATUS,
      UI_ACTION_TYPES.MOBILE_APP_NAVIGATE_TO_ACCOUNT,
      UI_ACTION_TYPES.MOBILE_APP_NAVIGATE_TO_SHOPPING,
      SPLIT_ACTION_TYPES.GET_TREATMENTS_SUCCEEDED,
    ]);

    switch (action.type) {
      case AUTH_ACTION_TYPES.LOGIN_SUCCEEDED: {
        yield fork(postLoginSucceeded, action);
        break;
      }
      case AUTH_ACTION_TYPES.LOGOUT: {
        yield fork(postLogout);
        break;
      }
      case ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED: {
        yield fork(postNextDelivery);
        break;
      }
      case ACCOUNT_ACTION_TYPES.FETCH_USER_SUCCEEDED: {
        yield fork(postUser, action);
        break;
      }
      case ACCOUNT_ACTION_TYPES.ADD_PAYMENT_SOURCE_SUCCEEDED: {
        yield fork(postBilling);
        break;
      }
      case REACTIVATION_ACTION_TYPES.COMPLETE_REACTIVATION_SUCCEEDED: {
        yield fork(postReactivation);
        break;
      }
      case ACCOUNT_ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED: {
        yield fork(postUnsubscribed);
        break;
      }
      case ACCOUNT_ACTION_TYPES.UNSKIP_ORDER_SUCCEEDED:
      case ORDER_ACTION_TYPES.SET_ORDER_STATUS: {
        yield fork(postOrderStatusChanged);
        break;
      }
      case UI_ACTION_TYPES.MOBILE_APP_NAVIGATE_TO_ACCOUNT: {
        yield fork(postNavigateToAccount);
        break;
      }
      case UI_ACTION_TYPES.MOBILE_APP_NAVIGATE_TO_SHOPPING: {
        yield fork(postNavigateToShopping);
        break;
      }
      case SPLIT_ACTION_TYPES.GET_TREATMENTS_SUCCEEDED: {
        yield fork(appRequiresUpdateFlow);
        break;
      }
      default:
        break;
    }
  }
}

function postMessage<T>(message: ReactNativeWebViewMessage<T>) {
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(JSON.stringify(message));
}

export function* postLoginSucceeded({ userId }: { userId: string }) {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.LOGIN_SUCCEEDED,
      payload: {
        userId,
      },
    };
    postMessage<{ userId: string }>(message);
  }
}

export function* appRequiresUpdateFlow() {
  const shouldShowAppUpdateModal = yield select(showAppRequiresUpdate);
  if (shouldShowAppUpdateModal) {
    yield put(showAppUpdateRequiredWarningModal());
  }
}

export function* postLogout() {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.LOGOUT,
    };
    postMessage(message);
  }
}

export function* postNextDelivery() {
  const isMobile: boolean = yield select(isNativeApp);
  const canCustomize: boolean = yield select(canUserCustomize);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.NEXT_DELIVERY,
      payload: {
        canUserCustomize: canCustomize,
      },
    };
    postMessage(message);
  }
}

export function* postUser({ user }: { user: any }) {
  const isMobile: boolean = yield select(isNativeApp);
  const auth: any = yield select((state) => state.auth);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.USER,
      payload: {
        account: {
          user,
        },
        auth,
      },
    };
    postMessage(message);
  }
}

export function* postBilling() {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.BILLING_ADDED,
    };
    postMessage(message);
  }
}

export function* postReactivation() {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.REACTIVATED,
    };
    postMessage(message);
  }
}

export function* postUnsubscribed() {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.UNSUBSCRIBED,
    };
    postMessage(message);
  }
}

export function* postOrderStatusChanged() {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.ORDER_STATUS_CHANGED,
    };
    postMessage(message);
  }
}

export function* postNavigateToAccount() {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.NAVIGATE_TO_ACCOUNT,
    };
    postMessage(message);
  }
}

export function* postNavigateToShopping() {
  const isMobile: boolean = yield select(isNativeApp);
  if (isMobile) {
    const message = {
      message: ReactNativeWebViewMessageType.NAVIGATE_TO_SHOPPING,
    };
    postMessage(message);
  }
}
