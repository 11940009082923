import { createSelector } from "reselect";

import { PLUS_PRICE_VARIANT_ID } from "app/constants";
import * as fromOfferings from "app/reducers/offerings";
import State from "app/types/state";
import Offering from "app/types/state/offerings/Offering";
import Tag from "app/types/state/offerings/Tag";
import safeArrayOfItems from "app/utils/safeArrayOfItems";

export function isOfferingsFetchComplete(state: State) {
  return fromOfferings.isOfferingsFetchComplete(state.offerings);
}

export function getFetchOfferingsTimestamp(state: State) {
  return fromOfferings.getFetchOfferingsTimestamp(state.offerings);
}

export function getOrderedVariantIds(state: State) {
  return fromOfferings.getOrderedVariantIds(state.offerings);
}

export function getMapOfVariants(state: State) {
  return fromOfferings.getMapOfVariants(state.offerings);
}

export function getMapOfSimilarVariantsByCategory(state: State) {
  return fromOfferings.getMapOfSimilarVariantsByCategory(state.offerings);
}

export function getOfferingsAlgoliaData(state: State) {
  return fromOfferings.getOfferingsAlgoliaData(state.offerings);
}

export const getVariantByVariantId = (
  state: State,
  props: { variantId: string }
) => {
  const offering = getMapOfVariants(state)[props.variantId];

  if (!offering) return null;
  return offering;
};

export const getVariantsByVariantIds = (
  state: State,
  props: { variantIds: string[] }
) => {
  const variantsMap = getMapOfVariants(state);
  return props.variantIds.reduce((acc: Offering[], variantId: string) => {
    if (variantsMap[variantId]) {
      acc.push(variantsMap[variantId]);
    }
    return acc;
  }, []);
};

export const getSimilarVariantsByCategory = (
  state: State,
  props: { variantId: string }
): string[] | undefined => {
  return getMapOfSimilarVariantsByCategory(state)[props.variantId];
};

export function getSelectedTagIds(state: State) {
  // don't return URL-driven selected-tags until all tags are fetched
  const allTags = fromOfferings.getOrderedTagIds(state.offerings);
  if (!allTags.length) {
    return [];
  }
  return fromOfferings.getSelectedTagIds(state.offerings) || [];
}

export function getPreviouslySelectedTagIds(state: State) {
  // don't return URL-driven selected-tags until all tags are fetched
  const allTags = fromOfferings.getOrderedTagIds(state.offerings);
  if (!allTags.length) {
    return [];
  }
  return fromOfferings.getPreviouslySelectedTagIds(state.offerings) || [];
}

export const getAllOfferings = createSelector(
  [getOrderedVariantIds, getMapOfVariants],
  (orderedOfferingIds, mapOfOfferings) => {
    if (!orderedOfferingIds || !orderedOfferingIds.length) return [];
    return orderedOfferingIds.map((o) => mapOfOfferings[o]);
  }
);

export function getOrderedTagIds(state: State) {
  return fromOfferings.getOrderedTagIds(state.offerings);
}

export function getMapOfTags(state: State) {
  return fromOfferings.getMapOfTags(state.offerings);
}

export const getTags = createSelector(
  [getOrderedTagIds, getMapOfTags],
  (tagIds, mapOfTags) => {
    return tagIds.reduce((acc, tId) => {
      const tag = mapOfTags[tId];
      if (tag.hidden === false) {
        acc.push(tag);
      }
      return acc;
    }, [] as Tag[]);
  }
);

export const getSelectedTags = createSelector(
  [getSelectedTagIds, getMapOfTags],
  (tagIds, mapOfTags) => safeArrayOfItems<Tag>(tagIds, mapOfTags)
);

export const getActiveFilter = createSelector(
  [getSelectedTags],
  (selectedTags) => (selectedTags.length === 1 ? selectedTags[0] : null)
);

export const getPlusPrice = createSelector([getMapOfVariants], (offerings) => {
  const offering = offerings[PLUS_PRICE_VARIANT_ID];
  return offering && offering.price;
});
