// @ts-nocheck
import { PropTypes } from "prop-types";
import React from "react";
import styled from "styled-components";

import { toRem } from "app/styles/utils";
import { rem } from "polished";
import Link from "app/ui/shared/Link";

const Button = ({
  children,
  onClick,
  className,
  disabled,
  type,
  ...rest
  // intent – this prop is used by StyledButton only
}) => {
  // Display eslint rule since we are setting this dynamically
  /* eslint-disable react/button-has-type */
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default Button;

export const ButtonIntents = {
  primary: "primary",
  seconday: "secondary",
  cta: "cta",
  normal: "normal",
  cancel: "cancel",
  warn: "warn",
  error: "error",
  success: "success",
  secondaryRebrand: "secondaryRebrand",
  eggplant: "eggplant",
  recurring: "recurring",
  defaultDark: "defaultDark",
};

const getColorFromIntent = (intent, theme) => {
  switch (intent) {
    case "primary":
      return theme.colors.grapefruit;
    case "secondary":
      return theme.colors.purple;
    case "cta":
      return theme.colors.beet;
    case "normal":
      return theme.colors.gray;
    case "cancel":
      return theme.colors.grayLight;
    case "warn":
      return theme.colors.orange;
    case "error":
      return theme.colors.grapefruit;
    case "success":
      return theme.colors.green;
    case "secondaryRebrand":
      return theme.colors.greenLight;
    case "eggplant":
      return theme.colors.eggplant;
    case "recurring":
      return theme.colors.blueDark;
    case ButtonIntents.defaultDark:
      return theme.colors.grayDark;
    default:
      return theme.colors.gray;
  }
};

// eslint-disable-next-line object-curly-newline, react/prop-types, max-len
const StrippedButton = ({
  intent,
  size,
  fullWidth,
  minWidth,
  underline,
  active,
  reverse,
  last,
  dark,
  isActive,
  showShelves,
  isMobile,
  ...restProps
}) => <Button {...restProps} />;

export const NakedButton = styled(StrippedButton)`
  background-color: transparent;
  border: none;
  outline: ${rem(1)} solid transparent;
`;

export const StyledButton = styled(StrippedButton)`
  border: none;
  border-radius: ${({ shape }) => {
    switch (shape) {
      case "round":
        return toRem(10);
      case "none":
        return toRem(0);
      default:
        return toRem(3);
    }
  }};
  background-color: ${({ theme, intent, disabled }) => {
    if (disabled) return theme.colors.grayLight;
    return getColorFromIntent(intent, theme);
  }};
  color: ${({ theme }) => theme.colors.marshmallow};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-weight: 600;
  font-size: ${({ size }) => {
    switch (size) {
      case "xSmall":
        return toRem(12);
      case "small":
        return toRem(14);
      case "regular":
        return toRem(16);
      default:
        return toRem(16);
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case "xSmall":
        return toRem([8, 12]);
      case "small":
        return toRem([8, 12]);
      case "regular":
        return toRem([8, 12]);
      case "large":
        return toRem([12, 16]);
      case "xLarge":
        return toRem([16, 20]);
      default:
        return toRem([8, 12]);
    }
  }};
  text-align: center;
  transition: background-color 300ms;

  ${({ minWidth }) =>
    minWidth &&
    `min-width: ${toRem(Number.isInteger(minWidth) ? minWidth : 160)};`};

  ${({ fullWidth }) => fullWidth && "width: 100%"};

  &:focus {
    background-color: ${({ theme }) => theme.colors.eggplant};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
    outline: ${rem(2)} solid transparent;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.eggplant};
  }
`;

export const GhostButton = styled(StyledButton)`
  background-color: transparent;
  border: ${({ dark, intent, disabled, theme }) => {
    if (dark) return `2px solid ${theme.colors.oat}`;
    let color;
    if (disabled) {
      color = theme.colors.grayLight;
    } else if (intent === "normal") {
      color = theme.colors.oat;
    } else {
      color = getColorFromIntent(intent, theme);
    }
    return `2px solid ${color}`;
  }};
  color: ${({ theme, dark, intent, disabled }) => {
    if (disabled) return theme.colors.grayLight;
    // override default normal for ghost buttons
    if (intent === "normal") return theme.colors.plum;
    if (dark) return theme.colors.plum;
    return getColorFromIntent(intent, theme);
  }};

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.oat};
    color: ${({ theme }) => theme.colors.plum};
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.beet};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.eggplant};
  }
`;

/* eslint-disable react/prop-types, jsx-a11y/anchor-has-content */
const StrippedLink = ({ intent, size, fullWidth, minWidth, ...restProps }) => {
  return <Link {...restProps} />;
};
/* eslint-enable react/prop-types */

const StyledButtonAsStrippedLink = (props) => (
  <StyledButton {...props} as={StrippedLink} />
);

export const StyledButtonLink = styled(StyledButtonAsStrippedLink)`
  display: inline-block;

  &:active {
    color: ${({ theme }) => theme.colors.marshmallow};
  }
  &:focus {
    outline-color: transparent;
  }
`;

export const TextButton = styled(StrippedButton)`
  border: none;
  background-color: transparent;
  color: ${({ theme, intent }) => getColorFromIntent(intent, theme)};
  font-weight: 600;
  line-height: ${toRem(18)};
  font-size: ${({ size }) => {
    switch (size) {
      case "xSmall":
        return toRem(12);
      case "small":
        return toRem(14);
      case "regular":
        return toRem(16);
      default:
        return toRem(16);
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case "small":
        return toRem([8, 12]);
      case "regular":
        return toRem([8, 12]);
      case "large":
        return toRem([12, 16]);
      case "xLarge":
        return toRem([16, 20]);
      default:
        return toRem([8, 12]);
    }
  }};
  outline: ${rem(3)} solid transparent;
  box-shadow: none;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
    color: ${({ theme }) => theme.colors.beet};
    text-decoration: none;
  }
`;

StyledButton.propTypes = {
  ...Button.propTypes,
  intent: PropTypes.oneOf([
    "primary",
    "secondary",
    "cta",
    "normal",
    "warn",
    "success",
    "cancel",
    "error",
    "secondaryRebrand",
    "eggplant",
    "recurring",
  ]),
};

StyledButton.defaultProps = {
  intent: "primary",
  size: "regular",
};
