// @ts-nocheck
/*
 * Original implementation integrated with Branch, Zendesk & FullStory
 *
 * This version is simplified to just Segment.
 */
import { call, select } from "redux-saga/effects";

import { config } from "app/config";
import * as Analytics from "./analytics";
import * as Monitoring from "app/monitoring";
import { waitForAnalyticsInitialization } from "app/sagas/waitForAnalyticsInitialization";
import { getUser, getUsageType } from "app/selectors";

// https://segment.com/docs/connections/spec/common/#context
const DEFAULT_CONTEXT = {
  app: {
    name: config.get("app.name"),
    version: config.get("app.version"),
    build: config.get("app.build"),
  },
};

function getOptions() {
  // From https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#managing-data-flow-with-the-integrations-object
  // You can pass an integrations object in the options of Alias, Group, Identify, Page, and Track methods to send data to only the selected destinations.
  // By default, all Destinations are enabled.
  return { context: DEFAULT_CONTEXT };
}

export const setAnonymousId = (anonymousId: string) => {
  return Analytics.setAnonymousId(anonymousId);
};

export const enableGACrossDomainTracking = () => {
  return Analytics.ready(() => {
    if (window?.ga && typeof window.ga === "function") {
      /* eslint-disable no-undef */
      // https://segment.com/docs/connections/destinations/catalog/google-analytics/#cross-domain-tracking
      ga("require", "linker");
      ga("linker:autoLink", [
        "get.imperfectfoods.com",
        "try.imperfectfoods.com",
      ]);
      /* eslint-enable no-undef */
    }
  });
};

export function getAnonymousId() {
  return Analytics.getAnonymousId();
}

/*
 * Note: Facebook pixel conversion tracking is directly created from Segment Page viewed events
 * https://segment.com/integrations/facebook-pixel/
 */
/*
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
 */
export function* viewPage(page, properties) {
  yield call(waitForAnalyticsInitialization);
  const usageType = yield select(getUsageType);
  const options = yield call(getOptions);
  try {
    Analytics.page(
      page,
      {
        usageType,
        ...properties,
      },
      options
    );
  } catch (error) {
    logAndReport(error);
  }
}

/*
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 */
export function* trackEvent(event, properties = {}, shouldAugmentData = false) {
  yield call(waitForAnalyticsInitialization);
  const usageType = yield select(getUsageType);
  const options = yield call(getOptions);
  const augmentedData = shouldAugmentData
    ? yield call(augmentUserData, properties)
    : properties;
  try {
    Analytics.track(
      event,
      {
        usageType,
        ...augmentedData,
      },
      options
    );
  } catch (error) {
    logAndReport(error);
  }
}

/*
 * Generic way of back-filling user data on track events to support marketing GTM data layer
 * https://app.asana.com/0/958208250985435/1109133349257519/f
 */
export function* augmentUserData(data = {}) {
  const user = yield select(getUser);
  if (!user) return data;

  const { firstName, lastName, defaultAddressId, mapOfAddresses } = user;

  const defaultAddress = mapOfAddresses[defaultAddressId];

  return {
    ...data,
    phone: user?.phone,
    email: user?.email,
    subscriptionId: user?.subscriptionId,
    addOnBoxIds: user?.addOnBoxIds,
    name: firstName && lastName ? `${firstName} ${lastName}` : firstName,
    street: defaultAddress?.address,
    street_2: defaultAddress?.addressLine2,
    zip: defaultAddress?.zip,
    city: defaultAddress?.city,
    state: defaultAddress?.state,
  };
}

/*
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
 */
export function* identifyUser(userId, traits) {
  const usageType = yield select(getUsageType);
  yield call(waitForAnalyticsInitialization);
  const options = yield call(getOptions);
  try {
    Analytics.identify(
      userId,
      {
        usageType,
        ...traits,
      },
      options
    );
  } catch (error) {
    logAndReport(error);
  }
}

export const resetUser = () => {
  try {
    return Analytics.reset();
  } catch (error) {
    logAndReport(error);
  }

  return undefined;
};

function logAndReport(error) {
  Monitoring.addError(error);
}
