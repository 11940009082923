import {
  MEDIUM_MIXED_BOX,
  MEDIUM_ORGANIC_BOX,
  LARGE_MIXED_BOX,
  LARGE_ORGANIC_BOX,
  EXTRA_LARGE_MIXED_BOX,
  EXTRA_LARGE_ORGANIC_BOX,
  SNACK_BOX,
  MEAT_AND_FISH_BOX,
  PLANT_BOX,
  DAIRY_BOX,
} from "app/constants/index";

import { HouseholdType, DietType, ProduceType } from "app/constants/signup";

import { formatCurrency } from "app/ui/global/utils";

const ALL_PACKS_BY_DIETARY_AND_HOUSEHOLDRANGE = {
  [DietType.VEGAN]: {
    [ProduceType.ORGANIC]: {
      [HouseholdType.SMALL]: {
        boxId: MEDIUM_ORGANIC_BOX,
        title: "14-18 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.MEDIUM]: {
        boxId: LARGE_ORGANIC_BOX,
        title: "16-21 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.LARGE]: {
        boxId: EXTRA_LARGE_ORGANIC_BOX,
        title: "18-25 varieties of fresh, sustainable groceries",
      },
    },
    [ProduceType.CONVENTIONAL]: {
      [HouseholdType.SMALL]: {
        boxId: LARGE_MIXED_BOX,
        title: "16-21 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.MEDIUM]: {
        boxId: LARGE_MIXED_BOX,
        title: "16-21 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.LARGE]: {
        boxId: EXTRA_LARGE_MIXED_BOX,
        title: "18-25 varieties of fresh, sustainable groceries",
      },
    },
    subtitle: DietType.VEGAN,
    priceTemplate: (totalPrice: number) =>
      `${formatCurrency(totalPrice - 5, 0)} - ${formatCurrency(
        totalPrice + 5,
        0
      )}`,
    packsSuggested: [PLANT_BOX],
  },
  [DietType.VEGETARIAN]: {
    [ProduceType.ORGANIC]: {
      [HouseholdType.SMALL]: {
        boxId: MEDIUM_ORGANIC_BOX,
        title: "14-18 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.MEDIUM]: {
        boxId: LARGE_ORGANIC_BOX,
        title: "16-21 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.LARGE]: {
        boxId: EXTRA_LARGE_ORGANIC_BOX,
        title: "18-25 varieties of fresh, sustainable groceries",
      },
    },
    [ProduceType.CONVENTIONAL]: {
      [HouseholdType.SMALL]: {
        boxId: MEDIUM_MIXED_BOX,
        title: "14-18 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.MEDIUM]: {
        boxId: LARGE_MIXED_BOX,
        title: "16-21 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.LARGE]: {
        boxId: EXTRA_LARGE_MIXED_BOX,
        title: "18-25 varieties of fresh, sustainable groceries",
      },
    },
    subtitle: DietType.VEGETARIAN,
    priceTemplate: (totalPrice: number) =>
      `${formatCurrency(totalPrice - 5, 0)} - ${formatCurrency(
        totalPrice + 5,
        0
      )}`,
    packsSuggested: [PLANT_BOX, SNACK_BOX],
  },
  [DietType.OMNIVORE]: {
    [ProduceType.ORGANIC]: {
      [HouseholdType.SMALL]: {
        boxId: MEDIUM_ORGANIC_BOX,
        title: "16-20 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.MEDIUM]: {
        boxId: LARGE_ORGANIC_BOX,
        title: "18-23 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.LARGE]: {
        boxId: EXTRA_LARGE_ORGANIC_BOX,
        title: "20-27 varieties of fresh, sustainable groceries",
      },
    },
    [ProduceType.CONVENTIONAL]: {
      [HouseholdType.SMALL]: {
        boxId: MEDIUM_MIXED_BOX,
        title: "16-20 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.MEDIUM]: {
        boxId: LARGE_MIXED_BOX,
        title: "18-23 varieties of fresh, sustainable groceries",
      },
      [HouseholdType.LARGE]: {
        boxId: EXTRA_LARGE_MIXED_BOX,
        title: "20-27 varieties of fresh, sustainable groceries",
      },
    },
    subtitle: DietType.OMNIVORE,
    priceTemplate: (totalPrice: number) =>
      `${formatCurrency(totalPrice - 10, 0)} - ${formatCurrency(
        totalPrice,
        0
      )}`,
    packsSuggested: [MEAT_AND_FISH_BOX, SNACK_BOX, DAIRY_BOX],
  },
};

export default ALL_PACKS_BY_DIETARY_AND_HOUSEHOLDRANGE;
