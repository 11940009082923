import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { useSelector } from "react-redux";

import Logo from "app/ui/shared/Logo";
import routes from "app/router/routes.json";
import breakpoints from "app/styles/breakpoints";
import { getAnonHeaderBannerConfigForRoute } from "app/selectors";
import LoggedOutAlertBanner from "app/ui/header/LoggedOutHeaderAlertBanner";

interface SignupHeaderProps {
  isOnCheckoutRoute: boolean;
}

const SignupHeader: React.FC<SignupHeaderProps> = ({ isOnCheckoutRoute }) => {
  const anonHeaderBannerConfig = useSelector(getAnonHeaderBannerConfigForRoute);

  return (
    <>
      <StyledHeader $isOnCheckoutRoute={isOnCheckoutRoute}>
        <Logo.Responsive url={routes.main.url} />
      </StyledHeader>
      {anonHeaderBannerConfig && (
        <LoggedOutAlertBanner headerBannerConfig={anonHeaderBannerConfig} />
      )}
    </>
  );
};

interface StyledHeaderProps {
  $isOnCheckoutRoute: boolean;
}

const StyledHeader = styled.header<StyledHeaderProps>`
  z-index: ${({ theme }) => theme.layout.zIndex.header};
  background-color: ${({ theme, $isOnCheckoutRoute }) => {
    if ($isOnCheckoutRoute) {
      return theme.colors.marshmallow;
    }
    return theme.colors.baba;
  }};
  position: fixed;
  height: ${({ theme }) => rem(theme.layout.loggedOutHeaderHeightMobile)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ theme, $isOnCheckoutRoute }) =>
    $isOnCheckoutRoute && theme.borders.header};

  ${breakpoints.xl`
    height: ${({ theme }) => rem(theme.layout.loggedOutHeaderHeightDesktop)};
  `}
`;

export default SignupHeader;
