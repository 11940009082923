import ACTION_TYPES from "app/actionTypes/search";
import { config } from "app/config";
import { SEARCH_QUERY_MIN, getVariantsIndex } from "app/constants";
import SearchQueryChangedAction, {
  SearchQueryChangedActionParams,
} from "app/types/state/search/SearchQueryChangedAction";
import SearchResultChangedAction, {
  SearchResultChangedActionParams,
} from "app/types/state/search/SearchResultChangedAction";
import SearchState from "app/types/state/search/SearchState";

const env = config.get("algolia.env");
export const DEFAULT_SEARCH_INDEX = getVariantsIndex(env);

export const initialState: SearchState = {
  query: "",
  variantIds: null,
  loadingNewSearchResults: false,
  algoliaData: null,
};

export const searchQueryMeetsMinimum = (query?: string) => {
  return (query && query.length > SEARCH_QUERY_MIN) || false;
};

const handleSearchQueryChanged = (
  state: SearchState,
  action: SearchQueryChangedAction
) => {
  const { query } = action;

  const isSearching = searchQueryMeetsMinimum(query);
  const variantIds = isSearching ? state.variantIds : initialState.variantIds;
  const algoliaData = isSearching
    ? state.algoliaData
    : initialState.algoliaData;
  return {
    ...state,
    query: action.query,
    variantIds,
    algoliaData,
    loadingNewSearchResults: isSearching && !state.variantIds,
  };
};

const handleSearchResultChanged = (
  state: SearchState,
  action: SearchResultChangedAction
) => {
  // Currently algolia only returns the index on multiqueries
  // Default to the search index
  const { queryID, hits } = action.result;
  return {
    ...state,
    query: action.query,
    variantIds: hits.map((hit) => hit.variantId),
    loadingNewSearchResults: false,
    algoliaData: { queryID, index: DEFAULT_SEARCH_INDEX },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_QUERY_CHANGED: {
      return handleSearchQueryChanged(state, action);
    }
    case ACTION_TYPES.SEARCH_RESULT_CHANGED: {
      return handleSearchResultChanged(state, action);
    }

    default:
      return state;
  }
}

export function getSearchQuery(state: SearchState) {
  return state.query;
}

export function getSearchVariantIds(state: SearchState) {
  return state.variantIds;
}

export function isLoadingNewSearchResults(state: SearchState) {
  return state.loadingNewSearchResults;
}

export function getSearchAlgoliaData(state: SearchState) {
  return state.algoliaData;
}

export function searchQueryChanged(
  params: SearchQueryChangedActionParams
): SearchQueryChangedAction {
  return { type: ACTION_TYPES.SEARCH_QUERY_CHANGED, ...params };
}

export function searchResultChanged(
  params: SearchResultChangedActionParams
): SearchResultChangedAction {
  return { type: ACTION_TYPES.SEARCH_RESULT_CHANGED, ...params };
}
