import { useMediaQuery, useTheme } from "@material-ui/core";
import { em } from "polished";
import { css } from "styled-components";
import { minWidth, minWidthNonScaling } from "app/styles/widths";

/*
 * FIXME: This is not the documented way to use the MUI breakpoint helpers but there is a bug with
 * custom breakpoints that will be fixed in the next major release
 * https://github.com/mui-org/material-ui/issues/21745
 *
 * New note: A fix for this workaround will be released in v5. The issue is closed in https://github.com/mui-org/material-ui/pull/26328.
 *
 * Documentation for theme.breakpoints.down is fixed in v5 https://github.com/mui-org/material-ui/pull/21922
 * theme.breakpoints.down(screenSize) returns a media query string which matches screen widths less than the screen size given (exclusive).
 * useMediumScreen() returns true if the screen width is < theme.breakpoints.values.md
 */
export const useSmallScreen = (direction = BreakpointDirection.DOWN) => {
  const theme = useTheme();
  return useMediaQuery(
    theme.breakpoints[direction](theme.breakpoints.values.sm)
  );
};

export const useMediumScreen = (direction = BreakpointDirection.DOWN) => {
  const theme = useTheme();
  return useMediaQuery(
    theme.breakpoints[direction](theme.breakpoints.values.md)
  );
};

export const useLargeScreen = (direction = BreakpointDirection.DOWN) => {
  const theme = useTheme();
  return useMediaQuery(
    theme.breakpoints[direction](theme.breakpoints.values.lg)
  );
};

export const useXLargeScreen = (direction = BreakpointDirection.DOWN) => {
  const theme = useTheme();
  return useMediaQuery(
    theme.breakpoints[direction](theme.breakpoints.values.xl)
  );
};

export const useXXLargeScreen = (direction = BreakpointDirection.DOWN) => {
  const theme = useTheme();
  return useMediaQuery(
    theme.breakpoints[direction](theme.breakpoints.values.xxl)
  );
};

export enum BreakpointDirection {
  DOWN = "down",
  UP = "up",
}

export const useCustomScreen = (
  width: number,
  direction: BreakpointDirection
) => {
  const theme = useTheme();
  return direction === BreakpointDirection.DOWN
    ? useMediaQuery(theme.breakpoints.down(width))
    : useMediaQuery(theme.breakpoints.up(width));
};

export interface IsMobileProp {
  $isMobile?: boolean;
}

export interface IsTabletProp {
  $isTablet?: boolean;
}

export interface IsRegularDesktopProp {
  $isRegularDesktop?: boolean;
}

export interface IsWideDesktopProp {
  $isWideDesktop?: boolean;
}

export interface QueryProp
  extends IsMobileProp,
    IsTabletProp,
    IsRegularDesktopProp,
    IsWideDesktopProp {}

export type SizesType = "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl" | "xxxxl";

type SizesObjectType = { [k in SizesType]: number };
type BreakpointsType = { [k in SizesType]: typeof css };

export const sizes = {
  sm: 375, // mobile
  md: 480, // xl mobile
  lg: 768, // tablet
  xl: 992, // xl tablet + desktop
  xxl: 1255, // desktop
  xxxl: 1440, // fancy monitor desktop
  xxxxl: 1464, // $$$$ monitor
} as SizesObjectType;

export const sizeQueries = {
  mobile: `(max-width: ${em(sizes.lg - 1)})`,
  tablet: `(max-width: ${em(sizes.xl - 1)})`,
  regularDesktop: `(max-width: ${em(sizes.xxl - 1)})`,
  wideDesktop: `(max-width: ${em(sizes.xxxl - 1)})`,
};

export const columns = {
  default: 4,
  small: 4,
  medium: 8,
  large: 12,
  xLarge: 12,
  xxLarge: 12,
};

// iterate through the sizes and create a media template
const breakpoints = Object.keys(sizes).reduce((accumulator, label) => {
  // eslint-disable-next-line no-param-reassign
  accumulator[label as SizesType] = minWidth(sizes[label as SizesType]);
  return accumulator;
}, {} as BreakpointsType);

// breakpoint helper that doesn't uses em so it doesn't change with font size
export const breakpointsNonScaling = Object.keys(sizes).reduce(
  (accumulator, label) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[label as SizesType] = minWidthNonScaling(
      sizes[label as SizesType]
    );
    return accumulator;
  },
  {} as BreakpointsType
);

export default breakpoints;
