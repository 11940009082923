import React from "react";
import NukaCarousel, {
  CarouselProps as NukaCarouselProps,
  CarouselSlideRenderControlProps,
} from "nuka-carousel";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForward from "@material-ui/icons/ArrowForwardIosRounded";
import styled from "styled-components";
import { rem } from "polished";
import { useDispatch } from "react-redux";
import { firstTimeShoppingCarouselSlideChanged } from "app/reducers/ui";

interface CarouselProps extends NukaCarouselProps {
  showControls?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  wrapAround = true,
  cellAlign = "center",
  scrollMode = "page",
  showControls = true,
  ...props
}) => {
  const dispatch = useDispatch();

  const trackCarouselSlideChange = (newSlideIndex) => {
    dispatch(firstTimeShoppingCarouselSlideChanged({ newSlideIndex }));
  };

  const RightControl = ({ nextSlide }: CarouselSlideRenderControlProps) => {
    return (
      <SCIconButton onClick={nextSlide} aria-label="Next Items">
        <ArrowForward aria-hidden="true" focusable="false" />
      </SCIconButton>
    );
  };

  const LeftControl = ({ previousSlide }: CarouselSlideRenderControlProps) => {
    return (
      <SCIconButton onClick={previousSlide} aria-label="Previous Items">
        <ArrowBack aria-hidden="true" focusable="false" />
      </SCIconButton>
    );
  };

  return (
    <NukaCarousel
      afterSlide={trackCarouselSlideChange}
      wrapAround={wrapAround}
      enableKeyboardControls
      cellAlign={cellAlign}
      renderCenterLeftControls={showControls ? LeftControl : null}
      renderCenterRightControls={showControls ? RightControl : null}
      scrollMode={scrollMode}
      {...props}
    >
      {children}
    </NukaCarousel>
  );
};

const SCIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.palette.custom.neutral.marshmallow};
  margin: 0 ${rem(20)};
  padding: ${rem(6)};
  color: ${({ theme }) => theme.palette.custom.type.peppercorn};
` as typeof IconButton;

export default Carousel;
