import React from "react";
import styled, { keyframes } from "styled-components";
import { rem } from "polished";

export interface CartBadgeProps {
  hasReachedMinimum: boolean;
  productCount: number;
}

const CartBadge: React.FC<CartBadgeProps> = ({
  hasReachedMinimum,
  productCount,
}) => {
  return (
    <BadgeContainer $productCount={productCount}>
      <NumCartItems
        $productCount={productCount}
        $hasReachedMinimum={hasReachedMinimum}
        aria-hidden="true"
      >
        {productCount}
      </NumCartItems>
      {!hasReachedMinimum && (
        <BadgePulse aria-hidden="true">{productCount}</BadgePulse>
      )}
    </BadgeContainer>
  );
};

const BadgeContainer = styled.span<{
  $productCount: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  ${({ $productCount }) =>
    $productCount.toString().length < 3
      ? `
        height: ${rem(30)};
        width: ${rem(30)};
      `
      : `
        min-height: ${rem(30)};
        min-width: ${rem(30)};
      `}
`;

const NumCartItems = styled.span<{
  $hasReachedMinimum: boolean;
  $productCount: number;
}>`
  background-color: ${({ theme }) => theme.colors.pink};
  border-radius: ${rem(20)};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  padding: ${rem(3)};
  ${({ $productCount }) =>
    $productCount.toString().length < 3
      ? `
        height: ${rem(20)};
        width: ${rem(20)};
      `
      : `
        min-height: ${rem(20)};
        min-width: ${rem(20)};
      `}
  ${({ $hasReachedMinimum }) => !$hasReachedMinimum && `position: absolute;`}
`;

const badgePulseAnimation = keyframes`
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
`;

const BadgePulse = styled.span`
  border-radius: ${rem(30)};
  color: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  font-size: ${rem(12)};
  height: ${rem(30)};
  min-height: ${rem(30)};
  line-height: ${rem(30)};
  padding: ${rem(3)};
  min-width: ${rem(30)};
  z-index: -1;
  border: 2px solid ${({ theme }) => theme.colors.pink};
  animation: ${badgePulseAnimation} 2s ease-out infinite;
`;

export default CartBadge;
