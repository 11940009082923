// Important note: any changes to this variable structure will impact the usage
// in the associated fetchSubPauseNextDeliveryFlow saga
const holdDurationOptions: {
  value: number;
  unit: moment.unitOfTime.DurationConstructor;
  label: string;
}[] = [
  { value: 1, unit: "months", label: "1 month" },
  { value: 2, unit: "months", label: "2 months" },
  { value: 3, unit: "months", label: "3 months" },
  { value: 4, unit: "months", label: "4 months" },
];

export default holdDurationOptions;
