import React from "react";
import { Box, Typography } from "@material-ui/core";
import { CarouselContentProps } from "app/ui/shopping/FirstTimeCarousel/carouselContents";
import WaveContainer from "app/ui/shared/WaveContainer";
import { getImageURL } from "app/ui/global/utils";
import styled from "styled-components";
import { Overline2 } from "app/ui/shared/ThemedTypography";
import { rem } from "polished";
import breakpoints from "app/styles/breakpoints";

const SlideDesktop: React.FC<CarouselContentProps> = ({
  headLine,
  subText,
  baseImageDirectory,
  desktopImage,
  main,
}) => (
  <SCWrapper>
    <SCWaveContainer color="baba" top={false} type="wave" bottom>
      <SCContainer>
        <Box alignSelf="center" width="65%" paddingTop={{ sm: 6, xl: 0 }}>
          <Overline2 gutterBottom>{headLine}</Overline2>
          <Typography variant="h3" gutterBottom>
            {main}
          </Typography>
          <Typography variant="body2">{subText}</Typography>
        </Box>
        <Box width={{ md: "35%", xxl: "30%" }} maxWidth="375px">
          <SCImg
            alt=""
            src={getImageURL(desktopImage, baseImageDirectory, { w: 750 })}
          />
        </Box>
      </SCContainer>
    </SCWaveContainer>
  </SCWrapper>
);

const SCWaveContainer = styled(WaveContainer)`
  ${breakpoints.xl`
    padding-top: ${rem(40)};
  `}
`;

const SCImg = styled.img`
  width: ${rem(375)};
`;

const SCWrapper = styled.div`
  width: 100%;
`;

const SCContainer = styled.div`
  display: flex;
  margin-left: ${rem(80)};
  justify-content: center;

  ${breakpoints.md`
    min-height: ${rem(250)};
  `}

  ${breakpoints.xxxl`
    min-height: ${rem(212)};
  `}

  ${breakpoints.xxl`
    justify-content: space-between;
  `}
`;

export default SlideDesktop;
