export default {
  app: {
    name: {
      doc: "Application Name",
      format: "String",
      default: "website",
    },
    build: {
      doc: "Application Build Identifier",
      format: "String",
      default: "v1.0.0:abcd",
    },
    version: {
      doc: "Application Version",
      format: "String",
      default: "1.0.0",
    },
  },
  algolia: {
    app_id: {
      doc: "Algolia App Id",
      format: "String",
      default: "B3U5PIITCM",
      env: "ALGOLIA_APP_ID",
    },
    key: {
      doc: "Algolia Key",
      format: "String",
      default: "6e0627db7722c475872c6e6edab1fecd",
      env: "ALGOLIA_KEY",
    },
    env: {
      doc: "Algolia Env",
      format: "String",
      default: "dev",
      env: "ALGOLIA_ENV",
    },
  },
  env: {
    doc: "The application environment.",
    format: ["production", "development", "local"],
    default: "local",
    env: "NODE_ENV",
  },
  flags: {
    log_accessibility: {
      doc: "Log accessibility warnings from react axe?",
      format: "Boolean",
      default: false,
      env: "LOG_ACCESSIBILITY",
    },
    maintenance_mode: {
      doc: "Maintenance mode enabled?",
      format: "Boolean",
      default: false,
      env: "MAINTENANCE_MODE",
    },
    logger_enabled: {
      doc: "Redux logging enabled?",
      format: "Boolean",
      default: false,
      env: "LOGGER_ENABLED",
    },
    https_only: {
      doc: "Force https from http?",
      format: "Boolean",
      default: false,
      env: "HTTPS_ONLY",
    },
    indexing_allowed: {
      doc: "Robots.txt allows indexing?",
      format: "Boolean",
      default: false,
      env: "INDEXING_ALLOWED",
    },
  },
  shelves: {
    card_limit: {
      doc: "Number of offering cards displayed per shelf",
      format: "int",
      default: 4,
      env: "SHELVES_CARD_LIMIT",
    },
  },
  split: {
    timeout: {
      doc: "Split Timeout",
      format: "Number",
      default: 5,
      env: "SPLIT_TIMEOUT",
    },
    key: {
      doc: "Split Key",
      format: "String",
      default: "1f05b0kso0ski8sdrohk0ehn9h2q18d0d8g6",
      env: "SPLIT_KEY",
    },
    debug: {
      doc: "Split debug enabled?",
      format: "Boolean",
      default: false,
      env: "SPLIT_DEBUG",
    },
    streaming: {
      // https://www.split.io/blog/introducing-streaming-architecture/
      doc: "Split streaming enabled?",
      format: "Boolean",
      default: true,
      env: "SPLIT_STREAMING",
    },
  },
  tc: {
    active_message_id: {
      doc: "Active T&C Message Id (must match value in users service config",
      format: "String",
      default: "terms-2019",
      env: "ACTIVE_TC_MESSAGE_ID",
    },
    modal_closable: {
      doc: "Can the user close the T&C Modal?",
      format: "Boolean",
      default: false,
      env: "TC_MODAL_CLOSABLE",
    },
    modal_decline_allowed: {
      doc: "Can the user decline?",
      format: "Boolean",
      default: false,
      env: "TC_MODAL_DECLINE_ALLOWED",
    },
  },
  alerts: {
    holiday_alert_visible: {
      doc: "Is there a holiday alert active?",
      format: "Boolean",
      default: false,
      env: "HOLIDAY_ALERT_VISIBLE",
    },
    holiday_alert_message: {
      doc: "The holiday alert message.",
      format: "String",
      default: "Holiday message goes here.",
      env: "HOLIDAY_ALERT_MESSAGE",
    },
  },
  endpoints: {
    gateway: {
      doc: "Gatway API endpoint",
      format: "String",
      default: "https://gateway-dev.imperfectfoods.co/v1",
      env: "GATEWAY_ENDPOINT",
    },
    merch_admin: {
      doc: "Merch Admin",
      format: "String",
      default: "https://merch-admin-dev.imperfectfoods.co",
      env: "MERCH_ADMIN_HOST",
    },
    users: {
      doc: "Users API endpoint",
      format: "String",
      default: "https://users-dev.imperfectfoods.co",
      env: "USERS_API_ENDPOINT",
    },
    website_host: {
      doc: "The website host",
      format: "String",
      default: "https://dev.imperfectfoods.co",
      env: "WEBSITE_HOST",
    },
    website_domain: {
      doc: "The website domain, used for links to blog, help etc",
      format: "String",
      default: "imperfectfoods.com",
      env: "WEBSITE_DOMAIN",
    },
    misfits: {
      doc: "The misfits api",
      format: "String",
      default: "https://review-master-dev.misfitsmarket.com/api",
      env: "MISFITS_API_ENDPOINT",
    },
  },
  social_urls: {
    facebook: {
      doc: "Imperfect Facebook URL",
      format: "String",
      default: "https://www.facebook.com/imperfectfoods",
      env: "FACEBOOK_URL",
    },
    twitter: {
      doc: "Imperfect Twitter URL",
      format: "String",
      default: "https://twitter.com/imperfect_foods",
      env: "TWITTER_URL",
    },
    pinterest: {
      doc: "Imperfect Pinterest URL",
      format: "String",
      default: "https://www.pinterest.com/imperfectfruit",
      env: "PINTEREST_URL",
    },
    instagram: {
      doc: "Imperfect Instagram URL",
      format: "String",
      default: "https://www.instagram.com/imperfectfoods",
      env: "INSTAGRAM_URL",
    },
  },
  segment: {
    write_key: {
      doc: "Segment write key",
      format: "String",
      default: "8rjZXFMqMeTz3meAKJDIsj1omm0m1lTd",
      env: "SEGMENT_WRITE_KEY",
    },
    snippet_version: {
      doc: "Segment snippet version",
      format: "String",
      default: "4.13.2",
      env: "SEGMENT_SNIPPET_VERSION",
    },
  },
  third_party: {
    prismic: {
      access_token: {
        doc: "Prismic Access Token",
        format: "String",
        default:
          "MC5Za05vQVJRQUFDTUFkZ2FG.Oe-_vX3vv70477-977-9EO-_ve-_vQ8xTnPvv73vv73vv73vv73vv73vv73vv73vv73vv71O77-9NwTvv73vv73vv73vv71V",
        env: "PRISMIC_ACCESS_TOKEN",
      },
      endpoint: {
        doc: "Prismic API Endpoint",
        format: "String",
        default: "https://imperfect.prismic.io/api/v2",
        env: "PRISMIC_API_ENDPOINT",
      },
    },
    prerender: {
      token: {
        doc: "Prerender Token",
        format: "String",
        default: "WQe188tkPJCG8uFNS2VS2",
        env: "PRERENDER_TOKEN",
      },
    },
    friendbuy: {
      site_key: {
        doc: "Friend Buy Site Key",
        format: "String",
        default: "site-ce6a2e61-host",
        env: "FRIENDBUY_SITE_KEY",
      },
      account_page_widget: {
        doc: "Friend Buy Account Page Widget",
        format: "String",
        default: "dUU-oXx",
        env: "FRIENDBUY_ACCOUNT_PAGE_WIDGET",
      },
      referrals_page_widget: {
        doc: "Friend Buy Referrals Page Widget",
        format: "String",
        default: "dUU-oy0",
        env: "FRIENDBUY_REFERRALS_PAGE_WIDGET",
      },
    },
    google_maps_key: {
      doc: "Google Maps API Key",
      format: "String",
      default: "AIzaSyAo_1AKyJOq3ydQ48tFVdSXtHUoGYWp2gA",
      env: "GOOGLE_MAPS_API_KEY",
    },
    stripe_public_key: {
      doc: "Stripe Public API Key",
      format: "String",
      default: "pk_test_UmVwCrRrbG88YpvK9LiC0z4X",
      env: "STRIPE_PUBLIC_KEY",
    },
    pinterest_domain_code: {
      doc:
        "Pinterest Domain Claim Code https://help.pinterest.com/en/business/article/claim-your-website",
      format: "String",
      default: "5ed409adf55c4f0c006cdcc43fa35962",
      env: "PINTEREST_DOMAIN_CODE",
    },
    kustomer: {
      chat_api: {
        doc:
          "Kustomer Chat Widget API https://developer.kustomer.com/chat-sdk/v2.0-Web/docs",
        format: "String",
        default:
          "eyJhbGciOiJub25lIn0.eyJvcmdOYW1lIjoiaW1wZXJmZWN0Zm9vZHMtc2FuZGJveCIsInJvbGVzIjpbIm9yZy50cmFja2luZyJdfQ.",
        env: "KUSTOMER_CHAT_API",
      },
      brand_id: {
        doc:
          "Kustomer Multi-brand chat https://kustomer.kustomer.help/multi-brand-chat-SkItrr3w",
        format: "String",
        default: "638a6290dbef3c4a3dea5b83",
        env: "KUSTOMER_BRAND_ID",
      },
    },
    cognito: {
      client_id: {
        doc: "Cognito Client Id",
        format: "String",
        default: "9eiu66ali85qre4tdcobp7ict",
        env: "COGNITO_CLIENT_ID",
      },
      user_pool_id: {
        doc: "Cognito User Pool Id",
        format: "String",
        default: "us-east-1_FgKouPuSY",
        env: "COGNITO_USER_POOL_ID",
      },
      region: {
        doc: "Cognito Region",
        format: "String",
        default: "us-east-1",
        env: "COGNITO_REGION",
      },
    },
  },
  document_data: {
    title_default: {
      doc: "Default page title",
      format: "String",
      default: "Grocery Delivery for Organic Food, Fresh Produce & More",
      env: "TITLE_DEFAULT",
    },
    title_template: {
      doc: "Template for page title",
      format: "String",
      default: "%s",
      env: "TITLE_TEMPLATE",
    },
    description_default: {
      doc: "Template for page description",
      format: "String",
      default:
        "Imperfect Foods delivers groceries on a mission. Shop produce, groceries, and snacks up to 30% less than grocery store prices. We deliver to the Bay Area, Los Angeles, Portland, Seattle, Midwest, East Coast, and South. Coming soon to the Southwest and Southeast.",
      env: "DESCRIPTION_DEFAULT",
    },
    keywords: {
      doc: "Template for page keywords",
      format: "String",
      default:
        "Imperfect foods, produce, groceries, ugly, fruits, vegetables, grocery delivery, discount, ugly produce, eat ugly, Bay Area, startup, California, San Francisco, Los Angeles, Portland, Seattle, Texas, Pacific Northwest, Midwest, East Coast, South",
      env: "KEYWORDS",
    },
  },
  datadog: {
    token: {
      doc: "Datadog token",
      format: "String",
      default: "puba13f43a62225d406fb86c74c455fcf0f",
      env: "DATADOG_TOKEN",
    },
    tag: {
      doc: "Datadog tag",
      format: "String",
      default: "website-local",
      env: "DATADOG_TAG",
    },
    rum_client_token: {
      doc: "Datadog RUM Client Token",
      format: "String",
      default: "puba13f43a62225d406fb86c74c455fcf0f",
      env: "DATADOG_RUM_CLIENT_TOKEN",
    },
    rum_application_id: {
      doc: "Datadog RUM Application Id",
      format: "String",
      default: "c84c3f67-7737-4bdc-a5c4-5577d24fd65b",
      env: "DATADOG_RUM_APPLICATION_ID",
    },
    env: {
      doc: "Datadog Environment",
      format: "String",
      default: "dev",
      env: "DD_ENV",
    },
  },
  basic_auth: {
    username: {
      doc: "Basic Auth Username (blank implies disabled)",
      format: "String",
      default: "",
      env: "BASIC_AUTH_USERNAME",
    },
    password: {
      doc: "Basic Auth Password (blank implies disabled)",
      format: "String",
      default: "",
      env: "BASIC_AUTH_PASSWORD",
    },
    realm: {
      doc: "Basic Auth Realm (optional)",
      format: "String",
      default: "imperfect-realm",
      env: "BASIC_AUTH_REALM",
    },
  },
  cdn: {
    base: {
      doc: "Default Client-Side CDN Host",
      format: "String",
      default: "https://dybby0uth7f1e.cloudfront.net",
      env: "DEFAULT_CDN_BASE",
    },
  },
  logging: {
    anon_id: {
      doc: "Logs the user's anonymous id on page load",
      format: "Boolean",
      default: false,
      env: "LOG_ANON_ID",
    },
  },
};
