import makeActionTypes, { ActionTypes } from "app/actionTypes";

const domain = "auth";
const prefixes = {
  LOGIN: `${domain}/LOGIN`,
  RESET_PASSWORD: `${domain}/RESET_PASSWORD`,
  REQUEST_PASSWORD_RESET: `${domain}/REQUEST_PASSWORD_RESET`,
  NEW_PASSWORD_REQUIRED: `${domain}/NEW_PASSWORD_REQUIRED`,
  UPDATE_PASSWORD: `${domain}/UPDATE_PASSWORD`,
};

type ActionPrefixKeys = keyof typeof prefixes;

const actionTypes = makeActionTypes({
  prefixes,
}) as ActionTypes<ActionPrefixKeys>;

export default {
  ...actionTypes,
  LOGOUT: `${domain}/LOGOUT`,
  IMPERSONATE: `${domain}/IMPERSONATE`,
  SAVE_CREDENTIALS: `${domain}/SAVE_CREDENTIALS`,
};
