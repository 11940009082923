import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Snackbar, {
  SnackbarTheme,
} from "app/ui/designSystem/molecules/Snackbar";
import ACTION_TYPES from "app/actionTypes/ui";
import { getRecipeToastState } from "app/selectors/ui";

const AddIngredientToast: React.FC = () => {
  const addRecipeIngredientInfo = useSelector(getRecipeToastState);

  const showRecipeToast = addRecipeIngredientInfo?.showRecipeToast;
  const numberOfIngredientsAdded =
    addRecipeIngredientInfo?.numberOfIngredientsAdded;
  const recipeName = addRecipeIngredientInfo?.recipeName;

  const recipeMessage = `🎉 ${recipeName} ${
    numberOfIngredientsAdded > 1 ? `ingredients` : `ingredient`
  } added to cart. We'll email you the recipe.`;

  const dispatch = useDispatch();

  const hideSnackbar = () => {
    dispatch({
      type: ACTION_TYPES.SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS,
      value: {
        showRecipeToast: false,
      },
    });
  };

  return (
    <Snackbar
      open={showRecipeToast}
      message={recipeMessage}
      onClose={hideSnackbar}
      snackbarTheme={SnackbarTheme.RECIPE}
    />
  );
};

export default AddIngredientToast;
