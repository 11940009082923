import ReactivationData from "app/types/account/ReactivationData";

export enum ReactivationSteps {
  REVIEW = "review",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
}

interface ReactivationState {
  reactivationData: ReactivationData | null;
  promoCode: string | null;
  reactivationStep: ReactivationSteps;
  reactivationFailed: boolean | null;
}

export default ReactivationState;
