import { take, fork, call, put } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/misfits";
import { fetchMisfitsValidDays } from "app/api/misfits";
import isZipDeliverableByMisfits from "app/utils/isZipDeliverableByMisfits";
import {
  fetchValidDaysByZipSuccess,
  fetchValidDaysByZipFailed,
} from "app/reducers/misfits";
import {
  MMValidDaysResponse,
  MMValidDaysAction,
} from "app/types/api/misfitsApi/subscription";

export default function* rootMisfits() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([ACTION_TYPES.SUBSCRIPTION_VALID_DAYS]);

    switch (action.type) {
      case ACTION_TYPES.SUBSCRIPTION_VALID_DAYS: {
        yield fork(fetchSubscriptionValidDays, action);
        break;
      }
      default:
        break;
    }
  }
}

function* fetchSubscriptionValidDays(action: MMValidDaysAction) {
  const { type, ...params } = action;
  try {
    const response: MMValidDaysResponse = yield call(
      fetchMisfitsValidDays,
      params
    );

    yield put(
      fetchValidDaysByZipSuccess({
        zip: action.zip,
        deliverable: isZipDeliverableByMisfits(response),
      })
    );
  } catch (error) {
    yield put(fetchValidDaysByZipFailed(action));
  }
}
