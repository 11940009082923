import { call } from "redux-saga/effects";
import { trackEvent } from "app/api/segment";
import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";

export default function* trackHoldPlaced(action) {
  let eventName;
  switch (action.type) {
    case ACCOUNT_ACTION_TYPES.SKIP_ORDER_SUCCEEDED:
      eventName = "Skipped Active Order";
      break;
    case ACCOUNT_ACTION_TYPES.SKIP_FUTURE_ORDER_SUCCEEDED:
      eventName = "Skipped Future Order";
      break;
    case ACCOUNT_ACTION_TYPES.ADD_VACATION_HOLD_SUCCEEDED:
      eventName = "Subscription Paused";
      break;
    default:
      break;
  }
  if (eventName) {
    yield call(trackEvent, eventName);
  }
}
