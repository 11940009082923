import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Selector } from "reselect";

import SmartRoute, { SmartRouteProps } from "app/router/SmartRoute";
import routes from "app/router/routes.json";
import {
  coreRouteSelector,
  isInitialized,
  isLoggedIn,
  isMaintenanceMode,
} from "app/selectors";
import State from "app/types/state";
import SpinnerPage from "app/ui/shared/SpinnerPage";
import { get as getCookie, set as setCookie } from "app/api/cookie";

export interface RestrictedRouteProps extends SmartRouteProps {
  overrideMaintainenceRedirect?: boolean;
  overrideBoysenberryRedirect?: boolean;
  spinnerBeforeInitialized?: boolean;
  shouldRedirect?: boolean;
  selector?: Selector<State, unknown>;
  initializedSelector?: Selector<State, unknown>;
  Spinner?: React.ComponentType;
}

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  overrideMaintainenceRedirect = false,
  overrideBoysenberryRedirect = false,
  spinnerBeforeInitialized = false,
  shouldRedirect = true,
  selector = isLoggedIn,
  initializedSelector = isInitialized,
  Spinner = SpinnerPage,
  ...props
}) => {
  const maintenanceMode = useSelector(isMaintenanceMode);
  const valid = useSelector(selector);
  const initialized = useSelector(initializedSelector);
  const data = useSelector(coreRouteSelector);

  const redirectTo = (pathname: string) => {
    return (
      <Redirect
        to={{
          pathname,
          state: {
            origin: "restricted",
            originPath: props.location?.pathname,
          },
        }}
      />
    );
  };

  if (!overrideBoysenberryRedirect) {
    const hasReloadedCookie = getCookie("hasHardReloadedPage");

    if (!hasReloadedCookie) {
      setCookie("hasHardReloadedPage", "true");
      window.location.reload();
    } else if (hasReloadedCookie === "true") {
      throw new Error("Cannot access restricted route: migrated user");
    }
  }

  if (
    !overrideMaintainenceRedirect &&
    maintenanceMode &&
    props.path !== routes.underConstruction.url
  ) {
    return redirectTo(routes.underConstruction.url);
  }

  if (!initialized && spinnerBeforeInitialized) {
    return <Spinner />;
  }

  if (!initialized || valid) {
    return <SmartRoute {...props} valid={valid} />;
  }

  if (shouldRedirect && data?.coreRoute) {
    return redirectTo(data.coreRoute);
  }

  return <SpinnerPage message="Restricted" />;
};

export default RestrictedRoute;
