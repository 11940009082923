import { call, select } from "typed-redux-saga";

import { trackEvent } from "app/api/segment";
import { getUpdateVariantQuantityData } from "app/selectors/crossDomain/analytics";
import TrackUpdateVariantQuantityParams from "app/types/order/TrackUpdateVariantQuantityParams";

function* trackUpdateVariantQuantity(action: TrackUpdateVariantQuantityParams) {
  const { quantity, originalQuantity } = action;

  let name = "Changed Variant Quantity";
  if (quantity <= 0) name = "Removed Product from Cart";
  else if (originalQuantity <= 0) name = "Added Product to Cart";

  const data = yield* select(getUpdateVariantQuantityData, action);

  yield call(trackEvent, name, data);
}

export default trackUpdateVariantQuantity;
