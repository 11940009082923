import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";

type StateValue = boolean | undefined;

export default (
  initialValue: StateValue,
  hoverComponentRef: RefObject<HTMLDivElement | null>
): [StateValue, Dispatch<SetStateAction<StateValue>>] => {
  const [isHovering, setIsHovering] = useState<StateValue>(initialValue);

  useEffect(() => {
    // This is here to fix this issue: https://github.com/facebook/react/issues/4492
    // Without this, some quantity buttons will remain expanded if users perform quick actions.
    if (isHovering && hoverComponentRef.current?.matches(":hover") === false)
      setIsHovering(false);
  });

  return [isHovering, setIsHovering];
};
