import * as fromSplit from "app/reducers/extendedSplit";
import {
  selectTreatmentValue,
  selectTreatmentWithConfig,
} from "@splitsoftware/splitio-redux";
import State from "app/types/state";
import { SplitTreatment } from "app/constants/treatments";

export function getSplitIsReady(state: State) {
  return state.splitio.isReady;
}

export function getSplitIsTimedout(state: State) {
  return state.splitio.isTimedout;
}

export function getSplitKey(state: State) {
  return fromSplit.getSplitKey(state.extendedSplit);
}

export function getSplitTrafficType(state: State) {
  return fromSplit.getSplitTrafficType(state.extendedSplit);
}

export function isSplitInitialized(state: State) {
  return fromSplit.getInitialized(state.extendedSplit);
}

// This only informs clients that initial treatments were requested
export function splitTreatmentsInitialized(state: State) {
  return fromSplit.treatmentsInitialized(state.extendedSplit);
}

export function getSplitTreatments(state: State) {
  return fromSplit.getSplitTreatments(state.extendedSplit);
}

/*
 * For both of the following selectors, they key is supposed to be inferred from the config
 * This works most of the time, but if two users happen to be in state (from logging in and out multiple times),
 * the key is not correctly inferred and is a known issue https://github.com/splitio/redux-client/issues/9.
 * Let's use our own key for safe measure.
 */
// https://help.split.io/hc/en-us/articles/360038851551-Redux-SDK#attribute-syntax
export const getSplitTreatmentValue = (
  state: State,
  splitName: SplitTreatment
) => {
  const key = getSplitKey(state);
  return selectTreatmentValue(state.splitio, splitName, key);
};
// https://help.split.io/hc/en-us/articles/360038851551-Redux-SDK#get-treatments-with-configurations
export const getSplitTreatmentWithConfig = (
  state: State,
  splitName: SplitTreatment
) => {
  const key = getSplitKey(state);
  return selectTreatmentWithConfig(state.splitio, splitName, key);
};
