// https://babeljs.io/blog/2019/03/19/7.4.0#migration-from-core-js-2
import "core-js/stable";
import "regenerator-runtime/runtime";

import { initConfig } from "app/config";

// Bootstrap our client-side config w/ window.env tunneled values
if (window && window.env) {
  initConfig(window.env);
}

// Wait to import the App code until the config is initialized from window.env
const runApp = () => {
  // eslint-disable-next-line global-require
  require("./app").default();
};

runApp();
