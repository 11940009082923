import { FadeIn } from "animate-css-styled-components";
import { cover } from "polished";
import styled from "styled-components";

export interface MaskProps {
  dark?: boolean;
}

const Mask = styled.div<MaskProps>`
  ${cover()}
  background-color: ${({ theme, dark }) => {
    return dark
      ? theme.colorUtils.fade(theme.colors.black, 0.5)
      : theme.colorUtils.fade(theme.colors.white, 0.15);
  }};
  position: fixed;
  z-index: ${({ theme }) => theme.layout.zIndex.accountNav - 1};
`;

export default Mask;

export const RevampMask = styled.div`
  ${cover()}
  position: fixed;
  backdrop-filter: blur(2px);
  background-color: ${({ theme }) =>
    theme.colorUtils.alpha(theme.colors.plum, 0.48)};

  /* until Firefox supports blur(), this is our workaround */
  @-moz-document url-prefix() {
    background-color: rgba(48, 6, 30, 0.9);
  }
`;

export const FadeInMask = styled(FadeIn)`
  ${cover()}
  background-color: ${({ theme, dark }) => {
    return dark
      ? theme.colorUtils.fade(theme.colors.black, 0.5)
      : theme.colorUtils.fade(theme.colors.white, 0.15);
  }};
  position: fixed;
`;

FadeIn.defaultProps = {
  duration: "250ms",
};
