import _ from "lodash";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import breakpoints, {
  breakpointsNonScaling,
  sizes,
} from "app/styles/breakpoints";
import Tag from "app/types/state/offerings/Tag";
import {
  offeringPreviewCardSizes,
  ProfileType as PreviewCardProfileType,
} from "app/ui/shopping/OfferingPreviewCard";
import { SIDE_NAV_WIDTH } from "app/ui/shopping/Navigation/SideNav";
import OfferingPreviewCardContainer from "app/ui/shopping/OfferingPreviewCard/OfferingPreviewCardContainer";

import CatalogShelfHeader, {
  calculateShelfHeaderHeight,
} from "./CatalogShelfHeader";
import NoVariantsWithFilter, {
  getNoVariantsWithFilterHeight,
} from "./NoVariantsWithFilter";
import useAnchorIdHash from "./useAnchorIdHash";
import ShelfPromotionCard from "./ShelfPromotionCard";

interface CatalogShelfTokenProps {
  activeFilter: Tag | null;
  columns: number;
  lastInAisle: boolean;
  shelfId: string;
  shelfName: string;
  description: string | null;
  variantIds: string[];
  promotionImageFilename: string | null;
  promotionLink: string | null;
  promotionLinkText: string | null;
  promotionVideoFilename: string | null;
  promotionVideoSubtitleFilename: string | null;
  isRecipe: boolean;
}
export interface CatalogShelfProps extends CatalogShelfTokenProps {
  style: React.CSSProperties;
  updateHash: _.DebouncedFunc<(anchorId: string) => void>;
  viewportRef: React.RefObject<HTMLDivElement>;
}

/**
 * This component renders a full shelf with header and products
 */
const CatalogShelf: React.FC<CatalogShelfProps> = ({
  activeFilter,
  columns,
  lastInAisle,
  shelfId,
  shelfName,
  description,
  promotionImageFilename,
  promotionLink,
  promotionLinkText,
  promotionVideoFilename,
  promotionVideoSubtitleFilename,
  style,
  updateHash,
  variantIds,
  viewportRef,
  isRecipe,
}) => {
  const ref = useAnchorIdHash(shelfId, updateHash, viewportRef);
  return (
    <ListItemContainer
      ref={ref}
      style={style}
      $columns={columns}
      $lastInAisle={lastInAisle}
    >
      <CatalogShelfHeader
        shelfId={shelfId}
        shelfName={shelfName}
        description={description}
        columns={columns}
        canAddToCart={isRecipe && variantIds?.length > 0}
      />
      <Grid $columns={columns}>
        {(promotionImageFilename || promotionVideoFilename) && (
          <ShelfPromotionCard
            shelfId={shelfId}
            shelfName={shelfName}
            promotionImageFilename={promotionImageFilename}
            promotionLink={promotionLink}
            promotionLinkText={promotionLinkText}
            promotionVideoFilename={promotionVideoFilename}
            promotionVideoSubtitleFilename={promotionVideoSubtitleFilename}
          />
        )}
        {variantIds.map((variantId, index) => (
          <OfferingPreviewCardContainer
            key={variantId}
            profileType={PreviewCardProfileType.REGULAR}
            variantId={variantId}
            source="single-page-catalog"
            rank={index}
            fromShelfId={shelfId}
            isRecipe={isRecipe}
          />
        ))}
      </Grid>
      {activeFilter && variantIds.length === 0 && (
        <NoVariantsWithFilter activeFilter={activeFilter} columns={columns} />
      )}
    </ListItemContainer>
  );
};

export default CatalogShelf;

const rowGap = 24;

const getRowPaddingBottom = (columns: number, lastInAisle: boolean) => {
  if (columns <= 2 && lastInAisle) return 40;
  if (columns > 2 && lastInAisle) return 56;
  return 24;
};

const ListItemContainer = styled.div<{
  $columns: number;
  $lastInAisle: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $columns, $lastInAisle }) =>
    `padding-bottom: ${rem(getRowPaddingBottom($columns, $lastInAisle))};`}
`;

const Grid = styled.div<{ $columns: number }>`
  display: grid;
  width: 100%;
  max-width: ${rem(sizes.xxxl)};
  grid-gap: ${rem(rowGap)} ${rem(16)};

  > div {
    max-width: unset;
    min-width: unset;
  }

  ${({ $columns }) =>
    `grid-template-columns: repeat(${$columns}, minmax(0, 1fr));`}

  ${breakpoints.md`
    padding: 0 ${rem(16)};
  `}

  ${breakpointsNonScaling.xl`
    padding: 0 ${rem(48)} 0 ${SIDE_NAV_WIDTH + 64}px;
  `}
`;

export interface CatalogShelfToken {
  component: typeof CatalogShelf;
  componentProps: CatalogShelfTokenProps;
  data: {
    anchorId: string;
    updateHash: boolean;
  };
  listItemHeight: number;
}

const calculateShelfHeight = (
  itemCount: number,
  columns: number,
  activeFilter: Tag | null,
  lastInAisle: boolean,
  description: string | null,
  promotionImageFilename: string | null,
  promotionVideoFilename: string | null,
  isRecipe: boolean
) => {
  const height =
    getRowPaddingBottom(columns, lastInAisle) +
    calculateShelfHeaderHeight(columns, description, isRecipe) +
    (activeFilter && itemCount === 0
      ? getNoVariantsWithFilterHeight(columns, false)
      : 0) +
    Math.ceil(
      (itemCount + (promotionImageFilename || promotionVideoFilename ? 2 : 0)) /
        columns
    ) *
      (offeringPreviewCardSizes.regularProfileHeight + rowGap);
  return height;
};

export const getCatalogShelfToken = (
  props: CatalogShelfTokenProps
): CatalogShelfToken => ({
  component: CatalogShelf,
  componentProps: props,
  data: {
    anchorId: props.shelfId,
    updateHash: true,
  },
  listItemHeight: calculateShelfHeight(
    props.variantIds.length,
    props.columns,
    props.activeFilter,
    props.lastInAisle,
    props.description,
    props.promotionImageFilename,
    props.promotionVideoFilename,
    props.isRecipe
  ),
});
