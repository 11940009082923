import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getPreviousRoutingLocation } from "app/selectors";
import State from "app/types/state";
import { ShoppingCatalogState } from "app/types/state/extendedRouter";

/*
 * Returns whether or not a user has just come from a PDP page
 */
export default (): boolean => {
  const history = useHistory<ShoppingCatalogState>();
  const previousRoutingLocation = useSelector((state: State) =>
    getPreviousRoutingLocation(state)
  );

  return (
    (previousRoutingLocation?.pathname?.includes("shopping/products") &&
      history.action === "POP") ||
    false
  );
};
