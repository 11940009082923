import ACTION_TYPES from "app/actionTypes/split";
import ANALYTIC_ACTION_TYPES from "app/actionTypes/analytics";
import ExtendedSplitState, {
  SplitTreatments,
} from "app/types/state/extendedSplit/ExtendedSplitState";
import GetTreatmentsProps from "app/types/state/extendedSplit/GetTreatmentsProps";
import InitializeSplitSucceededProps from "app/types/state/extendedSplit/InitializeSplitSucceededProps";

export const initialState: ExtendedSplitState = {
  initialized: false,
  key: null,
  trafficType: null,
  // Duplicated treatments for consumption by ConnectedSplitTreatments
  // and signup caching of anon splits on user record
  treatments: {},
  treatmentsInitialized: false,
};

const handleGetTreatments = (
  state: ExtendedSplitState,
  action: GetTreatmentsProps
) => {
  const treatments: SplitTreatments = action.splitNames.reduce((acc, name) => {
    acc[name] = state.treatments[name] || null;
    return acc;
  }, {} as SplitTreatments);

  return {
    ...state,
    treatments: {
      ...state.treatments,
      ...treatments,
    },
  };
};

const handleGetTreatmentsSucceeded = (state: ExtendedSplitState) => {
  return {
    ...state,
    treatmentsInitialized: true,
  };
};

// split does not appear to export the type for this action
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleAddTreatments = (state: ExtendedSplitState, action: any) => {
  const treatments = action.payload.treatments
    ? Object.keys(action.payload.treatments).reduce((acc, splitName) => {
        const { treatment, config } = action.payload.treatments[splitName];
        const value = config
          ? { treatment, config: JSON.parse(config) }
          : treatment;
        acc[splitName] = value;
        return acc;
      }, {} as Record<string, unknown>)
    : {};
  return {
    ...state,
    // Capture the key from split just in case the key changed (key is the userId or anonymousUserId)
    // https://github.com/splitio/redux-client/issues/9
    key: action.payload.key,
    treatments: {
      ...state.treatments,
      ...treatments,
    },
  };
};

const handleInitializeSplitSucceeded = (
  state: ExtendedSplitState,
  action: InitializeSplitSucceededProps
) => {
  return {
    ...state,
    key: action.key,
    trafficType: action.trafficType,
  };
};

const handleSplitReady = (state: ExtendedSplitState) => {
  return {
    ...state,
    initialized: true,
  };
};

export default function reducer(
  state: ExtendedSplitState = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any = {}
) {
  switch (action.type) {
    case ANALYTIC_ACTION_TYPES.SDK_RESET:
      return initialState;

    case ACTION_TYPES.SPLIT_READY:
    case ACTION_TYPES.SPLIT_TIMEDOUT: {
      return handleSplitReady(state);
    }

    case ACTION_TYPES.SPLIT_READY_WITH_EVALUATIONS: {
      return handleAddTreatments(handleSplitReady(state), action);
    }

    case ACTION_TYPES.SPLIT_UPDATE_WITH_EVALUATIONS:
    case ACTION_TYPES.ADD_TREATMENTS: {
      return handleAddTreatments(state, action);
    }

    case ACTION_TYPES.INITIALIZE_SPLIT_SUCCEEDED: {
      return handleInitializeSplitSucceeded(state, action);
    }

    case ACTION_TYPES.GET_TREATMENTS: {
      return handleGetTreatments(state, action);
    }

    case ACTION_TYPES.GET_TREATMENTS_SUCCEEDED: {
      return handleGetTreatmentsSucceeded(state);
    }

    default:
      return state;
  }
}

export function getInitialized(state: ExtendedSplitState) {
  return state.initialized;
}

export function treatmentsInitialized(state: ExtendedSplitState) {
  return state.treatmentsInitialized;
}

export function getSplitTreatments(state: ExtendedSplitState) {
  return state.treatments;
}

export function getSplitKey(state: ExtendedSplitState) {
  return state.key;
}

export function getSplitTrafficType(state: ExtendedSplitState) {
  return state.trafficType;
}

export function initializeSplit() {
  return { type: ACTION_TYPES.INITIALIZE_SPLIT };
}

export function initializeSplitSucceeded(
  params: InitializeSplitSucceededProps
) {
  return { type: ACTION_TYPES.INITIALIZE_SPLIT_SUCCEEDED, ...params };
}

export function getTreatments(params: GetTreatmentsProps) {
  return { type: ACTION_TYPES.GET_TREATMENTS, ...params };
}

export function getGlobalTreatments(params: GetTreatmentsProps) {
  return { type: ACTION_TYPES.GET_GLOBAL_TREATMENTS, ...params };
}
