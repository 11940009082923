import React from "react";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";
import { rem } from "polished";

import breakpoints from "app/styles/breakpoints";
import { minWidth } from "app/styles/widths";
import {
  CarouselContentProps,
  BlobOptions,
} from "app/ui/shopping/FirstTimeCarousel/carouselContents";
import { getImageURL } from "app/ui/global/utils";
import { Overline3, Caption2 } from "app/ui/shared/ThemedTypography";
import ShellBlobOne from "app/images/ShellBlobOne";
import ShellBlobTwo from "app/images/ShellBlobTwo";

const SlideMobile: React.FC<CarouselContentProps> = ({
  headLine,
  main,
  subText,
  baseImageDirectory,
  mobileImage,
  lowerMobileImage,
  blob,
  blobColor,
}) => {
  const BlobBackground =
    blob === BlobOptions.BLOB_ONE ? (
      <ShellBlobOne fillColor={blobColor} />
    ) : (
      <ShellBlobTwo fillColor={blobColor} />
    );

  return (
    <Box
      position="relative"
      width="100%"
      bgcolor="custom.neutral.baba"
      display="flex"
      justifyContent="center"
    >
      {BlobBackground}
      <SCImage
        alt=""
        src={getImageURL(mobileImage, baseImageDirectory, { w: 208 })}
      />
      <Box
        position="absolute"
        top="0"
        width={{ xs: rem(262), sm: rem(292) }}
        paddingTop={rem(24)}
        paddingLeft={rem(5)}
        zIndex={1}
      >
        <Overline3 gutterBottom>{headLine}</Overline3>
        <SCTypography variant="h4" gutterBottom>
          {main}
        </SCTypography>
        <Caption2>{subText}</Caption2>
      </Box>
      <SCLowerImage
        alt=""
        src={getImageURL(lowerMobileImage, baseImageDirectory, { w: 208 })}
      />
    </Box>
  );
};

export default SlideMobile;

const SCImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: ${rem(104)};

  ${minWidth(500)`
    width: ${rem(130)};
  `};
`;

const SCLowerImage = styled(SCImage)`
  top: auto;
  bottom: 0;
  left: 0;
`;

const SCTypography = styled(Typography)`
  line-height: ${rem(24)};
  font-size: ${rem(16)};

  ${breakpoints.md`
    font-size: ${rem(18)};
  `}
`;
