import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { rem } from "polished";

import { sizes } from "app/styles/breakpoints";
import fonts from "app/styles/fonts";

const muiTypography = {
  typography: {
    fontFamily: "Montserrat",
    h1: {
      fontFamily: fonts.lufga,
      fontWeight: Number(fonts.weight.extraBold),
      fontSize: fonts.size.mobile.header1,
      lineHeight: rem(40),
      [`@media (min-width: ${sizes.md}px)`]: {
        fontSize: fonts.size.tablet.header1,
        lineHeight: rem(56),
      },
      [`@media (min-width: ${sizes.xl}px)`]: {
        fontSize: fonts.size.desktop.header1,
        lineHeight: rem(80),
      },
    },
    h2: {
      fontFamily: fonts.lufga,
      fontWeight: Number(fonts.weight.extraBold),
      fontSize: fonts.size.desktop.header2,
      lineHeight: rem(56),
      textTransform: "none",
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: fonts.size.mobile.header2,
        lineHeight: rem(40),
      },
    },
    h3: {
      fontFamily: fonts.lufga,
      fontWeight: Number(fonts.weight.extraBold),
      fontSize: fonts.size.desktop.header3,
      lineHeight: rem(40),
      textTransform: "none",
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: fonts.size.mobile.header3,
        lineHeight: rem(32),
      },
    },
    h4: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.bold),
      fontSize: fonts.size.desktop.header4,
      lineHeight: rem(32),
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: fonts.size.mobile.header4,
        lineHeight: rem(28),
      },
    },
    h5: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.semiBold),
      fontSize: fonts.size.desktop.header5,
      lineHeight: rem(24),
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: fonts.size.mobile.header5,
        lineHeight: rem(24),
      },
    },
    h6: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.semiBold),
      fontSize: fonts.size.desktop.header6,
      lineHeight: rem(24),
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: fonts.size.mobile.header6,
        lineHeight: rem(20),
      },
    },
    subtitle1: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.normal),
      fontSize: rem(24),
      lineHeight: rem(36),
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: rem(18),
        lineHeight: rem(28),
      },
    },
    body1: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.normal),
      fontSize: rem(20),
      lineHeight: rem(32),
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: rem(16),
        lineHeight: rem(28),
      },
      "& a": {
        color: "inherit",
        textDecoration: "underline",
      },
    },
    body2: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.normal),
      fontSize: rem(16),
      lineHeight: rem(24),
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: rem(14),
        lineHeight: rem(24),
      },

      "& a": {
        fontWeight: Number(fonts.weight.semiBold),
      },
    },
    button: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.semiBold),
      fontSize: rem(16),
      lineHeight: rem(24),
      textTransform: "none",
    },
    caption: {
      fontFamily: fonts.body,
      fontWeight: Number(fonts.weight.normal),
      fontSize: rem(13),
      lineHeight: rem(16),
    },
    overline: {
      fontFamily: fonts.imperfectHand,
      fontWeight: Number(fonts.weight.bold),
      fontSize: fonts.size.desktop.overlineOne,
      lineHeight: rem(46),
      textTransform: "none",
      [`@media (max-width: ${sizes.md}px)`]: {
        fontSize: fonts.size.mobile.overlineOne,
        lineHeight: rem(40),
      },
    },
  },
};

export default muiTypography as TypographyOptions;
