import SignupSelectedWindowValuesFormatted from "app/types/selectors/crossDomain/SignupSelectedWindowValuesFormatted";
import { InlineAlertProps } from "../designSystem/organisms/InlineAlert";

// eslint-disable-next-line import/prefer-default-export
export const getExceptionInlineAlertProps = (
  props: SignupSelectedWindowValuesFormatted
): InlineAlertProps => {
  return {
    severity: "info",
    color: "warning",
    titleCopy: `Your regular delivery day is ${props.originalDeliveryDateAsMoment?.format(
      "dddd"
    )}`,
    bodyCopy: `Due to the holidays, your first delivery will be ${props.deliveryDateAsMoment.format(
      "dddd, MMM D"
    )}.`,
  };
};
