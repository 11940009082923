export enum KustomerAPIListenerEvents {
  // "onUnread",
  OPEN = "onOpen",
  // "onClose",
  // "onConversationCreate",
  // "onLogin",
  // "onLogout",
}
// incomplete typing, see docs
type DescribeCustomerParams = { attributes: { emails: string[] } };

// these types are incomplete, see docs for full details
// https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/overview-chat-ui-api-reference
export interface KustomerAPI {
  addListener: (event: KustomerAPIListenerEvents, callback: () => void) => void; // incomplete typing, see docs
  describeCustomer: (params: DescribeCustomerParams) => void;
}
