import { createGlobalStyle } from "styled-components";

const GlobalBodyBackground = createGlobalStyle<{ bgColor?: string }>`
  body {
    background-color: ${({ bgColor, theme }) => {
      // @ts-ignore
      return bgColor || theme.colors.white;
    }};
  }
`;

export default GlobalBodyBackground;
