import { useEffect } from "react";
import { useSelector } from "react-redux";

import { isLoggedIn, isNativeApp } from "app/selectors";

export default function useAppsflyerSmartBanner() {
  const isNative = useSelector(isNativeApp);
  const isUserLoggedIn = useSelector(isLoggedIn);

  useEffect(() => {
    // show the Appsflyer Smart Banner
    if (window && window.AF && !isNative && isUserLoggedIn) {
      window.AF("banners", "showBanner");
    }

    return () => {
      // hide the Appsflyer Smart Banner
      if (window && window.AF && !isNative && isUserLoggedIn) {
        window.AF("banners", "hideBanner");
      }
    };
  }, [isNative, isUserLoggedIn]);
}
