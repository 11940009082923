import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { rem } from "polished";
import _debounce from "lodash/debounce";
import { Remove, Add, Delete } from "@material-ui/icons";
import { Portal } from "react-portal";
import MuiSnackbar from "@material-ui/core/Snackbar";
import MuiTooltip from "@material-ui/core/Tooltip";
import MuiButton from "@material-ui/core/Button";

import { useMediumScreen } from "app/styles/breakpoints";
import ScrollingNumber from "app/ui/designSystem/molecules/animations/ScrollingNumber";
import { updateVariantQuantity } from "app/reducers/orders";
import { onEnterOrSpaceDown, UI_DEBOUNCE_RATE } from "app/ui/global/utils";
import { SnackbarContainerId } from "app/ui/designSystem/molecules/Snackbar/SnackbarContainer";
import ViewableOffering from "app/types/selectors/crossDomain/ViewableOffering";
import { AlertType } from "app/ui/designSystem/molecules/buttons/PrimaryQuantityButton";
import theme from "app/styles/theme";
import { colorUtils } from "app/styles/utils";
import { DialogType } from "app/types/ui/Dialog";
import { setDialog } from "app/reducers/ui";
import { canViewSignupShopping } from "app/selectors";

export interface CartItemPurchaseButtonsProps {
  className?: string;
  offering: ViewableOffering;
  onDecrement?: () => void;
  onIncrement?: () => void;
  popperClassName?: string;
  removeItemFromCart: () => void;
  showUndo: boolean;
}

const CartItemPurchaseButtons: React.FC<CartItemPurchaseButtonsProps> = ({
  className,
  offering,
  onDecrement,
  onIncrement,
  removeItemFromCart,
  showUndo,
}) => {
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [optimisticQuantity, setOptimisticQuantity] = useState(
    offering.quantity
  );
  const alertType = useMediumScreen() ? AlertType.SNACKBAR : AlertType.TOOLTIP;
  const isInSignupShopping = !!useSelector(canViewSignupShopping);

  const setQuantity = useCallback(
    _debounce(
      (updatedQuantity) =>
        dispatch(
          updateVariantQuantity({
            variantId: offering.variantId,
            quantity: updatedQuantity,
            source: "Cart",
            isInSignupShopping,
          })
        ),
      UI_DEBOUNCE_RATE
    ),
    []
  );

  // replace the optimisticQuantity when the correct value when the offering is updated
  // this is important when out stock factors change the quantity outside of this component
  useEffect(() => {
    setOptimisticQuantity(offering.quantity);
  }, [offering]);

  // show a confirmation dialog once before decrementing out of stock offerings
  const [removeOutOfStockDialogSeen, setRemoveOutofStockDialogSeen] = useState(
    false
  );

  const setRemoveOutOfStockDialog = () => {
    dispatch(
      setDialog({
        type: DialogType.REMOVE_OUT_OF_STOCK,
        offeringName: offering.name,
        quantity: offering.quantity - 1,
        shelfId: undefined,
        source: "Cart",
        variantId: offering.variantId,
      })
    );
    setRemoveOutofStockDialogSeen(true);
  };

  const handleIncrement = () => {
    if (onIncrement) onIncrement();
    const updatedQuantity = optimisticQuantity + 1;
    if (updatedQuantity <= offering.maxQuantity) {
      setOptimisticQuantity(updatedQuantity);
      setQuantity(updatedQuantity);
    }
  };

  const handleDecrement = () => {
    if (!offering.hasStock && !removeOutOfStockDialogSeen) {
      setRemoveOutOfStockDialog();
      return;
    }

    if (onDecrement) onDecrement();
    const updatedQuantity = optimisticQuantity - 1;
    if (updatedQuantity >= 1) {
      setOptimisticQuantity(updatedQuantity > 0 ? updatedQuantity : 0);
      setQuantity(updatedQuantity);
    } else {
      removeItemFromCart();
    }
  };

  const quantityNumberArray = Array.from(String(optimisticQuantity), Number);

  if (quantityNumberArray.length === 1) {
    quantityNumberArray.unshift(0);
  }

  const snackbarPortal = document.getElementById(SnackbarContainerId.ROOT);

  const alertText = () => {
    if (optimisticQuantity === offering.maxQuantity) {
      return `Limit ${offering.maxQuantity} per customer`;
    }
    if (!offering.hasStock) {
      return `This item is out of stock`;
    }
    return "";
  };

  const incrementAlertElement = () => {
    const incrementFailedText = alertText();
    if (incrementFailedText) {
      if (alertType === AlertType.TOOLTIP) {
        return (
          <IncrementControlTooltip
            arrow
            enterTouchDelay={0}
            placement="top-end"
            title={incrementFailedText}
          >
            <IncrementAndDecrementButton />
          </IncrementControlTooltip>
        );
      }
      if (alertType === AlertType.SNACKBAR) {
        return (
          <IncrementAndDecrementButton
            tabIndex={showUndo ? -1 : 0}
            onKeyDown={() => setSnackbarOpen(true)}
            onClick={() => setSnackbarOpen(true)}
            aria-label="Add"
            aria-controls={`productQuantity${offering.productId}`}
            role="button"
          />
        );
      }
    }
    return (
      <IncrementAndDecrementButton
        tabIndex={showUndo ? -1 : 0}
        onKeyDown={onEnterOrSpaceDown(handleIncrement)}
        onClick={handleIncrement}
        aria-label="Add"
        aria-controls={`productQuantity${offering.productId}`}
        role="button"
      />
    );
  };

  return (
    <PurchaseButtonsWrapper
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <BackgroundContainer>
        {optimisticQuantity === 1 ? (
          <Delete fontSize="small" />
        ) : (
          <Remove fontSize="small" />
        )}
        <ScreenReaderQuantity
          id={`productQuantity${offering.productId}`}
          aria-live="polite"
          aria-atomic="true"
        >
          {optimisticQuantity}
        </ScreenReaderQuantity>
        <ScrollingNumbersContainer>
          <ScrollingNumber
            value={quantityNumberArray[0]}
            fontWeight={theme.fonts.weight.semiBold}
            shouldDisappearWhenZero
          />
          <ScrollingNumber
            value={quantityNumberArray[1]}
            fontWeight={theme.fonts.weight.semiBold}
          />
        </ScrollingNumbersContainer>
        <Add
          fontSize="small"
          color={
            optimisticQuantity >= offering.maxQuantity || !offering.hasStock
              ? "disabled"
              : "inherit"
          }
        />
      </BackgroundContainer>
      <IncrementAndDecrementButton
        tabIndex={showUndo ? -1 : 0}
        onKeyDown={onEnterOrSpaceDown(handleDecrement)}
        onClick={handleDecrement}
        aria-label="Remove"
        aria-controls={`productQuantity${offering.productId}`}
        role="button"
      />
      {incrementAlertElement()}
      <Portal node={snackbarPortal}>
        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={alertText()}
          ContentProps={{ variant: "outlined" }}
          action={
            <CloseSnackbarButton onClick={() => setSnackbarOpen(false)}>
              Dismiss
            </CloseSnackbarButton>
          }
        />
      </Portal>
    </PurchaseButtonsWrapper>
  );
};

// this is a hidden div for screen readers to read the quantity
const ScreenReaderQuantity = styled.div`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

const PurchaseButtonsWrapper = styled.div`
  height: ${rem(44)};
  width: ${rem(88)};
  display: flex;
  position: relative;
`;

// this is a click target area and does not contain an image, used for both + and -
const IncrementAndDecrementButton = styled.span`
  height: 100%;
  width: 50%;
  cursor: pointer;
  z-index: 1;

  &:focus {
    border: ${rem(2)} solid ${({ theme }) => theme.colors.beet};
    outline: none;
    background: ${({ theme }) => colorUtils.alpha(theme.colors.beet, 0.2)};
    border-radius: ${rem(8)};
    transform: scale(0.7);
  }
`;

const ScrollingNumbersContainer = styled.div`
  margin-left: ${rem(2)};
`;

const BackgroundContainer = styled.span`
  height: ${rem(32)};
  width: ${rem(76)};
  margin: ${rem(6)};
  padding: ${rem(4)} ${rem(8)};
  background-color: ${theme.colors.baba};
  border-radius: ${rem(8)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Snackbar = styled(MuiSnackbar)`
  && {
    bottom: ${rem(68)};
    width: 100%;
    padding: 0 ${rem(16)};
  }

  & .MuiSnackbarContent-root {
    width: 100%;
    padding: ${rem(12)} ${rem(16)};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${rem(15)};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
    line-height: ${rem(24)};
    background-color: ${({ theme }) => theme.colors.peppercorn};
  }
`;

const IncrementControlTooltip = styled((props) => (
  <MuiTooltip classes={{ popper: props.popperClassName }} {...props} />
))`
  & .MuiTooltip-tooltipPlacementTop {
    margin: ${rem(0)};
  }
  & .MuiTooltip-tooltip {
    padding: ${rem(8)};
    font-size: ${rem(13)};
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    line-height: ${rem(14)};
    background-color: ${({ theme }) => theme.colors.textBlack};
  }
  & .MuiTooltip-arrow {
    color: ${({ theme }) => theme.colors.textBlack};
  }
`;

const CloseSnackbarButton = styled(MuiButton)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${rem(14)};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: ${rem(24)};
  text-transform: uppercase;
`;

export default CartItemPurchaseButtons;
