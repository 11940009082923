// @ts-nocheck
import styled from "styled-components";
import { rem } from "polished";

import { StrippedLink } from "app/ui/shared/Link";

const UnstyledLink = styled(StrippedLink)`
  color: inherit;
  cursor: pointer;
  text-decoration: inherit;
  &:active {
    color: inherit;
  }

  &:focus {
    outline: ${rem(3)} solid transparent;
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.eggplant};
    text-decoration: underline;
  }
`;

export default UnstyledLink;
