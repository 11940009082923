import { call, select } from "typed-redux-saga";

import { trackEvent } from "app/api/segment";
import {
  RECURRING_ITEM_MODAL_ID,
  EXPLORE_ADDONS_MODAL,
  ORDER_DETAILS_MODAL_ID,
  OOA_ZIP_CHANGE_MODAL_ID,
  PDP_IMPERFECTIONS_MODAL,
  PDP_INTENTIONAL_SOURCING_MODAL,
  TERMS_CONDITIONS_ACCEPT_MODAL,
  CANCEL_SUBSCRIPTION_MODAL,
} from "app/constants/modals";
import { waitForInitialization } from "app/sagas/waitForInitialization";
import { getSecondaryBoxModalById } from "app/selectors";
import Modal from "app/types/ui/Modal";
import { NEVER_LIST_MODAL_ID } from "app/ui/global/modals/ListModalWrapper";
import { NEVER_LIST_INFO_MODAL } from "app/ui/global/modals/NeverListFeatureIntro";

function* trackModalActions({
  modalId,
  params,
  visible,
  shouldTrackAnalytics,
}: Modal) {
  // Wait for initialization to complete
  yield call(waitForInitialization);

  switch (modalId) {
    case NEVER_LIST_MODAL_ID: {
      if (visible && params?.offering) {
        // const { inNeverList } = params;
        // NOTE: This is not a "true" offering
        // It is the algolia response for index neverlist
        // inNeverList appears to never be passed at all?
        const { productId, inNeverList } = params.offering;
        const eventName = inNeverList
          ? "Viewed Remove from Never List Modal"
          : "Viewed Add to Never List Modal";
        yield* call(trackEvent, eventName, { productId });
      }
      break;
    }
    case NEVER_LIST_INFO_MODAL: {
      if (visible) {
        yield* call(trackEvent, "Viewed Never List Info Modal");
      }
      break;
    }
    case RECURRING_ITEM_MODAL_ID: {
      if (visible && params?.offering) {
        const { inRecurringItems } = params;
        // NOTE: This is not a "true" offering
        // It is the algolia response for index alwayslist_name_sorted
        // There is no variantId, so switched to productId
        const { productId } = params.offering;
        const eventName = inRecurringItems
          ? "Viewed Remove from Recurring Items Modal"
          : "Viewed Add to Recurring Items Modal";
        yield* call(trackEvent, eventName, {
          productId,
        });
      }
      break;
    }
    case CANCEL_SUBSCRIPTION_MODAL: {
      const eventName = visible
        ? "Subscription Cancellation Modal Opened"
        : "Subscription Cancellation Modal Closed";
      yield* call(trackEvent, eventName);
      break;
    }
    case TERMS_CONDITIONS_ACCEPT_MODAL: {
      const eventName = visible
        ? "Terms & Conditions Acceptance Modal Opened"
        : "Terms & Conditions Acceptance Modal Closed";
      yield* call(trackEvent, eventName);
      break;
    }
    // Add-Ons from main My Account
    case EXPLORE_ADDONS_MODAL: {
      if (visible && params?.box) {
        let box;
        if (!params?.box?.displayName) {
          // fetch box info if on my account, sign up pages will contain box information
          box = yield select(getSecondaryBoxModalById);
        }
        const { id: boxId, displayName: boxName } = params.box;

        yield* call(trackEvent, "My Account Add-On Modal Opened", {
          modalId,
          boxId,
          boxName: boxName || box.displayName,
        });
      }
      break;
    }

    case ORDER_DETAILS_MODAL_ID: {
      if (visible && shouldTrackAnalytics) {
        const location = params.location || null;
        yield* call(trackEvent, "Order History Modal Opened", { location });
      }
      break;
    }
    case OOA_ZIP_CHANGE_MODAL_ID: {
      let eventName = visible
        ? "OOA Zip Change Modal Opened"
        : "OOA Zip Change Modal Closed";

      interface EventParams {
        modalId: string;
        promoCode?: string | null;
      }

      const eventParams: EventParams = {
        modalId,
      };

      if (params.misfitsZipDeliverable && visible) {
        eventName = "IF to MM Signup Redirect Modal Opened";
        eventParams.promoCode = params.promo?.code || null;
      }

      yield* call(trackEvent, eventName, eventParams);
      break;
    }

    case PDP_IMPERFECTIONS_MODAL: {
      yield* call(
        trackEvent,
        `PDP Imperfections Modal ${visible ? "Opened" : "Closed"}`,
        params
      );
      break;
    }

    case PDP_INTENTIONAL_SOURCING_MODAL: {
      yield* call(
        trackEvent,
        `PDP Intentional Sourcing Modal ${visible ? "Opened" : "Closed"}`,
        params
      );
      break;
    }

    default:
      break;
  }
}

export default trackModalActions;
