import { ISplitState } from "@splitsoftware/splitio-redux/lib/types";

import ExtendedSplitState from "./extendedSplit/ExtendedSplitState";

import AccountState from "app/types/state/account/Account";
import AislesState from "app/types/state/aisles";
import AnalyticsState from "app/types/state/analytics";
import AuthState from "app/types/state/auth";
import CatalogState from "app/types/state/catalog";
import DeliveriesState from "app/types/state/deliveries/deliveries";
import {
  ExtendedRouterState,
  RouterState,
} from "app/types/state/extendedRouter";
import OfferingsState from "app/types/state/offerings/OfferingsState";
import OrderState from "app/types/state/orders";
import SearchState from "app/types/state/search/SearchState";
import ShelvesState from "app/types/state/shelves";
import SignupState from "app/types/state/signup";
import UIState from "app/types/state/ui";
import MobileState from "app/types/state/mobile";
import ReactivationState from "app/types/state/reactivation/ReactivationState";
import FriendbuyState from "app/types/state/friendbuy";
import MisfitsState from "app/types/state/misfits";

interface DefinedState {
  auth: AuthState;
  router: RouterState;
  extendedRouter: ExtendedRouterState;
  aisles: AislesState;
  shelves: ShelvesState;
  catalog: CatalogState;
  ui: UIState;
  signup: SignupState;
  orders: OrderState;
  analytics: AnalyticsState;
  deliveries: DeliveriesState;
  account: AccountState;
  offerings: OfferingsState;
  search: SearchState;
  splitio: ISplitState;
  extendedSplit: ExtendedSplitState;
  mobile: MobileState;
  reactivation: ReactivationState;
  friendbuy: FriendbuyState;
  misfits: MisfitsState;
}

interface PlaceHolderState {
  // TODO: Flesh this out with actual state once all reducers are types
  [key: string]: unknown;
}

type State = DefinedState & PlaceHolderState;

export default State;

// https://redux.js.org/tutorials/essentials/part-5-async-logic#loading-state-for-requests

export enum LoadingStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}
