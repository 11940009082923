import { useEffect } from "react";

import theme from "app/styles/theme";
import { scrollWithOffset } from "app/ui/global/utils";

export default function useScrollWithOffset(
  elementId: string,
  offset: number = theme.layout.headerHeight,
  smooth: boolean = false
) {
  useEffect(() => {
    if (elementId) {
      const el = document.getElementById(elementId);
      if (el) {
        // If we ever use scroll-to-anchor tags on mobile
        // this will need to be smarter
        scrollWithOffset(el, offset, smooth);
      }
    }
  }, [elementId]);
}
