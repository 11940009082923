import React from "react";
import { useSelector } from "react-redux";

import { getAisleListData } from "app/selectors";
import Aisle from "app/types/state/aisles/Aisle";
import NavTabs, { NavTabData } from "app/ui/designSystem/molecules/NavTabs";

export interface MobileNavigationScrollingProps
  extends React.HTMLAttributes<HTMLDivElement> {
  currentAisle?: Aisle;
}

const MobileNavigationScrolling: React.FC<MobileNavigationScrollingProps> = ({
  currentAisle,
}) => {
  const aisleListItems = useSelector(getAisleListData);

  const allTabs: NavTabData[] = aisleListItems.map((aisle) => ({
    label: aisle.label,
    href: `${aisle.to.pathname}${aisle.to.hash}`,
    disabled: false,
  }));

  const getSelectedTabIndex = () => {
    if (currentAisle) {
      const currentAisleIndex = aisleListItems.findIndex(
        (aisle) => aisle.id === currentAisle.aisleId
      );
      if (currentAisleIndex !== -1) {
        return currentAisleIndex;
      }
    }
    return -1;
  };

  const intialTabIndex = getSelectedTabIndex();

  return (
    <NavTabs
      allTabs={allTabs}
      getSelectedTabIndex={getSelectedTabIndex}
      indexOfTabInitiallyOpen={intialTabIndex}
    />
  );
};

export default MobileNavigationScrolling;
