import { cover } from "polished";
import React, { RefObject, useCallback, useRef } from "react";
import { Portal } from "react-portal";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import useBodyScrollLock from "app/ui/hooks/useBodyScrollLock";
import useWindowEvent from "app/ui/hooks/useWindowEvent";

type MaskWithFadeTransitionComponentTheme = "default" | "dark" | "opaqueWhite";

export interface MaskWithFadeTransitionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  onClick?: () => void;
  componentTheme?: MaskWithFadeTransitionComponentTheme;
  scrollLock?: boolean;
  timeout?: number;
  toggleRef?: RefObject<HTMLButtonElement>;
  top?: number;
}

/**
 * Disaplys the passed in children elements on top of a
 * mask overlay that fades in/out when mounted/unmounted
 */
export const MaskWithFadeTransition: React.FC<MaskWithFadeTransitionProps> = ({
  visible,
  children,
  onClick = () => null,
  componentTheme = "default",
  scrollLock = false,
  timeout = 500,
  toggleRef,
  top,
  ...props
}) => {
  const addBodyScrollLock = () => {
    if (scrollLock) {
      document.body.classList.add("disallow-scroll");
    }
  };
  const removeBodyScrollLock = () => {
    if (scrollLock) {
      document.body.classList.remove("disallow-scroll");
    }
  };

  return (
    <CSSTransition
      classNames="fade"
      in={visible}
      timeout={timeout}
      mountOnEnter
      unmountOnExit
      onEntered={addBodyScrollLock}
      onExit={removeBodyScrollLock}
    >
      <Portal>
        <Mask
          onClick={onClick}
          componentTheme={componentTheme}
          scrollLock={scrollLock}
          timeout={timeout / 1000}
          top={top}
          toggleRef={toggleRef}
          {...props}
        >
          {children}
        </Mask>
      </Portal>
    </CSSTransition>
  );
};

interface MaskProps extends React.HTMLAttributes<HTMLDivElement> {
  timeout: number;
  onClick: () => void;
  componentTheme: MaskWithFadeTransitionComponentTheme;
  scrollLock: boolean;
  top?: number;
  toggleRef?: RefObject<HTMLButtonElement>;
}

/**
 * Displays the passed in children elements on top of a
 * mask overlay
 */
const Mask: React.FC<MaskProps> = ({
  children,
  onClick,
  componentTheme,
  scrollLock,
  timeout,
  toggleRef,
  top,
  ...props
}) => {
  const maskRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = useCallback((e: Event) => {
    const outsideClick =
      !maskRef?.current?.contains(e.target as HTMLElement) &&
      !toggleRef?.current?.contains(e.target as HTMLElement);
    if (outsideClick) onClick();
  }, []);
  useWindowEvent("mousedown", handleOutsideClick);
  useBodyScrollLock(scrollLock);

  return (
    <MaskOverlay
      ref={maskRef}
      onClick={onClick}
      $componentTheme={componentTheme}
      $timeout={timeout}
      $top={top}
      {...props}
    >
      {children}
    </MaskOverlay>
  );
};

interface MaskOverlayProps {
  $componentTheme: MaskWithFadeTransitionComponentTheme;
  $timeout: number;
  $top?: number;
}

const MaskOverlay = styled.div<MaskOverlayProps>`
  ${cover()}
  ${({ $top }) => $top && `top: ${$top}px;`}
  position: fixed;
  background-color: ${({ $componentTheme, theme }) => {
    switch ($componentTheme) {
      case "dark":
        return "rgba(0, 0, 0, 0.5)";
      case "opaqueWhite":
        return theme.colors.white;
      default:
        return "rgba(255, 255, 255, 0.15)";
    }
  }};
  transition: ${({ $timeout }) => `opacity ${$timeout}s;`};
  z-index: ${({ theme }) => theme.layout.zIndex.modal};

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }
`;
