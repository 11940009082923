import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";

import { getRoutingLocation } from "app/selectors";
import { setCatalogNavigateUrl } from "app/reducers/ui";

export interface NavTabData {
  label: string;
  href: string;
  disabled?: boolean;
}

export interface NavTabsProps {
  allTabs: Array<NavTabData>;
  indexOfTabInitiallyOpen?: number | null;
  scrolling?: boolean;
  scrollButtons?: boolean;
  getSelectedTabIndex: () => number;
}

const TabProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const NavTabs: React.FC<NavTabsProps> = ({
  allTabs,
  indexOfTabInitiallyOpen,
  scrolling = true,
  scrollButtons = false,
  getSelectedTabIndex,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    indexOfTabInitiallyOpen ?? allTabs.findIndex((tab) => !tab.disabled)
  );

  const dispatch = useDispatch();

  const handleChange = (
    _event: React.SyntheticEvent<EventTarget>,
    newValue: number
  ) => {
    setActiveTabIndex(newValue);
    dispatch(setCatalogNavigateUrl(allTabs[newValue].href));
  };

  // if the hash changes, check if we should change the tab
  const location = useSelector(getRoutingLocation);
  useEffect(() => {
    const newActiveTabIndex = getSelectedTabIndex();
    if (newActiveTabIndex !== -1 && newActiveTabIndex !== activeTabIndex) {
      const activeElement = document.activeElement as HTMLElement;
      if (activeElement.blur) {
        activeElement.blur();
      }
      setActiveTabIndex(newActiveTabIndex);
    }
  }, [location.hash]);

  const handleClick = (event) => {
    // disable link from here and let the setCatalogNavigateUrl consumer do the route push
    event.preventDefault();
  };

  return (
    <SCBox>
      <SCTabs
        value={activeTabIndex}
        onChange={handleChange}
        aria-label="Navigation Tabs"
        variant={scrolling ? "scrollable" : "standard"}
        scrollButtons={scrollButtons ? "auto" : "off"}
        $scrollable={!!scrolling}
      >
        {allTabs.map((tab, i) => {
          return (
            <SCTab
              key={tab.label}
              component="a"
              onClick={handleClick}
              href={tab.href}
              label={tab.label}
              aria-label={tab.label}
              disableRipple={false}
              disabled={tab.disabled}
              {...TabProps(i)}
            />
          );
        })}
      </SCTabs>
    </SCBox>
  );
};

const SCBox = styled(Box)`
  height: ${rem(48)};
`;

const SCTabs = styled(Tabs)<{ $scrollable: boolean }>`
  overflow: visible;
  min-height: ${rem(48)};
  ${({ $scrollable }) =>
    !$scrollable &&
    `
    & .MuiTabs-scroller {
      overflow: visible !important;
    }
  `}
  & .MuiTabs-indicator {
    height: ${rem(3)};
    border-radius: ${rem(3)};
    padding: 0;
    background-color: ${({ theme }) => theme.colors.beet};
  }
  & .MuiTabs-flexContainer button:first-child {
    margin-left: 0;
  }
`;

const SCTab = styled(Tab)`
  color: ${({ theme }) => theme.colors.peppercorn};
  border: ${rem(1)} solid transparent;
  border-bottom: ${rem(3)} solid transparent;
  min-height: unset;
  min-width: unset;
  padding: 0 ${rem(8)};
  margin: 0;

  &:focus {
    outline: none;
  }

  .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.colors.beet};
  }
  .MuiTab-wrapper {
    height: ${rem(42)};
    padding-top: ${rem(12)};
  }
` as typeof Tab;

export default NavTabs;
