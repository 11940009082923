import MuiCard from "@material-ui/core/Card";
import MuiCardContent from "@material-ui/core/CardContent";
import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import { ellipsis, rem } from "polished";
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { RECURRING_ITEM_MODAL_ID } from "app/constants/modals";
import { updateVariantQuantity } from "app/reducers/orders";
import {
  showModal,
  offeringCardClicked,
  addRecipeIngredientToState,
  setDialog,
} from "app/reducers/ui";
import { toShopProductDetailPage } from "app/router/routes";
import ViewableOffering from "app/types/selectors/crossDomain/ViewableOffering";
import {
  SourcingData,
  SourcingReason,
} from "app/types/state/offerings/Offering";
import Shelf from "app/types/state/shelves/Shelf";
import { UI_DEBOUNCE_RATE, getImageURL } from "app/ui/global/utils";
import useSafeHoverState from "app/ui/hooks/useSafeHoverState";
import OfferingBrandName from "app/ui/shared/OfferingDetail/OfferingBrandName";
import OfferingFromLocation from "app/ui/shared/OfferingDetail/OfferingFromLocation";
import OfferingNameText, {
  StyledWeight,
} from "app/ui/shared/OfferingDetail/OfferingNameText";
import Price, { PriceSizes } from "app/ui/shared/OfferingDetail/Price";
import UnstyledLink from "app/ui/shared/UnstyledLink";
import OfferingImage from "app/ui/shopping/OfferingPreviewCard/OfferingImage";
import ImperfectionTags from "app/ui/designSystem/molecules/Tag/ImperfectionTags";
import QuantityButton, {
  AlertType,
} from "app/ui/designSystem/molecules/buttons/PrimaryQuantityButton";
import { RECURRING_ITEMS_NUDGE_THROTTLE } from "app/ui/shopping/constants";
import { DialogType } from "app/types/ui/Dialog";
import { isInIntentionalSourcingTreatment } from "app/selectors";

export enum ProfileType {
  SLIM = "slim",
  REGULAR = "regular",
}

export const getPPCConfigFromProfileType = (profileType: ProfileType) => ({
  ar: profileType === ProfileType.REGULAR ? undefined : "1:1",
  height: profileType === ProfileType.REGULAR ? 160 : undefined,
  width: profileType === ProfileType.REGULAR ? undefined : 136,
  dpr: profileType === ProfileType.REGULAR ? 1.6 : 2.9,
  b: profileType === ProfileType.REGULAR ? "auto" : undefined,
  c: profileType === ProfileType.REGULAR ? "pad" : "fill",
});

const getImperfections = (sourcingData: SourcingData[] | null) => {
  if (!sourcingData) return [];
  const imperfections: string[] = [];
  sourcingData.forEach((source: SourcingData) => {
    source.reasons.forEach((reason: SourcingReason) => {
      imperfections.push(reason.name);
    });
  });
  return imperfections;
};

export interface OfferingPreviewCardProps {
  profileType?: ProfileType;
  offering: ViewableOffering | null;
  className?: string;
  source: string;
  allowRecurringItemsNudge: boolean;
  shouldShowRecurringNudge: boolean;
  timeOfLastAttemptedNudge: number;
  rank?: number;
  fromShelfId?: string;
  isRecipe?: boolean;
  shelfInfo: Shelf;
  isInSignupShopping?: boolean;
}

const OfferingPreviewCard: React.FC<OfferingPreviewCardProps> = ({
  profileType = ProfileType.REGULAR,
  offering,
  className,
  source,
  allowRecurringItemsNudge,
  shouldShowRecurringNudge,
  timeOfLastAttemptedNudge,
  rank,
  fromShelfId,
  isRecipe,
  shelfInfo,
  isInSignupShopping = false,
}) => {
  // What should be displayed if there is no offering in state?
  // Very unlinkely edge case
  if (!offering || _isEmpty(offering)) return null;

  const dispatch = useDispatch();
  const cardContainerRef = useRef(null);
  const [active, setActive] = useSafeHoverState(undefined, cardContainerRef);
  const [removeOutOfStockDialogSeen, setRemoveOutofStockDialogSeen] = useState(
    false
  );

  const [optimisticQuantity, setOptimisticQuantity] = useState(
    offering.quantity
  );

  useEffect(() => {
    setOptimisticQuantity(offering.quantity);
  }, [offering.quantity]);

  const isProductInCart = optimisticQuantity > 0;
  const alertType =
    profileType === ProfileType.REGULAR
      ? AlertType.TOOLTIP
      : AlertType.SNACKBAR;

  const Profile =
    profileType === ProfileType.REGULAR ? RegularProfile : SlimProfile;

  const linkToDetail = toShopProductDetailPage({
    variantId: offering.variantId,
    fromShelfId,
    isInSignupShopping,
  });

  const trackOfferingCardClicked = () => {
    dispatch(
      offeringCardClicked({
        variantId: offering.variantId,
        rank,
        objectID: offering.objectID,
      })
    );
  };

  const formattedOfferingName = offering.brandName
    ? offering.name.replace(new RegExp(`^${offering.brandName}`, "i"), "")
    : offering.name;

  const setQuantity = useCallback(
    _debounce(
      (updatedQuantity) =>
        dispatch(
          updateVariantQuantity({
            variantId: offering.variantId,
            shelfId: fromShelfId,
            quantity: updatedQuantity,
            source,
            rank,
            isInSignupShopping,
          })
        ),
      UI_DEBOUNCE_RATE
    ),
    []
  );
  const addRecipeIngredient = useCallback(
    _debounce(() => {
      const products = [offering];
      const recipeUrl = shelfInfo.promotionLink || "";
      const recipeName = shelfInfo.name || "";
      const recipeImage = getImageURL(shelfInfo.promotionImageFilename || "");
      const { shelfId } = shelfInfo;
      return dispatch(
        addRecipeIngredientToState(
          products,
          shelfId,
          recipeName,
          recipeUrl,
          recipeImage
        )
      );
    }, UI_DEBOUNCE_RATE),
    []
  );

  const handleDecrement = () => {
    if (offering && !offering.hasStock && !removeOutOfStockDialogSeen) {
      dispatch(
        setDialog({
          type: DialogType.REMOVE_OUT_OF_STOCK,
          offeringName: offering.name,
          quantity: offering.quantity - 1,
          rank,
          shelfId: fromShelfId,
          source,
          variantId: offering.variantId,
        })
      );
      setRemoveOutofStockDialogSeen(true);
      return;
    }

    const updatedQuantity = optimisticQuantity - 1;
    setOptimisticQuantity(updatedQuantity);
    setQuantity(updatedQuantity);
  };

  const handleIncrement = () => {
    const updatedQuantity = optimisticQuantity + 1;
    setOptimisticQuantity(updatedQuantity);
    setQuantity(updatedQuantity);
    showRecurringModal(optimisticQuantity);
    if (isRecipe) {
      addRecipeIngredient();
    }
  };

  const showRecurringModal = (quantity: number) => {
    const initialIncrement = quantity < 1;
    const shouldShowRecurringOffer =
      shouldShowRecurringNudge &&
      offering?.availableForRecurring &&
      !offering?.inRecurringItems;

    if (initialIncrement && shouldShowRecurringOffer) {
      const currentTime = Date.now();
      const secondsSinceLastNudge =
        (currentTime - timeOfLastAttemptedNudge) / 1000;
      if (
        offering &&
        secondsSinceLastNudge > RECURRING_ITEMS_NUDGE_THROTTLE &&
        allowRecurringItemsNudge
      ) {
        dispatch(
          showModal(RECURRING_ITEM_MODAL_ID, {
            displayNudgeToggle: true,
            inRecurringItems: false,
            offering: {
              productId: offering.productId,
              variantId: offering.variantId,
              name: offering.name,
              imageFilename: offering.imageFilename,
            },
          })
        );
      }
    }
  };

  return (
    <Profile
      $isProductInCart={isProductInCart}
      square
      variant="outlined"
      data-testid={`offering-preview-card-${profileType}`}
      data-clickid="offering-preview-card"
      className={className}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      ref={cardContainerRef}
    >
      <LinkToDetail
        to={linkToDetail}
        aria-label={formattedOfferingName}
        onClick={trackOfferingCardClicked}
      />
      {profileType === ProfileType.REGULAR && (
        <CardContentRegular
          active={active}
          alertType={alertType}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          isProductInCart={isProductInCart}
          offering={offering}
          optimisticQuantity={optimisticQuantity}
        />
      )}
      {profileType === ProfileType.SLIM && (
        <CardContentSlim
          active={active}
          alertType={alertType}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          isProductInCart={isProductInCart}
          offering={offering}
          optimisticQuantity={optimisticQuantity}
        />
      )}
      <OfferingImage
        profileType={profileType}
        productId={offering.productId}
        variantId={offering.variantId}
        name={offering.name}
        imageFilename={offering.imageFilename}
        hasStock={offering.hasStock}
        inNeverList={offering.inNeverList}
        inRecurringItems={offering.inRecurringItems}
        fromShelfId={fromShelfId}
        percentOff={offering.percentOff}
        flags={offering.flags}
        retailPriceDiscount={offering.retailPriceDiscount}
        shouldShowRetailProductFlag={offering.shouldShowRetailProductFlag}
        saleDiscountInDollars={offering.saleDiscountInDollars}
        scalePriceFormatted={offering.scalePriceFormatted}
        scalePricePercentOff={offering.scalePricePercentOff}
      />
    </Profile>
  );
};

interface CardContentProps {
  active?: boolean;
  alertType: AlertType;
  handleDecrement: () => void;
  handleIncrement: () => void;
  isProductInCart: boolean;
  offering: ViewableOffering;
  optimisticQuantity: number;
}

const CardContentRegular: React.FC<CardContentProps> = ({
  active,
  alertType,
  handleDecrement,
  handleIncrement,
  isProductInCart,
  offering,
  optimisticQuantity,
}) => {
  const isInIntentionalSourcing = useSelector(isInIntentionalSourcingTreatment);
  const imperfections = useMemo(() => {
    if (offering.sourcingData == null) return [];
    if (isInIntentionalSourcing) {
      return getImperfections(offering.sourcingData);
    }
    return getImperfections(
      offering.sourcingData.filter((sd) => sd.sourcingType === "imperfections")
    );
  }, [offering, isInIntentionalSourcing]);

  const maxImperfections = isInIntentionalSourcing ? 1 : 2;

  return (
    <CardContent
      $hasStock={offering.hasStock}
      $isProductInCart={isProductInCart}
      $profileType={ProfileType.REGULAR}
    >
      <div>
        {offering.brandName && (
          <StyledOfferingBrandName
            brandName={offering.brandName}
            $profileType={ProfileType.REGULAR}
          />
        )}
        {!offering.brandName && offering.fromLocation && (
          <StyledOfferingFromLocation
            fromLocation={offering.fromLocation}
            $profileType={ProfileType.REGULAR}
          />
        )}
        <OfferingNameWrapper
          $overflowLines={offering.brandName || offering.fromLocation ? 2 : 3}
          $profileType={ProfileType.REGULAR}
        >
          <OfferingNameText
            brandName={offering.brandName}
            name={offering.name}
            packagingUnitAmount={offering.packageUnitAmount}
            packageUnitFormatted={offering.packageUnitFormatted}
            realUnitAmount={offering.realUnitAmount}
            realUnitFormatted={offering.realUnitFormatted}
            realUnitIsApproximate={offering.realUnitIsApproximate}
            realUnitIsIndividual={offering.realUnitIsIndividual}
          />
        </OfferingNameWrapper>
        {imperfections.length > 0 && (
          <ImperfectionTags
            imperfections={imperfections.slice(0, maxImperfections)}
            profileType={ProfileType.REGULAR}
          />
        )}
      </div>
      <PriceRow>
        <Price
          fullPrice={offering.scalePriceFormatted ? null : offering.fullPrice}
          activePrice={offering.activePrice}
          retailPrice={offering.retailPrice}
          shouldShowRetailPrice={offering.shouldShowRetailPrice}
          salePriceDiscount={offering.salePriceDiscount}
          retailPriceDiscount={offering.retailPriceDiscount}
          scalePriceFormatted={offering.scalePriceFormatted}
          scaleMinQuantity={offering.scaleMinQuantity}
        />
        <QuantityButtonWrapper>
          <QuantityButton
            entity={offering.name}
            activeParent={active}
            alertType={alertType}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            hasStock={offering.hasStock}
            hoverEffect
            quantity={optimisticQuantity}
            maxQuantity={offering.maxQuantity}
            maxQuantityMessage={`Limit ${offering.maxQuantity} per customer`}
          />
        </QuantityButtonWrapper>
      </PriceRow>
    </CardContent>
  );
};

const CardContentSlim: React.FC<CardContentProps> = ({
  active,
  alertType,
  handleDecrement,
  handleIncrement,
  isProductInCart,
  offering,
  optimisticQuantity,
}) => {
  const isInIntentionalSourcing = useSelector(isInIntentionalSourcingTreatment);
  const imperfections = useMemo(() => {
    if (offering.sourcingData == null) return [];
    if (isInIntentionalSourcing) {
      return getImperfections(offering.sourcingData);
    }
    return getImperfections(
      offering.sourcingData.filter((sd) => sd.sourcingType === "imperfections")
    );
  }, [offering, isInIntentionalSourcing]);

  return (
    <CardContent
      $hasStock={offering.hasStock}
      $isProductInCart={isProductInCart}
      $profileType={ProfileType.SLIM}
    >
      {offering.brandName && (
        <StyledOfferingBrandName
          brandName={offering.brandName}
          $profileType={ProfileType.SLIM}
        />
      )}
      {!offering.brandName && offering.fromLocation && (
        <StyledOfferingFromLocation
          fromLocation={offering.fromLocation}
          $profileType={ProfileType.SLIM}
        />
      )}
      <OfferingNameWrapper
        $overflowLines={offering.brandName || offering.fromLocation ? 2 : 3}
        $profileType={ProfileType.SLIM}
      >
        <OfferingNameText
          brandName={offering.brandName}
          name={offering.name}
          packagingUnitAmount={offering.packageUnitAmount}
          packageUnitFormatted={offering.packageUnitFormatted}
          realUnitAmount={offering.realUnitAmount}
          realUnitFormatted={offering.realUnitFormatted}
          realUnitIsApproximate={offering.realUnitIsApproximate}
          realUnitIsIndividual={offering.realUnitIsIndividual}
        />
      </OfferingNameWrapper>
      <AdditionalContentWrapper>
        <ImperfectionsAndPriceWrapper
          $withoutImperfections={!!(imperfections?.length === 0)}
        >
          {imperfections.length > 0 && (
            <ImperfectionTags
              imperfections={imperfections.slice(0, 1)}
              profileType={ProfileType.SLIM}
            />
          )}
          <Price
            fullPrice={offering.scalePriceFormatted ? null : offering.fullPrice}
            activePrice={offering.activePrice}
            retailPrice={offering.retailPrice}
            shouldShowRetailPrice={offering.shouldShowRetailPrice}
            salePriceDiscount={offering.salePriceDiscount}
            retailPriceDiscount={offering.retailPriceDiscount}
            scalePriceFormatted={offering.scalePriceFormatted}
            scaleMinQuantity={offering.scaleMinQuantity}
            size={PriceSizes.SMALL_RESPONSIVE}
          />
        </ImperfectionsAndPriceWrapper>
        <QuantityButtonWrapper>
          <QuantityButton
            entity={offering.name}
            activeParent={active}
            alertType={alertType}
            buttonSize={34}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            hasStock={offering.hasStock}
            hoverEffect={false}
            quantity={optimisticQuantity}
            maxQuantity={offering.maxQuantity}
            maxQuantityMessage={`Limit ${offering.maxQuantity} per customer`}
          />
        </QuantityButtonWrapper>
      </AdditionalContentWrapper>
    </CardContent>
  );
};

const LinkToDetail = styled(UnstyledLink)`
  position: absolute;
  top: ${rem(-1)}; /* negatives fix double border problem */
  right: ${rem(-1)};
  bottom: ${rem(-3)};
  left: ${rem(-1)};
  z-index: 1;
`;

export const offeringPreviewCardSizes = {
  regularProfileHeight: 320,
  slimProfileHeight: 136,
};

const RegularProfile = styled(MuiCard)<{ $isProductInCart: boolean }>`
  position: relative;
  transition: border-color 200ms ease-in-out;
  transform: translateZ(0);
  border-color: ${({ theme, $isProductInCart }) =>
    $isProductInCart ? theme.colors.pink : theme.colors.grayLighter};
  border-width: ${({ $isProductInCart }) =>
    $isProductInCart ? rem(2) : rem(1)};
  border-style: solid;
  height: ${rem(offeringPreviewCardSizes.regularProfileHeight)};
  &:hover {
    cursor: pointer;
    box-shadow: ${rem(0)} ${rem(0)} ${rem(15)} ${rem(4)} rgba(96, 99, 103, 0.25);
  }
  min-width: ${rem(220)};
  max-width: ${rem(300)};
  flex: 1 1 ${rem(256)};
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  overflow: visible;
`;

const SlimProfile = styled(MuiCard)<{ $isProductInCart: boolean }>`
  position: relative;
  min-width: ${rem(320)};
  max-width: ${rem(479)};
  height: ${rem(offeringPreviewCardSizes.slimProfileHeight)};
  border: ${({ theme }) => `${rem(1)} solid ${theme.colors.grayLighter}`};
  display: flex;
  flex-direction: row-reverse;

  ${({ theme, $isProductInCart }) =>
    $isProductInCart &&
    `
    border-top: ${rem(1)} solid ${theme.colors.pink};
    border-bottom: ${rem(1)} solid ${theme.colors.pink};
  `}
`;

const CardContent = styled(MuiCardContent)<{
  $hasStock: boolean;
  $isProductInCart: boolean;
  $profileType: ProfileType;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: ${rem(12)};
  border-style: solid;
  ${({ theme }) => `border-color: ${theme.colors.white}`};

  ${({ $isProductInCart, $profileType }) =>
    $profileType === ProfileType.REGULAR &&
    `
    justify-content: space-between;
    border-width: ${$isProductInCart ? rem(0) : rem(1)};
  `}

  ${({ $isProductInCart, $profileType }) =>
    $profileType === ProfileType.SLIM &&
    `
    border-width: ${$isProductInCart ? rem(0) : `${rem(1)} ${rem(0)}`};
  `}

  &.MuiCardContent-root {
    padding-bottom: ${rem(12)};
  }
`;

const BrandNameAndFromLocationStyles = css<{
  $profileType: ProfileType;
}>`
  ${({ $profileType }) =>
    $profileType === ProfileType.REGULAR &&
    `
    margin-bottom: ${rem(4)};
    font-size: ${rem(13)};
    line-height: ${rem(15.85)};
  `}

  ${({ $profileType }) =>
    $profileType === ProfileType.SLIM &&
    `
    margin-bottom: ${rem(2)};
    font-size: ${rem(12)};
    line-height: ${rem(14.63)};
  `}
`;

const StyledOfferingBrandName = styled(OfferingBrandName)<{
  $profileType: ProfileType;
}>`
  ${BrandNameAndFromLocationStyles}
`;

const StyledOfferingFromLocation = styled(OfferingFromLocation)<{
  $profileType: ProfileType;
}>`
  ${BrandNameAndFromLocationStyles}
`;

const OfferingNameWrapper = styled.span<{
  $overflowLines: number;
  $profileType: ProfileType;
}>`
  margin-bottom: ${rem(4)};
  color: ${({ theme }) => theme.colors.textBlack};
  font-size: ${rem(20)};
  line-height: ${rem(22)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  ${({ $overflowLines }) => ellipsis(undefined, $overflowLines)};

  ${({ $profileType }) =>
    $profileType === ProfileType.REGULAR &&
    `
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    min-height: ${rem(44)};
  `}

  ${({ $profileType }) =>
    $profileType === ProfileType.SLIM &&
    `
    font-size: ${rem(15)};
    line-height: ${rem(18.29)};
    min-height: ${rem(38)};
  `}

  ${StyledWeight} {
    font-size: ${({ $profileType }) =>
      $profileType === ProfileType.REGULAR ? rem(14) : rem(13)};
    line-height: ${({ $profileType }) =>
      $profileType === ProfileType.REGULAR ? rem(22) : rem(15.85)};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  }
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const AdditionalContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const ImperfectionsAndPriceWrapper = styled.div<{
  $withoutImperfections: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 0;

  ${({ $withoutImperfections }) =>
    $withoutImperfections && `justify-content: flex-end;`};
`;

const QuantityButtonWrapper = styled.div`
  position: absolute;
  right: ${rem(6)};
  bottom: ${rem(6)};
`;

export default OfferingPreviewCard;
