type PLATFORM = "ios" | "android";

export const getAppVersionInfo = (): {
  platform: string;
  version: string | null;
} => {
  const { userAgent } = navigator;
  // Match against our custom userAgent string, that we set from the app
  const appUserAgentRegex = "ImperfectFoodsApp-(ios|android)/(.*)";
  const matches = userAgent.match(appUserAgentRegex);
  if (matches != null) {
    return { platform: matches[1], version: matches[2] };
  }
  // In older apps, we did not specifiy custom userAgent, so version cannot be found
  // But they contain some relevant info about the platform
  // e.g. "Mozilla/5.0 (Linux; Android 10; Android SDK built for x86 Build/QSR1.190920.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/74.0.3729.185 Mobile Safari/537.36"
  const platform =
    userAgent.toLowerCase().indexOf("android") !== -1 ? "android" : "ios";
  return { platform, version: null };
};

const getPlatformStoreURL = (platform: PLATFORM): string => {
  if (platform === "ios") {
    return "https://apps.apple.com/us/app/imperfect-foods/id1577380836";
  }
  return "https://play.google.com/store/apps/details?id=com.imperfectfoods";
};

export const getStoreURL = () => {
  const appInfo = getAppVersionInfo();
  return getPlatformStoreURL(appInfo.platform as PLATFORM);
};

// A good technique to find touch enabled client https://stackoverflow.com/a/4819886
export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
