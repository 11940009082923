// @ts-nocheck
import { useEffect } from "react";
import { useSelector } from "react-redux";

import State from "app/types/state";
import { isNativeApp } from "app/selectors";
import { config } from "app/config";

export default function useChatWidget(enabled: boolean) {
  const isNative = useSelector((state: State) => isNativeApp(state));

  useEffect(() => {
    // show the chat widget
    if (enabled && window && window.Kustomer && !isNative) {
      Kustomer.start({
        brandId: config.get("third_party.kustomer.brand_id"),
      });
    }

    return () => {
      // hide the chat widget
      if (enabled && window && window.Kustomer) {
        Kustomer.stop();
      }
    };
  }, [enabled]);
}
