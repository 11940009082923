// @ts-nocheck
import ACTION_TYPES from "app/actionTypes/cms";

export const CONTENT_TYPES = {
  PAGE: "content_page",
  // if this pulls in ANY other custom types, we define which fields of those custom types we want to bring in
  MENU: "navigation_menu",
  TOP_SHELF: "shelf",
};

// Needed to fetch full content for linked entries in Prismic
// https://prismic.io/docs/reactjs/query-the-api/fetch-linked-document-fields
export const LINKED_CONTENT = {
  PAGE: [
    "hero_banner.image",
    "hero_banner.alt_text",
    "hero_banner.url",

    "testimonial.image",
    "testimonial.quote",
    "testimonial.byline_1",
    "testimonial.byline_2",
    "testimonial.type",

    "partner.partner_name",
    "partner.link",
    "partner.logo",

    "recipe.title",
    "recipe.image",
    "recipe.recipe_url",
  ],
};

export const initialState = {
  content: {}, // full content data by ID
  mapOfContentBySingleton: {}, // map of singleton type to ID
  mapOfContentByUID: {}, // map of UID to ID
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTENT_BY_UID_SUCCEEDED: {
      return {
        ...state,
        content: {
          ...state.content,
          [action.content.id]: action.content,
        },
        mapOfContentByUID: {
          ...state.mapOfContentByUID,
          [action.contentType]: {
            ...state.mapOfContentByUID[action.contentType],
            [action.uid]: {
              id: action.content.id,
              metadata: {
                status: 200,
              },
            },
          },
        },
      };
    }
    case ACTION_TYPES.FETCH_CONTENT_BY_UID_FAILED: {
      return {
        ...state,
        mapOfContentByUID: {
          ...state.mapOfContentByUID,
          [action.contentType]: {
            ...state.mapOfContentByUID[action.contentType],
            [action.uid]: {
              id: null,
              metadata: {
                status: action.error.status,
              },
            },
          },
        },
      };
    }

    default:
      return state;
  }
}

export function getCMSContent(state) {
  return state.content;
}

export function getActiveCMSContent(state) {
  return state.activeContent;
}

export function getMapOfCMSContentByUID(state) {
  return state.mapOfContentByUID;
}

export function getMapOfCMSContentBySingleton(state) {
  return state.mapOfContentBySingleton;
}

function fetchContentByUID(uid, contentType, params) {
  return { type: ACTION_TYPES.FETCH_CONTENT_BY_UID, uid, contentType, params };
}

export const fetchCMSMenuByUID = (uid: string, params?: any) => {
  return fetchContentByUID(uid, CONTENT_TYPES.MENU, params);
};

export const fetchCMSShelfByUID = (uid: string, params?: any) => {
  return fetchContentByUID(uid, CONTENT_TYPES.TOP_SHELF, params);
};
