import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import routes from "app/router/routes.json";
import { getImageURL } from "app/ui/global/utils";
import BlinkingEyedProduct from "app/ui/designSystem/molecules/BlinkingEyedProduct";
import { ProductType } from "app/ui/designSystem/molecules/BlinkingEyedProduct/utils";
import { useSelector } from "react-redux";
import { canViewSignupShopping } from "app/selectors";

const CharacterArray = [
  // height is in px
  { name: "broccoli", height: 98 },
  { name: "cabbage", height: 80 },
  { name: "potato", height: 102 },
  { name: "peach", height: 93 },
];

export interface CartEmtpyStateProps {
  onShopClick: () => void;
}

const CartEmptyState: React.FC<CartEmtpyStateProps> = ({ onShopClick }) => {
  const randomCharacter =
    CharacterArray[Math.floor(Math.random() * CharacterArray.length)];
  // NC-1244 productionalize signup shopping
  const isInSignupShopping = !!useSelector(canViewSignupShopping);

  return (
    <StyledEmptyCartContainer data-testid="empty-cart-state">
      <StyledImageContainer>
        <img
          src={getImageURL("Featured_Box_1.png", "v1648586947/shop")}
          alt="Empty Imperfect Foods box"
        />
        <StyledBlinkingEyedProduct
          product={randomCharacter.name as ProductType}
          $heightDifference={randomCharacter.height}
        />
      </StyledImageContainer>
      <StyledEmptyCartText>Uh oh... your cart is empty</StyledEmptyCartText>
      <ShopButton
        color="primary"
        component={isInSignupShopping ? Button : Link}
        role="link"
        to={routes.shopping.url}
        variant="contained"
        onClick={onShopClick}
      >
        Shop now
      </ShopButton>
    </StyledEmptyCartContainer>
  );
};

const StyledEmptyCartContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: ${rem(16)};
`;

const StyledImageContainer = styled.div``;

const StyledBlinkingEyedProduct = styled(BlinkingEyedProduct)<{
  $heightDifference: number; // translateY to accommodate for veg height, 102 being the tallest
  // this is in px for positioning when scaling font size
}>`
  position: absolute;
  top: 0;
  transform: translateY(
    ${({ $heightDifference }) => `${102 - $heightDifference + 150}px`}
  );
`;

const StyledEmptyCartText = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.extraBold};
  font-size: ${rem(24)};
  margin-bottom: ${rem(16)};
  text-align: center;
`;

const ShopButton = styled(Button)`
  margin-right: ${rem(16)};
  padding: ${rem(8)} ${rem(24)};
  font-size: ${rem(14)};
` as typeof Button;

export default CartEmptyState;
