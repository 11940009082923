// @ts-nocheck
import { take, fork, call, put, select } from "redux-saga/effects";
import _difference from "lodash/difference";

import ACTION_TYPES from "app/actionTypes/offerings";
import {
  algoliaOfferingsClient,
  buildAlgoliaFilterString,
} from "app/api/algolia";
import {
  fetchTags,
  fetchAllOfferingsById,
  fetchAllOfferings,
} from "app/api/productService";
import {
  fetchedAllOfferingsAction,
  fetchedAllTagsAction,
} from "app/reducers/offerings";
import { updateRecentlyAddedShelf } from "app/reducers/shelves";
import { goTo } from "app/router/routes";
import {
  getActiveOrderPriceZoneFcAndDate,
  getRoutingLocation,
  getOrderedTagIds,
  getAccountFulfillmentCenterId,
  getCurrentTimeForUserTimezone,
  getNextDeliveryPriceZoneFcAndDate,
  isInRecentlyAddedTreatment,
  getActiveOrderId,
} from "app/selectors";
import { updateLocalStorageData } from "app/reducers/ui";
import { sendLog } from "app/monitoring";
import { LogLevel } from "app/types/monitoring";

export default function* rootAuth() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      ACTION_TYPES.FETCH_ALL_OFFERINGS,
      ACTION_TYPES.FETCH_ALL_TAGS,
      ACTION_TYPES.FETCH_OFFERINGS_BY_ID,
      ACTION_TYPES.FETCH_SIMILAR_OFFERINGS_BY_CATEGORY,
    ]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_ALL_OFFERINGS: {
        yield fork(fetchAllOfferingsFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_ALL_TAGS: {
        yield fork(fetchAllTagsFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_OFFERINGS_BY_ID: {
        yield fork(fetchOfferingsByIdFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_SIMILAR_OFFERINGS_BY_CATEGORY: {
        yield fork(fetchSimilarOfferingsByCategory, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* fetchAllOfferingsFromAPIFlow(action) {
  const { clearExistingVariants, overrides } = action;

  const { fcAbbr, packDate, priceZoneId } = yield select(
    getActiveOrderPriceZoneFcAndDate
  );

  try {
    const results = yield call(fetchAllOfferings, {
      fc: overrides?.fc || fcAbbr,
      packDate: overrides?.packDate || packDate,
      priceZone: overrides?.priceZone || priceZoneId,
    });

    yield put({
      type: ACTION_TYPES.FETCH_ALL_OFFERINGS_FROM_API_SUCCEEDED,
      clearExistingVariants,
      results,
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.FETCH_ALL_OFFERINGS_FROM_API_FAILED,
      error,
    });
  }
}

export function* fetchAllOfferingsFlow(action = {}) {
  const { clearAlgoliaCache } = action;

  try {
    const { fcAbbr, priceZoneId, packDate } = yield select(
      getActiveOrderPriceZoneFcAndDate
    );

    // TODO: NC-1244 productionalize signup shopping
    const {
      fcAbbr: nextDeliveryFCAbbr,
      priceZoneId: nextDeliveryPriceZoneId,
      packDate: nextDeliveryPackDate,
    } = yield select(getNextDeliveryPriceZoneFcAndDate);

    const algoliaParams = {
      fc: fcAbbr || nextDeliveryFCAbbr,
      packDate: packDate || nextDeliveryPackDate,
      // priceZoneId is always a default value, so we need a ternary based on if active order has other values/exists.
      priceZoneId: packDate ? priceZoneId : nextDeliveryPriceZoneId,
    };

    const filters = buildAlgoliaFilterString(algoliaParams);

    if (clearAlgoliaCache) {
      yield call(algoliaOfferingsClient.clearCache);
    }

    const { results } = yield call(
      algoliaOfferingsClient.allOfferingsMultiQuery,
      {
        filters,
      }
    );

    yield fork(sendLog, {
      level: LogLevel.INFO,
      tags: ["algolia", "offerings"],
      message: "Fetch all offerings from Algolia",
      filters,
      offeringCount: results?.reduce((acc, result) => acc + result?.nbHits, 0),
    });

    const inRecentlyAddedTreatment = yield select(isInRecentlyAddedTreatment);

    if (!!localStorage && inRecentlyAddedTreatment) {
      const offeringLocalStorageKey = "offeringData";
      const activeOrderId = yield select(getActiveOrderId);
      const previousLocalStorageData = JSON.parse(
        localStorage.getItem(offeringLocalStorageKey)
      );
      const snapshotOfferings = results.reduce((prev, current) => {
        return [...prev.hits, current.hits];
      });
      const currentSnapshotTime = Date.now();

      const currentSnapshotVariantIds = snapshotOfferings.reduce(
        (result, singleOffering) => {
          if (singleOffering.isVisible && singleOffering.hasStock) {
            result.push(singleOffering.variantId);
          }
          return result;
        },
        []
      );

      const previousSnapshots =
        previousLocalStorageData && previousLocalStorageData[activeOrderId];
      const previousSnapshotsToKeep = {};
      const previousSnapshotsTimestamps = previousSnapshots
        ? Object.keys(previousSnapshots)
        : [];
      const maxSnapshotsToKeep =
        previousSnapshotsTimestamps.length > 20
          ? 20
          : previousSnapshotsTimestamps.length;
      const minHoursToCompare = 2;
      const numOfMillisecondsInAnHour = 36e5;
      let snapshotToCompare = null;

      for (let i = 0; i < maxSnapshotsToKeep; i++) {
        if (
          Math.abs(previousSnapshotsTimestamps[i] - currentSnapshotTime) /
            numOfMillisecondsInAnHour >=
          minHoursToCompare
        ) {
          snapshotToCompare = previousSnapshots[previousSnapshotsTimestamps[i]];
          break;
        } else {
          previousSnapshotsToKeep[previousSnapshotsTimestamps[i]] =
            previousSnapshots[previousSnapshotsTimestamps[i]];
        }
      }

      const dataToStore = {
        [activeOrderId]: {
          [currentSnapshotTime]: currentSnapshotVariantIds,
          ...previousSnapshotsToKeep,
        },
      };

      const stringifiedData = JSON.stringify(dataToStore);

      yield put(
        updateLocalStorageData(offeringLocalStorageKey, stringifiedData)
      );

      const variantIdsSinceYouveBeenGone =
        snapshotToCompare &&
        _difference(currentSnapshotVariantIds, snapshotToCompare);

      const recentlyAddedShelf = {
        shelfId: "52069124-1b15-4071-a3ac-bd81a8c5e08a",
        name: "Since You Last Shopped",
        endpoint: null,
        startDate: "2022-01-01",
        endDate: null,
        description: "These products are now available since your last visit",
        promotionImageFilename: null,
        promotionLink: null,
        promotionLinkText: null,
        promotionVideoFilename: null,
        promotionVideoSubtitleFilename: null,
      };

      yield put(
        updateRecentlyAddedShelf({
          recentlyAddedShelf,
          recentlyAddedShelfVariantIds: variantIdsSinceYouveBeenGone,
        })
      );
    }

    yield put(fetchedAllOfferingsAction({ ...action, results }));
  } catch (error) {
    yield fork(sendLog, {
      level: LogLevel.ERROR,
      tags: ["algolia", "offerings"],
      message: "Fetch all offerings error",
      error,
    });
    yield put({
      ...action,
      type: ACTION_TYPES.FETCH_ALL_OFFERINGS_FAILED,
      error,
    });
  }
}

export function* fetchOfferingsByIdFlow({ variantIds }) {
  try {
    const fc = yield select(getAccountFulfillmentCenterId);
    const currentDate = yield select(getCurrentTimeForUserTimezone);
    const params = {
      fc,
      packDate: currentDate.format("YYYY-MM-DD"),
      variants: variantIds,
    };

    const response = yield call(fetchAllOfferingsById, params);
    const offerings = response.results.map((r) => r.offering);
    yield put({
      type: ACTION_TYPES.FETCH_OFFERINGS_BY_ID_SUCCEEDED,
      offerings,
    });
  } catch (error) {
    yield put({ type: ACTION_TYPES.FETCH_OFFERINGS_BY_ID_FAILED, error });
  }
}

export function* fetchAllTagsFlow(action = {}) {
  const { tags } = action;
  try {
    const params = { includeArchived: false, includeHidden: true, tags };
    const result = yield call(fetchTags, params);
    yield put(fetchedAllTagsAction({ ...action, result }));
    yield fork(removeInvalidURLFilters, "tag");
  } catch (error) {
    yield put({ ...action, type: ACTION_TYPES.FETCH_ALL_TAGS_FAILED, error });
  }
}

const stripFilter = (filterType, validFilters, location) => {
  if (location.query && location.query[filterType]) {
    let newLocation;
    if (Array.isArray(location.query[filterType])) {
      newLocation = {
        ...location,
        query: {
          ...location.query,
          [filterType]: location.query[filterType].filter((f) =>
            validFilters.includes(f)
          ),
        },
      };
    } else if (validFilters.includes(location.query[filterType])) {
      newLocation = location;
    } else {
      newLocation = {
        ...location,
        query: {
          ...location.query,
          [filterType]: [],
        },
      };
    }
    if (newLocation.query[filterType] !== location.query[filterType]) {
      goTo(newLocation, "replace");
    }
  }
};

export function* removeInvalidURLFilters(filterType) {
  const location = yield select(getRoutingLocation);

  if (filterType === "tag") {
    const tags = yield select(getOrderedTagIds);
    stripFilter(filterType, tags, location);
  }
}

export function* fetchSimilarOfferingsByCategory({ variantId, categories }) {
  try {
    const multiQueryResults = yield call(
      algoliaOfferingsClient.categoryMultiQuery,
      {
        categoryIds: categories.map((c) => c.categoryId),
        filters: `NOT objectID:${variantId}`,
      }
    );
    yield put({
      type: ACTION_TYPES.FETCH_SIMILAR_OFFERINGS_BY_CATEGORY_SUCCEEDED,
      payload: {
        multiQueryResults: multiQueryResults.results.map((r) => r.hits),
        variantId,
      },
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.FETCH_SIMILAR_OFFERINGS_BY_CATEGORY_FAILED,
      error,
    });
  }
}
