import React from "react";
import { cover } from "polished";
import styled from "styled-components";

import grid from "app/styles/grid";

export interface SpinnerProps extends React.HTMLAttributes<HTMLElement> {
  message?: string;
  MessageComponent?: React.ComponentType;
}

const Spinner: React.FC<SpinnerProps> = ({
  message = "Loading...",
  MessageComponent = "h3",
}) => {
  return <MessageComponent data-testid="spinner">{message}</MessageComponent>;
};

export default Spinner;

export const SimpleSpinnerWrapper = styled.div`
  ${grid.row};
  ${grid.cell.fit};
  align-items: center;
  justify-content: center;
`;

export const SpinnerWrapper = styled(SimpleSpinnerWrapper)`
  ${cover()};
  background-color: ${({ theme }) =>
    theme.colorUtils.fade(theme.colors.white, 0.25)};
  z-index: ${({ theme }) => theme.layout.zIndex.default};
`;
