// @ts-nocheck
import { take, fork, put, select } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/search";
import { algoliaOfferingsClient } from "app/api/algolia";
import {
  searchQueryMeetsMinimum,
  searchResultChanged,
} from "app/reducers/search";
import { getActiveOrder } from "app/selectors";

export default function* rootSearch() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([ACTION_TYPES.SEARCH_QUERY_CHANGED]);

    switch (action.type) {
      case ACTION_TYPES.SEARCH_QUERY_CHANGED: {
        yield fork(makeAlgoliaSearchQuery, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* makeAlgoliaSearchQuery(action = {}) {
  const { query } = action;

  if (searchQueryMeetsMinimum(query)) {
    const activeOrder = yield select(getActiveOrder);
    const filters = "";

    const result = yield algoliaOfferingsClient.search({
      query,
      filters,
      attributesToRetrieve: ["variantId"],
      analyticsTags: [`fc:${activeOrder.fcAbbr}`],
      enableReRanking: true,
    });

    yield put(
      searchResultChanged({
        result,
        query,
      })
    );
  }
}
