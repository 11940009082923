import { rem } from "polished";
import React from "react";
import styled from "styled-components";

import { breakpointsNonScaling, sizes } from "app/styles/breakpoints";
import Tag from "app/types/state/offerings/Tag";
import RemoveFilter from "./RemoveFilter";
import { SIDE_NAV_WIDTH } from "app/ui/shopping/Navigation/SideNav";

export interface NoVariantsWithFilterProps {
  activeFilter: Tag;
  columns: number;
  lastInAisle?: boolean;
  style?: React.CSSProperties;
}

const NoVariantsWithFilter: React.FC<NoVariantsWithFilterProps> = React.memo(
  ({ activeFilter, columns, lastInAisle, style }) => {
    return (
      <ListItemContainer
        style={style}
        $columns={columns}
        $lastInAisle={!!lastInAisle}
      >
        0 products match your selected filter.
        <RemoveFilter scrollToTop={false} selectedTag={activeFilter} />
      </ListItemContainer>
    );
  }
);

export default NoVariantsWithFilter;

const lineHeight = 20;

const getPaddingBottom = (columns: number, lastInAisle: boolean) => {
  if (columns <= 2 && lastInAisle) return 64;
  if (columns > 2 && lastInAisle) return 80;
  return 24;
};

const ListItemContainer = styled.div<{
  $columns: number;
  $lastInAisle: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${rem(sizes.xxxl)};
  padding-left: ${rem(16)};
  padding-bottom: ${({ $columns, $lastInAisle }) =>
    rem(getPaddingBottom($columns, $lastInAisle))};
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(lineHeight)};

  ${breakpointsNonScaling.xl`
    display: block;
    padding-right: ${rem(48)};
    padding-left: ${SIDE_NAV_WIDTH + 64}px;
  `}
`;

export interface NoVariantsWithFilterToken {
  component: typeof NoVariantsWithFilter;
  componentProps: {
    activeFilter: Tag;
    columns: number;
    lastInAisle: boolean;
  };
  data: {};
  listItemHeight: number;
}

export const getNoVariantsWithFilterHeight = (
  columns: number,
  lastInAisle: boolean
) => {
  return columns > 1
    ? lineHeight + getPaddingBottom(columns, lastInAisle)
    : lineHeight * 2 + getPaddingBottom(columns, lastInAisle);
};

export const getNoVariantsWithFilterToken = ({
  activeFilter,
  columns,
  lastInAisle,
}: {
  activeFilter: Tag;
  columns: number;
  lastInAisle: boolean;
}): NoVariantsWithFilterToken => ({
  component: NoVariantsWithFilter,
  componentProps: {
    activeFilter,
    columns,
    lastInAisle,
  },
  data: {},
  listItemHeight: getNoVariantsWithFilterHeight(columns, lastInAisle),
});
