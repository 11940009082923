import React from "react";
import Footer from "app/ui/footer";
import RevampFooter from "app/ui/universal/footer";

const WrappedFooter = ({
  isOnQuizStepRoute,
  isOnJoinPage,
  isOnSignupSummaryPage,
  isOnSubscriptionPage,
  isOnSignupCompletePage,
  isOnCheckoutRoute,
}) => {
  if (isOnQuizStepRoute) {
    return null;
  }

  if (isOnJoinPage || isOnSubscriptionPage) {
    return <RevampFooter shouldShowBeetBox zIndex={0} />;
  }

  if (isOnSignupCompletePage) {
    return <RevampFooter shouldShowBeetBox />;
  }

  // TODO: remove in NC-1468?
  return (
    <Footer onlyShowCopyright={isOnSignupSummaryPage || isOnCheckoutRoute} />
  );
};

export default WrappedFooter;
