export enum AisleClickedSource {
  NavigationTopBar = "Navigation Top Bar",
  SubaisleSideNav = "Subaisle Side Nav",
  YouMightAlsoLike = "You Might Also Like",
}

export default interface AisleClicked {
  location: string;
  aisleName: string;
  aisleId?: string;
  source: AisleClickedSource;
}
