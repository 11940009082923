import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import ACTION_TYPES from "app/actionTypes/deliveries";
import NextDeliverySuccessResponse from "app/types/account/NextDeliverySuccessResponse";
import SubscriptionPositResponseAction from "app/types/state/account/SubscriptionPositResponseAction";
import DeliveriesState, {
  FullWindowData,
} from "app/types/state/deliveries/deliveries";

export const initialState: DeliveriesState = {
  windows: {}, // map of deliveryWindowId => deliveryWindow data,
  windowIdsByZip: {}, // map of zipCode => array of deliveryIds
  fetchingWindowsByZip: false,
  nextDelivery: {
    deliveryDate: null,
    isActiveOrder: null,
    inCustomizationWindow: null,
    isCustomizationDelayed: null,
    deliveryWindow: {
      startDate: null,
      startDay: null,
      startTime: null,
      endDate: null,
      endDay: null,
      endTime: null,
      timezone: null,
      customizationStartDate: null,
      customizationStartTime: null,
      customizationEndDate: null,
      customizationEndTime: null,
      timezoneFC: "",
    },
    windowIsReplacement: null,
    windowReplacementReason: null,
    donationPartners: [],
    // originalDeliveryDate: null // not used, but available in state
  },
  nextDeliveryTimestamp: null,
  skippedActiveOrder: null,
  skippedNextOrder: null,
  nextAvailableWindowDelivery: null,
  zipCodeSummary: null,
  subPauseNextDeliveryDates: {},
  trackingInfo: null,
};

const handleNextDeliverySucceeded = (
  state: DeliveriesState,
  action: NextDeliverySuccessResponse
) => {
  return {
    ...state,
    nextDelivery: action.nextDelivery,
    nextDeliveryTimestamp: action.timestamp,
    skippedActiveOrder: action.skippedActiveOrder || null,
    skippedNextOrder: action.skippedNextOrder || null,
    zipCodeSummary: action.zipCodeSummary || null,
    nextAvailableWindowDelivery: action.nextAvailableWindowDelivery || null,
  };
};

const handleWindowsByZipSucceeded = (
  state: DeliveriesState,
  action: SubscriptionPositResponseAction
) => {
  const { zipCode } = action;
  const { windows, windowIdsByZip } = (action.windows || []).reduce(
    (
      acc: {
        windows: Record<string, FullWindowData>;
        windowIdsByZip: string[];
      },
      deliveryWindow: FullWindowData
    ) => {
      const { windowId } = deliveryWindow;

      acc.windows[windowId] = deliveryWindow;
      if (zipCode) {
        acc.windowIdsByZip.push(windowId);
      }

      return acc;
    },
    { windows: {}, windowIdsByZip: [] }
  );

  return {
    ...state,
    windows: {
      ...state.windows,
      ...windows,
    },
    ...(zipCode && {
      windowIdsByZip: {
        ...state.windowIdsByZip,
        [zipCode]: windowIdsByZip,
      },
    }),
    fetchingWindowsByZip: false,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    // TODO: NC-1010 Remove if subscription pause experiment fails
    case ACCOUNT_ACTION_TYPES.FETCH_SUB_PAUSE_NEXT_DELIVERY_SUCCEEDED:
      return {
        ...state,
        // This next delivery data fetch is tightly coupled to the PauseInfo component
        // UI layer - future enhancement is to decouple this to be flexible for additional durations
        subPauseNextDeliveryDates: {
          ...state.subPauseNextDeliveryDates,
          ...action.deliveryDates,
        },
      };
    case ACTION_TYPES.FETCH_WINDOW_BY_ID_SUCCEEDED:
      return {
        ...state,
        windows: {
          ...state.windows,
          [action.deliveryWindow.windowId]: action.deliveryWindow,
        },
      };

    case ACCOUNT_ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT:
    case ACTION_TYPES.FETCH_WINDOWS_BY_ZIP:
      return {
        ...state,
        fetchingWindowsByZip: true,
      };
    case ACCOUNT_ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT_SUCCEEDED:
    case ACTION_TYPES.FETCH_WINDOWS_BY_ZIP_SUCCEEDED: {
      return handleWindowsByZipSucceeded(state, action);
    }
    case ACTION_TYPES.FETCH_WINDOWS_BY_ZIP_FAILED:
      return {
        ...state,
        fetchingWindowsByZip: false,
      };

    case ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED:
      return handleNextDeliverySucceeded(state, action);

    case ACTION_TYPES.FETCH_AVAILABLE_DELIVERIES_SUCCEEDED:
      return {
        ...state,
        flexibleDeliveries: action.flexibleDeliveries,
      };

    case ACTION_TYPES.FETCH_SHIPMENT_TRACKING_INFO_SUCCEEDED:
      return {
        ...state,
        trackingInfo: action.trackingInfo,
      };

    default:
      return state;
  }
}

export function getDeliveryWindows(state: DeliveriesState) {
  return state.windows;
}

export function getDeliveryWindowIdsByZip(state: DeliveriesState) {
  return state.windowIdsByZip;
}

export function isFetchingWindowsByZip(state: DeliveriesState) {
  return state.fetchingWindowsByZip;
}

export function getNextDelivery(state: DeliveriesState) {
  return state.nextDelivery;
}

// TODO: NC-1010 Remove if subscription pause experiment fails
export function getSubPauseNextDeliveryDates(state: DeliveriesState) {
  return state.subPauseNextDeliveryDates;
}

export function getFlexibleDeliveries(state: DeliveriesState) {
  return state.flexibleDeliveries;
}

export function getNextDeliveryTimestamp(state: DeliveriesState) {
  return state.nextDeliveryTimestamp;
}

export function getNextAvailableWindowDelivery(state: DeliveriesState) {
  return state.nextAvailableWindowDelivery;
}

export function getNextDeliveryWindowReplacementReason(state: DeliveriesState) {
  return state?.nextDelivery?.windowReplacementReason;
}

export function getSkippedActiveOrder(state: DeliveriesState) {
  return state.skippedActiveOrder;
}

export function getSkippedNextOrder(state: DeliveriesState) {
  return state.skippedNextOrder;
}

export function fetchDeliveryWindowById(
  deliveryWindowId: string,
  includeNextDelivery: boolean
) {
  return {
    type: ACTION_TYPES.FETCH_WINDOW_BY_ID,
    deliveryWindowId,
    includeNextDelivery,
  };
}

export function fetchDeliveryWindowsByZip(
  zipCode: string,
  includeNextDelivery: boolean = true
) {
  return {
    type: ACTION_TYPES.FETCH_WINDOWS_BY_ZIP,
    zipCode,
    includeNextDelivery,
  };
}

export function fetchFlexibleDeliveries(
  zipCode: string,
  subscriptionWindowId: string,
  userId: string
) {
  return {
    type: ACTION_TYPES.FETCH_AVAILABLE_DELIVERIES,
    zipCode,
    subscriptionWindowId,
    userId,
  };
}

export function fetchNextDeliverySucceededAction(
  nextDeliverySuccessResponse: NextDeliverySuccessResponse
) {
  return {
    ...nextDeliverySuccessResponse,
    type: ACCOUNT_ACTION_TYPES.FETCH_NEXT_DELIVERY_SUCCEEDED,
  };
}

export function getIsCustomizationDelayed(state: DeliveriesState) {
  return state?.nextDelivery?.isCustomizationDelayed;
}

export function fetchShipmentTrackingInfo(orderId: string, userId: string) {
  return { type: ACTION_TYPES.FETCH_SHIPMENT_TRACKING_INFO, orderId, userId };
}

export function getShipmentTrackingInfo(state: DeliveriesState) {
  return state.trackingInfo;
}
