import React from "react";
import theme from "app/styles/theme";

interface BlobProps {
  fillColor?: string;
}

const ShellBlobOne = ({ fillColor = theme.colors.lemon }: BlobProps) => {
  return (
    <svg
      width="100%"
      height="192"
      viewBox="0 0 353 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M283.36 266.1C236.665 285.759 189.353 243.374 141.442 221.928C91.5032 199.574 25.6693 194.527 6.26502 142.065C-13.9377 87.4449 22.0745 33.1242 55.0887 -11.5378C86.8201 -54.4643 127.654 -92.3547 181.006 -94.3262C235.578 -96.3427 288.273 -66.4258 323.633 -21.4611C357.539 21.6549 355.47 86.459 348.006 139.753C340.329 194.573 332.499 245.412 283.36 266.1Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ShellBlobOne;
