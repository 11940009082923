// @ts-nocheck
import _pick from "lodash/pick";
import { take, fork, call, put, select, cancel, all } from "redux-saga/effects";

import ROUTING_ACTION_TYPES from "app/actionTypes/routing";
import ACTION_TYPES from "app/actionTypes/signup";
import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import ORDER_ACTION_TYPES from "app/actionTypes/orders";
import { CRITICAL_DATA_REQUEST } from "app/api/request";
import { OOA_ZIP_CHANGE_MODAL_ID } from "app/constants/modals";
import { updateAuthUserAttributes } from "app/api/cognito";

import {
  FUTURE_MARKET_LAUNCH_MODAL,
  SIGNUP_SHOPPING,
} from "app/constants/treatments";
import {
  signupStarted,
  signupEnded,
  currentStepChanged,
  signupStepComplete,
  signupAbandoned,
  setSignupValues,
  updateSignupFlowConfig,
  updateVariantQuantity,
} from "app/reducers/signup";
import { showModal } from "app/reducers/ui";
import { goTo } from "app/router/routes";
import {
  fetchUserSummaryFlow,
  createUserFlow,
  createCredentialsFlow,
  updateUserFlow,
  createAddressFlow,
  updateAddressDeliveryNotesFlow,
  updateSignupPreferencesFlow,
} from "app/sagas/account";
import { loginFlow } from "app/sagas/auth";
import { fetchBoxesFlow } from "app/sagas/boxes";
import extractResponseMessage from "app/sagas/extractResponseMessage";
import { handleTreatments } from "app/sagas/split";
import { waitForInitialization } from "app/sagas/waitForInitialization";
import validateUserDeferredCoupon from "app/sagas/validateUserDeferredCoupon";
import fetchSubscriptionPositFlow from "app/sagas/fetchSubscriptionPositFlow";
import { waitForSplitTreatmentsInitialized } from "app/sagas/waitForSplit";
import setPaymentDetailsFlow from "app/sagas/signup/setPaymentDetailsFlow";

import {
  getDeliveryWindowIdsByZip,
  getSecondaryBoxesByDeliveryWindowId,
  getCurrentSignupStepForRoute,
  getSignupStepForPreviousRoute,
  getSignupProgressStep,
  getSignupCurrentStep,
  getSignupFlow,
  getMetadataForSignupStep,
  getSignupValues,
  getSignupZipCode,
  isLoggedIn,
  getUser,
  getRoutingLocation,
  getSignupConfirmationStep,
  isSignupStateInitialized,
  canUserSignup,
  isSignupRouteAllowed,
  isSignupReasonNoteAllowed,
  isValidZipCode,
  getDeliveryWindows,
  getSignupSelectedDeliveryWindowDEPRECATED,
  getSignupSelectedFulfillmentCenterId,
  getSignupSelectedDeliveryProvider,
  getUserId,
  getSplitTreatments,
  getNewMarketTreatment,
  getImpactClick,
  isInSignupShoppingTreatment,
  getSignupVariantQuantities,
  getSignupVariantQuantity,
} from "app/selectors";
import { ImpactClick } from "app/types/state/analytics";
import { SignupSteps } from "app/types/state/signup";
import { FullWindowData } from "app/types/state/deliveries/deliveries";
import { SetDeliveryDetailsAction } from "app/types/signup/setDeliveryDetails";
import { fetchValidDaysByZip } from "app/reducers/misfits";

export default function* rootSignup() {
  let routeTask;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      ACTION_TYPES.SET_SIGNUP_VALUES,
      ACTION_TYPES.SYNC_SIGNUP_PREFERENCES,
      ACTION_TYPES.STEP_COMPLETE,
      ACTION_TYPES.SIGNUP_STARTED,
      ACTION_TYPES.CURRENT_STEP_CHANGED,
      ACTION_TYPES.SET_SELECTED_ZIP_CODE,
      ACTION_TYPES.SET_SELECTED_ZIP_CODE_CHECKOUT,
      ACTION_TYPES.CREATE_ACCOUNT,
      ACTION_TYPES.SET_DELIVERY_DETAILS,
      ACTION_TYPES.SET_PAYMENT_DETAILS,
      ACTION_TYPES.SAVE_OOA_USER,
      ACCOUNT_ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT_SUCCEEDED,
      ROUTING_ACTION_TYPES.LOCATION_CHANGED,
      ACTION_TYPES.UPDATE_CART,
      ORDER_ACTION_TYPES.FETCH_ORDER_POSIT_SUCCEEDED,
    ]);

    switch (action.type) {
      case ACTION_TYPES.SYNC_SIGNUP_PREFERENCES:
      case ACTION_TYPES.SET_SIGNUP_VALUES: {
        yield fork(syncSignupPreferencesFlow, action);
        break;
      }
      case ACTION_TYPES.STEP_COMPLETE: {
        yield fork(syncLocationFlow, action);
        yield fork(syncSignupPreferencesFlow, action);
        break;
      }
      case ACTION_TYPES.SIGNUP_STARTED:
      case ACTION_TYPES.CURRENT_STEP_CHANGED: {
        yield fork(syncLocationFlow, action);
        break;
      }
      case ROUTING_ACTION_TYPES.LOCATION_CHANGED: {
        if (routeTask && routeTask.isRunning()) {
          yield cancel(routeTask);
        }
        routeTask = yield fork(syncSignupFlow, action);
        break;
      }
      case ACTION_TYPES.SET_SELECTED_ZIP_CODE: {
        yield fork(setSelectedZipCodeFlow, action);
        break;
      }
      case ACTION_TYPES.SET_SELECTED_ZIP_CODE_CHECKOUT: {
        yield fork(setSelectedZipCodeFlowCheckout, action);
        break;
      }
      case ACTION_TYPES.CREATE_ACCOUNT:
        yield fork(createAccountFlow, action);
        break;
      case ACTION_TYPES.SET_DELIVERY_DETAILS:
        yield fork(setDeliveryDetailsFlow, action);
        break;
      case ACTION_TYPES.SET_PAYMENT_DETAILS:
        yield fork(setPaymentDetailsFlow, action);
        break;
      case ACTION_TYPES.SAVE_OOA_USER:
        yield fork(saveOOAUserFlow, action);
        break;
      case ACCOUNT_ACTION_TYPES.FETCH_SUBSCRIPTION_POSIT_SUCCEEDED:
        yield fork(setSubscriptionPositTreatment, action);
        break;
      case ORDER_ACTION_TYPES.FETCH_ORDER_POSIT_SUCCEEDED:
        yield fork(setStartCarts, action);
        break;
      case ACTION_TYPES.UPDATE_CART:
        yield fork(updateCartFlow, action);
        break;
      default:
        break;
    }
  }
}

export function* setStartCarts(props) {
  if (props.order === undefined) return;
  if (props.isPrefilledCartFilled === false) {
    const variantQuantities = props.order.lineItems.map((item) => ({
      variantId: item.variantId,
      quantity: item.quantity,
    }));
    yield put({ type: ACTION_TYPES.POPULATE_VARIANTS, variantQuantities });
    yield put({ type: ACTION_TYPES.SET_SIGNUP_START_CARTS });
  }
}

function* handleSplitSignupConfigChange() {
  yield call(waitForSplitTreatmentsInitialized);
  const isInSignupShopping = yield select(isInSignupShoppingTreatment);
  yield put(
    updateSignupFlowConfig({
      isInSignupShopping,
    })
  );
}

/*
 * SIGNUP_STARTED event should occur when step transitions
 * from a null/undefined step to a valid step
 * NOTE: This should occur after initialization has completed
 * and the user has left a restricted route
 */
function* syncSignupFlow() {
  yield call(waitForInitialization);
  yield call(handleSplitSignupConfigChange);

  const canSignup = yield select(canUserSignup);
  const routeStep = yield select(getCurrentSignupStepForRoute);
  const currentStep = yield select(getSignupCurrentStep);
  const previousStep = yield select(getSignupStepForPreviousRoute);
  const confirmationStep = yield select(getSignupConfirmationStep);
  const signupInitialized = yield select(isSignupStateInitialized);

  const isSignupStarting = !signupInitialized && !previousStep && !!routeStep;
  const isSignupEnding = previousStep && routeStep === confirmationStep;
  const isSignupAbandoned = previousStep && !routeStep;
  const hasStepChanged = routeStep && currentStep !== routeStep;
  // Have we transitioned into the signup flow?
  if (isSignupStarting) {
    let referralCode, expectedStep;

    if (canSignup) {
      // Check if the user came in w/ a zip + boxType in url
      // If so, preset the zip and boxType if valid
      const location = yield select(getRoutingLocation);
      if (location && location.query) {
        const { zip } = location.query;

        // eslint-disable-next-line prefer-destructuring
        referralCode = location.query.referralCode;
        const validZipCode = yield select(isValidZipCode, { zip });

        if (validZipCode) {
          yield call(setSelectedZipCodeFlow, { zip });
        }
      }
      yield call(prefetchSignupData);

      // check if lastProgress is in current SignupConfig flow
      const flow = yield select(getSignupFlow);
      const [step0, step1] = flow;
      const lastProgress = yield select(getSignupProgressStep);
      const isStepValid = flow.includes(lastProgress);
      /*
       * Determine which step we should start on
       * We want to kick the user back to the beginning of signup if last progress
       * is not part of this current signup flow
       */
      expectedStep = isStepValid ? lastProgress : step0;

      // After prefetching our data, verify the expected step
      const values = yield select(getSignupValues);
      const { selectedDeliveryWindowId, zip } = values;

      if (zip && selectedDeliveryWindowId && expectedStep === step0) {
        expectedStep = step1;
      } else if (expectedStep === lastProgress && !selectedDeliveryWindowId) {
        expectedStep = step0;
      }
    }

    // Start the signup flow
    yield put(signupStarted(expectedStep, { referralCode }));

    // Have we transitioned out of the signup flow?
  } else if (isSignupEnding) {
    // End the signup flow
    yield put(signupEnded());
  } else if (isSignupAbandoned) {
    // Signup was abandoned
    yield put(signupAbandoned());

    // Have we gone back a step (via url from banner)?
  } else if (hasStepChanged) {
    yield put(currentStepChanged(routeStep));
  }
}

/*
 * This will refetch any data required for supporting a user that enters signup mid-flow
 */
function* prefetchSignupData() {
  yield call(validateUserDeferredCoupon);
  const params = { tags: [CRITICAL_DATA_REQUEST] };

  const values = yield select(getSignupValues);
  const {
    // boxId,
    zip,
    // boxType,
    selectedDeliveryWindowId,
  } = values;
  let deliveryWindowId;
  let deliveryWindows = [];
  yield all([
    call(fetchBoxesFlow, params),
    zip &&
      call(fetchSubscriptionPositFlow, {
        ...params,
        zipCode: zip,
      }),
    zip && put(fetchValidDaysByZip({ zip })),
  ]);
  if (zip) {
    deliveryWindows = yield select(getDeliveryWindowIdsByZip, { zip });
    if (deliveryWindows && deliveryWindows.length) {
      if (
        selectedDeliveryWindowId &&
        deliveryWindows.includes(selectedDeliveryWindowId)
      ) {
        deliveryWindowId = selectedDeliveryWindowId;
      } else {
        // eslint-disable-next-line prefer-destructuring
        deliveryWindowId = deliveryWindows[0];
      }
    }
  }

  if (
    deliveryWindowId &&
    (!selectedDeliveryWindowId || deliveryWindowId !== selectedDeliveryWindowId)
  ) {
    yield put(
      setSignupValues({
        selectedDeliveryWindowId: deliveryWindowId,
        deliveryWindows,
      })
    );
  }
}

/*
 * This will send the user to the step url if it does not match the expected value in state
 * Should be called after programmatically changing step pointers.
 */
function* syncLocationFlow() {
  const routeStep = yield select(getCurrentSignupStepForRoute);
  const currentStep = yield select(getSignupCurrentStep);
  const shouldRedirect = yield select(isSignupRouteAllowed);

  if (shouldRedirect && currentStep !== routeStep) {
    const metadata = yield select(getMetadataForSignupStep, {
      stepId: currentStep,
    });
    if (metadata) {
      const location = yield select(getRoutingLocation);
      if (location.pathname !== metadata.url) {
        yield call(goTo, {
          pathname: metadata.url,
          state: {
            origin: "signupSync",
            originPath: location.pathname,
          },
        });
      }
    }
  }
}

/**
 * Call only on /join/delivery
 * Fetches new delivery windows based on passed zip and sets store with new zip
 * and delivery windows IF zip is deliverable
 */
function* setSelectedZipCodeFlowCheckout(action) {
  const { zip } = action;
  yield call(handleTreatments, {
    splitNames: [FUTURE_MARKET_LAUNCH_MODAL],
    props: { zip },
  });

  try {
    yield call(fetchSubscriptionPositFlow, {
      zipCode: zip,
    });

    const deliveryWindows = yield select(getDeliveryWindowIdsByZip, { zip });
    if (deliveryWindows && deliveryWindows.length) {
      const selectedDeliveryWindowId = deliveryWindows[0];

      yield put({
        ...action,
        type: ACTION_TYPES.SET_SELECTED_ZIP_CODE_SUCCEEDED,
        zip,
        deliveryWindows,
        selectedDeliveryWindowId,
      });

      yield put(
        setSignupValues({
          zip,
          deliveryWindows,
          selectedDeliveryWindowId,
        })
      );
    }
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.SET_SELECTED_ZIP_CODE_FAILED,
      zip,
      error,
    });
  }
}

function* setSubscriptionPositTreatment(action) {
  const { inCustomizationWindow } = action;
  yield call(handleTreatments, {
    splitNames: [SIGNUP_SHOPPING],
    props: { isInCusto: inCustomizationWindow },
  });
  const isInSignupShopping = yield select(isInSignupShoppingTreatment);
  yield put(
    updateSignupFlowConfig({
      isInSignupShopping,
    })
  );
}

/**
 * Call only on /join or /join/subscription steps of signup flow
 * OR when valid zip query is appended to any signup route url
 * via setSelectedZipCode reducer / SET_SELECTED_ZIP_CODE action
 * Fetches new delivery windows based on passed zip and sets store with new zip
 * and delivery windows IF zip is deliverable OTHERWISE resets signup flow
 */
function* setSelectedZipCodeFlow(action) {
  const { zip } = action;
  yield call(handleTreatments, {
    splitNames: [FUTURE_MARKET_LAUNCH_MODAL],
    props: { zip },
  });

  // If changing zip, rather than entering new one, grab existing FC to see if new zip differs
  const prevSignupDeliveryWindow = yield select(
    getSignupSelectedDeliveryWindowDEPRECATED
  );
  const prevSignupZip = yield select(getSignupZipCode);

  // If no existing signup FC in state, fetch window info for the previous zip to determine existing FC
  if (prevSignupZip && !prevSignupDeliveryWindow) {
    yield call(fetchSubscriptionPositFlow, {
      zipCode: prevSignupZip,
    });
  }
  const prevSignupFCID = yield select(getSignupSelectedFulfillmentCenterId);

  const prevSignupDeliveryProvider = prevSignupDeliveryWindow
    ? yield select(getSignupSelectedDeliveryProvider)
    : null;

  try {
    yield all([
      call(fetchSubscriptionPositFlow, {
        zipCode: zip,
      }),
      put(fetchValidDaysByZip({ zip })),
    ]);

    const deliveryWindowIds = yield select(getDeliveryWindowIdsByZip, { zip });
    const deliveryWindowsWithAllDetails = yield select(getDeliveryWindows);
    const selectedDeliveryWindowId =
      deliveryWindowIds && deliveryWindowIds.length ? deliveryWindowIds[0] : "";
    const selectedDeliveryWindow: FullWindowData = selectedDeliveryWindowId
      ? deliveryWindowsWithAllDetails[selectedDeliveryWindowId]
      : null;

    if (selectedDeliveryWindow) {
      const newSignupFCID = selectedDeliveryWindow.fulfillmentCenterId;
      const newSignupDeliveryProvider = selectedDeliveryWindow.deliveryProvider;
      const isZipForNewFC = prevSignupFCID && prevSignupFCID !== newSignupFCID;
      const isZipForNewDeliveryProvider =
        prevSignupDeliveryProvider &&
        prevSignupDeliveryProvider !== newSignupDeliveryProvider;

      yield put({
        ...action,
        type: ACTION_TYPES.SET_SELECTED_ZIP_CODE_SUCCEEDED,
        zip,
        deliveryWindows: deliveryWindowIds,
        selectedDeliveryWindowId,
        // if new zip is for a new FC or delivery provider, reset box values and signup progress
        shouldResetSignupValues: isZipForNewFC || isZipForNewDeliveryProvider,
      });

      const currentStep = yield select(getSignupCurrentStep);
      // How do we do this in a future-proof way?
      // Zip check should cause the step to advance ONLY for the first (join) step
      // This is because we don't have the zip check as it's own step
      if (currentStep === SignupSteps.JOIN) {
        yield put(
          signupStepComplete({
            zip,
            deliveryWindows: deliveryWindowIds,
            selectedDeliveryWindowId,
          })
        );
      } else {
        // TODO: also reset address when zip is changed to new FC?
        yield put(
          setSignupValues({
            zip,
            deliveryWindows: deliveryWindowIds,
            selectedDeliveryWindowId,
          })
        );
      }
    } else {
      yield put({
        ...action,
        type: ACTION_TYPES.SET_SELECTED_ZIP_CODE_FAILED,
        zip,
        deliveryWindows: deliveryWindowIds,
        selectedDeliveryWindowId,
      });
    }

    // valid zip entered (deliverable AND undeliverable)
    yield put({
      type: ACTION_TYPES.SUBSCRIPTION_ZIP_ENTERED,
      zip,
      selectedDeliveryWindow,
    });
  } catch (error) {
    yield put({
      ...action,
      type: ACTION_TYPES.SET_SELECTED_ZIP_CODE_FAILED,
      zip,
      error,
    });
  }
}

/*
 *
 */
function* createAccountFlow(action) {
  const { email, password } = action;

  const summary = yield call(fetchUserSummaryFlow, { email });
  const values = yield select(getSignupValues);

  if (summary && summary.hasCredentials) {
    // Save anonymous treatments
    const treatments = yield select(getSplitTreatments);
    yield call(loginFlow, { email, password, shouldRedirect: false });
    // if logged in, complete step
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      // Persist latest anonymous treatments
      yield call(updateUserFlow, { values: { treatments }, rethrow: false });
      yield call(waitForInitialization);

      yield fork(prefetchSignupData);
      yield put({ type: ACTION_TYPES.SYNC_SIGNUP_PREFERENCES, values });
      yield call(completeAccountStep, action);
    } else {
      yield put({
        ...action,
        type: ACTION_TYPES.CREATE_ACCOUNT_FAILED,
        failureMessage: `User '${email}' exists.  Please choose a different email.`,
      });
    }
  } else {
    try {
      const shouldCreateUser = !summary;
      const shouldCreateCredentials =
        shouldCreateUser || (summary && !summary.hasCredentials);

      if (shouldCreateUser) {
        const signupPreferences = {
          boxId: values.boxId,
          zip: values.zip,
          cadence: values.selectedFrequency,
        };

        const treatments = yield select(getSplitTreatments);
        const quizValues = values.quizValues || null;
        const attributes = { treatments, quizValues };

        const impactClick: ImpactClick = yield select(getImpactClick);

        // click id is only valid for 45 days
        if (
          impactClick.dateAdded &&
          moment().diff(impactClick.dateAdded, "days") > 45
        ) {
          impactClick.id = null;
        }

        yield call(createUserFlow, {
          email,
          signupPreferences,
          attributes,
          rethrow: true,
          impactClickId: impactClick.id,
        });
      }

      if (shouldCreateCredentials) {
        yield call(createCredentialsFlow, { email, password, rethrow: true });
        yield call(loginFlow, { email, password, shouldRedirect: false });
      }

      yield call(completeAccountStep, action);
    } catch (e) {
      const message = yield call(extractResponseMessage, e);
      yield put({
        ...action,
        type: ACTION_TYPES.CREATE_ACCOUNT_FAILED,
        failureMessage:
          message || "Account was not created.  Please try again.",
      });
    }
  }
}

function* completeAccountStep(action, values) {
  yield put({ ...action, type: ACTION_TYPES.CREATE_ACCOUNT_SUCCEEDED });
  yield put(signupStepComplete(values));
}

/*
 * https://app.asana.com/0/511453562545209/605473459560181
 */
function* saveOOAUserFlow(action) {
  try {
    const { email } = action;
    let shouldCreateUser, shouldUpdateUser, shouldLogin, userId;
    const { zip, boxId, selectedFrequency } = yield select(getSignupValues);
    const signupPreferences = {
      zip,
      boxId,
      cadence: selectedFrequency,
    };
    const loggedIn = yield select(isLoggedIn);
    if (!loggedIn) {
      const summary = yield call(fetchUserSummaryFlow, { email });
      if (summary && summary.userId) {
        // eslint-disable-next-line prefer-destructuring
        userId = summary.userId;
        shouldLogin = summary.hasCredentials;
        shouldUpdateUser = !shouldLogin && !!userId;
        if (shouldLogin) {
          yield put({
            ...action,
            type: ACTION_TYPES.SAVE_OOA_USER_FAILED,
            failureMessage: "Please login to continue.",
          });
        }
      } else {
        // Create user account w/ OOA vars (signupZip & email, etc)
        shouldCreateUser = true;
      }
    } else {
      userId = yield select(getUserId);
      shouldUpdateUser = true;
    }

    if (shouldCreateUser) {
      const newMarketTreatment = yield select(getNewMarketTreatment);
      const futureMarket =
        newMarketTreatment.treatment !== "off"
          ? newMarketTreatment.treatment
          : undefined;
      const treatments = yield select(getSplitTreatments);
      yield call(createUserFlow, {
        email,
        signupPreferences,
        rethrow: true,
        futureMarket,
        attributes: { treatments },
      });
    } else if (shouldUpdateUser) {
      yield call(updateSignupPreferencesFlow, {
        userId,
        signupPreferences,
        rethrow: true,
      });
    }

    yield put({
      ...action,
      type: ACTION_TYPES.SAVE_OOA_USER_SUCCEEDED,
      values: { ...action.values, ...signupPreferences },
    });
  } catch (e) {
    const message = yield call(extractResponseMessage, e);
    yield put({
      ...action,
      type: ACTION_TYPES.SAVE_OOA_USER_FAILED,
      failureMessage:
        message ||
        "Unable to add user to mailing list.  Please contact Customer Service.",
    });
  }
}

// update user fields (if changed)
// create default address (if changed)
function* setDeliveryDetailsFlow(action: SetDeliveryDetailsAction) {
  const { values } = action;
  const { zip, deliveryWindowId, ...others } = values;
  let fieldsUpdated;
  let signupReasonFields;
  try {
    // zip could be out of sync as the address validation dialog may update the user's submitted zip
    const signupValues = yield select(getSignupValues);
    if (signupValues.zip !== zip) {
      yield call(setSelectedZipCodeFlowCheckout, { zip });
    }
    yield all([
      // update user fields (firstName, lastName, phone)
      call(updateUserFieldsFlow, action),
      // create address (address fields + delivery notes)
      deliveryWindowId && call(updateAddressFieldsFlow, action),
    ]);

    fieldsUpdated = true;
  } catch (e) {
    const message = yield call(extractResponseMessage, e);
    yield put({
      ...action,
      type: ACTION_TYPES.SET_DELIVERY_DETAILS_FAILED,
      failureMessage:
        message ||
        "Could not save delivery details. Please contact Customer Service.",
    });
  }

  if (fieldsUpdated) {
    const deliveryWindows = yield select(getDeliveryWindowIdsByZip, { zip });
    // if (deliveryWindowId && deliveryWindows && deliveryWindows.length) {
    const signupValues = yield select(getSignupValues);
    if (
      others.signupReasonId ||
      others.signupReasonNotes ||
      signupValues.signupReasonNotes
    ) {
      const { signupReasonId } = others;
      let { signupReasonNotes } = others;

      // Determine if currently selected reasonId allows notes
      const noteAllowed = signupReasonId
        ? yield select(isSignupReasonNoteAllowed, { signupReasonId })
        : false;

      // reset signupReasonNotes if the newly selected signup reason doesn't allow notes
      if (
        !noteAllowed &&
        (signupValues.signupReasonNotes || signupReasonNotes)
      ) {
        signupReasonNotes = "";
      }

      signupReasonFields = {
        signupReasonId,
        signupReasonNotes,
      };
    }

    const vals = {
      ...others,
      ...signupReasonFields,
      zip,
      selectedDeliveryWindowId: deliveryWindowId,
      deliveryWindows,
    };

    if (deliveryWindowId && deliveryWindows && deliveryWindows.length) {
      // secondary boxes are not all available across all FC's
      // if the zip has changed in the address form, the customer could cross FC's
      // in this case pull out any previously selected secondary boxes no longer available
      const availableSecondaryBoxIds = yield select(
        getSecondaryBoxesByDeliveryWindowId,
        { deliveryWindowId }
      );
      const addOnBoxIds = (signupValues.addOnBoxIds || []).filter((boxId) =>
        availableSecondaryBoxIds.includes(boxId)
      );

      yield put({
        ...action,
        type: ACTION_TYPES.SET_DELIVERY_DETAILS_SUCCEEDED,
      });
      yield put(
        signupStepComplete({
          ...vals,
          addOnBoxIds,
        })
      );
    } else {
      yield put({ ...action, type: ACTION_TYPES.SET_DELIVERY_DETAILS_FAILED });
      yield put(
        showModal(OOA_ZIP_CHANGE_MODAL_ID, {
          displayStartHere: false,
          zip,
        })
      );
    }
  }
}

function* updateUserFieldsFlow(action) {
  const { values } = action;
  const newFields = _pick(values, [
    "firstName",
    "lastName",
    "email",
    "phone",
    "signupZip",
    "signupOutOfArea",
  ]);
  const user = yield select(getUser);

  const updatedFields = Object.keys(newFields).reduce((acc, key) => {
    const value = newFields[key];
    if (value !== user[key]) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (Object.keys(updatedFields).length) {
    yield call(updateUserFlow, { values: updatedFields, rethrow: true });
    yield call(updateAuthUserAttributes, updatedFields);
  }
}

function* updateAddressFieldsFlow(action) {
  const { values, fuzzyValidation } = action;
  // TODO: 'how did you hear about us' field?
  const newFields = _pick(values, [
    "address",
    "addressLine2",
    "city",
    "state",
    "zip",
    "deliveryNotes",
  ]);
  const { defaultAddressId, mapOfAddresses } = yield select(getUser);
  let updatedFields = newFields;
  if (defaultAddressId) {
    const address = mapOfAddresses[defaultAddressId];

    updatedFields = Object.keys(newFields).reduce((acc, key) => {
      const value = newFields[key];
      if ((value && value !== address[key]) || (!value && !!address[key])) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  const keysToUpdate = Object.keys(updatedFields);

  if (keysToUpdate.length) {
    if (keysToUpdate.length === 1 && keysToUpdate[0] === "deliveryNotes") {
      yield call(updateAddressDeliveryNotesFlow, {
        ...updatedFields,
        addressId: defaultAddressId,
        rethrow: true,
      });
    } else {
      yield call(createAddressFlow, {
        values: newFields,
        rethrow: true,
        fuzzyValidation,
      });
    }
  }
}

function* syncSignupPreferencesFlow({ values }) {
  const { boxId, zip, selectedFrequency } = values;
  const userId = yield select(getUserId);
  if (userId && (boxId || zip || selectedFrequency)) {
    const signupPreferences = {
      ...(boxId && { boxId }),
      ...(zip && { zip }),
      ...(selectedFrequency && { cadence: selectedFrequency }),
    };
    yield call(updateSignupPreferencesFlow, { userId, signupPreferences });
  }
}

// TODO: NC-1244 productionalize signup shopping
function* updateCartFlow(action) {
  const existingVariantQuantity = yield select(
    getSignupVariantQuantity,
    action.variantId
  );

  if (existingVariantQuantity?.quantity !== action.quantity) {
    yield put(
      updateVariantQuantity({
        variantId: action.variantId,
        quantity: action.quantity,
      })
    );
    const items = yield select(getSignupVariantQuantities);
    yield put({ type: ORDER_ACTION_TYPES.FETCH_ORDER_POSIT, items });
  }
}
