// @ts-nocheck
import { em, rem } from "polished";
import { PropTypes } from "prop-types";
import React from "react";
import styled from "styled-components";

import routes from "app/router/routes.json";
import breakpoints from "app/styles/breakpoints";
import { imageReplacement } from "app/styles/utils";
import { getImageURL } from "app/ui/global/utils";
import Link from "app/ui/shared/Link";

const Logo = ({ children, url, useSmallHeaderLogo, ...rest }) => {
  return (
    <Link to={url} onMouseDown={(e) => e.preventDefault()} {...rest}>
      {children}
    </Link>
  );
};

Logo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  useSmallHeaderLogo: PropTypes.bool,
};

Logo.defaultProps = {
  url: routes.main.url,
};

// Keep Logo as full-sized image from cloudinary (30.57 KB)
Logo.Mini = styled(Logo)`
  ${imageReplacement};
  cursor: pointer;
  display: block;
  height: ${({ theme }) => `${theme.logo.mini.height}px`};
  width: ${({ theme }) => `${theme.logo.mini.width}px`};
  background: ${({ theme: { logo } }) =>
    `no-repeat center/contain url(${getImageURL(
      logo.mini.url,
      null,
      {},
      {}
    )})`};
`;

Logo.Vertical = styled(Logo)`
  ${imageReplacement};
  cursor: pointer;
  display: block;
  height: ${({ theme }) => `${theme.logo.vertical.height}px`};
  width: ${({ theme }) => `${theme.logo.vertical.width}px`};
  background: ${({ theme: { logo } }) =>
    `no-repeat center/contain url(${getImageURL(
      logo.vertical.url,
      null,
      {},
      {}
    )})`};
`;

const smallDesktopLogoBreakpoint = `@media screen and (max-width: ${em(
  1063
)}) and (min-width: ${em(992)})`;

/*
 * The smallDesktopLogoBreakpoint conditional styling is to make sure all header items fit on one line even with small desktop sizes.
 * If a user is shopping, we have more header items to accommodate.
 */
Logo.Responsive = styled(Logo.Mini)`
  display: block;
  position: relative;

  &:focus {
    outline: ${rem(3)} solid transparent; /* for WHCM users */

    &:after {
      content: "";
      position: absolute;
      top: ${rem(-2)};
      right: ${rem(-4)};
      bottom: ${rem(-4)};
      left: ${rem(-4)};
      border: ${({ theme }) => theme.borders.focus};
      box-shadow: ${({ theme }) => theme.boxShadows.focus};
    }
  }

  ${breakpoints.lg`
    height: ${({ theme }) => `${theme.logo.mini.height}px`}
    max-width: ${({ theme }) => `${theme.logo.mini.width}px`};
    background-image: ${({ theme: { logo } }) =>
      `url(${getImageURL(logo.vertical.url, null, {}, {})})`};
  `}

  ${breakpoints.xl`
    height: ${({ theme }) => `${theme.logo.vertical.height}px`};
    width: ${({ theme }) => `${theme.logo.vertical.width}px`};
    background-image: ${({ theme: { logo } }) =>
      `url(${getImageURL(logo.vertical.url, null, {}, {})})`};
  `}

  ${({ useSmallHeaderLogo }) =>
    useSmallHeaderLogo &&
    `${smallDesktopLogoBreakpoint}{
      max-width: ${rem(55)};
      transform: rotate(-30deg);
      background-image: url(${getImageURL(
        "carrot_logo.png",
        "website-assets"
      )});
    }
  `}
`;

export default Logo;
