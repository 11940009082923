// @ts-nocheck
import color from "color";
import { cover, em, rem } from "polished";
import PropTypes from "prop-types";
import { css, keyframes } from "styled-components";

export const GOLDEN_RATIO = 1.61803399;

export const StylePropTypes = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.array,
]);

export const colorUtils = {
  fade: (c: string, amount: number): string =>
    color(c).fade(amount).rgb().string(),
  alpha: (c: string, amount: number): string =>
    color(c).alpha(amount).rgb().string(),
};

// convert to Root-relative Em - input can be either an int, or array of ints
/**
 * @deprecated Use em() and rem() from polished instead.
 * convert to Root-relative Em - input can be either an int, or array of ints
 */
export const toRem = (size, rootRelative = true) => {
  if (Array.isArray(size)) {
    return size.map((s) => toRem(s, rootRelative)).join(" ");
  }
  if (!Number.isFinite(size) || size === 0) return size; // pass through values like 'auto' or 0
  return rootRelative ? rem(size) : em(size);
};

// Image Replacement - must also define a height, width, and background image
export const imageReplacement = `
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;

  a {
    ${cover()};
  }
`;

export const flexboxFix = `
  min-height: 0;
  min-width: 0;
`;

export const centered = `
  margin-left: auto;
  margin-right: auto;
`;

export const breakoutOfParent = `
  margin: ${toRem(-16)};
`;

const ellipsisAnimationName = keyframes`
  to {
    width: ${toRem(16)};
  }
`;

const asciiEllipsisCharacter = `"\\2026"`;

/*
 * @deprecated use app/ui/designSystem/molecules/EllipsisAnimationText/
 */
export const animatedAppendedEllipsis = css`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsisAnimationName.getName()} steps(4, end) 900ms infinite;
    content: ${asciiEllipsisCharacter};
    width: 0px;
  }
`;
