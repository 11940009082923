import { ellipsis, rem } from "polished";
import React from "react";
import styled from "styled-components";

export interface OfferingBrandNameProps
  extends React.HTMLAttributes<HTMLDivElement> {
  brandName: string;
}

const OfferingBrandName: React.FC<OfferingBrandNameProps> = ({
  brandName,
  className,
}) => {
  return <BrandName className={className}>{brandName}</BrandName>;
};

export default OfferingBrandName;

const BrandName = styled.div`
  color: ${({ theme }) => theme.colors.boxShadowGray};
  font-size: ${rem(13)};
  line-height: ${rem(15.85)};
  margin-bottom: ${rem(4)};
  ${ellipsis(undefined, 1)};
`;
