// @ts-nocheck
// TODO:
// Simplify and/or rename this component when we productize NeverList
// NeverList is now the only feature that uses this Wrapper

import PropTypes from "prop-types";
import React from "react";
import { css } from "styled-components";

import { toRem } from "app/styles/utils";
import Modal from "app/ui/global/modals/Modal";
import NeverListModal from "app/ui/global/modals/NeverListModal";

export const NEVER_LIST_MODAL_ID = "never-list";

const ListModalWrapper = ({ modalId }) => {
  return (
    <Modal modalId={modalId} dark narrow modalStyles={modalStyles}>
      {(closeModal, params) => {
        switch (modalId) {
          case NEVER_LIST_MODAL_ID:
            return <NeverListModal closeModal={closeModal} {...params} />;

          default:
            return null;
        }
      }}
    </Modal>
  );
};

const outer = css`
  border-radius: ${toRem(10)};
`;

const modalStyles = { outer };

ListModalWrapper.propTypes = {
  modalId: PropTypes.string.isRequired,
};

export default ListModalWrapper;
