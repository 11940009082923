import React from "react";
import styled from "styled-components";
import { rem } from "polished";

export enum ProductFlagTypes {
  MISC = "misc",
  ON_SALE = "on-sale",
  RETAIL_PRICE = "retail-price",
  SOLD_OUT = "sold-out",
}

enum ProductFlagColors {
  CHERRY = "cherry",
  TARO = "taro",
  LEMON = "lemon",
  BABA = "baba",
}

enum ProductFlagTextColors {
  MARSHMALLOW = "marshmallow",
  PEPPERCORN = "peppercorn",
}

export enum ProductFlagSizes {
  SMALL = "small",
  LARGE = "large",
}

export interface ProductFlagProps {
  type?: ProductFlagTypes;
  size?: ProductFlagSizes;
  children: React.ReactNode;
  className?: string;
}

const ProductFlag: React.FC<ProductFlagProps> = ({
  type = ProductFlagTypes.ON_SALE,
  size = ProductFlagSizes.SMALL,
  children,
  className,
}) => {
  return (
    <FlagShape
      $type={type}
      $size={size}
      className={className}
      data-testid="product-flag"
    >
      {children}
    </FlagShape>
  );
};

const FlagShape = styled.span<{
  $type: ProductFlagTypes;
  $size: ProductFlagSizes;
}>`
  ${({ theme, $type, $size }) => {
    const getFlagColors = () => {
      switch ($type) {
        case ProductFlagTypes.ON_SALE: {
          return {
            bgColor: ProductFlagColors.CHERRY,
            fontColor: ProductFlagTextColors.MARSHMALLOW,
          };
        }

        case ProductFlagTypes.RETAIL_PRICE: {
          return {
            bgColor: ProductFlagColors.BABA,
            fontColor: ProductFlagTextColors.PEPPERCORN,
          };
        }

        case ProductFlagTypes.SOLD_OUT: {
          return {
            bgColor: ProductFlagColors.TARO,
            fontColor: ProductFlagTextColors.MARSHMALLOW,
          };
        }

        default: {
          return {
            bgColor: ProductFlagColors.BABA,
            fontColor: ProductFlagTextColors.PEPPERCORN,
          };
        }
      }
    };

    return `
      font-weight: ${theme.fonts.weight.semiBold};
      background-color: ${theme.colors[getFlagColors().bgColor]};
      color: ${theme.colors[getFlagColors().fontColor]};
      height: ${$size === ProductFlagSizes.SMALL ? rem(28) : rem(48)};
      font-size: ${$size === ProductFlagSizes.SMALL ? rem(13) : rem(20)};
      padding-left: ${$size === ProductFlagSizes.SMALL ? rem(6) : rem(12)};
      padding-right: ${
        $size === ProductFlagSizes.SMALL ? rem(12.8) : rem(23.2)
      };
    `;
  }}
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
  z-index: 1;
`;

export default ProductFlag;
