import Typography, {
  TypographyProps as MUITypographyProps,
} from "@material-ui/core/Typography";
import styled from "styled-components";
import { rem } from "polished";
import breakpoints from "app/styles/breakpoints";
import fonts from "app/styles/fonts";
import theme from "app/styles/theme";
import { ColorNames } from "app/styles/colors";

// styled Typography components throw a TS error when passed the component prop. this will be resolved in MUI v5.
// https://github.com/mui-org/material-ui/issues/15695#issuecomment-978223484
export interface TypographyProps {
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "label" | "span";
}

// Adding custom variants to Typography (to match our design system)
// will be supported in MUI v5 (09 / 2021 release). We will be able to
// add those and then delete these at that point.
// https://next.material-ui.com/customization/theme-components/#adding-new-component-variants

// TODO: ENGM-183 these lg breakpoints should be changed to md so they switch like the other fonts
export interface Overline2Props extends MUITypographyProps {
  $textColor?: ColorNames;
}
export const Overline2 = styled(Typography)<Overline2Props>`
  color: ${({ theme, $textColor }) =>
    $textColor ? theme.colors[$textColor] : theme.colors.plum};
  font-family: ${({ theme }) => theme.fonts.imperfectHand};
  font-size: ${({ theme }) => theme.fonts.size.mobile.overlineTwo};
  line-height: ${rem(32)};
  text-transform: "none";

  ${breakpoints.lg`
    font-size: ${({ theme }) => theme.fonts.size.desktop.overlineTwo};
  `}
`;

export const Overline3 = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.imperfectHand};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.mobile.overlineThree};
  line-height: ${rem(22)};

  ${breakpoints.lg`
    font-size: ${({ theme }) => theme.fonts.size.desktop.overlineThree};
    line-height: ${rem(26)};
  `};
` as typeof Typography;

export const Caption2 = styled(Typography)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${theme.fonts.size.mobile.caption2};
  line-height: ${rem(16)};
  font-family: ${({ theme }) => theme.fonts.body};

  ${breakpoints.lg`
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.desktop.caption2};
  `};
` as typeof Typography;

export const Link3 = styled(Typography)`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  font-size: ${rem(13)};
  line-height: ${rem(16)};
  font-family: ${({ theme }) => theme.fonts.body};

  ${breakpoints.lg`
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    font-size: ${rem(12)};
  `};
` as typeof Typography;

export const Body3 = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${rem(12)};
  line-height: ${rem(20)};

  ${breakpoints.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `};
` as typeof Typography;

// we have several variants like Body2 semibold that can use this
// to cover most semibold variants
export const SemiBold = styled(Typography)`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
` as typeof Typography;

export const SemiBoldPrimary = styled(Typography)`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  color: ${({ theme }) => theme.palette.primary.main};
` as typeof Typography;

export const Italic = styled(Typography)`
  font-style: italic;
` as typeof Typography;

// Matches the mui error helper text
export const ErrorText = styled(Typography)`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.palette.error.main};
  font-size: ${rem(13)};
  line-height: ${rem(16)};
` as typeof Typography;

export const HelperText = styled(Typography)`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${theme.fonts.size.mobile.caption2};
  line-height: ${rem(16)};
  font-family: ${({ theme }) => theme.fonts.body};

  ${breakpoints.lg`
    font-size: ${theme.fonts.size.desktop.caption2};
  `};
`;

/*
 * Declare additional themes outside of the MUI variants
 * https://v4.mui.com/customization/typography/#variants
 */
export const caption2 = {
  fontFamily: fonts.body,
  fontSize: fonts.size.mobile.caption2,
  fontWeight: fonts.weight.normal,
  lineHeight: rem(16),

  [theme.breakpoints.up("md")]: {
    fontSize: fonts.size.desktop.caption2,
    fontWeight: fonts.weight.bold,
  },
};

export const Overline4 = styled(Typography)`
  ${({ theme }) => `color: ${theme.colors.taro};`}
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  text-transform: uppercase;

  ${breakpoints.md`
    font-size: ${rem(14)};
  `};
` as typeof Typography;
