import { ellipsis, rem } from "polished";
import React from "react";
import styled from "styled-components";

export interface OfferingFromLocationProps
  extends React.HTMLAttributes<HTMLDivElement> {
  fromLocation: string;
}

const OfferingFromLocation: React.FC<OfferingFromLocationProps> = ({
  fromLocation,
  className,
}) => {
  return (
    <FromLocation className={className}>Grown in {fromLocation}</FromLocation>
  );
};

export default OfferingFromLocation;

const FromLocation = styled.div`
  color: ${({ theme }) => theme.colors.boxShadowGray};
  font-size: ${rem(13)};
  line-height: ${rem(15.85)};
  margin-bottom: ${rem(4)};
  ${ellipsis(undefined, 1)};
`;
