import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import {
  offeringPreviewCardSizes,
  ProfileType as PreviewCardProfileType,
} from "app/ui/shopping/OfferingPreviewCard";
import OfferingPreviewCardContainer from "app/ui/shopping/OfferingPreviewCard/OfferingPreviewCardContainer";
import useAnchorIdHash from "./useAnchorIdHash";

export interface CatalogMobileOfferingProps {
  style: React.CSSProperties;
  variantId: string;
  shelfId: string;
  index: number;
  updateHash?: _.DebouncedFunc<(anchorId: string) => void>;
  viewportRef?: React.RefObject<HTMLDivElement>;
  lastInAisle: boolean;
}

const CatalogMobileOffering: React.FC<CatalogMobileOfferingProps> = React.memo(
  ({
    variantId,
    style,
    shelfId,
    updateHash,
    viewportRef,
    index,
    lastInAisle,
  }) => {
    const ref = useAnchorIdHash(shelfId, updateHash, viewportRef);

    return (
      <ListItemContainer ref={ref} style={style} $lastInAisle={lastInAisle}>
        <OfferingPreviewCardContainer
          key={variantId}
          profileType={PreviewCardProfileType.SLIM}
          variantId={variantId}
          source="single-page-catalog"
          rank={index}
          fromShelfId={shelfId}
        />
      </ListItemContainer>
    );
  }
);

const PADDING_BOTTOM = 24;
const LAST_IN_AISLE_PADDING_BOTTOM = 64;

const ListItemContainer = styled.div<{ $lastInAisle: boolean }>`
  padding-bottom: ${({ $lastInAisle }) =>
    $lastInAisle ? rem(LAST_IN_AISLE_PADDING_BOTTOM) : rem(PADDING_BOTTOM)};
`;

export default CatalogMobileOffering;

export interface CatalogMobileOfferingToken {
  component: typeof CatalogMobileOffering;
  componentProps: {
    variantId: string;
    shelfId: string;
    index: number;
    lastInAisle: boolean;
  };
  data: {
    updateHash: boolean;
  };
  listItemHeight: number;
}

const calculateHeight = (lastInAisle: boolean) => {
  return lastInAisle
    ? offeringPreviewCardSizes.slimProfileHeight + LAST_IN_AISLE_PADDING_BOTTOM
    : offeringPreviewCardSizes.slimProfileHeight + PADDING_BOTTOM;
};

export const getCatalogMobileOfferingToken = ({
  variantId,
  shelfId,
  index,
  lastInAisle,
}: {
  variantId: string;
  shelfId: string;
  index: number;
  lastInAisle: boolean;
}): CatalogMobileOfferingToken => ({
  component: CatalogMobileOffering,
  componentProps: {
    variantId,
    shelfId,
    index,
    lastInAisle,
  },
  data: {
    updateHash: true,
  },
  listItemHeight: calculateHeight(lastInAisle),
});
