import { call, put, select, all } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/account";
import { fetchNextDelivery } from "app/api/userService";
import { getUserId, getNowInUserFC } from "app/selectors";
import holdDurationOptions from "app/ui/shared/SubscriptionPause/SkipOrPauseCard/holdDurationOptions";

// TODO: NC-1010 Remove if subscription pause experiment fails
function* fetchSubPauseNextDeliveryFlow() {
  try {
    const userId = yield select(getUserId);
    const nowInUserFC = yield select(getNowInUserFC);
    const selectedHoldStartDate = nowInUserFC.clone().format("YYYY-MM-DD");

    const nextDeliveriesData = yield all(
      holdDurationOptions.map(({ value, unit }) => {
        const selectedHoldEndDate = nowInUserFC
          .clone()
          .add(value, unit)
          .format("YYYY-MM-DD");

        const params = {
          userId,
          selectedHoldStartDate,
          selectedHoldEndDate,
          ignoreActiveOrderForNextDelivery: true,
        };

        return call(fetchNextDelivery, params);
      })
    );

    // This next delivery data fetch is tightly coupled to the PauseInfo component
    // UI layer - future enhancement is to decouple this to be flexible for additional durations
    const deliveryDates = holdDurationOptions.reduce((acc, option, i) => {
      return { ...acc, [option.label]: nextDeliveriesData[i].nextDelivery };
    }, {});

    yield put({
      type: ACTION_TYPES.FETCH_SUB_PAUSE_NEXT_DELIVERY_SUCCEEDED,
      deliveryDates,
    });

    return deliveryDates;
  } catch (e) {
    yield put({
      type: ACTION_TYPES.FETCH_SUB_PAUSE_NEXT_DELIVERY_FAILED,
      e,
    });
    return e;
  }
}

export default fetchSubPauseNextDeliveryFlow;
