export { REHYDRATE } from "redux-persist";

type ActionSuffix = "SUCCEEDED" | "FAILED";

export const SUCCEEDED_SUFFIX: ActionSuffix = "SUCCEEDED";
export const FAILED_SUFFIX: ActionSuffix = "FAILED";

export type WithActionSuffix<T extends string> = `${T}_${ActionSuffix}`;

interface MakeActionTypes {
  prefixes: {
    [key: string]: string;
  };
  suffixes?: ActionSuffix[];
}

type ActionKeys<T extends string> = WithActionSuffix<T> | T;

export type ActionTypes<T extends string> = {
  [key in ActionKeys<T>]: key;
};

export default function ({
  prefixes = {},
  suffixes = [SUCCEEDED_SUFFIX, FAILED_SUFFIX],
}: MakeActionTypes) {
  return Object.keys(prefixes).reduce(
    (blob: MakeActionTypes["prefixes"], key: string) => {
      /* eslint-disable no-param-reassign, array-callback-return */
      blob[key] = prefixes[key];
      suffixes.map((suffix) => {
        const otherKey = `${key}_${suffix}`;
        blob[otherKey] = `${prefixes[key]}_${suffix}`;
      });
      return blob;
      /* eslint-enable */
    },
    {}
  );
}
