const defaultVerifyItem = <T extends unknown>(x: T) => !!x;

const safeArrayOfItems = <T extends unknown>(
  array: string[],
  mapOfItems: { [key: string]: T },
  verifyItem = defaultVerifyItem
) => {
  if (!array || !array.length || !mapOfItems) return [];
  return array.reduce((acc, id) => {
    const item = mapOfItems[id];
    if (verifyItem(item)) {
      acc.push(item);
    }
    return acc;
  }, [] as T[]);
};

export default safeArrayOfItems;
