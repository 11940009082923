import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import breakpoints from "app/styles/breakpoints";
import HeaderBannerConfig, {
  HeaderAlertType,
  HeaderAlertBannerColor,
} from "app/types/split/HeaderBannerConfig";

export interface HeaderAlertBannerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  headerBannerConfig?: HeaderBannerConfig;
}

const LoggedOutAlertBanner: React.FC<HeaderAlertBannerProps> = ({
  headerBannerConfig = {},
}) => {
  const {
    icon = "info-circle",
    message = "",
    backgroundColor = "warning",
  } = headerBannerConfig;

  return (
    <StyledBanner $backgroundColor={backgroundColor}>
      <FirstLine>
        <StyledFontAwesomeIcon $backgroundColor={backgroundColor} icon={icon} />
        <StyledFirstMessage
          data-testid="first-message"
          $backgroundColor={backgroundColor}
        >
          {message}
        </StyledFirstMessage>
      </FirstLine>
    </StyledBanner>
  );
};

interface StyledBannerProps {
  $backgroundColor: HeaderAlertType;
}

const StyledBanner = styled.div<StyledBannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px 0 20px;
  position: relative;
  top: ${({ theme }) => rem(theme.layout.loggedOutHeaderHeightMobile)};
  background-color: ${({ $backgroundColor }) => {
    return (
      HeaderAlertBannerColor[$backgroundColor] || HeaderAlertBannerColor.alert
    );
  }};
  z-index: ${({ theme }) => theme.layout.zIndex.stickySubHeader};
  ${breakpoints.xl`
    top: ${({ theme }) => rem(theme.layout.loggedOutHeaderHeightDesktop)};
  `}
`;

interface StyledMessageProps {
  $backgroundColor: HeaderAlertType;
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: (prop) => !["backgroundColor"].includes(prop),
})<StyledMessageProps>`
  color: ${({ theme, $backgroundColor }) => {
    return $backgroundColor === "urgent"
      ? theme.colors.white
      : theme.colors.fontDark;
  }};
`;

const FirstLine = styled.div`
  display: flex;
  align-self: center;
`;

const StyledFirstMessage = styled.p<StyledMessageProps>`
  font-size: ${rem(13)};
  padding: 0 0 0 14px;
  color: ${({ theme, $backgroundColor }) => {
    return $backgroundColor === "urgent"
      ? theme.colors.white
      : theme.colors.fontDark;
  }};
`;

export default LoggedOutAlertBanner;
