// @ts-nocheck
import makeActionTypes from "app/actionTypes";

const domain = "cms";
const prefixes = {
  FETCH_CONTENT_BY_UID: `${domain}/FETCH_CONTENT_BY_UID`,
};

const actionTypes = makeActionTypes({ prefixes });
export default actionTypes;
