export type OrderParamsType = {
  [key: string]: boolean;
};

/* Base order-service params for order fetching */
export const BASE_ORDER_PARAMS = {
  coupons: true,
  tax: false,
};

/* Detailed order-service params for order fetching */
export const DETAILED_ORDER_PARAMS = {
  ...BASE_ORDER_PARAMS,
  credits: true,
  boxFee: true,
  deliveryFee: true,
  discounts: true,
};

/* Detailed + tax order-service params for order fetching */
export const DETAILED_TAX_ORDER_PARAMS = {
  ...DETAILED_ORDER_PARAMS,
  tax: true,
};

export const hasDetailedOrderParams = (params: OrderParamsType) => {
  return !!params?.credits;
};

export const hasTaxDetailedOrderParams = (params: OrderParamsType) => {
  return !!params?.tax;
};

export const FREE_DELIVERY_THRESHOLD_DEFAULT = "DefaultThreshold";

export enum CancelReasonCode {
  SKIPPED_BY_CUSTOMER = "SkippedByCustomer",
  SKIPPED_BY_INTERNAL_USER = "SkippedByInternalUser",
  SKIPPED_BECAUSE_TEST_ACCOUNT = "SkippedBecauseTestAccount",
  DISPATCH_FAILED_UNDER_MINIMUM = "DispatchFailedUnderMinimum",
  DISPATCH_FAILED_NOT_AUTHORIZED = "DispatchFailedNotAuthorized",
  DISPATCH_FAILED_TESTUSER = "DispatchFailedTestUser",
  MISBEGOTTEN = "Misbegotten",
  SUBSCRIPTION_CANCELED = "SubscriptionCanceled",
  SUBSCRIPTION_HOLD_SET = "SubscriptionHoldSet",
  DELIVERED_NOT_CHARGED = "DeliveredNotCharged",
  DELIVERY_FAILED = "DeliveryFailed",
  FAILED_PACK = "FailedPack",
  DAMAGED = "Damaged",
  OVER_CAPACITY = "OverCapacity",
}
