import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import loadable from "app/ui/global/loadable";
import { MediaTablet } from "app/ui/global/media";
import { fetchCMSMenuByUID } from "app/reducers/cms";
import { HEADER_NAV_UID } from "app/constants";
import { HeaderProps } from "app/ui/universal/header/types";

const MobileHeader = loadable(
  () => import("./MobileHeader"),
  true
) as React.FC<HeaderProps>;
const DesktopHeader = loadable(
  () => import("./DesktopHeader"),
  true
) as React.FC<HeaderProps>;

const Header: React.FC<HeaderProps> = ({
  backgroundColor,
  ctaText,
  ctaTextMobile,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCMSMenuByUID(HEADER_NAV_UID));
  }, []);

  return (
    <MediaTablet>
      {(isTablet) =>
        isTablet ? (
          <MobileHeader
            backgroundColor={backgroundColor}
            ctaTextMobile={ctaTextMobile}
          />
        ) : (
          <DesktopHeader backgroundColor={backgroundColor} ctaText={ctaText} />
        )
      }
    </MediaTablet>
  );
};

export default Header;
