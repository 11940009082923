import { useEffect } from "react";

export default function useWindowEvent(
  event: string,
  callback: (e: Event) => void
) {
  useEffect(() => {
    window.addEventListener(event, callback);

    return () => {
      window.removeEventListener(event, callback);
    };
  }, [event, callback]);
}
