import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { clearDialog } from "app/reducers/ui";
import { Dialog } from "app/types/ui/Dialog";

/**
 * Ensures that the enter transition fires when mounting the dialog,
 * and that the exit transition resolves before unmounting.
 *
 * Provide `handleExited` to MUI Dialog's `TransitionProps` > `onExited`
 * prop, and use `handleClose` for any call to close the Dialog.
 */
const useHandleTransitions = (dialog: Dialog) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(true), []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleExited = () => {
    dispatch(clearDialog(dialog));
  };

  return { open, handleClose, handleExited };
};

export default useHandleTransitions;
