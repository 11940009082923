import * as fromFriendbuy from "app/reducers/friendbuy";
import State from "app/types/state";
import { APIType, APITypeProps } from "app/types/state/friendbuy";

export const getFriendbuyActiveAPIType = (state: State) =>
  fromFriendbuy.getActiveAPIType(state.friendbuy);

export const isFriendbuyInitialized = (state: State) =>
  fromFriendbuy.isInitialized(state.friendbuy);

export const getReferralDataForType = (state: State, props: APITypeProps) =>
  fromFriendbuy.getReferralDataForType(state.friendbuy, props);

export const getActiveReferralData = (state: State) =>
  fromFriendbuy.getActiveReferralData(state.friendbuy);

export const isFriendbuyAPITypeLoaded = (state: State, props: APITypeProps) =>
  getReferralDataForType(state, props)?.loaded;

export const getNextGenReferralData = (state: State) => {
  return getReferralDataForType(state, {
    apiType: APIType.NEXTGEN,
  });
};

export const getLegacyReferralData = (state: State) => {
  const referral = getReferralDataForType(state, {
    apiType: APIType.FIRSTGEN,
  });
  return referral;
};

export const getCapturedReferral = (state: State) => {
  const referralData = getNextGenReferralData(state);

  return referralData && referralData.referral;
};

// NC-1499 Remove legacy friendbuy
export const getLegacyReferral = (state: State) => {
  const referralData = getLegacyReferralData(state);
  return referralData && referralData.referral;
};

export const isFriendBuyNext = (state: State) =>
  getFriendbuyActiveAPIType(state) === APIType.NEXTGEN;
