// @ts-nocheck
import { take, fork, call, put } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/cms";
import { fetchContentByUID } from "app/api/cms";

export default function* rootCMS() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([ACTION_TYPES.FETCH_CONTENT_BY_UID]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_CONTENT_BY_UID: {
        yield fork(fetchContentByUIDFlow, action);
        break;
      }
      default:
        return;
    }
  }
}

export function* fetchContentByUIDFlow({ uid, contentType, params }) {
  try {
    const content = yield call(fetchContentByUID, { uid, contentType, params });

    if (content) {
      yield put({
        type: ACTION_TYPES.FETCH_CONTENT_BY_UID_SUCCEEDED,
        contentType,
        content,
        uid,
      });
    } else {
      yield put({
        type: ACTION_TYPES.FETCH_CONTENT_BY_UID_FAILED,
        contentType,
        uid,
        error: { status: 404 },
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.FETCH_CONTENT_BY_UID_FAILED,
      contentType,
      uid,
      error,
    });
  }
}
