import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { track as amplitudeTrack } from "@amplitude/analytics-browser";

import actions from "app/actionTypes/routing";
import { Route } from "app/types/router/Route";
import { getRoutingLocation, isNativeApp } from "app/selectors";
import { RouterLocation } from "app/types/state/extendedRouter";
import RouteChangedAction from "app/types/router/RouteChangedAction";
import normalizePath from "app/router/normalizePath";
import State from "app/types/state";

export const pageChangedAction = (
  routeMetadata: Route | null,
  location: RouterLocation<unknown>,
  normalizedPath: string
): RouteChangedAction => {
  return {
    type: actions.PAGE_CHANGED,
    routeMetadata,
    location,
    normalizedPath,
  };
};

export default function usePageChanged(
  routeMetadata: Route | null,
  enabled: boolean
) {
  // previous path is initially null
  const previousPathRef = useRef<string | null>(null);
  const dispatch = useDispatch();
  const location = useSelector(getRoutingLocation);
  const pathname = normalizePath(location.pathname);

  // amplitude page view tracking
  const pageName = (routeMetadata && routeMetadata.page) || "Unknown";
  const eventTitle = pageName
    ? `${pageName.toLowerCase()} page view`
    : "page view";
  const isNative = useSelector((state: State) => isNativeApp(state));
  const eventProperties = {
    ...location,
    domain: window.location.hostname,
    isNative,
  };
  amplitudeTrack(eventTitle, eventProperties);

  // has the route path changed?
  const hasRoutePathChanged = () => pathname !== previousPathRef.current;

  useEffect(() => {
    if (enabled && hasRoutePathChanged()) {
      dispatch(pageChangedAction(routeMetadata, location, pathname));
      // Store the pathname as the previous path following a page changed
      previousPathRef.current = pathname;
    }
  }, [enabled, pathname]);

  return {
    currentPath: pathname,
    previousPath: previousPathRef.current,
  };
}
