import React from "react";
import styled from "styled-components";

import { stripBrandNameFromOfferingName } from "app/ui/global/utils";

export interface OfferingNameTextProps {
  brandName: string | null;
  name: string;
  packagingUnitAmount: number | null;
  packageUnitFormatted: string | null;
  realUnitAmount: number | null;
  realUnitFormatted: string | null;
  realUnitIsApproximate: boolean | null;
  realUnitIsIndividual: boolean;
}

const OfferingNameText: React.FC<OfferingNameTextProps> = ({
  brandName,
  name,
  packagingUnitAmount,
  packageUnitFormatted,
  realUnitAmount,
  realUnitFormatted,
  realUnitIsApproximate,
  realUnitIsIndividual,
}) => {
  const quantity =
    packagingUnitAmount !== null && packageUnitFormatted
      ? {
          amount: packagingUnitAmount,
          unit: packageUnitFormatted,
        }
      : undefined;
  const weight =
    realUnitAmount !== null && realUnitFormatted
      ? {
          amount: realUnitAmount,
          unit: realUnitFormatted,
          isApproximate: !!realUnitIsApproximate,
        }
      : undefined;

  return (
    <>
      <span data-testid="offering-name">
        {stripBrandNameFromOfferingName(name, brandName)}
      </span>
      {!realUnitIsIndividual && (
        <>
          {quantity && (
            <>
              {", "}
              <span data-testid="offering-quantity">
                {quantity.amount}&nbsp;{quantity.unit}
              </span>
            </>
          )}
          {!quantity && weight && (
            <>
              {", "}
              <span data-testid="offering-weight">
                {weight.isApproximate && "~"}
                {weight.amount}&nbsp;{weight.unit}
              </span>
            </>
          )}{" "}
          {quantity && weight && (
            <StyledWeight data-testid="offering-weight">
              ({weight.isApproximate && "~"}
              {weight.amount}&nbsp;{weight.unit})
            </StyledWeight>
          )}
        </>
      )}
      {realUnitIsIndividual && weight && quantity && (
        <>
          {", "}
          <span data-testid="offering-weight">
            {weight.isApproximate && "~"}
            {weight.amount}&nbsp;{weight.unit}
          </span>
          {" x "}
          <span data-testid="offering-quantity">{quantity.amount}</span>
        </>
      )}
    </>
  );
};

export const StyledWeight = styled.span``;

export default OfferingNameText;
