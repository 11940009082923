import React from "react";
import styled from "styled-components";

import colors, { Color } from "app/styles/colors";

export interface WaveProps {
  bgcolor?: Color;
  color?: Color;
  bottom?: boolean;
}

const Wave: React.FC<WaveProps> = ({
  bgcolor,
  color = "baba",
  bottom = false,
}) => {
  const baseTransform = "matrix(-1, 0, 0, -1, 454.519989, 117.452766)";
  const bottomTransform = "rotate(45deg)";

  // Rotate the svg if it's the bottom border
  const transform = bottom
    ? `${baseTransform} ${bottomTransform}`
    : baseTransform;

  return (
    <SCWaveSVG
      viewBox="39.76 46.768 375 23.917"
      xmlns="http://www.w3.org/2000/svg"
      $bgcolor={bgcolor}
    >
      <path
        d="M 39.76 64.207 C 232.76 84.252 261.959 50.055 414.76 64.207 L 414.76 46.768 L 39.76 46.768 L 39.76 64.207 Z"
        fill={colors[color]}
        transform={transform}
      />
    </SCWaveSVG>
  );
};

const SCWaveSVG = styled.svg<{ $bgcolor?: Color }>`
  ${({ $bgcolor }) => $bgcolor && `background-color: ${colors[$bgcolor]};`}
  display: block;
`;

export default Wave;
