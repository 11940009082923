import React from "react";
import styled from "styled-components";

import { getImageURL } from "app/ui/global/utils";
import Link from "app/ui/shared/Link";

export enum Variant {
  AppStore = "APP_STORE",
  GooglePlayStore = "GOOGLE_PLAY_STORE",
}

interface VariantData {
  alt: string;
  href: string;
  imageId: string;
  imageSubdirectory: string;
}

const APP_STORE_VARIANT_DATA: VariantData = {
  alt: "Download on the App Store Badge",
  href: "https://apps.apple.com/us/app/imperfect-foods/id1577380836",
  imageId: "Download_on_the_App_Store_Badge_US.svg",
  imageSubdirectory: "website-assets",
};

const GOOGLE_PLAY_STORE_VARIANT_DATA: VariantData = {
  alt: "Get it on Google Play",
  href: "https://play.google.com/store/apps/details?id=com.imperfectfoods",
  imageId: "get_app_google_play.png",
  imageSubdirectory: "website-assets",
};

const getVariantData = (variant: Variant): VariantData => {
  switch (variant) {
    case Variant.AppStore:
      return APP_STORE_VARIANT_DATA;

    case Variant.GooglePlayStore:
      return GOOGLE_PLAY_STORE_VARIANT_DATA;

    default:
      return APP_STORE_VARIANT_DATA;
  }
};

export interface Props {
  height?: number;
  variant: Variant;
}

const AppBadge: React.FC<Props> = ({ height = 40, variant }) => {
  const { alt, href, imageId, imageSubdirectory } = getVariantData(variant);

  return (
    <StyledLink external newWindow to={href}>
      <BadgeImage
        alt={alt}
        src={getImageURL(imageId, imageSubdirectory, {
          dpr: 2.0,
          h: height * 2,
        })}
        $height={height}
        $variant={variant}
      />
    </StyledLink>
  );
};

export default AppBadge;

const StyledLink = styled(Link)`
  display: inline-flex;
`;

/**
 * The Google Play badge guidelines state that, "There must be clearspace
 * surrounding the badge equal to one-quarter the height of the badge."
 *
 * Ref: https://play.google.com/intl/en_us/badges/
 *
 * In order to maintain visual consistency, the following styles ahere
 * to this guideline across all variants.
 */
const BadgeImage = styled.img<{ $height: number; $variant: Variant }>`
  height: ${({ $height }) => $height}px;
  margin: ${({ $height }) => $height / 4}px;

  ${({ $height, $variant }) =>
    $variant === Variant.GooglePlayStore &&
    `
    height: ${$height + 2 * ($height / 4)}px;
    margin: 0;
  `}
`;
