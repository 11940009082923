// @ts-nocheck
const extractResponseMessage = (response) => {
  return extractResponse(response)
    .then((r = {}) => r?.message)
    .catch(() => null);
};

export const extractResponse = (response) => {
  if (
    !response ||
    !(typeof response.clone === "function") ||
    !(typeof response.text === "function")
  ) {
    return Promise.resolve(null);
  }
  return response
    .clone()
    .text()
    .then((text) => {
      if (!text) {
        return Promise.resolve(null);
      }

      return JSON.parse(text);
    });
};

export default extractResponseMessage;
