import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import RestrictedRoute from "app/router/RestrictedRoute";
import {
  isLoggedIn,
  isBootstrapped,
  hasUserBeenActive,
  splitTreatmentsInitialized,
  canUserViewReactivation,
  canUserViewGoodbye,
} from "app/selectors";
import routes from "app/router/routes.json";

import loadable from "app/ui/global/loadable";
import Header from "app/ui/header";
import colors from "app/styles/colors";

// Lazy-load routes
const Logout = loadable(() => import("../../ui/logout"));
const Account = loadable(() => import("../../ui/account"));
const Footer = loadable(() => import("../../ui/universal/footer"), true);
const Reactivate = loadable(() => import("../../ui/reactivate"));

export const accountRoutePaths = [
  routes.logout.url,
  routes.account.url,
  routes.recurringItems.url,
  routes.reactivate.url,
  routes.goodbye.url,
];

const AccountRoutes = () => {
  return (
    <>
      <Header />
      <Switch>
        <RestrictedRoute
          exact
          path={routes.logout.url}
          selector={isLoggedIn}
          initializedSelector={isBootstrapped}
          component={Logout}
          overrideMaintainenceRedirect
          overrideBoysenberryRedirect
        />
        <RestrictedRoute
          path={routes.account.url}
          selector={hasUserBeenActive}
          spinnerBeforeInitialized
          component={Account}
          bgColor={colors.baba}
        />
        <RestrictedRoute
          path={routes.goodbye.url}
          selector={canUserViewGoodbye}
          spinnerBeforeInitialized
          initializedSelector={splitTreatmentsInitialized}
          component={Reactivate}
        />
        <RestrictedRoute
          path={routes.reactivate.url}
          selector={canUserViewReactivation}
          spinnerBeforeInitialized
          initializedSelector={splitTreatmentsInitialized}
          component={Reactivate}
        />
        <Route path={routes.recurringItems.url}>
          <Redirect to={routes.account.url} />
        </Route>
      </Switch>
      <Footer />
    </>
  );
};

export default AccountRoutes;
