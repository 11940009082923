import React from "react";
import { Box } from "@material-ui/core";

const LoadingSkeleton: React.FC = () => {
  return (
    // Could eventually use MUI lab Skeleton for more interactive loading
    <Box bgcolor="custom.neutral.baba" height={1500} width="100%" />
  );
};

export default LoadingSkeleton;
