import * as fromAnalytics from "../reducers/analytics";

import State from "app/types/state";
import { ImpactClick } from "app/types/state/analytics";

export function isAnalyticsInitialized(state: State): boolean {
  return fromAnalytics.isAnalyticsInitialized(state.analytics);
}

export function getAnonymousId(state: State) {
  return fromAnalytics.getAnonymousId(state.analytics);
}

export function isLoggingOut(state: State) {
  return fromAnalytics.isLoggingOut(state.analytics);
}

export function getImpactClick(state: State): ImpactClick {
  return fromAnalytics.getImpactClick(state.analytics);
}
