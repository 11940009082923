// @ts-nocheck
import queryString from "query-string";
import { call } from "redux-saga/effects";

import request from "./request";

import { config } from "app/config";

const baseUrl = config.get("endpoints.gateway");

// eslint-disable-next-line import/prefer-default-export
export function* fetchAllOfferings(params) {
  const { tags, ...others } = params;
  const url = `${baseUrl}/offerings?${queryString.stringify(others)}`;
  return yield call(request, url, { tags }, "json");
}

export function* fetchBoxes({ tags }) {
  const url = `${baseUrl}/boxes`;
  return yield call(request, url, { tags }, "json", false);
}

export function* fetchTags(params) {
  const { tags, ...others } = params;
  const url = `${baseUrl}/tags`;
  const queryParams = others ? `?${queryString.stringify(others)}` : "";
  return yield call(request, `${url}${queryParams}`, { tags }, "json", false);
}

// eslint-disable-next-line
export function* fetchAisles(params) {
  const { tags, ...others } = params;
  const url = `${baseUrl}/aisles`;
  const queryParams = others ? `?${queryString.stringify(others)}` : "";
  return yield call(request, `${url}${queryParams}`, { tags }, "json", false);
}

export function* fetchAllOfferingsById(params) {
  const url = `${baseUrl}/offerings/by-variants`;
  const body = JSON.stringify(params);
  return yield call(request, url, { method: "POST", body }, "json");
}
