import { createMuiTheme, ThemeOptions } from "@material-ui/core";
import { StyledTheme, ExtendedMUITheme, OverridesFunc } from "app/types/styles";

const createTheme = (
  styledTheme: StyledTheme,
  muiTheme: ThemeOptions,
  muiOverrides?: OverridesFunc
): ExtendedMUITheme => {
  const extendedTheme = {
    ...createMuiTheme(muiTheme),
    ...styledTheme,
  };

  if (muiOverrides) {
    extendedTheme.overrides = muiOverrides(extendedTheme);
  }

  return extendedTheme;
};

export default createTheme;
