import { call } from "redux-saga/effects";

import { trackEvent } from "app/api/segment";

export function* triageSkipOrder() {
  yield call(trackEvent, "Skip Delivery Clicked", {
    label: "Skipped active order",
  });
}

export function* triageSkipFutureOrder() {
  yield call(trackEvent, "Skip Delivery Clicked", {
    label: "Skipped future order",
  });
}
