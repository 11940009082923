import React from "react";
import { ActiveOrderExpanded } from "./CartTotalBreakdown";

/*
 * Render delivery fee or the text 'FREE'
 */
// eslint-disable-next-line import/prefer-default-export
export const renderDeliveryFee = (order: ActiveOrderExpanded) => {
  const { deliveryFeeAfterDiscount } = order;

  if (
    deliveryFeeAfterDiscount &&
    deliveryFeeAfterDiscount !== "0.00" &&
    !deliveryFeeAfterDiscount.startsWith("-")
  ) {
    return <>${deliveryFeeAfterDiscount}</>;
  }

  return "FREE";
};

/*
 * Render a list of coupon codes
 */
export const renderCoupons = (order: ActiveOrderExpanded) => {
  if (!order || !order.coupons || !order.coupons.length) return null;

  const { coupons } = order;
  const codes = coupons.map((coupon) => coupon.code);

  return codes.join(", ");
};
