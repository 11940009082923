import { take, fork, call, put, select } from "redux-saga/effects";

import UI_ACTION_TYPES from "app/actionTypes/ui";
import FRIENDBUY_ACTION_TYPES from "app/actionTypes/friendbuy";
import { applyReferralAttribution } from "app/api/userService";
import {
  isFriendbuyLoaded,
  onCouponReceived,
  onWidgetActionTriggered,
  getVisitorStatus,
} from "app/api/friendbuy";
import {
  CouponReceivedCallback,
  WidgetActionTriggeredCallback,
  VisitorStatusCallback,
  VisitorStatusPayload,
} from "app/types/api/friendbuy";
import * as Monitoring from "app/monitoring";
import {
  applyAttributionAction,
  applyAttributionFailedAction,
  applyAttributionSuccessAction,
  friendbuyCaptureReferralAction,
  friendbuyVisitorStatusAction,
} from "app/reducers/friendbuy";
import {
  getNextGenReferralData,
  canApplyNextGenCoupon,
  getUserId,
} from "app/selectors";
import { store } from "app/ui/App";
import {
  FRIEND_TAGS,
  ADVOCATE_TAGS,
  APIType,
  FriendbuyVisitorStatusAction,
  ReferralAttributes,
  ReferralData,
  LogEventCode,
  ApplyAttributionAction,
  UserReferralAttribution,
} from "app/types/state/friendbuy";
import {
  friendbuyBootstrapFlow,
  waitForFriendbuyReady,
  fetchReferralCouponFlow,
  triageAPITypeChanged,
  waitForFriendbuyInitialized,
} from "./shared";
import getReferralFromVisitorStatus, {
  isValidReferral,
} from "app/utils/transformations/getReferralFromVisitorStatus";
import { LogLevel } from "app/types/monitoring";

export const apiType = APIType.NEXTGEN;

/* eslint-disable max-len */
/*
 * Relevant Information:
 * a) Initialization occurs at app bootstrap time, after login, and after user creation (in signup)
 * b) After INITIALIZE_SUCCEEDED, we are guaranteed to have accurate information on the logged in user
 * c) FB widgets are controlled via segment page events
 *
 * Main Flows:
 * 1) Capture Referral should happen as early in app initialization as possible (bootstrap), but only once in a session.
 * 2) TODO: NC-1495 Fetch referral coupon from referral campaign id shortly after referral capture (for display purposes)
 * 4) TODO: NC-1496 Carry the referral data captured from visitor status to the end of signup
 */
/* eslint-enable max-len */

export default function* rootFriendbuy() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      UI_ACTION_TYPES.BOOTSTRAP_COMPLETE,
      UI_ACTION_TYPES.INITIALIZE_SUCCEEDED,
      FRIENDBUY_ACTION_TYPES.VISITOR_STATUS,
      FRIENDBUY_ACTION_TYPES.CAPTURE_REFERRAL,
      FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION,
    ]);

    switch (action.type) {
      case UI_ACTION_TYPES.BOOTSTRAP_COMPLETE: {
        yield fork(bootstrapFlow);
        yield fork(initializeFriendFlow);
        break;
      }

      // We re-initialize when we login, create an account (signup), and at startup
      case UI_ACTION_TYPES.INITIALIZE_SUCCEEDED: {
        if (action.loggedIn) {
          yield fork(advocateInitializationFlow);

          // We re-initialize when we login, create an account (signup), and at startup
          yield fork(applyReferralTriageFlow);
        }
        break;
      }
      case FRIENDBUY_ACTION_TYPES.VISITOR_STATUS: {
        if (action.apiType === apiType) {
          yield fork(captureReferral, action);
        }
        break;
      }
      case FRIENDBUY_ACTION_TYPES.CAPTURE_REFERRAL: {
        yield fork(fetchReferralCouponFlow, action);
        break;
      }
      case FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION: {
        yield fork(applyAttributionFlow, action);
        break;
      }

      default:
        break;
    }
  }
}

// Triggered by FB when visitor status is available
export const getVisitorStatusCallback: VisitorStatusCallback = (
  visitorStatus
) => {
  Monitoring.sendLog({
    level: LogLevel.INFO,
    tags: FRIEND_TAGS,
    message: "Friendbuy visitor status received",
    visitorStatus,
    apiType,
    eventCode: LogEventCode.VISITOR_STATUS_RECEIVED,
  });
  return dispatchAction(friendbuyVisitorStatusAction(apiType, visitorStatus));
};

// Triggered by FB when single-use coupon available
// Not currently in use but log for visibility
export const couponReceivedCallback: CouponReceivedCallback = (coupon) => {
  Monitoring.sendLog({
    level: LogLevel.INFO,
    tags: FRIEND_TAGS,
    message: "Friendbuy single-use coupon received",
    coupon,
    apiType,
    eventCode: LogEventCode.COUPON_RECEIVED,
  });
  return dispatchAction({
    type: FRIENDBUY_ACTION_TYPES.COUPON_RECEIVED,
    coupon,
  });
};

// Triggered by FB when widget action is triggered
// Not currently in use but log for visibility
export const widgetActionTriggeredCallback: WidgetActionTriggeredCallback = (
  widgetAction
) => {
  Monitoring.sendLog({
    level: LogLevel.INFO,
    tags: ADVOCATE_TAGS,
    message: "Friendbuy widget triggered",
    widgetAction,
    apiType,
    eventCode: LogEventCode.WIDGET_ACTION_TRIGGERED,
  });
  return dispatchAction({
    type: FRIENDBUY_ACTION_TYPES.WIDGET_TRIGGERED,
    widgetAction,
  });
};

const dispatchAction = (action: unknown) => {
  try {
    store.dispatch(action);
  } catch (error) {
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: FRIEND_TAGS,
      message: "Friendbuy dispatch callback failed",
      error,
      action,
    });
  }
};

export function* bootstrapFlow() {
  yield call(friendbuyBootstrapFlow, isFriendbuyLoaded, apiType);
}

export function* waitForLoaded(isAdvocateFlow = false) {
  return yield call(waitForFriendbuyReady, apiType, isAdvocateFlow);
}

// Should be called once at boostrap
export function* initializeFriendFlow() {
  yield call(waitForLoaded);
  // Call these once
  yield call(onCouponReceived, couponReceivedCallback);
  yield call(getVisitorStatus, getVisitorStatusCallback);
  yield call(onWidgetActionTriggered, widgetActionTriggeredCallback);
}

export function* advocateInitializationFlow() {
  yield call(triageAPITypeChanged, apiType);
}

/*
 * This should be called early in the app initialization flow so that
 * the FriendBuy referral data can be captured and saved in state.
 */
export function* captureReferral({
  visitorStatus,
}: FriendbuyVisitorStatusAction) {
  let campaignId: string | null = null;
  let advocateUserId: string | null = null;
  try {
    const referral: ReferralAttributes = yield call(
      getReferralFromVisitorStatus,
      visitorStatus as VisitorStatusPayload
    );

    campaignId = referral?.campaignId || null;
    advocateUserId = referral?.referringUserId;

    if (isValidReferral(referral)) {
      yield put(friendbuyCaptureReferralAction(referral));
      Monitoring.sendLog({
        level: LogLevel.INFO,
        tags: FRIEND_TAGS,
        message: "Friendbuy referral captured",
        referral,
        apiType,
        campaignId,
        advocateUserId,
        eventCode: LogEventCode.VALID_REFERRAL_CAPTURED,
      });
    }
  } catch (error) {
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: FRIEND_TAGS,
      message: "Friendbuy referral could not be captured",
      error,
      apiType,
      campaignId,
      advocateUserId,
    });
  }
}

export function* applyReferralTriageFlow() {
  // Set isAdvocateFlow to true because we want to wait for split to return just in case
  // Both next-gen & first-gen referrals are present, and split is tie-breaker
  yield call(waitForFriendbuyInitialized);
  const shouldApplyReferralCoupon = yield select(canApplyNextGenCoupon);
  const referralData: ReferralData | null = yield select(
    getNextGenReferralData
  );

  if (
    shouldApplyReferralCoupon &&
    !!referralData?.referral &&
    !!referralData.referralCouponCode
  ) {
    const userId = yield select(getUserId);
    yield put(
      applyAttributionAction(
        userId,
        referralData.referral as ReferralAttributes,
        referralData.referralCouponCode
      )
    );
  }
}

export function* applyAttributionFlow(action: ApplyAttributionAction) {
  const { userId, referral, couponCode } = action;
  const params = {
    campaignId: referral?.campaignId,
    referralCode: referral?.referralCode,
    referringUserId: referral?.referringUserId,
    couponCode,
  };
  try {
    // Applies couponCode to user account and caches attribution data in case friendbuy attribution caching fails
    // We will consider the signup event as the official "referral applied" event
    const userReferralAttribution: UserReferralAttribution = yield call(
      applyReferralAttribution,
      userId,
      params
    );
    Monitoring.sendLog({
      level: LogLevel.INFO,
      tags: FRIEND_TAGS,
      message: "Applied referral coupon",
      apiType,
      eventCode: LogEventCode.REFERRAL_COUPON_APPLIED,
      ...params,
    });
    yield put(
      applyAttributionSuccessAction(
        userId,
        referral,
        couponCode,
        userReferralAttribution
      )
    );
  } catch (error) {
    yield put(
      applyAttributionFailedAction(userId, referral, couponCode, error)
    );
    Monitoring.sendLog({
      level: LogLevel.ERROR,
      tags: FRIEND_TAGS,
      message: "Apply referral coupon failed",
      apiType,
      eventCode: LogEventCode.REFERRAL_COUPON_FAILED,
      error,
      ...params,
    });
  }
}
