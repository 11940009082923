import { Middleware } from "redux";

import { SUCCEEDED_SUFFIX, FAILED_SUFFIX } from "app/actionTypes";

/*
 * Automatically call the onAsyncSuccess or onAsyncFailure callback with an optional message.
 */
const middleware: Middleware = () => (next) => (action) => {
  const { onAsyncSuccess, onAsyncFailure, type } = action;
  if (onAsyncSuccess && type.endsWith(SUCCEEDED_SUFFIX)) {
    onAsyncSuccess(action);
  } else if (onAsyncFailure && type.endsWith(FAILED_SUFFIX)) {
    onAsyncFailure(action);
  }

  return next(action);
};

export default middleware;
