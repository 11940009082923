import DonationPartner from "app/types/state/deliveries/DonationPartner";
import {
  SkippedActiveOrder,
  SkippedNextOrder,
} from "app/types/account/NextDeliveryResponse";
import UserNextDelivery from "app/types/account/UserNextDelivery";

export type Windows = Record<string, FullWindowData>;
export enum DeliveryProvider {
  FedEx = "FedEx",
  Onfleet = "OnFleet",
  Bringg = "Bringg",
  EmployeePickUp = "PickUp",
}

type DeliveryProviderType = "FedEx" | "OnFleet" | "Bringg" | "PickUp";

export interface FullWindowData {
  windowId: string;
  fulfillmentCenterId: string;
  startDay: number;
  startTime: string;
  endDay: number;
  endTime: string;
  dispatchDay?: number;
  dispatchTime?: string;
  customizationStartDay: number;
  customizationStartTime: string;
  customizationEndDay: number;
  customizationEndTime: string;
  deliveryPrice?: number;
  orderBy?: number;
  isVisible?: boolean;
  messageToUser?: string;
  dbpRouteId?: null | number;
  dbpWindowId?: null | number;
  created?: string;
  archived: boolean;
  archivedDate?: null | string;
  smsNotifications?: boolean;
  updated?: string;
  packDateOffset?: number;
  deliveryProvider?: DeliveryProvider | DeliveryProviderType;
  subtotalMin?: null | number;
  carrierDaysInTransit?: null | number;
  zoneId?: string;
  zoneName?: string;
  fcName?: string;
  fcAbbreviation?: string;
  marketName?: string;
  capacitySummary?: {
    hasCapacity: boolean;
    signupMessageToUser: string;
  };
  startDayFC?: number;
  startTimeFC?: string;
  endDayFC?: number;
  endTimeFC?: string;
  customizationStartDayFC?: number;
  customizationStartTimeFC?: string;
  customizationEndDayFC?: number;
  customizationEndTimeFC?: string;
  pickupTime?: string;
  isLineHaul?: boolean;
  transitTime?: unknown;
  marketCode?: string;
  timezone: string;
  timezoneFC: string;
  nextDelivery?: NextDelivery;
  zipCodeSummary?: ZipCodeSummary;
}
export interface FullWindowExtended extends FullWindowData {
  label: string;
}

export interface SignupSelectedWindowData
  extends Omit<FullWindowData, "nextDelivery"> {
  nextDelivery: NextDelivery;
}

export interface NextDelivery {
  windowId: string;
  deliveryDate: string;
  deliveryDateOffset: number;
  isReplacementDeliveryDate: boolean;
  replacementOriginalDeliveryDate: null | string;
  inCustomizationPeriod: boolean;
  nextCustomizationStartDate: string;
  canCreateOrderSyncronously: boolean;
  canCustomizationJobRun: boolean;
  isCustomizationDelayed: boolean;
  isWarming: boolean;
  warmingEndDate: null | string;
  canDonate: boolean;
  donationPartners: DonationPartner[];
  packDate: string;
  hasCapacity: boolean;
  timestamp: string;
}

export interface ZipCodeSummary {
  zipCode: string;
  warmingEndDate: string | null;
  isWarming: boolean;
  warmingTimestamp: string;
  warmingMessageToUser: string | null;
  warmingEndDateIsExact: boolean | null;
  warmingSignupConfirmationMessage: string | null;
  accountMessageToUser: string;
  fullServiceStartDate: string | null;
  isZipServicedByWindow: boolean;
}
export interface DeliveryWindow {
  startDate: string | null;
  startDay: number | null;
  startTime: string | null;
  endDate: string | null;
  endDay: number | null;
  endTime: string | null;
  customizationStartDate: string | null;
  customizationStartTime: string | null;
  customizationEndDate: string | null;
  customizationEndTime: string | null;
  timezone?: string | null;
  timezoneFC: string;
  windowId?: string;
  zipCodeSummary?: ZipCodeSummary;
}

export interface FlexibleDelivery {
  windowId: string;
  deliveryDate: string;
  deliveryDateOffset: number;
  isReplacementDeliveryDate: boolean;
  replacementOriginalDeliveryDate: string | null;
  inCustomizationPeriod: boolean;
  nextCustomizationStartDate: string | null;
  nextCustomizationEndDate: string | null;
  nextCustomizationStartDateTime: string | null;
  nextCustomizationEndDateTime: string | null;
  canCreateOrderSyncronously: boolean;
  canCustomizationJobRun: boolean;
  isCustomizationDelayed: boolean;
  isWarming: boolean;
  warmingEndDate: string | null;
  canDonate: boolean;
  donationPartners: DonationPartner[] | null;
  packDate: string | null;
  hasCapacity: boolean;
  timestamp: string | null;
  flexibleOrderStartDateTime: string | null;
  flexibleOrderEndDateTime: string | null;
  canCreateFlexibleOrderNow: boolean;
}

export interface ShipmentTrackingInfo {
  orderId: string;
  deliveryDate: Date;
  status: string;
  photoUrl: string | null;
  trackingUrl: string | null;
}

export default interface DeliveriesState {
  windows: Windows | {};
  windowIdsByZip: Record<string, string[]>;
  fetchingWindowsByZip: boolean;
  nextDelivery: UserNextDelivery | null;
  nextDeliveryTimestamp: string | null;
  skippedActiveOrder: SkippedActiveOrder | null;
  skippedNextOrder: SkippedNextOrder | null;
  nextAvailableWindowDelivery: NextDelivery | null;
  zipCodeSummary?: ZipCodeSummary | null;
  flexibleDeliveries?: FlexibleDelivery[] | null;
  // TODO: NC-1010 Remove if subscription pause experiment fails
  subPauseNextDeliveryDates?: { string: UserNextDelivery } | {};
  trackingInfo: ShipmentTrackingInfo | null;
}
