import _omit from "lodash/omit";
import _uniq from "lodash/uniq";

import {
  UpdateDeliveryInfoParams,
  UpdateDeliveryInfoWithValidationParams,
  UpdateDeliveryInfoQueryParams,
} from "app/types/account/updateDeliveryInfo";
import FetchPaymentSources from "app/types/account/FetchPaymentSources";
import AccountState, {
  AccountAddress,
  NeverItem,
  PaymentSource,
  RecurringItemProduct,
  SignupOffset,
  CancelSubscriptionStatus,
  AddPaymentSourceAction,
} from "app/types/state/account/Account";
import ApplyCouponParams from "app/types/state/account/ApplyCouponParams";
import {
  CancelSubscription,
  CancelSubscriptionAction,
} from "app/types/state/account/CancelSubscription";
import { CancellationReasonsChosen } from "app/types/state/account/CancellationReasonsChosen";
import { CancellationWinbackViewed } from "app/types/state/account/CancellationWinbackViewed";
import { DonateFutureOrder } from "app/types/state/account/DonateFutureOrder";
import { SetAddonBoxIds } from "app/types/state/account/SetAddonBoxIds";
import { SetMessageAsRead } from "app/types/state/account/SetMessageAsRead";
import { SetPlusMembershipAutoRenewal } from "app/types/state/account/SetPlusMembershipAutoRenewal";
import {
  SkipOrder,
  SkipOrderParams,
  UnskipOrderParams,
} from "app/types/account/SkipOrderAction";
import { UndonateFutureOrder } from "app/types/state/account/UndonateFutureOrder";
import {
  AddVacationHold,
  RemoveVacationHold,
} from "app/types/account/VacationHoldActions";
import { SignupCadence } from "app/types/state/signup";
import ACTION_TYPES from "app/actionTypes/account";
import FRIENDBUY_ACTION_TYPES from "app/actionTypes/friendbuy";
import { USER_STAGE_ACTIVE } from "app/selectors/account";
import { stripNonNumeric } from "app/ui/global/utils";
import AddPaymentSourceParams, {
  AddPaymentSourceRequestParams,
} from "app/types/state/account/AddPaymentSourceParams";
import { ApplyAttributionSuccessAction } from "app/types/state/friendbuy";
import { OnSubmitConfig } from "app/ui/forms/onSubmit";

export const initialState: AccountState = {
  user: {
    defaultAddressId: null,
    addresses: [],
    mapOfAddresses: {},
    signupPreferences: {
      boxId: null,
      cadence: null,
      zip: null,
    },
    messagesInQueue: [],
    neverList: [],
    recurringItems: {
      products: {},
    },
    phone: null,
    cadence: null,
    cadenceOffset: null,
  },
  creditBalance: 0,
  creditTransactions: [],
  paymentSources: {
    loading: false,
    defaultSourceId: null,
    sources: [],
    mapOfPaymentSources: {},
  },
  signupReasons: [],
  cancellationReasons: [],
  removingCoupon: null,
  winbackAccepted: false,
  shouldAutoSkipOrder: false,
  shouldAutoSwitchCadence: false,
  lastAutoSkippedOrder: null,
  rewardCouponUnused: true,
  skipActionInProgress: null,
  cancelSubscriptionStatus: null,
  markMessageAsReadInProgress: null,
  addingSecondaryBoxIds: [],
};

// Manually define action types where appropriate
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addUser = (state: AccountState, action: any = {}) => {
  const { addresses, mapOfAddresses } = (action.user.addresses || []).reduce(
    (
      acc: {
        addresses: string[];
        mapOfAddresses: Record<string, AccountAddress>;
      },
      address: AccountAddress
    ) => {
      acc.addresses.push(address.addressId);
      acc.mapOfAddresses = {
        ...acc.mapOfAddresses,
        [address.addressId]: address,
      };
      return acc;
    },
    { addresses: [], mapOfAddresses: {} }
  );

  const recurringItems = action.user.recurringItems.reduce(
    (
      acc: { products: { [x: string]: RecurringItemProduct } },
      item: { itemType: string; itemId: string | number; quantity: number }
    ) => {
      if (item.itemType === "product") {
        acc.products[item.itemId] = { quantity: item.quantity };
      }
      return acc;
    },
    { products: {} }
  );

  const neverList = action.user.neverItems.reduce(
    (acc: string[], item: NeverItem) => {
      if (item.itemType === "product" && !acc.includes(item.itemId)) {
        acc.push(item.itemId);
      }
      return acc;
    },
    []
  );

  return {
    ...state,
    user: {
      ...action.user,
      phone: stripNonNumeric(action.user.phone || ""),
      addresses,
      mapOfAddresses,
      recurringItems,
      neverList,
    },
  };
};

const handleReferralAttributionSucceeded = (
  state: AccountState,
  action: ApplyAttributionSuccessAction
) => {
  const { userReferralAttribution } = action;
  return {
    ...state,
    user: {
      ...state.user,
      // user record values that come back
      deferredCoupon: userReferralAttribution.deferredCoupon,
      referringUserId: userReferralAttribution.referringUserId,
      referringCampaignId: userReferralAttribution.referringCampaignId,
      referringUserReferralCode:
        userReferralAttribution.referringUserReferralCode,
      referralDate: userReferralAttribution.referralDate,
    },
  };
};

// Manually define action types where appropriate
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USER_SUCCEEDED:
    case ACTION_TYPES.UPDATE_USER_SUCCEEDED:
    case ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE_SUCCEEDED:
      return addUser(state, action);
    case ACTION_TYPES.UPDATE_SIGNUP_PREFERENCES_SUCCEEDED: {
      return {
        ...state,
        user: {
          ...state.user,
          signupPreferences: action.signupPreferences,
        },
      };
    }
    case ACTION_TYPES.CREATE_ADDRESS_SUCCEEDED: {
      const { address } = action;
      const { addressId } = address;
      return {
        ...state,
        user: {
          ...state.user,
          defaultAddressId: addressId,
          addresses: [...state.user.addresses, addressId],
          mapOfAddresses: {
            ...state.user.mapOfAddresses,
            [addressId]: address,
          },
        },
      };
    }

    case ACTION_TYPES.UPDATE_ADDRESS_DELIVERY_NOTES_SUCCEEDED: {
      const { addressId, deliveryNotes } = action;
      const address = state.user.mapOfAddresses[addressId];
      return {
        ...state,
        user: {
          ...state.user,
          mapOfAddresses: {
            ...state.user.mapOfAddresses,
            [addressId]: {
              ...address,
              deliveryNotes,
            },
          },
        },
      };
    }

    case ACTION_TYPES.CREATE_USER_SUCCEEDED:
    case ACTION_TYPES.FETCH_USER_SUMMARY_SUCCEEDED: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    }

    case ACTION_TYPES.FETCH_PAYMENT_SOURCES:
      return {
        ...state,
        paymentSources: {
          ...state.paymentSources,
          loading: action.resetStatus ? true : state.paymentSources.loading,
        },
      };
    case ACTION_TYPES.FETCH_PAYMENT_SOURCES_SUCCEEDED:
      return {
        ...state,
        paymentSources: {
          ...state.paymentSources,
          loading: false,
          defaultSourceId: action.default_source,
          sources: action.sources.map((s: PaymentSource) => s.id),
          mapOfPaymentSources: action.sources.reduce(
            (acc: { [x: string]: PaymentSource }, source: PaymentSource) => {
              acc[source.id] = source;
              return acc;
            },
            {}
          ),
        },
      };
    case ACTION_TYPES.FETCH_PAYMENT_SOURCES_FAILED:
      return {
        ...state,
        paymentSources: {
          ...state.paymentSources,
          status: {
            successMessage: null,
            failureMessage: action.error?.message || null,
          },
          loading: false,
        },
      };
    case ACTION_TYPES.ADD_PAYMENT_SOURCE_SUCCEEDED:
      return {
        ...state,
        paymentSources: {
          ...state.paymentSources,
          // TODO: This assumes new card was added as default
          defaultSourceId: action.card.id,
          sources: _uniq(state.paymentSources.sources.concat(action.card.id)),
          mapOfPaymentSources: {
            ...state.paymentSources.mapOfPaymentSources,
            [action.card.id]: action.card,
          },
        },
      };
    case ACTION_TYPES.CREATE_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        user: {
          ...state.user,
          stage: USER_STAGE_ACTIVE,
          ...action.subscription,
        },
      };

    case ACTION_TYPES.UPDATE_DELIVERY_INFO_SUCCEEDED:
      return {
        ...state,
        user: {
          ...state.user,
          phone: action.phone
            ? stripNonNumeric(action.phone)
            : state.user.phone,
          defaultAddressId:
            action.address && action.address.addressId
              ? action.address.addressId
              : state.user.defaultAddressId,
          addresses: action.address
            ? state.user.addresses.concat(action.address.addressId)
            : state.user.addresses,
          mapOfAddresses: action.address
            ? {
                ...state.user.mapOfAddresses,
                [action.address.addressId]: action.address,
              }
            : state.user.mapOfAddresses,
        },
      };

    case ACTION_TYPES.FETCH_SIGNUP_REASONS_SUCCEEDED: {
      return {
        ...state,
        signupReasons: action.reasons,
      };
    }

    case ACTION_TYPES.FETCH_CANCELLATION_REASONS_SUCCEEDED: {
      return {
        ...state,
        cancellationReasons: action.reasons,
      };
    }

    case FRIENDBUY_ACTION_TYPES.APPLY_ATTRIBUTION_SUCCEEDED: {
      return handleReferralAttributionSucceeded(state, action);
    }

    // NC-1499 Remove legacy friendbuy
    case FRIENDBUY_ACTION_TYPES.APPLY_REFERRAL_SUCCEEDED:
      return {
        ...state,
        user: {
          ...state.user,
          // user record values that come back
          deferredCoupon: action.deferredCoupon,
          referringUserId: action.referringUserId,
          referringUserCampaignId: action.referringUserCampaignId,
          referringUserReferralCode: action.referringUserReferralCode,
          referralDate: action.referralDate,
        },
      };

    case ACTION_TYPES.REMOVE_COUPON: {
      return {
        ...state,
        removingCoupon: true,
      };
    }
    case ACTION_TYPES.REMOVE_COUPON_SUCCEEDED:
    case ACTION_TYPES.REMOVE_COUPON_FAILED: {
      return {
        ...state,
        removingCoupon: false,
      };
    }
    case ACTION_TYPES.FETCH_CREDIT_BALANCE_SUCCEEDED: {
      return {
        ...state,
        creditBalance: action.balance,
      };
    }
    case ACTION_TYPES.FETCH_CREDIT_TRANSACTIONS_SUCCEEDED: {
      return {
        ...state,
        creditTransactions: action.transactions,
      };
    }
    case ACTION_TYPES.SKIP_ORDER:
    case ACTION_TYPES.SKIP_FUTURE_ORDER:
    case ACTION_TYPES.UNSKIP_ORDER:
    case ACTION_TYPES.DONATE_FUTURE_ORDER:
    case ACTION_TYPES.UNDONATE_FUTURE_ORDER: {
      return {
        ...state,
        skipActionInProgress: true,
      };
    }
    case ACTION_TYPES.SKIP_ORDER_SUCCEEDED:
    case ACTION_TYPES.SKIP_FUTURE_ORDER_SUCCEEDED:
    case ACTION_TYPES.UNSKIP_ORDER_SUCCEEDED:
    case ACTION_TYPES.SKIP_ORDER_FAILED:
    case ACTION_TYPES.SKIP_FUTURE_ORDER_FAILED:
    case ACTION_TYPES.UNSKIP_ORDER_FAILED:
    case ACTION_TYPES.DONATE_FUTURE_ORDER_SUCCEEDED:
    case ACTION_TYPES.UNDONATE_FUTURE_ORDER_SUCCEEDED:
    case ACTION_TYPES.DONATE_FUTURE_ORDER_FAILED:
    case ACTION_TYPES.UNDONATE_FUTURE_ORDER_FAILED: {
      return {
        ...state,
        skipActionInProgress: false,
      };
    }

    case ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST: {
      return {
        ...state,
        user: {
          ...state.user,
          neverList: state.user.neverList.includes(action.productId)
            ? state.user.neverList
            : state.user.neverList.concat(action.productId),
        },
      };
    }
    case ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST: {
      return {
        ...state,
        user: {
          ...state.user,
          neverList: state.user.neverList.filter(
            (id) => id !== action.productId
          ),
        },
      };
    }
    case ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST_SUCCEEDED: {
      return {
        ...state,
        user: {
          ...state.user,
          neverList: state.user.neverList.includes(action.productId)
            ? state.user.neverList
            : state.user.neverList.concat(action.productId),
        },
      };
    }
    case ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_SUCCEEDED: {
      return {
        ...state,
        user: {
          ...state.user,
          neverList: state.user.neverList.filter(
            (id) => id !== action.productId
          ),
        },
      };
    }
    case ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST_FAILED: {
      return {
        ...state,
        user: {
          ...state.user,
          neverList: state.user.neverList.filter(
            (id) => id !== action.productId
          ),
        },
      };
    }
    case ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST_FAILED: {
      return {
        ...state,
        user: {
          ...state.user,
          neverList: state.user.neverList.includes(action.productId)
            ? state.user.neverList
            : state.user.neverList.concat(action.productId),
        },
      };
    }
    case ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS: {
      const products = state.user.recurringItems.products
        ? {
            ...state.user.recurringItems.products,
            [action.productId]: { quantity: action.quantity },
          }
        : { [action.productId]: { quantity: action.quantity } };
      return {
        ...state,
        user: {
          ...state.user,
          recurringItems: { ...state.user.recurringItems, products },
        },
      };
    }
    case ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS: {
      const products = state.user.recurringItems.products
        ? _omit(state.user.recurringItems.products, action.productId)
        : {};

      return {
        ...state,
        user: {
          ...state.user,
          recurringItems: { ...state.user.recurringItems, products },
        },
      };
    }

    case ACTION_TYPES.CANCEL_SUBSCRIPTION: {
      return {
        ...state,
        cancelSubscriptionStatus: CancelSubscriptionStatus.IN_PROGRESS,
      };
    }
    case ACTION_TYPES.CANCEL_SUBSCRIPTION_SUCCEEDED: {
      return {
        ...initialState,
        cancelSubscriptionStatus: CancelSubscriptionStatus.COMPLETE,
      };
    }
    case ACTION_TYPES.CANCEL_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        cancelSubscriptionStatus: null,
      };
    }
    case ACTION_TYPES.SET_MESSAGE_AS_READ: {
      return {
        ...state,
        markMessageAsReadInProgress: true,
      };
    }
    case ACTION_TYPES.SET_MESSAGE_AS_READ_SUCCEEDED:
    case ACTION_TYPES.SET_MESSAGE_AS_READ_FAILED: {
      return {
        ...state,
        markMessageAsReadInProgress: false,
      };
    }

    case ACTION_TYPES.CADENCE_OFFSET_UPDATED: {
      return {
        ...state,
        user: {
          ...state.user,
          cadenceOffset: action.offset,
        },
      };
    }

    case ACTION_TYPES.SET_ADD_ON_BOX_IDS: {
      return {
        ...state,
        addingSecondaryBoxIds: action.changedAddOnBoxIds || [],
      };
    }

    case ACTION_TYPES.SET_ADD_ON_BOX_IDS_SUCCEEDED: {
      return {
        ...state,
        user: {
          ...state.user,
          addOnBoxIds: action.addOnBoxIds, // optimistic, confirmed with refetch of user record
        },
        addingSecondaryBoxIds: [],
      };
    }
    case ACTION_TYPES.SET_ADD_ON_BOX_IDS_FAILED: {
      return {
        ...state,
        addingSecondaryBoxIds: [],
      };
    }
    case ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE: {
      return {
        ...state,
        user: {
          ...state.user,
          allowRecurringItemsNudge: action.values.allowRecurringItemsNudge,
        },
      };
    }
    case ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE_FAILED: {
      return {
        ...state,
        user: {
          ...state.user,
          allowRecurringItemsNudge: action.initialValue,
        },
      };
    }
    case ACTION_TYPES.SET_WINBACK_ACCEPTED: {
      return {
        ...state,
        winbackAccepted: action.value,
      };
    }
    case ACTION_TYPES.SET_SHOULD_AUTO_SKIP_ORDER: {
      return {
        ...state,
        shouldAutoSkipOrder: action.value,
      };
    }
    case ACTION_TYPES.SET_SHOULD_AUTO_SWITCH_CADENCE_TO_BIWEEKLY: {
      return {
        ...state,
        shouldAutoSwitchCadence: action.value,
      };
    }
    case ACTION_TYPES.SET_LAST_AUTOSKIPPED_ORDER: {
      return {
        ...state,
        lastAutoSkippedOrder: action.value,
      };
    }
    case ACTION_TYPES.SET_REWARD_COUPON_UNUSED: {
      return {
        ...state,
        rewardCouponUnused: action.value,
      };
    }
    default:
      return state;
  }
}

export function getUser(state: AccountState) {
  return state.user;
}

export function getPaymentSources(state: AccountState) {
  return state.paymentSources;
}

export function getSignupReasons(state: AccountState) {
  return state.signupReasons;
}

export function getCancellationReasons(state: AccountState) {
  return state.cancellationReasons;
}

export function isRemovingCoupon(state: AccountState) {
  return state.removingCoupon;
}

export function getCreditBalance(state: AccountState) {
  return state.creditBalance;
}

export function getUserCreditTransactions(state: AccountState) {
  return state.creditTransactions;
}

export function isSkipActionInProgress(state: AccountState) {
  return state.skipActionInProgress;
}

export function getCancelSubscriptionStatus(state: AccountState) {
  return state.cancelSubscriptionStatus;
}

export function markMessageAsReadInProgress(state: AccountState) {
  return state.markMessageAsReadInProgress;
}

export function getAddingSecondaryBoxIds(state: AccountState) {
  return state.addingSecondaryBoxIds;
}

export function fetchUser() {
  return { type: ACTION_TYPES.FETCH_USER };
}

export function fetchPaymentSources(props?: FetchPaymentSources) {
  const resetStatus: boolean =
    props?.resetStatus === undefined ? true : props?.resetStatus;
  return { type: ACTION_TYPES.FETCH_PAYMENT_SOURCES, resetStatus };
}

// used by account billing form
export const addPaymentSource: AddPaymentSourceAction = (params) => {
  return addPaymentSourceAction(params);
};

// used by signup saga
export const addPaymentSourceAction = (
  params: AddPaymentSourceRequestParams
): AddPaymentSourceParams => {
  return {
    type: ACTION_TYPES.ADD_PAYMENT_SOURCE,
    ...params,
  };
};

export function updateSubscriptionBox(values: {
  boxId: string;
  cadence: SignupCadence;
  cadenceOffset: SignupOffset;
  formikConfig: OnSubmitConfig;
}) {
  return { type: ACTION_TYPES.UPDATE_SUBSCRIPTION_BOX, ...values };
}

export function updateDeliveryInfoWithValidation(
  params: UpdateDeliveryInfoWithValidationParams
) {
  return { type: ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_STEP, params };
}

export function updateDeliveryInfoQuery(params: UpdateDeliveryInfoQueryParams) {
  const { values, formikConfig, fuzzyValidation = false } = params;
  return {
    type: ACTION_TYPES.UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP,
    ...values,
    formikConfig,
    fuzzyValidation,
  };
}

export function cancelUpdateDeliveryInfoOnValidationStep({
  formikConfig,
}: {
  formikConfig: OnSubmitConfig;
}) {
  return {
    type: ACTION_TYPES.UPDATE_DELIVERY_INFO_VALIDATION_STEP_FAILED,
    formikConfig: {
      ...formikConfig,
      failureMessage: formikConfig.cancelMessage || "",
    },
  };
}

export function cancelUpdateDeliveryInfoOnQueryStep({
  formikConfig,
}: {
  formikConfig: OnSubmitConfig;
}) {
  return {
    type: ACTION_TYPES.UPDATE_DELIVERY_INFO_ACCOUNTQUERY_STEP_FAILED,
    formikConfig: {
      ...formikConfig,
      resetUponFailure: true,
      failureMessage: formikConfig.cancelMessage || "",
    },
  };
}

export function updateDeliveryInfo(values: UpdateDeliveryInfoParams) {
  return { type: ACTION_TYPES.UPDATE_DELIVERY_INFO, ...values };
}

export function fetchSignupReasons() {
  return { type: ACTION_TYPES.FETCH_SIGNUP_REASONS };
}

export function fetchCancellationReasons() {
  return { type: ACTION_TYPES.FETCH_CANCELLATION_REASONS };
}

// TODO: NC-1010 Remove if subscription pause experiment fails
export function fetchSubPauseNextDelivery() {
  return { type: ACTION_TYPES.FETCH_SUB_PAUSE_NEXT_DELIVERY };
}

export function fetchNextDelivery(params = null) {
  return { type: ACTION_TYPES.FETCH_NEXT_DELIVERY, params };
}

export function fetchFutureOrders() {
  return { type: ACTION_TYPES.FETCH_FUTURE_ORDERS };
}

export function addVacationHold(params: AddVacationHold) {
  return { type: ACTION_TYPES.ADD_VACATION_HOLD, ...params };
}

export function removeVacationHold(params: RemoveVacationHold) {
  return { type: ACTION_TYPES.REMOVE_VACATION_HOLD, ...params };
}

export function skipOrder(params: SkipOrderParams) {
  return { type: ACTION_TYPES.SKIP_ORDER, ...params } as SkipOrder;
}

export function skipFutureOrder(params: SkipOrderParams) {
  return { type: ACTION_TYPES.SKIP_FUTURE_ORDER, ...params } as SkipOrder;
}

export function unskipOrder(params: UnskipOrderParams) {
  return { type: ACTION_TYPES.UNSKIP_ORDER, ...params };
}

export function setShouldAutoSkipOrder(value: boolean) {
  return { type: ACTION_TYPES.SET_SHOULD_AUTO_SKIP_ORDER, value };
}

export function donateFutureOrder(params: DonateFutureOrder) {
  return { type: ACTION_TYPES.DONATE_FUTURE_ORDER, ...params };
}

export function undonateFutureOrder(params: UndonateFutureOrder) {
  return { type: ACTION_TYPES.UNDONATE_FUTURE_ORDER, ...params };
}

export function applyCoupon(params: ApplyCouponParams) {
  return { type: ACTION_TYPES.APPLY_COUPON, ...params };
}

export function removeCoupon() {
  return { type: ACTION_TYPES.REMOVE_COUPON };
}

export function cancelSubscription(
  params: CancelSubscription
): CancelSubscriptionAction {
  return { type: ACTION_TYPES.CANCEL_SUBSCRIPTION, ...params };
}

export function fetchCreditBalance() {
  return { type: ACTION_TYPES.FETCH_CREDIT_BALANCE };
}

export function fetchUserCreditTransactions(type = "billing") {
  return { type: ACTION_TYPES.FETCH_CREDIT_TRANSACTIONS, creditType: type };
}

export function addProductToNeverList(
  productId: string,
  source = "card",
  skipToast = false
) {
  return {
    type: ACTION_TYPES.ADD_PRODUCT_TO_NEVER_LIST,
    productId,
    source,
    skipToast,
  };
}

export function removeProductFromNeverList(productId: string) {
  return { type: ACTION_TYPES.REMOVE_PRODUCT_FROM_NEVER_LIST, productId };
}

export function addProductToRecurringItems(
  productId: string,
  triggeringVariantId: string,
  source: unknown,
  quantity = 1,
  skipToast = false,
  name = ""
) {
  return {
    type: ACTION_TYPES.ADD_PRODUCT_TO_RECURRING_ITEMS,
    productId,
    quantity,
    metadata: { triggeringVariantId, source },
    skipToast,
    name,
  };
}

export function removeProductFromRecurringItems(
  productId: string,
  source: unknown
) {
  return {
    type: ACTION_TYPES.REMOVE_PRODUCT_FROM_RECURRING_ITEMS,
    productId,
    source,
  };
}

export function cancellationReasonsChosen(params: CancellationReasonsChosen) {
  return { type: ACTION_TYPES.CANCELLATION_REASONS_CHOSEN, ...params };
}

export function cancellationWinbackViewed(params: CancellationWinbackViewed) {
  return { type: ACTION_TYPES.CANCELLATION_WINBACK_VIEWED, ...params };
}

export function setMessageAsRead(params: SetMessageAsRead) {
  return { type: ACTION_TYPES.SET_MESSAGE_AS_READ, ...params };
}

export function setAddOnBoxIds(params: SetAddonBoxIds) {
  return { type: ACTION_TYPES.SET_ADD_ON_BOX_IDS, ...params };
}

export function enrollPlusMembership(params: {}) {
  return { type: ACTION_TYPES.ENROLL_PLUS_MEMBERSHIP, ...params };
}

export function setPlusMembershipAutoRenewal(
  params: SetPlusMembershipAutoRenewal
) {
  return { type: ACTION_TYPES.SET_PLUS_MEMBERSHIP_AUTORENEWAL, ...params };
}

export function toggleAllowRecurringItemsNudge(bool: boolean) {
  return {
    type: ACTION_TYPES.SET_ALLOW_RECURRING_ITEMS_NUDGE,
    values: { allowRecurringItemsNudge: bool },
  };
}

export function setWinbackAccepted(value: Boolean) {
  return {
    type: ACTION_TYPES.SET_WINBACK_ACCEPTED,
    value,
  };
}

export function validateRewardCoupon() {
  return {
    type: ACTION_TYPES.VALIDATE_REWARD_COUPON,
  };
}
