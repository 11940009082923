import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { getImageURL } from "app/ui/global/utils";
import Button from "app/ui/shared/Button";

interface CloseIconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  iconAlt?: string;
}

const CloseIconButton: React.FC<CloseIconButtonProps> = ({
  onClick,
  className,
  disabled,
  iconAlt = "Close",
}) => {
  return (
    <StyledIconButton
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      <img
        src={getImageURL("Close.svg", "website-assets", { w: 48 })}
        alt={iconAlt}
        width="24px"
      />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(Button)`
  background-color: transparent;
  border: none;
  border-radius: ${rem(4)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin: 0;
  padding: 0;

  &:focus {
    border: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focus};

    /* change SVG color https://codepen.io/sosuke/pen/Pjoqqp */
    img {
      filter: invert(11%) sepia(57%) saturate(5015%) hue-rotate(312deg)
        brightness(80%) contrast(98%);
    }
  }

  img {
    display: inline-block;
    vertical-align: middle;
  }
`;

export default CloseIconButton;
