import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { rem } from "polished";
import { Box, Dialog, DialogProps, RadioGroup } from "@material-ui/core";

import { cancelUpdateDeliveryInfoOnValidationStep } from "app/reducers/account";
import { UpdateDeliveryInfoWithValidationParams } from "app/types/account/updateDeliveryInfo";
import { AddressType } from "app/types/state/account/Account";
import {
  AddressValidationDialog as AddressValidationDialogType,
  ADDRESS_VALIDATION_OPTION,
} from "app/types/ui/Dialog";
import RadioCard, {
  Subtitle2,
} from "app/ui/designSystem/molecules/buttons/Secondary/RadioCard";
import DialogContent from "app/ui/designSystem/organisms/Dialogs/ConfirmationDialog";
import useHandleTransitions from "app/ui/global/dialogs/useHandleTransitions";

export const makeContentUtility = (
  title: ADDRESS_VALIDATION_OPTION,
  { address, addressLine2, city, state, zip }: AddressType
) => ({
  title,
  subtitle1: (
    <>
      {address}
      <br />
      {addressLine2}
    </>
  ),
  subtitle2: `${city}, ${state} ${zip}`,
  label: `${title}: ${address} ${addressLine2} ${city}, ${state} ${zip}`,
});

export const mergeParamsUtility = (initialParams, selectedAddress) => {
  const { values, formikConfig } = initialParams;
  const updatedFormValues = {
    ...values,
    ...selectedAddress,
  };
  const updatedActionParams = {
    ...initialParams,
    formikConfig: {
      ...formikConfig,
      updatedFormValues,
    },
    values: updatedFormValues,
  } as UpdateDeliveryInfoWithValidationParams;
  return updatedActionParams;
};

export interface AddressValidationDialogProps
  extends Omit<
    DialogProps,
    "open" | "maxWidth" | "onClose" | "TransitionProps"
  > {
  dialog: AddressValidationDialogType;
}

export const AddressValidationDialog: React.FC<AddressValidationDialogProps> = ({
  dialog,
  ...dialogProps
}) => {
  const { showUserOptionOnly, addresses, actionParams } = dialog;
  const initialSelectedAddress = showUserOptionOnly
    ? ADDRESS_VALIDATION_OPTION.USER
    : ADDRESS_VALIDATION_OPTION.SUGGESTED;
  const [selected, setSelected] = useState(initialSelectedAddress);
  const dispatch = useDispatch();
  const { open, handleClose, handleExited } = useHandleTransitions(dialog);
  const handlePrimaryButtonClick = () => {
    const updatedActionParams = mergeParamsUtility(
      actionParams,
      addresses[selected]
    );
    // @ts-ignore TODO: update updateAddressNextAction type to generic
    dispatch(actionParams.updateAddressNextAction(updatedActionParams));
    handleClose();
  };
  const handleCancelClick = () => {
    dispatch(
      cancelUpdateDeliveryInfoOnValidationStep({
        formikConfig: actionParams.formikConfig,
      })
    );
    handleClose();
  };
  const handleOnChange = (event) => {
    setSelected(event.target.value as ADDRESS_VALIDATION_OPTION);
  };

  return (
    <Dialog
      {...dialogProps}
      data-testid="dialog-address-validation"
      maxWidth={false}
      open={open}
      onClose={handleCancelClick}
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogContent
        headerText={
          showUserOptionOnly
            ? "Is this address correct?"
            : "We recommend this address"
        }
        primaryButtonText="Confirm"
        onPrimaryButtonClick={handlePrimaryButtonClick}
        secondaryButtonText="Edit"
        onSecondaryButtonClick={handleCancelClick}
        onClearIconButtonClick={handleCancelClick}
      >
        <Box width="100%">
          {showUserOptionOnly && (
            <AddressUserOptionOnly
              address={addresses[ADDRESS_VALIDATION_OPTION.USER]}
            />
          )}
          {!showUserOptionOnly && (
            <AddressRadioGroup
              addresses={addresses}
              handleOnChange={handleOnChange}
              selected={selected}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface AddressUserOptionOnlyProps {
  address: AddressType;
}

const AddressUserOptionOnly: React.FC<AddressUserOptionOnlyProps> = ({
  address,
}) => {
  const content = makeContentUtility(ADDRESS_VALIDATION_OPTION.USER, address);
  return (
    <Box display="flex" flexDirection="column" mt={6}>
      <Subtitle2>
        <Box textAlign={{ xs: "center", lg: "left" }}>{content.subtitle1}</Box>
      </Subtitle2>
      <Subtitle2>
        <Box textAlign={{ xs: "center", lg: "left" }}>{content.subtitle2}</Box>
      </Subtitle2>
    </Box>
  );
};

interface AddressRadioGroupProps {
  addresses: AddressValidationDialogType["addresses"];
  handleOnChange: (event: React.ChangeEvent) => void;
  selected: ADDRESS_VALIDATION_OPTION;
}

const AddressRadioGroup: React.FC<AddressRadioGroupProps> = ({
  addresses,
  handleOnChange,
  selected,
}) => (
  <RadioGroup
    aria-label="delivery address"
    name="delivery address"
    onChange={handleOnChange}
  >
    <Box
      display="flex"
      gridColumnGap={rem(24)}
      flexDirection={{ xs: "column", lg: "row" }}
    >
      {Object.keys(addresses).map((key) => {
        const content = makeContentUtility(
          key as ADDRESS_VALIDATION_OPTION,
          addresses[key]
        );
        return (
          <RadioCard
            key={key}
            title={content.title}
            subtitle1={content.subtitle1}
            subtitle2={content.subtitle2}
            isCentered
            checked={selected === key}
            value={key}
            ariaLabel={content.label}
          />
        );
      })}
    </Box>
  </RadioGroup>
);

export default AddressValidationDialog;
