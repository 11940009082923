import { Button, Typography } from "@material-ui/core";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";

interface CartItemRemoveUndoProps {
  className?: string;
  disabled: boolean;
  productName: string;
  undoClicked: () => void;
}

const CartItemRemoveUndo: React.FC<CartItemRemoveUndoProps> = ({
  className,
  disabled,
  productName,
  undoClicked,
}) => (
  <Container className={className}>
    <ProductName variant="body1">Remove {productName}</ProductName>
    <Undo disabled={disabled} tabIndex={0} onClick={undoClicked}>
      Undo
    </Undo>
  </Container>
);

export default CartItemRemoveUndo;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.baba};
`;

const ProductName = styled(Typography)`
  padding: 0 ${rem(16)};
  font-size: ${rem(15)};
  line-height: ${rem(18.29)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  line-height: ${rem(15.85)};
`;

const Undo = styled(Button)`
  padding: 0 ${rem(16)};
  color: ${({ theme }) => theme.colors.beet};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  text-decoration: underline;
  transition: color 0.5s linear;

  &:hover {
    color: ${({ theme }) => theme.colors.eggplant};
    background-color: unset;
    text-decoration: underline;
  }

  &:focus {
    border: none;
    outline: ${({ theme }) => theme.borders.focus};
    box-shadow: ${({ theme }) => theme.boxShadows.focusWithOutline};
  }
`;
