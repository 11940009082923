// @ts-nocheck
import moment from "moment-timezone";
import { createSelector } from "reselect";

import * as fromAccount from "../reducers/account";

import { config } from "app/config";
import { PLUS_MEMBERSHIP_GROUP_ID } from "app/constants";

import { CancelSubscriptionStatus } from "app/types/state/account/Account";
import SubscriptionHold, {
  HoldType,
  TransformedHold,
} from "app/types/state/account/SubscriptionHold";

export const USER_STAGE_PROSPECT = "Prospect";
export const USER_STAGE_ACTIVE = "Active";
export const USER_STAGE_LAPSED = "Lapsed";

export const FAILED_AUTH_MESSAGE_ID = "13";
// eslint-disable-next-line prefer-destructuring
export const ACTIVE_TC_MESSAGE_ID = config.get("tc.active_message_id");
export const DECLINED_TERMS_CANCELLATION_REASON =
  "4895393d-4764-4e4c-b2f5-df0f5c542bbd";

export function getUser(state) {
  return fromAccount.getUser(state.account);
}

export function getShouldAutoSkipOrder(state) {
  return state.account.shouldAutoSkipOrder;
}

export function winbackAccepted(state) {
  return state.account.winbackAccepted;
}

export function isRemovingDeferredCoupon(state) {
  return fromAccount.isRemovingCoupon(state.account);
}

export function getUserFirstYear(state) {
  const user = getUser(state);
  if (!user || !user.created) {
    return null;
  }

  const createdAsMoment = moment(user.created);
  if (!createdAsMoment.isValid()) {
    return null;
  }

  return createdAsMoment.year().toString();
}

const makeGetUserProperty = (property, defaultValue) =>
  createSelector([getUser], (user) => {
    const value = user?.[property];
    if (value === undefined && defaultValue !== undefined) {
      return defaultValue;
    }
    return value;
  });

export const getAccountFulfillmentCenter = makeGetUserProperty(
  "fulfillmentCenter"
);
export const getAccountFulfillmentCenterId = makeGetUserProperty(
  "fulfillmentCenterId"
);
export const getUserEmail = makeGetUserProperty("email");
export const getUserPhone = makeGetUserProperty("phone");
export const getMapOfAddresses = makeGetUserProperty("mapOfAddresses");
export const getDefaultAddressID = makeGetUserProperty("defaultAddressId");
export const getUserCadence = makeGetUserProperty("cadence");
export const getUserCadenceOffset = makeGetUserProperty("cadenceOffset");
export const getSubscriptionBoxId = makeGetUserProperty("boxId"); // gets boxId, not subscriptionBoxId!
export const getUserDeliveryWindowId = makeGetUserProperty("deliveryWindowId");
export const getUserStage = makeGetUserProperty("stage");
export const getAccountUserId = makeGetUserProperty("userId");
export const getUserTimezone = makeGetUserProperty("timezone");
export const getGroups = makeGetUserProperty("groups");
export const getDBPCustomerId = makeGetUserProperty("dbpCustomerId");
export const getUserHolds = makeGetUserProperty("holds");
export const getAccountReferringUserReferralCode = makeGetUserProperty(
  "referringUserReferralCode"
);
export const getUserSignupPreferences = makeGetUserProperty(
  "signupPreferences"
);
export const getUserMessagesInQueue = makeGetUserProperty(
  "messagesInQueue",
  []
);
export const getUserImpact = makeGetUserProperty("impact");
export const getUserNeverList = makeGetUserProperty("neverList");
export const getUserNeverItems = makeGetUserProperty("neverItems");
export const getUserRecurringItems = makeGetUserProperty("recurringItems");
export const getUserSecondaryBoxIds = makeGetUserProperty("addOnBoxIds");
export const isUserPlusMember = makeGetUserProperty("isPlusMember");
export const getUserPriceZoneId = makeGetUserProperty("priceZoneId");
export const isUserOnWarmingHold = makeGetUserProperty("isOnWarmingHold");
export const getUserSignupValues = makeGetUserProperty("quizValues");
export const getUserLifetimeOrderCount = makeGetUserProperty(
  "lifetimeOrderCount"
);
export const getUserLifetimeOrderRevenue = makeGetUserProperty(
  "lifetimeOrderRevenue"
);
export const getUserMigratedToMmStatus = makeGetUserProperty(
  "migratedToMmStatus"
);

export const isMigratedToMM = createSelector(
  [getUserMigratedToMmStatus],
  (status) => {
    return status === "Complete";
  }
);

export const shouldAllowRecurringItemsNudge = createSelector(
  [getUser],
  (user) => user && user.allowRecurringItemsNudge !== false
);

export const getUserDeferredCoupon = createSelector(
  [getUser],
  (user) => user && user.deferredCoupon && user.deferredCoupon.code
);

export function getUserRecurringProducts(state) {
  const recurringItemsMap = getUserRecurringItems(state);
  return recurringItemsMap.products && Object.keys(recurringItemsMap.products);
}

export const isUserActive = createSelector(
  [getUserStage],
  (stage) => USER_STAGE_ACTIVE === stage
);
export const isUserLapsed = createSelector(
  [getUserStage],
  (stage) => USER_STAGE_LAPSED === stage
);
export const isUserProspect = createSelector(
  [getUserStage],
  (stage) => USER_STAGE_PROSPECT === stage
);

export const canUserSignup = createSelector([getUserStage], (stage) =>
  stage ? [USER_STAGE_PROSPECT, USER_STAGE_LAPSED].includes(stage) : true
);

export const hasUserBeenActive = createSelector(
  [isUserActive, isUserLapsed],
  (active, lapsed) => active || lapsed
);

export const getUserPreferredName = createSelector(
  [getUser],
  ({ firstName, preferredName }) =>
    (preferredName || firstName || "").substring(0, 20)
);

export function getCurrentTimeForUserTimezone(state) {
  const { timezone } = getUser(state);
  if (timezone) return moment.tz(timezone);
  return moment();
}

export function getPaymentSources(state) {
  return fromAccount.getPaymentSources(state.account);
}

export function getSignupReasons(state) {
  return fromAccount.getSignupReasons(state.account);
}

export function getCancellationReasons(state) {
  return fromAccount.getCancellationReasons(state.account);
}

export const getSelectedCancellationReason = createSelector(
  [getCancellationReasons, (state, props) => props],
  (cancellationReasons, { cancellationReasonId }) => {
    const selectedCancellationReason = cancellationReasons.find((reason) => {
      return cancellationReasonId === reason.id;
    });
    return selectedCancellationReason && selectedCancellationReason.reason;
  }
);

export function getCreditBalance(state) {
  return fromAccount.getCreditBalance(state.account);
}

export const getCreditBalanceFormatted = createSelector(
  [getCreditBalance],
  (creditBalance) => (creditBalance || 0).toFixed(2)
);

export function getUserCreditTransactions(state) {
  return fromAccount.getUserCreditTransactions(state.account);
}

export function isSkipActionInProgress(state) {
  return fromAccount.isSkipActionInProgress(state.account);
}

export function getAddingSecondaryBoxIds(state) {
  return fromAccount.getAddingSecondaryBoxIds(state.account);
}

export const getUserCreditTransactionsFormatted = createSelector(
  [getUserCreditTransactions],
  (creditTransactions) =>
    creditTransactions.map((t) => {
      return {
        ...t,
        id: t.transactionId,
        dateAsMoment: moment(t.transactionDate),
      };
    })
);

export const getSelectedSignupReason = createSelector(
  [getSignupReasons, (state, props) => props],
  (signupReasons, { signupReasonId }) => {
    const selectedSignupReason = signupReasons.find((reason) => {
      return signupReasonId === reason.signupReasonId;
    });
    return selectedSignupReason;
  }
);

export const isSignupReasonNoteAllowed = createSelector(
  [getSelectedSignupReason],
  (reason) => reason && reason.allowsNotes
);

export const isFetchingPaymentSources = createSelector(
  [getPaymentSources],
  ({ loading }) => loading
);

export const getMapOfPaymentSources = createSelector(
  [getPaymentSources],
  ({ mapOfPaymentSources }) => mapOfPaymentSources
);

export const getDefaultPaymentSourceID = createSelector(
  [getPaymentSources],
  ({ defaultSourceId }) => defaultSourceId
);

export const getDefaultPaymentSource = createSelector(
  [getMapOfPaymentSources, getDefaultPaymentSourceID],
  (sources, defaultPaymentSourceID) => sources[defaultPaymentSourceID] || null
);

export const getDefaultAddress = createSelector(
  [getMapOfAddresses, getDefaultAddressID],
  (addresses, defaultAddressID) => addresses[defaultAddressID] || null
);

// for use across all group types (eg feature, membership, etc)
// selectors always take the form (state, props) and props is an object
export const inGroup = createSelector(
  [getGroups, (state, props) => props],
  (groups = [], { groupId }) => {
    return groups.some((group) => group.groupId === groupId);
  }
);

export const getCurrentHolds = (
  holdType: HoldType,
  holdReason?: SubscriptionHold["holdReason"]
) =>
  createSelector(
    [getUserHolds, getUserTimezone, getCurrentTimeForUserTimezone],
    (holds: SubscriptionHold[], userTimezone, userCurrentTime) => {
      if (!holds || !holds.length) return [];
      const holdsFilteredTransformed = holds
        .reduce((acc: TransformedHold[], h) => {
          const holdStartMoment = moment.tz(h.holdStartDate, userTimezone);
          const holdEndMoment = moment.tz(h.holdEndDate, userTimezone);
          if (
            h.holdType === holdType &&
            userCurrentTime.isSameOrAfter(holdStartMoment) &&
            userCurrentTime.isSameOrBefore(holdEndMoment) &&
            (!holdReason || h.holdReason === holdReason)
          ) {
            acc.push({
              ...h,
              holdStartMoment,
              holdEndMoment,
            });
          }
          return acc;
        }, [])
        .sort((current, next) =>
          current.holdStartMoment.diff(next.holdStartMoment)
        );
      return holdsFilteredTransformed;
    }
  );

export const isUserOnHold = (
  holdType: HoldType,
  holdReason?: SubscriptionHold["holdReason"]
) =>
  createSelector(
    [(state) => getCurrentHolds(holdType, holdReason)(state)],
    (holds) => !!(holds && holds.length)
  );

export const getCurrentMostRecentHold = (
  holdType: HoldType,
  holdReason?: SubscriptionHold["holdReason"]
) =>
  createSelector(
    [(state) => getCurrentHolds(holdType, holdReason)(state)],
    (holds) => (holds && holds.length ? holds[0] : null)
  );

export const isUserOnInternalHold = isUserOnHold("internal");
export const isUserOnInternalBillingHold = isUserOnHold("internal", "billing");
export const isUserOnInternalSubtotalHold = isUserOnHold(
  "internal",
  "subtotal"
);
export const getCurrentVacationHolds = getCurrentHolds("vacation");
export const getCurrentMostRecentVacationHold = getCurrentMostRecentHold(
  "vacation"
);
export const isUserOnVacationHold = isUserOnHold("vacation");
export const isUserOnDBPHold = isUserOnHold("dbp");

export const isUserCurrentlyOnHold = createSelector(
  [
    isUserOnInternalHold,
    isUserOnVacationHold,
    isUserOnWarmingHold,
    isUserOnDBPHold,
  ],
  (onInternalHold, onVacationHold, onWarmingHold, onDBPHold) => {
    return onInternalHold || onVacationHold || onWarmingHold || onDBPHold;
  }
);

const makeHasMessageId = (messageId) =>
  createSelector([getUserMessagesInQueue], (messagesInQueue) => {
    return !!messagesInQueue.find((m) => m.messageId === messageId);
  });
export const hasFailedAuthMessage = makeHasMessageId(FAILED_AUTH_MESSAGE_ID);
export const hasTCMessage = makeHasMessageId(ACTIVE_TC_MESSAGE_ID);

// Round to nearest integer for numbers >99
export const getUserImpactFormatted = createSelector(
  [getUserImpact],
  (impact) => {
    if (!impact || !impact.cO2 || !impact.produce || !impact.water) return null;
    const format = (amt) => {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
      return amt.toLocaleString("en", {
        maximumFractionDigits: amt < 1000 ? 2 : 0,
      });
    };
    return {
      cO2: format(impact.cO2),
      produce: format(impact.produce),
      water: format(impact.water),
    };
  }
);

export function getCancelSubscriptionStatus(state) {
  return fromAccount.getCancelSubscriptionStatus(state.account);
}

export function cancelSubscriptionInProgress(state) {
  return (
    getCancelSubscriptionStatus(state) === CancelSubscriptionStatus.IN_PROGRESS
  );
}

export function markMessageAsReadInProgress(state) {
  return fromAccount.markMessageAsReadInProgress(state.account);
}

export const getPlusMembership = createSelector([getGroups], (groups = []) => {
  const membership = groups.find((g) => g.groupId === PLUS_MEMBERSHIP_GROUP_ID);

  return {
    startDate: membership && membership.startDate,
    endDate: membership && membership.endDate,
    autorenew: membership && membership.userGroupMetadata.autorenew,
  };
});
