// @ts-nocheck
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { toRem } from "app/styles/utils";
import { getImageURL } from "app/ui/global/utils";
import Page from "app/ui/cms/contentPage_DEPRECATED/Page_DEPRECATED";
import Pill from "app/ui/shared/Pill";

const Error = ({ titleText, ctaText }) => {
  return (
    <Page>
      <ImageWrapper>
        <img
          src={getImageURL("ErrorImage.png", "v1578606354/website-assets", {
            w: "600",
          })}
          alt="Tomato holding a sign saying OOOPS!"
        />
      </ImageWrapper>
      <Text>{titleText}</Text>
      <PillButton
        id="error-button-reload-cta"
        intent="success"
        onClick={() => window.location.reload(true)}
      >
        {ctaText}
      </PillButton>
    </Page>
  );
};

Error.propTypes = {
  titleText: PropTypes.string,
  ctaText: PropTypes.string,
};

Error.defaultProps = {
  titleText: "Yikes, we hit a snag!",
  ctaText: "Refresh Page",
};

const ImageWrapper = styled.div`
  max-width: ${toRem(600)};
  margin: 0 auto;
`;

const Text = styled.h1`
  && {
    font-size: ${toRem(24)};
    margin-bottom: ${toRem(24)};
  }
`;

const PillButton = styled(Pill)`
  align-self: center;
  border-radius: ${toRem(18)};
  margin-bottom: ${toRem(32)};
  padding: ${toRem([10, 16])};

  ${({ theme }) => `
    background-color: ${theme.colors.pink};
    color: ${theme.colors.white};
  `};
`;

export default Error;
