// @ts-nocheck
import { take, fork, call, put, select, all } from "redux-saga/effects";

import ACTION_TYPES from "app/actionTypes/orders";
import ACCOUNT_ACTION_TYPES from "app/actionTypes/account";
import UI_ACTION_TYPES from "app/actionTypes/ui";
import {
  fetchOrderSummaries,
  fetchOrder,
  authActiveOrder,
  updateVariantQuantity,
  setOrderStatus,
  removeCoupon,
  addBoxesToOrder,
  fetchOrderPosit,
} from "app/api/orderService";
import { jalapenoValidateCoupon } from "app/api/misfits";
import { BASE_ORDER_PARAMS } from "app/constants/orders";
import { fetchAllOfferings } from "app/reducers/offerings";
import { updateVariantQuantity as updateVariantQuantityAction } from "app/reducers/orders";
import { setDialog } from "app/reducers/ui";
import { fetchFutureOrdersFlow, setCadenceOffsetFlow } from "app/sagas/account";
import createAdHocOrderFlow from "app/sagas/createAdhocOrderFlow";
import { extractResponse } from "app/sagas/extractResponseMessage";
import fetchNextDeliveryFlow from "app/sagas/fetchNextDeliveryFlow";
import { bufferedActionProcessor } from "app/sagas/utils";
import {
  getUserId,
  getActiveOrder,
  getActiveOrderId,
  getOriginalOrderItem,
  getOriginalOrderAdhocLineByVariantId,
  getActiveOrderVariantQty,
  getMapOfBoxes,
  getBaseOrderRequestParams,
  getVariantByVariantId,
  getActiveAlgoliaData,
  getNextSkippableOrder,
  getOfferingByVariantId,
  getShelfVariantIds,
  getCatalogPreviewTool,
  getNextDeliveryPriceZoneFcAndDate,
  getSignupCoupon,
} from "app/selectors";
import { getShelfInfoFromShelfId } from "app/selectors/shelves";
import SetOrderStatusAction from "app/types/orders/SetOrderStatusAction";
import { DialogType } from "app/types/ui/Dialog";
import { skipOrder, skipFutureOrder } from "app/reducers/account";
import { getImageURL } from "app/ui/global/utils";

export default function* rootOrders() {
  yield fork(bufferedActionProcessor, {
    actionType: ACTION_TYPES.UPDATE_VARIANT_QUANTITY,
    processorFn: updateVariantQuantityFlow,
  });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take([
      ACTION_TYPES.FETCH_ACTIVE_ORDER,
      ACTION_TYPES.FETCH_ORDER_SUMMARIES,
      ACTION_TYPES.FETCH_ORDER,
      ACTION_TYPES.INCREMENT_VARIANTS_QUANTITY,
      ACTION_TYPES.SET_ORDER_STATUS,
      ACTION_TYPES.VALIDATE_COUPON,
      ACTION_TYPES.JALAPENO_VALIDATE_COUPON,
      ACTION_TYPES.REMOVE_COUPON,
      ACTION_TYPES.CREATE_AD_HOC_ORDER,
      ACTION_TYPES.ADD_BOXES_TO_ORDER,
      ACTION_TYPES.AUTO_SKIP_NEXT_ORDER,
      ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF,
      ACTION_TYPES.FETCH_ORDER_POSIT,
    ]);

    switch (action.type) {
      case ACTION_TYPES.FETCH_ACTIVE_ORDER: {
        yield fork(fetchActiveOrderFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_ORDER_SUMMARIES: {
        yield fork(fetchOrderSummariesFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_ORDER: {
        yield fork(fetchOrderFlow, action);
        break;
      }
      case ACTION_TYPES.INCREMENT_VARIANTS_QUANTITY: {
        yield fork(incrementVariantsQuantityFlow, action);
        break;
      }
      case ACTION_TYPES.SET_ORDER_STATUS: {
        yield fork(setOrderStatusFlow, action);
        break;
      }
      case ACTION_TYPES.JALAPENO_VALIDATE_COUPON: {
        yield fork(jalapenoValidateCouponFlow, action);
        break;
      }
      case ACTION_TYPES.VALIDATE_COUPON: {
        yield fork(validateCouponFlow, action);
        break;
      }
      case ACTION_TYPES.REMOVE_COUPON: {
        yield fork(removeCouponFlow, action);
        break;
      }
      case ACTION_TYPES.CREATE_AD_HOC_ORDER: {
        yield fork(createAdHocOrderFlow, action);
        break;
      }
      case ACTION_TYPES.ADD_BOXES_TO_ORDER: {
        yield fork(addBoxesToOrderFlow, action);
        break;
      }
      case ACTION_TYPES.AUTO_SKIP_NEXT_ORDER: {
        yield fork(autoSkipNextOrder, action);
        break;
      }
      case ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF: {
        yield fork(updateAllVariantQuantitiesFromShelfFlow, action);
        break;
      }
      case ACTION_TYPES.FETCH_ORDER_POSIT: {
        yield fork(fetchOrderPositFlow, action);
        break;
      }
      default:
        break;
    }
  }
}

export function* fetchActiveOrderFlow({ tags, params = {} }) {
  const orderId = yield select(getActiveOrderId);
  if (orderId) {
    yield call(fetchOrderFlow, { orderId, params, tags });
    yield put({ type: ACTION_TYPES.FETCH_ACTIVE_ORDER_SUCCEEDED });
  } else {
    // this doesn't actually fail ....
    yield put({ type: ACTION_TYPES.FETCH_ACTIVE_ORDER_FAILED });
  }
}

export function* fetchOrderSummariesFlow() {
  try {
    const userId = yield select(getUserId);
    const params = { ...BASE_ORDER_PARAMS, tax: true };

    const orders = yield call(fetchOrderSummaries, { userId, params });
    yield put({
      type: ACTION_TYPES.FETCH_ORDER_SUMMARIES_SUCCEEDED,
      orders,
      params,
    });
  } catch (error) {
    yield put({ type: ACTION_TYPES.FETCH_ORDER_SUMMARIES_FAILED, error });
  }
}

export function* fetchOrderFlow(action) {
  const { orderId, params = {}, tags } = action;
  if (orderId) {
    try {
      const baseOrderParams = yield select(getBaseOrderRequestParams);
      const orderParams = {
        ...baseOrderParams,
        ...params,
      };
      const response = yield call(fetchOrder, {
        orderId,
        params: orderParams,
        tags,
      });
      const order = {
        ...response,
        params: orderParams,
      };

      yield put({ ...action, type: ACTION_TYPES.FETCH_ORDER_SUCCEEDED, order });
    } catch (error) {
      yield put({ ...action, type: ACTION_TYPES.FETCH_ORDER_FAILED, error });
    }
  }
}

export function* updateVariantQuantityFlow(action) {
  const {
    isCollectionAdd,
    metadata,
    optimistic = true,
    optimisticId,
    quantity,
    rank,
    searching = false,
    searchQuery = null,
    source,
    variantId,
    shelfId,
  } = action;
  const orderId = yield select(getActiveOrderId);
  const originalItem = yield select(getOriginalOrderItem, {
    optimisticId,
    variantId,
  });
  const originalAdhocLine = yield select(getOriginalOrderAdhocLineByVariantId, {
    optimisticId,
    variantId,
  });
  const originalQuantity = originalItem ? originalItem.quantity : 0;

  const catalogPreviewTool = yield select(getCatalogPreviewTool);

  try {
    // don't update the quantity if its unchanged or in catalog preview mode
    if (quantity === originalQuantity || catalogPreviewTool?.data !== null) {
      yield put({
        type: ACTION_TYPES.UPDATE_VARIANT_QUANTITY_CANCELED,
        metadata,
        optimistic,
        optimisticId,
        orderId,
        originalQuantity,
        quantity,
        source,
        variantId,
        shelfId,
        ...(searching && { searching }),
        ...(searchQuery && { searchQuery }),
      });
    } else {
      const params = yield select(getBaseOrderRequestParams);
      const offering = yield select(getVariantByVariantId, { variantId });
      const algoliaData = yield select(getActiveAlgoliaData);
      const metadata = {
        ...(offering &&
          originalQuantity === 0 && {
            objectID: offering.objectID,
            queryID: algoliaData?.queryID,
            index: algoliaData?.index,
          }),
      };
      const response = yield call(updateVariantQuantity, {
        orderId,
        variantId,
        quantity,
        params,
        metadata,
      });

      const order = {
        ...response,
        params,
      };

      yield put({
        type: ACTION_TYPES.UPDATE_VARIANT_QUANTITY_SUCCEEDED,
        metadata,
        optimistic,
        optimisticId,
        order,
        orderId,
        quantity,
        variantId,
        shelfId,

        // These are needed for analytics
        originalAdhocLine,
        originalItem,
        originalQuantity,
        rank,
        source,
        ...(quantity > originalQuantity ? { isCollectionAdd } : {}),
        ...(searching && { searching }),
        ...(searchQuery && { searchQuery }),
      });
    }
  } catch (error) {
    const isOutOfStock = yield call(isItemOutOfStock, error);

    yield put({
      type: ACTION_TYPES.UPDATE_VARIANT_QUANTITY_FAILED,
      error,
      isOutOfStock,
      originalItem,
      orderId,
      optimisticId,
      variantId,
      quantity,
      optimistic,
      metadata,
      source,
      ...(searching && { searching }),
      ...(searchQuery && { searchQuery }),
    });

    // If the item is out of stock on a purchase, notify the user
    // and for good measure, let's refetch the offerings
    try {
      if (isOutOfStock) {
        const offering = yield select(getVariantByVariantId, { variantId });
        yield put(
          setDialog({
            type: DialogType.PURCHASE_FAILED,
            offeringName: offering.name,
            variantId: offering.variantId,
          })
        );
        yield put(fetchAllOfferings({ clearAlgoliaCache: true }));
      }
    } catch (e) {
      // do nothing
    }
  }
}

function* updateAllVariantQuantitiesFromShelfFlow(action) {
  const { shelfId } = action;
  try {
    const shelfInfo = yield select(getShelfInfoFromShelfId, shelfId);
    const recipeImage = getImageURL(shelfInfo.promotionImageFilename || "");
    const recipeUrl = shelfInfo.promotionLink || "";
    const recipeName = shelfInfo.name || "";

    const variantIds = yield select(getShelfVariantIds, { shelfId });
    const products = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const variantId of variantIds) {
      const offering = yield select(getOfferingByVariantId, { variantId });
      if (offering.hasStock) {
        products.push(offering);
      }
    }

    const arrayOfItemsToAdd = products.map((product) => {
      const updatedQuantity = product.quantity + 1;
      return {
        variantId: product.variantId,
        shelfId,
        quantity: updatedQuantity,
      };
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const individualVariant of arrayOfItemsToAdd) {
      yield put(
        updateVariantQuantityAction({
          variantId: individualVariant.variantId,
          shelfId: individualVariant.shelfId,
          quantity: individualVariant.quantity,
        })
      );
      yield take([
        ACTION_TYPES.UPDATE_VARIANT_QUANTITY_SUCCEEDED,
        ACTION_TYPES.UPDATE_VARIANT_QUANTITY_FAILED,
        ACTION_TYPES.UPDATE_VARIANT_QUANTITY_CANCELED,
      ]);
    }
    yield put({
      type: ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF_SUCCEEDED,
    });
    if (recipeName) {
      const showRecipeToast = true;
      yield put({
        type: UI_ACTION_TYPES.SET_ADD_ONE_OR_MORE_RECIPE_INGREDIENTS,
        showRecipeToast,
        products,
        shelfId,
        recipeName,
        recipeUrl,
        recipeImage,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPES.UPDATE_ALL_VARIANT_QUANTITIES_FROM_SHELF_FAILED,
    });
  }
}

function* incrementVariantQuantityFlow({ variantId, ...rest }) {
  const currentQty = yield select(getActiveOrderVariantQty, { variantId });
  // omit any lingering action type (eg INCREMENT_VARIANTS_QUANTITY batch)
  const { type, ...restActionParams } = rest;

  yield put(
    updateVariantQuantityAction({
      ...restActionParams,
      variantId,
      quantity: currentQty + 1,
    })
  );
}

function* incrementVariantsQuantityFlow({
  variantIds,
  optimistic = false,
  ...rest
}) {
  const isCollectionAdd = variantIds.length > 1;

  yield all(
    variantIds.map((variantId) =>
      incrementVariantQuantityFlow({
        ...rest,
        variantId,
        isCollectionAdd,
        optimistic,
      })
    )
  );
}

export function* authActiveOrderFlow(rethrow = true) {
  try {
    const activeOrderId = yield select(getActiveOrderId);
    yield call(authActiveOrder, activeOrderId);
    yield put({ type: ACTION_TYPES.AUTH_ACTIVE_ORDER_SUCCEEDED });
  } catch (error) {
    if (rethrow) throw error;
    yield put({ type: ACTION_TYPES.AUTH_ACTIVE_ORDER_FAILED, error });
  }
}

export function* setOrderStatusFlow({
  orderId: oId,
  status,
  reason,
  reasonCode,
  newCadenceOffsetBasisDate,
}: SetOrderStatusAction) {
  let orderId = oId;
  try {
    if (!orderId) {
      orderId = yield select(getActiveOrderId);
    }
    yield call(setOrderStatus, { orderId, status, reason, reasonCode });
    if (newCadenceOffsetBasisDate) {
      const userId = yield select(getUserId);
      yield call(setCadenceOffsetFlow, { userId, newCadenceOffsetBasisDate });
      yield call(fetchFutureOrdersFlow);
    }
    yield call(fetchNextDeliveryFlow);
    yield put({
      type: ACTION_TYPES.SET_ORDER_STATUS_SUCCEEDED,
      orderId,
      status,
    });
  } catch (error) {
    yield put({ type: ACTION_TYPES.SET_ORDER_STATUS_FAILED, error });
  }
}
/* eslint-enable object-curly-newline */

const isItemOutOfStock = (response) => {
  if (!response || response.ok) return false;

  return extractResponse(response).then((responseBody) => {
    if (!responseBody || !responseBody.errors || !responseBody.errors.length)
      return false;
    return responseBody.errors.some(
      (e) => e.code === "invalid_adhoc_set_quantity"
    );
  });
};

export function* jalapenoValidateCouponFlow() {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get("promo") || searchParams.get("promocode");

  try {
    const userId = yield select(getUserId);
    const coupon = yield call(jalapenoValidateCoupon, code, userId);
    yield put({
      type: ACTION_TYPES.JALAPENO_VALIDATE_COUPON_SUCCEEDED,
      code,
      coupon,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.JALAPENO_VALIDATE_COUPON_FAILED, e });
  }
}

export function* validateCouponFlow(action) {
  const { code } = action;
  try {
    const userId = yield select(getUserId);
    const coupon = yield call(jalapenoValidateCoupon, code, userId);
    yield put({ type: ACTION_TYPES.VALIDATE_COUPON_SUCCEEDED, code, coupon });
  } catch (e) {
    yield put({ type: ACTION_TYPES.VALIDATE_COUPON_FAILED, e });
  }
}

export function* removeCouponFlow({ couponId }) {
  try {
    const orderId = yield select(getActiveOrderId);
    const order = yield call(removeCoupon, orderId, couponId);
    yield put({ type: ACTION_TYPES.REMOVE_COUPON_SUCCEEDED, order });
  } catch (e) {
    yield put({ type: ACTION_TYPES.REMOVE_COUPON_FAILED, e, couponId });
  }
}

export function* addBoxesToOrderFlow({ boxIds }) {
  try {
    const activeOrder = yield select(getActiveOrder);
    const order = yield call(addBoxesToOrder, activeOrder.orderId, { boxIds });
    const mapOfBoxes = yield select(getMapOfBoxes);
    const boxes = boxIds.map((bi) => mapOfBoxes[bi]);
    yield put({
      type: ACTION_TYPES.ADD_BOXES_TO_ORDER_SUCCEEDED,
      previousOrder: activeOrder, // Used by reducers to determine if order has changed
      order,
      boxes,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.ADD_BOXES_TO_ORDER_FAILED, e });
  }
}

// TODO: NC-1244 productionalize signup shopping
export function* fetchOrderPositFlow({
  items,
  boxId = null,
  isPrefilledCartFilled = true,
}) {
  try {
    const {
      packDate,
      fulfillmentCenterId,
      windowId,
      deliveryDate,
    } = yield select(getNextDeliveryPriceZoneFcAndDate);
    const coupon = yield select(getSignupCoupon);

    const params = {
      // if a boxId is provided to order posit, it fetches a start cart. We only want to do this when a user initially sees shopping experience.
      boxId: isPrefilledCartFilled ? null : boxId,
      fulfillmentCenterId,
      windowId,
      deliveryDate,
      packDate,
      items,
      coupon: coupon?.code || undefined,
    };

    const order = yield call(fetchOrderPosit, params);
    yield put({
      type: ACTION_TYPES.FETCH_ORDER_POSIT_SUCCEEDED,
      order,
      isPrefilledCartFilled,
    });
  } catch (e) {
    yield put({ type: ACTION_TYPES.FETCH_ORDER_POSIT_FAILED, error: e });
  }
}

export function* autoSkipNextOrder() {
  try {
    const { order, isActive } = yield select(getNextSkippableOrder);
    if (order != null) {
      const skipAction = yield call(
        isActive ? skipOrder : skipFutureOrder,
        order
      );
      yield put(skipAction);
      yield put({
        type: ACCOUNT_ACTION_TYPES.SET_LAST_AUTOSKIPPED_ORDER,
        value: { deliveryDate: order.deliveryDate },
      });
      yield put({
        type: UI_ACTION_TYPES.SET_SKIP_NEXT_ORDER_SNACK,
        value: "NEXT_ORDER_SKIPPED",
      });
    } else {
      yield put({
        type: UI_ACTION_TYPES.SET_SKIP_NEXT_ORDER_SNACK,
        value: "NEXT_FIVE_ORDERS_SKIPPED",
      });
    }
  } finally {
    yield put({
      type: ACCOUNT_ACTION_TYPES.SET_SHOULD_AUTO_SKIP_ORDER,
      value: false,
    });
  }
}
