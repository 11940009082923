import { rem } from "polished";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getSelectedTags } from "app/selectors";
import Aisle from "app/types/state/aisles/Aisle";
import Tag from "app/types/state/offerings/Tag";
import { AisleClickedSource } from "app/types/ui/AisleClicked";
import { AisleShelfClickedSource } from "app/types/ui/AisleShelfClicked";
import { ImageType as AisleNavWidgetImageType } from "app/ui/shared/AisleNavWidget";
import RemoveFilterWidget from "app/ui/shopping/Navigation/RemoveFilterWidget";
import SubaisleListItem, {
  SubaisleListItemData,
} from "app/ui/shopping/Navigation/SubAisle/SubaisleListItem";
import useFilterOutEmptySmartShelves from "app/ui/shopping/hooks/useFilterOutEmptySmartShelves";
import { aisleClicked, aisleShelfClicked } from "app/reducers/ui";

export interface MobileDropdownProps {
  type: "aisle" | "shelf";
  currentAnchorId?: string;
  currentAisle?: Aisle;
  toggleAislesDropdown: () => void;
  toggleShelvesDropdown: () => void;
  aisleListItems: SubaisleListItemData[];
  subaisleListItems?: SubaisleListItemData[];
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({
  type,
  currentAisle,
  toggleAislesDropdown,
  toggleShelvesDropdown,
  aisleListItems,
  subaisleListItems,
}) => {
  const tags: Tag[] = useSelector(getSelectedTags);
  const dispatch = useDispatch();
  const handleAisleClick = ({ id, name }: { id: string; name: string }) => {
    toggleAislesDropdown();
    dispatch(
      aisleClicked({
        location: "Mobile",
        aisleId: id,
        aisleName: name,
        source: AisleClickedSource.NavigationTopBar,
      })
    );
  };

  const handleSubaisleClick = ({ id, name }: { id: string; name: string }) => {
    if (!currentAisle) return;
    toggleShelvesDropdown();
    dispatch(
      aisleShelfClicked({
        location: "Mobile",
        shelfId: id,
        shelfName: name,
        aisleId: currentAisle.aisleId,
        aisleName: currentAisle.name,
        source: AisleShelfClickedSource.NavigationTopBar,
      })
    );
  };

  const filteredShelves = useFilterOutEmptySmartShelves(subaisleListItems);

  if (currentAisle && type === "shelf") {
    return (
      <MobileDropdownWrapper data-testid="shelves-dropdown-wrapper">
        {filteredShelves?.map(({ active, disabled, id, label, to, value }) => (
          <StyledSubaisleListItem
            active={active}
            disabled={disabled}
            key={id}
            label={label}
            onClick={() => handleSubaisleClick({ id, name: label })}
            to={to}
            value={value}
          />
        ))}
        {tags.length > 0 && (
          <StyledRemoveFilterWidget
            tag={tags[0]}
            imageType={AisleNavWidgetImageType.PUMPKIN_BOTTOM_EDGE}
          />
        )}
      </MobileDropdownWrapper>
    );
  }

  return (
    <MobileDropdownWrapper data-testid="aisles-dropdown-wrapper">
      {aisleListItems.map(({ active, disabled, id, label, to, value }) => (
        <StyledSubaisleListItem
          active={active}
          disabled={disabled}
          key={id}
          label={label}
          onClick={() => handleAisleClick({ id, name: label })}
          to={to}
          value={value}
        />
      ))}
      {tags.length > 0 && (
        <StyledRemoveFilterWidget
          tag={tags[0]}
          imageType={AisleNavWidgetImageType.PUMPKIN_BOTTOM_EDGE}
        />
      )}
    </MobileDropdownWrapper>
  );
};

export default MobileDropdown;

const MobileDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding-top: ${rem(8)};
  padding-bottom: ${rem(64)};
`;

const StyledRemoveFilterWidget = styled(RemoveFilterWidget)`
  margin: ${rem(16)};
  margin-top: ${rem(12)};
`;

const StyledSubaisleListItem = styled(SubaisleListItem)`
  padding-right: ${rem(32)};
  padding-left: ${rem(32)};
`;
