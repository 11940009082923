// @ts-nocheck
// For mobile-first styles - the default
import { css } from "styled-components";
import { em } from "polished";

export const minWidth = (width: number) => (...args) => css`
  @media (min-width: ${em(width)}) {
    ${css(...args)}
  }
`;
// For styles that only apply up to a specific width. this width scales with font size (em units)
export const maxWidth = (width: number) => (...args) => css`
  @media (max-width: ${em(width)}) {
    ${css(...args)}
  }
`;

//  For styles that only apply up to a specific width. This width does not scale with font size
export const minWidthNonScaling = (width: number) => (...args) => css`
  @media (min-width: ${width}px) {
    ${css(...args)}
  }
`;
