import { Drawer } from "@material-ui/core";
import { rem } from "polished";
import React, { useRef } from "react";
import styled from "styled-components";
import theme from "app/styles/theme";
import Cart from "app/ui/shopping/Cart/Cart";
import breakpoints from "app/styles/breakpoints";

interface ShopCartProps {
  toggleCart: () => void;
  cartVisible: boolean;
}

const ShopCart: React.FC<ShopCartProps> = ({ cartVisible, toggleCart }) => {
  const cartContainerRef = useRef<HTMLDivElement>(null);

  return (
    <StyledDrawer
      anchor="right"
      disableEnforceFocus
      onClose={toggleCart}
      open={cartVisible}
      style={{
        zIndex: theme.layout.zIndex.cart,
      }}
    >
      <HeaderPlaceholder />
      <CartContainer ref={cartContainerRef}>
        <Cart cartContainerRef={cartContainerRef} />
      </CartContainer>
    </StyledDrawer>
  );
};

export default ShopCart;

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    border-radius: 0;
  }
`;

const HeaderPlaceholder = styled.div`
  flex-shrink: 0;
  height: ${({ theme }) => rem(theme.layout.headerHeightMobile)};

  ${breakpoints.xl`
    height: ${({ theme }) => rem(theme.layout.headerHeight)};
  `}
`;

const CartContainer = styled.div`
  height: 100%;
  overflow: auto;
`;
